import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';

import { Login } from './Login'
import { Register } from './Register'

import { useSelector, useDispatch } from 'react-redux';
import { UserActions } from '../Redux';
import _ from 'lodash';

const TabPane = Tabs.TabPane;

const AuthCard = (props) => {
	const tab = useSelector((state) => state.user.login_tab);
	const [current_tab, set_current_tab] = useState(tab)
	if (props.initial_tab && props.initial_tab != current_tab && _.isUndefined(tab)) {
		set_current_tab(props.initial_tab);
	}
  const dispatch = useDispatch();

	useEffect(()=> {
		set_current_tab(tab);
	},[tab]);

  return (
    <Card 
      title={current_tab == "login" ? "Log In" : "Register"} 
      bordered={false} 
      className="funnel-card"
    >
      <Tabs 
        size="small" 
        animated={true} 
        defaultActiveKey={current_tab}
        activeKey={current_tab}
        tabBarStyle={{ display: 'none' }} 
        className="login-tabs"
      >
        <TabPane tab="Log In" key="login" className='input-tab'>

          <Login isModal={true} onLoggedIn={null} forward={true}/>
          <span className="login-toggle">
            Don't have an account? <a onClick={()=>dispatch(UserActions.toggleLoginTab("signup"))}>Register Now</a>
          </span>

        </TabPane>
        <TabPane tab="Register" key="signup" className='input-tab'>

          <Register onLoggedIn={null} forward={true}/>
          <span className="login-toggle">
            Already have an account? <a onClick={()=>dispatch(UserActions.toggleLoginTab("login"))}>Log In</a>
          </span>

        </TabPane>
      </Tabs>
    </Card>  
  );
}

export { AuthCard };