import styled from "styled-components";

const ThirtyPercentInputLayout = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "tp-label tp-input";
  justify-content: space-between;
  margin-bottom: 10px;
  color: #333;

  input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    &:hover {
      border-color: #3bb864;
      border-right-width: 1px !important;
    }
    &:focus {
      border-color: #60de4f;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(27 172 81 / 20%);
    }
  }
`;

const ThirtyPercentSelectLayout = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "tp-label select";
  justify-content: space-between;
  margin-bottom: 10px;
  color: #333;

  select {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 3px 4px;
    color: #333;

    &:hover {
      border-color: #3bb864;
      border-right-width: 1px !important;
    }
    &:focus {
      border-color: #60de4f;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(27 172 81 / 20%);
    }
  }
`;

export { ThirtyPercentInputLayout, ThirtyPercentSelectLayout };
