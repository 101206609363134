import styled from "styled-components";

const SIFT_Button = styled.button`
  text-align: center;
  border-radius: 3px;
  padding: 4.7px 15px;
  font-size: 1.1em;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  font-weight: 700;
  cursor: pointer;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  font-family: "Muli", sans-serif;
  text-shadow: none;
  max-width: 200px;
  background-color: ${(props) => (props.type == "ghost" ? "#fff" : "#ff4800")};
  border-color: #ff4800;
  margin-right: 10px;
  color: ${(props) => (props.type == "ghost" ? "#ff4800" : "#fff")};
  border: 1px solid #ff4800;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    background-color: ${(props) => (props.type == "ghost" ? "#fff" : "#ff6d29")};
    border-color: #ff6d29;
  }
`;

export { SIFT_Button };
