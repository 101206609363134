import styled from "styled-components";

const BaseWrap = styled.section`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: grid;
  background: #fff;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header-area"
    "inputs";

  .header-area {
    grid-area: header-area;
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 80px;

      a img {
        height: 100%;
      }
    }
  }

  .inputs {
    grid-area: inputs;
    width: 95%;
    height: 100%;
    display: grid;
    place-items: center;
  }
`;

export { BaseWrap };
