import styled from "styled-components";

export const ModuleSelectRow = styled.section`
  display: flex;
  width: 100%;
  place-content: center;
  z-index: 9;
  margin-top: 5px;
  padding-right: 45px;
`;

export const LockButton = styled.button`
  border: none;
  background: none;
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
`;
