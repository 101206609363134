import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";
import { usePrevious } from "../../..";

const AzimuthLayer = ({ id }) => {
  const map = useMap();
  const dispatch = useDispatch();

  //store
  const canopy = useSelector((state) => state.canopy.canopies[id]);
  // const canopies = useSelector((state) => state.canopy.canopies);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const prevSelectedCanopy = usePrevious(selected_canopy);

  useEffect(() => {
    if (selected_canopy == id) {
      addAzi();
    } else {
      removeAzi();
    }
  }, [selected_canopy]);

  useEffect(() => {
    if (!canopy) return;
    removeAzi();
    if (!canopy.changed_from_override) addAzi();
  }, [canopy.azimuthHolder]);

  const addAzi = () => {
    if (selected_canopy !== id) return;
    let aziLayer = L.geoJSON(canopy.azimuthHolder, {
      azi_layer_id: canopy.id,
      transform: false,
      draggable: false,
      scaling: false,
      interactive: false,
      style: (aziStyle) => {
        return { color: "none", fillColor: "var(--canopy-color)", fillOpacity: "0.9" };
      },
    });
    aziLayer.addTo(map);
  };

  const removeAzi = (remove_id = id) => {
    map.eachLayer((layer) => layer.feature && layer.options.azi_layer_id == remove_id && layer.remove());
  };

  return <></>;
};

export { AzimuthLayer };
