import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { MapContainer, Marker } from "react-leaflet";
import { MapTileLayer, MapZoomHandler, MapEventHandler } from "../../../Map/components";

//styles
import { ReportMapWrap } from "./styles";

// redux
import { useSelector } from "react-redux";

const LiveCanopyReportVicinityMap = ({ setCanopyMapRefs }) => {
  const mapCenter = useSelector((state) => state.inputs.map_center);
  const [showControls, setShowControls] = useState(false);
  const [position, setPosition] = useState(mapCenter);
  const [zoom, setZoom] = useState(14);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [activeTileSet, setActivetileSet] = useState("Street Map");
  const [map, setMap] = useState(null);

  const markerRef = useRef();

  useEffect(() => {
    if (!map) return;
    setTimeout(() => {
      setCanopyMapRefs("vicinityMap", map);
    }, 500);
  }, [map]);

  const handleThirtyPercentZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleMarkerDrag = (e) => {
    console.log("e", e);
    // let pos = canopyVicinityMarker.current.leafletElement.getLatLng();
    // setPosition(pos);
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <MapContainer
        // whenCreated={setMap}
        whenReady={(map) => setMap(map.target)}
        id="vicinityMap"
        key="vicinityMap"
        className="vicinityMap"
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={mapCenter}
        zoom={zoom}
        minZoom={2}
        maxZoom={20}
        onzoomend={() => handleThirtyPercentZoom(map.getZoom())}
        zoomControl={false}
        zoomSnap={0.25}
        zoomDelta={0.25}
        style={{ width: "100%", height: "100%" }}
      >
        <MapTileLayer liveReportMap={true} tileLayer={"StreetMapHD"} />

        <MapZoomHandler position="topright" />
        <MapEventHandler marker_latlng={position} />

        <Marker ref={markerRef} draggable={true} eventHandlers={eventHandlers} position={position} />
      </MapContainer>
    </ReportMapWrap>
  );
};

export { LiveCanopyReportVicinityMap };
