//
// this is used for certain GLOBAL actions that should have side-effects on multiple reducers
//

export const siftConstants = {
  RESET_TO_DEFAULT: "RESET_TO_DEFAULT",

  NEW_PROJECT_REQUEST: "NEW_PROJECT_REQUEST",
  NEW_PROJECT_COMPLETE: "NEW_PROJECT_COMPLETE",

  SELECT_PROJECT: "SELECT_PROJECT",
  CLEAR_PROJECT: "CLEAR_PROJECT",
  LOAD_PROJECT_REQUEST: "LOAD_PROJECT_REQUEST",
  LOAD_PROJECT_DOWNLOADED: "LOAD_PROJECT_DOWNLOADED",
  LOAD_PROJECT_COMPLETE: "LOAD_PROJECT_COMPLETE",
  LOAD_PROJECT_FAILED: "LOAD_PROJECT_FAILED",

  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_COMPLETE: "DELETE_PROJECT_COMPLETE",
  DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",

  SAVE_PROJECT_REQUEST: "SAVE_PROJECT_REQUEST",
  SAVE_PROJECT_SUCCESS: "SAVE_PROJECT_SUCCESS",
  SAVE_PROJECT_COMPLETE: "SAVE_PROJECT_COMPLETE",
  SAVE_PROJECT_FAILURE: "SAVE_PROJECT_FAILURE",

  TOGGLE_PROJECTMANAGER_MODAL: "TOGGLE_PROJECTMANAGER_MODAL",
  TOGGLE_TOOLBAR_VISIBILITY: "TOGGLE_TOOLBAR_VISIBILITY",

  BUG_REPORT_REQUEST: "BUG_REPORT_REQUEST",
  BUG_REPORT_SUCCESS: "BUG_REPORT_SUCCESS",
  BUG_REPORT_COMPLETE: "BUG_REPORT_COMPLETE",
  BUG_REPORT_FAILURE: "BUG_REPORT_FAILURE",

  // map
  IMPORT_KMZ_REQUEST: "IMPORT_KMZ_REQUEST",
  IMPORT_KMZ_PROCESS: "IMPORT_KMZ_PROCESS",
  TOGGLE_ISDRAWING: "TOGGLE_ISDRAWING",
  CREATE_FEATURE: "CREATE_FEATURE",
  UPDATE_FEATURE: "UPDATE_FEATURE",
  DELETE_FEATURE: "DELETE_FEATURE",
  SELECT_FEATURE_ID: "SELECT_FEATURE_ID",
  UPDATE_TOTAL_AREA: "UPDATE_TOTAL_AREA",
  UPDATE_MAP_CENTER: "UPDATE_MAP_CENTER",
  CREATEDXF_REQUEST: "CREATEDXF_REQUEST",
  CREATEDXF_SUCCESS: "CREATEDXF_SUCCESS",
  CREATEDXF_FAILURE: "CREATEDXF_FAILURE",
  OFFSETPOLYGON_REQUEST: "OFFSETPOLYGON_REQUEST",
  OFFSETPOLYGON_SUCCESS: "OFFSETPOLYGON_SUCCESS",
  OFFSETPOLYGON_FAILURE: "OFFSETPOLYGON_FAILURE",

  // io manager
  UPDATE_INPUT: "UPDATE_INPUT",
  UPDATE_TAB: "UPDATE_TAB",
  UPDATE_FINANCE_TOGGLE: "UPDATE_FINANCE_TOGGLE",
  UPDATE_FINANCE_DEPRECIATION: "UPDATE_FINANCE_DEPRECIATION",
  UPDATE_UI_STATE: "UPDATE_UI_STATE",
  DROPDOWN_SELECT: "DROPDOWN_SELECT",
  GET_RUNID_REQUEST: "GET_RUNID_REQUEST",
  GET_RUNID_SUCCESS: "GET_RUNID_SUCCESS",
  GET_RUNID_FAILURE: "GET_RUNID_FAILURE",

  RUN_CAN_CANCEL: "RUN_CAN_CANCEL",

  CLOSE_ERROR_SIFT: "CLOSE_ERROR_SIFT",
  ERROR_SIFT: "ERROR_SIFT",

  FIX_WEATHER_INPUTS: "FIX_WEATHER_INPUTS",
  INIT_WITH_WEATHER_REQ: "INIT_WITH_WEATHER_REQ",
  INIT_WITH_WEATHER_SUCCESS: "INIT_WITH_WEATHER_SUCCESS",
  INIT_WITH_WEATHER_FAILURE: "INIT_WITH_WEATHER_FAILURE",

  INIT_RESULTS_REQUEST: "INIT_RESULTS_REQUEST",
  INIT_RESULTS_SUCCESS: "INIT_RESULTS_SUCCESS",
  INIT_RESULTS_ERROR: "INIT_RESULTS_ERROR",
  RUN_SIFT_REQUEST: "RUN_SIFT_REQUEST",
  RUN_SIFT_SUCCESS: "RUN_SIFT_SUCCESS",
  RUN_SIFT_ERROR: "RUN_SIFT_ERROR",
  GENERATE_RESULTS_REQUEST: "GENERATE_RESULTS_REQUEST",
  GENERATE_RESULTS_UPDATE: "GENERATE_RESULTS_UPDATE",
  GENERATE_RESULTS_COMPLETE: "GENERATE_RESULTS_COMPLETE",
  GENERATE_RESULTS_SUCCESS: "GENERATE_RESULTS_SUCCESS",
  GENERATE_RESULTS_VALIDATED: "GENERATE_RESULTS_VALIDATED",
  GENERATE_RESULTS_FAILURE: "GENERATE_RESULTS_FAILURE",
  CANCEL_RUN_REQUEST: "CANCEL_RUN_REQUEST",
  RESULT_SELECT: "RESULT_SELECT",
  GET_LAYOUT_REQUEST: "GET_LAYOUT_REQUEST",
  GET_LAYOUT_SUCCESS: "GET_LAYOUT_SUCCESS",
  GET_LAYOUT_FAILURE: "GET_LAYOUT_FAILURE",

  TOGGLE_IMPORT_VIS: "TOGGLE_IMPORT_VIS",
  IMPORTDATA_REQUEST: "IMPORTDATA_REQUEST",
  IMPORTDATA_SUCCESS: "IMPORTDATA_SUCCESS",
  IMPORTDATA_FAILURE: "IMPORTDATA_FAILURE",

  SAVEINPUT_REQUEST: "SAVEINPUT_REQUEST",
  SAVEINPUT_SUCCESS: "SAVEINPUT_SUCCESS",
  SAVEINPUT_FAILURE: "SAVEINPUT_FAILURE",

  PULL_MAP_LOC: "PULL_MAP_LOC",
  PULL_MAP_LOC_SUCCESS: "PULL_MAP_LOC_SUCCESS",
  EXPORT_MAP_STARTED: "EXPORT_MAP_STARTED",
  EXPORT_MAP_COMPLETED: "EXPORT_MAP_COMPLETED",
  EXPORT_MAP: "EXPORT_MAP",
  UPDATE_MARKER_REQ: "UPDATE_MARKER_REQ",
  UPDATE_MARKER: "UPDATE_MARKER",
  WEATHER_REQUEST: "WEATHER_REQUEST",
  WEATHER_SUCCESS: "WEATHER_SUCCESS",
  WEATHER_FAILURE: "WEATHER_FAILURE",
  WEATHER_DATA_SUCCESS: "WEATHER_DATA_SUCCESS",
  WEATHER_DATA_FAILURE: "WEATHER_DATA_FAILURE",
  CLEAR_WEATHER: "CLEAR_WEATHER",

  DOWNLOAD_FILES_REQUEST: "DOWNLOAD_FILES_REQUEST",
  DOWNLOAD_FILES_SUCCESS: "DOWNLOAD_FILES_SUCCESS",
  DOWNLOAD_FILES_FAILURE: "DOWNLOAD_FILES_FAILURE",
  LOG_DOWNLOAD: "LOG_DOWNLOAD",

  UPDATE_TOPO: "UPDATE_TOPO",
  CLEAR_TOPO_DATA: "CLEAR_TOPO_DATA",
  GET_TOPO_DATA_FAILURE: "GET_TOPO_DATA_FAILURE",
  GET_TOPO_ID_REQUEST: "GET_TOPO_ID_REQUEST",
  GET_TOPO_ID_SUCCESS: "GET_TOPO_ID_SUCCESS",
  GET_TOPO_ID_FAILURE: "GET_TOPO_ID_FAILURE",
  GET_TOPO_DATA_REQUEST: "GET_TOPO_DATA_REQUEST",
  GET_TOPO_DATA_SUCCESS: "GET_TOPO_DATA_SUCCESS",
  GET_TOPO_DATA_ERROR: "GET_TOPO_DATA_ERROR",
  UPDATE_TOPO_MODE: "UPDATE_TOPO_MODE",

  GET_TUTORIAL_REQUEST: "GET_TUTORIAL_REQUEST",
  GET_TUTORIAL_SUCCESS: "GET_TUTORIAL_SUCCESS",
  GET_TUTORIAL_COMPLETE: "GET_TUTORIAL_COMPLETE",
  GET_TUTORIAL_FAILURE: "GET_TUTORIAL_FAILURE",

  TOGGLE_COLLAB_MODAL: "TOGGLE_COLLAB_MODAL",
  SHARE_COLLAB_REQUEST: "SHARE_COLLAB_REQUEST",
  CHECK_COLLAB_REQUEST: "CHECK_COLLAB_REQUEST",
  COLLAB_SUCCESS: "COLLAB_SUCCESS",
  COLLAB_FAILURE: "COLLAB_FAILURE",

  REFRESH_COLLAB_REQUEST: "REFRESH_COLLAB_REQUEST",
  REFRESH_COLLAB_SUCCESS: "REFRESH_COLLAB_SUCCESS",

  TOGGLE_ACCOUNT_MODAL: "TOGGLE_ACCOUNT_MODAL",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  UPDATE_SCREEN_WIDTH: "UPDATE_SCREEN_WIDTH",
  TOGGLE_MOBILE_INPUTS: "TOGGLE_MOBILE_INPUTS",

  // UNDO_FEATURES: "UNDO_FEATURES",
  // REDO_FEATURES: "REDO_FEATURES",

  POLL_FOR_ALERTS: "POLL_FOR_ALERTS",
  PREPARE_REPORT: "PREPARE_REPORT",
  UPDATE_REPORT_DATA: "UPDATE_REPORT_DATA",

  // this is used to toggle off some shared UI states
  CANOPY_RUN_COMPLETED: "CANOPY_RUN_COMPLETED",
};
