import React from "react";
import cards from "./cards";
import { Button, Card, Col, Row, Modal, Spin, Alert, Empty } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined, CheckCircleOutlined, IssuesCloseOutlined, QuestionOutlined } from "@ant-design/icons";

class PlanManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan_stage: 0,
      selected_sub: 0,
    };
  }
  /* props needed 
    user - object
    products - object
    billing - object

    active - bool
    updating - bool

    onCancel - function
    onAddingCard - function
    onSubConfirmed - function
  */
  handleSelectSub = (sub) => {
    this.setState({ selected_sub: sub });
  };
  handleIncrementPage = () => {
    // SELECT SUB
    if (this.state.plan_stage == 0) {
      if (this.props.billing.length > 0) {
        this.setState({ plan_stage: 2 });
      } else {
        // no billing exists, go to payment method add page
        this.setState({ plan_stage: 1 });
      }
    }
    // ADD PAYMENT METHOD
    if (this.state.plan_stage == 1) {
      if (this.props.user.user_type == 3) {
        this.setState({ plan_stage: 0 });
      } else if (this.props.billing.length > 0) {
        this.setState({ plan_stage: 2 });
      }
    }
    // SUMMARY OF CHANGES
    if (this.state.plan_stage == 2) {
      if (this.props.billing.length > 0) {
        this.props.onSubConfirmed(this.state.selected_sub);
      }
    }
  };
  goBack = () => {
    this.setState({ plan_stage: 0 });
  };

  render() {
    const props = this.props;
    const { user, billing } = props;
    let productPlan = user.product_plan;
    let productPlanPaid = user.product_plan_paid;
    let defaultPayment = billing.length > 0 ? billing.filter((p) => p.is_default) : undefined;
    let hasPaymentMethod = billing.length > 0;

    return (
      <Modal
        title="Manage Plan"
        style={{ maxWidth: "1300px" }}
        width="80%"
        okText={
          user.email_verified == 0 ? (
            "Please Verify Email"
          ) : this.state.selected_sub == 0 ? (
            "Select a Plan asasd"
          ) : this.state.plan_stage == 2 ? (
            "Confirm"
          ) : (
            <span>
              Next <ArrowRightOutlined />
            </span>
          )
        }
        cancelText={
          this.state.plan_stage > 0 ? (
            <span>
              <ArrowLeftOutlined /> Back
            </span>
          ) : (
            "Cancel"
          )
        }
        // visible={this.props.active}
        open={this.props.active}
        onOk={this.handleIncrementPage}
        confirmLoading={this.props.updating}
        onCancel={this.state.plan_stage > 0 ? this.goBack : () => this.props.onCancel()}
        afterClose={() => this.setState({ plan_stage: 0, selected_sub: 0 })}
        okButtonProps={{ disabled: user.email_verified == 0 || this.state.selected_sub == 0 }}
      >
        {/* This modal has 3 possible screens
        0- Plan selection 
        1- Add payment method (if none available)
        2- Summary of plan selection

      */}
        <div style={{ height: 500 }}>
          {this.state.plan_stage == 0 ? (
            <Row gutter={16}>
              {this.props.products.map((product) => (
                <Col span={8} key={product.id}>
                  <Card
                    title={<div style={{ textAlign: "center", color: "var(--primary-brand-color)", fontSize: "15pt" }}>{product.name}</div>}
                    style={{ height: 500 }}
                    hoverable
                    bodyStyle={{ padding: "3%" }}
                  >
                    <ul>
                      {product.product_description.split(";").map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                    </ul>

                    <div className="plan-card-cost">
                      {user.free_trial_status == 0 && product.id > 1 && <div style={{ textAlign: "center", color: "#676767", fontSize: "14pt" }}>Begin 7 day Free Trial</div>}${product.monthly_cost}
                    </div>
                    <div className="plan-card-subtext">per month</div>

                    <Button
                      style={{ width: "94%", position: "absolute", bottom: 10, right: "3%" }}
                      type={this.state.selected_sub == product.id ? "primary" : "default"}
                      disabled={productPlan == product.id}
                      loading={this.props.updating}
                      onClick={() => {
                        this.handleSelectSub(product.id);
                      }}
                    >
                      {productPlan == product.id ? "CURRENT" : "SELECT"} {this.props.updating && <LoadingOutlined />}{" "}
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : this.state.plan_stage == 1 ? (
            // SELECT PAYMENT METHOD - EITHER NO PAYMENT METHODS EXIST OR NONE ARE DEFAULT
            <Row gutter={16}>
              {user.user_type == 3 ? (
                <div style={{ paddingBottom: "10px" }}>
                  <Alert
                    // message="Notice"
                    description="Your account is paid for and managed by your organization, no payment method is required. Your plan features are shown to the left, upgrading or downgrading your plan is disabled. For inquiries about upgrading SIFT to a higher plan, speak with your organization's SIFT accounts manager."
                    type="info"
                    showIcon
                  />
                </div>
              ) : !hasPaymentMethod ? (
                <div style={{ overflowY: "auto", height: "475px" }}>
                  {billing.length == 0 && (
                    <Spin spinning={this.props.updating}>
                      <Empty description="No Payment Method Added">
                        <Button type="primary" loading={this.props.updating} onClick={() => this.props.onAddingCard(true)}>
                          Add Payment Method
                        </Button>
                      </Empty>
                    </Spin>
                  )}
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <span>
                    <CheckCircleOutlined theme="twoTone" twoToneColor="#52c41a" /> Payment Added!
                  </span>
                </div>
              )}
            </Row>
          ) : this.state.plan_stage == 2 ? (
            <Row>
              <div>
                <h3>Summary of Changes</h3>

                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> You've selected the <b>{this.props.products[this.state.selected_sub - 1].name}</b> plan at{" "}
                    <b>${this.props.products[this.state.selected_sub - 1].monthly_cost}</b> per month.
                  </span>
                </div>

                {/* UPGRADING TO A NEW PLAN OR TRIAL IS ACTIVE */}
                {(this.state.selected_sub > productPlan && this.state.selected_sub > productPlanPaid) || user.free_trial_status == 1 || user.free_trial_status == 3 ? (
                  <div className="summary-text">
                    {user.free_trial_status == 0 ? (
                      <span>
                        <IssuesCloseOutlined /> You are activating your SIFT Free Trial, which will expire in 7 days. You will be charged at the end of your trial.
                      </span>
                    ) : user.free_trial_status == 1 || user.free_trial_status == 3 ? (
                      <span>
                        <IssuesCloseOutlined /> Your SIFT Free Trial is still active and will expire on {user.free_trial_end_dt}.{" "}
                        {this.state.selected_sub > 1 && <span>You will be charged at the end of your trial.</span>}
                      </span>
                    ) : user.free_trial_status == 2 && productPlanPaid == 2 && this.state.selected_sub == 3 ? (
                      <span>
                        <IssuesCloseOutlined /> You will be charged a prorated{" "}
                        <b>
                          ${(user.days_remaining_in_cycle * (this.props.products[this.state.selected_sub - 1].monthly_cost / 30 - this.props.products[productPlan - 1].monthly_cost / 30)).toFixed(0)}{" "}
                          now
                        </b>{" "}
                        for the remainder of this billing cycle.
                      </span>
                    ) : (
                      <span>
                        <IssuesCloseOutlined /> You will be charged <b>${this.props.products[this.state.selected_sub - 1].monthly_cost}</b> today.
                      </span>
                    )}
                  </div>
                ) : // DOWNGRADING PLAN (Already paid)
                productPlanPaid >= this.state.selected_sub ? (
                  <div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> <b>{this.props.products[productPlanPaid - 1].name}</b> plan is paid and active for the remainder of the billing cycle.
                      </span>
                    </div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> Your plan will change to <b>{this.props.products[this.state.selected_sub - 1].name}</b> on {user.plan_charge_dt}.
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="summary-text">
                    <span>
                      <IssuesCloseOutlined /> You are downgrading, this change will take effect next billing cycle ({user.plan_charge_dt}).
                    </span>
                  </div>
                )}
                {this.state.selected_sub > 1 && (
                  <div className="summary-text">
                    <span>
                      <IssuesCloseOutlined /> Each billing cycle covers one month of SIFT service.{" "}
                    </span>
                  </div>
                )}
                {this.state.selected_sub > 1 && (
                  <div className="summary-text">
                    <span>
                      <IssuesCloseOutlined /> Cancel future charges any time by downgrading to a FREE plan.{" "}
                    </span>
                  </div>
                )}
              </div>

              <div className="plan-overview">
                <div>
                  <span>
                    <div className="payment-card summary">
                      <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>This is the payment method you have selected:</div>
                      <div className="payment-text-holder">
                        <div className="payment-text">
                          {defaultPayment[0].brand} (**** {defaultPayment[0].last4})
                        </div>
                        <div className="payment-text">
                          Expiration Date {defaultPayment[0].exp_month}/{defaultPayment[0].exp_year}
                        </div>
                      </div>
                      <div>
                        {cards.findIndex((card) => card.name == defaultPayment[0].brand) > -1 ? (
                          <img src={cards[cards.findIndex((card) => card.name == defaultPayment[0].brand)].icon} />
                        ) : (
                          <QuestionOutlined />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
                {/* PLAN OVERVIEW  */}
                <div style={{ width: "48%" }}>
                  <Alert
                    message="Plan Overview"
                    type="success"
                    showIcon
                    description={
                      <span>
                        <ul>
                          {this.props.products[this.state.selected_sub - 1].product_description.split(";").map((desc, index) => (
                            <li key={index}>{desc}</li>
                          ))}
                        </ul>
                      </span>
                    }
                  />
                </div>
              </div>
            </Row>
          ) : (
            <div></div>
          )}
        </div>
      </Modal>
    );
  }
}

export { PlanManager };
