import { tutorialConstants } from '../_constants';

export const tutorialActions = {
  startTutorial,
  startTour,
  runExample,
  startExampleTut,
  navigateTutorial,
  resetTutorial,
  showTutorialFunnel,
  endTutorial,
};

function startTutorial() {
  return {
    type: tutorialConstants.START_TUTORIAL,
  };
}

function startTour() {
  return {
    type: tutorialConstants.START_TOUR,
  };
}

function runExample() {
  return {
    type: tutorialConstants.RUN_EXAMPLE,
  };
}

function startExampleTut() {
  return {
    type: tutorialConstants.START_EXAMPLE_TUT,
  };
}

function navigateTutorial(direction) {
  return {
    type: tutorialConstants.NAVIGATE_TUTORIAL,
    direction,
  };
}

function resetTutorial() {
  return {
    type: tutorialConstants.RESET_TUTORIAL,
  };
}

function showTutorialFunnel() {
  return {
    type: tutorialConstants.SHOW_TUTORIAL_FUNNEL,
  };
}

function endTutorial() {
  return {
    type: tutorialConstants.END_TUTORIAL,
  };
}

function showTutorialNav(bool) {
  return {
    type: tutorialConstants.SHOW_TUTORIAL_NAV,
    bool,
  };
}
