import React, { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";
import { usePrevious } from "../../Redux";
import { useSelector } from "react-redux";
// import { down_pointer } from "../../../assets/images";

const ShadeObjectMarker = ({ id = undefined }) => {
  const map = useMap();
  // shade marker pos is actually the objects id. This had to change because of the way highlighting now works.
  // Instead of displaying a map marker, we are just highlighting the map layer
  const shade_marker_pos = useSelector((state) => state.canopy.shade_marker_pos);

  const prev_shade_marker_pos = usePrevious(shade_marker_pos);

  useEffect(() => {
    // Unhighlight if shade_marker_pos is undefined or changed
    if (!shade_marker_pos || prev_shade_marker_pos !== shade_marker_pos) {
      map.eachLayer((layer) => {
        if (layer?.options && layer?.options?.obj_type == "shade_obj") {
          layer.setStyle({ weight: 2, fillOpacity: 0.1 });
        }
      });
    }

    // Highlight the layer if shade_marker_pos is defined
    if (shade_marker_pos) {
      let layer = getLayer(shade_marker_pos);
      if (layer && layer?.options?.obj_type == "shade_obj") {
        layer.setStyle({ weight: 3, fillOpacity: 0.5 });
      }
    }
  }, [shade_marker_pos]);

  const getLayer = (id) => {
    let temp_layer;
    map.eachLayer((layer) => {
      if (layer?.options && layer?.options?.obj_type == "shade_obj" && layer.options.id == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  // const cust_icon = L.icon({
  //   iconUrl: down_pointer,
  //   iconSize: [32, 32],
  //   iconAnchor: [16, 32],
  //   popupAnchor: null,
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  return null;

  // return <>{shade_marker_pos && <Marker icon={cust_icon} position={[shade_marker_pos.lat, shade_marker_pos.lng]} />}</>;
};
export { ShadeObjectMarker };
