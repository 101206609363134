import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserActions, authActions, siftActions, inputsActions, simple_inverter, default_inverter_inputs } from "../../../Redux";

import { Skeleton, Input, Divider, Button, Modal, Popconfirm, message, Radio, Checkbox, Switch } from "antd";
import { DiffOutlined } from "@ant-design/icons";
import { getAgreement, Agreement } from "../../../../components/Auth/agreement";
const { TextArea } = Input;

import config from "config";
import fetchUtil from "../../../Redux/_services/fetchUtil";

const styles = {
  root: {
    display: "inline-block",
    borderRadius: 3,
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.85)",
    lineHeight: "30px",
    width: "500px",
    paddingTop: "15px",
  },
  content: {
    left: "30px",
    position: "relative",
  },
  skeleton: {
    padding: "7%",
  },
  input: {
    width: "128px",
    invoice: {
      width: "175px",
    },
  },
  label: {
    display: "inline-block",
    width: "75px",
    textAlign: "right",
    paddingRight: "5px",
  },
  save: {
    left: "373px",
    top: "51px",
    // position: 'absolute',
    zIndex: 100,
  },
};
const agreement = (
  <div style={{ height: 500, overflowY: "auto" }}>
    <div dangerouslySetInnerHTML={getAgreement()} />
  </div>
);

function show_agreement() {
  Modal.success({
    title: "Terms and Conditions",
    content: agreement,
    footer: null,
    width: "80%",
    className: "sift-modal",
    style: { maxWidth: "1300px" },
    icon: null,
    maskClosable: true,
    closable: true,
    okText: "Close",
    okType: "ghost",
  });
}
function password_reset(email) {
  const requestOptions = {
    method: "POST",
    //headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
    }),
  };
  // fetch(`${config.apiUrl}/api/password_reset/`, requestOptions).then(handleResponse);
  fetchUtil(`${config.apiUrl}/api/password_reset/`, requestOptions, false, handleResponse);
}
function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      message.error("Error requesting reset.", 2.5);
      return Promise.reject(error);
    } else {
      message.success("Please check your email for reset link.", 2.5);
    }
    return data;
  });
}

const UserSettings = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const platform = useSelector((state) => state.user.platform);

  const [saving_user, set_saving_user] = useState(false);

  const [local_user, set_local_user] = useState({
    invoice_name: user ? user.invoice_name : undefined,
    invoice_company: user ? user.company : undefined,
    invoice_address: user ? user.invoice_address : undefined,
    default_lat: user ? user.map_center[0] : undefined,
    default_lng: user ? user.map_center[1] : undefined,
    use_random_map_location: user ? user.use_random_map_location : undefined,
    platform: platform,
    en_target_inputs: user ? user.en_target_inputs : undefined,
  });

  useEffect(() => {
    if (!user) return;
    set_local_user({
      invoice_name: user.invoice_name,
      invoice_company: user.company,
      invoice_address: user.invoice_address,
      default_lat: user.map_center[0],
      default_lng: user.map_center[1],
      use_random_map_location: user.use_random_map_location,
      platform: platform,
      en_target_inputs: user.en_target_inputs,
    });
  }, [user]);

  const handleLogOut = () => {
    dispatch(authActions.logout());
  };

  const handleSaveChanges = () => {
    dispatch(UserActions.updateUserData(local_user));
    set_saving_user(true);
    setTimeout(() => {
      let bulk_inputs = local_user.en_target_inputs == 1 ? { ...JSON.parse(JSON.stringify(simple_inverter)) } : { ...JSON.parse(JSON.stringify(default_inverter_inputs)) };
      dispatch(inputsActions.update_bulk_inputs(bulk_inputs));
      set_saving_user(false);
    }, 2000);
  };

  const handleInputUpdate = (key, value) => {
    if (key == "default_lat" || key == "default_lng") {
      let changed_value = value.trimEnd().replace("°", "");
      set_local_user({ ...local_user, [key]: changed_value });
    } else {
      set_local_user({ ...local_user, [key]: value });
    }
  };
  // const { user, local_user } = props;

  // const [local_user, set_local_user] = useState({
  //   invoice_name: user ? user.invoice_name : undefined,
  //   invoice_company: user ? user.company : undefined,
  //   invoice_address: user ? user.invoice_address : undefined,
  //   default_lat: user ? user.map_center[0] : undefined,
  //   default_lng: user ? user.map_center[1] : undefined,
  //   use_random_map_location: user ? user.use_random_map_location : undefined,
  //   platform: platform,
  //   en_target_inputs: user ? user.en_target_inputs : undefined,
  // });

  // useEffect(() => {
  //   if (!user) return;
  //   set_local_user({
  //     invoice_name: user.invoice_name,
  //     invoice_company: user.company,
  //     invoice_address: user.invoice_address,
  //     default_lat: user.map_center[0],
  //     default_lng: user.map_center[1],
  //     use_random_map_location: user.use_random_map_location,
  //     platform: platform,
  //     en_target_inputs: user.en_target_inputs,
  //   });
  // }, [user]);

  let loaded = false;
  if (user) {
    loaded = true;
  }

  if (!loaded) {
    return (
      <div style={styles.skeleton}>
        <Skeleton active />
      </div>
    );
  } else {
    return (
      <div style={styles.root}>
        <div style={styles.save}>
          <Button
            type="primary"
            ghost
            icon={<DiffOutlined />}
            size="small"
            onClick={() => {
              handleSaveChanges();
            }}
            loading={saving_user}
          >
            Save Changes
          </Button>
        </div>

        <Divider className="account" orientation="left">
          General
        </Divider>
        <div style={styles.content}>
          <div>
            <label>
              You're logged in as: <span>{user.email}</span>
            </label>
          </div>
          <div>
            <span>
              <a
                onClick={() => {
                  show_agreement();
                }}
              >
                Terms and Conditions
              </a>
              {" - "}
              <Popconfirm title="Reset Password?" onConfirm={() => password_reset(user.email)} okText="Yes" cancelText="No">
                <a>Reset Password</a>
              </Popconfirm>
              {" - "}
              <Popconfirm title="Log Out?" onConfirm={() => handleLogOut()} okText="Yes" cancelText="No">
                <a>Log Out</a>
              </Popconfirm>
            </span>
          </div>
        </div>
        <Divider className="account" orientation="left">
          Map Defaults and Settings
        </Divider>
        <div style={styles.content}>
          <span style={{ ...styles.label, paddingRight: "4px" }}>
            <label>Map Center</label>
          </span>
          <Radio.Group
            style={{ marginLeft: "auto" }}
            value={local_user.use_random_map_location}
            // disabled={rackType == 2}
            buttonStyle="solid"
            size="small"
            onChange={(event) => {
              handleInputUpdate(event.target.id, event.target.value);
            }}
          >
            <Radio.Button id="use_random_map_location" value={true}>
              Random
            </Radio.Button>
            <Radio.Button id="use_random_map_location" value={false}>
              User Defined
            </Radio.Button>
          </Radio.Group>
        </div>
        <div style={styles.content}>
          <span style={styles.label}>
            <label>Latitude</label>
          </span>
          <Input
            id="default_lat"
            onChange={(event) => {
              handleInputUpdate(event.target.id, event.target.value);
            }}
            defaultValue={local_user.default_lat}
            addonAfter={"°"}
            size="small"
            style={styles.input}
            disabled={local_user.use_random_map_location}
          />
        </div>

        <div style={styles.content}>
          <span>
            <label style={styles.label}>Longitude</label>
            <Input
              id="default_lng"
              onChange={(event) => {
                handleInputUpdate(event.target.id, event.target.value);
              }}
              defaultValue={local_user.default_lng}
              addonAfter={"°"}
              size="small"
              style={styles.input}
              disabled={local_user.use_random_map_location}
            />
          </span>
        </div>

        {/* <div style={styles.content}>
          <label>Enable Config Target Inputs</label>
          <Switch
            style={{ marginLeft: 5 }}
            size="small"
            id="en_target_inputs"
            checked={local_user.en_target_inputs == 1}
            onChange={(checked) => {
           handleInputUpdate("en_target_inputs", checked ? true : false);
            }}
          />
        </div> */}

        <Divider className="account" orientation="left">
          Invoice
        </Divider>
        <div style={styles.content}>
          <span style={styles.label}>
            <label>Name</label>
          </span>
          <Input
            id="invoice_name"
            onChange={(event) => {
              handleInputUpdate(event.target.id, event.target.value);
            }}
            value={local_user.invoice_name}
            size="small"
            style={styles.input.invoice}
          />
        </div>
        <div style={styles.content}>
          <span>
            <label style={styles.label}>Company</label>
            <Input
              id="invoice_company"
              onChange={(event) => {
                handleInputUpdate(event.target.id, event.target.value);
              }}
              value={local_user.invoice_company}
              size="small"
              style={styles.input.invoice}
            />
          </span>
        </div>
        <div style={styles.content}>
          <span>
            <label style={styles.label}>Address</label>
            <TextArea
              id="invoice_address"
              rows={2}
              onChange={(event) => {
                handleInputUpdate(event.target.id, event.target.value);
              }}
              value={local_user.invoice_address}
              size="small"
              style={styles.input.invoice}
            />
          </span>
        </div>
      </div>
    );
  }
};

export { UserSettings };
