import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { TopoScaleWrap } from "./styles";

const TopoScaleLayer = ({ topo_scale_url }) => {
  const topo_scale_ref = useRef();
  const screen_width = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  useEffect(() => {
    L.DomEvent.disableClickPropagation(topo_scale_ref.current);
  }, []);

  useEffect(() => {
    dragStuff();
  }, []);

  useEffect(() => {
    dragStuff();
  }, [screen_width]);

  const dragStuff = () => {
    let topo_scale_box = document.getElementById("topo-scale-box");
    // get the bounds of each item
    // let topo_scale_box_bounds = topo_scale_box.getBoundingClientRect();
    let map_edge_bounds = document.getElementById("map").getBoundingClientRect();

    // console.log("topo bounds", topo_scale_box_bounds);
    // console.log("map_edge_bounds", map_edge_bounds);
    dragElement(topo_scale_box);

    function dragElement(topo_scale_box) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(topo_scale_box.id + "-header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(topo_scale_box.id + "-header").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        topo_scale_box.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // console.log("pos1", pos1);
        // console.log("pos2", pos2);
        // console.log("pos3", pos3);
        // console.log("pos4", pos4);

        if (pos3 >= map_edge_bounds.left && pos3 <= map_edge_bounds.right && pos4 >= map_edge_bounds.top && pos4 <= map_edge_bounds.bottom) {
          // set the element's new position:
          topo_scale_box.style.top = topo_scale_box.offsetTop - pos2 + "px";
          topo_scale_box.style.left = topo_scale_box.offsetLeft - pos1 + "px";
        }
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  };

  return (
    <TopoScaleWrap id="topo-scale-box" ref={topo_scale_ref}>
      {/* <section className="topo-scale-box-header">Click here to move</section> */}
      <img src={topo_scale_url} />
    </TopoScaleWrap>
  );
};

export { TopoScaleLayer };
