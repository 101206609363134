import styled from "styled-components";

const ToolbarWrap = styled.section`
  box-sizing: border-box;
  width: ${(props) => (props.vertical ? "30px" : "min-content")};
  height: auto;
  background-color: #fff;
  padding: 0px 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s var(--easing-spring);
  overflow: hidden;
  zindex: 405;

  .map-action-buttons {
    display: flex;
    flex-direction: ${(props) => (props.vertical ? "column" : "row")};
    align-items: center;
    height: ${(props) => (props.vertical ? "auto" : "40px")};
    background-color: #fff;
    transition: all 0.3s var(--easing-spring);
    z-index: 10;

    button {
      width: 30px;
      height: 30px;
      background: #fff;
      border: 1px solid transparent;
      border-radius: 2px;
      margin-right: ${(props) => (props.vertical ? "0px" : "2px")};
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s var(--easing-spring);

      img {
        width: ${(props) => (props.vertical ? "16px" : "20px")};
        height: ${(props) => (props.vertical ? "16px" : "20px")};
      }

      &:hover {
        cursor: pointer;
        background: var(--background-highlight-color);
      }

      &:hover > img {
        filter: invert(100%);
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .button-active {
      background: var(--background-highlight-color);
      img {
        filter: invert(100%);
      }
    }

    .identity-button {
      &:hover {
        background: var(--background-highlight-color);
        img {
          filter: none;
        }
      }
    }

    .undo-redo {
      display: flex;
      align-items: center;
      .undo,
      .redo {
        width: 15px;
        height: 15px;
      }
    }

    .vert-line {
      height: 28px;
      width: 2px;
      margin: 0px 5px;
      background-color: var(--gutter-background-color);
    }
  }

  .map-action-tray {
    height: 0;
    transition: height 0.3s var(--easing-spring);
    z-index: 9;
  }

  .expand-down {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    height: ${(props) => `${props.expand_height}px`};
    transition: height 0.3s var(--easing-spring);
  }
`;

const LegendWrap = styled.section`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-template-rows: 1fr;
  gap: 0px 15px;
  background-color: #fff;
  padding: 0px 10px;
  border-radius: 3px;
  transition: all 0.3s var(--easing-spring);
  overflow: hidden;

  .legend-row {
    display: flex;
    align-items: center;

    section {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }

    p {
      white-space: nowrap;
    }
  }
`;

export { ToolbarWrap, LegendWrap };
