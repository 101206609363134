import { authConstants } from "../_constants";
import jwtDecode from "jwt-decode";

let user = JSON.parse(localStorage.getItem("user")) || undefined;

let initial_state = {
  loggingIn: false,
  loggedIn: false,
  registering: false,

  access: {},
  token: {},
  error: undefined,
  login_error: undefined,
  register_error: [],
  pw_reset_msg: undefined,
  login_visible: false,
  login_tab: "signup",
  refresh_visible: false,
  show_agreement_modal: false,
  show_terms: false,
  openedViaAgreegmentUpdate: false,
};

const initialState = user ? user : initial_state;

// console.log('init_state', initialState)

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_VISIBILITY_TOGGLE:
      return {
        ...state,
        login_visible: action.visible,
        login_tab: action.tab,
      };
    case authConstants.LOGIN_TAB_TOGGLE:
      return {
        ...state,
        login_tab: action.tab,
      };

    case authConstants.REFRESH_VISIBILITY_TOGGLE:
      return { ...state, refresh_visible: action.show };

    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        error: false,
        login_error: undefined,
        loggingIn: true,
      };

    // TODO: Deprecated
    case authConstants.LOGIN_SUCCESS:
      // console.log('LOGIN_SUCCESS', action)
      // let remember = localStorage.getItem('remember');
      // let user = {
      //   ...state,
      //   loggingIn: false,
      //   loggedIn: true,
      //   login_visible: false,
      //   forward: action.forward,
      //   access: {
      //     token: action.user.access,
      //     ...jwtDecode(action.user.access)
      //   },
      //   refresh: {
      //     token: action.user.refresh,
      //     ...jwtDecode(action.user.refresh)
      //   },
      //   remember: remember
      // }
      // // store user object for later
      // localStorage.setItem('user', JSON.stringify(user));
      return { ...action.user, show_agreement_modal: false, show_terms: false, openedViaAgreegmentUpdate: false };

    case authConstants.CONTINUE_LOGIN:
      // console.log('continue login', state.show_terms)
      localStorage.setItem("user", JSON.stringify(action.user));
      return {
        ...action.user,
        // show_agreement_modal: !action.user.terrasmart_terms,
        // show_terms: state.show_terms,
        // openedViaAgreegmentUpdate: state.openedViaAgreegmentUpdate,
      };

    case authConstants.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        error: false,
        login_error: undefined,
        refresh_visible: false,
      };

    case authConstants.REFRESH_TOKEN_SUCCESS:
      let refreshUser = {
        ...state,
        loggingIn: false,
        loggedIn: true,
        error: false,
        login_error: undefined,
        access: {
          token: action.access.access,
          ...jwtDecode(action.access.access),
        },
        refresh: {
          token: action.refresh,
          ...jwtDecode(action.refresh),
        },
      };
      // store user object for later
      localStorage.setItem("user", JSON.stringify(refreshUser));
      return refreshUser;
    case authConstants.REFRESH_TOKEN_FAILURE:
      return {};
    case authConstants.LOGIN_FAILURE:
      let error = action.error;
      return {
        ...state,
        error: true,
        login_error: error,
        loggingIn: false,
      };
    case authConstants.LOGOUT:
      return {};

    case authConstants.REGISTER_REQUEST:
      return { ...state, registering: true, register_error: [] };

    case authConstants.REGISTER_SUCCESS:
      return { ...state, registering: false };

    case authConstants.REGISTER_FAILURE:
      let email_errors = (action.error.email && action.error.email) || [];
      let password1_errors = (action.error.password1 && action.error.password1) || [];
      let reg_errors = [...email_errors, ...password1_errors];

      return { ...state, registering: false, loggingIn: false, register_error: reg_errors };

    // "A user is already registered with this e-mail address."
    // "The two password fields didn't match."
    // "This password is too short. It must contain at least 8 characters."
    // "This password is too common."
    // "This password is entirely numeric."

    case authConstants.PWRESET_REQUEST:
      return { ...state, loggingIn: true, pw_reset_msg: undefined };

    case authConstants.PWRESET_SUCCESS:
      return { ...state, loggingIn: false, pw_reset_msg: "Please check your email for reset link." };

    case authConstants.PWRESET_FAILURE:
      return { ...state, loggingIn: false, pw_reset_msg: "Error requesting reset." };

    case authConstants.TOGGLE_NEW_AGREEMENT_MODAL:
      return { ...state, show_agreement_modal: action.visible };

    case authConstants.SHOW_TERMS:
      // console.log('SHOW_TERMS', state.show_terms, state.openedViaAgreegmentUpdate)
      return {
        ...state,
        show_terms: action.visible,
        openedViaAgreegmentUpdate: action.openedFromAgreementUpdate,
        show_agreement_modal: state.show_terms && state.openedViaAgreegmentUpdate,
        show_declined_modal: false,
      };

    case authConstants.AGREEMENT_DECLINED:
      return { ...state, show_agreement_modal: false, show_terms: false, openedViaAgreegmentUpdate: false };

    case authConstants.AGREEMENT_ACCEPTED:
      return { ...state, show_agreement_modal: false, show_terms: false, openedViaAgreegmentUpdate: false };

    default:
      return state;
  }
}

export function accessToken(state) {
  if (state.access) {
    return state.access.token;
  }
}

export function isAccessTokenExpired(state) {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - new Date().getTime() < 5000;
  }
  return true;
}

export function refreshToken(state) {
  if (state.refresh) {
    return state.refresh.token;
  }
  return false;
}

export function isRefreshTokenExpired(state) {
  if (state.refresh && state.refresh.exp) {
    // console.log(`Current time left ${1000* state.refresh.exp - (new Date()).getTime()}`)
    return 1000 * state.refresh.exp - new Date().getTime() < 5000;
  }
  return true;
}
export function isAccessValid(state) {
  if (state.access && state.access.exp) {
    // console.log(`Current time left on access ${1000* state.access.exp - (new Date()).getTime()}`)
    // return false when token expires in less than 10m
    return 1000 * state.access.exp - new Date().getTime() > 600000;
  }
  return false;
}
export function isRefreshValid(state) {
  if (state.refresh && state.refresh.exp) {
    // console.log(`Current time left on refresh ${1000 * state.refresh.exp - new Date().getTime()}`);
    // return false when token expires in less than 12h
    return 1000 * state.refresh.exp - new Date().getTime() > 43200000;
  }
  return false;
}

export function isAuthenticated(state) {
  // return !isRefreshTokenExpired(state)
  // return isAccessValid(state) && isRefreshValid(state);
  return isRefreshValid(state);
}

export function errors(state) {
  return state.errors;
}
