export const canopyConstants = {
  UPDATE_CANOPY_INPUT: "UPDATE_CANOPY_INPUT",
  UPDATE_CANOPY_BULK_INPUTS: "UPDATE_CANOPY_BULK_INPUTS",
  UPDATE_CANOPY_PROPERTY: "UPDATE_CANOPY_PROPERTY",
  RUN_CANOPY: "RUN_CANOPY",
  RUN_CANOPY_COMPLETE: "RUN_CANOPY_COMPLETE",
  APPLY_GLOBAL_OVERRIDE: "APPLY_GLOBAL_OVERRIDE",
  DELETE_CANOPY: "DELETE_CANOPY",
  UPDATE_IMPORTED_IMAGES: "UPDATE_IMPORTED_IMAGES",
  NEW_PROJECT: "NEW_PROJECT",
  LOAD_CANOPY_PROJECT: "LOAD_CANOPY_PROJECT",
  UPDATE_CANOPY_RUN: "UPDATE_CANOPY_RUN",
  UPDATE_SHADE_OBJECT: "UPDATE_SHADE_OBJECT",
  RUN_SHADE_REQUEST: "RUN_SHADE_REQUEST",
  RUN_SHADE_COMPLETE: "RUN_SHADE_COMPLETE",
  RUN_SHADE_CANCEL: "RUN_SHADE_CANCEL",
  UPDATE_CANOPY_TOOLBAR: "UPDATE_CANOPY_TOOLBAR",
};
