import React, { useState, useEffect } from "react";
import { ThirtyPercentFormLayout } from "./styles";

// components
import { ThirtyPercentInput, ThirtyPercentSelect } from "./ThirtyPercentInputs";
import { Spinner } from "../../../../Spinner";

const thirty_percent_inputs = [
  { id: "customer_for", label_text: "For" },
  { id: "customer_at", label_text: "At" },
  { id: "address_one", label_text: "Address Line 1" },
  { id: "address_two", label_text: "Address Line 2" },
  { id: "project_number", label_text: "Project Number" },
  { id: "drawn_by", label_text: "Drawn by Initials" },
  { id: "reviewed_by", label_text: "Reviewed by Initials" },
  {
    id: "array_type",
    label_text: "Array Type",
    options: {
      0: "CP-T",
      1: "CP-LS",
      2: "CP-Truss",
    },
  },
];

const ThirtyPercentForm = ({ setFormToggle, saveThirtyPercentInputs, thirtyPercentInputValues }) => {
  const [input_values, set_input_values] = useState({ ...thirtyPercentInputValues });
  const [saving, setSaving] = useState(false);

  const updateThirtyPercentInputs = (key, value) => {
    set_input_values({ ...input_values, [key]: value });
  };

  const saveInputUpdates = () => {
    setSaving(true);
    setTimeout(() => {
      saveThirtyPercentInputs("thirtyPercentInputs", input_values);
      setFormToggle(false);
      setSaving(false);
    }, 2000);
  };

  const cancelInputUpdates = () => {
    set_input_values({ ...thirtyPercentInputValues });
    setFormToggle(false);
  };

  return (
    <ThirtyPercentFormLayout>
      <section className="thirty-percent-form">
        <section className="thiry-percent-title">
          <h3>30% Set Input Fields</h3>
        </section>
        <section className="thirty-percent-form-body">
          {thirty_percent_inputs.map((input) => {
            if (input.id == "array_type") {
              return (
                <ThirtyPercentSelect
                  key={input.id}
                  id={input.id}
                  labelText={input.label_text}
                  input_value={input_values[input.id]}
                  options={input.options}
                  updateThirtyPercentInputs={updateThirtyPercentInputs}
                />
              );
            } else {
              return <ThirtyPercentInput key={input.id} id={input.id} labelText={input.label_text} input_value={input_values[input.id]} updateThirtyPercentInputs={updateThirtyPercentInputs} />;
            }
          })}
        </section>
        <section className="thirty-percent-buttons">
          <button className="cancel-tp-button" onClick={() => cancelInputUpdates()}>
            Cancel
          </button>
          <button className="save-tp-button" onClick={() => saveInputUpdates()}>
            Save
            {saving && <Spinner marginLeft={"5px"} marginRight={"2px"} />}
          </button>
        </section>
      </section>
    </ThirtyPercentFormLayout>
  );
};

export { ThirtyPercentForm };
