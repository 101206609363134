import React, { useState, useEffect } from "react";

import { LabelWrap } from "./styles";

const Checkbox = ({ label, value, onChange, disabled = false, labelPosition = "right" }) => {
  return (
    <LabelWrap>
      <input type="checkbox" disabled={disabled} checked={value} labelPosition={labelPosition} onChange={onChange} />
      {label}
    </LabelWrap>
  );
};

export { Checkbox };
