import React, { useState, useEffect } from "react";
import { canopyActions } from "../../../../../Redux";
import { SelectRow, RowDivider, InputRow } from "./styles";
import {Button, Menu, Dropdown, Select} from "antd";
import { DownOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import {value} from "lodash/seq";

const prettifyText = (text) => {
  // takes in the ugly text from the product data planeType and makes it a little more presentable
  switch (text) {
    case "single_slope":
      return "Single Slope";
    case "inverted":
      return "Inverted";
    case "dual_tilt":
      return "Dual Tilt";
    default:
      return "";
  }
};

const getOrientation = (value) => {
  switch (value) {
    case 1:
      return "Portrait";
    case 2:
      return "Landscape";
    default:
      return "";
  }
};

const global_override_inputs = [
  { var_n: "module", label: "Module", prefix: "" },
  { var_n: "orientation", label: "Orientation", prefix: "" },
  { var_n: "modY", label: "Modules Wide", prefix: "mod" },
  { var_n: "modX", label: "Modules Long", prefix: "mod" },
  { var_n: "mod_height", label: "Module Height", prefix: "m" },
  { var_n: "mod_width", label: "Module Width", prefix: "m" },
  { var_n: "modXGap", label: "Modules, Gap", prefix: "m" },
  { var_n: "azimuth", label: "Azimuth", prefix: "°" },
  { var_n: "planeType", label: "Plane Type", prefix: "" },
  { var_n: "tilt", label: "Tilt", prefix: "°" },
];

// const InputOverlay = ({ set_selected_input }) => {
//   const onSelectInput = (e) => {
//     set_selected_input(global_override_inputs[e.key]);
//   };
//
//   return (
//     <Menu style={{ height: "300px", overflowY: "auto" }} onClick={onSelectInput}>
//       {Object.values(global_override_inputs).map((item, index) => (
//         <Menu.Item key={index} eventKey={index}>
//           {item.label}
//         </Menu.Item>
//       ))}
//     </Menu>
//   );
// };
const GlobalOverrides = () => {
  const dispatch = useDispatch();
  const [selected_input, set_selected_input] = useState({ label: "Select Input" });

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const canopy = useSelector((state) => state.canopy.canopies[selected_canopy]);

  const handleGOClick = () => {
    if (selected_input.var_n === undefined) return;
    dispatch(canopyActions.applyGlobalOverride(selected_input.var_n));
    set_selected_input({ label: "Select Input" });
  };

  const items = [];
  Object.values(global_override_inputs).map((item, index) => (
      items.push({ label : item.label , value : index})
  ));
  const onSelectInput = (value) => {
    set_selected_input(global_override_inputs[value]);
  };
  // const menuProps = {
  //   items,
  //   onClick: onSelectInput,
  // };

  return (
    <>
      <RowDivider />
      <section style={{ marginLeft: 5 }}>
        <p>Global Override</p>
      </section>
      <InputRow>
        {/*<Dropdown menu={menuProps} placement="bottom" trigger={["click"]}>*/}
        {/*  <Button style={{ width: "225px" }}>*/}
        {/*    {selected_input.label}*/}
        {/*    <DownOutlined />*/}
        {/*  </Button>*/}
        {/*</Dropdown>*/}
        <Select
            onChange={onSelectInput}
            placeholder={selected_input.label}
            style={{
              width: 200,
            }}
            options={items}
        >
        </Select>
        <Button type="primary" size="small" onClick={handleGOClick}>
          Set All
        </Button>
      </InputRow>
      {selected_input.var_n !== undefined && (
        <section style={{ marginLeft: 5 }}>{`Current Value: ${
          selected_input.var_n === "mod_height" || selected_input.var_n === "mod_width"
            ? canopy.module[selected_input.var_n]
            : selected_input.var_n === "orientation"
            ? getOrientation(canopy[selected_input.var_n])
            : selected_input.var_n === "module"
            ? canopy.module.mod_name
            : selected_input.var_n === "planeType"
            ? prettifyText(canopy[selected_input.var_n])
            : canopy[selected_input.var_n]
        } ${selected_input.prefix}`}</section>
      )}
    </>
  );
};

export { GlobalOverrides };
