import { canopyConstants } from "../_constants";
import { siftService } from "../_services";

export const canopyActions = {
  updateCanopyInputs,
  update_canopy_bulk_inputs,
  updateCanopyProperty,
  updateImportedImages,
  updateShadeObject,

  // temp stuff
  initResults,
  generateResults,
  applyGlobalOverride,
  deleteCanopies,
  new_project,
  load_canopy_project,
  update_canopy_run,
  update_canopy_toolbar,
};

function updateCanopyInputs(key, value, report_ready = false) {
  return {
    type: canopyConstants.UPDATE_CANOPY_INPUT,
    key,
    value,
    report_ready,
  };
}

function update_canopy_bulk_inputs(bulk_inputs) {
  return {
    type: canopyConstants.UPDATE_CANOPY_BULK_INPUTS,
    bulk_inputs,
  };
}

function updateCanopyProperty(canopy_id, key, value, changed_from_inputs = false) {
  return {
    type: canopyConstants.UPDATE_CANOPY_PROPERTY,
    canopy_id,
    key,
    value,
    changed_from_inputs,
  };
}

// temp canopy stuff

function initResults(inputs) {
  return (dispatch) => {
    siftService._init(inputs).then((response) => {
      if (response.run_id) {
        dispatch(canopyActions.generateResults(response.run_id, 10));
      } else {
        console.log("error happened in init");
      }
    });
  };
}

function generateResults(run_id, delay) {
  return (dispatch) => {
    siftService._getResults(run_id).then((response) => {
      if (response.status == 100) {
        // COMPLETE
        console.log("completed", response.complete);
      } else if (response.status == 50) {
        // CANCEL
        console.log("cancel 50");
      } else if (response.status == 97) {
        // ERROR
        console.log("error 97");
      } else {
        console.log("completed", response.complete);
        setTimeout(dispatch(canopyActions.generateResults(run_id, Math.pow(delay, 2))), delay);
      }
    });
  };
}

function applyGlobalOverride(key, selected_module = undefined, changed_from_input_header = false) {
  return { type: canopyConstants.APPLY_GLOBAL_OVERRIDE, key, selected_module, changed_from_input_header };
}

function deleteCanopies(id, delete_all = false) {
  return {
    type: canopyConstants.DELETE_CANOPY,
    id,
    delete_all,
  };
}

function updateImportedImages(key, value, imageId = undefined) {
  return { type: canopyConstants.UPDATE_IMPORTED_IMAGES, key, value, imageId };
}

function updateShadeObject(key, value, shade_object_id = undefined) {
  return { type: canopyConstants.UPDATE_SHADE_OBJECT, key, value, shade_object_id };
}

function new_project() {
  return {
    type: canopyConstants.NEW_PROJECT,
  };
}

function load_canopy_project(project_id) {
  return { type: canopyConstants.LOAD_CANOPY_PROJECT, project_id };
}

function update_canopy_run(key, value = undefined) {
  return { type: canopyConstants.UPDATE_CANOPY_RUN, key, value };
}

function update_canopy_toolbar(key, value) {
  return { type: canopyConstants.UPDATE_CANOPY_TOOLBAR, key, value };
}
