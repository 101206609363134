import styled, { css } from "styled-components";

export const PerformanceInputWrapper = styled.section`
  width: 100%;
  height: auto;
  padding: 10px;

  .inputs-panel {
    display: grid;
    grid-template-rows: repeat(19, auto);
  }

  .input-one-col {
    display: grid;
    width: 100%;
    height: 20px;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    /* padding: 0 5px; */
    margin: 4px auto 10px auto;

    span {
      font-size: 11px;
    }
  }
  .input-one-col.coord {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    place-items: normal;
  }
  .input-one-col.indent {
    margin-left: 10px;
  }

  .input-two-col {
    display: grid;
    grid-template-columns: 1fr 120px;
    padding: 3px 0px;
    align-content: center;

    label {
      grid-column: 1;
      background-color: #fff;
    }
    Button {
      grid-column: 2;
      margin: auto 0px auto auto;
    }
    Input {
      grid-column: 2;
    }
  }

  .info-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    p {
      font-size: 12px;
      margin: 0px 0px 0px 5px;
    }
  }

  .perf-input-row-line {
    display: grid;
    grid-template-columns: 1fr 120px;
    padding: 3px 0px;
    align-content: center;

    label {
      grid-column: 1;
      background-color: #fff;
      position: relative;

      ${({ en_powerfactor }) =>
        en_powerfactor == 1 &&
        css`
          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 151px;
            background: #d9d9d9;
            position: absolute;
            top: 10px;
            left: 125px;
          }
        `}
    }
    Button {
      grid-column: 2;
      margin: auto 0px auto auto;
    }
    Input {
      grid-column: 2;
    }
  }

  .config-three-col {
    display: grid;
    grid-template-columns: 50% 10% 120px;
    padding: 3px 0px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: auto 2px auto auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
  }

  /* .perf-input-label-line label {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
  }
  .perf-input-label-line label:before {
    content: "";
    display: block;
    height: 1px;
    width: 15px;
    background: #d9d9d9;
    margin-right: 5px;
    position: abosulute;
  }
  .perf-input-label-line label:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: #d9d9d9;
    margin-left: 5px;
    position: abosulute;
  } */

  .input-do-roads {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 5px;
    margin-top: 8px;
    align-content: center;
  }

  .input-three-col {
    display: grid;
    grid-template-columns: 50% 12% 38%;
    padding: 0 5px;
    margin-top: 8px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: 0px 5px 0px auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
  }

  .racking-input {
    padding: 0 5px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr;

    .radio-buttons {
      grid-column: 1;
      display: grid;
      place-items: center;
    }
  }

  .text-block {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    padding: 0 5px;

    .ant-select-selector {
      width: 290px;
    }
  }

  .weather-divider {
    margin: 8px -15px !important;
    font-size: 10pt;
    pointer-events: none;

    .ant-divider-inner-text {
      padding-left: 0px;
      cursor: pointer;
    }
    .ant-divider-inner-text:hover {
      color: var(--primary-brand-color);
    }
  }
`;
