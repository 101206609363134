import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../Redux";

import { Input, Spin, Button, Switch, Tooltip, Alert } from "antd";
import { MenuFoldOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";
import _ from "lodash";

import { InfoCircleOutlined } from "@ant-design/icons";

const height_adder = 20;
const columns = [
  { label: "", width: "20%" },
  { label: "", width: "20%" },
  { label: "", width: "0%" },
];
const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const InverterInputs = () => {
  const dispatch = useDispatch();

  const inputs = useSelector((state) => state.inputs);
  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";

  const [eff_data, set_eff_data] = useState([]);
  const [eff_height, set_eff_height] = useState(0);
  const [temp_data, set_temp_data] = useState([]);
  const [temp_height, set_temp_height] = useState(0);
  const [dc_max_pwr, set_dc_max_pwr] = useState(0);
  const [local_inputs, set_local_inputs] = useState(inputs);
  const [local_simple_inverter_dcac, set_local_simple_inverter_dcac] = useState(1.4);

  const inv_inputs = useMemo(() => {
    return {
      simple_inverter_dcac: inputs.simple_inverter_dcac,
      derate_maxoutput: inputs.derate_maxoutput,
      derate_temps: inputs.derate_temps,
      simple_inverter: inputs.simple_inverter,
      inv_rating: inputs.inv_rating,
      inv_pnom: inputs.inv_pnom,
      inv_pd_pacokw: inputs.inv_pd_pacokw,
      mppt_low_inverter: inputs.mppt_low_inverter,
      mppt_hi_inverter: inputs.mppt_hi_inverter,
      inv_pd_vdcmax: inputs.inv_pd_vdcmax,
      maxEff: inputs.maxEff,
      inv_pd_pnt: inputs.inv_pd_pnt,
      pthresh: inputs.pthresh,
      derate_toggle: inputs.derate_toggle,
    };
  }, [
    inputs.simple_inverter_dcac,
    inputs.derate_maxoutput,
    inputs.derate_temps,
    inputs.simple_inverter,
    inputs.inv_rating,
    inputs.inv_pnom,
    inputs.inv_pd_pacokw,
    inputs.mppt_low_inverter,
    inputs.mppt_hi_inverter,
    inputs.inv_pd_vdcmax,
    inputs.maxEff,
    inputs.inv_pd_pnt,
    inputs.pthresh,
    inputs.derate_toggle,
  ]);

  useEffect(() => {
    set_local_inputs(inputs);
    handleCreateEffData(inputs);
    handleCreateTempData(inputs);
    calculateDCMaxPwr(inputs);
    // console.log("local", local_inputs);
  }, [inputs]);

  useEffect(() => {
    set_local_simple_inverter_dcac(inputs.simple_inverter_dcac);
  }, [inputs.simple_inverter_dcac]);

  useEffect(() => {
    if (inputs.derate_maxoutput.length < 2 && inputs.derate_temps.length < 2) {
      updateInput("derate_toggle", 0);
    }
  }, [inputs.derate_maxoutput.length, inputs.derate_temps.length]);

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // console.log(key, value);
    // do some input validation here
    let fixed_value = value;
    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;
    // setInverterCuts(ioManager.inputs.layout.data, ioManager.inputs.inverter.data.inverterRating);

    if (key != "derate_toggle") {
      if (key != "maxEff") {
        // console.log(_.isUndefined(value), _.isNaN(value));
        if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
          return;
        }
        fixed_value = parseInt(value);
      }
      if (key == "maxEff" || key == "simple_inverter_dcac") {
        if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
          return;
        }
        fixed_value = parseFloat(value);
      }
    }

    // console.log(fixed_value);
    dispatch(inputsActions.update_input(key, fixed_value));
  };

  // ////////////////////////////////////////////
  // DATA TABLE FUNCTION
  function handleCreateEffData(inputs) {
    const { inv_pd_eff_pout, inv_pd_efficiency } = inputs;
    if (!inv_pd_eff_pout || !inv_pd_efficiency) return;

    let t_eff_data = [
      [
        { value: "P Out kWac", readOnly: true },
        { value: "Efficiency(%)", readOnly: true },
      ],
    ];
    let t_eff_height = 78;

    let minLoop = Math.min(inv_pd_eff_pout.length, inv_pd_efficiency.length);
    // Some inverters ended up having different sized arrays, so we're going with the min length
    for (var index = 0; index < minLoop; index++) {
      t_eff_data.splice(t_eff_data.length, 0, [
        { readOnly: false, value: _.round(inv_pd_eff_pout[index], 2) },
        { readOnly: false, value: _.round(inv_pd_efficiency[index], 2) },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button
                  style={{ marginRight: 2, borderColor: "#ffffff" }}
                  shape="circle"
                  id={index}
                  key={index}
                  size="small"
                  type="primary"
                  ghost
                  onClick={(e) => {
                    onInsertRow(e, "eff");
                  }}
                >
                  <MenuFoldOutlined />
                </Button>
                <Button
                  style={{ marginRight: 2, borderColor: "#ffffff" }}
                  shape="circle"
                  key={(parseInt(index) + 1) * 100}
                  id={(parseInt(index) + 1) * 100}
                  size="small"
                  type="primary"
                  ghost
                  onClick={(e) => {
                    onRemoveRow(e, "eff");
                  }}
                >
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
      t_eff_height = t_eff_height + height_adder;
    }
    set_eff_data(t_eff_data);
    set_eff_height(t_eff_height);
  }
  function handleCreateTempData(inputs) {
    const { derate_temps, derate_maxoutput } = inputs;
    let t_temp_data = [
      [
        { value: "Temp °C", readOnly: true },
        { value: "Max kWac", readOnly: true },
      ],
    ];
    let t_temp_height = 78;

    for (var index in derate_maxoutput) {
      t_temp_data.splice(t_temp_data.length, 0, [
        { readOnly: false, value: derate_temps[index] },
        { readOnly: false, value: derate_maxoutput[index] },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button
                  style={{ marginRight: 2, borderColor: "#ffffff" }}
                  shape="circle"
                  id={(parseInt(index) + 1) * 1000}
                  size="small"
                  type="primary"
                  ghost
                  onClick={(e) => {
                    onInsertRow(e, "temp");
                  }}
                >
                  <MenuFoldOutlined />
                </Button>
                <Button
                  style={{ marginRight: 2, borderColor: "#ffffff" }}
                  shape="circle"
                  id={(parseInt(index) + 1) * 10000}
                  size="small"
                  type="primary"
                  ghost
                  onClick={(e) => {
                    onRemoveRow(e, "temp");
                  }}
                >
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
      t_temp_height = t_temp_height + height_adder;
    }
    set_temp_data(t_temp_data);
    set_temp_height(t_temp_height);
  }

  function onInsertRow(e, type, id = undefined) {
    let index = 0;
    if (type == "eff") {
      const { inv_pd_eff_pout, inv_pd_efficiency } = inputs;
      index = parseInt(e.currentTarget.id);

      let t_inv_pd_eff_pout = inv_pd_eff_pout;
      let t_inv_pd_efficiency = inv_pd_efficiency;

      t_inv_pd_eff_pout.splice(index + 1, 0, 0);
      t_inv_pd_efficiency.splice(index + 1, 0, 0);

      dispatch(
        inputsActions.update_bulk_inputs({
          inv_pd_eff_pout: t_inv_pd_eff_pout,
          inv_pd_efficiency: t_inv_pd_efficiency,
        })
      );
    }
    if (type == "temp") {
      const { derate_temps, derate_maxoutput } = inputs;
      index = e == "enable_temp_derate" ? 0 : parseInt(e.target.id) / 1000 - 1;

      let t_derate_temps = derate_temps;
      let t_derate_maxoutput = derate_maxoutput;

      t_derate_temps.splice(index + 1, 0, 0);
      t_derate_maxoutput.splice(index + 1, 0, 0);

      dispatch(
        inputsActions.update_bulk_inputs({
          derate_temps: t_derate_temps,
          derate_maxoutput: t_derate_maxoutput,
        })
      );
    }
  }
  function onRemoveRow(e, type) {
    let index = 0;
    if (type == "eff") {
      const { inv_pd_eff_pout, inv_pd_efficiency } = inputs;
      if (inv_pd_eff_pout.length == 1) return;
      index = e.currentTarget.id / 100 - 1;

      let t_inv_pd_eff_pout = inv_pd_eff_pout;
      let t_inv_pd_efficiency = inv_pd_efficiency;

      t_inv_pd_eff_pout.splice(index, 1);
      t_inv_pd_efficiency.splice(index, 1);

      dispatch(
        inputsActions.update_bulk_inputs({
          inv_pd_eff_pout: t_inv_pd_eff_pout,
          inv_pd_efficiency: t_inv_pd_efficiency,
        })
      );
    }
    if (type == "temp") {
      const { derate_temps, derate_maxoutput } = inputs;
      if (derate_temps.length == 1) return;
      index = e.currentTarget.id / 10000 - 1;

      let t_derate_temps = derate_temps;
      let t_derate_maxoutput = derate_maxoutput;

      t_derate_temps.splice(index, 1);
      t_derate_maxoutput.splice(index, 1);

      dispatch(
        inputsActions.update_bulk_inputs({
          derate_temps: t_derate_temps,
          derate_maxoutput: t_derate_maxoutput,
        })
      );
    }
  }
  function handleEffChanges(changes) {
    const data = eff_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newInvData = data.slice(1, data.length);
    var POut = [];
    var Eff = [];
    newInvData.forEach((row) => {
      let pout = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let eff = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      POut.push(pout);
      Eff.push(eff);
    });

    dispatch(
      inputsActions.update_bulk_inputs({
        inv_pd_eff_pout: POut,
        inv_pd_efficiency: Eff,
      })
    );
  }
  function handleTempChanges(changes) {
    const data = temp_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newInvData = data.slice(1, data.length);
    var Temp = [];
    var Output = [];

    newInvData.forEach((row) => {
      let temp = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let output = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Temp.push(temp);
      Output.push(output);
    });

    dispatch(
      inputsActions.update_bulk_inputs({
        derate_temps: Temp,
        derate_maxoutput: Output,
      })
    );
  }
  // ////////////////////////////////////////////

  function calculateDCMaxPwr(inputs) {
    const { inv_pd_pacokw, maxEff } = inputs;
    if (!isNaN(inv_pd_pacokw) && !isNaN(maxEff)) {
      if (inv_pd_pacokw > 0 && maxEff > 0) {
        set_dc_max_pwr(_.round((inv_pd_pacokw / maxEff) * 100, 2));
      }
    }
  }

  //  init functions called every refresh
  // Create eff table data
  // handleCreateEffData();
  // // Create temp table data
  // handleCreateTempData();
  // calculateDCMaxPwr();

  return (
    <div className="input-content-box">
      {/* {internal_sift && (
        <section className="input-row">
          <label>Simple Inverter</label>
          <Switch
            size="small"
            id="simple_inverter"
            checked={local_inputs.simple_inverter == 1}
            onChange={(checked) => {
              updateInput("simple_inverter", checked ? 1 : 0);
            }}
          />
        </section>
      )} */}
      {local_inputs.simple_inverter == 1 ? (
        // <div className="input-row">
        //   <label>Inverter Rating</label>
        //   <Input
        //     id="inv_rating"
        //     onChange={(event) => {
        //       updateInput(event.target.id, event.target.value);
        //     }}
        //     value={local_inputs.inv_rating}
        //     size="small"
        //     disabled={saving}
        //     suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kW</span>}
        //   />
        // </div>
        <>
          <section className="input-row">
            <label>{`DC:AC (ILR):`}</label>
            <Input
              type="number"
              size="small"
              id="simple_inverter_dcac"
              min={0.9}
              max={1.8}
              step="0.1"
              value={local_inputs.simple_inverter_dcac}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="info-msg">
            <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", marginTop: 5 }} />
            <p>Module Target has been enabled. Inverter grouping, DC:AC range, and Fixed Inverter Qty inputs are disabled when using Simple Inverter Input.</p>
          </section>
        </>
      ) : (
        <>
          <section className="input-row">
            <label>Inverter Rating</label>
            <Input
              id="inv_rating"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_rating}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kW</span>}
            />
          </section>

          <section className="input-row">
            <label>AC Power Nom</label>
            <Input
              id="inv_pnom"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pnom}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
            />
          </section>

          <section className="input-row">
            <label>AC Power Max</label>
            <Input
              id="inv_pd_pacokw"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pd_pacokw}
              size="small"
              disabled={saving}
              key={30}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
            />
          </section>

          <section className="input-row">
            <label>Min MPPT</label>
            <Input
              id="mppt_low_inverter"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mppt_low_inverter}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
            />
          </section>

          <section className="input-row">
            <label>Max MPPT</label>
            <Input
              id="mppt_hi_inverter"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mppt_hi_inverter}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
            />
          </section>

          <section className="input-row">
            <label>Max PV Volts</label>
            <Input
              id="inv_pd_vdcmax"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pd_vdcmax}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
            />
          </section>

          <section className="input-row">
            <label>Max Efficiency</label>
            <Input
              id="maxEff"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.maxEff}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row">
            <label>DC at Max Pwr</label>
            <span style={{ marginLeft: "auto", marginRight: "3px" }}>{dc_max_pwr} kW</span>
          </section>

          <section className="input-row">
            <label>Night Pwr Use</label>
            <Input
              id="inv_pd_pnt"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.inv_pd_pnt}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
            />
          </section>

          <section className="input-row">
            <label>Power Threshold</label>
            <Input
              id="pthresh"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.pthresh}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
            />
          </section>

          <section className="input-row">
            <label>Efficiency Curve</label>
          </section>
          <section className="input-table-container">
            <section className="input-table" style={{ height: eff_height, width: 200 }}>
              <ReactDataSheet
                data={eff_data}
                sheetRenderer={renderSheet}
                valueRenderer={(cell) => cell.value}
                onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                onCellsChanged={handleEffChanges}
              />
            </section>
          </section>

          <Tooltip placement="bottom" title={inputs.derate_temps.length < 2 ? "Temp Derate must have at least 2 rows to be active." : ""} mouseEnterDelay={0.5}>
            <section className="input-row">
              <label>Temp Derate</label>
              <Switch
                size="small"
                id="derate_toggle"
                checked={local_inputs.derate_toggle == 1}
                onChange={(checked) => {
                  updateInput("derate_toggle", checked ? 1 : 0);
                }}
              />
            </section>
          </Tooltip>

          {local_inputs.derate_toggle == 1 && (
            <section className="input-table-container">
              <section className="input-table" style={{ height: temp_height, width: 200 }}>
                <ReactDataSheet
                  data={temp_data}
                  sheetRenderer={renderSheet}
                  valueRenderer={(cell) => cell.value}
                  onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                  onCellsChanged={handleTempChanges}
                />
              </section>
            </section>
          )}
        </>
      )}

      {/* </Spin> */}
    </div>
  );
};

export { InverterInputs };
