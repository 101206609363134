import styled from "styled-components";

const TopoPanelWrap = styled.section`
  padding: 10px;
  height: 635px;
  overflow-y: scroll;

  h3 {
    margin: 0px 0px 5px 0px;
  }

  .bold-font {
    font-weight: 600;
    margin: 10px 0;
  }

  .col-two {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    place-items: center;

    .topo-button {
      grid-row: 1;
      grid-column: 2;
      place-self: end;
    }
  }

  .flex-row {
    display: flex;

    button {
      margin-right: 12px;
      outline: none;
      background: none;
      border: none;
      color: var(--primary-brand-color);

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .topo-row {
    margin: 10px 0px;
    p {
      margin: 0px;
    }
  }

  .cut-fill {
    p {
      margin: 0px 0px 5px 0px;
    }
  }

  .surface-btns {
    margin: 10px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
  }

  .topo-row-lrg {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0px;
    }
  }

  .topo-one-row {
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  .lg-mg-r {
    margin-right: 12px;
  }

  .move-rt {
    margin-left: 54px;
  }

  .topo-two-row {
    margin: 0 0 10px 0;

    button {
      height: 35px;
      width: 35px;
      margin: 0 5px 0 0;
      border-radius: 4px;
      outline: 0;
      background: #fff;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      transition: all 0.3s var(--easing-spring);
      position: relative;

      &:hover {
        /* color: var(--primary-brand-color); */
        background: ${(props) => (props.starter_limits ? "none" : "#fff")};
        /* background: #fff; */
        border-color: ${(props) => (props.starter_limits ? "none" : "var(--primary-brand-color)")};
      }
    }

    .active-topo-button {
      color: #fff;
      background: var(--primary-brand-color);
      border-color: var(--primary-brand-color);
      transition: opacity 0.3 var(--easing-spring);

      &:hover {
        opacity: 0.7;
        color: #fff;
        background: var(--primary-brand-color);
        border-color: var(--primary-brand-color);
      }
    }

    .avail-topo-button {
      color: var(--primary-brand-color);
      background: #fff;
      border-color: var(--primary-brand-color);

      &:focus {
        box-shadow: 0 0 5px var(--background-highlight-color);
      }
    }

    /* &:after {
      content: "√";
      position: absolute;
      left: 22px;
      top: -6px;
      font-size: 10px;
      font-weight: 800;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #0ad50a;
      z-index: 10;
      color: #0ad50a;
    } */
  }

  .vert-buttons {
    padding-left: 5px;

    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
  }
  .topo-input {
    label {
      margin-right: 5px;
    }
    .ant-input-affix-wrapper {
      height: 24px;
      width: 115px;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
`;

export { TopoPanelWrap };
