import styled from "styled-components";

const ReportPageHeaderWrap = styled.section`
  grid-area: report-header;
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  margin-bottom: 10px;

  .report-header-image {
    grid-column: 1;
    display: grid;
    place-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.9);
    padding: 0px 15px;

    img,
    svg {
      width: 100px;
    }
  }

  .report-header-content {
    grid-column: 2;
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    .report-header-row-1,
    .report-header-row-2,
    .report-header-row-3 {
      display: grid;
      padding: 0px 10px;
      border-bottom: 1px solid #333;
      line-height: 25px;

      p {
        margin: 0px;
      }
    }
    .report-header-row-1 {
      width: 100%;
      grid-template-columns: 1fr auto;

      p:nth-child(2) {
        text-align: center;
        padding: 0px 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.9);
      }
    }
    .report-header-row-2 {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      p:nth-child(2) {
        border-left: 1px solid rgba(0, 0, 0, 0.9);
        padding-left: 10px;
      }
    }
  }
`;

export { ReportPageHeaderWrap };
