import { tutorialConstants } from '../_constants';
import { defaultTipsVisible, TourContent, ExampleContent } from '../../Tutorial';

const initialStateDefault = {
  currentIndex: 0,
  tutorialActive: true,
  showTutorialFunnel: false,
  tutorialPath: undefined, //0 = tour, 1 = example
  currentStep: undefined,
  visibleTips: defaultTipsVisible,
  content: undefined,
  showTutorialNav: false,
};

const initialState = {
  currentIndex: 0,
  tutorialActive: true,
  showTutorialFunnel: false,
  tutorialPath: undefined, //0 =tour, 1= example
  currentStep: undefined,
  visibleTips: defaultTipsVisible,
  content: undefined,
  showTutorialNav: false,
};

export function tutorial(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case tutorialConstants.START_TUTORIAL:
      return {
        ...initialStateDefault,
      };

    case tutorialConstants.START_TOUR:
      return {
        ...state,
        tutorialPath: 0,
        content: TourContent,
        visibleTips: {
          config_tip: true,
        },
        showTutorialNav: true,
      };

    case tutorialConstants.RUN_EXAMPLE:
      return {
        ...state,
        tutorialPath: 1,
        content: ExampleContent,
        showTutorialNav: false,
      };

    case tutorialConstants.START_EXAMPLE_TUT:
      let exampleTips = Object.values(state.content)[state.currentIndex].visible_tips;
      let startingTip = { ...defaultTipsVisible, ...exampleTips };
      return {
        ...state,
        visibleTips: {
          ...startingTip,
        },
        showTutorialNav: true,
      };

    case tutorialConstants.NAVIGATE_TUTORIAL:
      let index = state.currentIndex;
      if (action.direction == 'next' && state.currentIndex < Object.values(state.content).length - 1) {
        index++;
      } else if (action.direction == 'prev' && state.currentIndex > 0) {
        index--;
      }

      let newTips = Object.values(state.content)[index].visible_tips;
      let tips = { ...defaultTipsVisible, ...newTips };

      return {
        ...state,
        currentIndex: index,
        visibleTips: {
          ...tips,
        },
      };

    case tutorialConstants.RESET_TUTORIAL:
      return {
        ...initialStateDefault,
      };

    case tutorialConstants.SHOW_TUTORIAL_FUNNEL:
      return {
        ...state,
        showTutorialFunnel: true,
        visibleTips: defaultTipsVisible,
      };

    case tutorialConstants.END_TUTORIAL:
      return {
        ...initialStateDefault,
        tutorialActive: false,
        showTutorialFunnel: false,
      };

    case tutorialConstants.SHOW_TUTORIAL_NAV:
      return {
        ...state,
        showTutorialNav: action.bool,
      };

    default:
      return state;
  }
}
