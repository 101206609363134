import { useEffect } from "react";
import { useMap } from "react-leaflet";
import * as EL from "esri-leaflet";

const EsriLayer = (props) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    let esriLayer;

    esriLayer = new EL.dynamicMapLayer({ ...props });

    if (props.eventHandlers) {
      const events = Object.keys(props.eventHandlers);
      events.forEach((event) => {
        esriLayer.on(event, props.eventHandlers[event]);
      });
    }

    // console.log("esriLayer", esriLayer);

    map.addLayer(esriLayer);

    return () => {
      map.removeLayer(esriLayer);
    };
  }, []);

  return null;
};

// this layer is used for the transmisson lines but can be used for any ArcGIS endpoint that sends back GeoJson data

const layerColor = {
  'riparian': "var(--primary-brand-color)",
  'wetland': "purple",
  'fema': "orange",
  'transmission': "red",
  'gasline': "black",
  'railway': "green",
}


const FEMA_COLOR = {
  'dark': '#B981BB',
  'light': '#C1ADD8',
  'regular': '#AAC1E0',
  'special': '#B1D7DD',
  'future': '#F8DDCF',
  'risk': '#EFE2BE',
}

const Esri_FeatureLayer = (props, { layerIsInteractive = false, layer_type = "wetland" }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    let esri_feature_layer;

    const getFemaColor = (type) => {
      switch (type) {
        case ('1% Annual Chance Flood Hazard'):
          return FEMA_COLOR.dark
        case ('0.2% Annual Chance Flood Hazard'):
          return FEMA_COLOR.light
        case ('Future Conditions 1% Annual Chance Flood Hazard'):
          return FEMA_COLOR.future
        case ('Special Floodway'):
          return FEMA_COLOR.special
        case ('Area with Reduced Risk Due to Levee'):
          return FEMA_COLOR.risk
        case ('Regulatory Floodway'):
          return FEMA_COLOR.regular
      }
    }

    esri_feature_layer = new EL.featureLayer({
      ...props, interactive: layerIsInteractive,
      // style: (feature) => {
      //   const femaClr = getFemaColor(feature?.properties?.esri_symbology)
      //   return {
      //     color: femaClr,
      //   };
      // }
    });

    if (props.eventHandlers) {
      const events = Object.keys(props.eventHandlers);
      events.forEach((event) => {
        esri_feature_layer.on(event, props.eventHandlers[event]);
      });
    }

    console.log('color is ', props.layer_type, layerColor[props.layer_type])
    // if (layer_type !== "fema") {
      esri_feature_layer.setStyle({
        stroke: true,
        // color: "red",
        color: `${layerColor[props.layer_type]}`,
        opacity: 1.0,
        weight: 2,
        fillColor: layerColor[props.layer_type],
        // fillColor: "red",
      });
    // }

    // esri_feature_layer.on("load", (e) => console.log("load event", e.target._layers));

    map.addLayer(esri_feature_layer);

    return () => {
      map.removeLayer(esri_feature_layer);
    };
  }, []);
  return null;
};

export { EsriLayer, Esri_FeatureLayer };
