import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resultColumns } from "./resultColumns";

import { ResultTableWrapper } from "./styles";

import { Tooltip } from "antd";
import { UpSquareOutlined, DownSquareOutlined } from "@ant-design/icons";

const CanopyResultTable = () => {
  const dispatch = useDispatch();

  const [sortMode, setSortMode] = useState({ col: "moduleCount", sort: "ascend" });

  const canopy_results = useSelector((state) => state.canopy.canopy_results);
  const selected_canopy_result = useSelector((state) => state.canopy.selected_canopy_result);

  const onSetMiniSort = (_sorter, e) => {
    // this button is nested in a column header that is clickable too, so stop event from bubbling to that header
    e.stopPropagation();
    let sorter;
    if (_sorter === "name") {
      sorter = "index";
    } else {
      sorter = _sorter;
    }

    if (sorter == sortMode.col && sortMode.sort === "ascend") {
      // flip
      setSortMode({ col: sorter, sort: "descend" });
    } else {
      // defaults to ascend
      setSortMode({ col: sorter, sort: "ascend" });
    }
  };

  const onSelectMiniResult = (result) => {
    if (selected_canopy_result && selected_canopy_result.id == result.id) {
      return;
      // console.log('click')
      // setSelectedId(result.id)
      // dispatch(siftActions.selectResult(result))
    }
    // props.onSelectResult(result);
  };

  const onSortResults = (results) => {
    if (sortMode.sort === "ascend") {
      // ascending
      results.sort((a, b) => a[sortMode.col] - b[sortMode.col]);
    } else {
      // descending
      results.sort((a, b) => b[sortMode.col] - a[sortMode.col]);
    }
    return results;
  };

  const results = Object.values(canopy_results.results).length > 0 && onSortResults(Object.values(canopy_results.results));

  return (
    <section className="canopy-results">
      <ResultTableWrapper>
        <table>
          <thead>
            <tr key="column-headers">
              {resultColumns.map((col, index) => {
                if (!col.visible) return;
                return (
                  <th
                    className={`column-name ${sortMode.col == col.key ? "active" : ""}`}
                    // style={{ width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center' }}
                    key={index}
                    onClick={(e) => onSetMiniSort(col.key, e)}
                  >
                    <span>{col.title}</span>
                    <span className="mini-sort">{sortMode.col == col.key ? sortMode.sort === "ascend" ? <UpSquareOutlined /> : <DownSquareOutlined /> : <span></span>}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {results?.map((res) => {
              return (
                <Tooltip placement="left" mouseEnterDelay={0.2} key={res.id}>
                  <tr
                    key={res.id}
                    // className={`mini-data ${selectedRow && selectedRow.id == res.id && "selected"}`}
                    className="mini-data"
                    onClick={() => onSelectMiniResult(res)}
                  >
                    {resultColumns.map((col, index) => {
                      if (col.visible) {
                        if (res["Canopy Type"] === "inverted" && col.key === "Tilt (°)") {
                          return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : `${res["Tilt To Azimuth"]} to Azimuth / ${res["Tilt From Azimuth"]} from Azimuth`}</td>;
                        } else {
                          return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : res[col.key]}</td>;
                        }
                      }
                    })}
                  </tr>
                </Tooltip>
              );
            })}
          </tbody>
        </table>
      </ResultTableWrapper>
    </section>
  );
};

export { CanopyResultTable };
