import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../Redux";
import { InputPopoutWrapper, SATWrap, SATWrapNoBorder, RackingSpecErrors } from "./styles";

// components
import { Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

//helpers
import { isNumber } from "../../Redux";

// assets
import { SAT_Large, SAT_Medium, SAT_Small, EWF_Large, EWF_Medium, EWF_Small, GFT_Large, GFT_Medium, GFT_Small } from "./RackingImages";

const rack_float_vars = [
  "xdim",
  "ydim",
  "module_gap",
  "drive_gap",
  "string_count",
  "rack_grade_limit",
  "gap",
  "bi_groundClearanceHeight",
  "bi_transmissionFactor",
  "bi_structureShadeFactor",
  "rlim",
  "Fshd_Celltrav",
];
const rack_int_vars = ["mod_per_string", "modules_high", "module", "Fshd_StringSteps"];

const RackingSpecs = ({ autoRack }) => {
  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);

  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    // do some input validation here
    let bulk_update;
    let fixed_value = value;

    if (bulk_update) {
      dispatch(inputsActions.update_bulk_inputs(bulk_update));
    } else {
      dispatch(inputsActions.update_input(key, fixed_value));
    }
  };

  const updateRackData = (index, key, value) => {
    let new_racks = local_inputs.racks.slice(0);
    new_racks[index][key] = value;

    if (key == "xdim" && (inputs.track_mode == 1 || inputs.track_mode == 2)) {
      new_racks[0][key] = value;
      new_racks[1][key] = value;
      new_racks[2][key] = value;
    }
    if (key == "ydim" && inputs.track_mode != 1) {
      new_racks[0][key] = value;
      new_racks[1][key] = value;
      new_racks[2][key] = value;
    }

    set_local_inputs({
      ...local_inputs,
      racks: new_racks,
    });

    if (key == "xdim" || key == "ydim") {
      if ((_.isString(value) && _.isEmpty(value)) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      let fixed_value = parseFloat(value);
      new_racks[index][key] = fixed_value;

      if (key == "xdim" && (inputs.track_mode == 1 || inputs.track_mode == 2)) {
        new_racks[0][key] = fixed_value;
        new_racks[1][key] = fixed_value;
        new_racks[2][key] = fixed_value;
      }
      if (key == "ydim" && inputs.track_mode != 1) {
        new_racks[0][key] = fixed_value;
        new_racks[1][key] = fixed_value;
        new_racks[2][key] = fixed_value;
      }
    }

    if (key == "module") {
      if ((_.isString(value) && _.isEmpty(value)) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      let fixed_value = parseInt(value);
      new_racks[index][key] = fixed_value;
    }

    // added this to check the value being updated for racks so that it doesn't make it to the store if it's undefined, empty, etc.
    // But even though this check works correctly and exits this function, updateInputs still gets called from somewhere else.
    // I have also disabled the 'fixed_inputs.rack_dims == 0' check in the run_side_effects function that may have to be enabled for testing this.

    // if (key == "xdim" || key == "ydim") {
    //   if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
    //     return;
    //   }
    // }

    // updateInput("racks", new_racks);
    dispatch(inputsActions.update_input("racks", new_racks));
  };

  const dispatch = useDispatch();
  const local_input_rack_2 = _.ceil(local_inputs.racks[2].module);

  return (
    <div className="popout-content">
      {/* A */}
      <SATWrap active={local_inputs.racks[0].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack A</h4>
          <div className="toggle-active">
            <Switch
              key="0"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={local_inputs.racks[0].active === 1}
              disabled={local_inputs.rack_dims == 0}
              onChange={() => updateRackData(0, "active", local_inputs.racks[0].active == 0 ? 1 : 0)}
            />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {local_inputs.track_mode == 2 && <p>{`(Single Face)`}</p>}
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[0].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[0].module}
            onChange={(e) => updateRackData(0, "module", e.target.value)}
          />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>
              {local_inputs.track_mode == 2
                ? `${_.round(local_inputs.racks[0].module / local_inputs.mod_per_string, 2)} Strings per face`
                : `${_.round(local_inputs.racks[0].module / local_inputs.mod_per_string, 2)} Strings`}
            </p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{local_inputs.track_mode == 2 ? `${local_inputs.racks[0].module * 2} Total Modules` : `${local_inputs.racks[0].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.mod_width * local_inputs.mod_height * local_inputs.racks[0].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.racks[0].xdim * local_inputs.racks[0].ydim).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[0].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[0].ydim}
            onChange={(e) => updateRackData(0, "ydim", e.target.value)}
          />
        </div>
        <div className="x-input">
          <label>X(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[0].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[0].xdim}
            onChange={(e) => updateRackData(0, "xdim", e.target.value)}
          />
        </div>
        <div className="racking-image">
          {local_inputs.track_mode == 2 ? (
            <EWF_Large active={local_inputs.racks[0].active} autoRack={autoRack} />
          ) : local_inputs.track_mode == 0 ? (
            <GFT_Large active={local_inputs.racks[0].active} autoRack={autoRack} />
          ) : (
            <SAT_Large active={local_inputs.racks[0].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrap>
      {/* B */}
      <SATWrap active={local_inputs.racks[1].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack B</h4>
          <div className="toggle-active">
            <Switch
              key="1"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={local_inputs.racks[1].active === 1}
              disabled={local_inputs.rack_dims == 0}
              onChange={() => updateRackData(1, "active", local_inputs.racks[1].active == 0 ? 1 : 0)}
            />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {local_inputs.track_mode == 2 && <p>{`(Single Face)`}</p>}
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[1].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[1].module}
            onChange={(e) => updateRackData(1, "module", e.target.value)}
          />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>
              {local_inputs.track_mode == 2
                ? `${_.round(local_inputs.racks[1].module / local_inputs.mod_per_string, 2)} Strings per face`
                : `${_.round(local_inputs.racks[1].module / local_inputs.mod_per_string, 2)} Strings`}
            </p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{local_inputs.track_mode == 2 ? `${local_inputs.racks[1].module * 2} Total Modules` : `${local_inputs.racks[1].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.mod_width * local_inputs.mod_height * local_inputs.racks[1].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.racks[1].xdim * local_inputs.racks[1].ydim).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[1].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[1].ydim}
            onChange={(e) => updateRackData(1, "ydim", e.target.value)}
          />
        </div>
        <div className="x-input">
          <label>X(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[1].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[1].xdim}
            onChange={(e) => updateRackData(1, "xdim", e.target.value)}
          />
        </div>
        <div className="racking-image">
          {local_inputs.track_mode == 2 ? (
            <EWF_Medium active={local_inputs.racks[1].active} autoRack={autoRack} />
          ) : local_inputs.track_mode == 0 ? (
            <GFT_Medium active={local_inputs.racks[1].active} autoRack={autoRack} />
          ) : (
            <SAT_Medium active={local_inputs.racks[1].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrap>
      {/* C */}
      <SATWrapNoBorder active={local_inputs.racks[2].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack C</h4>
          <div className="toggle-active">
            <Switch
              key="2"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={local_inputs.racks[2].active === 1}
              disabled={local_inputs.rack_dims == 0}
              onChange={() => updateRackData(2, "active", local_inputs.racks[2].active == 0 ? 1 : 0)}
            />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {local_inputs.track_mode == 2 && <p>{`(Single Face)`}</p>}
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[2].active || local_inputs.rack_dims == 0}
            value={local_input_rack_2}
            onChange={(e) => updateRackData(2, "module", e.target.value)}
          />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>
              {local_inputs.track_mode == 2
                ? `${_.round(local_input_rack_2 / local_inputs.mod_per_string, 2)} Strings per face`
                : `${_.round(local_input_rack_2 / local_inputs.mod_per_string, 2)} Strings`}
            </p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{local_inputs.track_mode == 2 ? `${local_inputs.racks[2].module * 2} Total Modules` : `${local_inputs.racks[2].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.mod_width * local_inputs.mod_height * local_inputs.racks[2].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: local_inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(local_inputs.racks[2].xdim * local_inputs.racks[2].ydim).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[2].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[2].ydim}
            onChange={(e) => updateRackData(2, "ydim", e.target.value)}
          />
        </div>
        <div className="x-input">
          <label>X(m)</label>
          <input
            className="racking_input"
            type="number"
            disabled={!local_inputs.racks[2].active || local_inputs.rack_dims == 0}
            value={local_inputs.racks[2].xdim}
            onChange={(e) => updateRackData(2, "xdim", e.target.value)}
          />
        </div>
        <div className="racking-image">
          {local_inputs.track_mode == 2 ? (
            <EWF_Small active={local_inputs.racks[2].active} autoRack={autoRack} />
          ) : local_inputs.track_mode == 0 ? (
            <GFT_Small active={local_inputs.racks[2].active} autoRack={autoRack} />
          ) : (
            <SAT_Small active={local_inputs.racks[2].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrapNoBorder>
    </div>
  );
};

const DynamicInputPopout = ({ onClose, popoutY, visible, autoRack }) => {
  const dispatch = useDispatch();

  // const { racking, module, performance } = useSelector((state) => state.sift.ioManager.inputs);
  // const type = racking.data.type;

  // const racking_error_present = useSelector((state) => state.sift.ioManager.uiState.racking_error_present);
  // const racking_errors = useSelector((state) => state.sift.ioManager.uiState.racking_errors);

  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);
  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  const [showError, setShowError] = useState(false);
  const [checkInputs, setCheckInputs] = useState(false);

  useEffect(() => {
    setShowError(false);
    setCheckInputs(false);
  }, [inputs.track_mode]);

  const checkBeforeClose = () => {
    if (local_inputs.racking_errors && !checkInputs) {
      setCheckInputs(true);
    } else {
      setCheckInputs(false);
      onClose();
    }
  };

  return (
    <InputPopoutWrapper popoutY={popoutY}>
      {visible && (
        <Fragment>
          <div className="input-popout">
            <div className="popout-close">
              {checkInputs && (
                <p>
                  <InfoCircleOutlined style={{ color: "red", marginRight: "5px" }} />
                  {`Racking specs contain critical errors.  Click 'Close' to ignore.`}
                </p>
              )}
              <a onClick={() => checkBeforeClose()}>
                <u>Close</u>
              </a>
            </div>
            <RackingSpecs autoRack={autoRack} />
          </div>
          <RackingSpecErrors showError={local_inputs.racking_errors}>
            <ul>
              {local_inputs.racking_errors &&
                (local_inputs.racking_errors.error_one ||
                  local_inputs.racking_errors.error_two ||
                  local_inputs.racking_errors.error_three ||
                  local_inputs.racking_errors.error_four ||
                  local_inputs.racking_errors.error_five) &&
                Object.values(local_inputs.racking_errors).map((err, index) =>
                  err == undefined || typeof err == "boolean" ? null : (
                    <li key={index}>
                      <div className="error_list">
                        <InfoCircleOutlined style={{ color: "red", marginRight: "5px", marginTop: "4px" }} />
                        <p>{err}</p>
                      </div>
                    </li>
                  )
                )}
            </ul>
          </RackingSpecErrors>
        </Fragment>
      )}
    </InputPopoutWrapper>
  );
};

export { DynamicInputPopout };
