import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

// redux
import { inputsActions } from "../Redux";

//tutorial stuff
//import { TutorialTip } from "../../components/TutorialTip";

import { SelectedButtonsWrap } from "./sc";
import { Descriptions, Dropdown, Spin, DatePicker, Input, Statistic, Row, Popconfirm } from "antd";

import { Button, ButtonGroup, Tooltip } from "../BaseComponents";

import { BuildOutlined, FileZipOutlined, AppstoreOutlined, FileImageOutlined } from "@ant-design/icons";

import { BudgetaryQuote } from "../_Internal";

const ResultDetailWrapper = styled.section`
  height: 315px;
  overflow-y: scroll;

  #masonry-effect {
    padding: 15px 10px 15px 10px;
    columns: ${(props) => (props.do_finance === 1 || props.do_finance === true ? "3 340px" : "2 400px")};
    column-gap: 1rem;

    .detail-box {
      width: 100%;
      margin-bottom: 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 3px;

      h3 {
        padding: 5px;
        margin: 0;
        font-size: 10pt;
        border-radius: 3px 3px 0 0;
        color: var(--primary-brand-color);
        background: #fafafa;
        border-bottom: 1px solid #e8e8e8;
      }

      .detail-row {
        display: flex;
        justify-content: space-between;
        padding: 3px 10px;
        width: 100%;
        color: #444;
        border-bottom: 1px solid #e8e8e8;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

const ResultDetail = (props) => {
  const dispatch = useDispatch();
  const { result, loading_layout, loading_files, map_loading } = props;
  //Tutorial Controls
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  const edited_layout = useSelector((state) => state.inputs?.edited_layouts);
  const undo_available = edited_layout && edited_layout[selectedResultId]?.edited ? true : false;

  const tutorial = useSelector((state) => state.tutorial);
  //const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);

  const current_plan = useSelector((state) => state.user.product_plan_paid);

  const track_mode = useSelector((state) => state.inputs.track_mode);
  // console.log("result", result);
  const editing_disabled = result.inverter_grouping > 0 || result.MWp > 100;
  // console.log(editing_disabled, result.inverter_grouping, result.MWp)

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  // portal specific state
  const download_lock = useSelector((state) => (internal_sift && state.user.admin ? false : state.inputs.uiState.download_lock));
  // const calculate_masonry_layout = () => {
  //   let mainId = "masonry-effect";
  //   let itemIdentifier = "#masonry-effect .detail-box";

  //   // Programmatically get the column width
  //   let item = document.querySelector(itemIdentifier);
  //   let parentWidth = item.parentNode.getBoundingClientRect().width;
  //   let itemWidth = item.getBoundingClientRect().width + parseFloat(getComputedStyle(item).marginLeft) + parseFloat(getComputedStyle(item).marginRight);
  //   let columnWidth = Math.round(1 / (itemWidth / parentWidth));

  //   // We need this line since JS nodes are dumb
  //   let arrayOfItems = Array.prototype.slice.call(document.querySelectorAll(itemIdentifier));
  //   let trackHeights = {};
  //   arrayOfItems.forEach(function (item) {
  //     let height = item.clientHeight;
  //     item.style.height = `${height}px`;
  //     // Get index of item
  //     let thisIndex = arrayOfItems.indexOf(item);
  //     // Get column this and set width
  //     let thisColumn = thisIndex % columnWidth;
  //     if (typeof trackHeights[thisColumn] == "undefined") {
  //       trackHeights[thisColumn] = 0;
  //     }
  //     trackHeights[thisColumn] += item.getBoundingClientRect().height + parseFloat(getComputedStyle(item).marginBottom);
  //     // If the item has an item above it, then move it to fill the gap
  //     if (thisIndex - columnWidth >= 0) {
  //       let getItemAbove = document.querySelector(`${itemIdentifier}:nth-of-type(${thisIndex - columnWidth + 1})`);
  //       let previousBottom = getItemAbove.getBoundingClientRect().bottom;
  //       let currentTop = item.getBoundingClientRect().top - parseFloat(getComputedStyle(item).marginBottom);
  //       item.style.top = `-${currentTop - previousBottom}px`;
  //     }
  //   });
  //   let max = Math.max(...Object.values(trackHeights));
  //   document.getElementById(mainId).style.height = `${max}px`;
  // };

  return (
    <section>
      <SelectedButtonsWrap>
        <div>
          <span>Result Actions:</span>
        </div>
        {internal_sift ? (
          <section
            id="download-buttons-tip"
            className="selected-button-actions"
            style={{ display: "flex", width: "min-content", outline: tutorial.visibleTips.download_buttons ? "3px solid var(--primary-brand-color)" : "3px solid transparent" }}
          >
            <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<BuildOutlined />} onClick={() => props.onPlot(result)} loading={loading_layout} size="small">
                Plot
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="Formatted PDF Report" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileZipOutlined />} onClick={() => props.onShowReport(result)} loading={loading_files} size="small">
                {show_live_report ? "Hide PDF Report" : "PDF Report"}
              </Button>
            </Tooltip>

            <ButtonGroup>
              <Tooltip
                placement="top"
                title={
                  editing_disabled
                    ? "Site editing is disabled when running with Inverter Groups or if the site exceeds 100 MWp."
                    : current_plan > 1
                    ? "Edit this Layout"
                    : "Layout Editing available on our Standard and Pro plans."
                }
                mouseEnterDelay={0.5}
              >
                <Popconfirm
                  title="Editing this layout is strictly for map visualization and will not be reflected in this result's performance model."
                  onConfirm={() => props.onShowLayoutEditor()}
                  style={{ width: 250 }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="secondary"
                    size="small"
                    styles={{ borderRadius: "4px 0px 0px 4px" }}
                    disabled={current_plan == 1 || editing_disabled}
                    label={show_layout_editor ? "Close Editor" : "Edit Layout"}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title={current_plan > 1 ? "Restore original layout" : null} mouseEnterDelay={0.5}>
                <Popconfirm title="Undo all edits?" onConfirm={() => props.undoAllLayoutEditing()} okText="Yes" cancelText="No">
                  <Button type="secondary" size="small" label="Undo" disabled={!undo_available} />
                </Popconfirm>
              </Tooltip>
            </ButtonGroup>

            <Tooltip placement="top" title="Detailed data for selected result" mouseEnterDelay={0.5}>
              <Button
                ghost
                type="secondary"
                icon={<FileZipOutlined />}
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_xlsx"));
                  } else {
                    props.onDownloadFiles(result);
                  }
                }}
                loading={loading_files}
                size="small"
              >
                {download_lock ? "Unlock XLSX" : "XLSX"}
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={current_plan > 1 ? "Export layout to DXF" : "Export to CAD is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button
                ghost
                enabled={current_plan > 1 ? "true" : "false"}
                type="secondary"
                icon={<AppstoreOutlined />}
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_cad"));
                  } else {
                    props.onExportMap("export_dxf");
                  }
                }}
                loading={map_loading}
                size="small"
              >
                {download_lock ? "Unlock CAD" : "CAD"}
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_kml")} loading={map_loading} size="small">
                KMZ
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={result.track_mode === 2 ? "SHD Export not available for East-West Facing" : "Export map to SHD"} mouseEnterDelay={0.5}>
              <Button ghost disabled={result.track_mode === 2} type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_pvsyst")} loading={map_loading} size="small">
                SHD
              </Button>
            </Tooltip>

            <BudgetaryQuote result={result} />

            {/* 
              <Tooltip placement="top" title="Export map to SHD" mouseEnterDelay={0.5}>
                <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => console.log("download bq pdf")} loading={map_loading} size="small">
                  BQ PDF
                </Button>
              </Tooltip>
             */}
          </section>
        ) : (
          <section
            id="download-buttons-tip"
            className="selected-button-actions"
            style={{ display: "flex", width: "min-content", outline: tutorial.visibleTips.download_buttons ? "3px solid var(--ghost-brand-color)" : "3px solid transparent" }}
          >
            <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<BuildOutlined />} onClick={() => props.onPlot(result)} loading={loading_layout} size="small">
                Plot
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={plan_restrictions.can_pdf ? "Download Formatted PDF Report" : "Export PDF is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button ghost type="secondary" disabled={!plan_restrictions.can_pdf} icon={<FileZipOutlined />} onClick={() => props.onShowReport(result)} loading={loading_files} size="small">
                {show_live_report ? "Hide PDF Report" : "PDF Report"}
              </Button>
            </Tooltip>

            <ButtonGroup>
              <Tooltip
                placement="top"
                title={
                  editing_disabled
                    ? "Site editing is disabled when running with Inverter Groups or if the site exceeds 100 MWp."
                    : current_plan > 1
                    ? "Edit this Layout"
                    : "Layout Editing available on our Standard and Pro plans."
                }
                mouseEnterDelay={0.5}
              >
                <Popconfirm
                  title={"Editing this layout is strictly for map visualization and will not be reflected in this result's performance model."}
                  style={{ width: 250 }}
                  onConfirm={() => props.onShowLayoutEditor()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button ghost type="secondary" size="small" styles={{ borderRadius: "4px 0px 0px 4px" }} disabled={current_plan == 1 || editing_disabled}>
                    {show_layout_editor ? "Close Editor" : "Edit Layout"}
                  </Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title={current_plan > 1 ? "Restore original layout" : null} mouseEnterDelay={0.5}>
                <Popconfirm title="Undo All edits to Layout?" onConfirm={() => props.undoAllLayoutEditing()} okText="Yes" cancelText="No">
                  <Button type="secondary" size="small" label="Undo" disabled={!undo_available || loading_layout} />
                </Popconfirm>
              </Tooltip>
            </ButtonGroup>

            <Tooltip placement="top" title="Detailed data for selected result" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileZipOutlined />} onClick={() => props.onDownloadFiles(result)} loading={loading_files} size="small">
                XLSX
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={plan_restrictions.can_dxf ? "Export layout to DXF" : "Export to CAD is available on our Standard and Pro plans."} mouseEnterDelay={0.5}>
              <Button ghost disabled={!plan_restrictions.can_dxf} type="secondary" icon={<AppstoreOutlined />} onClick={() => props.onExportMap("export_dxf")} loading={map_loading} size="small">
                CAD
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
              <Button ghost type="secondary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_kml")} loading={map_loading} size="small">
                KMZ
              </Button>
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                plan_restrictions.can_3ds && track_mode !== 2 && !undo_available
                  ? "Export map to SHD"
                  : result.track_mode === 2
                  ? "SHD Export not available for East-West Facing"
                  : undo_available
                  ? "SHD Export not available when layout had been edited. Undo all edits to layout to enable SHD Export."
                  : "Export to SHD is available on our Standard and Pro plans."
              }
              mouseEnterDelay={0.5}
            >
              <Button
                ghost
                type="secondary"
                disabled={!plan_restrictions.can_3ds || result.track_mode === 2 || undo_available}
                icon={<FileImageOutlined />}
                onClick={() => props.onExportMap("export_pvsyst")}
                loading={map_loading}
                size="small"
              >
                SHD
              </Button>
            </Tooltip>
          </section>
        )}

        {/* <TutorialTip
          elementKey="download-buttons-tip"
          visible={tutorial.visibleTips.download_buttons}
          customTipWidth={screenWidth < 992 ? 210 : undefined}
          highlight={false}
          placement={screenWidth < 992 ? "bottom" : "right"}
          content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.download_buttons}
        >
        </TutorialTip> */}
      </SelectedButtonsWrap>

      <ResultDetailWrapper do_finance={result.do_finance}>
        <section id="masonry-effect">
          {Object.keys(result.summary).map((key, index) => {
            if (key === "tax_depriciation" && result.metric === 0) {
              return;
            } else if (Object.values(result.summary[key]).length > 0) {
              return (
                <section className="detail-box" key={index}>
                  <h3>{result.summary[key].label}</h3>
                  {Object.values(result.summary[key].value).map((item, index) => {
                    if (internal_sift && (item.label === "DC:AC" || item.label === "MW ac")) {
                      return;
                    } else {
                      if (item && item.value !== "") {
                        return (
                          <section className="detail-row" key={index}>
                            <label>{item.label}</label>
                            <span>{item.value}</span>
                          </section>
                        );
                      }
                    }
                  })}
                </section>
              );
            }
          })}
        </section>
      </ResultDetailWrapper>
    </section>
  );
};

export { ResultDetail };
