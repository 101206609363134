import * as turf from "@turf/turf";

import {
  fixProductDimensions,
  getRectFromOrigin,
  buildRectangleCells,
  default_canopy_inputs,
  create_UUID,
  getModuleDimsByOrientation,
  getAzimuthRectPosition,
  calculateModuleDimensions,
  buildRectBounds,
  count_modules,
} from "./Canopy.Helpers";

export function scale_canopy(event_geoJson, canopyInputs) {
  let scaled_canopy = {
    ...canopyInputs,
  };

  // console.log("canopyInputs", canopyInputs);

  // console.log(event_geoJson);
  let coords = event_geoJson.geometry.coordinates;
  // scaled_canopy.rotation = event_geoJson.properties["rotation"];
  // console.log("og coords", canopyInputs.coords);
  // console.log("new coords", coords[0]);
  let options = { units: "meters" };
  let origin_point = [scaled_canopy.origin.lng, scaled_canopy.origin.lat];

  let og_origin = [
    Math.min(
      ...canopyInputs.coords.map(function (p) {
        return p[0];
      })
    ),
    Math.min(
      ...canopyInputs.coords.map(function (p) {
        return p[1];
      })
    ),
  ];
  let new_origin_point = [
    Math.min(
      ...coords[0].map(function (p) {
        return p[0];
      })
    ),
    Math.min(
      ...coords[0].map(function (p) {
        return p[1];
      })
    ),
  ];

  // let new_origin_point = [coords[0][0][0], coords[0][0][1]]
  // console.log(origin_point, new_origin_point);

  // let og_origin = turf.flip(turf.point(canopyInputs.coords[0]))
  // console.log(og_origin);

  let y_dist = turf.distance(turf.point(coords[0][0]), turf.point(coords[0][3]), options);
  let base_y = scaled_canopy.module_dimensions.y * scaled_canopy.base_dimension.modY + (scaled_canopy.base_dimension.modY - 1) * scaled_canopy.modYGap + scaled_canopy.edgeOffset * 2;
  y_dist = Math.max(y_dist, base_y);

  let new_modY = Math.floor(y_dist / (scaled_canopy.modYGap + scaled_canopy.module_dimensions.y) + scaled_canopy.modYGap);
  let x_dist = turf.distance(turf.point(coords[0][0]), turf.point(coords[0][1]), options);
  let base_x = scaled_canopy.module_dimensions.x * scaled_canopy.base_dimension.modX + (scaled_canopy.base_dimension.modX - 1) * scaled_canopy.modXGap + scaled_canopy.edgeOffset * 2;
  x_dist = Math.max(x_dist, base_x);

  let new_modX = Math.floor(x_dist / (scaled_canopy.modXGap + scaled_canopy.module_dimensions.x) + scaled_canopy.modXGap);
  // 5.8933392525096755 /1.976
  let new_lat = new_origin_point[1];
  let new_lng = new_origin_point[0];

  let modX_change = Math.abs(scaled_canopy.dimensions.modX - new_modX) > 0;
  let modY_change = Math.abs(scaled_canopy.dimensions.modY - new_modY) > 0;

  // console.log(modX_change, modY_change);
  if (modX_change || modY_change) {
    scaled_canopy.origin = { lat: new_lat, lng: new_lng };
  }
  scaled_canopy.dimensions = {
    x: scaled_canopy.dimensions.x,
    y: scaled_canopy.dimensions.y,
    modX: new_modX,
    modY: new_modY,
  };
  scaled_canopy.dimensions = fixProductDimensions(scaled_canopy);

  scaled_canopy.modY = new_modY;
  scaled_canopy.modX = new_modX;

  if (scaled_canopy.planeType === "dual_tilt") {
    if (scaled_canopy.dimensions.modY < 3) {
      scaled_canopy.dimensions.modY = 3;
    } else if (scaled_canopy.dimensions.modY % 2 !== 0) {
      scaled_canopy.dimensions.modY = scaled_canopy.dimensions.modY + 1;
    }
    scaled_canopy.dimensions = fixProductDimensions(scaled_canopy);
  }

  scaled_canopy.coords = getRectFromOrigin(scaled_canopy.origin, scaled_canopy.dimensions, scaled_canopy.rotation);

  let block_cells = buildRectangleCells(scaled_canopy.origin, scaled_canopy, true);
  scaled_canopy.editCellsGeoJson = block_cells.cell_arr;
  scaled_canopy.visibleGeoJson = block_cells.visibleGeoJson;
  scaled_canopy.module_count = count_modules(scaled_canopy.editCellsGeoJson);
  // scaled_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

  scaled_canopy.geoJson = turf.polygon([scaled_canopy.coords]);
  scaled_canopy.geoJson.properties["rotation"] = scaled_canopy.rotation;

  scaled_canopy.azimuthHolder = getAzimuthRectPosition(scaled_canopy.geoJson.geometry.coordinates, scaled_canopy.rotation);

  // console.log("scaled", scaled_canopy);

  return scaled_canopy;
}

export function drag_canopy(event_geoJson, canopyInputs) {
  let dragged_canopy = {
    ...canopyInputs,
  };
  let coords = event_geoJson.geometry.coordinates;

  dragged_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
  // dragged_canopy.rotation = event_geoJson.properties["rotation"];
  dragged_canopy.coords = getRectFromOrigin(dragged_canopy.origin, dragged_canopy.dimensions, dragged_canopy.rotation);

  let block_cells = buildRectangleCells(dragged_canopy.origin, dragged_canopy, true);
  dragged_canopy.editCellsGeoJson = block_cells.cell_arr;
  dragged_canopy.visibleGeoJson = block_cells.visibleGeoJson;

  dragged_canopy.geoJson = turf.polygon([dragged_canopy.coords]);
  dragged_canopy.geoJson.properties["rotation"] = dragged_canopy.rotation;

  dragged_canopy.azimuthHolder = getAzimuthRectPosition(dragged_canopy.geoJson.geometry.coordinates, dragged_canopy.rotation);

  return dragged_canopy;
}

export function rotate_canopy(event_geoJson, canopyInputs, provided_rotation = undefined) {
  let rotated_canopy = {
    ...canopyInputs,
  };
  let coords = event_geoJson.geometry.coordinates;
  rotated_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };

  rotated_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
  rotated_canopy.rotation = provided_rotation;

  // We have to fix the Azimuth since we use 180deg is south in solar
  let azimuth = provided_rotation + 180;
  if (azimuth > 360) {
    azimuth -= 360;
  }
  rotated_canopy.azimuth = azimuth;

  // rotated_canopy.rotation = event_geoJson.properties["rotation"];
  rotated_canopy.coords = getRectFromOrigin(rotated_canopy.origin, rotated_canopy.dimensions, rotated_canopy.rotation);

  let block_cells = buildRectangleCells(rotated_canopy.origin, rotated_canopy, true);
  rotated_canopy.editCellsGeoJson = block_cells.cell_arr;
  rotated_canopy.visibleGeoJson = block_cells.visibleGeoJson;

  rotated_canopy.geoJson = turf.polygon([rotated_canopy.coords]);
  rotated_canopy.geoJson.properties["rotation"] = rotated_canopy.rotation;

  rotated_canopy.azimuthHolder = getAzimuthRectPosition(rotated_canopy.geoJson.geometry.coordinates, rotated_canopy.rotation);
  // let fix_rotation_degrees = 0;
  // let rotation = provided_rotation;
  // if (provided_rotation == undefined) {
  //   // rotation = event_geoJson.properties["rotation"];
  //   rotation = canopyInputs.rotation;
  //   let rot_deg = rotation * (180 / Math.PI);
  //   fix_rotation_degrees = rot_deg > 180 ? rot_deg - 360 : rot_deg;
  //   fix_rotation_degrees = Math.round(fix_rotation_degrees, 0);
  //   rotated_canopy.coords = getRectFromOrigin(rotated_canopy.origin, rotated_canopy.dimensions, rotated_canopy.rotation + fix_rotation_degrees);

  //   rotated_canopy.rotation = rotated_canopy.rotation + fix_rotation_degrees;
  // } else {
  //   fix_rotation_degrees = provided_rotation;
  //   rotated_canopy.rotation += fix_rotation_degrees;
  //   let center = turf.center(rotated_canopy.geoJson);
  //   let pivotOptions = { pivot: [center.geometry.coordinates[0], center.geometry.coordinates[1]] };
  //   let translatedPolygon = turf.transformRotate(rotated_canopy.geoJson, provided_rotation, pivotOptions);
  //   rotated_canopy.geoJson = translatedPolygon;
  //   // grab new origin
  //   rotated_canopy.origin = { lat: translatedPolygon.geometry.coordinates[0][0][1], lng: translatedPolygon.geometry.coordinates[0][0][0] };
  //   // rebuild rotated_canopy/recangle
  //   rotated_canopy.coords = getRectFromOrigin(rotated_canopy.origin, rotated_canopy.dimensions, rotated_canopy.rotation);
  // }

  // let block_cells = buildRectangleCells(rotated_canopy.origin, rotated_canopy, true);
  // rotated_canopy.editCellsGeoJson = block_cells.cell_arr;
  // rotated_canopy.visibleGeoJson = block_cells.visibleGeoJson;

  // rotated_canopy.azimuth += fix_rotation_degrees;
  // rotated_canopy.azimuth = rotated_canopy.azimuth < 0 ? rotated_canopy.azimuth + 360 : rotated_canopy.azimuth;
  // rotated_canopy.azimuth = Math.abs(rotated_canopy.azimuth) % 360;

  // rotated_canopy.rotation = rotated_canopy.azimuth - 180;

  // rotated_canopy.geoJson = turf.polygon([rotated_canopy.coords]);
  // rotated_canopy.geoJson.properties["rotation"] = rotated_canopy.rotation;

  // rotated_canopy.azimuthHolder = getAzimuthRectPosition(rotated_canopy.geoJson.geometry.coordinates, rotated_canopy.rotation);

  return rotated_canopy;
}

export function create_canopy(origin, module) {
  // let num = Object.keys(carport.rectangles).length + 1;

  let canopy_object = {
    // spread copy of defaults
    ...JSON.parse(JSON.stringify(default_canopy_inputs)),
    // create new id
    id: create_UUID(),
    // set origin (where map was clicked)
    origin: origin,
    // set module (passed from store, whichever is selected)
    module: module,
    module_locked: true,
    module_dimensions: getModuleDimsByOrientation(default_canopy_inputs.orientation, module),
    name: `Canopy (${create_UUID().substring(0, 4)})`,
    disabled_module_indexes: [],
    shape: "Rectangle",
  };

  canopy_object.dimensions = { ...canopy_object.base_dimension };
  canopy_object.dimensions = fixProductDimensions(canopy_object);
  canopy_object.coords = getRectFromOrigin(origin, canopy_object.dimensions);
  canopy_object.geoJson = turf.polygon([canopy_object.coords]);
  canopy_object.geoJson.properties["rotation"] = 0;
  // creating visible geoJSON -- which is the underlying cells that makeup the canopy
  let block_cells = buildRectangleCells(origin, canopy_object);
  canopy_object.editCellsGeoJson = block_cells.cell_arr;
  canopy_object.visibleGeoJson = block_cells.visibleGeoJson;
  canopy_object.module_count = count_modules(canopy_object.editCellsGeoJson);

  // canopy_object.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
  canopy_object.bounds = buildRectBounds(canopy_object.geoJson.geometry.coordinates[0]);
  // TODO later
  canopy_object.azimuthHolder = getAzimuthRectPosition(canopy_object.geoJson.geometry.coordinates, canopy_object.rotation);
  // canopy_object.alignmentLines = drawAlignmentLines(canopy_object);
  // canopy_object.alignmentCircles = drawAlignmentCircles(canopy_object.coords);

  return canopy_object;
}

export function duplicate_canopy(direction, canopyToDuplicate, name = undefined) {
  // get selected rectangle
  let duplicate_canopy = {
    ...JSON.parse(JSON.stringify(canopyToDuplicate)),
    id: create_UUID(),
  };
  // update name & index
  duplicate_canopy.name = `${duplicate_canopy.name}`;
  // figure out which way this canopy is being shifted
  let shift_angle = direction === "right" ? 90 : direction === "left" ? -90 : direction === "up" ? 0 : direction === "down" ? 180 : 0;
  // account for previous rotation
  shift_angle = shift_angle + duplicate_canopy.rotation;
  // figure out the distance canopy will be shifted
  let shift_dist = direction === "right" || direction === "left" ? duplicate_canopy.dimensions.x : direction === "up" || direction === "down" ? duplicate_canopy.dimensions.y : 5;
  // translate the canopy to new location
  let turfOptions = { units: "meters" };
  duplicate_canopy.geoJson = turf.transformTranslate(duplicate_canopy.geoJson, shift_dist, shift_angle, turfOptions);
  // grab new coords & update canopy properties
  let coords = turf.getCoords(duplicate_canopy.geoJson);
  duplicate_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
  duplicate_canopy.coords = coords[0];
  // redraw modules
  // duplicate_canopy.modules = drawModules(duplicate_canopy);
  let block_cells = buildRectangleCells(duplicate_canopy.origin, duplicate_canopy, true);
  duplicate_canopy.editCellsGeoJson = block_cells.cell_arr;
  duplicate_canopy.visibleGeoJson = block_cells.visibleGeoJson;
  // duplicate_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

  // duplicate_canopy.alignmentLines = drawAlignmentLines(duplicate_canopy);
  // duplicate_canopy.alignmentCircles = drawAlignmentCircles(duplicate_canopy.coords);
  duplicate_canopy.azimuthHolder = getAzimuthRectPosition(duplicate_canopy.geoJson.geometry.coordinates, duplicate_canopy.rotation);
  return duplicate_canopy;
}

export function paste_canopy(origin, copiedCanopy, name = undefined) {
  let pasted_canopy = {
    // spread copy of defaults
    ...JSON.parse(JSON.stringify(copiedCanopy)),
    // create new id
    id: create_UUID(),
    // set origin (where map was clicked)
    origin: origin,
  };
  // pasted_canopy.name = `${copiedCanopy.name} (Copy)`;
  pasted_canopy.coords = getRectFromOrigin(origin, pasted_canopy.dimensions, pasted_canopy.rotation);
  pasted_canopy.geoJson = turf.polygon([pasted_canopy.coords]);
  pasted_canopy.geoJson.properties["rotation"] = pasted_canopy.rotation;
  // creating visible geoJSON -- which is the underlying cells that makeup the canopy
  let block_cells = buildRectangleCells(origin, pasted_canopy, true);
  pasted_canopy.editCellsGeoJson = block_cells.cell_arr;
  pasted_canopy.visibleGeoJson = block_cells.visibleGeoJson;
  // pasted_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

  // TODO later
  pasted_canopy.azimuthHolder = getAzimuthRectPosition(pasted_canopy.geoJson.geometry.coordinates, pasted_canopy.rotation);
  // pasted_canopy.alignmentLines = drawAlignmentLines(pasted_canopy);
  // pasted_canopy.alignmentCircles = drawAlignmentCircles(pasted_canopy.coords);

  return pasted_canopy;
}

export function redraw_canopy(selectedInputs, eventType, canopy = undefined) {
  let redrawn_canopy = { ...JSON.parse(JSON.stringify(selectedInputs)), dimensions: { ...selectedInputs.dimensions, modX: selectedInputs.modX, modY: selectedInputs.modY } };
  // let redrawn_canopy = {
  //   ...canopy,
  //   name: selectedInputs.name,
  //   azimuth: selectedInputs.azimuth,
  //   orientation: selectedInputs.orientation,
  //   dimensions: {
  //     ...selectedInputs.dimensions,
  //     modX: selectedInputs.modX,
  //     modY: selectedInputs.modY,
  //   },
  //   module: { ...selectedInputs.module },
  //   module_dimensions: {
  //     ...canopy.module_dimensions,
  //     mod_width: selectedInputs.module.mod_width,
  //     mod_height: selectedInputs.module.mod_height,
  //   },
  //   modGap: selectedInputs.modGap,

  //   planeType: selectedInputs.type,
  //   tilt: selectedInputs.tilt,
  //   towardAzimuth: selectedInputs.towardAzimuth,
  //   tiltToAzimuth: selectedInputs.tiltToAzimuth,
  //   tiltFromAzimuth: selectedInputs.tiltFromAzimuth,
  // };

  if (eventType === "modX" || eventType === "modY") {
    redrawn_canopy.dimensions.modX = Math.max(selectedInputs.modX, redrawn_canopy.base_dimension.modX);
    redrawn_canopy.dimensions.modY = Math.max(selectedInputs.modY, redrawn_canopy.base_dimension.modY);
    redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions, redrawn_canopy.rotation);
  }
  if (eventType === "azimuth") {
    let new_azimuth = Math.abs(selectedInputs.azimuth) % 360;
    redrawn_canopy.azimuth = new_azimuth;
    let rotation_diff = new_azimuth - 180 - redrawn_canopy.rotation;
    redrawn_canopy.rotation = new_azimuth - 180;

    let centroid = turf.centroid(redrawn_canopy.geoJson);
    let turfOptions = { pivot: [centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]] };
    let rotatedPoly = turf.transformRotate(redrawn_canopy.geoJson, rotation_diff || 0, turfOptions);
    let coords = turf.getCoords(rotatedPoly);

    redrawn_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
    redrawn_canopy.coords = coords[0];
  }
  if (eventType === "mod_width" || eventType === "mod_height") {
    redrawn_canopy.module_dimensions = getModuleDimsByOrientation(redrawn_canopy.orientation, redrawn_canopy.module);
    redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions, redrawn_canopy.rotation);
  }
  if (eventType === "module") {
    redrawn_canopy.module_dimensions = getModuleDimsByOrientation(redrawn_canopy.orientation, redrawn_canopy.module);
    redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions, redrawn_canopy.rotation);
  }
  if (eventType === "type") {
    if (redrawn_canopy.planeType === "dual_tilt") {
      if (redrawn_canopy.dimensions.modY < 3) {
        redrawn_canopy.dimensions.modY = 3;
      } else if (redrawn_canopy.dimensions.modY % 2 !== 0) {
        redrawn_canopy.dimensions.modY = redrawn_canopy.dimensions.modY + 1;
      }
      redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    }
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions, redrawn_canopy.rotation);
  }
  // modXGap happens during a global override
  if (eventType === "modGap" || eventType === "modXGap") {
    redrawn_canopy.modXGap = redrawn_canopy.modGap;
    redrawn_canopy.modYGap = redrawn_canopy.modGap;
    redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions, redrawn_canopy.rotation);
  }

  if (eventType === "orientation") {
    redrawn_canopy.module_dimensions = getModuleDimsByOrientation(redrawn_canopy.orientation, redrawn_canopy.module_dimensions);
    redrawn_canopy.dimensions = calculateModuleDimensions(redrawn_canopy);
    redrawn_canopy.dimensions = fixProductDimensions(redrawn_canopy);
    redrawn_canopy.coords = getRectFromOrigin(redrawn_canopy.origin, redrawn_canopy.dimensions);
    redrawn_canopy.modY = redrawn_canopy.dimensions.modY;
    redrawn_canopy.modX = redrawn_canopy.dimensions.modX;
    redrawn_canopy.geoJson = turf.polygon([redrawn_canopy.coords]);

    // handle canopy rotation during orientation change
    let pivotOptions = { pivot: [redrawn_canopy.geoJson.geometry.coordinates[0][0][0], redrawn_canopy.geoJson.geometry.coordinates[0][0][1]] };
    redrawn_canopy.geoJson = turf.transformRotate(redrawn_canopy.geoJson, redrawn_canopy.rotation, pivotOptions);

    let block_cells = buildRectangleCells(redrawn_canopy.origin, redrawn_canopy, true);
    redrawn_canopy.editCellsGeoJson = block_cells.cell_arr;
    redrawn_canopy.visibleGeoJson = block_cells.visibleGeoJson;
    redrawn_canopy.module_count = count_modules(redrawn_canopy.editCellsGeoJson);

    // redrawn_canopy.alignmentLines = drawAlignmentLines(redrawn_canopy);
    // redrawn_canopy.alignmentCircles = drawAlignmentCircles(redrawn_canopy.coords);

    redrawn_canopy.azimuthHolder = getAzimuthRectPosition(redrawn_canopy.geoJson.geometry.coordinates, redrawn_canopy.rotation);

    return redrawn_canopy;
  }

  redrawn_canopy.geoJson = turf.polygon([redrawn_canopy.coords]);
  redrawn_canopy.geoJson.properties["rotation"] = redrawn_canopy.rotation;
  let block_cells = buildRectangleCells(redrawn_canopy.origin, redrawn_canopy, true);
  redrawn_canopy.editCellsGeoJson = block_cells.cell_arr;
  redrawn_canopy.visibleGeoJson = block_cells.visibleGeoJson;
  redrawn_canopy.module_count = count_modules(redrawn_canopy.editCellsGeoJson);

  redrawn_canopy.azimuthHolder = getAzimuthRectPosition(redrawn_canopy.geoJson.geometry.coordinates, redrawn_canopy.rotation);

  return redrawn_canopy;
}

export function non_rect_edit(event_geoJson, canopyInputs) {
  // the event_geoJson has been updated from the NonRect.. component
  // with enabled/disabled for each cell, so we just need to update
  // blocks_arr and create a new visual
  let new_canopy = {
    ...canopyInputs,
    disabled_module_indexes: event_geoJson,
  };
  // _ is lodash and .zip loops over two equally sized arrays
  // and gives you access to both
  // _.zip(event_geoJson, new_canopy.visibleGeoJson).map(value => {
  // 	value[1].properties.enabled = value[0].properties.enabled
  // })

  let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);

  // if (block_cells.visibleGeoJson == false) {
  //   block_cells = buildRectangleCells(new_canopy.origin, new_canopy);
  // }
  new_canopy.editCellsGeoJson = block_cells.cell_arr;
  new_canopy.visibleGeoJson = block_cells.visibleGeoJson;
  new_canopy.module_count = count_modules(new_canopy.editCellsGeoJson);

  return new_canopy;
}

export function translate_canopy(canopy, direction) {
  let translated_canopy = {
    ...canopy,
  };
  let options = { units: "meters" };
  let distance = 1 / 3.281; // convert ft > meters
  // shift canopy
  let translatedPolygon = turf.transformTranslate(translated_canopy.geoJson, distance, direction, options);

  // grab new origin
  translated_canopy.origin = { lat: translatedPolygon.geometry.coordinates[0][0][1], lng: translatedPolygon.geometry.coordinates[0][0][0] };
  // rebuild translated_canopy/recangle
  translated_canopy.coords = getRectFromOrigin(translated_canopy.origin, translated_canopy.dimensions, translated_canopy.rotation);

  translated_canopy.geoJson = turf.polygon([translated_canopy.coords]);
  translated_canopy.geoJson.properties["rotation"] = translated_canopy.rotation;

  let block_cells = buildRectangleCells(translated_canopy.origin, translated_canopy, true);
  translated_canopy.editCellsGeoJson = block_cells.cell_arr;
  translated_canopy.visibleGeoJson = block_cells.visibleGeoJson;

  translated_canopy.azimuthHolder = getAzimuthRectPosition(translated_canopy.geoJson.geometry.coordinates, translated_canopy.rotation);
  return translated_canopy;
}
