import { authHeader } from "../_helpers";
import { inputsConstants } from "../_constants";
import { poll_job, upload_to_s3 } from "./basics.jobs";
import { alertActions } from "../_actions";
import config from "config";
import fetchUtil from "../_services/fetchUtil";

export async function execute_hori_job(inputs = undefined, type = "sift-import") {
  let job_inputs = { ...inputs, job_type: type, file_type: "horizon" };

  const requestOptions = {
    method: "POST",
    // headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...job_inputs }),
    // body: JSON.stringify({ ...inputs, job_type: type }),
  };
  return fetchUtil(`${config.apiUrl}/dash/import/`, requestOptions);
}

export const fetch_horizon_shading = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_type: "sift-import",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux
    async run(dispatch) {
      // update store to show that we are grabbing shading
      // get job_id and url from placing data into
      dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "horizon_shade_loading", value: true });

      let job_url_req = await execute_hori_job(inputs, "sift-import");

      inputs.job_id = job_url_req.run_id;

      // place inputs into bucket
      let new_inputs = JSON.stringify({ ...inputs });

      let upload_resp = await upload_to_s3(job_url_req.url, new_inputs);

      if (upload_resp.error) {
        let error = upload_resp.error;
        console.log("there seems to have been and error", error);
        dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "horizon_shade_loading", value: false });
      }

      //poll for status
      let init_poll_resp = await poll_job(inputs, "sift-import", dispatch);

      //check for errors, and if there no errors, grab results and return results
      // console.log("init_poll_resp horizon shading job", init_poll_resp);
      if (init_poll_resp.error) {
        dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "horizon_shade_loading", value: false });
        dispatch(alertActions.error(`${init_poll_resp.err_details[0]}`));
        return init_poll_resp;
      }
      // console.log("hori_shade_stuff", init_poll_resp);
      let results = init_poll_resp.output.results;

      dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "horizon_shade_loading", value: false });
      dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "horizon_data", value: results });

      // update store with results and turn loading indicators off.
    },
  };
};
