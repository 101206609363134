import React, { useState, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";

import { canopyActions, usePrevious } from "../../Redux";

import { TreeEventLayer, TreeLineEventLayer, BuildingLayer, BuildingEventLayer } from "./";

const ShadeLayer = ({ interactive = true }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const delete_shade_id = useSelector((state) => state.canopy.delete_shade_id);
  const prev_shade_objects = usePrevious(shade_objects);

  const selected_shade_id_ref = useRef();

  useEffect(() => {
    selected_shade_id_ref.current = selected_shade_object_id;
  }, [selected_shade_object_id]);

  useEffect(() => {
    map.on("click", (e) => {
      if (selected_shade_id_ref.current) {
        map.eachLayer((layer) => layer.options.id == selected_shade_id_ref.current && layer.pm.disable());
        // dispatch(canopyActions.updateCanopyInputs("selected_shade_object_id", undefined));
      }
    });
    return () => map.off("click");
  }, []);

  useEffect(() => {
    if (prev_shade_objects && Object.values(prev_shade_objects).length > 0 && Object.values(shade_objects).length == 0) {
      map.eachLayer((layer) => layer.options.obj_type && layer.options.obj_type == "shade_obj" && layer.remove());
    }
  }, [shade_objects, prev_shade_objects]);

  useEffect(() => {
    if (delete_shade_id == "delete_all") {
      map.eachLayer((layer) => (layer.options.type == "tree" || layer.options.type == "treeLine " || layer.options.type == "building") && layer.remove());
    } else if (delete_shade_id) {
      map.eachLayer((layer) => layer.options.id == delete_shade_id && layer.remove());
    }
  }, [delete_shade_id]);

  return (
    <>
      {shade_objects &&
        Object.values(shade_objects)?.length > 0 &&
        Object.values(shade_objects)?.map((shade) => {
          if (shade.type == "tree") {
            return <TreeEventLayer key={shade.id} id={shade.id} interactive={interactive} />;
          } else if (shade.type == "treeLine") {
            return <TreeLineEventLayer key={shade.id} id={shade.id} interactive={interactive} />;
          } else {
            return <BuildingEventLayer key={shade.id} id={shade.id} interactive={interactive} />;
          }
        })}
    </>
  );
};

export { ShadeLayer };
