/*
	MapFeatureHandler.js
	This handles ALL feature manipulation/display/interactions
	with features within the map (GeoJSON, Polygon, etc)
*/

import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";
import { LayerGroup, GeoJSON } from "react-leaflet";

import { siftActions, inputsActions, usePrevious } from "../../../Redux";
import { MapFeatureOverrides } from "./MapFeatureOverrides";
import { useKey } from "../MapEventHandler/useKey";

import { OffsetPolygon } from "../OffsetPolygon/OffsetPolygon";
import L from "leaflet";

//drawing helpers
import { handleFeatureCreated, handlePolylineCreated, getArea, getAcreage } from "./_draw.helpers";

const MapFeatureHandler = () => {
  // get the map from parent
  const map = useMap();
  const dispatch = useDispatch();

  const site_features = useSelector((state) => state.inputs.site_features);
  const project_loading = useSelector((state) => state.projects.loading);
  const project_loaded = useSelector((state) => state.projects.loaded);
  const topo_id = useSelector((state) => state.sift.ioManager.inputs.topo.topo_id);
  const show_offset_tool = useSelector((state) => state.sift.ioManager.uiState.show_offset_tool);
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const undo_redo_present = useSelector((state) => state.undoRedo.present);
  const cur_hover_id = useSelector((state) => state.inputs.cur_hover_id);
  const prevSelectedId = usePrevious(selectedFeatureId);
  const prevProjectLoading = usePrevious(project_loading);
  const prev_undo_redo_present = usePrevious(undo_redo_present);

  const [abort_select, set_abort_select] = useState(false);

  // hotkey stuff
  const delete_feature_hotkey_mac = useKey("backspace");
  const delete_feature_hotkey = useKey("delete");
  const deselect_hotkey = useKey("escape");
  const cycle_hotkey = useKey("n");

  useMapEvents({
    completePolyFromHotkey: (e) => _onCreateFeature(e.current_polygon, true),
    deleteFeatures: () => deleteAllFeatures(),
  });

  useEffect(() => {
    if (prevProjectLoading == false && project_loading == true && project_loaded == false) {
      set_abort_select(true);
    }

    if (prevProjectLoading == true && project_loading == false && project_loaded == true) {
      setTimeout(() => {
        map.fire("checkTileLayer");
        map.fire("zoomextents");
      }, 400);
    }
  }, [project_loaded, project_loading, prevProjectLoading]);

  useEffect(() => {
    if (cur_hover_id) {
      // highlight
      let layer = getLayer(cur_hover_id);

      if (layer) {
        // layer.setStyle({ weight: 3, fillOpacity: 0.5, fillColor: "var(--primary-brand-color)" });
        if (layer.feature.geometry.type == "Polygon") {
          layer.setStyle({ weight: 3, fillOpacity: 0.5, fillColor: "var(--primary-brand-color)" });
        } else {
          layer.setStyle({ weight: 3, color: "var(--primary-brand-color)" });
        }
      }
    } else {
      // unhighlight
      let layer = getLayer(cur_hover_id);

      if (layer) {
        layer.setStyle({ weight: 1, fillOpacity: 0.01, fillColor: "#ffffff" });
      }
    }
  }, [cur_hover_id]);

  useEffect(() => {
    if (prev_undo_redo_present != undo_redo_present) {
      set_abort_select(true);
    }
  }, [undo_redo_present]);

  const mapHasFocus = () => {
    return document.getElementById("map") == document.activeElement;
  };

  const focusMap = () => {
    document.getElementById("map").focus();
  };

  useEffect(() => {
    if (!mapHasFocus()) return;
    if (!selectedFeatureId) return;
    if ((delete_feature_hotkey || delete_feature_hotkey_mac) && mapHasFocus()) _onDeleteFeature(selectedFeatureId);
  }, [delete_feature_hotkey, delete_feature_hotkey_mac]);

  useEffect(() => {
    if (!mapHasFocus()) return;
    if (!selectedFeatureId) return;
    if (deselect_hotkey && mapHasFocus()) dispatch(inputsActions.update_site_input("selectedFeatureId", undefined));
  }, [deselect_hotkey]);

  useEffect(() => {
    if (!mapHasFocus()) return;
    if (!selectedFeatureId) return;
    if (cycle_hotkey) cycleFeatures();
  }, [cycle_hotkey]);

  useEffect(() => {
    if (prevSelectedId != selectedFeatureId) {
      // a different poly was selected
      if (prevSelectedId) _stopEditingFeature(getLayer(prevSelectedId));
      if (selectedFeatureId) _startEditingFeature(getLayer(selectedFeatureId));
      if (selectedFeatureId && show_offset_tool) {
        dispatch(siftActions.updateUIState("show_offset_tool", false));
      }
    }
  }, [selectedFeatureId]);

  useEffect(() => {
    if (!map) return;
    // feature created
    map.on(L.Draw.Event.CREATED, (e) => {
      if (e.layerType == "polygon") {
        _onCreateFeature(e);
        _onCancelDraw(e);
      } else {
        _onCreatePolyline(e);
        _onCancelDraw(e);
      }
      set_abort_select(false);
    });
    map.on("draw:canceled", (e) => {
      _onCancelDraw(e);
    });

    return () => {
      map.off(L.Draw.Event.CREATED);
      map.off("draw:canceled");
    };
  }, [map]);

  useEffect(() => {
    if (!site_features) return;
    calculateTotalArea();
  }, [site_features]);

  const onEachFeature = (feature, layer) => {
    layer.on({
      add: () => {
        if (abort_select) return;
        _startEditingFeature(layer, feature.properties.index);
      },
      click: () => {
        _onClickFeature(layer, feature.properties.index);
      },
      edit: () => {
        if (layer.feature.geometry.type == "Polygon") {
          _onEditFeature(layer);
        } else {
          _onEditPolyline(layer);
        }
      },
      // "pm:edit": () => _onEditFeature(layer),
    });
  };

  const _onCreateFeature = (layer, completeFromHotkey = false) => {
    let geoJson = handleFeatureCreated(layer, completeFromHotkey);
    dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
    focusMap();
    // dispatch(siftActions.createFeature(geoJson, geoJson.properties.index));
  };

  const _onCreatePolyline = (layer, completeFromHotkey = false) => {
    let geoJson = handlePolylineCreated(layer, completeFromHotkey);
    // console.log("geoJson", geoJson);
    dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
    focusMap();
    // dispatch(siftActions.createFeature(geoJson, geoJson.properties.index));
  };

  // const _onDrawVertex = (e) => {
  //   console.log("_onDrawVertex", e);
  // };

  const _onEditPolyline = (layer) => {
    console.log("layer edited:", layer);
    let geoJson = layer.toGeoJSON();
    dispatch(siftActions.updateFeature(geoJson));
    dispatch(inputsActions.update_site_input("edit_site_feature", geoJson));
  };

  const _onEditFeature = (layer) => {
    let geoJson = layer.toGeoJSON();
    geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
    geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
    dispatch(siftActions.updateFeature(geoJson));
    dispatch(inputsActions.update_site_input("edit_site_feature", geoJson));
  };

  const _onCancelDraw = (event = undefined) => {
    if (event.layerType == "polygon") {
      dispatch(inputsActions.update_ui_state("is_drawing", false));
    } else {
      dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
    }
  };

  const _startEditingFeature = (layer, id = undefined) => {
    if (!layer) return;
    layer.editing.enable();
    // layer.pm.enable({ limitMarkersToCount: 100 });
  };

  const _stopEditingFeature = (layer, id = undefined) => {
    if (!layer) return;
    layer.editing.disable();
    // layer.pm.disable({ limitMarkersToCount: 100 });
  };

  const cycleFeatures = () => {
    if (Object.keys(site_features).length > 1) {
      let ids = Object.keys(site_features);
      let current_index = ids.findIndex((id) => id == selectedFeatureId);
      // console.log( ids.length, current_index)
      let next_index = current_index + 1 == ids.length ? 0 : current_index + 1;
      dispatch(inputsActions.update_site_input("selectedFeatureId", ids[next_index]));

      // dispatch(siftActions.selectFeatureById(ids[next_index]));
    }
  };

  const _onDeleteFeature = (id) => {
    dispatch(inputsActions.update_site_input("remove_site_feature", id));
  };

  const _onClickFeature = (e, id) => {
    console.log("e", e, id);
    // stop other mouse events on leaflet map
    if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);

    // prevent trying to select the same rectangle
    // if (selectedFeatureId == id) return;
    _startEditingFeature(e);
    // dispatch(siftActions.selectFeatureById(id));
    dispatch(inputsActions.update_site_input("selectedFeatureId", id));
  };

  const getLayer = (id) => {
    let temp_layer;
    map.eachLayer((layer) => {
      if (layer.feature && layer.feature.properties.index == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  const calculateTotalArea = () => {
    let total_area = 0;
    let total_acreage = 0;
    Object.values(site_features).forEach((feature) => {
      if (feature.properties.identity == 1) {
        total_area += getArea(feature.geometry.coordinates);
        total_acreage += getAcreage(feature.geometry.coordinates);
      }
    });
    dispatch(siftActions.updateTotalArea(total_area, total_acreage));
  };

  const deleteAllFeatures = () => {
    map.fire("cancelDraw");
    if (selectedFeatureId) _stopEditingFeature(getLayer(selectedFeatureId));

    dispatch(inputsActions.update_input("site_features", []));

    if (topo_id) {
      dispatch(inputsActions.clear_topo_data());
    }
  };

  return (
    <>
      <MapFeatureOverrides />
      <LayerGroup>
        {Object.values(site_features).map((feature) => {
          if (feature.geometry.type == "LineString") {
            {
              /* console.log("linestring feature", feature); */
            }
          }
          let visible = feature.properties.checked == undefined ? true : feature.properties.checked ? true : false;
          return (
            <Fragment key={feature.properties.index}>
              {visible && (
                <GeoJSON
                  data={feature}
                  transform={false}
                  draggable={false}
                  maxVertices={455}
                  key={feature.properties.index}
                  onEachFeature={onEachFeature}
                  bubblingMouseEvents={false}
                  type="user_map_feature"
                  style={{
                    fillColor: "transparent",
                    // fillOpacity: 0.01,
                    weight: feature.geometry.type == "Polygon" ? 1 : 3,
                    color: feature.properties.identity == 0 ? "white" : feature.properties.identity == 1 ? "red" : feature.properties.identity == 2 && "yellow",
                  }}
                >
                  {selectedFeatureId === feature.properties.index && <OffsetPolygon feature_id={feature.properties.index} />}
                </GeoJSON>
              )}
            </Fragment>
          );
        })}
      </LayerGroup>
    </>
  );
};

export { MapFeatureHandler };
