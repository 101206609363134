import React, { Fragment } from "react";

import { canopyInputs } from "./CanopyInputsData";

import { CanopyInputsWrapper } from "../styles";

function CanopyInputs() {
  return (
    <CanopyInputsWrapper>
      {Object.values(canopyInputs).map((input, index) => {
        return (
          <Fragment key={index}>
            <label>{input.label}</label>
            {input.component}
          </Fragment>
        );
      })}
    </CanopyInputsWrapper>
  );
}

export { CanopyInputs };
