import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Marker, Popup, useMapEvents } from "react-leaflet";
import { useKey } from "../MapEventHandler/useKey";

import { siftActions, inputsActions } from "../../../Redux";
import { invisibleMarker } from "../../../../assets/images";

import { isLatitude, isLongitude } from "../../../Redux";

import L from "leaflet";
import { Radio } from "antd";

const OffsetPolygon = ({ feature_id }) => {
  const dispatch = useDispatch();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const show_offset_tool = useSelector((state) => state.sift.ioManager.uiState.show_offset_tool);
  const site_features = useSelector((state) => state.inputs.site_features);
  const feature = site_features[selectedFeatureId];

  const [offset_loading, set_offset_loading] = useState(false);
  const [menu_latlng, set_menu_latlng] = useState({ lat: undefined, lng: undefined });
  const [offset_distance, set_offset_distance] = useState(25);
  //const [offsetType, setOffsetType] = useState("m");
  const offsetType = useSelector((state) => state.inputs.offset_type);
  // const [local_feature, set_local_feature] = useState(feature);

  const updateOffSetType = (event) => {
    //setOffsetType(event.target.value);
    dispatch(inputsActions.update_offset_type("offset_type", event.target.value));
  };
  useMapEvents({
    toggleOffset: (e) => toggle_offset_polygon(e),
  });

  // hotkey
  let offset_poly_hotkey = useKey("o");

  const mapHasFocus = () => {
    return document.getElementById("map") == document.activeElement;
  };

  useEffect(() => {
    if (selectedFeatureId !== feature_id) return;
    if (!mapHasFocus()) return;
    if (!feature) return;

    if (offset_poly_hotkey && feature_id == selectedFeatureId) {
      toggle_offset_polygon();
    }
  }, [offset_poly_hotkey]);

  useEffect(() => {
    if (!feature) return;
    // console.log("feature", feature.properties.center);
    set_menu_latlng({ lat: feature.properties.center[1], lng: feature.properties.center[0] });
  }, [selectedFeatureId]);

  const getIcon = () => {
    return new L.Icon({
      iconUrl: invisibleMarker,
    });
  };

  // Convert feet to meters
  function feetToMeters(feet) {
    return feet * 0.3048;
  }
  const offsetPolygon = (offsetDirection, saveOffset = false) => {
    let distance = offsetType === "ft" ? feetToMeters(offset_distance) : offset_distance;
    console.log("distance - " + distance);
    dispatch(siftActions.offsetPolygon(feature, parseFloat(distance) * (offsetDirection == "inside" ? -1 : 1)));
    set_offset_loading(false);
    set_offset_distance(25);
  };

  const toggle_offset_polygon = (e = undefined) => {
    if (selectedFeatureId !== feature_id) return;
    if (e && e.selected_from == "context") {
      dispatch(inputsActions.update_site_input("selectedFeatureId", e.id));
      // dispatch(siftActions.selectFeatureById(e.id));
      setTimeout(() => {
        dispatch(siftActions.updateUIState("show_offset_tool", !show_offset_tool));
      }, 500);
    } else {
      dispatch(siftActions.updateUIState("show_offset_tool", !show_offset_tool));
    }
  };

  return (
    <>
      {feature && feature_id == selectedFeatureId && show_offset_tool && menu_latlng.lat && menu_latlng.lng && (
        <Marker
          position={[menu_latlng.lat, menu_latlng.lng]}
          icon={getIcon()}
          eventHandlers={{
            add: (e) => {
              e.target.openPopup();
            },
          }}
        >
          <Popup>
            <section className="offset-tool" style={{ width: 270 }}>
              <div className="offset-tool-close">
                {offset_loading && (
                  <div className="offset-loading">
                    <p>Loading</p>
                    <Spinner color={"var(--primary-brand-color)"} />
                  </div>
                )}
              </div>
              <div className="offset-tool-input">
                <div>
                  <label>Offset Distance </label>
                  <Radio.Group buttonStyle="solid" size="small" value={offsetType} onChange={updateOffSetType}>
                    <Radio.Button key="feet" value="ft" className="offset-tool-button">
                      ft
                    </Radio.Button>
                    <Radio.Button key="meter" value="m" className="offset-tool-button">
                      m
                    </Radio.Button>
                  </Radio.Group>
                </div>

                <input
                  style={{ outlineColor: "var(--primary-brand-color", width: 134 }}
                  type="Number"
                  step="1"
                  value={offset_distance}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => set_offset_distance(e.target.value)}
                />
              </div>
              <div className="offset-tool-buttons">
                <button
                  // onMouseEnter={() => offsetPolygon('inside')}
                  // onMouseLeave={() => offsetPolygon('clearOffset')}
                  onClick={() => offsetPolygon("inside", true)}
                >
                  Inside
                </button>
                <button
                  // onMouseEnter={() => offsetPolygon('outside')}
                  // onMouseLeave={() => offsetPolygon('clearOffset')}
                  onClick={() => offsetPolygon("outside", true)}
                >
                  Outside
                </button>
              </div>
            </section>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export { OffsetPolygon };
