import React from "react";

import {
  align1,
  align2,
  azimuth,
  copytable_pvsyst,
  databases_pvsyst,
  databases2_pvsyst,
  databases2_pvsyst_inv,
  gamma_pvsyst,
  shade2,
  config_gcr,
  racking_bifacial,
  import_save,
  module_char_export,
  module_pvsyst,
  map_export,
  weather_dragger,
  topo_import_3,
  map_iii_1,
  map_iii_2,
  map_controls_1,
  base_layers_1,
  base_layers_2,
  module_importer,
  inverter_importer,
  inverter_pvsyst,
  shading_3,
  shading_4,
  shading_5,
  shading_6,
  shading_7,
  layout_fill_1,
  layout_fill_2,
  layout_invLock_1,
  layout_invLock_2,
  layout_invCut_1,
  layout_margin_1,
  layout_margin_2,
  layout_margin_3,
  layout_coord_1,
  layout_coord_2,
  layout_coord_3,
  collab_2,
  collab_button,
  kmz_folder_struct,
  polygon_offset_one,
  polygon_offset_two,
  polygon_offset_three,
  export_from_cad_one,
  export_from_cad_two,
  export_from_cad_three,
  export_from_cad_four,
  dims_in_plot_one,
  dims_in_plot_two,
  dims_in_plot_three,
  module_saving_one,
  module_saving_two,
  inverter_saving_one,
  inverter_saving_two,
  inverter_rating_dc_one,
  design_temp_equation,
  racking_dims_specs_one,
  racking_dims_specs_two,
  racking_dims_specs_three,
  racking_dims_specs_four,
  racking_auto_one,
  racking_saving_one,
  racking_saving_two,
  linear_electrical_one,
  import_topo_one,
  slope_analysis_one,
  slope_analysis_two,
  slope_analysis_three,
  remove_racks_one,
  cut_fill_one,
  cut_fill_two,
  cut_fill_three,
  weather_1,
  weather_2,
  weather_3,
  weather_4,
  weather_5,
  weather_6,
  sift_perf_model_one,
  install_cost_summed_one,
  install_cost_detailed_one,
  install_cost_detailed_two,
  install_cost_detailed_three,
  install_cost_detailed_four,
  install_cost_detailed_five,
  install_cost_detailed_six,
  install_cost_detailed_seven,
  lcoe_formula,
  op_cost_one,
  inverters_grouping_one,
  inverters_grouping_two,
  inverters_grouping_three,
  irr_one,
  irr_two,
  irr_three,
  irr_four,
  irr_five,
  irr_six,
  irr_seven,
  irr_eight,
  irr_nine,
  irr_ten,
  irr_eleven,
  irr_twelve,
  irr_thirteen,
  irr_fourteen,
  irr_fifteen,
  irr_sixteen,
  irr_seventeen,
  irr_eighteen,
  irr_nineteen,
  irr_twenty,
  irr_twentyone,
  irr_twentytwo,
  irr_twentythree,
  irr_twentyfour,
  irr_twentyfive,
  irr_twentysix,
  irr_twentyseven,
  irr_twentyeight,
  irr_twentynine,
  irr_thirty,
  irr_thirtyone,
  irr_thirtytwo,
  irr_thirtythree,
  irr_thirtyfour,
  irr_thirtyfive,
  irr_thirtysix,
  irr_thirtyseven,
  irr_thirtyeight,
  irr_thirtynine,
  irr_fourty,
  irr_fourtyone,
  irr_fourtytwo,
  irr_fourtythree,
  irr_fourtyfour,
  topo_impact_1,
  topo_impact_2,
  topo_impact_3,
  topo_impact_4,
} from "../../assets/images/drawers";

const center_image = { display: "block", margin: "0 auto" };

const center_div = { display: "block", margin: "0 auto", textAlign: "center" };

const left_align_image = { display: "block", margin: "5px 0px", maxWidth: 300 };

// ========= START NEW USER =========================================>
// ================================================================>

const QuickLookVideo = () => {
  return (
    <iframe
      width="100%"
      height="290"
      style={{ border: 0 }}
      src="https://www.youtube.com/embed/N1p2pQ4ZtLw"
      title="Video: SIFT Quick Look[10:58]"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

const New_QuickLook = (props) => {
  return (
    <div>
      {/* <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        title="Sunfig Video Tutorial: Overview"
        // src="https://www.youtube.com/embed/xjCXQZlF0k8"
        src="https://www.youtube.com/watch?v=00zeb1kONCo"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}

      <QuickLookVideo />
    </div>
  );
};

const New_OverviewTutorial = (props) => {
  return (
    <div>
      {/* <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        title="Sunfig Video Tutorial: Overview"
        // src="https://www.youtube.com/embed/xjCXQZlF0k8"
        src="https://www.youtube.com/watch?v=00zeb1kONCo"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}

      <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        src="https://www.youtube.com/embed/00zeb1kONCo"
        title="Video: SIFT Overview[34:02]"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const New_Training = (props) => {
  return <div>{/* https://calendly.com/siftdemo/demo */}</div>;
};

// ========= START GENERAL =========================================>
// ================================================================>
const Collab_Video = (props) => {
  return (
    <div>
      <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        title="Video: SIFT Collaboration [4:30]"
        src="https://www.youtube.com/embed/udBf6PtYl8k"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const Project_Collab = (props) => {
  return (
    <div>
      <p>
        Paid users can start a collaboration group by clicking the Collaborate button at the top of SIFT.
        {/* <img src={collab_1} style={{ display: "inline-block", marginLeft: "3px" }} alt="" /> */}
        <img src={collab_button} alt="" style={center_image} />
      </p>
      <p>SIFT Collaborate uses Check In, Check Out controls. Only one user can have a project Checked Out at a time, and this user will have full Read/Write access.</p>
      <p>If a project is Checked Out by another user, you will be able to review but you will not be able to save. You can Save As the project to duplicate it.</p>
      <p>Project owners can force a Check In of their projects.</p>
      <img src={collab_2} alt="" style={center_image} />
      <br />
      <p>Invite additional users to your project by entering their email and clicking Share.</p>
      <p>All users will be able to Check In, Check Out, Edit and Run shared projects.</p>
      {/* <p>
        All users will be able to Check In, Check Out, Edit and Run shared projects. Users will be limited by their account type. For example, Free users you share with will be able to view the
        project but will still have limited features available to them.
      </p> */}
    </div>
  );
};

// <========= START MAP =========================================>
const Map_Tutorial = (props) => {
  return (
    <div>
      <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        title="Video: Map Controls[2:54]"
        src="https://www.youtube.com/embed/SXHn4n40pE4"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {/* <iframe width="848" height="477" src="https://www.youtube.com/embed/hFu1XmmSIds" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>       */}
    </div>
  );
};

const Map_Controls = () => {
  return (
    <div>
      <p>
        SIFT requires at least one boundary to generate results. To import a polygon or polygons, simply drag and drop a KMZ or KML file directly into the map. You can also manually create a polygon
        on the map using the Draw tool. After the polygons have been created, they can be assigned as a boundary, an exclusion or inactive. Use the right-click menu, hotkeys, or action links to set
        the polygon as a boundary.
      </p>
      <b>Hotkeys:</b>
      <ul>
        <li>Right click – open action menu</li>
        <li>Left click and drag – map pan</li>
        <li>Scroll wheel – zoom in/out</li>
        <li>+/- keys – zoom in/out</li>
        <li>Middle mouse double click – zoom extents </li>
        <li>D - draw</li>
        <li>C - Auto-complete polygon that is currently being drawn</li>
        {/* <li>Ctrl/CMD + Z - Undo last action on Polygons</li> */}
        {/* <li>Ctrl/CMD + Shift + Z - Redo last action on Polygons</li> */}
      </ul>
      <b>Hotkeys when a polygon is selected</b>
      <ul>
        <li>B – set as boundary</li>
        <li>E – set as exclusion</li>
        <li>I – set as inactive</li>
        <li>N – cycle through polygons</li>
        <li>O - offset polygon</li>
        <li>ESC – deselect all</li>
        <li>DEL – delete polygon</li>
      </ul>
      <b>Toolbar Icons</b>
      <ul>
        <li>Search</li>
        <li>Change Base Map</li>
        <li>Draw</li>
        <li>Delete All</li>
        <li>Export Drawing</li>
        <li>Zoom In</li>
        <li>Zoom Out</li>
        <li>Zoom Extents</li>
      </ul>
      <b>Right-click action menu</b>
      <ul>
        <li>Draw</li>
        <li>Toggle Dims On/Off</li>
        <li>Toggle Azimuth On/Off</li>
        <li>Export Map</li>
        <ul>
          <li>KMZ</li>
          <li>CAD</li>
        </ul>
        <li>Selected Polygon</li>
        <ul>
          <li>Offset Polygon</li>
          <li>Set as Boundary</li>
          <li>Set as Exclusion</li>
          <li>Set as Inactive</li>
          <li>Delete</li>
        </ul>
      </ul>
      <b>Definitions:</b>
      <p>
        <u>Boundary</u>: SIFT will use this as the buildable area. The total allowable boundary area is limited based on user account. Boundaries are red.
      </p>
      <p>
        <u>Exclusion</u>: SIFT will not place PV in this area. Exclusions may be placed within boundaries. Exclusions are yellow.
      </p>
      <p>
        <u>Inactive</u>: SIFT will ignore these polygons. Inactive polygons are white.
      </p>
      <p>
        When importing a KMZ or KML file, all polygons will be assigned as inactive by default. Define and alter these polygons as needed. In addition to the hotkey functions, polygons can also be
        defined using the Set: tool at the bottom left side of the map upon selecting a polygon.
      </p>
      <b>Trouble Selecting a Polygon?</b>
      <p>Cycle through all polygons using the hotkey N, or right click over stacked polygons and a menu will appear. The menu includes the option to select any polygon under your mouse cursor. </p>
      <img src={map_controls_1} alt="" style={center_image} />
      <p style={{ width: "100%", textAlign: "center" }}>Right click menu to select nested polygons.</p>
    </div>
  );
};

const Map_Layers = () => {
  return (
    <section>
      <p>Non-paid users on the Starter Plan will have access to basic basemap layers shown below…</p>
      <br />
      <img src={base_layers_1} alt="" style={center_image} />
      <br />
      <p>Paid users on the Standard Plan or Pro Plan will have access to high quality basemap layers shown below…</p>
      <br />
      <img src={base_layers_2} alt="" style={center_image} />
      <br />
      <p>
        There are also several Additional Layers that can be added to the map including Riparian, Wetlands and FEMA data. These layers can be analyzed individually, together or in conjunction with the
        built-in slope analysis tool to determine which, if any, areas within the buildable area are not feasible for solar installation. If any of these layers do appear within the buildable area of
        the site, manually draw an exclusion polygon where applicable to allow SIFT to ignore these areas for future generated results. See the Topography section for more details about the slope
        analysis function.
      </p>
      <br />
      <p>The sources for these three additional data sets are listed below…</p>
      <p style={{ margin: "10px 0px 0px 0px" }}>Riparian</p>
      <a href="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer" target="_blank" rel="noopener noreferrer">
        https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer
      </a>
      <p style={{ margin: "10px 0px 0px 0px" }}>Wetlands</p>
      <a href="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer" target="_blank" rel="noopener noreferrer">
        https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer
      </a>
      <p style={{ margin: "10px 0px 0px 0px" }}>FEMA</p>
      <a href="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer" target="_blank" rel="noopener noreferrer">
        https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer
      </a>
    </section>
  );
};

const Map_import_kmz = () => {
  return (
    <div>
      <b>For a simble KMZ with a few polygons:</b>
      <p> Drag and drop the file directly onto the map.</p>
      <p>SIFT will attempt to parse the polygons within the KMZ. All polygons that SIFT recognizes will show up in the map.</p>
      <img src={map_iii_1} alt="" style={center_image} />
      <div style={center_div}>KMZ imported, all polygons are inactive</div>
      <br />
      <img src={map_iii_2} alt="" style={center_image} />
      <div style={center_div}>User defines each polygon as a boundary or an exclusion</div>

      <br />
      <br />
      <p>
        Polygons are set as Inactive by default. Select and set each polygon as a Boundary or Exclusion. Polygons that are left inactive will be ignored. Add additional polygons with the Draw tool as
        needed.
      </p>
      <br />
      <br />
      {/* <p>
        The KMZ parser has the functionality of automatically setting your polygons as Boundary or Exclusions when importing. Open the KMZ in Google Earth and create folders "Boundary", "Exclusion"
        and "Ignore" and move as many polygons into those folders as needed to have them automatically set. Polygons in the "Ignore" folder will not be brought into SIFT and Polygons that are not in
        any folder will be set as inactive by default.
      </p> */}
      <b>For a complex KMZ with many polygons:</b>
      <p>When you have many polygons in one file, it can be tedious to define each one separately.</p>
      <br />
      <p>To save time, you can define collections of polygons in Google Earth, save the KMZ, and import into SIFT.</p>
      <br />
      <p>To do this, create folders in Google Earth named “Boundary”, “Exclusion”, “Inactive”, and “Ignore”. Place the appropriate polygons and polylines within these labeled folders.</p>
      <br />
      <p>
        When you import this KMZ into SIFT, SIFT will set each polygon automatically according to the folder name. See the simple example below of MyKMZ’s folder structure, and the resulting import
        into SIFT
      </p>
      <img src={kmz_folder_struct} alt="" style={center_image} />
      <p>Folder “Boundary”: Polygons will be set as boundary.</p>
      <p>Folder “Exclusion”: Polygons will be set as exclusion.</p>
      <p>Folder “Inactive”: Polygons will be set as inactive.</p>
      <p>Folder “Ignore”: Polygons will not be imported to SIFT.</p>
      <br />
      <p>If any polygons are outside of this folder structure, they will import as Inactive by default.</p>
    </div>
  );
};
const Map_export = () => {
  return (
    <div>
      <p>To export what is drawn the map, select the Map Export button on the left map toolbar.</p>
      <div>
        <img src={map_export} alt="" />
      </div>
      <br />
      <p>Select KMZ or CAD to download. CAD files are exported in the universal .DXF format which can be opened by any CAD program.</p>
    </div>
  );
};
const Map_plot_dims = () => {
  return (
    <div>
      <p>The illustration below defines Pitch (B) and Intra-Row Spacing (IR). The collector width (A) is the X-dim for single axis and the Y-dim for ground fixed tilt. </p>

      <img src={dims_in_plot_one} alt="" style={center_image} />
      {/* <div style={center_div}>SAT (Left), GFT (Middle), and East-West (Right) dimensions.</div> */}
      <br />
      <p>
        Ground fixed tilt is not drawn as a projected aerial view. Ground fixed tilt products are drawn as if ϴ = 0°. This gives an accurate A and B dimension in all plots, but an inaccurate IR
        dimension in the plot. This is standard for ground fixed tilt layouts.
      </p>
      <img src={dims_in_plot_two} alt="" style={center_image} />
      <p>
        The GCR of East-West fixed tilt is defined by the GCR of each plane. The GCR of the East plane is equal to the GCR of the West plane. Like ground fixed, East-West fixed is not drawn as a
        projected aerial view. They are drawn as if ϴ = 0°. This gives an accurate A and B dimension in all plots, but an inaccurate IR dimension in the plot. IR dimensions are shown in the reports.
      </p>
      <p>
        Note that the maximum GCR for East-West fixed is equal to 2Acos(ϴ). This is 0.5GCR for ϴ = 0°, and slightly higher GCRs as ϴ increases. When drawn at the maximum GCR, the visual may show
        polygons slightly overlapping. This is intended, to retain the accurate A dimension, the tilt would eliminate this overlap in construction.
      </p>
      <img src={dims_in_plot_three} alt="" style={center_image} />
    </div>
  );
};
const Map_DefaultLoc = () => {
  return (
    <div>
      <p>Each time a user logs in to SIFT or begins a new project, the map view will update to a random place in the world. To set a default location within SIFT, take the following steps….</p>
      <ol>
        <li>1. Navigate to the Account button on the bottom left side of the screen.</li>
        <li>Click ‘User’</li>
        <li>Input the desired Latitude and Longitude values</li>
        <li>Click Save Changes</li>
      </ol>
    </div>
  );
};

// ========= START MODULE =========================================>
// ================================================================>
const Module_PAN_drawer = () => {
  return (
    <div>
      <p>1. In the Module tab, click Import.</p>
      <img src={import_save} alt="" style={center_image} />
      <br />
      <p>
        2. Drag and drop your PAN file into the popup window. You can also click the window and navigate to your PAN file location. Click Import and the exact module specifications from the PAN file
        will be imported into SIFT.
      </p>
      <img src={module_importer} alt="" style={center_image} />
    </div>
  );
};

const Module_PVSyst_drawer = () => {
  return (
    <div>
      <p>In PVSyst, you can export any module into a text string. You can then paste this text into SIFT to replicate the module.</p>
      <p>1. Open PVSyst, at the top click Settings and then Preferences. Navigate to Export options and confirm that Seperator character of clipboard export is set to ; semicolon. Click ok.</p>
      <img src={module_char_export} alt="" style={center_image} />
      <p>2. Open PVSyst, select Databases from the PVSyst home screen.</p>
      <img src={databases_pvsyst} alt="" style={center_image} />
      <br />
      <p>3. Select PV modules.</p>
      <img src={databases2_pvsyst} alt="" style={center_image} />
      <br />
      <p>4. Open the module specifications. At the bottom of the specification window, click ‘Copy to table’.</p>
      <img src={copytable_pvsyst} alt="" style={center_image} />
      <br />
      <p>You will get a notification from PVSyst that the data has been copied to your clipboard. </p>
      <p>5. While on the Module tab, click the Import button.</p>
      <img src={import_save} alt="" style={center_image} />
      <p>6. In the popup window, navigate to PVSyst Text. Paste the text directly into the text field and click Import. SIFT will parse the text and populate the exact module specifications..</p>
      <img src={module_pvsyst} alt="" style={center_image} />
    </div>
  );
};
const Module_SaveSearch_drawer = () => {
  return (
    <div>
      <b>Module Saving</b>
      <p>Imported module specifications are stored in a module database within the user’s individual account. This allows the user to search and use this module on future projects. </p>
      <br />
      <p>Edit the Name field within the Module tab and click Save. We recommend including the module rating for easy searching capabilities.</p>
      <img src={module_saving_one} alt="" style={center_image} />
      <br />
      <b>Module Searching</b>
      <p>In the dropdown menu, type in all or part of the module name. Select the module from the drop down to load those specifications.</p>
      <img src={module_saving_two} alt="" style={center_image} />
    </div>
  );
};
const Module_Gamma_drawer = () => {
  return (
    <div>
      <p>Advanced! This has a very minor impact on performance, and in most cases SIFT manages this automatically. Some older or encrypted module files do not have a Tcoef of Gamma.</p>

      <p>If importing from an encrypted PAN file or PVSyst Text, Tcoef of Gamma was calculated from Tcoef of Pmpp automatically. </p>
      <p>If you want to disable temperature correction on Gamma (not common), set Tcoef of Gamma to 0.</p>

      <img src={gamma_pvsyst} alt="" style={center_image} />
      <p>The related PVSyst tab is shown for added clarity.</p>
      <p>PVSyst Variable = SIFT Variable</p>
      <p>Pmpp temper. Coef = Tcoef of Pmpp </p>
      <p>Gamma temp. coeff (%/C) / 100 * Gamma = Tcoef of Gamma (1/C)</p>
      <p>Note that Tcoef of Pmpp is also referred to as muPmpReq in some reference papers.</p>
    </div>
  );
};

// <========= START INVERTER =========================================>
// ===================================================================>
const Inverter_OND_drawer = () => {
  return (
    <div>
      <p>In the Inverter tab, click Import. </p>
      <img src={import_save} alt="" style={center_image} />
      <p>
        Drag and drop your OND file into the popup window. You can also click the window and navigate to your OND file location. Click Import and the exact inverter specifications from the OND file
        will be imported into SIFT.{" "}
      </p>
      <img src={inverter_importer} alt="" style={center_image} />
    </div>
  );
};
const Inverter_PVSyst_drawer = () => {
  return (
    <div>
      <p>In PVSyst, you can export any inverter into a text string. You can then paste this text into SIFT to replicate the inverter.</p>
      <p>1. Open PVSyst, select Databases from the PVSyst home screen.</p>
      <img src={databases_pvsyst} alt="" style={center_image} />
      <br />
      <p>2. Select Grid inverter.</p>
      <img src={databases2_pvsyst_inv} alt="" style={center_image} />
      <br />
      <p>3. Open the inverter specifications. At the bottom of the specification window, click ‘Copy to table’..</p>
      <img src={databases2_pvsyst} alt="" style={center_image} />
      <br />
      <p>You will get a notification from PVSyst that the data has been copied to your clipboard. </p>
      <p>4. While on the Inverter tab, click the Import button.</p>
      <img src={import_save} alt="" style={center_image} />
      <p>5. In the popup window, navigate to PVSyst Text. Paste the text directly into the text field and click Import. SIFT will parse the text and populate the exact inverter specifications.n.</p>
      <img src={inverter_pvsyst} alt="" style={center_image} />
    </div>
  );
};
const Inverter_SaveSearch_drawer = () => {
  return (
    <div>
      <b>Inverter Saving</b>
      <p>Imported inverter specifications are stored in an inverter database within the user’s individual account. This allows only that user to search and use this module on future projects. </p>
      <p>Edit the Name field within the Inverter tab and click Save. We recommend you include the inverter nameplate for easy searching capabilities.</p>
      <img src={inverter_saving_one} alt="" style={center_image} />
      <br />
      <b>Inverter Searching</b>
      <p>In the dropdown menu, type in all or part of the inverter name. Select the inverter from the dropdown to load those specifications.</p>
      <img src={inverter_saving_two} alt="" style={center_image} />
    </div>
  );
};

const Inverter_Rating_And_DC = () => {
  return (
    <div>
      <p>By default, the Inverter Rating is set to the AC Power Nom, read from the OND file.</p>
      <br />
      <p>Some users prefer to set the Inverter Rating to a round number, or a number that reflects the power output at higher temperatures. </p>
      <br />
      <p>Inverter Rating has no impact on performance. Performance of the inverter is dictated by the other specification fields. Inverter Rating is only used to calculate MWac and DC:AC.</p>
      <br />
      <p>MWac = Inverter Quantity * Inverter Rating</p>
      <br />
      <p>DC:AC = MWdc / MWac</p>
      <br />
      <p>In the example below, a user has set the Inverter Rating to 2400 kW, which is this inverter’s rating at 55C. If their layout has 10 inverters, it will report as 24MWac. </p>
      <img src={inverter_rating_dc_one} alt="" style={center_image} />
    </div>
  );
};

// <========= START RACKING =========================================>
const Design_Temp = () => {
  return (
    <div>
      <b>Design Temp</b>
      <p>
        Design Temp is the minimum temperature that the site will operate. This low temperature “Design Temp” produces the highest PV voltage. The aim is to keep the PV maximum voltage below the
        inverter’s maximum voltage.
      </p>
      <br />
      <img src={design_temp_equation} alt="" style={center_image} />
      <br />
      <p>SIFT will run with any Modules Per String. An input that is too high or low will result in high losses at the inverter due to threshold and voltage limits.</p>
      <p>Design Temp and resulting maximum modules per string is driven by local regulation.</p>
      <p>
        For general guidance, we suggest using this ASHRAE tool:{" "}
        <a href="https://ashrae-meteo.info/v2.0/" target="_blank" rel="noopener noreferrer">
          https://ashrae-meteo.info/v2.0/
        </a>{" "}
        Locate a station close to your site and use the Extreme Annual Design Conditions section to define a reasonable minimum temperature.
      </p>
    </div>
  );
};
const Racking_SaveSearch_drawer = () => {
  return (
    <div>
      <b>Racking Saving</b>
      <p>
        User defined racking specifications are stored in a racking database within each user’s individual account. This allows only that user to search and use these racking specifications on future
        projects.
      </p>
      <br />
      <p>Edit the Name field within the Racking tab and click Save. We recommend using common identifiers for easy searching capabilities.</p>
      <img src={racking_saving_one} alt="" style={center_image} />
      <br />
      <b>Racking Searching</b>
      <p>In the dropdown, type in all or part of the racking name. Select the rack from the dropdown to load those specifications.</p>
      <img src={racking_saving_two} alt="" style={center_image} />
    </div>
  );
};

const Auto_Racking_Dims = () => {
  return (
    <div>
      <p>
        In Auto mode, racking dimensions are calculated based on module dimensions pulled from the Module tab and some standard inputs. For maximum accuracy, use the Manual option and enter in your
        racking manufactures specific dimensions.
      </p>
      <br />
      <b>Modules High</b>
      <p>Integer number of modules along the shade path (along the face height). A one high portrait tracker would be 1 module high. A 2 high portrait fixed tilt would be 2 modules high</p>
      <br />
      <b>Module Orientation</b>
      <p>
        Module orientation as viewed along the shade path. If interrow shading progresses along the long edge of the module, that module is in Portrait. See help topic “Linear and Electrical Shading”
        for more about shade path.
      </p>
      <br />
      <b>Strings per Rack</b>
      <p>Defines how many strings are on Rack A, B, and C. Users can also toggle racks on and off.</p>
      <br />
      <b>Module Gap</b>
      <p>Gap between modules and equal in both directions.</p>
      <br />
      <b>Drive Gap</b>
      <p>Specific to Single Axis, this is an additional dimension adder to account for drives. Depending on manufacturer, other minor gaps may exist, use Manual for maximum precision.</p>
      <br />
      <b>Modules Wide</b>
      <p>
        Calculated value, not an input. Based on Modules High and Strings per Rack, how many modules wide is the rack. For example, 2 high portrait ground fixed tilt with 25 modules per string and 1
        string cannot be 12.5 modules wide. The rack would be 13 modules wide, with one module ‘space’ empty
      </p>
      <br />
      <p>A few sample calculations for automated racking in Portrait are given for clarity.</p>
      <br />
      <b>Sample Ground Fixed Calculation</b>
      <p>Dim Y = Modules High * Module Y + (Modules High – 1) * Module Gap Dim X = Modules Wide * Module X + (Modules Wide – 1) * Module Gap</p>
      <br />
      <b>Sample Single Axis Calculation</b>
      <p>Dim Y = Modules Wide * Module X + (Modules Wide – 1) * Module Gap + Drive Gap Dim X = Modules High * Module Y + (Modules High – 1) * Module Gap</p>
      <br />
      <b>Sample East West Fixed Calculation</b>
      <p>Dim Y = Modules Wide * Module X + (Modules Wide – 1) * Module Gap Dim X = Modules High * Module Y + (Modules High – 1) * Module Gap</p>
    </div>
  );
};

const Racking_Dims_Auto = () => {
  return (
    <section>
      <p>Users may define the size of their racks manually, or with the Auto tool.</p>
      <p>
        When using Auto, the rack dimensions are calculated based on the module dimensions, modules high, module orientation, modules per string, and strings per rack (or strings per face for EWF
        racks). Additional length is added according to the Mod Gap and Drive Gap inputs.
      </p>
      <p>Each racking format has a slightly different equation, but as a simple example the X dimension of a GFT rack with portrait modules would be:</p>
      <p>
        GFT<span>&#8339;</span>=Modules Wide*Module<span>&#8339;</span>+(Modules Wide-1)*Module Gap
      </p>
      <img src={racking_auto_one} />
    </section>
  );
};
const Racking_Specs_drawer = () => {
  return (
    <div>
      <b>Rack Type</b>
      <p>The racking options are Single Axis Tracker (SAT), Ground Fixed Tilt (GFT) or East-West Facing (EWF).</p>
      <br />
      <b>Racking Specs</b>
      <p>For Single Axis Tracker, there is 1 input for X (typically module height) and up to 3 inputs for Y (tracker length or height including all modules and gaps).</p>
      <br />
      <p>For Ground Fixed tilt, there is 1 input for Y (face height) and up to 3 inputs for X (options for width).</p>
      <br />
      <img src={racking_dims_specs_one} alt="" style={center_image} />
      <div style={center_div}>SIFT Racking Dimension Table</div>
      <br />
      <img src={racking_dims_specs_two} alt="" style={center_image} />
      <div style={center_div}>GFT (Left) and SAT (Right) dimensions.</div>
      <br />
      <b>Rack Size</b>
      <p>Categorize up to three different racking sizes as “Rack A”, “Rack B”, and “Rack C”. </p>
      <br />
      <b>On/Off Toggle</b>
      <p>If toggled On, this racking size will be used in the layout. If toggled Off, this racking size will not be used in the layout.</p>
      <br />
      <b>X,Y</b>
      <p>X is the width in meters of a ground fixed tilt rack or tracker. Y is the height or length in meters of a ground fixed tilt rack or tracker.</p>
      <br />
      <p>For ground fixed tilt Y is the “face height” or the distance from the bottom of the lowest panel to the top of the highest panel. See diagram above.</p>
      <br />
      <b>Mod Count</b>
      <p>Total number of modules on that rack or tracker. The number of modules per string is defined in the ‘Performance’ tab.</p>
      <br />
      <b>Grade Limit</b>
      <p>
        Depending on the rack type, this represents the tolerance at which the racking system can be built in the North-South or East-West direction. The default is 10% for SAT and EWF and 20% for
        GFT. These values can also be defined in the Site tab under Topography
      </p>
      <br />
      <b>Rack to Rack Gap</b>
      <p>For SAT and EWF systems, this defines the North-South spacing between single axis trackers.</p>
      <img src={racking_dims_specs_three} alt="" style={center_image} />
      <br />
      <p>For GFT systems, this defines the East-West spacing between the racks.</p>
      <br />
      <img src={racking_dims_specs_four} alt="" style={center_image} />
      <br />
      <p>Note that an input of 0m represents a continuous structure.</p>
      <br />
      <b>Backtracking</b>
      <p>A tracking control program aimed at minimizing performance losses by minimizing row to row shading at early morning and late afternoon hours. Backtracking is on by default.</p>
      <br />
      <b>Irradiance Optimized Tracking</b>
      <p>A tracking control program that seeks the tilt angle that produces the maximum irradiance result at each time step. </p>
      <br />
      <b>Tracking Angle</b>
      <p>For Single Axis Trackers, this represents both max positive and max negative tilt. An input of 52 is equivalent to φ min = -52°, φ max = 52°. The default value is 60°.</p>
      <br />
      <b>Tilt Angle Minimum & Maximum</b>
      <p>The defined range of tilt angles is optimized for maximum yield when running performance models.</p>
      <br />
      <p>The defined range of tilt angles is optimized for maximum financial benefit when running an IRR financial analysis with time of use considerations.</p>
      <br />
      <p>To analyze a singular tilt angle, set both the minimum and maximum tilt to the same value. Example: Tilt Angle Minimum and Maximum = 15° will lock the tilt angle to 15° for all scenarios.</p>
      <br />
      <b>Shade Mode</b>
      <p>See help topic Linear and Electrical Shading. </p>
    </div>
  );
};
const Racking_Shade_drawer = () => {
  return (
    <div>
      <p>Shade Mode is not applicable for backtracking single axis tracker systems. These inputs are most commonly used with ground fixed tilt systems. </p>
      <br />
      <b>Linear</b>
      <p>Performance losses are proportional to the shade height along the collector width.</p>
      <br />
      <b>Electrical</b>
      <p>
        Like PVSyst, the SIFT performance model does not use ‘Portrait’ or ‘Landscape’ orientation as inputs. All orientation performance effects are managed in Electrical Shading. The SIFT electrical
        shading works in the same way as PVSyst and is suitable for any orientation and product.
      </p>
      <img src={linear_electrical_one} alt="" style={center_image} />
      <br />
      <img src={shade2} alt="" style={center_image} />
      <p>
        Note that in PVSyst, the Cell Transverse variable is entered in centimeters. In SIFT, the Cell Transverse input is entered in <i>meters</i>.
      </p>
      <br />
      <p>
        <u>Typical inputs:</u>
        <br />
        Cell Transverse = 0.156m
        <br />
        GFT 2-high Portait: String Steps = 2<br />
        GFT 4-high Landscape: String Steps = 12
        <br />
        GFT 2-high Split Cell Portrait: String Steps = 4<br />
        SAT Backtracking Off: String Steps = 1<br />
      </p>
      <br />
      <b>Electrical (Detailed Explanation)</b>
      <p>
        Shade mode defines how a module’s production changes as it is shaded. For some modules like CdTe, the fraction of the module shaded is equal to the energy loss, a linear shade relationship.
      </p>

      <p>
        Common Si modules have something closer to a step function relationship with interrow shading, this is referred to as the electrical shading effect. The energy lost depends on the module cell
        architecture, the orientation of the module, and the racking product. The following is an explanation of how this work for a typical 3-diode solar module, but the SIFT parameters can be
        adjusted for any module.
      </p>
      <br />
      <p>A typical Si module has 3 cell strings. Each cell string is shown below in a different color.</p>
      <img src={shading_3} alt="" style={center_image} />
      <p>As soon as a single cell in a string is shaded, that entire string ceases production. String Steps defines how many times the production drops along the interrow shade path.</p>

      <p>
        For the above module if the shade path passes from left to right the correct input is 3 strings steps. If the shade path passes from bottom to top the correct input is 1 string step. Examples
        below.
      </p>

      <p>Example 1: Single Axis Tracker. For trackers, interrow shade path is left to right. For this module orientation String Steps = 1.</p>

      <p>Example 2: Ground Fixed Tilt. For a 2-high portrait structure, interrow shade path is bottom to top. For this module orientation String Steps = 2.</p>

      <p>Example 3: Ground Fixed Tilt. For a 4-high landscape structure, interrow shade path is bottom to top. For this module orientation, String Steps = 12</p>

      <img src={shading_4} alt="" style={center_image} />
      <p>
        The more string steps along the shade path, the fewer losses are introduced due to electrical shading. As an example, we can compare linear vs. electrical shading for examples B and C above.
      </p>
      <img src={shading_5} alt="" style={center_image} />
      <img src={shading_6} alt="" style={center_image} />
      <p>
        Cell Transverse is the size of a module cell along the shade path. Most cells are square, but split cell modules are becoming more common in some applications so verify this dimension. This
        distance defines the transition period between a cell string being on and off.{" "}
      </p>
      <img src={shading_7} alt="" style={center_image} />
    </div>
  );
};
const Racking_Bifacial = () => {
  return (
    <div>
      <p>These variables are used for bifacial modules only. See the performance technical documentation for more detail.</p>
      <img src={racking_bifacial} alt="" style={center_image} />
      <br />
      <i>Height Above Ground (H)</i>
      <p>For GFT, this is the height from ground to the bottom panel edge. For SAT, this is the height from the ground to the rotation axis.</p>

      <i>Transparent Fraction (T)</i>
      <p>The percent of irradiation that passes through the racking table unimpeded. For example, between modules or between tables. This variable is typically set at a conservative 0%.</p>

      <i>Structure Shade Factor (S)</i>
      <p>Percentage of the backside irradiation that is shaded or blocked by the racking structure. This percentage is applied as a direct loss to backside irradiation.</p>
    </div>
  );
};

// <========= START WEATHER =========================================>
const Weather_General = () => {
  return (
    <div>
      <p>There are three ways to get weather data into SIFT, Automatic, Manual, and Custom.</p>
      <p>
        <u>Automatic</u>: If no actions are taken in the weather tab, SIFT will define a latitude and longitude based on your boundary and import weather data from the selected source.
      </p>
      <p>
        <u>Manual</u>: Use the pin or manually adjust your site’s latitude and longitude information. Select a weather data source and click ‘Manual Request’. Once imported, a weather data summary
        table will appear for you to review.
      </p>
      <p>
        <u>Custom</u>: To import custom weather data, click the Import button at the top of the weather tab and follow the instructions.
      </p>
    </div>
  );
};
const Weather_Location_drawer = () => {
  return (
    <div>
      <p>Clicking “Recenter Pin” will place a pin in the middle of the map and automatically populate the location fields. You can also drag the pin around the map.</p>
      <p>
        Imported weather data contains Latitude, Longitude, and Elevation information which may differ from your pin location. The performance model will use the latitude, longitude, elevation, and
        time zone values in the text fields, not the values shown in the weather summary
      </p>
    </div>
  );
};
const Weather_Sources_drawer = () => {
  return (
    <div>
      <p>SIFT supports the automatic import from the following sources.</p>

      <b>NSRDB</b>
      <p>
        NREL’s National Solar Radiation Database covers much of North and South America. Based on the latitude and longitude provided SIFT automatically pulls the nearest NSRDB PSM v3 TMY data set.
      </p>

      <a href="https://maps.nrel.gov/nsrdb-viewer/?aL=f69KzE%255Bv%255D%3Dt%26f69KzE%255Bd%255D%3D1&bL=clight&cE=0&lR=0&mC=18.312810846425442%2C-25.839843749999996&zL=2">NSRDB Data Viewer</a>
      <br />
      <br />
      <b>NASA POWER</b>
      <p>
        The NASA POWER SSE dataset covers the entire world. Based on the latitude and longitude provided, NASA returns average daily irradiation per month. The NREL Bird Clear Sky Model is used to
        extrapolate daily averages into hourly irradiation profiles suitable for performance analysis.
      </p>

      <a href="https://power.larc.nasa.gov/data-access-viewer/">NASA POWER Viewer</a>
      <br />
      <a href="https://www.nrel.gov/grid/solar-resource/clear-sky.html">NREL Bird Clear Sky Model</a>
    </div>
  );
};
const Weather_Custom_Weather = () => {
  return (
    <div>
      <p>1. In the weather tab, click Import.</p>
      <img src={import_save} alt="" style={center_image} />
      <br />
      <p>2. Drag and drop your .CSV, .XLX, or .XLSX file into the upload window.</p>
      <img src={weather_dragger} alt="" style={center_image} />
      <br />
      <p>Weather file formats vary from source to source. If SIFT cannot recognize your format, use the template.</p>
      <p>As a fail-safe backup:</p>
      <ol>
        <li>
          Click the <a href="https://sift-template-files.s3.us-east-2.amazonaws.com/sift_met_template_v2.xls">download template link</a> from the import window and open the file
          sift_met_template_v2.xls
        </li>
        <li>Copy/Paste your 8760s for GHI, DHI, DNI, Wind Speed, and Temperature. The xls includes some instructions. Save this file.</li>
        <li>Upload the edited template as described above.</li>
        <li>Your custom weather is now in SIFT.</li>
      </ol>
    </div>
  );
};
const Weather_PVSyst = () => {
  return (
    <div>
      <p>PVSyst weather data is stored in an encrypted .MET format. Follow these steps to export the data into a CSV that can be imported into SIFT.</p>
      <p>1. Open PVSyst and select ‘Databases’</p>
      <img src={weather_1} alt="" style={{ ...center_image, maxWidth: "70%" }} />
      <br />
      <p>2. Select ‘Meteo tables and graphs’.</p>
      <img src={weather_2} alt="" style={{ ...center_image, maxWidth: "60%" }} />
      <br />
      <p>3. At the top of the window, select the .MET file you would like to export.</p>
      <img src={weather_3} alt="" style={{ ...center_image, maxWidth: "40%" }} />
      <br />
      <p>4. In the same window, navigate to the ‘Tables’ tab. Make the following selections:</p>
      <ul>
        <li>Values = Hourly</li>
        <li>Horiz. Global</li>
        <li>Horiz. Difuse</li>
        <li>Horiz. Beam</li>
        <li>Amb. Temperature</li>
        <li>Wind velocity</li>
        <li>Irradiation Units = W/m²</li>
      </ul>
      <img src={weather_4} alt="" style={{ ...center_image, maxWidth: "80%" }} />
      <br />
      <p>5. Select ‘Show table’ at the bottom of the window. A new window will launch with your prepared data.</p>
      <img src={weather_5} alt="" style={{ ...center_image, maxWidth: "80%" }} />
      <br />
      <p>6. Select ‘Export’ and then ‘Copy to File’.</p>
      <p>7. Click the ‘Change File’ button and select a location that will be easy to find. Seperator should be Semi-colon. Then Click ‘OK’.</p>
      <img src={weather_6} alt="" style={{ ...center_image, maxWidth: "80%" }} />
      <br />
      <p>8. A .CSV file will be saved to your local device. In SIFT, select Import and then drag and drop this .CSV file to SIFT. </p>
      <img src={import_save} alt="" style={{ ...center_image, maxWidth: "40%" }} />
    </div>
  );
};

const Spectral_Correction = () => {
  return (
    <section>
      <p>
        The spectral correction component of the performance model only operates when a CdTe module is used. The weather data must have precipitable water (ColumnPrecip cm) or relative humidity (RH)
        to calculate spectral correction.
      </p>
      <p>If ColumnPrecip is in the weather data, it is used. If it is not available and RH is, ColumnPrecip is estimated using the Guermard 94 model</p>
      <p>
        Pressure is also pulled from the weather data if available. If pressure is not in the weather data, it is estimated based on project altitude. Relative airmass is calculated using the Kasten
        Young 1989 model.
      </p>
      <p>SIFT uses the following airmass coefficients: [0.86273, -0.038948, -0.012506, 0.098871, 0.084658, -0.0042948]</p>
    </section>
  );
};
// <========= START PERFORMANCE =========================================>
const Performance_model_drawer = (props) => {
  // console.log(props);
  return (
    <div>
      <p>
        The SIFT Performance Model aims to produce similar results to PVsyst so the production expectations are consistent from feasibility to detailed design. Ten representative projects and their
        alignment are shown below.
      </p>
      <img src={sift_perf_model_one} alt="" style={{ width: "100%" }} />
      <p>
        SIFT production is typically within the range of -1% to 0% (slightly conservative) when compared to PVSyst. Please contact sales for copies of our validation studies which include all
        supporting SIFT and PVSyst reports.
      </p>
      <p>
        The inputs in the Performance tab are equivalent to inputs required in PVSyst. A standard set of performance inputs is provided as a default. Performance outputs are reported in the same way
        as PVSyst, allowing for easy comparison of results.
      </p>
      {/* <a onClick={() => props.onOpenDocs()}>Technical Documentation</a> */}
    </div>
  );
};

const Troubleshooting_sift_vs_pvsyst = (props) => {
  // console.log(props);
  return (
    <div>
      <p>
        When comparing the SIFT performance model against the PVSyst performance model, it’s critical that all inputs match exactly. When users notice discrepancies between the two performance models,
        there are several inputs that we always have them double check to verify that the same information is being used within both models. Generally, these inputs cause the largest impact on
        performance results and will create a wide gap in performance model alignment. If you are experiencing any discrepancies between the SIFT and PVSyst performance models, please double check
        that the inputs noted below are matching in both models.
      </p>
      <p>
        <u>Weather</u>
      </p>
      <p>
        The weather data is the number one input that will cause discrepancies between performance results when not matching. If you are not using third-party weather data, remember that weather data
        can be exported directly from SIFT and imported into PVSyst or vice versa. However, note that PVSyst does not allow users to export Meteonorm data from their platform.
      </p>
      <p>
        <u>Products</u>
      </p>
      <p>
        This includes modules, inverters, and racking. Note that modules and inverters generally come in the form of .PAN and .OND files, which can be easily uploaded into both platforms.
        Additionally, SIFT also allows users to import modules and inverters via PVSyst Text. Please reference the Module and Inverter help menu topics for more details.
      </p>
      <p>
        When creating racking within PVSyst, for SAT, choose Unlimited Trackers, horiz. axis, and for GFT or EWF, choose Unlimited sheds. Also, please make the Nb. of trackers or Nb. of sheds a large
        number (for example: 100+) for all projects.
      </p>
      <p>
        <u>Performance</u>
      </p>
      <p>All SIFT inputs were created to mimic those within PVSyst. Make sure to verify that all SIFT performance losses match those within the Detailed losses section in PVSyst.</p>
      <p>
        <u>Project Settings</u>
      </p>
      <p>
        In Project Settings, within PVSyst, at the top of the page, please select Included in diffuse for Circumsolar Treatment and PNom (Inverters) for AC power losses reference. The SIFT performance
        model always chooses a conservative approach, when possible, in comparison to PVSyst, and these two settings reflect that approach.
      </p>
      <p>Please do not hesitate to reach out to the SIFT team if you are having trouble aligning SIFT and PVSyst performance results. We will be sure to make it our top priority.</p>
    </div>
  );
};

const TopoPerfImpact = () => {
  return (
    <div>
      <p>
        In SIFT it is possible to model the topographic performance impact for single axis and ground fixed products. Topography must be imported, and the “Topographic Performance Impact” toggle must
        be enabled.
      </p>
      <p>
        For each rack, the topographic profile along and around the rack is read from the elevation data. If a rack is beyond its grade tolerance it can be removed, see Removing Racks based on Grade
        Limits. The racks are oriented in 3D space based on the average topographic slope. Some racks may be able to offset negatively impacting or improve positive impacting topography based on
        mechanical flexibility, this is typically minimal and is not done in SIFT.
      </p>
      <p>Any SIFT layout can be exported to SHD shade scene for import to PVsyst. </p>
      <p>
        The SIFT Topographic Performance Impact model currently modifies irradiance and near shading. You will see the impact in the performance loss table in the Global incident in collector plane
        and near shadings losses.
      </p>
      <br />
      <p>
        <u>Irradiance</u>
      </p>
      <p>The amount of solar irradiation collected by the module plane is impacted by the rotation caused by the surface.</p>
      <p>
        For ground fixed tilt, this changes the real tilt and azimuth of the modules. Another way to think of it is the normal vector from the module plane rotates with the rack. This changes the
        amount and timing of irradiance in the plane of array. For single axis, the same is true as the racking rotates throughout the day.
      </p>
      <p>
        For general guidance, the impact of this change for ground fixed tilt is small. For single axis, tilting towards azimuth has a positive effect, and tilting away has a negative impact. This
        impact is negligible near the equator but can get quite large at higher or lower latitudes.
      </p>
      <p>Illustrated in PVsyst, here are two identical ground fixed racks. The one in the back has been rotated about the green axis. </p>
      <img src={topo_impact_1} alt="" style={center_image} />
      <p>Illustrated in PVsyst, here are two identical single axis racks. The one in the back has been rotated about the red axis.</p>
      <img src={topo_impact_2} alt="" style={center_image} />
      <p>
        <u>Near Shade</u>
      </p>
      <p>
        Also referred to as Self-shade, this is the impact topography has on neighboring racks. This can cause an increase in shading at certain times of day. For ground fixed, the only relevant
        direction is knowing the orientation of the rack in front. For single axis, the topography impacts the orientation of both neighboring trackers. These differences in orientation cause
        differences in the timing and amount of shade on each rack.
      </p>
      <p>
        For general guidance, the impact of this for ground fixed tilt can be extremely negative if the racks in front are higher (e.g. a north facing slope in the northern hemisphere). For single
        axis, the impact is dependent on the surface but is generally 0-2%.
      </p>
      <p>Illustrated in PVsyst, here are two identical ground fixed racks. The one in the front is higher than the one behind. </p>
      <img src={topo_impact_3} alt="" style={center_image} />
      <p>Illustrated in PVsyst, here are three identical single axis racks on an eastern facing slope. </p>
      <img src={topo_impact_4} alt="" style={center_image} />
      <p>
        <u>Tilts and Tracking Angles </u>
      </p>
      <p>
        If using the ground fixed tilt optimization function in SIFT. SIFT will optimize the tilt assuming a flat plane. That tilt angle will be applied to the entire site, regardless of topography.{" "}
      </p>
      <p>When using any tracker, the tracking angles are calculated assuming a flat plane. The tracking angles will be identical for all trackers, regardless of topography</p>
    </div>
  );
};

const PowerFactor = () => {
  return (
    <div>
      <p>Power Factor = cos(phi) and its impact is defined by this relationship:</p>
      <p>Apparent energy = Active Energy / cos(phi)</p>
      <p>{`Where cos(phi) is the phase shift difference between current and voltage. Modeling with Power Factor is optional. If used, the input value is typically 0.95-1.0. If cos(phi) < 1, Apparent Energy is larger than Active Energy.`}</p>
      <p>
        Active Energy is the “real” energy coming from the performance model. Modeling with Power Factor doesn’t adjust Active Energy directly, or switch to reporting Apparent Energy. SIFT will always
        report Active Energy, and Active Energy is what feeds all downstream yield and financial models. What changes is that the model uses Apparent Energy to calculate AC-side losses. Since this
        value is higher than Active Energy, some of the losses calculate slightly higher.
      </p>
      <p>For losses after the inverter, this is straightforward. Apparent energy is used to calculate the percent losses that are a function of STC. This percentage is applied to Active Power.</p>
      <p>
        For losses at the inverter, inverter Pnom needs to be further defined. Is the inverter Pnom defined at Active Power, or Apparent Power? This information is not available in the OND file. If
        defined as Apparent, there will be slightly higher losses in the inverter vs. Active.{" "}
      </p>
    </div>
  );
};

const Performance_grid_power = () => {
  return (
    <div>
      <p>If the AC generation is above this target, the generation is clipped. This is also referred to as interconnection or interconnect limit. </p>
      <p>Example 5 hours of AC generation (kW):</p>
      <div style={center_div}>[10, 30, 50, 45, 25]</div>
      <br />
      <p>Example 5 hours of AC generation (kW) with Grid Power Limit = 40kW:</p>
      <div style={center_div}>[10, 30, 40, 40, 25]</div>
    </div>
  );
};

// <========= START FINANCE =========================================>
const Finance_InstallCosts_drawer = () => {
  return (
    <div>
      <p>
        Project costs in SIFT are distilled into costs that scale with DC, costs that scale with AC, and costs that are fixed. Our work with estimators has shown utility sites can be simplified and
        scaled this way with +/- 1% accuracy.
      </p>
      <p>
        Estimating a project to the dollar is important but is not necessary to complete an optimization analysis in SIFT. More important than the precise estimate is the relationship and ratios
        between DC, AC, fixed costs, and site area. Most items scale perfectly within one of these categories. The most notable exception is wiring, which typically makes up only 1-2% of system costs,
        which scales primarily with DC but also a small amount with pitch or GCR.
      </p>

      <p>
        <u>Module $/W</u> – Modules
      </p>

      <p>
        <u>Other DC $/W</u> – May contain items such as: racking, construction, wiring.
      </p>

      <p>
        <u>AC $/W</u> – May contain items such as: inverters, foundations, transformers.
      </p>

      <p>
        <u>Footprint $/ha </u> – Area in hectares used by racking, at this cost per hectare. Includes area directly under PV and associated area consumed by GCR or Pitch spacing and gaps.
      </p>

      <p>
        <u>Fixed $</u> - May contain items such as: land costs, engineering and development fee, substation, interconnection, permits, margin.
      </p>
    </div>
  );
};
const Finance_Custom_OP_Cost = () => {
  return (
    <div>
      <p>To adjust the length of the Custom Schedule:</p>
      <ol>
        <li>Toggle Custom Schedule off</li>
        <li>Change Analysis Period to desired length</li>
        <li>Toggle Custom Schedule on</li>
      </ol>
      <p>Overwrite based on Inputs(left) will overwrite the custom schedule with escalated DC, AC, footprint, and Fixed operation costs from the primary Operating Costs fields.</p>
      <p>The custom operating costs schedule allows you to incorporate as much complexity as you would like into the project expenses over the life of the project. </p>
      <p>
        For example, imagine you’d like to replace 50% of the inverters in year 14 at a cost of $0.10/Wac. Taking care to convert to $/kWac for operating costs: $0.10/1000*0.50 would be your input for
        Year 14 in $/kWac. If you prepare this in a spreadsheet, you can copy & paste directly into the Custom Schedule Table.{" "}
      </p>
    </div>
  );
};

const Finance_Custom_Rev_Incentives = () => {
  return (
    <div>
      <p>To adjust the length of the Custom Schedule:</p>
      <ol>
        <li>Toggle Custom Schedule off </li>
        <li>Change Analysis Period to desired length</li>
        <li>Toggle Custom Schedule on</li>
      </ol>
      <p>Overwrite based on Inputs(left) will overwrite the custom schedule with escalated $/kWh from the primary Revenue and Incentives fields.</p>
      <p>The custom revenue and incentives schedule allows you to incorporate as much complexity as you would like into the project revenues over the life of the project.</p>
      <p>For example, if your project revenues include a capacity payment, you could include that as a $/kWac revenue. This will scale as a function of your inverter capacity.</p>
      <p>For example, if you project revenue includes a fixed incentive, you could include that as a flat $ amount. This will not scale according to project size or configuration.</p>
    </div>
  );
};

const Install_Cost_Summed = () => {
  return (
    <section>
      <img src={install_cost_summed_one} style={center_image} />
      <br />
      <p>The Install Costs Summed option distills project costs into three simple buckets. Costs that scale with DC, costs that scale with AC, and costs that are fixed.</p>
      <p>
        This is an unusual way to view project costs, but many veterans SIFT users prefer this for its simplicity. Most items fall neatly within these buckets. Our work with estimators has shown
        utility sites can be accurately scaled this way within a few percent.{" "}
      </p>
      <p>
        Estimating a project to the dollar is important but is not necessary to complete an optimization analysis in SIFT. More important than the precise estimate is the relationship and ratios
        between DC, AC, fixed costs, and site area. Most items scale perfectly within one of these categories.{" "}
      </p>
      <p>
        <u>DC $/Wp:</u> May contain items such as: modules, racking, construction, wiring.
      </p>
      <p>
        <u>AC $/Wac: </u> May contain items such as: inverters, foundations, transformers.
      </p>
      <p>
        <u>Fixed $: </u> May contain items such as: land costs, engineering and development fee, substation, interconnection, permits, margin.
      </p>
    </section>
  );
};

const Install_Cost_Detailed = () => {
  return (
    <section>
      <img src={install_cost_detailed_one} />
      <p>
        The Install Costs Detail option is broken into four sections. The inputs here are not meant to be exhaustive, or replace a true estimate, but serve as a guide through project costs in a more
        typical manner than the Summed option.{" "}
      </p>
      <p>The critical aspect of optimization is the relationship between DC costs, AC costs, Fixed costs, and usable area.</p>
      <p>Do not feel obligated to input a value in every field. Use the inputs and units that make sense for you.</p>
      <br />
      <b>DC Units</b>
      <img src={install_cost_detailed_two} />
      <p>
        <u>Module:</u> Cost per module. Units of $/Wp or $.
      </p>
      <p>
        <u>Rack A/B/C:</u> Cost per Rack. Units of $/Wp, $/module, or $. Up to three racks can be defined in the Products, Racking tab.
      </p>
      <p>
        <u>BOS Other:</u> All other costs scaling as a function of DC. Units of $/Wp.
      </p>
      <p>
        Total DC is displayed as a guide only and is not used in SIFT, your inputs are used in SIFT. It is there to help see your costs combined to a common unit. It is only the Module, Rack A, and
        BOS Other costs converted to $/Wp.
      </p>
      <br />
      <b>AC Units</b>
      <img src={install_cost_detailed_three} />
      <p>
        <u>Inverter:</u> Cost per inverter. Units of $/Wac or $.
      </p>
      <p>
        <u>AC Aux:</u> Auxiliary items associated with the inverter, such as transformers and foundations. Units of $/Wac or $.
      </p>
      <p>
        <u>MV Wire:</u> Cost of MV Wire. Units of $/Wac or $.
      </p>
      <p>
        <u>Other AC:</u> All other costs that are scaling as a function of AC. Units of $/Wac.
      </p>
      <p>Total AC is displayed as a guide only and is not used in SIFT, your inputs are used in SIFT. It is there to help see your costs combined to a common unit.</p>
      <br />
      <b>Fixed Units</b>
      <img src={install_cost_detailed_four} />
      <p>
        <u>Interconnect:</u>Interconnect costs. Substation, switch yard, tap, transmission lines. Units of $.
      </p>
      <p>
        <u>Permit Fees:</u>Units of $.
      </p>
      <p>
        <u>Engineering:</u>Units of $.
      </p>
      <p>
        <u>Margin:</u>Units of $.
      </p>
      <p>
        <u>Other Fixed:</u>Units of $.
      </p>
      <p>Total Fixed is displayed as a guide only and is not used in SIFT, your inputs are used in SIFT. It is there to help see your costs combined to a common unit.</p>
      <br />
      <b>Misc. Units</b>
      <p>It is uncommon to use this section. This is a catchall section where we place unusual cost units.</p>
      <img src={install_cost_detailed_five} />
      <p>
        <u>Rack Footprint:</u>Calculates the area under the racks and a rack’s associated GCR or Pitch area. See example below. Total area is multiplied by this value. Units of $/ha or $/acre.
      </p>
      <p>
        <u>Buidable Area:</u>Multiplies the Buildable Area, Boundaries less Exclusions, by this value. See example below. Total area is multiplied by this value. Units of $/ha or $/acre.
      </p>
      <p>
        <u>Contingency:</u>Calculated Total Install Cost is increased by this amount. Units of %.
      </p>
      <p>
        <u>Spacing Adders:</u>Allows you to dynamically account for increased costs at lower GCRs. Costs are interpreted linearly between defined points. Using the inputs shown above, a 0.35GCR site
        would have an added cost of $0.015. Use with caution, most increases in costs are a function of area not spacing.
      </p>
      <p>Example Rack Footprint, shown in white:</p>
      <img src={install_cost_detailed_six} />
      <p>Example Buildable Area, shown in white:</p>
      <img src={install_cost_detailed_seven} />
    </section>
  );
};

const Finance_OperatingCosts_drawer = () => {
  return (
    <div>
      <p>Operating Costs are handled much like Install costs with items scaling with DC, AC, and fixed. </p>
      <img src={op_cost_one} />
      <p>
        <u>DC:</u>Costs applied as a function of $/kWp. This is most typically used for O&M costs.
      </p>
      <p>
        <u>AC:</u>Costs that scale with the number of inverters. This input is most used within custom schedule for inverter replacement. For example, if your inverter costs $45/kWac and you want to
        replace 1/3 of your inverters in each of years 15, 16, and 17, you could enter $15/kWac in each of those three years.{" "}
      </p>
      <p>
        <u>Rack Footprint:</u>Calculates the area under the racks and a rack’s associated GCR or Pitch area. See example below. Total area is multiplied by this value.
      </p>
      <p>
        <u>Fixed Op Cost:</u>Operating costs that do not scale with any configuration variables. Land lease costs are common here, as well as fixed components of O&M contracts.
      </p>
      <p>
        <u>Escalation:</u>All operating costs are escalated by this percent. Open Custom Schedule to see values escalating over the lifetime of the project.
      </p>
      <p>
        <u>Custom Schedule:</u>If you want to add more complexity to your operating costs, use the Custom Schedule toggle. As an example, this gives you the ability to increase $/kWp costs after n
        years for additional tracker maintenance or build an inverter replacement schedule within $/kWac. Or, if your fixed costs are a combination of escalating and non-escalating items you can
        manage that here. You can paste directly into this table from Excel.
      </p>
      <br />
      <p>Note that the length of Custom Schedule is tied to the Analysis Period field. </p>
      <br />
      <p>To Change the length of Custom Schedule</p>
      <ol>
        <li>Toggle off Custom Schedule</li>
        <li>Change the Analysis Period</li>
        <li>Toggle on Custom Schedule</li>
      </ol>
      <p>Rack Footprint example:</p>
      <img src={install_cost_detailed_six} />
    </div>
  );
};
const Finance_AnalysisPeriod = () => {
  return (
    <div>
      <p>This field defines the total life of the project and the span of time for which SIFT will perform the performance and financial models.</p>
      {/* <img src={finance_period} alt="" style={center_image} /> */}
    </div>
  );
};
const Finance_CustomerSch = () => {
  return (
    <div>
      <p>To adjust the length of the Custom Schedule:</p>
      <ol>
        <li>Toggle Custom Schedule off </li>
        <li>Change Analysis Period to desired length</li>
        <li>Toggle Custom Schedule on</li>
      </ol>

      <p>Overwrite based on Inputs(left) will overwrite the custom schedule with escalated DC, AC, and Fixed operation costs from the primary Operating Costs fields.</p>

      <p>The custom operation costs schedule allows you to incorporate as much complexity as you would like into the project cashflow. </p>

      <p>
        For example, imagine you’d like to replace 50% of the inverters in year 14 at a cost of $0.10/Wac. Taking care to convert to $/kWac for operating costs: $0.10/1000*0.50 would be your input for
        Year 14 in $/kWac. If you prepare this in a spreadsheet, you can copy & paste directly into the Custom Schedule Table.
      </p>
    </div>
  );
};
const Finance_SunfigModel = (props) => {
  return (
    <div>
      <p>See our open technical documentation for more information. The link below will navigate you away from SIFT, save your project before clicking</p>

      {/* <Link to="/docs">Technical Documentation</Link> */}
      <a onClick={() => props.onOpenDocs()}>Technical Documentation</a>
    </div>
  );
};
const Finance_Model_LCOE = () => {
  return (
    <section>
      <p>Levelized Cost of Electricity, LCOE, is calculated within all Financial Models as:</p>
      <img src={lcoe_formula} style={center_image} />
    </section>
  );
};

const Finance_Model_IRR = () => {
  return (
    <section>
      <p>
        To calculate IRR, NPV, or PPA the final cash flow of the project must be defined. The financial model first determines the revenue generated according to the price of energy and generation
        profile. Debt is sized according to user inputs and then taxes, depreciation, and incentives are applied. The install and operating costs, escalations, and interest rates are used to generate
        an after-tax cash flow. The after-tax cash flow is used to calculate NPV, IRR.
      </p>
      <p>If using IRR, a full financial table is produced for each configuration. This financial table is available for download and review.</p>
      <br />
      <b>Revenue</b>
      <p>Revenue is calculated according to the hourly production, after all AC losses, and the rate. Fixed or hourly rates are escalated according to the standard yearly escalation formula.</p>
      <img src={irr_one} style={center_image} />
      <p>The revenue for each hour is calculated according to that hour’s rate and production.</p>
      <img src={irr_two} style={center_image} />
      <p>Other sources of revenue are added, if applicable, to finalize the project’s total revenue. The most common of these are Production Based Incentives (PBI) and salvage value over time.</p>
      <img src={irr_three} style={center_image} />
      <br />
      <b>Expenses</b>
      <p>Operating costs are calculated according to a configuration’s DC size (kWp), AC size (kWac), the fixed costs, and the yearly escalation. </p>
      <img src={irr_four} style={center_image} />
      <img src={irr_five} style={center_image} />
      <p>Storage costs and insurance costs are not in SIFT as of writing but will contribute to the total expenses.</p>
      <img src={irr_six} style={center_image} />
      <br />
      <b>Cost</b>
      <p>
        The total cost, or construction cost, of the installation incurred at year zero. Total install cost is calculated according to a configuration’s DC size (kWp), AC size (kWac), and fixed costs.
      </p>
      <img src={irr_seven} style={center_image} />
      <br />
      <b>EBIDA</b>
      <p>Earnings Before Interest, Depreciation, and Amortization. </p>
      <img src={irr_eight} style={center_image} />
      <br />
      <b>Debt Sizing</b>
      <p>Cash Available For Debt Service (CAFDS)</p>
      <img src={irr_nine} style={center_image} />
      <br />
      <b>Fixed Percent</b>
      <p>
        Finance can be structured without debt, with a fixed percent of debt, or with a debt service coverage ratio. If no debt, percent debt is 0%. Percent debt is the percent of the total install
        cost that is financed, the remainder is paid with cash or equity. The total principle + interest (PI) payment is calculated according to the following equations.
      </p>
      <img src={irr_ten} style={center_image} />
      <img src={irr_eleven} style={center_image} />
      <img src={irr_twelve} style={center_image} />
      <img src={irr_thirteen} style={center_image} />
      <img src={irr_fourteen} style={center_image} />
      <img src={irr_fifteen} style={center_image} />
      <img src={irr_sixteen} style={center_image} />
      <br />
      <b>DSCR</b>
      <p>
        Debt Service Coverage Ratio. Each year, for the tenor of the debt, the total debt payment is equal to a fixed ratio of total revenue. The payments and initial debt size are calculated
        according to the following equations.
      </p>
      <img src={irr_seventeen} style={center_image} />
      <img src={irr_eighteen} style={center_image} />
      <img src={irr_nineteen} style={center_image} />
      <img src={irr_twenty} style={center_image} />
      <img src={irr_twentyone} style={center_image} />
      <img src={irr_twentytwo} style={center_image} />
      <img src={irr_twentythree} style={center_image} />
      <img src={irr_twentyfour} style={center_image} />
      <img src={irr_twentyfive} style={center_image} />
      <img src={irr_sixteen} style={center_image} />
      <br />
      <img src={irr_twentyfive} style={center_image} />

      <b>Pre-tax Cashflows</b>
      <img src={irr_twentysix} style={center_image} />
      <img src={irr_twentyseven} style={center_image} />
      <img src={irr_twentyeight} style={center_image} />
      <img src={irr_twentynine} style={center_image} />
      <img src={irr_thirty} style={center_image} />
      <img src={irr_thirtyone} style={center_image} />
      <img src={irr_thirtytwo} style={center_image} />
      <img src={irr_thirtythree} style={center_image} />
      <img src={irr_thirtyfour} style={center_image} />
      <br />
      <b>Depreciation</b>
      <p>Depreciation schedules are based on (IRS Publication 946).</p>
      <img src={irr_thirtyfive} style={center_image} />
      <img src={irr_thirtysix} style={center_image} />
      <br />
      <b>Investment Tax Credit</b>
      <p>
        If applying the investment tax credit (ITC), a special depreciation calculation is made. ITC is only eligible on the value of the 5-year MACRS. The depreciation basis of 5-year MACRS goes down
        and the ITC credit is added to the project cashflow in year 1.
      </p>
      <img src={irr_thirtyseven} style={center_image} />
      <img src={irr_thirtyeight} style={center_image} />
      <img src={irr_thirtynine} style={center_image} />
      <br />
      <b>Taxes</b>
      <p>The use of state tax is uncommon. For projects globally, use the “federal” tax rate input to define your project’s total tax liability.</p>
      <img src={irr_fourty} style={center_image} />
      <img src={irr_fourtyone} style={center_image} />
      <img src={irr_fourtytwo} style={center_image} />
      <img src={irr_fourtythree} style={center_image} />
      <img src={irr_fourtyfour} style={center_image} />
      <p>
        LCOE, IRR, and NPV values are based on the Project Cash Flow After Tax, costs, and financing rates described above. Upon completion of a project model, SIFT allows you to download a full
        financial table with all the above calculated.
      </p>
    </section>
  );
};

// <========= START TOPO =========================================>
const Topo_SlopeTutorial = (props) => {
  return (
    <div>
      <iframe
        width="100%"
        height="290"
        style={{ border: 0 }}
        title="Sunfig Video Tutorial: Slope Analysis"
        src="https://www.youtube.com/embed/3GblvfnkI2o"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {/* <iframe width="848" height="477" src="https://www.youtube.com/embed/3GblvfnkI2o" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>      */}
    </div>
  );
};
const Topo_Import = (props) => {
  return (
    <div>
      <p>You can import elevation data from USGS and Google. Topography import is defined by map boundaries, verify boundaries before clicking import.</p>
      {/* <p>You can import elevation data from USGS and Google. SIFT will automatically generate slope analysis layers from this data. See the Elevation Sources section for more details.</p>
      <br />
      <p>
        Depending on the chosen rack type (SAT, GFT, EWF) the Limit inputs represent the tolerances at which each rack can be built defined by the racking company. North-South Slope represents SAT and
        EWF racks and will default to a 10% grade limit. East-West Slope represents GFT racks and will default to a 20% grade limit. Max Slope is a combination of North-South Slope and East-West Slope
        and will default to a 15% grade limit.
      </p>
      <br />
      <p>Once these limits have been defined, click Import Topography to populate the map view with the slope analysis layers.</p> */}
      <img src={import_topo_one} alt="" style={center_image} />
      <br />
      <p>The bounding box of the topography data is determined by the boundary (red) polygons in the map.</p>
      <img src={topo_import_3} alt="" style={center_image} />
    </div>
  );
};
const Topo_Layers = (props) => {
  return (
    <div>
      <p>
        Important: These are visual layers! Racking grade limit checks are done against the raw elevation layer. If you want the visual layer to represent the limits of your racking product, ensure
        the Visual Scale (%) is the same as your Racking Grade Limit (%).
      </p>
      <br />
      <p>
        After you import topography, the below menu with appear. A solid blue box means that the layer is available. On import, only the Elevation Ungraded layer is calculated. This is sufficient to
        run topo-informed layouts.
      </p>
      <img src={slope_analysis_one} alt="" style={center_image} />
      <br />
      <p>If you want to create a slope analysis layer, click one of the other boxes. From left to right:</p>
      <ul>
        <li>Elevation</li>
        <li>Max Slope</li>
        <li>North-South Slope</li>
        <li>East-West Slope</li>
      </ul>
      <img src={slope_analysis_two} alt="" style={center_image} />
      <br />
      <p>In this example, I’ll click the 2nd button which is Max Slope. I’ve set the Visual Scale to 15% and then click ‘Create Layer’.</p>
      <img src={slope_analysis_three} alt="" style={center_image} />
      <br />
      <p>
        The Max Slope visual analysis layer will be created, and you can toggle it on/off by clicking the boxes. If you want to delete a layer (perhaps to set a new scale), select it and click ‘Delete
        Layer’. If you want to delete all topo data, click ‘Clear All Topo Data’ at the top of the Topography menu.  
      </p>
    </div>
  );
};

const Topo_Remove = (props) => {
  return (
    <div>
      <p>Look for the Racking on Topography actions section in the Layout menu.</p>
      <br />
      <p>{`If ‘Remove if Grade > Racking Grade Limit’ is selected, SIFT will check each rack against the underlying topography. Racks are automatically removed according to their Grade Limit, set in the Racking menu.`}</p>
      <img src={remove_racks_one} style={center_image} />
      <br />
      <p>{`If ‘No Action, Ignore Grade’ is selected, the results will ignore the grade limits while generating results.`}</p>
      <br />
      <p>{`It is possible to have two underlying surfaces to act on. An ‘Ungraded’ surface, pulled from USGS or Google, or a ‘Graded’ surface, calculated in SIFT. You can chose which surface to act or check against. `}</p>
    </div>
  );
};

const Topo_Sources = (props) => {
  return (
    <div>
      <p>SIFT supports the automatic import from the following sources.</p>
      <b>USGS (United States Geographical Survey)</b>
      <br />
      USGS 3DEP 1/3 arc-second dataset. Available for most of the continental United States and Alaska.
      <br />
      Approximate Resolution: 10 meters
      <br />
      <br />
      <a href="https://www.usgs.gov/">USGS.gov</a>
      <br />
      <a href="https://viewer.nationalmap.gov/basic/">USGS Data Viewer</a>
      <br />
      <br />
      <b>Google</b>
      <br />
      Google Elevation API, identical source to Google Earth.
      <br />
      Available globally.
      <br />
      Approximate Resolution: 10 meters
      <br />
      <br />
      <a href="https://developers.google.com/maps/documentation/javascript/elevation">Google Elevation Service</a>
    </div>
  );
};

const Cut_And_Fill = (props) => {
  return (
    <div>
      <p>
        Once you import topography from USGS and Google you have the option to estimate the Cut & Fill and grade the surface. You can also generate slope analysis layers from the Ungraded or Graded
        surfaces.
      </p>
      <br />
      <p>To estimate Cut & Fill volumes, click the ‘Cut/Fill’ button.</p>
      <img src={cut_fill_one} style={center_image} />
      <br />
      <p>Type in the Grade Target %, for example if you wanted to estimate the Cut & Fill volumes for a 10% racking product, you might type 10% and then click ‘Estimate Cut/Fill’.</p>
      <img src={cut_fill_two} style={center_image} />
      <br />
      <p>SIFT will automatically estimate the volumes of Cut & Fill required to grade the buildable area to the target.</p>
      <img src={cut_fill_three} style={center_image} />
      <br />
      <p>After Cut & Fill is calculated, you can create a visual layer that shows where the Cut & Fill volumes are.</p>
      <br />
      <p>Details about how this function works:</p>
      <p>
        The Grading function requires elevation data from USGS or Google. Grading is done on each independent buildable area. For example, if you have two boundary polygons, the two parcels will be
        graded to the Grade Target independently. Similarly, if one boundary area is bisected by an exclusion, each side will be graded independently. This is like how the layout function works,
        operating on independent or isolated sections of buildable area.
      </p>
      <p>
        Though validated by our civil teams for volume accuracy, the grading calculation should be viewed as an estimate. Items like retention ponds, roads, and civil strategies like terracing are not
        applied. The calculation is done on the buildable area and is not informed by what sections are or are not ultimately used by layout.{" "}
      </p>
      <p>SIFT grades in a way that balances the Cut & Fill volumes if possible.</p>
      <p>
        When grading, SIFT will look for a relatively flat plane within a given parcel. It does not grade from the edge or middle of the parcel, which could result in a poor approach. Once some
        candidate locations are found, a few calculations are done to try to select one that limits the total volume and is relatively balanced between Cut & Fill.{" "}
      </p>
    </div>
  );
};

// <========= START LAYOUT =========================================>
const Layout_Azimuth = () => {
  return (
    <div>
      <p>In SIFT, south is 180°. </p>
      <ul>
        <li>North = 0°</li>
        <li>South = 180°</li>
        <li>East = 90°</li>
        <li>West = 270°</li>
      </ul>
      <img src={azimuth} alt="" style={center_image} />
    </div>
  );
};

const POI_Limit = () => {
  return (
    <div>
      <p>Point of interconnection (POI) limit, also known as grid power limit.</p>
      <p>If the AC generation is above this target, the generation is clipped. This is also referred to as interconnection or interconnect limit. </p>
      <p>Example 5 hours of AC generation (kW): [10, 30, 50, 45, 25]</p>
      <p>Example 5 hours of AC generation (kW) with Grid Power Limit = 40kW: [10, 30, 40, 40, 25]</p>
    </div>
  );
};

// const Fixed_Inverter_Quantity = () => {
//   return (
//     <section>
//       <p>There are two options for Inverter Quantity:</p>
//       <p>
//         <u>Toggle On:</u> Allows you to set the number of inverters. Ideal for sites with a known AC target.
//       </p>
//       <p>
//         <u>Toggle Off:</u> Will populate the site with the maximum number of inverters possible at each GCR and DC:AC combination.{" "}
//       </p>
//       <p>If you have no nameplate limit, but an interconnection limit, apply the interconnection limit in the Performance Tab with POI Limit.</p>
//       <b>Example:</b>
//       <p>Consider this representative layout, each square representing 1 of 72 total strings. This theoretical DC:AC scenario requires 6 strings per inverter.</p>
//       <p>If Inverter Quantity = Max:</p>
//       <img src={fixed_inverter_one} />
//       <p>The maximum number of inverters are placed. 66 strings and 11 inverters will be used for the analysis.</p>
//       <br />
//       <p>If Inverter Quantity is on and value is set to 5 inverters:</p>
//       <img src={fixed_inverter_two} />
//       <p>The five inverters are placed. 30 strings and 5 inverters will be used for the analysis.</p>
//       <p>If the set inverter quantity requires more strings than are available in the layout, that scenario will not be modeled and will not appear in the results.</p>
//     </section>
//   );
// };

const Layout_Fill = () => {
  return (
    <div>
      <p>
        Layout Fill OFF: <br />
        This is the recommended setting. SIFT will remove racks and strings according to inverter quantity, DC:AC, and Designer Margin
      </p>

      <p>
        As an example, this layout has 72 strings, each string represented here as a single square. Inverter quantity is set to 9 and each inverter requires 6 strings. Only the white squares will be
        drawn.
      </p>
      <img src={layout_fill_1} alt="" style={center_image} />
      <br />
      <p>SIFT attempts to remove racks and strings by ranking apparent value. This machine ranking is not perfect, but produces reasonable designs according to a set of rules such as:</p>
      <ul>
        <li>{`Larger racks > Smaller racks`}</li>
        <li>{`Larger boundaries > Smaller boundaries`}</li>
        <li>{`Racks with neighbors > Isolated racks`}</li>
      </ul>

      <p>
        Layout Fill ON: <br />
        This setting will draw the maximum DC population for every configuration. Some teams and designers prefer this as a starting point when they export designs to CAD.
      </p>
      <p>
        As an example, this layout has 72 strings, each string represented here as a single square. Inverter quantity is set to 9 and each inverter requires 6 strings. All 72 strings are drawn but not
        all are used in the calculations. Calculations for performance, cost, module quantities, etc. will only include the 54 strings required for the configuration.
      </p>
      <img src={layout_fill_2} alt="" style={center_image} />
      <br />
      <p>The results table indicate how many strings were used in calculations vs what is drawn.</p>
    </div>
  );
};
const Layout_DesignerMargin = () => {
  return (
    <div>
      {/* <p>This ensures you have n% more strings in your drawing than in the calculations. Some users like to think of this as design padding, or an area buffer.</p>
      <p>
        The automated layouts measure a site’s DC capacity potential at various orientations. These layouts are not meant to replicate the artistic aspects or complete details of a final design. The
        algorithm will populate the site with the selected products but is unaware of site conditions and construction considerations.
      </p> */}
      <p>Designer Margin allows you to tune the layout to produce more conservative results, based on your need. Some users think of this as design padding, or an area buffer.</p>

      <p>Designer Margin will cut a fixed percent of the possible DC capacity. The order of operations is:</p>

      <ol>
        <li>Racks are removed according to topography limitations and the maximum possible DC is set.</li>
        <li>If inverter grouping is disabled, a Designer Margin percent of racks are removed. SIFT then determines the inverter quantity possible from the given DC:AC ratio and available racks.</li>
        <li>
          If inverter grouping is enabled, inverter groups are created, and unused racks are removed. If a fixed inverter quantity is set, the layout will remove additional inverter groups to hit the
          target quantity.
        </li>
        <li>
          If the removed racks represent a percentage greater than Designer Margin, no further action is taken. If the removed racks represent a percentage less than the Designer Margin, inverter
          groups are removed until the Designer Margin is met or exceeded.{" "}
        </li>
      </ol>

      <p>In the example below, there are 43 racks. We will assume no racks are removed due to topography limits. .</p>
      <img src={layout_margin_1} alt="" style={center_image} />
      <br />
      <p>In this example we’ll use a designer margin of 5%. 5% of 43 is 2.15, or 3 racks. 3 racks or more need to be removed.</p>
      <p>The racks go through inverter grouping. In this example, an inverter group requires 6 racks. Remainder racks are removed from the configuration (gray).</p>
      <img src={layout_margin_2} alt="" style={center_image} />
      <br />
      <p>
        This layout has 6 inverters. Fixed inverter quantity is not set, so SIFT will keep all inverter groups. If the quantity was set to 5, one group would be removed. If the quantity was set to 7,
        this result would be discarded.{" "}
      </p>
      <p>
        With a Designer Margin of 5%, a minimum of 5% extra strings are provided for design adjustments. In this case, SIFT will generate a design for 11 inverters and provide 4 additional strings in
        the drawing.
      </p>
      <p>{`7 racks were removed due to the inverter grouping strategy. This is greater than 3, so no further action is taken. Future designer has >= 5% margin to work with within the site.`}</p>
      <p>Removing all gray racks for additional clarity, this input and designer margin would generate the following layout.</p>
      <img src={layout_margin_3} alt="" style={center_image} />
      <br />
    </div>
  );
};
const Layout_InvLock = () => {
  return (
    <div>
      <p>There are two options for Inverter Quantity Lock:</p>

      <p>
        <u>Toggle On</u> – Allows you to set the number of inverters. Perfect for sites with a known target nameplate.
      </p>

      <p>
        <u>Toggle Off</u> – Will populate the site with the maximum number of inverters possible at each GCR and DC:AC combination.
      </p>

      <p>If you have no nameplate limit, but an interconnection limit, apply the interconnection limit in the Performance Tab with POI Limit.</p>

      <p>
        Example: <br /> Consider this representative layout, each square representing 1 of 72 total strings. This theoretical DC:AC scenario requires 6 strings per inverter.
      </p>

      <p>If Inverter Quantity Lock = Max:</p>

      <img src={layout_invLock_1} alt="" style={center_image} />
      <br />
      <p>
        The maximum number of inverters are placed. 66 strings and 11 inverters will be used for the analysis. If Layout Fill is ON (See Layout Fill) 6 strings will be left on the drawing but unused
        in cost, performance, and financial models. If Layout Fill is OFF, 6 strings will be removed from the drawing.
      </p>
      <p>If Inverter Quantity is on and value is set to 5 inverters:</p>
      <img src={layout_invLock_2} alt="" style={center_image} />
      <p>The five inverters are placed. 30 strings and 5 inverters will be used for the analysis. Assuming Layout Fill is off, 42 strings will be removed from the drawing.</p>

      <p>If the set inverter quantity requires more strings than are available in the layout, that scenario will not be modeled and will not appear in the results.</p>
    </div>
  );
};
const Layout_RackAlign = () => {
  return (
    <div>
      <p>Racking Align On forces a regular alignment between racks, typical for larger sites. Racking Align Off allows the racking to shift and accommodate more strings, typical for smaller sites.</p>
      <b>
        <u>Ground Fixed Tilt:</u>
      </b>
      <p></p>
      Aligned(Left)
      <ul>
        <li>Racks are aligned vertically and horizontally</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      Unaligned(Right)
      <ul>
        <li>Rows are shifted East-West to maximize site density.</li>
        <li>Horizontal rows allowed; vertical roadways disabled. </li>
      </ul>
      <img src={align1} alt="" style={center_image} />
      <br />
      <br />
      <b>
        <u>Single Axis Tracker:</u>
      </b>
      <p></p>
      Aligned (Left)
      <ul>
        <li>Trackers are aligned vertically and horizontally.</li>
        <li>Horizontal and vertical roadways allowed.</li>
      </ul>
      Unaligned(Right)
      <ul>
        <li>Trackers are shifted North-South to maximize site density.</li>
        <li>Horizontal rows disabled; vertical roadways allowed.</li>
      </ul>
      <img src={align2} alt="" style={center_image} />
    </div>
  );
};
const Layout_Roads = () => {
  return (
    <div>
      <p>SIFT does not attempt to map road placements to specific site entry points. The purpose is to consume an accurate amount of space within the boundary.</p>

      <p>The current system supports East-West roads only, future developments will include North-South and Perimeter road options.</p>

      <p>There are three Road Spacing options:</p>

      <p>
        <u>Off</u> – No roads are placed
      </p>

      <p>
        <u>Auto</u> – Road placement is automatically determined by racking type, inverter size, and default minimum distances.{" "}
      </p>

      <p>
        <u>Manual</u> – A road is placed at least every defined number of meters. For example, if a site requires a road every 100m, SIFT will place a road at 100m or less depending on racking size
        and spacing.
      </p>
    </div>
  );
};
const Layout_DrawInv = () => {
  return (
    <div>
      <p>
        This is a visual feature that places inverter foundations or ‘pads’ throughout the site. For central inverters, it is typical for each foundation to have 1 or 2 inverters. For string inverters
        that are constructed on centralized foundations, a single cut may contain 6 inverters or more. Use the inverters per pad input field to define this.{" "}
      </p>
      <p>
        For each configuration, SIFT will calculate the number of pads required and space them evenly throughout your site according to the DC distribution. These centroids (black circles) are used to
        define the final inverter position along roads.
      </p>
      <img src={layout_invCut_1} alt="" style={center_image} />
    </div>
  );
};
const Layout_CoordSystem = () => {
  return (
    <div>
      <p>SIFT can create your designs and exports in your preferred coordinate system. When you export from CAD, these layouts will be geo-referenced. </p>
      <p>To achieve this, set your coordinate system before you run a project.</p>
      <img src={layout_coord_1} alt="" style={center_image} />
      <br />
      <p>
        Depending on your coordinate system and how its projection differs from the SIFT map projection, you may notice your design appears slightly off azimuth. This is not an error, but a difference
        in how the underlying map is presented. A longer explanation is below. When you are ready, export your design to CAD.{" "}
      </p>
      <img src={layout_coord_2} alt="" style={center_image} />
      <br />
      <p>
        Open the project in CAD. Your project is now properly geo-referenced onto your selected coordinate system! If you have Civil 3D, you can set the coordinate system and turn on the map to
        verify. You will notice that for this site, with the user-defined coordinate system, the south-facing azimuth was maintained, and the orientation of the underlying maps is slightly different.
      </p>
      <img src={layout_coord_3} alt="" style={center_image} />
      <br />A note on coordinate systems:
      <div>
        <i>
          Google Earth, KMZ, and the SIFT map all operate on the WGS84 datum standard (EPSG 4326). All this fundamentally means is that the vertices are stored as latitude and longitude coordinates.
          When you run a project with a user-defined coordinate system, the boundaries and exclusions are sent to our servers in the WGS 84 format and converted to your coordinate system. Your project
          layouts are then produced within your coordinate system. SIFT will reconvert them for display within the SIFT map. Depending on your coordinate system and the local conversion, you may
          notice your layouts are slightly off-azimuth in the SIFT map. This is not an error, but a result of the different projections and how they choose to locally flatten a curved surface. When
          you export to CAD, you will find your site is perfectly aligned to your coordinate system and original CAD drawings.
        </i>
      </div>
    </div>
  );
};

const Inverters_And_Grouping = () => {
  return (
    <section>
      <b>Off</b>
      <p>
        Inverters are not drawn, and the layout does not display inverter groups in different colors. Results still reflect accurate amounts of DC, AC, and inverter quantities for a given
        configuration.{" "}
      </p>
      <p>
        In the Off example below. The inputs require 6 racks per inverter. There are enough racks for 7 inverters, with 1 rack remaining (gray). The remaining rack is removed. Performance and finance
        results reflect 7 inverter groups.
      </p>
      <img src={inverters_grouping_one} style={center_image} />
      <br />
      <b>Fill</b>
      <p>DC capacity will fill the site, and inverter groups will be drawn according to the configuration parameters. Inverter groups may bridge boundaries to maximize land use.</p>
      <p>
        In the Fill example below. Inverter groups consist of 6 racks. 7 groups of 6 are grouped and drawn. Other racks may be possible (gray) but are removed if they are not part of an inverter
        group.
      </p>
      <img src={inverters_grouping_two} style={center_image} />
      <br />
      <b>Integer</b>
      <p>
        DC capacity will fill the site, and inverter groups will be drawn according to the configuration parameters. Boundaries must contain an integer number of inverter groups. Inverter groups are
        not allowed to bridge boundaries.{" "}
      </p>
      <p>
        In the Integer example below. Inverter groups consist of 6 racks. 6 groups of 6 are grouped and drawn. Other racks may be possible (gray) but are removed if not part of an inverter group.{" "}
      </p>
      <img src={inverters_grouping_three} style={center_image} />
      <br />
      <b>Draw Inverters</b>
      <p>
        This is a visual feature that places inverter foundations or ‘pads’ throughout the site. For central inverters, it is typical for each foundation to have 1 or 2 inverters. For string inverters
        that are constructed on centralized foundations, a single foundation may contain 6 inverters or more. Use the inverters per pad input field to define this.{" "}
      </p>
      <p>Inverter pads dimensions can be defined. The distance between the Road to Rack on the inverter side is also defined here. Pad Y must be equal to or greater than Road to Rack, Inv.</p>
    </section>
  );
};

// <========= START CONFIG =========================================>

const Config_Ranges = () => {
  return (
    <div>
      <p>
        Use the arrows or manually input values to define the configuration parameter ranges and the steps (or increments) at which these values will be evaluated. SIFT will evaluate each
        configuration minimum and all increments up to and including the maximum.
      </p>
      Example:
      <ul>
        <li>String/Inverter: Range = 10 to 20, Increment = 3</li>
        <li>SIFT will evaluate: [10, 13, 16, 19, 20]</li>
        <li>String/Inverter Total = 5</li>
      </ul>
      <p>Each GCR value will be evaluated with each String/Inverter value.</p>
      <p>Total Configuration Count = GCR Total * String/Inverter Total</p>
    </div>
  );
};
const Config_GCR = () => {
  return (
    <div>
      <p>
        In SIFT, Ground Coverage Ratio (GCR) is defined as A/B in reference to the image below. SAT is displayed on the left and GFT is displayed on the right. This definition is consistent with all
        major performance modeling tools.
      </p>

      <img src={config_gcr} alt="" style={center_image} />
      <br />
      <p>Pitch (B) is also displayed in the /config tab based on your range selection</p>
    </div>
  );
};
const Config_Strings = () => {
  return (
    <div>
      <p>To evaluate different DC:AC ratios, SIFT requires a range defined by Strings per Inverter. </p>

      <p>DC:AC = (Modules per String * Module Rating * Strings per Inverter) / (Inverter Rating)</p>

      <p>SIFT does not allow explicit DC:AC range setting, as some DC:AC ratios are impossible based on module, string size, and inverter ratings. </p>

      <p>DC:AC is displayed in the /config tab based on your range selection.</p>
    </div>
  );
};

const Config_Workers = () => {
  return (
    <div>
      <p>Each “Configuration” represents a unique project setup.</p>
      <p>Total Configuration Count = GCR Total * Strings/Inverter Total</p>
      <p>SIFT is limited by Configurations per request, see your account page for details. The /config tab also displays your per-run configuration limit under the Total Configuration Count. </p>
      To reduce Configuration count:
      <ol>
        <li>Increase the step value.</li>
        <li>Decrease the GCR or String/Inverter ranges. </li>
      </ol>
    </div>
  );
};

const Polygon_Offset = () => {
  return (
    <div>
      <p>
        To create a polygon offset in SIFT, right click over the desired polygon, navigate to the correct polygon within the menu and select the Offset Polygon function. Alternatively, select the
        desired polygon and use the hotkey O.
      </p>
      <img src={polygon_offset_one} style={left_align_image} />
      <br />
      <p>Define the offset distance and direction, and a new offset polygon will be created. Further assign offset polygons as boundaries or exclusions as needed.</p>
      <br />
      <img src={polygon_offset_two} style={left_align_image} />
      <img src={polygon_offset_three} style={left_align_image} />
      <br />
    </div>
  );
};

const Export_KMZ_From_CAD = () => {
  return (
    <div>
      <p>To export a KMZ from CAD, you need AutoCAD Civil 3D. Other 3rd party addons and programs exist but are not covered here. Most KMZ files import into SIFT, this is only one of many options.</p>
      <br />
      <p>To export a KMZ from AutoCAD Civil 3D, navigate to the ‘Civil 3D’ workspace.</p>
      <img src={export_from_cad_one} style={left_align_image} />
      <br />
      <p>First, check your coordinate system by navigating to the Settings tab in the Toolspace window. Right click your drawing and select Edit Drawing Settings.</p>
      <img src={export_from_cad_two} style={left_align_image} />
      <p>In this window, you can check, set, or change your coordinate system. In this case my coordinate system is UTM WGS84 Zone 11 North.</p>
      <img src={export_from_cad_three} style={left_align_image} />
      <p>In the Toolspace window, click the Toolbox tab. Open Miscellaneous Utilities and select Export KML</p>
      <img src={export_from_cad_four} style={left_align_image} />
      <p>
        Select all relevant boundary and exclusion polygons. On the Geo-Reference step, select Drawing Coordinate System Transform. On the Nudge step, set elevation to 0.0 and Elevation relative to
        ground. These should be the default settings. Export the KMZ. The KMZ is now ready to import into SIFT! See the import KMZ help topic for more information.
      </p>
    </div>
  );
};

// <========= START HELP ========================================>
//prettier-ignore
const SIFT_Help = (props) => {
  return (
    <div>
      <div className="drawer-content-help">
        <p>Welcome to the SIFT Help Menu.</p>
      </div>
      <br />

      <div className="drawer-content-help">
        <p>New Users</p>
      </div>
      <div>
        <ul>
          {/* <li>
            <a onClick={props.onShowTutorial}>Project Example Tutorial</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("new_user_quick_look")}>Video: SIFT Quick Look[10:58]</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("new_user_overview")}>Video: SIFT Overview[34:02]</a>
          </li>
          <li>
            <a href="https://calendly.com/siftdemo/demo" target="_blank" rel="noopener noreferrer">
              Schedule a Demo
            </a>
          </li>
          <li>
            <a href="https://calendly.com/sifttraining/training" target="_blank" rel="noopener noreferrer">
              Schedule SIFT Training
            </a>
          </li>
        </ul>
      </div>

      <div className="drawer-content-help">
        <p>General</p>
      </div>
      <div>
        <ul>
        <li>
            <a onClick={() => props.onOpenDrawer("collab_video")}>Video: SIFT Collaboration [4:30]</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("project_collab")}>Project Collaboration</a>
          </li>
        </ul>
      </div>

      <div className="drawer-content-help">
        <p>Map</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("map_tutorial")}>Video: Map Controls[2:54]</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_controls")}>Map Controls</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_layers")}>Map Layers</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_import_kmz")}>Import from KMZ</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("polygon_offset")}>Polygon Offset</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_export")}>Export to KMZ or CAD</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("export_kmz_from_cad")}>Export a KMZ from CAD</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_plot")}>Dimensions in Plot (GCR & Pitch Definitions)</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("map_default_loc")}>Change Default Location</a>
          </li>
        </ul>
      </div>

      <div className="drawer-content-help">
        <p>Module</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("module_pan_import")}>Module Import from PAN</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("module_pvsyst_import")}>Module Import from PVSyst</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("module_save_search")}>Module Saving and Searching</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("module_gamma")}>Tcoef of Pmpp and Tcoef of Gamma</a>
          </li>
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Inverter</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("inverter_ond_import")}>Inverter Import from OND</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("inverter_pvsyst_import")}>Inverter Import from PVSyst</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("inverter_save_search")}>Inverter Saving and Searching</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("inverter_rating_and_dc")}>Inverter Rating and DC:AC</a>
          </li>
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Racking</p>
      </div>
      <div>
        <ul>
        <li>
            <a onClick={() => props.onOpenDrawer("racking_auto")}>Racking Dimensions, Auto</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("racking_specs")}>Racking Dimensions and Specs</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("auto_racking")}>Automated Racking Dimensions</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("racking_save_search")}>Racking Saving and Searching</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("design_temp")}>Design Temp</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("racking_shade_mode")}>Linear and Electrical Shading</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("racking_bifacial")}>Height Above Ground, Transparent Fraction, and Structure Shade Factor</a>
          </li>
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Weather</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("weather_general")}>General</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("weather_loc")}>Location</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("weather_source")}>Weather Sources</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("weather_custom_import")}>Importing Custom Weather Data</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("weather_pvsyst")}>Weather Data Import from PVSyst</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("spectral_correction")}>Spectral Correction</a>
          </li>
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Performance</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("perf_model")}>SIFT Performance Model</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("perf_troubleshooting")}>Troubleshooting: SIFT vs PVSyst</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("topo_perf_impact")}>Topographic Performance Impact</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("power_factor")}>Power Factor</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("perf_grid_power")}>Grid Power Limit</a>
          </li> */}
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Finance</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("finance_period")}>Analysis Period</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("install_cost_summed")}>Install Costs, Summed</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("install_cost_detailed")}>Install Costs, Detail</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("finance_install_cost")}>Install Costs</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("finance_op_cost")}>Operating Costs</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("finance_schedule")}>Custom Schedule</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("finance_custom_op_cost")}>Custom Schedule: Operating Costs</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("finance_custom_rev_and_incentives")}>Custom Schedule: Revenue and Incentives</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("finance_model_lcoe")}>SIFT Financial Model, LCOE</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("finance_model_irr")}>SIFT Financial Model, IRR</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("finance_model")}>Sunfig Financial Model</a>
          </li> */}
        </ul>
      </div>

      <div className="drawer-content-help">
        <p>Topography</p>
      </div>
      <div>
        <ul>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("topo_tutorial")}>Video Tutorial: Slope Analysis</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("topo_import")}>Import Topography</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("topo_layers")}>Slope Analysis Layers</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("topo_remove")}>Removing Racks based on Grade Limits</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("topo_cut_fill")}>Grading and Cut & Fill</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("topo_sources")}>Elevation Sources</a>
          </li>
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Layout</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("poi_limit")}>POI Limit</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("layout_inv_lock")}>Fixed Inverter Quantity</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("layout_align")}>Rack Aligned and Unaligned</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("layout_azimuth")}>Azimuth</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("layout_coord_system")}>Geo-Referenced CAD with User-Defined Coordinate System</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("layout_ew_roads")}>East-West Roads</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("inverters_and_grouping")}>Inverters and Grouping</a>
          </li>
          {/* <li>
            <a onClick={() => props.onOpenDrawer("layout_inv_cuts")}>Draw Inverters</a>
          </li> */}
          <li>
            <a onClick={() => props.onOpenDrawer("layout_margin")}>Designer Margin</a>
          </li>
         
         
        </ul>
      </div>
      <div className="drawer-content-help">
        <p>Config</p>
      </div>
      <div>
        <ul>
          <li>
            <a onClick={() => props.onOpenDrawer("config_ranges")}>Ranges and Increments</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("config_gcr")}>Range: GCR or Pitch</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("config_strings")}>Range: DC:AC from String/Inverter</a>
          </li>
          <li>
            <a onClick={() => props.onOpenDrawer("config_workers")}>Maximum Configuration Count</a>
          </li>
        </ul>
      </div>
      {/* <div className="drawer-content-help">
        <p>Archived</p>
      </div> */}
      {/* <div>
        <ul>
         <li>
            <a onClick={() => props.onOpenDrawer("layout_fill")}>Layout Fill</a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export {
  // main menu
  SIFT_Help,
  // New users
  New_QuickLook,
  New_OverviewTutorial,
  New_Training,
  // General
  Project_Collab,
  // Map
  Map_Tutorial,
  Map_Controls,
  Map_Layers,
  Map_import_kmz,
  Polygon_Offset,
  Map_export,
  Export_KMZ_From_CAD,
  Map_plot_dims,
  Map_DefaultLoc,
  // Module
  Module_PAN_drawer,
  Module_PVSyst_drawer,
  Module_SaveSearch_drawer,
  Module_Gamma_drawer,
  // Inverter
  Inverter_OND_drawer,
  Inverter_PVSyst_drawer,
  Inverter_SaveSearch_drawer,
  Inverter_Rating_And_DC,
  // Racking
  Racking_Specs_drawer,
  Auto_Racking_Dims,
  Racking_SaveSearch_drawer,
  Design_Temp,
  Racking_Shade_drawer,
  Racking_Bifacial,
  // Weather
  Weather_General,
  Weather_Location_drawer,
  Weather_Sources_drawer,
  Weather_Custom_Weather,
  Weather_PVSyst,
  // Performance
  Performance_model_drawer,
  Performance_grid_power,
  Troubleshooting_sift_vs_pvsyst,
  TopoPerfImpact,
  PowerFactor,
  // Finance
  Finance_AnalysisPeriod,
  Finance_InstallCosts_drawer,
  Finance_OperatingCosts_drawer,
  Finance_CustomerSch,
  Finance_Custom_OP_Cost,
  Finance_Custom_Rev_Incentives,
  Finance_SunfigModel,
  // Topo
  Topo_SlopeTutorial,
  Topo_Import,
  Topo_Layers,
  Topo_Remove,
  Cut_And_Fill,
  Topo_Sources,
  // Layout
  Layout_Azimuth,
  POI_Limit,
  Layout_InvLock,
  Layout_DrawInv,
  Layout_Fill,
  Layout_DesignerMargin,
  Layout_RackAlign,
  Layout_Roads,
  Layout_CoordSystem,
  // Config
  Config_Ranges,
  Config_GCR,
  Config_Strings,
  Config_Workers,
  Racking_Dims_Auto,
  Spectral_Correction,
  Install_Cost_Summed,
  Install_Cost_Detailed,
  Finance_Model_LCOE,
  Finance_Model_IRR,
  Inverters_And_Grouping,
  Collab_Video,
  QuickLookVideo,
};
