import { UserConstants, ProjectConstants } from "../_constants";
import { userService, siftService } from "../_services";
import { alertActions, accountActions, inputsActions } from "./";
import { default_inverter_inputs, simple_inverter } from "../_reducers";
import { history } from "../_helpers";
import LogRocket from "logrocket";

export const UserActions = {
  login,
  continueLogin,
  refreshToken,
  identifyUser,
  logout,
  register,
  pwReset,

  get_library_data,

  toggleLoginVis,
  toggleLoginTab,

  showTerms,
  showAgreementModal,
  agreementAccepted,
  agreementDeclined,

  toggleFunnelVis,
  updateUserData,
  addPayment,
  updatePlanTo,
  deletePayment,
  setDefaultPayment,
  // portal
  internalLogin,
  reset_pw,
  updateUserValue,
};

function login(username, password, forward = false) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password, forward).then(
      (user) => {
        dispatch(success(user, forward));
        dispatch(UserActions.get_library_data());
        dispatch(UserActions.identifyUser(user.access.user_id, username));
        if (forward) {
          // If logged in from homepage (TODO: REMOVE HOME PAGE)
          window.scrollTo(0, 0);
          history.push("/sift");
        }

        // if (user?.terrasmart_terms === undefined || user?.terrasmart_terms === false) {
        //   dispatch(UserActions.showAgreementModal(true));
        // }
        // dispatch(UserActions.get_library_data());
      },
      (error) => {
        if (error.detail === "Too many failed attempts. User Locked Out for 10.0 mins") {
          dispatch(alertActions.error("Too many failed attempts. User Locked Out for 10 mins"));
          dispatch(failure("Too many failed attempts. User Locked Out for 10 mins"));
        } else {
          dispatch(alertActions.error("Incorrect email or password"));
          dispatch(failure("Incorrect email or password"));
        }
      }
    );
  };

  function request(user) {
    return {
      type: UserConstants.LOGIN_REQUEST,
      user,
    };
  }

  function success(user, forward) {
    return {
      type: UserConstants.LOGIN_SUCCESS,
      user,
      forward,
    };
  }

  function failure(error) {
    return {
      type: UserConstants.LOGIN_FAILURE,
      error,
    };
  }
}
function continueLogin(user, platform = "sift") {
  return (dispatch) => {
    // console.log(user)
    if ((user?.terrasmart_terms === undefined || user?.terrasmart_terms === false) && platform === "sift") {
      // dispatch(UserActions.showAgreementModal(true));
      dispatch(UserActions.logout());
    } else {
      dispatch(success(user));
      dispatch(UserActions.get_library_data());
      // dispatch(accountActions.getAll());
      dispatch(UserActions.identifyUser(user.access.user_id));
    }
  };

  function success(user) {
    return {
      type: UserConstants.CONTINUE_LOGIN,
      user,
    };
  }
}
function refreshToken(refresh) {
  return (dispatch) => {
    dispatch(
      request({
        refresh,
      })
    );

    userService.refreshToken(refresh).then(
      (access) => {
        dispatch(success(access, refresh));
        dispatch(accountActions.getAll());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(refresh) {
    return {
      type: UserConstants.REFRESH_TOKEN_REQUEST,
      refresh,
    };
  }

  function success(access, refresh) {
    return {
      type: UserConstants.REFRESH_TOKEN_SUCCESS,
      access,
      refresh,
    };
  }

  function failure(error) {
    return {
      type: UserConstants.REFRESH_TOKEN_FAILURE,
      error,
    };
  }
}
function identifyUser(user_id, email = undefined) {
  return (dispatch) => {
    if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage") {
      let user_info = JSON.parse(localStorage.getItem("userInfo"));
      email = email || (user_info && user_info.email) || "";
      let plan_id = (user_info && user_info.plan_id) || "";
      if (email) {
        LogRocket.identify(user_id, { email: email, plan_id: plan_id });
      } else {
        LogRocket.identify(user_id);
      }
    }
  };
}

function logout() {
  return (dispatch) => {
    dispatch(request());
    userService.logout();
  };
  // return { type: UserConstants.LOGOUT };
  function request() {
    return {
      type: UserConstants.LOGOUT,
    };
  }
}
function register(user, forward = false) {
  return (dispatch) => {
    dispatch(request(user));
    let saved_user = user;
    userService.register(user).then(
      // data => console.log(data)

      (data) => {
        dispatch(alertActions.success("Registration successful"));
        // dispatch(success(user));
        dispatch(UserActions.login(saved_user.email, saved_user.password1, forward));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return {
      type: UserConstants.REGISTER_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: UserConstants.REGISTER_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: UserConstants.REGISTER_FAILURE,
      error,
    };
  }
}
// const passMsg = "If you have an email with us, you’ll receive an email shortly with directions on how to reset your password";
function pwReset(email) {
  const scoped_msg = `If an account exists for ${email}, you will get an email with instructions on resetting your password. `;
  return (dispatch) => {
    dispatch(request());
    userService.pwReset(email).then(
      (email) => {
        dispatch(success());
      },
      (error) => {
        // Note: error message available in error.email not in error.data.email
        let errorMsg = error?.data?.email[0]?.search("no active user associated") ? scoped_msg : error?.data?.email[0] || "An Error has occured.";
        dispatch(failure(errorMsg));
      }
    );
  };

  function request() {
    return {
      type: UserConstants.PWRESET_REQUEST,
    };
  }

  function success() {
    return {
      type: UserConstants.PWRESET_SUCCESS,
      payload: scoped_msg,
    };
  }

  function failure(error) {
    return {
      type: UserConstants.PWRESET_FAILURE,
      payload: error,
    };
  }
}

function get_library_data() {
  return (dispatch) => {
    dispatch(request());
    userService.get_library_data().then((response) => {
      siftService.downloadFromS3(response.url).then((_response) => {
        let user_data = JSON.parse(localStorage.getItem("userInfo"));
        if (user_data) {
          user_data.plan_id = _response.user_data.product_plan;
          localStorage.setItem("userInfo", JSON.stringify(user_data));
          if (user_data) {
            user_data.plan_id = _response.user_data.product_plan;
            localStorage.setItem("userInfo", JSON.stringify(user_data));
          }
          dispatch(complete_user(_response.user_data));
          dispatch(complete_projects(_response.projects));
          // setTimeout(() => {
          //   if (_response.user_data.en_target_inputs) {
          //     let bulk_inputs = _response.user_data.en_target_inputs ? { ...JSON.parse(JSON.stringify(simple_inverter)) } : { ...JSON.parse(JSON.stringify(default_inverter_inputs)) };
          //     dispatch(inputsActions.update_bulk_inputs(bulk_inputs));
          //   }
          // }, 1500);
        }
      });
    });
  };
  function request() {
    return { type: UserConstants.GET_USER_LIB_REQ };
  }
  function complete_projects(projects) {
    return { type: ProjectConstants.GOT_PROJECT_DATA, projects };
  }
  function complete_user(user_data) {
    return { type: UserConstants.GET_USER_LIB_COMP, user_data };
  }
}

function toggleLoginVis(visible, tab) {
  return (dispatch) => {
    dispatch({
      type: UserConstants.LOGIN_VISIBILITY_TOGGLE,
      visible,
      tab,
    });
  };
}
function toggleLoginTab(tab) {
  return (dispatch) => {
    dispatch({
      type: UserConstants.LOGIN_TAB_TOGGLE,
      tab,
    });
  };
}
function toggleFunnelVis(value) {
  return (dispatch) => {
    dispatch({
      type: UserConstants.FUNNEL_VISIBILITY_TOGGLE,
      value,
    });
  };
}

function updateUserData(data) {
  return (dispatch) => {
    // userService.updateUserData(data).then(dispatch(accountActions.getAll()));
    userService.updateUserData(data).then((response) => {
      // console.log("user info", response);

      dispatch(alertActions.success("User Info Saved"));
      dispatch(success(data, true));
    });
  };

  function request(sub) {
    return { type: UserConstants.UPDATESUB_REQUEST, sub };
  }
  function success(info, update_user_settings = false) {
    return { type: UserConstants.UPDATESUB_SUCCESS, info, update_user_settings };
  }
  function failure(error) {
    return { type: UserConstants.UPDATESUB_FAILURE, error };
  }
}

function updateUserValue(key, value = undefined) {
  return { type: UserConstants.UPDATE_USER_VALUE, key, value };
}
// Called AFTER we've got a token from STRIPE endpoint
function addPayment(token) {
  return (dispatch) => {
    dispatch(request({ ...token }));

    userService.addPayment({ ...token }).then(
      (info) => {
        if (info.response.error) {
          dispatch(failure(info));
          dispatch(alertActions.error(info.response.error.toString()));
        } else {
          dispatch(success(info));
          // dispatch(alertActions.success('Payment method added'));
          // get updated payment info from backend
          // dispatch(accountActions.getAll());
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(token) {
    return { type: UserConstants.ADDPAYMENT_REQUEST, token };
  }
  function success(info) {
    return { type: UserConstants.ADDPAYMENT_SUCCESS, info };
  }
  function failure(error) {
    return { type: UserConstants.ADDPAYMENT_FAILURE, error };
  }
}

// called after confirmation of updating plan
function updatePlanTo(plan, paid_plan) {
  return (dispatch) => {
    dispatch(request(plan, paid_plan));

    // userService.updatePlanTo(sub).then(
    //   (info) => {
    dispatch(alertActions.success("Subscription updated"));
    // dispatch(accountActions.getAll());
    dispatch(success(plan, paid_plan));

    // if (history.location.pathname == "/") {
    //   window.scrollTo(0, 0);
    //   history.push("/sift");
    //   history.push("#app");
    // }
    // },
    // (error) => {
    //   dispatch(failure(error.toString()));
    //   dispatch(alertActions.error(error.toString()));
    // }
    // );
  };

  function request(plan, paid_plan) {
    return { type: UserConstants.UPDATESUB_REQUEST, plan, paid_plan };
  }
  function success(plan, paid_plan) {
    return { type: UserConstants.UPDATESUB_SUCCESS, plan, paid_plan };
  }
  function failure(error) {
    return { type: UserConstants.UPDATESUB_FAILURE, error };
  }
}
function deletePayment(id) {
  return (dispatch) => {
    dispatch(request(id));
    userService.deletePayment(id).then(
      (info) => {
        dispatch(alertActions.success("Payment Method Removed"));
        // dispatch(accountActions.getAll());
        dispatch(success(info));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(sub) {
    return { type: UserConstants.UPDATESUB_REQUEST, sub };
  }
  function success(info) {
    return { type: UserConstants.UPDATESUB_SUCCESS, info };
  }
  function failure(error) {
    return { type: UserConstants.UPDATESUB_FAILURE, error };
  }
}
function setDefaultPayment(id) {
  console.log("id", id);
  return (dispatch) => {
    dispatch(request(id));
    userService.setDefaultPayment(id).then(
      (info) => {
        dispatch(alertActions.success("Default Payment Method Set"));
        dispatch(success(info));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(sub) {
    return { type: UserConstants.UPDATESUB_REQUEST, sub };
  }
  function success(info) {
    return { type: UserConstants.UPDATESUB_SUCCESS, info };
  }
  function failure(error) {
    return { type: UserConstants.UPDATESUB_FAILURE, error };
  }
}

function showTerms(visible, openedFromAgreementUpdate = false) {
  return {
    type: UserConstants.SHOW_TERMS,
    visible,
    openedFromAgreementUpdate,
  };
}
function showAgreementModal(visible) {
  return {
    type: UserConstants.TOGGLE_NEW_AGREEMENT_MODAL,
    visible,
  };
}
function agreementAccepted(user) {
  return (dispatch) => {
    dispatch(acceptedRequest());

    userService.agreeToTerms().then((response) => {
      dispatch(UserActions.continueLogin(user));
    });
    // localStorage.setItem("TOS_Agreement", 1);
  };
  function acceptedRequest() {
    return {
      type: UserConstants.AGREEMENT_ACCEPTED,
    };
  }
}
function agreementDeclined() {
  return (dispatch) => {
    dispatch(declinedRequest());
    // localStorage.setItem("TOS_Agreement", -1);
    setTimeout(() => {
      dispatch(UserActions.logout());
    }, 1000);
  };
  function declinedRequest() {
    return {
      type: UserConstants.AGREEMENT_DECLINED,
    };
  }
}

function internalLogin(username, password, forward = undefined) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.internalLogin(username, password).then(
      (user) => {
        let reset_pw = user["reset_password"] === "reset_password";
        if (reset_pw) {
          dispatch(reset_password(user));
        } else {
          dispatch(success(user));
          if (forward) {
            history.push(`/sift/?${forward}`);
          } else {
            history.push("/sift");
          }
          dispatch(UserActions.get_library_data());
          // dispatch(portalActions.getProjects());
          // dispatch(authActions.identifyUser(user.access.user_id, username));
          // dispatch(projectManagerActions.getProjects());
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error('Incorrect email or password'));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: UserConstants.LOGIN_SUCCESS, user };
  }
  function reset_password(user) {
    return { type: UserConstants.PWRESET_REQUEST, user };
  }
  function failure(error) {
    return { type: UserConstants.LOGIN_FAILURE, error };
  }
}

function reset_pw(email, old_password, new_password) {
  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(email, old_password, new_password).then(
      (response) => {
        dispatch(success());
        // history.push("/");
        // dispatch(portalActions.getProjects());
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error('Incorrect email or password'));
      }
    );
  };

  function request() {
    return { type: authConstants.PWRESET_REQUEST };
  }
  function success() {
    return { type: authConstants.PWRESET_SUCCESS };
  }
  function failure() {
    return { type: authConstants.PWRESET_FAILURE };
  }
}
