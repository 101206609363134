import React, { useState, useEffect } from "react";
import { CanopyLayer, CanopyToolbar, CanopyHotkeys } from "./";

import { ShadeLayer, ShadeDrawingLayers, ShadeObjectMarker } from "../../EnvironmentalShade";

import config from "config";

const Canopy = () => {
  return (
    <>
      <CanopyToolbar position="topleft" />
      <CanopyLayer />
      <CanopyHotkeys />
      {config.env !== "prod" && (
        <>
          <ShadeDrawingLayers />
          <ShadeLayer />
          <ShadeObjectMarker />
        </>
      )}
    </>
  );
};

export { Canopy };
