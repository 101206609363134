export const base_inputs = {
  version: {
    value: "1.8.0",
    default_value: undefined,
    other_names: [],
  },
  platform: {
    value: "sift",
    default_value: undefined,
    other_names: [],
  },
  job_type: {
    value: "sift-run",
    default_value: undefined,
    other_names: [],
  },
  job_id: {
    value: undefined,
    default_value: undefined,
    other_names: [],
  },
  result_handler: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  api_key: {
    value: "",
    default_value: undefined,
    other_names: [],
  },

  site_features: {
    value: [],
    default_value: undefined,
    other_names: ["features"],
  },

  project_name: {
    value: "",
    default_value: undefined,
    other_names: [],
  },
  project_id: {
    value: undefined,
    default_value: undefined,
    other_names: [],
  },
  project_type: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  active: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  edit_dt: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  collab_on: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  locked_by_email: {
    value: "",
    default_value: undefined,
    other_names: [],
  },
  locked: {
    value: true,
    default_value: undefined,
    other_names: [],
  },
  locked_by_me: {
    value: false,
    default_value: undefined,
    other_names: [],
  },
  creator: {
    value: true,
    default_value: undefined,
    other_names: [],
  },
  user_list: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  notes: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  local_weather: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  gcr: {
    value: 0.5,
    default_value: undefined,
    other_names: [],
  },
  gcr_min: {
    value: 0.35,
    default_value: undefined,
    other_names: [],
  },
  gcr_max: {
    value: 0.44,
    default_value: undefined,
    other_names: [],
  },
  gcr_inc: {
    value: 0.01,
    default_value: undefined,
    other_names: [],
  },
  pitch_min: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  pitch_max: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  pitch_inc: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  spi_min: {
    value: 115,
    default_value: undefined,
    other_names: [],
  },
  spi_max: {
    value: 142,
    default_value: undefined,
    other_names: [],
  },
  spi_inc: {
    value: 3,
    default_value: undefined,
    other_names: [],
  },
  config_spacing_toggle: {
    value: "gcr",
    default_value: undefined,
    other_names: ["selected_gcr_pitch"],
    mapping_function: (old_value) => (old_value == 0 ? "gcr" : "pitch"),
  },
  gcrs: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  pitches: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  spis: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  rack_dims: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  racking_name: {
    value: "Generic SAT 26mod/str",
    default_value: undefined,
    other_names: [],
  },
  track_mode: {
    value: 1,
    default_value: undefined,
    other_names: ["type"],
  },

  racks: {
    value: [
      { key: 1, active: 1, xdim: 1.956, ydim: 88.303, module: 84, string_count: 3 },
      { key: 2, active: 1, xdim: 1.956, ydim: 59.41, module: 56, string_count: 2 },
      { key: 3, active: 0, xdim: 1.956, ydim: 30.516, module: 28, string_count: 1 },
    ],
    default_value: undefined,
    other_names: [],
  },
  mods_high: {
    value: 1,
    default_value: undefined,
    other_names: [],
    // component: (
    // 		<div className="input-row">
    // 			<label>Rack Grade Limit</label>
    // 			<Input
    // 				type="number"
    // 				id="rack_grade_limit"
    // 				size="small"
    // 				disabled={saving}
    // 				value={local_inputs.rack_grade_limit}
    // 				onChange={(event) => {
    // 					updateInput(event.target.id, event.target.value);
    // 				}}
    // 				suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
    // 			/>
    // 		</div>
    // 	)
  },
  gap: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  tilt_min: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  tilt_inc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  tilt_max: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  rack_grade_limit: {
    value: 10,
    default_value: undefined,
    other_names: ["grade_limit"],
  },
  orientation: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  continuous: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  can_continuous: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  vert_gap: {
    value: 0.02,
    default_value: undefined,
    other_names: [],
  },
  hori_gap: {
    value: 0.01,
    default_value: undefined,
    other_names: [],
  },
  string_counts: {
    value: [3, 2, 1],
    default_value: undefined,
    other_names: [],
  },

  simple_module: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  simple_inverter: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_dc_lock: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dc_lock_value: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  tilts: {
    value: [0],
    default_value: undefined,
    other_names: [],
  },
  rlim: {
    value: 60,
    default_value: undefined,
    other_names: ["track_angle"],
  },
  en_backtrack: {
    value: 1,
    default_value: undefined,
    other_names: ["backtrack"],
  },
  en_irradtrack: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  modules_high: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  module_gap: {
    value: 0.02,
    default_value: undefined,
    other_names: [],
  },
  drive_gap: {
    value: 2.0,
    default_value: undefined,
    other_names: [],
  },
  shade_mode: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  Fshd_CollWidth: {
    value: 1.956,
    default_value: undefined,
    other_names: [],
  },
  Fshd_StringSteps: {
    value: 1,
    default_value: undefined,
    other_names: ["string_steps"],
  },
  Fshd_Celltrav: {
    value: 0.156,
    default_value: undefined,
    other_names: ["cell_trav"],
  },
  bi_groundClearanceHeight: {
    value: 1.5,
    default_value: undefined,
    other_names: ["module_clearance_height"],
  },
  bi_transmissionFactor: {
    value: 0,
    default_value: undefined,
    other_names: ["module_transmission_perc"],
  },
  bi_structureShadeFactor: {
    value: 10,
    default_value: undefined,
    other_names: ["module_structure_shade_perc"],
  },
  latitude: {
    value: undefined,
    default_value: undefined,
    other_names: ["search_lat"],
  },
  longitude: {
    value: undefined,
    default_value: undefined,
    other_names: ["search_lng"],
  },
  elevation: {
    value: undefined,
    default_value: undefined,
    other_names: ["search_ele"],
  },
  timezone: {
    value: undefined,
    default_value: undefined,
    other_names: ["search_tz"],
  },
  weather_id: {
    value: undefined,
    default_value: undefined,
    other_names: [],
  },
  weather_method: {
    value: 0,
    default_value: undefined,
    other_names: [],
    notes: "0=Automatic, 1=Manual",
  },
  weather_source: {
    value: 0,
    default_value: undefined,
    other_names: ["source"],
    notes: "0=NSRDB, 1=NASA",
  },

  weather_name: {
    value: "Automatic",
    default_value: undefined,
    other_names: [],
  },

  weather_summary: {
    value: undefined,
    default_value: undefined,
    other_names: ["Summary"],
  },
  weather_latitude: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  weather_longitude: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  weather_elevation: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  weather_timezone: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  mod_per_string: {
    value: 26,
    default_value: undefined,
    other_names: ["modules_per_string"],
  },
  perf_name: {
    value: "",
    default_value: undefined,
    other_names: ["performance_name"],
  },
  dc_degrade: {
    value: 0.5,
    default_value: undefined,
    other_names: ["dc_degradation"],
  },
  dc_thermal_Uc: {
    value: 29,
    default_value: undefined,
    other_names: ["thermal_uc"],
  },
  dc_thermal_Uv: {
    value: 0,
    default_value: undefined,
    other_names: ["thermal_uv"],
  },
  dc_module_quality_loss: {
    value: -1.0,
    default_value: undefined,
    other_names: ["quality_loss"],
  },
  dc_module_lid_loss: {
    value: 1.5,
    default_value: undefined,
    other_names: ["lid_loss"],
  },
  dc_module_mismatch: {
    value: 1.5,
    default_value: undefined,
    other_names: ["mismatch_loss"],
  },
  dc_strings_mismatch: {
    value: 0.1,
    default_value: undefined,
    other_names: [],
  },
  dc_wiring_loss_at_stc: {
    value: 2.0,
    default_value: undefined,
    other_names: ["dc_loss_stc"],
  },
  bi_back_mismatch: {
    value: 10.0,
    default_value: undefined,
    other_names: [],
  },
  en_powerfactor: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  pnom_apparent: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  power_factor: {
    value: 0.7,
    default_value: undefined,
    other_names: [],
  },
  ac_aux_kw: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_wiring_loss_at_stc: {
    value: 2.0,
    default_value: undefined,
    other_names: ["ac_losses"],
  },
  ac_transformer_loss_constant: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_transformer_loss_at_stc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_MV_line_loss_stc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_hv_transformer_loss_at_stc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_transmission_loss: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ac_other_loss: {
    value: 0.0,
    default_value: undefined,
    other_names: [],
  },
  simple_inverter_dcac: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_monthly_losses: {
    value: 0,
    default_value: undefined,
    other_names: ["doMonthlySoiling"],
  },
  soiling_single: {
    value: 2.0,
    default_value: undefined,
    other_names: ["soiling"],
  },
  albedo_single: {
    value: 0.2,
    default_value: undefined,
    other_names: ["albedo"],
  },
  soiling: {
    value: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
    default_value: undefined,
    other_names: ["soiling_monthly"],
  },
  albedo: {
    value: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
    default_value: undefined,
    other_names: ["albedo_monthly"],
  },
  sazm: {
    value: 180,
    default_value: undefined,
    other_names: ["azimuth"],
  },

  bi_bifaciality: {
    value: 0.7,
    default_value: undefined,
    other_names: ["module_bifaciality"],
  },
  en_bifacial: {
    value: 1,
    default_value: undefined,
    other_names: ["module_isbifacial"],
  },
  mod_name: {
    value: "Generic 535W Bifacial",
    default_value: undefined,
    other_names: ["module_name"],
  },
  mod_rating: {
    value: 535,
    default_value: undefined,
    other_names: ["rating"],
  },
  mod_area: {
    value: 2.613,
    default_value: undefined,
    other_names: ["module_area"],
  },
  mod_width: {
    value: 1.096,
    default_value: undefined,
    other_names: ["mlm_Width"],
  },
  mod_height: {
    value: 2.384,
    default_value: undefined,
    other_names: ["mlm_Length"],
  },
  mlm_N_series: {
    value: 55,
    default_value: undefined,
    other_names: [],
  },
  mlm_N_parallel: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  mlm_N_diodes: {
    value: 3,
    default_value: undefined,
    other_names: [],
  },
  mlm_V_mp_ref: {
    value: 31,
    default_value: undefined,
    other_names: [],
  },
  mlm_I_mp_ref: {
    value: 17.28,
    default_value: undefined,
    other_names: [],
  },
  mlm_V_oc_ref: {
    value: 37.3,
    default_value: undefined,
    other_names: [],
  },
  mlm_I_sc_ref: {
    value: 18.36,
    default_value: undefined,
    other_names: [],
  },
  mlm_S_ref: {
    value: 1000,
    default_value: undefined,
    other_names: [],
  },
  mlm_T_ref: {
    value: 25,
    default_value: undefined,
    other_names: [],
  },
  mlm_R_shref: {
    value: 140,
    default_value: undefined,
    other_names: [],
  },
  mlm_R_sh0: {
    value: 3200,
    default_value: undefined,
    other_names: [],
  },
  mlm_R_shexp: {
    value: 3.2,
    default_value: undefined,
    other_names: [],
  },
  mlm_R_s: {
    value: 0.112,
    default_value: undefined,
    other_names: [],
  },
  mlm_alpha_isc: {
    value: 10.47,
    default_value: undefined,
    other_names: [],
  },
  mlm_beta_voc_spec: {
    value: -115.8,
    default_value: undefined,
    other_names: [],
  },
  mlm_n_0: {
    value: 1.014,
    default_value: undefined,
    other_names: [],
  },
  mlm_mu_n: {
    value: -0.0005,
    default_value: undefined,
    other_names: [],
  },
  mlm_T_c_fa_alpha: {
    value: 0.9,
    default_value: undefined,
    other_names: [],
  },
  mlm_D2MuTau: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  mlm_E_g: {
    value: 1.121,
    default_value: undefined,
    other_names: [],
  },
  muPmpReq: {
    value: -0.352,
    default_value: undefined,
    other_names: [],
  },
  module_iam_ang: {
    value: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    default_value: undefined,
    other_names: [],
  },
  module_iam_eff: {
    value: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    default_value: undefined,
    other_names: [],
  },
  technology: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  mod_pnom: {
    value: 535,
    default_value: undefined,
    other_names: [],
  },

  inv_name: {
    value: "Generic 1000kW",
    default_value: undefined,
    other_names: ["inverter_name"],
  },
  inv_rating: {
    value: 1000,
    default_value: undefined,
    other_names: ["inverterRating"],
  },
  derate_maxoutput: {
    value: [1100.0, 1000.0],
    default_value: undefined,
    other_names: [],
  },
  derate_temps: {
    value: [25.0, 50.0],
    default_value: undefined,
    other_names: [],
  },
  derate_toggle: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  inv_pd_eff_pout: {
    value: [
      500.0, 1000.0, 1500.0, 2000.0, 2500.0, 3000.0, 3500.0, 4000.0, 4500.0, 5000.0, 7777.778, 10555.556, 13333.333, 16111.111, 18888.889, 21666.667, 24444.444, 27222.222, 30000.0, 37777.778,
      45555.556, 53333.333, 61111.111, 68888.889, 76666.667, 84444.444, 92222.222, 100000.0,
    ],
    default_value: undefined,
    other_names: [],
  },
  inv_pd_efficiency: {
    value: [
      0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16,
      98.92999999999999, 98.7,
    ],
    default_value: undefined,
    other_names: [],
  },
  inv_pd_pacokw: {
    value: 1100,
    default_value: undefined,
    other_names: [],
  },
  inv_pd_pnt: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  inv_pd_vdcmax: {
    value: 1000,
    default_value: undefined,
    other_names: [],
  },
  maxEff: {
    value: 99,
    default_value: undefined,
    other_names: [],
  },
  mppt_hi_inverter: {
    value: 850,
    default_value: undefined,
    other_names: [],
  },
  mppt_low_inverter: {
    value: 460,
    default_value: undefined,
    other_names: [],
  },
  inv_pnom: {
    value: 1000,
    default_value: undefined,
    other_names: ["pnom"],
  },
  v1_eff: {
    value: [0],
    default_value: undefined,
    other_names: [],
  },
  v1_pl: {
    value: [0.0],
    default_value: undefined,
    other_names: [],
  },
  v2_eff: {
    value: [0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
    default_value: undefined,
    other_names: [],
  },
  v2_pl: {
    value: [0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
    default_value: undefined,
    other_names: [],
  },
  v3_eff: {
    value: [0],
    default_value: undefined,
    other_names: [],
  },
  v3_pl: {
    value: [0.0],
    default_value: undefined,
    other_names: [],
  },
  vs_effeu: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  vs_effmax: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  vs_vnom: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  iv_curves: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  pthresh: {
    value: 42500.0,
    default_value: undefined,
    other_names: [],
  },
  imax_ac: {
    value: 2570.0,
    default_value: undefined,
    other_names: [],
  },
  imax_dc: {
    value: 4650.0,
    default_value: undefined,
    other_names: [],
  },
  inv_pd_pdco: {
    value: 2905760.0,
    default_value: undefined,
    other_names: [],
  },
  layout_name: {
    value: "",
    default_value: undefined,
    other_names: [],
  },
  do_grid_poi_lim: {
    value: 0,
    default_value: undefined,
    other_names: ["doGridPower"],
  },
  ac_grid_poi_lim: {
    value: 0,
    default_value: undefined,
    other_names: ["grid_power_limit"],
  },
  do_designer_margin: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  designer_margin: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_inv_spacing: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  inverter_per_cut: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  epsg: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_inverter_limit_lock: {
    value: 0,
    default_value: undefined,
    other_names: ["do_inverter_qty_lock"],
  },
  inverter_limit: {
    value: 0,
    default_value: undefined,
    other_names: ["inverter_qty"],
  },
  do_wiring: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  wiring_inv_rating: {
    value: 2000,
    default_value: undefined,
    other_names: [],
  },
  wiring_spi: {
    value: 200,
    default_value: undefined,
    other_names: [],
  },
  do_rack_align: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  do_roads: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  road_spacing: {
    value: 300,
    default_value: undefined,
    other_names: [],
  },
  road_spacing_option: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  road_width: {
    value: 4,
    default_value: undefined,
    other_names: [],
  },
  road_to_rack: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  inv_pad_x: {
    value: 6,
    default_value: undefined,
    other_names: [],
  },
  inv_pad_y: {
    value: 3,
    default_value: undefined,
    other_names: [],
  },
  road_to_rack_inv: {
    value: 3,
    default_value: undefined,
    other_names: [],
  },
  inverter_grouping: {
    value: 0,
    default_value: undefined,
    other_names: [],
    notes: "off - fill - integer",
  },
  combiner_location: {
    value: 1,
    default_value: undefined,
    other_names: [],
    notes: "centroids or by_road",
  },
  strings_per_combiner: {
    value: 16,
    default_value: undefined,
    other_names: [],
  },
  wire_stringing: {
    value: 0, // center or split string
    default_value: undefined,
    other_names: [],
  },

  do_dual_tilt: {
    value: 0,
    default_value: undefined,
    other_names: ["do_dualTilt"],
  },
  topo_id: {
    value: undefined,
    default_value: undefined,
    other_names: [],
  },
  boundary_bbox: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  map_center: {
    value: [34.6964398, -118.2827397],
    default_value: undefined,
    other_names: [],
  },
  topo_source: {
    value: "USGS",
    default_value: undefined,
    other_names: [],
  },

  graded_avail: {
    value: false,
    default_value: undefined,
    other_names: [],
  },
  grade_target: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  grade_cut_amt: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  grade_fill_amt: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },

  vis_ele_ungraded_avail: {
    value: false,
    default_value: undefined,
    other_names: ["topo_live"],
  },
  vis_ele_graded_avail: {
    value: false,
    default_value: undefined,
    other_names: [],
  },

  vis_ns_ungraded_percent: {
    value: 10,
    other_names: ["ns_grade_limit"],
  },
  vis_ns_ungraded_avail: {
    value: false,
    other_names: ["topo_live"],
  },

  vis_ew_ungraded_percent: {
    value: 20,
    other_names: ["ew_grade_limit"],
  },
  vis_ew_ungraded_avail: {
    value: false,
    other_names: ["topo_live"],
  },

  vis_max_ungraded_percent: {
    value: 15,
    other_names: ["u_grade_limit"],
  },
  vis_max_ungraded_avail: {
    value: false,
    other_names: ["topo_live"],
  },

  topo_layout_surface: {
    value: "ungraded",
    default_value: undefined,
    other_names: [],
  },
  topo_layout_action: {
    value: "nothing",
    default_value: undefined,
    other_names: ["topo_action"],
    notes: "nothing || delete",
  },
  topo_mode: {
    value: "off",
    default_value: undefined,
    other_names: [],
    notes: "off || ele || u || ns || ew",
  },
  topo_url: {
    value: "",
    default_value: undefined,
    other_names: [],
  },
  topo_scale_url: {
    value: "",
    default_value: undefined,
    other_names: [],
  },

  do_finance: {
    value: false,
    default_value: undefined,
    other_names: [],
  },
  finance_name: {
    value: "",
    default_value: undefined,
    other_names: [],
  },
  metric: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  analysis_period: {
    value: 20,
    default_value: undefined,
    other_names: [],
  },
  discount_rate: {
    value: 8,
    default_value: undefined,
    other_names: [],
  },
  dc_op_cost: {
    value: 10,
    default_value: undefined,
    other_names: [],
  },
  ac_op_cost: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  fixed_op_cost: {
    value: 20000,
    default_value: undefined,
    other_names: [],
  },
  lease_cost: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  escalation: {
    value: 2,
    default_value: undefined,
    other_names: [],
  },
  do_custom_schedule: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  customSchedule: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  summarized_dc: {
    value: 0.95,
    default_value: undefined,
    other_names: [],
  },
  summarized_ac: {
    value: 0.1,
    default_value: undefined,
    other_names: [],
  },
  foot_print: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  fixed_cost: {
    value: 1500000,
    default_value: undefined,
    other_names: [],
  },
  toggle_finance_type: {
    value: 1, // 0 = summarized, 1 = detailed
    default_value: undefined,
    other_names: [],
  },
  module_dc_cost: {
    value: { value: 0.35, type: 0, total: 0.35 },
    default_value: undefined,
    other_names: [],
  },
  rack_a_finance: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  rack_b_finance: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  rack_c_finance: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  bos_other: {
    value: { value: 0.6, type: 0, total: 0.6 },
    default_value: undefined,
    other_names: [],
  },
  inverter_cost: {
    value: { value: 0.06, type: 0, total: 0.06 },
    default_value: undefined,
    other_names: [],
  },
  ac_aux: {
    value: { value: 0.04, type: 0, total: 0.04 },
    default_value: undefined,
    other_names: [],
  },
  mv_wire: {
    value: { value: 0, type: 0, total: 0.0 },
    default_value: undefined,
    other_names: [],
  },
  other_ac: {
    value: { value: 0, type: 0, total: 0.0 },
    default_value: undefined,
    other_names: [],
  },
  interconnection: {
    value: { value: 1000000, type: 0, total: 1000000 },
    default_value: undefined,
    other_names: [],
  },
  permits_fees: {
    value: { value: 250000, type: 0, total: 250000 },
    default_value: undefined,
    other_names: [],
  },
  engineering: {
    value: { value: 100000, type: 0, total: 100000 },
    default_value: undefined,
    other_names: [],
  },
  margin: {
    value: { value: 150000, type: 0, total: 150000 },
    default_value: undefined,
    other_names: [],
  },
  other_fixed: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  rack_footprint: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  boundary_area: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  contingency: {
    value: { value: 0, type: 0, total: 0 },
    default_value: undefined,
    other_names: [],
  },
  spacing_gcr: {
    value: [0.3, 0.45, 0.6],
    default_value: undefined,
    other_names: [],
  },
  spacing_per_wp: {
    value: [0, 0, 0],
    default_value: undefined,
    other_names: [],
  },
  footprint_op_cost: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  area_install_cost: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  area_op_cost: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ri_power: {
    value: 0.06,
    default_value: undefined,
    other_names: [],
  },
  ri_escalation: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  do_include_itc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  itc_percent: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_include_ptc: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ptc_value: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  do_ri_customSchedule: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  ri_customSchedule: {
    value: [],
    default_value: undefined,
    other_names: [],
  },
  debt_structure: {
    value: 1,
    default_value: undefined,
    other_names: [],
  },
  debt_percent: {
    value: 65,
    default_value: undefined,
    other_names: [],
  },
  debt_dscr: {
    value: 1.3,
    default_value: undefined,
    other_names: [],
  },
  debt_interest: {
    value: 4,
    default_value: undefined,
    other_names: [],
  },
  debt_tenor: {
    value: 15,
    default_value: undefined,
    other_names: [],
  },
  dep_5yrSL: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_15yrSL: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_20yrSL: {
    value: 100,
    default_value: undefined,
    other_names: [],
  },
  dep_30yrSL: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_35yrSL: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_39yrSL: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_5yrMACRS: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  dep_15yrMACRS: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  state_taxes: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  federal_taxes: {
    value: 20,
    default_value: undefined,
    other_names: [],
  },
  revenue_type: {
    value: 0,
    default_value: undefined,
    other_names: [],
  },
  tou_grid: {
    value: [
      [1, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [2, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
      [3, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [4, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [5, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [6, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [7, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [8, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [9, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [10, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
      [11, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [12, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [13, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [14, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [15, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
      [16, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
      [17, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
      [18, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
      [19, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [20, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
      [21, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [22, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [23, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      [24, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    ],
    default_value: undefined,
    other_names: [],
  },
  worker_limit: 500,
};
