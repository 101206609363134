import styled from "styled-components";

const FinanceTabLayout = styled.section`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: auto;
  gap: 10px 0px;

  .input-row-two-col {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "label input";

    label {
      grid-area: label;
    }

    input {
      grid-area: input;
      max-width: 135px;
      margin-left: auto;
    }

    p {
      margin: 0px;
    }

    /* stupid antd */
    .finance-install-cost-radios {
      > label {
        margin-right: 4px;
        > span {
          padding-right: 1px !important;
          padding-left: 1px !important;
        }
      }
    }
  }
  .input-row-two-onethree-col {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr;
    grid-template-areas: "label input";

    label {
      grid-area: label;
    }

    input {
      grid-area: input;
      max-width: 215px;
      margin-left: auto;
    }

    p {
      margin: 0px;
    }
  }

  .input-row-three-col {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1.5fr 1.5fr;
    grid-template-rows: 1fr;
    gap: 0px 5px;
    grid-template-areas: "label input dropdown";

    input {
      max-width: 135px;
      margin-left: auto;
    }

    .ant-select-selection-item {
      width: inherit;
    }
  }

  .total-row {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1.5fr 1.5fr;
    grid-template-rows: 1fr;

    p {
      margin: 0px;
      white-space: nowrap;
    }

    p:nth-child(2) {
      width: 100%;
      white-space: nowrap;
      margin: 0px auto 0px 10px;
    }
  }

  .custom-schedule-row {
    display: grid;
    grid-template-columns: 1fr min-content 0.6fr;
    align-items: center;
    gap: 12px;

    input {
      max-width: 135px;
      margin-left: auto;
    }

    a,
    p {
      white-space: nowrap;
    }
  }
`;

const DCWireWrapper = styled.section`
  width: 350px;
  display: grid;
  max-height: 550px;
  overflow-x: hidden;
  /* overflow-y: scroll; */

  .dc-table {
    width: 275px;
  }
`;

export { FinanceTabLayout, DCWireWrapper };
