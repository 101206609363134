import styled, { keyframes } from "styled-components";

const rotate = keyframes`
	to { transform: rotate(360deg); }
`;

const DivWrapper = styled.div`
  .spin-blur {
    clear: both;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
  .spin-container {
    position: relative;
    transition: opacity 0.3s;
  }
  &.spin-nested-loading {
    position: relative;
  }
`;

const SpinWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--primary-brand-color);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* position: relative; */
  display: none;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &.spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }
  &.spin {
    /* position: absolute; */
    top: 0;
    inset-inline-start: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  .spin-dot-spin {
    animation: ${rotate} 1.2s infinite linear;
  }
  .spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;

    &.large {
      font-size: 32px;
    }
    &.small {
      font-size: 14px;
    }
  }

  .spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: var(--primary-brand-color);
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    /* opacity: 0.3; */
    animation-name: css-dev-only-do-not-override-15rg2km-antSpinMove;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    &.large {
      width: 14px;
      height: 14px;
    }
    &.small {
      width: 6px;
      height: 6px;
    }
    &:nth-child(1) {
      top: 0;
      inset-inline-start: 0;
    }
    &:nth-child(2) {
      top: 0;
      inset-inline-end: 0;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      inset-inline-end: 0;
      bottom: 0;
      animation-delay: 0.8s;
    }
    &:nth-child(4) {
      bottom: 0;
      inset-inline-start: 0;
      animation-delay: 1.2s;
    }
  }
  .spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #ffffff;
    font-size: 14px;
    padding-top: 11px;
    position: relative;
  }
`;

export { DivWrapper, SpinWrapper };
