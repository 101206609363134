/*
	This is the DEFAULT input set used by portal to initialize the inputs state 
	and to be used when hitting New Project

	really the only inputs in here should be inputs that can be changed through portal
	so that we can easily spread this into the master_inputs.json to end with the final_inputs.json

	final_inputs = {
		...master_inputs,
		...inputs
	}
*/

import config from "config";
import { FinanceSelectMenu } from "../..";
import { getBounds, getNewCenterPoint, uuid, getCenterPointofFeature } from "../_helpers";
import { base_inputs } from "../_helpers/sift_base_inputs";
// import defaultProducts from "../../Sunfig/data/defaultProductData";
// import defaultModules from "../../Sunfig/data/defaultModules";
// import { Terratrak_2P_SAT, Standard_GFT } from "../helpers/gmCalculators.js";
//

const build = config.platform;

const default_ui_state = {
  do_module_target: false,
  do_gcr_override: false,
  do_tilt_override: false,
  do_dimension_override: false,
  do_advanced_mod_specs: false,
  tab: "simplified",
  selected_module_index: 0,
  selected_product_index: 0,
  topo_error: undefined,
  topo_loading: false,
  ele_graded_checked: false,
  max_ungraded_checked: false,
  max_graded_checked: false,
  ns_ungraded_checked: false,
  ns_graded_checked: false,
  ew_ungraded_checked: false,
  ew_graded_checked: false,
  pm_modal_visible: false,
  disable_hotkeys: false,
  is_drawing: false,
  is_drawing_polyline: false,
  // tile_set: "SatelliteHD",
  tile_set: "Mapbox",

  search_visible: false,
  additional_layers: {
    wetlands_on: false,
    riparian_on: false,
    fema_on: false,
    transmission_lines_on: false,
    gas_lines_on: false,
    railway_lines_on: false,
  },
  layout_visible: true,
  loading: false,
  // portal specific for salesforce
  show_sf_modal: false,
  download_type: undefined,
  download_lock: true,
  show_dims: false,
  show_azi: false,
};

const internal_def_inputs = {
  // INTERNAL SIFT DEFAULT
  gcr_min: 0.3,
  gcr_max: 0.7,
  gcrs: [
    0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62,
    0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7,
  ],

  weather_source: 0,

  inv_id: "simple_inv",
  inv_name: "Simple Inverter Input",
  simple_inverter: 1,
  inv_rating: 1000,
  simple_inverter_dcac: 1.4,

  racking_id: "Terrasmart GLIDE Wave",
  racking_name: "Terrasmart GLIDE Wave",
  track_mode: 0,
  type: 0,
  design_temp: -10,
  racks: [
    { key: 1, active: 1, xdim: 27.906, ydim: 3.917, module: 56, string_count: 2 },
    { key: 2, active: 1, xdim: 14.018, ydim: 3.917, module: 28, string_count: 1 },
    { key: 3, active: 1, xdim: 7.074, ydim: 3.917, module: 14, string_count: 0.5 },
  ],
  rack_dims: 0,
  mod_per_string: 26,
  gap: 0,
  tilt_min: 10,
  tilt_max: 35,
  tilt_inc: 5,
  tilts: [10, 15, 20, 25, 30, 35],
  rlim: 0,
  en_backtrack: 0,
  en_irradtrack: 0,
  shade_mode: 1,
  modules_high: 2,
  orientation: 0,
  module_gap: 0.025,
  drive_gap: 0.025,
  rack_grade_limit: 20,
  bi_groundClearanceHeight: 1,
  bi_transmissionFactor: 0,
  bi_structureShadeFactor: 10,
  Fshd_StringSteps: 2,
  Fshd_Celltrav: 0.156,
  product_type: "wave",

  do_pvWatts: 1,
  simple_module: 1,
  name: "Simple Module Input",
  mod_name: "Simple Module Input",
  mod_rating: 500,
  bi_bifaciality: 0.7,
  mod_width: 1.133,
  mod_height: 2.286,
  mod_id: "simple_mod",
  default: 0,
};

export const default_inputs = {
  version: "1.8.0",
  platform: "sift",

  job_type: "sift-run",
  // job_type: "portal-ground",
  job_id: undefined,

  warnings: [],

  result_handler: 1,
  api_key: "",

  site_features: [],
  offset_type: "m",
  project_name: "",
  project_id: undefined,
  active: 1,
  collab_on: 0,
  // locked_by_me, locked, locked_by_email

  local_weather: 0,
  en_topo_perf: 0,

  // managed in portal through do_override_gcr toggle that unlocks inputs for min/max
  gcr: 0.5,
  gcr_min: 0.35,
  gcr_max: 0.44,
  gcr_inc: 0.01,
  pitch_min: 0,
  pitch_max: 0,
  pitch_inc: 1,
  spi_min: 75,
  spi_max: 100,
  spi_inc: 3,
  config_spacing_toggle: "gcr",
  worker_count: 100,

  spi_min_limit: 68,
  spi_max_limit: 217,

  gcrs: [0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44],
  pitches: [],
  spis: [115, 118, 121, 124, 127, 130, 133, 136, 139, 142],

  // somewhat racking specific inputs

  // Auto or Manual racking dims? 0=Auto 1=Manual
  racking_errors: undefined,

  // INTERNAL SIFT DEFAULT
  // racking_name: "Terrasmart GLIDE Fuse",
  // track_mode: 0,
  // design_temp: -10,
  // racks: [
  //   { key: 1, active: 1, xdim: 1.96, ydim: 27.91, module: 56, string_count: 4 },
  //   { key: 2, active: 1, xdim: 1.96, ydim: 14.02, module: 28, string_count: 3 },
  //   { key: 3, active: 1, xdim: 1.96, ydim: 7.07, module: 14, string_count: 2 },
  // ],
  // rack_dims: 0,
  // mod_per_string: 26,
  // gap: 0,
  // tilt_min: 7.5,
  // tilt_max: 12.5,
  // tilt_inc: 2.5,
  // tilts: [7.5, 10, 12.5],
  // rlim: 0,
  // en_backtrack: 0,
  // en_irradtrack: 0,
  // shade_mode: 1,
  // modules_high: 4,
  // orientation: 0,
  // module_gap: 0.025,
  // drive_gap: 0.025,
  // rack_grade_limit: 10,
  // bi_groundClearanceHeight: 1,
  // bi_transmissionFactor: 0,
  // bi_structureShadeFactor: 10,
  // Fshd_StringSteps: 4,
  // Fshd_Celltrav: 0.156,
  product_type: "agile",

  racking_name: "Generic SAT 26mod/str",
  track_mode: 1,
  design_temp: -10,
  racks: [
    { key: 1, active: 1, xdim: 4.788, ydim: 45.504, module: 78, string_count: 3 },
    { key: 2, active: 1, xdim: 4.788, ydim: 30.996, module: 52, string_count: 2 },
    { key: 3, active: 0, xdim: 4.788, ydim: 16.488, module: 26, string_count: 1 },
  ],
  rack_dims: 0,
  mod_per_string: 26,
  gap: 2,
  tilt_min: 0,
  tilt_inc: 0,
  tilt_max: 0,
  tilts: [0],
  rlim: 60,
  en_backtrack: 1,
  en_irradtrack: 0,
  shade_mode: 1,
  modules_high: 1,
  orientation: 0,
  module_gap: 0.02,
  drive_gap: 2.0,
  rack_grade_limit: 10,
  bi_groundClearanceHeight: 1.5,
  bi_transmissionFactor: 0,
  bi_structureShadeFactor: 10,
  Fshd_CollWidth: 1.956,
  Fshd_StringSteps: 1,
  Fshd_Celltrav: 0.156,

  continuous: 0,
  can_continuous: 0,
  // Portal uses strings for this, SIFT uses integers
  // orientation: "portrait",
  vert_gap: 0.02,
  hori_gap: 0.01,
  // string_counts used in portal is broken out from racks.. not sure which would be better
  string_counts: [3, 2, 1],
  // set by product selection

  // set elsewhere
  latitude: undefined,
  longitude: undefined,
  elevation: undefined,
  timezone: undefined,
  weather: undefined,
  weather_source: 1,
  weather_summary: undefined,
  weather_name: "Automatic",
  // gcr: undefined,

  // readonly unless simplified tab selected

  // hidden/hardcoded
  //analysis_period: 1,

  // new user inputs
  // DC losses
  perf_name: "",
  dc_degrade: 0.5,
  dc_thermal_Uc: 29,
  dc_thermal_Uv: 0,
  dc_module_quality_loss: -1.0,
  dc_module_lid_loss: 1.5,
  dc_module_mismatch: 1.5,
  dc_strings_mismatch: 0.1,
  dc_wiring_loss_at_stc: 2.0,
  // hide if bifacial off
  bi_back_mismatch: 10.0,
  en_powerfactor: 0,
  power_factor: 0.7,
  pnom_apparent: 0,
  // AC losses
  ac_aux_kw: 0,
  ac_wiring_loss_at_stc: 2.0,
  ac_transformer_loss_constant: 0,
  ac_transformer_loss_at_stc: 0,
  ac_MV_line_loss_stc: 0,
  ac_hv_transformer_loss_at_stc: 0,
  ac_transmission_loss: 0,
  ac_other_loss: 0.0,
  // simple_inverter_dcac: 1.4,
  // single vs monthly
  do_monthly_losses: 0,
  soiling_single: 2.0,
  albedo_single: 0.2,
  soiling: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
  albedo: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],

  sazm: 180,

  // MODULE INPUTS
  //simple_module: 0,
  modules: [],
  bi_bifaciality: 0.7,
  en_bifacial: 1,
  mod_name: "Generic 535W Bifacial",
  mod_dims_calc: 0,

  mod_rating: 535,
  mod_area: 2.613,
  mod_width: 1.096,
  mod_height: 2.384,

  mlm_N_series: 55,
  mlm_N_parallel: 2,
  mlm_N_diodes: 3,
  mlm_V_mp_ref: 31,
  mlm_I_mp_ref: 17.28,
  mlm_V_oc_ref: 37.3,
  mlm_I_sc_ref: 18.36,
  mlm_S_ref: 1000,
  mlm_T_ref: 25,
  mlm_R_shref: 140,
  mlm_R_sh0: 3200,
  mlm_R_shexp: 3.2,
  mlm_R_s: 0.112,
  mlm_alpha_isc: 10.47,
  mlm_beta_voc_spec: -115.8,
  mlm_n_0: 1.014,
  mlm_mu_n: -0.0005,
  mlm_T_c_fa_alpha: 0.9,
  mlm_D2MuTau: 0,
  mlm_E_g: 1.121,
  muPmpReq: -0.352,
  module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
  module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
  technology: 0,
  mod_pnom: 535,

  // INVERTER INPUTS
  simple_inverter: 0,
  inv_rating: 1000,
  inv_name: "Generic 1000kW",
  simple_inverter_dcac: 1.4,
  derate_maxoutput: [1100.0, 1000.0],
  derate_temps: [25.0, 50.0],
  derate_toggle: 0,
  inv_pd_eff_pout: [
    5.0, 10.0, 15.0, 20.0, 25.0, 30.0, 35.0, 40.0, 45.0, 50.0, 77.77778, 105.55556, 133.33333000000002, 161.11111, 188.88889, 216.66667, 244.44444, 272.22222, 300.0, 377.77778, 455.55555999999996,
    533.3333299999999, 611.1111099999999, 688.88889, 766.6666700000001, 844.44444, 922.22222, 1000.0,
  ],
  inv_pd_efficiency: [
    0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16,
    98.92999999999999, 98.7,
  ],
  inv_pd_pacokw: 1100,
  inv_pd_pnt: 0,
  inv_pd_vdcmax: 1000,
  maxEff: 99,
  mppt_hi_inverter: 850,
  mppt_low_inverter: 460,
  inv_pnom: 1000,
  v1_eff: [0],
  v1_pl: [0.0],
  v2_eff: [0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
  v2_pl: [0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
  v3_eff: [0],
  v3_pl: [0.0],
  vs_effeu: [],
  vs_effmax: [],
  vs_vnom: [],
  iv_curves: [],
  pthresh: 0,
  // imax_ac: 2570.0,
  // imax_dc: 4650.0,
  // inv_pd_pdco: 2905760.0,
  //

  layout_name: "",
  do_grid_poi_lim: 0,
  ac_grid_poi_lim: 0,
  do_designer_margin: 0,
  designer_margin: 0,
  do_inv_spacing: 0,
  inverter_per_cut: 1,
  epsg: 0,
  do_inverter_limit_lock: 0,
  inverter_limit: 0,

  do_wiring: 0,
  // wiring_dcac: 1.4,
  // wiring_mod_rating: 400,
  // wiring_modules_per_string: 28,
  wiring_inv_rating: 2000,
  wiring_spi: 200,
  // wiring_max_row_length: 2,
  do_rack_align: 1,
  // azimuth: 180,
  do_roads: 1,
  road_spacing: 300,
  road_spacing_option: 2,
  road_width: 4,
  road_to_rack: 2,
  inv_pad_x: 6,
  inv_pad_y: 3,
  road_to_rack_inv: 3,
  // off - fill - integer
  inverter_grouping: 0,
  // centroids or by_road
  combiner_location: 1,
  strings_per_combiner: 16,
  // center or split string
  wire_stringing: 0,

  // controlled by toggle in ui -> do_dc_lock: false -- remember to set back to 0 when toggling off
  do_dc_lock: 0,
  dc_lock_value: 0,

  do_dual_tilt: 0,

  topo_id: undefined,
  boundary_bbox: [],
  // topo_id: "9354b9c857bd405cad431db3d3d41425",
  // boundary_bbox: [
  // 	-81.73639876613618,
  // 	38.04260029408751,
  // 	-81.69645826305425,
  // 	38.07316583188557
  // ],
  // map_center: [undefined, undefined],
  map_center: [34.6964398, -118.2827397],
  topo_source: "USGS",
  // what should the elevation be graded to % wise
  graded_avail: false,
  grade_target: 0,
  grade_cut_amt: 0,
  grade_fill_amt: 0,

  vis_ele_ungraded_avail: false,
  vis_ns_ungraded_percent: 10,
  vis_ns_ungraded_avail: false,
  vis_ew_ungraded_percent: 20,
  vis_ew_ungraded_avail: false,
  vis_max_ungraded_percent: 15,
  vis_max_ungraded_avail: false,

  // vis_ele: { ungraded_percent: 0,  ungraded_avail:false, graded_percent:0, graded_avail: false},
  // vis_max: { ungraded_percent: 15, ungraded_avail:false, graded_percent:15, graded_avail: false},
  // vis_ns: { ungraded_percent: 10, ungraded_avail:false, graded_percent:10, graded_avail: false},
  // vis_ew: { ungraded_percent: 20, ungraded_avail:false, graded_percent:20, graded_avail: false},
  // vis_g: { ungraded_percent: 20, ungraded_avail:false, graded_percent:20, graded_avail: false},

  // vis_g: { "graded_avail": false, "graded_percent": 0, "ungraded_avail": false, "ungraded_percent": 0 },
  // vis_ew: { "graded_avail": false, "graded_percent": 20, "ungraded_avail": true, "ungraded_percent": 20 },
  // vis_ns: { "graded_avail": false, "graded_percent": 10, "ungraded_avail": true, "ungraded_percent": 10 },
  // vis_ele: { "graded_avail": false, "graded_percent": 0, "ungraded_avail": true, "ungraded_percent": 0 },
  // vis_max: { "graded_avail": false, "graded_percent": 15, "ungraded_avail": true, "ungraded_percent": 15 },
  // ungraded || graded
  topo_layout_surface: "ungraded",
  // nothing || delete
  topo_layout_action: "nothing",
  // off || ele || u || ns || ew
  topo_mode: "off",
  topo_url: "",
  topo_scale_url: "",
  // topo_url: "https://topo-tiles.sunfig.com/test/9354b9c857bd405cad431db3d3d41425/NS/10/{z}/{x}/{y}.png",
  // topo_scale_url: "https://topo-tiles.sunfig.com/test/9354b9c857bd405cad431db3d3d41425/NS/10/scale.png",

  finance_name: "",
  do_finance: 0,
  metric: 0,
  analysis_period: 20,
  discount_rate: 8,
  dc_op_cost: 10,
  ac_op_cost: 0,
  fixed_op_cost: 20000,
  lease_cost: 0,
  escalation: 2,
  do_custom_schedule: 0,
  customSchedule: [],

  // summarized
  summarized_dc: 0.95,
  summarized_ac: 0.1,
  foot_print: 0,
  fixed_cost: 1500000,

  toggle_finance_type: 1, // 0 = summarized, 1 = detailed

  // D/C Units
  module_dc_cost: { value: 0.35, type: 0, total: 0.35 },
  rack_a_finance: { value: 0, type: 0, total: 0 },
  rack_b_finance: { value: 0, type: 0, total: 0 },
  rack_c_finance: { value: 0, type: 0, total: 0 },
  bos_other: { value: 0.6, type: 0, total: 0.6 },
  // A/C Units
  inverter_cost: { value: 0.06, type: 0, total: 0.06 },
  ac_aux: { value: 0.04, type: 0, total: 0.04 },
  mv_wire: { value: 0, type: 0, total: 0.0 },
  other_ac: { value: 0, type: 0, total: 0.0 },
  // fixed units
  interconnection: { value: 1000000, type: 0, total: 1000000 },
  permits_fees: { value: 250000, type: 0, total: 250000 },
  engineering: { value: 100000, type: 0, total: 100000 },
  itc_ineligible: { value: 0, type: 0, total: 0 },
  margin: { value: 150000, type: 0, total: 150000 },
  other_fixed: { value: 0, type: 0, total: 0 },
  // Misc Units
  rack_footprint: { value: 0, type: 0, total: 0 },
  boundary_area_per: { value: 0, type: 0, total: 0 },
  contingency: { value: 0, type: 0, total: 0 },
  spacing_gcr: [0.3, 0.45, 0.6],
  spacing_per_wp: [0, 0, 0],

  // footprint_install_cost: 0,
  footprint_op_cost: 0,
  area_install_cost: 0,
  area_op_cost: 0,
  ri_power: 0.06,
  ri_escalation: 1,
  do_include_itc: 0,
  itc_percent: 0,
  do_include_ptc: 0,
  ptc_value: 0.023,
  do_ri_customSchedule: 0,
  ri_customSchedule: [],
  debt_structure: 1,
  debt_percent: 65,
  debt_dscr: 1.3,
  debt_interest: 4,
  debt_tenor: 15,
  dep_5yrSL: 0,
  dep_15yrSL: 0,
  dep_20yrSL: 100,
  dep_30yrSL: 0,
  dep_35yrSL: 0,
  dep_39yrSL: 0,
  dep_5yrMACRS: 0,
  dep_15yrMACRS: 0,
  state_taxes: 0,
  federal_taxes: 20,
  revenue_type: 0,
  tou_grid: [
    [1, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [2, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
    [3, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [4, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [5, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [6, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [7, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [8, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [9, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [10, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
    [11, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [12, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [13, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [14, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [15, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
    [16, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
    [17, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
    [18, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
    [19, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [20, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
    [21, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [22, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [23, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
    [24, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
  ],

  // mutable inputs
  worker_limit: 500,
  recenter: 0,

  selectedFeatureId: undefined,

  edited_layouts: {},
  selected_rack_layout_id: undefined,

  // INTERNAL SIFT SPECIFIC INPUTS
  is_simplified: 0,
  simple_module: 0,
  portfolio_sweep: 0,
  mod_target: 0,
  mwh_target: 0,
  do_continuous: 0,
  res_generated: false,
  changed_input_warning: false,
  cur_hover_id: undefined,
  pan_to_feature_id: undefined,
  hidden_unchecked_features: [],
  horizon_shade_loading: false,
  horizon_data: undefined,
  // quote_id: undefined,

  ...(build == "internal_sift" ? internal_def_inputs : {}),
};

export const get_defaults = () => {
  return { ...JSON.parse(JSON.stringify(default_inputs)) };
};

export const inputsInitialState = {
  env: config.env,
  ...JSON.parse(JSON.stringify(default_inputs)),
  uiState: { ...JSON.parse(JSON.stringify(default_ui_state)) },
};

export const simple_module = {
  do_pvWatts: 1,
  simple_module: 1,
  name: "Simple Module Input",
  mod_name: "Simple Module Input",
  mod_rating: 500,
  bi_bifaciality: 0.7,
  mod_width: 0.992,
  mod_height: 1.658,
  mod_id: "simple_mod",
  default: 0,
  mod_dims_calc: 0,
  // 'mlm_Width': 0.992,
  // 'mlm_Length': 1.658,
  // 'rating': 380,
  // 'mod_id': "simple"
};
export const simple_inverter = {
  inv_id: "simple_inv",
  inv_name: "Simple Inverter Input",
  name: "Simple Inverter Input",
  simple_inverter: 1,
  inv_rating: 1000,
  simple_inverter_dcac: 1.4,
};

export const default_inverter_inputs = {
  simple_inverter: 0,
  inv_rating: 1000,
  inv_name: "Generic 1000kW",
  simple_inverter_dcac: 1.4,
  derate_maxoutput: [1100.0, 1000.0],
  derate_temps: [25.0, 50.0],
  derate_toggle: 0,
  inv_pd_eff_pout: [
    5.0, 10.0, 15.0, 20.0, 25.0, 30.0, 35.0, 40.0, 45.0, 50.0, 77.77778, 105.55556, 133.33333000000002, 161.11111, 188.88889, 216.66667, 244.44444, 272.22222, 300.0, 377.77778, 455.55555999999996,
    533.3333299999999, 611.1111099999999, 688.88889, 766.6666700000001, 844.44444, 922.22222, 1000.0,
  ],
  inv_pd_efficiency: [
    0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16,
    98.92999999999999, 98.7,
  ],
  inv_pd_pacokw: 1100,
  inv_pd_pnt: 0,
  inv_pd_vdcmax: 1000,
  maxEff: 99,
  mppt_hi_inverter: 850,
  mppt_low_inverter: 460,
  inv_pnom: 1000,
  v1_eff: [0],
  v1_pl: [0.0],
  v2_eff: [0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
  v2_pl: [0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
  v3_eff: [0],
  v3_pl: [0.0],
  vs_effeu: [],
  vs_effmax: [],
  vs_vnom: [],
  iv_curves: [],
  pthresh: 0,
  // imax_ac: 2570.0,
  // imax_dc: 4650.0,
  // inv_pd_pdco: 2905760.0,
  //
};

export const default_topo = {
  topo_state: {
    topo_id: undefined,
    boundary_bbox: undefined,
    topo_source: "USGS",
    graded_avail: false,
    grade_target: 0,
    grade_cut_amt: 0,
    grade_fill_amt: 0,
    vis_ele: { ungraded: false, ungraded_percent: 0, ungraded_avail: false, graded: false, graded_percent: 0, graded_avail: false },
    vis_max: { ungraded: false, ungraded_percent: 15, ungraded_avail: false, graded: false, graded_percent: 15, graded_avail: false },
    vis_ns: { ungraded: false, ungraded_percent: 10, ungraded_avail: false, graded: false, graded_percent: 10, graded_avail: false },
    vis_ew: { ungraded: false, ungraded_percent: 20, ungraded_avail: false, graded: false, graded_percent: 20, graded_avail: false },
    topo_layout_surface: "ungraded",
    topo_layout_action: "nothing",
    topo_mode: "off",
    topo_url: "",
    topo_scale_url: "",

    vis_ele_ungraded_avail: false,
    vis_ns_ungraded_percent: 10,
    vis_ns_ungraded_avail: false,
    vis_ew_ungraded_percent: 20,
    vis_ew_ungraded_avail: false,
    vis_max_ungraded_percent: 15,
    vis_max_ungraded_avail: false,

    vis_ele_graded_avail: false,
    vis_ns_graded_percent: 10,
    vis_ns_graded_avail: false,
    vis_ew_graded_percent: 20,
    vis_ew_graded_avail: false,
    vis_max_graded_percent: 15,
    vis_max_graded_avail: false,
  },

  uiState: {
    ele_graded_checked: false,
    max_ungraded_checked: false,
    max_graded_checked: false,
    ns_ungraded_checked: false,
    ns_graded_checked: false,
    ew_ungraded_checked: false,
    ew_graded_checked: false,
  },
};

const stopper_keys = ["data", "weather_summary", "features", "complexity", "map_center"];

export const weather_reset = {
  location: undefined,
  weather_lat: undefined,
  weather_lng: undefined,
  weather_ele: undefined,
  weather_tz: undefined,
  weather_id: undefined,
  weather_summary: undefined,
};

export function get_value(input, type, def) {
  let return_input = def;
  if (input && input.value) {
    if (type === "string") {
      return input.value.toString();
    }

    if ((_.isString(input.value) && _.isEmpty(input.value)) || _.isUndefined(input.value) || _.isNaN(input.value)) {
      return def;
    }

    if (type === "float") {
      return parseFloat(input.value);
    }
    if (type === "int") {
      return parseInt(input.value);
    }
  }

  return return_input;
}
export function flatten(objection, root, go_deeper = true) {
  let inputs = {};
  Object.entries(objection).map((entry) => {
    if (!_.isNull(entry[1])) {
      if (typeof entry[1] == "object" && go_deeper && entry[0] !== "features") {
        let should_i_go_deeper = stopper_keys.findIndex((key) => key == entry[0]) == -1;
        inputs = {
          ...inputs,
          ...flatten(entry[1], entry[0] === "data" ? root : entry[0], should_i_go_deeper),
        };
      } else {
        let _key = entry[0];
        if (entry[0] === "name" || entry[0] === "id") {
          _key = `${root}_${entry[0]}`;
        }
        inputs[_key] = { value: entry[1], root: root, type: typeof entry[1] };
      }
    }
  });
  return inputs;
}

const fix_site_features = (features) => {
  let temp_features = {};
  Object.values(features).map((feature) => {
    // console.log("fix_site_features", features);
    // feature.properties.center = feature.properties.center ? feature.properties.center : getCenterPointofFeature(feature.geometry.coordinates);
    feature.properties.center = getCenterPointofFeature(feature.geometry.coordinates);
    feature.properties.sortIndex = feature.properties.sortIndex ? feature.properties.sortIndex : 1;
    feature.properties.name = feature.properties.name ? feature.properties.name : feature.properties.identity == 1 ? "Boundary1" : feature.properties.identity == 2 ? "Exclusion2" : "Inactive0";
    temp_features[feature.properties.index] = feature;
  });

  return temp_features;
};

export const map_to_1_8 = (inputs, current_inputs) => {
  console.log("mapping project inputs", inputs);

  let fixed_inputs = { ...JSON.parse(JSON.stringify(inputsInitialState)) };
  inputs.json_inputs.offset_type = default_inputs.offset_type;
  if (Object.keys(inputs.json_inputs).length > 0 && inputs.json_inputs.version === "1.8.0") {
    // project is already on new project structure and should be good to go
    console.log("project is most recent version");
    // Just makes sure the site_features object has the correct shape i.e. [feature id] : {feature}.
    if (Object.values(inputs.json_inputs.site_features).length > 0) {
      let fixed_site_features = fix_site_features(inputs.json_inputs.site_features);
      inputs.json_inputs.site_features = fixed_site_features;
    }
    if (Object.keys(inputs.json_inputs.site_features).length > 0) {
      inputs.json_inputs.boundary_bbox = getBounds(inputs.json_inputs.site_features);
      inputs.json_inputs.latitude = inputs.json_inputs.boundary_bbox[1] + Math.abs(inputs.json_inputs.boundary_bbox[3] - inputs.json_inputs.boundary_bbox[1]) / 2;
      inputs.json_inputs.longitude = inputs.json_inputs.boundary_bbox[0] + Math.abs(inputs.json_inputs.boundary_bbox[2] - inputs.json_inputs.boundary_bbox[0]) / 2;
      // TODO: somehow we should try to pull elevation/timezone/site_location
      inputs.json_inputs.map_center = [inputs.json_inputs.latitude, inputs.json_inputs.longitude];
      inputs.json_inputs.recenter += 1;
    }

    inputs.json_inputs.mod_area = inputs.json_inputs.mod_width * inputs.json_inputs.mod_height;

    let inv_pd_eff_pout = inputs.json_inputs.inv_pd_eff_pout;
    let inv_rating = inputs.json_inputs.inv_rating;
    let inv_pnom = inputs.json_inputs.inv_pnom;
    let partload = [];
    for (var i = 0; i < inv_pd_eff_pout.length; i++) partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
    if (partload[partload.length - 1] > 3) {
      partload = [];
      for (var i = 0; i < inv_pd_eff_pout.length; i++) {
        inv_pd_eff_pout[i] = inv_pd_eff_pout[i] / 100;
        partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
      }
    }
    if (partload[partload.length - 1] > 3) {
      inputs.json_inputs.inv_name = default_inputs.inverter_name.value;
      inputs.json_inputs.derate_toggle = default_inputs.derate_toggle.value;
      inputs.json_inputs.derate_temps = default_inputs.derate_temps.value;
      inputs.json_inputs.derate_maxoutput = default_inputs.derate_maxoutput.value;
      inputs.json_inputs.inv_rating = default_inputs.inv_rating;
      inputs.json_inputs.inv_pnom = default_inputs.inv_pnom;
      inputs.json_inputs.mppt_low_inverter = default_inputs.mppt_low_inverter;
      inputs.json_inputs.inv_pd_pacokw = default_inputs.inv_pd_pacokw;
      inputs.json_inputs.mppt_hi_inverter = default_inputs.mppt_hi_inverter;
      inputs.json_inputs.maxEff = default_inputs.maxEff;
      inputs.json_inputs.inv_pd_vdcmax = default_inputs.inv_pd_vdcmax;
      inputs.json_inputs.inv_pd_pnt = default_inputs.inv_pd_pnt;
      inputs.json_inputs.inv_pd_eff_pout = default_inputs.inv_pd_eff_pout;

      inputs.json_inputs.inv_pd_efficiency = default_inputs.inv_pd_efficiency;
      inputs.json_inputs.v1_eff = default_inputs.v1_eff;
      inputs.json_inputs.v1_pl = default_inputs.v1_pl;
      inputs.json_inputs.v2_eff = default_inputs.v2_eff;
      inputs.json_inputs.v2_pl = default_inputs.v2_pl;
      inputs.json_inputs.v3_eff = default_inputs.v3_eff;
      inputs.json_inputs.v3_pl = default_inputs.v3_pl;
      inputs.json_inputs.vs_effeu = default_inputs.vs_effeu;
      inputs.json_inputs.vs_effmax = default_inputs.vs_effmax;
      inputs.json_inputs.vs_vnom = default_inputs.vs_vnom;
    }
    inputs.json_inputs.is_simplified = inputs.json_inputs.is_simplified ? inputs.json_inputs.is_simplified : 0;
    inputs.json_inputs.simple_module = inputs.json_inputs.simple_module ? inputs.json_inputs.simple_module : 0;
    inputs.json_inputs.inverter = inputs.json_inputs.inverter ? inputs.json_inputs.inverter : 1;
    inputs.json_inputs.portfolio_sweep = inputs.json_inputs.portfolio_sweep ? inputs.json_inputs.portfolio_sweep : 1;
    inputs.json_inputs.mod_target = inputs.json_inputs.mod_target ? inputs.json_inputs.mod_target : 0;
    inputs.json_inputs.mwh_target = inputs.json_inputs.mwh_target ? inputs.json_inputs.mwh_target : 0;
    inputs.json_inputs.do_continuous = inputs.json_inputs.do_continuous ? inputs.json_inputs.do_continuous : 0;
    inputs.json_inputs.design_temp = inputs.json_inputs.design_temp ? inputs.json_inputs.design_temp : -10;
    // inputs.json_inputs.uiState.tile_set = inputs.json_inputs.uiState.tile_set ? inputs.json_inputs.uiState.tile_set : default_ui_state.tile_set;
    inputs.json_inputs.uiState.tile_set = default_ui_state.tile_set;
    inputs.json_inputs.uiState.additional_layers = inputs.json_inputs.uiState.additional_layers ? inputs.json_inputs.uiState.additional_layers : default_ui_state.additional_layers;
    inputs.json_inputs.uiState.layout_visible = inputs.json_inputs.uiState.layout_visible ? inputs.json_inputs.uiState.layout_visible : default_ui_state.layout_visible;
    inputs.json_inputs.ac_hv_transformer_loss_at_stc = inputs.json_inputs.ac_hv_transformer_loss_at_stc ? inputs.json_inputs.ac_hv_transformer_loss_at_stc : 0;
    inputs.json_inputs.en_powerfactor = inputs.json_inputs.en_powerfactor ? inputs.json_inputs.en_powerfactor : 0;
    inputs.json_inputs.power_factor = inputs.json_inputs.power_factor ? inputs.json_inputs.power_factor : 0.7;
    inputs.json_inputs.pnom_apparent = inputs.json_inputs.pnom_apparent ? inputs.json_inputs.pnom_apparent : 0;

    // ITC Ineligible patch
    inputs.json_inputs.itc_ineligible = inputs.json_inputs.itc_ineligible ? inputs.json_inputs.itc_ineligible : default_inputs.itc_ineligible;

    // PTC patch
    inputs.json_inputs.ptc_value = inputs.json_inputs.ptc_value ? inputs.json_inputs.ptc_value : 0.023;
    inputs.json_inputs.do_include_ptc = inputs.json_inputs.do_include_ptc ? inputs.json_inputs.do_include_ptc : 0;
    inputs.json_inputs.lease_cost = inputs.json_inputs.lease_cost ? inputs.json_inputs.lease_cost : 0;
    if (inputs.json_inputs.do_ri_customSchedule == 1) {
      let custom_ri_schedule = [];
      for (var i = 0; i < inputs.json_inputs.analysis_period; i++) {
        let year = i + 1;
        let col_$kWh = inputs.json_inputs.ri_customSchedule[i][1];
        let col_$KWac = inputs.json_inputs.ri_customSchedule[i][2] ? inputs.json_inputs.ri_customSchedule[i][2] : 0;
        let col_$ = inputs.json_inputs.ri_customSchedule[i][3] ? inputs.json_inputs.ri_customSchedule[i][3] : 0;
        custom_ri_schedule.push([year, parseFloat(col_$kWh), col_$KWac, col_$]);
      }
      inputs.json_inputs.ri_customSchedule = custom_ri_schedule;
    }

    return inputs.json_inputs;
  }

  if (Object.keys(inputs.old_inputs).length == 0) {
    // literally empty project inputs, just return fixed project
    console.log("empty project inputs -- return default");
    return fixed_inputs;
  } else if (Object.keys(inputs.json_inputs).length == 0 && Object.keys(inputs.old_inputs).length > 0) {
    const { old_inputs } = inputs;
    console.log("mapping pre 1.8 project inputs", old_inputs);

    let flatten_inputs;
    try {
      flatten_inputs = flatten(old_inputs, "root");
      console.log(flatten_inputs);
    } catch (error) {
      console.log("error flattening old inputs", error);
      return fixed_inputs;
    }

    fixed_inputs.site_features = {};
    if (flatten_inputs.features) {
      Object.values(flatten_inputs.features.value).map((feature) => {
        //
        let new_id = uuid();
        // clear out properties so we don't get tainted data
        feature.properties = {
          index: new_id,
          identity: feature.properties.identity || 0,
          center: getCenterPointofFeature(feature.geometry.coordinates),
          active: true,
        };
        //
        fixed_inputs.site_features[new_id] = feature;
      });
      if (Object.keys(fixed_inputs.site_features).length > 0) {
        fixed_inputs.boundary_bbox = getBounds(fixed_inputs.site_features);
        fixed_inputs.latitude = fixed_inputs.boundary_bbox[1] + Math.abs(fixed_inputs.boundary_bbox[3] - fixed_inputs.boundary_bbox[1]) / 2;
        fixed_inputs.longitude = fixed_inputs.boundary_bbox[0] + Math.abs(fixed_inputs.boundary_bbox[2] - fixed_inputs.boundary_bbox[0]) / 2;
        // TODO: somehow we should try to pull elevation/timezone/site_location
        fixed_inputs.map_center = [fixed_inputs.latitude, fixed_inputs.longitude];
        fixed_inputs.recenter += 1;
      }
    }

    if (!_.isUndefined(flatten_inputs.gcr_range)) {
      fixed_inputs.gcr_min = flatten_inputs.gcr_range.value[0] || 0.3;
      fixed_inputs.gcr_max = flatten_inputs.gcr_range.value[1] || 0.4;
      fixed_inputs.gcr_inc = get_value(flatten_inputs.gcr_step, "float", 0.01);
    }

    if (!_.isUndefined(flatten_inputs.pitch_range)) {
      fixed_inputs.pitch_min = parseFloat(flatten_inputs.pitch_range.value[0]);
      fixed_inputs.pitch_max = parseFloat(flatten_inputs.pitch_range.value[1]);
      fixed_inputs.pitch_inc = get_value(flatten_inputs.pitch_step, "float", 1);
    }

    if (!_.isUndefined(flatten_inputs.spi_range)) {
      fixed_inputs.spi_min = flatten_inputs.spi_range.value[0] || 0;
      fixed_inputs.spi_max = flatten_inputs.spi_range.value[1] || 0;
      fixed_inputs.spi_inc = get_value(flatten_inputs.spi_step, "int", 1);
    }

    if (!_.isUndefined(flatten_inputs.selected_gcr_pitch)) {
      fixed_inputs.config_spacing_toggle = flatten_inputs.selected_gcr_pitch.value == 0 ? "gcr" : "pitch";
    }

    fixed_inputs.layout_name = get_value(flatten_inputs.layout_name, "string", "");
    fixed_inputs.sazm = get_value(flatten_inputs.azimuth, "int", 180);
    fixed_inputs.do_inverter_limit_lock = get_value(flatten_inputs.do_inverter_qty_lock, "int", 0);
    fixed_inputs.inverter_limit = get_value(flatten_inputs.inverter_qty, "int", 0);
    fixed_inputs.do_rack_align = get_value(flatten_inputs.do_rack_align, "int", 1);
    fixed_inputs.do_roads = get_value(flatten_inputs.do_roads, "int", 1);
    fixed_inputs.road_spacing_option = get_value(flatten_inputs.road_spacing_option, "int", 2);
    fixed_inputs.road_spacing = get_value(flatten_inputs.road_spacing, "int", 300);
    fixed_inputs.do_inv_spacing = get_value(flatten_inputs.do_inv_spacing, "int", 0);
    fixed_inputs.inverter_per_cut = get_value(flatten_inputs.inverter_per_cut, "int", 1);
    fixed_inputs.do_designer_margin = get_value(flatten_inputs.do_designer_margin, "int", 0);
    fixed_inputs.designer_margin = get_value(flatten_inputs.designer_margin, "float", 0);
    fixed_inputs.epsg = get_value(flatten_inputs.epsg, "int", 0);
    fixed_inputs.do_grid_poi_lim = get_value(flatten_inputs.doGridPower, "int", 0);
    fixed_inputs.ac_grid_poi_lim = get_value(flatten_inputs.grid_power_limit, "int", 0);

    fixed_inputs.mod_name = get_value(flatten_inputs.module_name, "string", "");
    fixed_inputs.mod_rating = get_value(flatten_inputs.rating, "int", 500);
    fixed_inputs.en_bifacial = flatten_inputs.module_isbifacial.value;
    fixed_inputs.bi_bifaciality = flatten_inputs.module_bifaciality.value;
    fixed_inputs.mod_width = get_value(flatten_inputs.mlm_Width, "float", 0);
    fixed_inputs.mod_height = get_value(flatten_inputs.mlm_Length, "float", 0);
    fixed_inputs.mod_area = fixed_inputs.mod_width * fixed_inputs.mod_height;
    // fixed_inputs.mod_pnom = flatten_inputs.pnom.value;
    fixed_inputs.technology = get_value(flatten_inputs.technology, "int", 0);
    fixed_inputs.mlm_S_ref = get_value(flatten_inputs.mlm_S_ref, "float", 0);
    fixed_inputs.mlm_T_ref = get_value(flatten_inputs.mlm_T_ref, "float", 0);
    fixed_inputs.mlm_I_sc_ref = get_value(flatten_inputs.mlm_I_sc_ref, "float", 0);
    fixed_inputs.mlm_V_oc_ref = get_value(flatten_inputs.mlm_V_oc_ref, "float", 0);
    fixed_inputs.mlm_I_mp_ref = get_value(flatten_inputs.mlm_I_mp_ref, "float", 0);
    fixed_inputs.mlm_V_mp_ref = get_value(flatten_inputs.mlm_V_mp_ref, "float", 0);
    fixed_inputs.mlm_alpha_isc = get_value(flatten_inputs.mlm_alpha_isc, "float", 0);
    fixed_inputs.mlm_beta_voc_spec = get_value(flatten_inputs.mlm_beta_voc_spec, "float", 0);
    fixed_inputs.mlm_N_series = get_value(flatten_inputs.mlm_N_series, "float", 0);
    fixed_inputs.mlm_N_parallel = get_value(flatten_inputs.mlm_N_parallel, "float", 0);
    fixed_inputs.mlm_R_shref = get_value(flatten_inputs.mlm_R_shref, "float", 0);
    fixed_inputs.mlm_R_sh0 = get_value(flatten_inputs.mlm_R_sh0, "float", 0);
    fixed_inputs.mlm_R_s = get_value(flatten_inputs.mlm_R_s, "float", 0);
    fixed_inputs.mlm_R_shexp = get_value(flatten_inputs.mlm_R_shexp, "float", 0);
    fixed_inputs.mlm_T_c_fa_alpha = get_value(flatten_inputs.mlm_T_c_fa_alpha, "float", 0);
    fixed_inputs.muPmpReq = get_value(flatten_inputs.muPmpReq, "float", 0);
    fixed_inputs.mlm_n_0 = get_value(flatten_inputs.mlm_n_0, "float", 0);
    fixed_inputs.mlm_mu_n = get_value(flatten_inputs.mlm_mu_n, "float", 0);
    fixed_inputs.mlm_D2MuTau = get_value(flatten_inputs.mlm_D2MuTau, "float", 0);
    fixed_inputs.mlm_E_g = get_value(flatten_inputs.mlm_E_g, "float", 0);
    fixed_inputs.mlm_N_diodes = get_value(flatten_inputs.mlm_N_diodes, "float", 0);
    fixed_inputs.module_iam_ang = flatten_inputs.module_iam_ang.value;
    fixed_inputs.module_iam_eff = flatten_inputs.module_iam_eff.value;

    let inv_pd_eff_pout = flatten_inputs.inv_pd_eff_pout.value;
    let inv_rating = get_value(flatten_inputs.inverterRating, "float", 0);
    let inv_pnom = get_value(flatten_inputs.pnom, "float", inv_rating);
    let partload = [];
    for (var i = 0; i < inv_pd_eff_pout.length; i++) partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
    if (partload[partload.length - 1] > 3) {
      partload = [];
      for (var i = 0; i < inv_pd_eff_pout.length; i++) {
        inv_pd_eff_pout[i] = inv_pd_eff_pout[i] / 100;
        partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
      }
    }

    if (partload[partload.length - 1] <= 3) {
      fixed_inputs.inv_name = flatten_inputs.inverter_name.value;
      fixed_inputs.derate_toggle = flatten_inputs.derate_toggle.value;
      fixed_inputs.derate_temps = flatten_inputs.derate_temps.value;
      fixed_inputs.derate_maxoutput = flatten_inputs.derate_maxoutput.value;
      fixed_inputs.inv_rating = inv_rating;
      fixed_inputs.inv_pnom = inv_pnom;
      fixed_inputs.mppt_low_inverter = get_value(flatten_inputs.mppt_low_inverter, "float", 0);
      fixed_inputs.inv_pd_pacokw = get_value(flatten_inputs.inv_pd_pacokw, "float", 0);
      fixed_inputs.mppt_hi_inverter = get_value(flatten_inputs.mppt_hi_inverter, "float", 0);
      fixed_inputs.maxEff = get_value(flatten_inputs.maxEff, "float", 0);
      fixed_inputs.inv_pd_vdcmax = get_value(flatten_inputs.inv_pd_vdcmax, "float", 0);
      fixed_inputs.inv_pd_pnt = get_value(flatten_inputs.inv_pd_pnt, "float", 0);
      fixed_inputs.inv_pd_eff_pout = inv_pd_eff_pout;

      fixed_inputs.inv_pd_efficiency = flatten_inputs.inv_pd_efficiency.value;
      fixed_inputs.v1_eff = flatten_inputs.v1_eff.value;
      fixed_inputs.v1_pl = flatten_inputs.v1_pl.value;
      fixed_inputs.v2_eff = flatten_inputs.v2_eff.value;
      fixed_inputs.v2_pl = flatten_inputs.v2_pl.value;
      fixed_inputs.v3_eff = flatten_inputs.v3_eff.value;
      fixed_inputs.v3_pl = flatten_inputs.v3_pl.value;
      fixed_inputs.vs_effeu = flatten_inputs.vs_effeu.value;
      fixed_inputs.vs_effmax = flatten_inputs.vs_effmax.value;
      fixed_inputs.vs_vnom = flatten_inputs.vs_vnom.value;
    } else {
      fixed_inputs.warnings.push("Stored inverter data was incompatible with the current performance model. Please select a new inverter, or reimport from OND.");
    }

    // map over required racking inputs
    fixed_inputs.racking_name = flatten_inputs.racking_name.value;
    fixed_inputs.rack_dims = get_value(flatten_inputs.rack_dims, "int", 0);
    fixed_inputs.mod_per_string = get_value(flatten_inputs.modules_per_string, "int", 26);
    fixed_inputs.modules_high = get_value(flatten_inputs.modules_high, "int", 1);
    fixed_inputs.orientation = get_value(flatten_inputs.orientation, "int", 0);

    fixed_inputs.track_mode = flatten_inputs.type.value;

    if (flatten_inputs.racks && flatten_inputs.racks.value) {
      Object.values(flatten_inputs.racks.value).map((rack, index) => {
        fixed_inputs.racks[index].key = rack.key;
        fixed_inputs.racks[index].active = rack.active;
        fixed_inputs.racks[index].xdim = parseFloat(rack.xdim);
        fixed_inputs.racks[index].ydim = parseFloat(rack.ydim);
        fixed_inputs.racks[index].module = parseInt(rack.module);
        fixed_inputs.racks[index].string_count = _.isUndefined(rack.string_count) ? rack.module / fixed_inputs.mod_per_string : rack.string_count;
      });
    }

    fixed_inputs.module_gap = get_value(flatten_inputs.module_gap, "float", 0.02);
    fixed_inputs.drive_gap = get_value(flatten_inputs.drive_gap, "float", 2);
    fixed_inputs.tilt_min = get_value(flatten_inputs.tilt_min, "float", 10);
    fixed_inputs.tilt_max = get_value(flatten_inputs.tilt_max, "float", 30);
    fixed_inputs.tilt_inc = get_value(flatten_inputs.tilt_inc, "float", 5);
    fixed_inputs.rack_grade_limit = get_value(flatten_inputs.grade_limit, "float", 10);
    fixed_inputs.gap = get_value(flatten_inputs.gap, "float", 2);
    fixed_inputs.bi_groundClearanceHeight = get_value(flatten_inputs.module_clearance_height, "float", 0.5);
    fixed_inputs.bi_transmissionFactor = get_value(flatten_inputs.module_transmission_perc, "float", 0);
    fixed_inputs.bi_structureShadeFactor = get_value(flatten_inputs.module_structure_shade_perc, "float", 0);
    fixed_inputs.shade_mode = get_value(flatten_inputs.shade_mode, "int", 1);
    fixed_inputs.Fshd_CollWidth = fixed_inputs.track_mode == 0 ? flatten_inputs.racks.value[0].ydim : flatten_inputs.racks.value[0].xdim;
    fixed_inputs.Fshd_StringSteps = get_value(flatten_inputs.string_steps, "int", 1);
    fixed_inputs.Fshd_Celltrav = get_value(flatten_inputs.cell_trav, "float", 0.156);
    fixed_inputs.en_backtrack = get_value(flatten_inputs.backtrack, "int", 1);
    fixed_inputs.rlim = get_value(flatten_inputs.track_angle, "int", 60);
    // fixed_inputs.do_dualTilt = flatten_inputs.do_dualTilt.value;

    // map over weather inputs
    if (flatten_inputs.weather_id && flatten_inputs.weather_id.value != 0 && flatten_inputs.weather_summary && flatten_inputs.weather_summary.value) {
      // Weather ERRORS??
      // The weather file lat/lng are x deg away from site -- might see unusual performance
      // We were unable to parse the weather file lat/lng -- will use the site lat/lng -or- manual override
      // Weather summary column (y) is wildly off what would be expected from this location -- might see unusual performance
      // When we import a custom weather file, why don't we use the lat/lng to ping NASA just to compare?

      fixed_inputs.weather_id = flatten_inputs.weather_id.value;
      fixed_inputs.weather_name = flatten_inputs.weather_name.value;
      // fixed_inputs.search_lat = flatten_inputs.search_lat.value;
      // fixed_inputs.search_lng = flatten_inputs.search_lng.value;
      fixed_inputs.elevation = get_value(flatten_inputs.search_ele, "float", 0);
      fixed_inputs.timezone = get_value(flatten_inputs.search_tz, "int", 0);
      fixed_inputs.weather_source = get_value(flatten_inputs.source, "int", 0);

      if (flatten_inputs.weather_summary.value) {
        // fixed_inputs.weather_source = flatten_inputs.weather_summary.value.Source;
        fixed_inputs.weather_lat = flatten_inputs.weather_summary.value.Latitude;
        fixed_inputs.weather_lng = flatten_inputs.weather_summary.value.Longitude;
        fixed_inputs.weather_ele = flatten_inputs.weather_summary.value.Elevation;
        fixed_inputs.weather_tz = flatten_inputs.weather_summary.value.Timezone;
        fixed_inputs.locationId = flatten_inputs.weather_summary.value.name;
        fixed_inputs.weather_summary = flatten_inputs.weather_summary.value.Summary;
        // fixed_inputs.county = flatten_inputs.weather_summary.value.county.value;
        // fixed_inputs.state = flatten_inputs.weather_summary.value.state.value;
      }
    }

    // map over performance inputs
    fixed_inputs.perf_name = flatten_inputs.performance_name.value;
    fixed_inputs.dc_degrade = get_value(flatten_inputs.dc_degradation, "float", 0);
    fixed_inputs.dc_thermal_Uc = get_value(flatten_inputs.thermal_uc, "float", 0);
    fixed_inputs.dc_thermal_Uv = get_value(flatten_inputs.thermal_uv, "float", 0);
    fixed_inputs.dc_module_quality_loss = get_value(flatten_inputs.quality_loss, "float", 0);
    fixed_inputs.dc_module_lid_loss = get_value(flatten_inputs.lid_loss, "float", 0);
    fixed_inputs.dc_module_mismatch = get_value(flatten_inputs.mismatch_loss, "float", 0);
    // fixed_inputs.dc_strings_mismatch = flatten_inputs.dc_strings_mismatch.value;
    fixed_inputs.dc_strings_mismatch = 0;
    fixed_inputs.dc_wiring_loss_at_stc = get_value(flatten_inputs.dc_loss_stc, "float", 0);
    fixed_inputs.bi_back_mismatch = 10;
    fixed_inputs.en_powerfactor = 0;
    fixed_inputs.power_factor = 0.7;
    fixed_inputs.pnom_apparent = 0;
    fixed_inputs.ac_aux_kw = 0;
    fixed_inputs.ac_wiring_loss_at_stc = get_value(flatten_inputs.ac_losses, "float", 0);
    fixed_inputs.ac_transformer_loss_constant = 0;
    fixed_inputs.ac_transformer_loss_at_stc = 0;
    fixed_inputs.ac_MV_line_loss_stc = 0;
    fixed_inputs.ac_hv_transformer_loss_at_stc = 0;
    fixed_inputs.ac_transmission_loss = 0;
    fixed_inputs.ac_other_loss = 0;
    fixed_inputs.albedo_single = get_value(flatten_inputs.albedo, "float", 0);
    fixed_inputs.soiling_single = get_value(flatten_inputs.soiling, "float", 0);
    fixed_inputs.do_monthly_losses = flatten_inputs.doMonthlySoiling.value;
    if (flatten_inputs.albedo_monthly && flatten_inputs.albedo_monthly.value) {
      fixed_inputs.albedo = flatten_inputs.albedo_monthly.value;
    }
    if (flatten_inputs.soiling_monthly && flatten_inputs.soiling_monthly.value) {
      fixed_inputs.soiling = flatten_inputs.soiling_monthly.value;
    }

    fixed_inputs.topo_id = get_value(flatten_inputs.topo_id, "string", undefined);
    if (fixed_inputs.topo_id == "topo") {
      fixed_inputs.topo_id = undefined;
    }
    fixed_inputs.topo_layout_action = get_value(flatten_inputs.topo_action, "string", "none");

    if (flatten_inputs.layers_generated && flatten_inputs.layers_generated.value != undefined) {
      fixed_inputs.vis_ele_ungraded_avail = fixed_inputs.topo_id !== undefined;
      fixed_inputs.vis_ns_ungraded_percent = parseInt(flatten_inputs.layers_generated.value["NS"]["limit"]);
      fixed_inputs.vis_ns_ungraded_avail = fixed_inputs.topo_id !== undefined;
      fixed_inputs.vis_ew_ungraded_percent = parseInt(flatten_inputs.layers_generated.value["EW"]["limit"]);
      fixed_inputs.vis_ew_ungraded_avail = fixed_inputs.topo_id !== undefined;
      fixed_inputs.vis_max_ungraded_percent = parseInt(flatten_inputs.layers_generated.value["U"]["limit"]);
      fixed_inputs.vis_max_ungraded_avail = fixed_inputs.topo_id !== undefined;

      // fixed_inputs.vis_ele = {
      // 	...fixed_inputs.vis_ele,
      // 	ungraded_avail: flatten_inputs.layers_generated.value['ele']['avail'] || false,
      // },
      // fixed_inputs.vis_max = {
      // 	...fixed_inputs.vis_max,
      // 	ungraded_percent: parseInt(flatten_inputs.layers_generated.value['U']['limit']),
      // 	ungraded_avail: flatten_inputs.layers_generated.value['U']['avail'] || false,
      // },
      // fixed_inputs.vis_ns = {
      // 	...fixed_inputs.vis_ns,
      // 	ungraded_percent: parseInt(flatten_inputs.layers_generated.value['NS']['limit']),
      // 	ungraded_avail: flatten_inputs.layers_generated.value['NS']['avail'] || false,
      // },
      // fixed_inputs.vis_ew = {
      // 	...fixed_inputs.vis_ew,
      // 	ungraded_percent: parseInt(flatten_inputs.layers_generated.value['EW']['limit']),
      // 	ungraded_avail: flatten_inputs.layers_generated.value['EW']['avail'] || false,
      // }
    } else {
      if (flatten_inputs.ns_grade_limit) {
        fixed_inputs.vis_ns_ungraded_percent = parseInt(flatten_inputs.ns_grade_limit.value);
      }
      if (flatten_inputs.ew_grade_limit) {
        fixed_inputs.vis_ew_ungraded_percent = parseInt(flatten_inputs.ew_grade_limit.value);
      }

      if (flatten_inputs.u_grade_limit) {
        fixed_inputs.vis_max_ungraded_percent = parseInt(flatten_inputs.u_grade_limit.value);
      }
    }

    // map over finance
    if (!_.isUndefined(flatten_inputs.do_finance)) {
      if (flatten_inputs.do_finance.type == "number") {
        fixed_inputs.do_finance = flatten_inputs.do_finance.value;
      }
      if (flatten_inputs.do_finance.type == "boolean") {
        fixed_inputs.do_finance = flatten_inputs.do_finance.value ? 1 : 0;
      }
    }

    if (fixed_inputs.do_finance == 1) {
      fixed_inputs.finance_name = get_value(flatten_inputs.finance_name, "string", "");
      fixed_inputs.metric = get_value(flatten_inputs.metric, "int", 0);
      fixed_inputs.analysis_period = get_value(flatten_inputs.analysis_period, "int", 20);
      fixed_inputs.discount_rate = get_value(flatten_inputs.discount_rate, "float", 8);
      fixed_inputs.dc_op_cost = get_value(flatten_inputs.dc_op_cost, "float", 10);
      fixed_inputs.ac_op_cost = get_value(flatten_inputs.ac_op_cost, "float", 0);
      fixed_inputs.fixed_op_cost = get_value(flatten_inputs.fixed_op_cost, "float", 20000);
      fixed_inputs.lease_cost = get_value(flatten_inputs.lease_cost, "float", 0);
      fixed_inputs.escalation = get_value(flatten_inputs.escalation, "float", 2);
      fixed_inputs.do_custom_schedule = get_value(flatten_inputs.do_custom_schedule, "int", 0);

      if (flatten_inputs.customSchedule) {
        fixed_inputs.customSchedule = flatten_inputs.customSchedule.value;
      }

      fixed_inputs.toggle_finance_type = get_value(flatten_inputs.toggle_finance_type, "int", 0);

      let summed_dc = get_value(flatten_inputs.summarized_dc, "float", 0);
      let summed_ac = get_value(flatten_inputs.summarized_ac, "float", 0);
      let summed_fixed = get_value(flatten_inputs.fixed_cost, "float", 0);
      if (_.isUndefined(flatten_inputs.summarized_dc) && !_.isUndefined(flatten_inputs.module_cost)) {
        // probably old project structure
        summed_dc = get_value(flatten_inputs.module_cost, "float", 0);
        if (!_.isUndefined(flatten_inputs.other_dc_cost)) {
          summed_dc += get_value(flatten_inputs.other_dc_cost, "float", 0);
        }
      }
      if (_.isUndefined(flatten_inputs.summarized_ac) && !_.isUndefined(flatten_inputs.ac_cost)) {
        summed_ac = get_value(flatten_inputs.ac_cost, "float", 0);
      }
      if (!_.isUndefined(flatten_inputs.footprint_install_cost)) {
        summed_fixed += get_value(flatten_inputs.footprint_install_cost, "float", 0);
      }
      // summarized data
      fixed_inputs.summarized_dc = summed_dc;
      fixed_inputs.summarized_ac = summed_ac;
      fixed_inputs.fixed_cost = summed_fixed;
      fixed_inputs.footprint_op_cost = get_value(flatten_inputs.footprint_op_cost, "float", 0);

      // D/C Units
      if (!_.isUndefined(flatten_inputs.module_dc_cost)) {
        fixed_inputs.module_dc_cost = flatten_inputs.module_dc_cost.value;
      }
      if (!_.isUndefined(flatten_inputs.rack_a_finance)) {
        fixed_inputs.rack_a_finance = flatten_inputs.rack_a_finance.value;
      }
      if (!_.isUndefined(flatten_inputs.rack_b_finance)) {
        fixed_inputs.rack_b_finance = flatten_inputs.rack_b_finance.value;
      }
      if (!_.isUndefined(flatten_inputs.rack_c_finance)) {
        fixed_inputs.rack_c_finance = flatten_inputs.rack_c_finance.value;
      }
      if (!_.isUndefined(flatten_inputs.bos_other)) {
        fixed_inputs.bos_other = flatten_inputs.bos_other.value;
      }

      // fixed units
      if (!_.isUndefined(flatten_inputs.interconnection)) {
        fixed_inputs.interconnection = flatten_inputs.interconnection.value;
      }
      if (!_.isUndefined(flatten_inputs.permits_fees)) {
        fixed_inputs.permits_fees = flatten_inputs.permits_fees.value;
      }
      if (!_.isUndefined(flatten_inputs.engineering)) {
        fixed_inputs.engineering = flatten_inputs.engineering.value;
      }
      if (!_.isUndefined(flatten_inputs.margin)) {
        fixed_inputs.margin = flatten_inputs.margin.value;
      }
      if (!_.isUndefined(flatten_inputs.itc_ineligible)) {
        fixed_inputs.itc_ineligible = flatten_inputs.itc_ineligible.value;
      }
      if (!_.isUndefined(flatten_inputs.other_fixed)) {
        fixed_inputs.other_fixed = flatten_inputs.other_fixed.value;
      }

      // A/C Units
      if (!_.isUndefined(flatten_inputs.inverter)) {
        fixed_inputs.inverter = flatten_inputs.inverter.value;
      }
      if (!_.isUndefined(flatten_inputs.ac_aux)) {
        fixed_inputs.ac_aux = flatten_inputs.ac_aux.value;
      }
      if (!_.isUndefined(flatten_inputs.mv_wire)) {
        fixed_inputs.mv_wire = flatten_inputs.mv_wire.value;
      }
      if (!_.isUndefined(flatten_inputs.other_ac)) {
        fixed_inputs.other_ac = flatten_inputs.other_ac.value;
      }

      // Misc Units
      if (!_.isUndefined(flatten_inputs.rack_footprint)) {
        fixed_inputs.rack_footprint = flatten_inputs.rack_footprint.value;
      }
      if (!_.isUndefined(flatten_inputs.boundary_area)) {
        fixed_inputs.boundary_area_per = flatten_inputs.boundary_area.value;
      }
      if (!_.isUndefined(flatten_inputs.contingency)) {
        fixed_inputs.contingency = flatten_inputs.contingency.value;
      }
      if (!_.isUndefined(flatten_inputs.spacing_gcr)) {
        fixed_inputs.spacing_gcr = flatten_inputs.spacing_gcr.value;
      }
      if (!_.isUndefined(flatten_inputs.spacing_per_wp)) {
        fixed_inputs.spacing_per_wp = flatten_inputs.spacing_per_wp.value;
      }
    }

    // console.log(fixed_inputs);
    return fixed_inputs;

    if (old_inputs.doRoads != undefined) {
      fixed_inputs.do_roads = old_inputs.doRoads ? 1 : 0;
      fixed_inputs.road_spacing_option = fixed_inputs.do_roads;
    }
    if (old_inputs.doRoads != undefined) {
      fixed_inputs.do_rack_align = old_inputs.doAlign ? 1 : 0;
    }
    if (old_inputs.selectedProductIndex != undefined) {
      // use Math.max here to make sure at least the 0 index is selected for Portal
      fixed_inputs.uiState.selected_product_index = Math.min(Math.max(old_inputs.selectedProductIndex, 0), defaultProducts.length - 1);
      fixed_inputs = {
        ...fixed_inputs,
        ...JSON.parse(JSON.stringify(defaultProducts[fixed_inputs.uiState.selected_product_index])),
      };
    }
    if (old_inputs.selectedModuleIndex != undefined) {
      // use Math.max here to make sure at least the 0 index is selected for Portal
      fixed_inputs.uiState.selected_module_index = Math.min(Math.max(old_inputs.selectedModuleIndex, 0), defaultModules.length - 1);
      fixed_inputs = {
        ...fixed_inputs,
        ...JSON.parse(JSON.stringify(defaultModules[fixed_inputs.uiState.selected_module_index])),
      };
      if (fixed_inputs.uiState.selected_module_index > 0) {
        fixed_inputs.simple_module = 0;
      }
    }
    if (old_inputs.tab != undefined) {
      fixed_inputs.uiState.tab = old_inputs.tab;
    }

    // if old_inputs['doOverrideTilt']:
    if (old_inputs.doOverrideTilt != undefined && old_inputs.OverrideTilt != undefined) {
      fixed_inputs.tilt_min = old_inputs.OverrideTilt[0];
      fixed_inputs.tilt_max = old_inputs.OverrideTilt[1];
    }
    // if old_inputs['doOverrideGCR']:
    if (old_inputs.doOverrideGCR != undefined && old_inputs.OverrideGCR != undefined) {
      fixed_inputs.gcr_min = old_inputs.OverrideGCR[0];
      fixed_inputs.gcr_max = old_inputs.OverrideGCR[1];
    }

    if (old_inputs.doOverrideDims != undefined) {
      fixed_inputs.uiState.do_dimension_override = old_inputs.doOverrideDims;
    }
    if (old_inputs.do_continuous != undefined) {
      fixed_inputs.continuous = old_inputs.do_continuous ? 1 : 0;
    }
    if (old_inputs.do_dc_lock != undefined) {
      fixed_inputs.uiState.do_module_target = old_inputs.do_dc_lock;
    }
    if (old_inputs.dc_lock_value != undefined) {
      fixed_inputs.dc_lock_value = parseInt(old_inputs.dc_lock_value);
    }
    if (old_inputs.mod_string != undefined) {
      fixed_inputs.mod_per_string = parseInt(old_inputs.mod_string);
    }

    if (old_inputs.is_bifacial != undefined) {
      fixed_inputs.en_bifacial = old_inputs.is_bifacial;
    }
    if (old_inputs.bifaciality != undefined) {
      fixed_inputs.bi_bifaciality = parseFloat(old_inputs.bifaciality);
    }
    if (old_inputs.bifacial_transmission_factor != undefined) {
      fixed_inputs.bi_transmissionFactor = old_inputs.bifacial_transmission_factor;
    }
    if (old_inputs.bifacial_ground_clearance_height != undefined) {
      fixed_inputs.bi_groundClearanceHeight = old_inputs.bifacial_ground_clearance_height;
    }
    if (old_inputs.bifacial_structure_shade_factor != undefined) {
      fixed_inputs.bi_structureShadeFactor = old_inputs.bifacial_structure_shade_factor;
    }

    if (old_inputs.layout) {
      if (old_inputs.layout.do_wiring != undefined) {
        fixed_inputs.do_wiring = old_inputs.layout.do_wiring;
      }
      if (old_inputs.layout.wiring_inv_rating != undefined) {
        fixed_inputs.wiring_inv_rating = parseInt(old_inputs.layout.wiring_inv_rating);
      }
      if (old_inputs.layout.wiring_spi != undefined) {
        fixed_inputs.wiring_spi = parseInt(old_inputs.layout.wiring_spi);
      }
      if (old_inputs.layout.do_rack_align != undefined) {
        fixed_inputs.do_rack_align = old_inputs.layout.do_rack_align;
      }
      if (old_inputs.layout.do_roads != undefined) {
        fixed_inputs.do_roads = old_inputs.layout.do_roads;
      }
      if (old_inputs.layout.road_spacing != undefined) {
        fixed_inputs.road_spacing = parseInt(old_inputs.layout.road_spacing);
      }
      if (old_inputs.layout.road_spacing_option != undefined) {
        fixed_inputs.road_spacing_option = old_inputs.layout.road_spacing_option;
      }
      if (old_inputs.layout.inverter_grouping != undefined) {
        fixed_inputs.inverter_grouping = old_inputs.layout.inverter_grouping;
      }
      if (old_inputs.layout.combiner_location != undefined) {
        fixed_inputs.combiner_location = old_inputs.layout.combiner_location;
      }
      if (old_inputs.layout.strings_per_combiner != undefined) {
        fixed_inputs.strings_per_combiner = parseInt(old_inputs.layout.strings_per_combiner);
      }
      if (old_inputs.layout.wire_stringing != undefined) {
        fixed_inputs.wire_stringing = old_inputs.layout.wire_stringing;
      }
    }

    if (old_inputs.topo_id != undefined) {
      fixed_inputs.topo_id = old_inputs.topo_id;
    }
    if (old_inputs.topo_action != undefined) {
      fixed_inputs.topo_layout_action = old_inputs.topo_action;
    }
    if (old_inputs.grading_enabled != undefined) {
      fixed_inputs.graded_avail = old_inputs.grading_enabled;
    }
    if (old_inputs.grade_target != undefined) {
      fixed_inputs.grade_target = parseInt(old_inputs.grade_target);
    }
    if (old_inputs.grade_limit != undefined) {
      fixed_inputs.rack_grade_limit = parseInt(old_inputs.grade_limit);
    }
    if (old_inputs.cut_amt != undefined) {
      fixed_inputs.grade_cut_amt = old_inputs.cut_amt;
    }
    if (old_inputs.fill_amt != undefined) {
      fixed_inputs.grade_fill_amt = old_inputs.fill_amt;
    }
    // if old_inputs['doTopo']:
    if (old_inputs.layers_generated != undefined) {
      (fixed_inputs.vis_ele = {
        ...fixed_inputs.vis_ele,
        ungraded_avail: old_inputs.layers_generated["ele"]["avail"] || false,
        graded_avail: old_inputs.layers_generated["ele"]["gavail"] || false,
      }),
        (fixed_inputs.vis_max = {
          ...fixed_inputs.vis_max,
          ungraded_percent: parseInt(old_inputs.layers_generated["U"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["U"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["U"]["limit"]),
          graded_avail: old_inputs.layers_generated["U"]["gavail"] || false,
        }),
        (fixed_inputs.vis_ns = {
          ...fixed_inputs.vis_ns,
          ungraded_percent: parseInt(old_inputs.layers_generated["NS"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["NS"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["NS"]["limit"]),
          graded_avail: old_inputs.layers_generated["NS"]["gavail"] || false,
        }),
        (fixed_inputs.vis_ew = {
          ...fixed_inputs.vis_ew,
          ungraded_percent: parseInt(old_inputs.layers_generated["EW"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["EW"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["EW"]["limit"]),
          graded_avail: old_inputs.layers_generated["EW"]["gavail"] || false,
        });
      fixed_inputs.vis_g = {
        ...fixed_inputs.vis_g,
        ungraded_percent: parseInt(old_inputs.grade_target),
        ungraded_avail: old_inputs.grading_enabled || false,
        graded_percent: parseInt(old_inputs.grade_target),
        graded_avail: old_inputs.grading_enabled || false,
      };
    }

    if (fixed_inputs.track_mode == 0) {
      fixed_inputs.racks.forEach((rack, index) => {
        rack.module = parseInt(rack.module);

        let mod_count = rack.module;
        if (fixed_inputs.string_counts.length == 0) {
          // GLIDE products are LIMITED to 24 modules no matter what
          mod_count = 24;
        } else {
          mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
        }

        let Rack_XY = Standard_GFT(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.mods_high, fixed_inputs.orientation, fixed_inputs.vert_gap, fixed_inputs.hori_gap);
        rack.module = mod_count;
        rack.xdim = Rack_XY[0];
        rack.ydim = Rack_XY[1];
      });
    } else {
      fixed_inputs.racks.forEach((rack, index) => {
        rack.module = parseInt(rack.module);

        let Tracker_XY = Terratrak_2P_SAT(fixed_inputs.mod_width, fixed_inputs.mod_height, rack.module, fixed_inputs.en_bifacial == 1);
        rack.xdim = Tracker_XY[0];
        rack.ydim = Tracker_XY[1];
      });
    }

    return fixed_project;
  }

  console.log("inputs key counts", Object.keys(inputs.json_inputs).length, Object.keys(inputs.old_inputs).length);
  console.log("unable to map project inputs -- return default");
  return fixed_inputs;
};
