//
import "./leaflet.bing";

// BELOW SUPPORTS REACT-LEAFLET V2
// import {GridLayer, withLeaflet} from 'react-leaflet';
// class BingLayer extends GridLayer {

//     createLeafletElement(props) {
//       return new L.bingLayer(props.bingkey, props);
//     }
// }

// export default withLeaflet(BingLayer);

const BingLayer = () => {
  return <></>;
};

export default BingLayer;

// import { createLayerComponent } from '@react-leaflet/core';
// import {bingLayer} from './leaflet.bing';

// const createLeafletElement = (props) => {

//     const instance = L.bingLayer(props.bingkey, props);

//     return { instance };
//   }

// export default createLayerComponent(createLeafletElement);
