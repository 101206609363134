import React, { useState, useEffect } from "react";
import { LayerGroup, Polygon, GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";

const WiringKeys = ["inverter", "racks"];

const LayoutLayer = ({ show_racks = true, show_inverters = true }) => {
  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  const layer_toggle = useSelector((state) => state.sift.ioManager.uiState.layer_toggle);
  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);

  if (!layout_visible) return <></>;

  return (
    <LayerGroup key="groups">
      {Object.values(layout).length > 0 &&
        Object.values(layout.inverter_groups).map((group, group_index) => {
          let group_color = group?.wires?.properties?.color;
          return Object.keys(group).map((key, index) => {
            if (!WiringKeys.includes(key)) {
              return;
            }
            if ((key == "racks" && !show_racks) || (key == "inverter" && !show_inverters)) return;
            let indx = 0;
            // console.log(key, index, group[key]);
            // group[key].color = '#33A2FF'
            if (key == "inverter" && show_inverters) {
              group[key].color = "#ee00ff";
              group[key].fillColor = "none";
              group[key].weight = 2;
              indx = 597;
            }
            if (key == "combiner_boxes") {
              if (!layer_toggle.show_combiners) return <></>;
              group[key].color = "#7FFF00";
              group[key].fillColor = "#7FFF00";
              group[key].weight = 4;
            }
            if (key == "racks" && show_racks) {
              group[key].color = group_color; //33A2FF
              group[key].fillColor = "none";
              group[key].weight = 2;
              indx = -599;
            }
            if (key == "wires") {
              if (!layer_toggle.show_wires) return <></>;
              // return
              group[key].color = group[key].properties.color;
              group[key].fillColor = "none";
              group[key].weight = 0.5;

              // #33A2FF
              // #5377ad
              // #33A2FF
              // #33A2FF
              // #33A2FF
              // 131 - #AAFFFF
              // 141 - #AAEAFF
              // 151 - #AAD4FF
              // 161 - #AABFFF
              // 171 - #AAAAFF
              // 181 - #BFAAFF
            }
            if (key == "strings") {
              if (!layer_toggle.show_strings) return <></>;
              group[key].color = group[key].properties.color;
              group[key].fillColor = "none";
              group[key].weight = 0.5;
            }

            return <Polygon positions={group[key]?.geometry?.coordinates} key={index} color={group[key]?.color} fillColor={group[key]?.fillColor} id={indx} weight={group[key]?.weight}></Polygon>;
          });
        })}
    </LayerGroup>
  );
};

export { LayoutLayer };
