import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { CarouselContainer, ArrowDownContainer } from "./styles";

const ArrowDown = () => {
  return (
    <ArrowDownContainer>
      <svg x="0px" y="0px" width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847">
        <g>
          <path
            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
          />
        </g>
      </svg>
    </ArrowDownContainer>
  );
};

const useMultipleRefs = (n) => {
  const refs = useRef(
    Array(n)
      .fill(1)
      .reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, [])
  );

  return refs.current;
};

const Carousel = ({ items, numberOfFrames = 3 }) => {
  const items_length = items?.length || numberOfFrames;

  const [currentIndex, setCurrentIndex] = useState(0);

  let refs = useMultipleRefs(items_length);

  const handleClickPrev = () => {
    const prevItemIndex = (currentIndex + items_length - 1) % items_length;

    refs[prevItemIndex].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    setCurrentIndex(prevItemIndex);
  };

  const handleClickNext = () => {
    const nextItemIndex = (currentIndex + 1) % items_length;

    refs[nextItemIndex].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    setCurrentIndex(nextItemIndex);
    // console.log("items", items[currentIndex]);
  };

  const handleClickIndicator = (index) => {
    refs[index].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    setCurrentIndex(index);
    // console.log("items", items[currentIndex]);
  };

  return (
    <CarouselContainer type={items[currentIndex].type}>
      <section className="controls-container">
        <section onClick={handleClickPrev} className="control prev-control">
          <ArrowDown />
        </section>
        <section onClick={handleClickNext} className="control next-control">
          <ArrowDown />
        </section>
      </section>

      <section className="indicators-container">
        {Array(items_length)
          .fill(1)
          .map((_, i) => (
            <span key={i} className={`indicator ${currentIndex === i && "active"}`} onClick={() => handleClickIndicator(i)}></span>
          ))}
      </section>

      <section className="carousel-viewport scrollbar-hidden">
        {Array(items_length)
          .fill(1)
          .map((_, i) => (
            <section key={i} ref={refs[i]} className="carousel-slide">
              {items[i].text && (
                <section className="carousel-text">
                  <p>{`${items[i].text}`}</p>
                </section>
              )}
              {items[i].ele}
              {/* <section className="carousel-snap" /> */}
            </section>
          ))}
      </section>
    </CarouselContainer>
  );
};

export { Carousel };
