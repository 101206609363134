import styled from "styled-components";

const LayerMenuWrap = styled.section`
  height: 205px;
  width: 255px;

  .layer-select,
  .additional-layers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;

    .toggle-layer-button {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 5px;
      padding: 0px 5px;

      label {
        margin-right: 10px;
      }
    }
  }

  .ant-radio-group,
  .ant-radio-group-outline {
    display: flex;
    flex-direction: column;
    margin-botton: 8px;
    align-items: flex-start;
  }
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    margin-left: 0;
    margin: 2px 0px 2px 2px;
  }
`;

export { LayerMenuWrap };
