import config from 'config';
const contactUrl = `${config.rootUrl}/contact`;

export async function sendContact(params) {
  // let body = JSON.stringify({
  //   email: params.email,
  //   message: params.message,
  // })

  // return body

  let response = await fetch(`${contactUrl}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 4,
      email: params.email,
      message: params.contactMessage,
      token: params.token,
    }),
  });

  let responseJson = await response;
  return responseJson;
}
