import { inputsConstants } from "../_constants";
import { authHeader } from "../_helpers";
import config from "config";

import { execute_job, poll_job, upload_to_s3 } from "./basics.jobs";
import fetchUtil from "../_services/fetchUtil";

const TopoValueMapper = {
  // ele: "vis_ele_ungraded_percent",
  NS: "vis_ns_ungraded_percent",
  EW: "vis_ew_ungraded_percent",
  U: "vis_max_ungraded_percent",
  // "ele/G": "vis_ele_graded_percent",
  "NS/G": "vis_ns_ungraded_percent",
  "EW/G": "vis_ew_ungraded_percent",
  "U/G": "vis_max_ungraded_percent",
  CF: "grade_target",
  Off: "off",
};

const TopoModeMapper = {
  ele: "vis_ele_ungraded_avail",
  NS: "vis_ns_ungraded_avail",
  EW: "vis_ew_ungraded_avail",
  U: "vis_max_ungraded_avail",
  // "ele/G": "vis_ele_graded_avail",
  "ele/G": "graded_avail",
  "NS/G": "vis_ns_graded_avail",
  "EW/G": "vis_ew_graded_avail",
  "U/G": "vis_max_graded_avail",
  // CF: "vis_g_ungraded_avail",
  CF: "graded_avail",
  Off: "off",
};

const loopLimit = 500;

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const topo_job = (inputs, action) => {
  let gen_ele = [false, false];
  let gen_ns = [false, false];
  let gen_ew = [false, false];
  let gen_max = [false, false];

  if (action === "pull_ele") {
    // this tells topo server to generate the elevation vis layer
    // along with importing elevation dataset
    gen_ele = [true, false];
  }

  if (action === "gen_layers" || action === "calc_grade") {
    gen_ele = [false, inputs.uiState.ele_graded_checked];
    gen_ns = [inputs.uiState.ns_ungraded_checked, inputs.uiState.ns_graded_checked];
    gen_ew = [inputs.uiState.ew_ungraded_checked, inputs.uiState.ew_graded_checked];
    gen_max = [inputs.uiState.max_ungraded_checked, inputs.uiState.max_graded_checked];
  }

  let topo_inputs = {
    //
    job_type: "topo-job",
    env: inputs.env === "prod" ? "prod" : "stage",
    id: inputs.topo_id,
    source: inputs.topo_id ? "file" : inputs.topo_source,
    bbox: inputs.boundary_bbox,
    // # bools for which tiles to generate
    generate_layers: {
      generate_ele: gen_ele,
      generate_ns: gen_ns,
      generate_ew: gen_ew,
      generate_u: gen_max,
      generate_cf: action === "calc_grade",
    },

    // # used as limits for slope analysis

    grade_limits: {
      ns_grade_limit: parseInt(inputs.vis_ns_ungraded_percent) || 10,
      ew_grade_limit: parseInt(inputs.vis_ew_ungraded_percent) || 20,
      u_grade_limit: parseInt(inputs.vis_max_ungraded_percent) || 15,
    },

    // # grading inputs
    grading: {
      enabled: action === "calc_grade",
      ns_grade_target: parseFloat(inputs.grade_target),
      ew_grade_target: parseFloat(inputs.grade_target),
      grade_target: parseFloat(inputs.grade_target),
      grade_target_type: 0,
    },
    // map features
    features: Object.values(inputs.site_features),

    topo: {
      graded_avail: inputs.graded_avail,
      grade_target: inputs.grade_target,

      vis_ele: {
        ...inputs.vis_ele,
        graded_percent: inputs.vis_ele_graded_percent,
        graded_avail: inputs.vis_ele_graded_avail || gen_ele[1],
      },
      vis_max: {
        // ...inputs.vis_max,
        graded_percent: inputs.vis_max_graded_percent,
        ungraded_percent: inputs.vis_max_ungraded_percent,
        ungraded_avail: inputs.vis_max_ungraded_avail || gen_max[0],
        graded_avail: inputs.vis_max_graded_avail || gen_max[1],
      },
      vis_ns: {
        // ...inputs.vis_ns,
        graded_percent: inputs.vis_ns_graded_percent,
        ungraded_percent: inputs.vis_ns_ungraded_percent,
        ungraded_avail: inputs.vis_ns_ungraded_avail || gen_ns[0],
        graded_avail: inputs.vis_ns_graded_avail || gen_ns[1],
      },
      vis_ew: {
        // ...inputs.vis_ew,
        graded_percent: inputs.vis_ew_graded_percent,
        ungraded_percent: inputs.vis_ew_ungraded_percent,
        ungraded_avail: inputs.vis_ew_ungraded_avail || gen_ew[0],
        graded_avail: inputs.vis_ew_graded_avail || gen_ew[1],
      },
      vis_g: {
        ...inputs.vis_g,
        graded_avail: inputs.vis_g_ungraded_avail || action === "calc_grade",
        ungraded_percent: inputs.grade_target,
      },
    },
  };

  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "topo_id",
    // action the job is taking
    action: action,
    job_type: "topo-job",

    async run(dispatch) {
      // console.log(topo_inputs);
      dispatch({ type: inputsConstants.UPDATE_INPUT_UI_STATE, key: "topo_loading", value: true });

      let init_resp = await execute_job(topo_inputs, "topo-job");

      topo_inputs.id = init_resp.job_id;
      topo_inputs.job_id = init_resp.job_id;

      let upload_resp = await upload_to_s3(init_resp.url, JSON.stringify(topo_inputs));

      if (upload_resp.error) {
        let error = job_resp.error;
        dispatch({ type: inputsConstants.TOPO_JOB_COMPLETE, results: {}, action: { error: error } });
      }

      let init_poll_resp = await poll_job(topo_inputs, "topo-job", dispatch);

      if (init_poll_resp.error) {
        return dispatch({ type: inputsConstants.TOPO_JOB_COMPLETE, results: {}, action: { error: init_poll_resp.errors } });
      } else {
        let bulk_inputs = {};
        let builk_ui = {};

        if (action === "pull_ele") {
          bulk_inputs = {
            topo_id: topo_inputs.job_id,
            topo_mode: "ele",
            topo_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/ele/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/ele/scale.png`,
          };
        }
        if (action === "calc_grade") {
          bulk_inputs = {
            job_action: "calc_grade",
            topo_id: topo_inputs.job_id,
            graded_avail: true,
            grade_cut_amt: init_poll_resp.output.output.cut_sum,
            grade_fill_amt: init_poll_resp.output.output.fill_sum,
            topo_mode: "CF",
            topo_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/CF/${topo_inputs.topo.grade_target}/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/CF/${topo_inputs.topo.grade_target}/scale.png`,
            vis_g: topo_inputs.topo.vis_g,
          };
          builk_ui = {
            ele_graded_checked: false,
            max_ungraded_checked: false,
            max_graded_checked: false,
            ns_ungraded_checked: false,
            ns_graded_checked: false,
            ew_ungraded_checked: false,
            ew_graded_checked: false,
          };
        }
        if (action === "gen_layers") {
          let mode = inputs.topo_mode;
          let mode_ext = mode;

          if (mode === "NS" || mode === "EW" || mode === "U" || mode === "CF") {
            mode_ext = `${mode}/${inputs[TopoValueMapper[mode]]}`;
          }
          if (mode === "NS/G" || mode === "EW/G" || mode === "U/G") {
            mode_ext = `${mode}/${inputs[TopoValueMapper[mode]]}`;
          }

          bulk_inputs = {
            topo_id: topo_inputs.job_id,
            topo_mode: mode,
            topo_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/${mode_ext}/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${topo_inputs.job_id}/${mode_ext}/scale.png`,
            vis_ele: topo_inputs.topo.vis_ele,
            vis_max: topo_inputs.topo.vis_max,
            vis_ns: topo_inputs.topo.vis_ns,
            vis_ew: topo_inputs.topo.vis_ew,
          };
          builk_ui = {
            ele_graded_checked: false,
            max_ungraded_checked: false,
            max_graded_checked: false,
            ns_ungraded_checked: false,
            ns_graded_checked: false,
            ew_ungraded_checked: false,
            ew_graded_checked: false,
          };
        }

        // topo job complete
        let topo_comp_uiState = {
          ...builk_ui,
          topo_loading: false,
          topo_error: "",
        };

        /** @author Abdul | 23 OCT, 2023
         * Task: sprint-16-slope-analysis : Max Slope, Nort-West, East-West Slope functions were not working for Graded
         * Solution: Previously we have not calculated the vis_ew_graded_avail, vis_max_graded_avail and vis_ns_graded_avail. So we fixed this in following lines
         */
        //
        let topo_comp_state = {
          ...bulk_inputs,
          topo_bbox: topo_inputs.bbox,
          // vis_g_ungraded_avail: builk_ui.vis_g?.ungraded_avail ? builk_ui.vis_g.ungraded_avail : inputs.vis_g_ungraded_avail,
          // vis_g_ungraded_percent: builk_ui.vis_g?.ungraded_percent ? builk_ui.vis_g.ungraded_percent : inputs.vis_g_ungraded_percent,
          vis_ew_ungraded_avail: bulk_inputs.vis_ew?.ungraded_avail ? bulk_inputs.vis_ew.ungraded_avail : inputs.vis_ew_ungraded_avail,
          vis_ew_graded_avail: bulk_inputs.vis_ew?.graded_avail ? bulk_inputs.vis_ew.graded_avail : inputs.vis_ew_graded_avail,
          // vis_ew_graded_avail: builk_ui.job_action == "calc_grade" ? false : builk_ui.vis_ew?.graded_avail ? builk_ui.vis_ew.graded_avail : inputs.vis_ew_graded_avail,
          vis_max_ungraded_avail: bulk_inputs.vis_max?.ungraded_avail ? bulk_inputs.vis_max.ungraded_avail : inputs.vis_max_ungraded_avail,
          vis_max_graded_avail: bulk_inputs.vis_max?.graded_avail ? bulk_inputs.vis_max.graded_avail : inputs.vis_max_graded_avail,
          // vis_max_graded_avail: builk_ui.job_action == "calc_grade" ? false : builk_ui.vis_max?.graded_avail ? builk_ui.vis_max.graded_avail : inputs.vis_max_graded_avail,
          vis_ns_ungraded_avail: bulk_inputs.vis_ns?.ungraded_avail ? bulk_inputs.vis_ns.ungraded_avail : inputs.vis_ns_ungraded_avail,
          vis_ns_graded_avail: bulk_inputs.vis_ns?.graded_avail ? bulk_inputs.vis_ns.graded_avail : inputs.vis_ns_graded_avail,
          // vis_ns_graded_avail: builk_ui.job_action == "calc_grade" ? false : builk_ui.vis_ns?.graded_avail ? builk_ui.vis_ns.graded_avail : inputs.vis_ns_graded_avail,
          // vis_ele_graded_avail: builk_ui.job_action == "calc_grade" ? true : false,
          vis_ele_ungraded_avail: true,
        };

        dispatch({ type: inputsConstants.TOPO_JOB_COMPLETE, ui_updates: topo_comp_uiState, state_updates: topo_comp_state });

        return topo_inputs;
      }
    },

    // job request action for redux
    request() {
      return { type: inputsConstants.UPDATE_INPUT_UI_STATE, key: "topo_loading", value: true };
    },
    // get job id (should be standardized across all jobs)
    async get_job_id(inputs) {
      console.log("topo inputs", inputs);
      const requestOptions = {
        method: "POST",
        //headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(inputs),
      };
      return fetchUtil(`${config.apiUrl}/dash/jobs/`, requestOptions);
    },

    async upload_to_s3(data) {
      // implement later
    },
    // poll job id (should be standardized across all jobs essentially)
    async poll_job(job) {
      const requestOptions = {
        method: "GET",
        //headers: { ...authHeader(), "Content-Type": "application/json" },
      };

      let loopBool = true;
      let loopCount = 0;
      let results = { job: job, error: false };
      // polling loop
      while (loopBool) {
        // poll request to backend
        var topo_check = await fetchUtil(`${config.apiUrl}/dash/jobs/?job_id=${job.job_id}`, requestOptions);
        if (topo_check["code"] == 100) {
          loopBool = false;
          //
          results.output = { ...JSON.parse(topo_check["output"]) };
          return results;
        } else if (topo_check["code"] == 97 || loopCount > loopLimit) {
          // ERROR OR TIMEOUT
          loopBool = false;
          results.error = { msg: "Error Connecting to Topography Server" };
          return results;
        } else {
          // wait a cool 1000ms then loop again
          await sleep(1000);
          loopCount += 1;
        }
      }
    },
    async download_from_s3(url) {
      // implement later
    },
    // completion of job -- maybe we handle the redux updates here?
    complete(results) {
      let _results;
      if (results.error) {
        _results = results.error;
      } else {
        if (results.job.action === "pull_ele") {
          _results = {
            topo_id: results.job.job_id,
            topo_mode: "ele",
            topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/scale.png`,
            uiState: {},
          };
        }
        if (results.job.action === "calc_grade") {
          _results = {
            job_action: "calc_grade",
            topo_id: results.job.job_id,
            graded_avail: true,
            grade_cut_amt: results.output.cut_sum,
            grade_fill_amt: results.output.fill_sum,
            topo_mode: "CF",
            topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/CF/${results.job.inputs.topo.grade_target}/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/CF/${results.job.inputs.topo.grade_target}/scale.png`,
            vis_g: results.job.inputs.topo.vis_g,
            uiState: {
              ele_graded_checked: false,
              max_ungraded_checked: false,
              max_graded_checked: false,
              ns_ungraded_checked: false,
              ns_graded_checked: false,
              ew_ungraded_checked: false,
              ew_graded_checked: false,
            },
          };
        }
        if (results.job.action === "gen_layers") {
          let mode = inputs.topo_mode;
          let mode_ext = mode;

          if (mode === "NS" || mode === "EW" || mode === "U" || mode === "CF") {
            mode_ext = `${mode}/${inputs[TopoValueMapper[mode]]}`;
          }
          if (mode === "NS/G" || mode === "EW/G" || mode === "U/G") {
            mode_ext = `${mode}/${inputs[TopoValueMapper[mode]]}`;
          }

          _results = {
            topo_id: results.job.job_id,
            topo_mode: mode,
            topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/${mode_ext}/{z}/{x}/{y}.png`,
            topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/${mode_ext}/scale.png`,
            vis_ele: results.job.inputs.topo.vis_ele,
            vis_max: results.job.inputs.topo.vis_max,
            vis_ns: results.job.inputs.topo.vis_ns,
            vis_ew: results.job.inputs.topo.vis_ew,
            uiState: {
              ele_graded_checked: false,
              max_ungraded_checked: false,
              max_graded_checked: false,
              ns_ungraded_checked: false,
              ns_graded_checked: false,
              ew_ungraded_checked: false,
              ew_graded_checked: false,
            },
          };
        }
      }

      return { type: inputsConstants.TOPO_JOB_COMPLETE, results: _results, action: action };
    },
  };
};
