import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";
import { canopyActions, inputsActions, usePrevious } from "../../../Redux";

import { search_map, stree_view, delete_polygon, textbubble } from "../../../../assets/images";
import { DrawCanopy } from "./DrawCanopy/DrawCanopy";
import { ToolbarWrap } from "../../../Map/components/Toolbar/styles";
import { LeafletControl } from "../../../Map/components/LeafletControl";
import { Search } from "../../../Map/components";
import { CanopyTools } from "./CanopyTools.js";
import { LayerMenu } from "../../../Map/components";
import { Tooltip, Popconfirm } from "antd";

const CanopyToolbar = ({ position }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const search_visible = useSelector((state) => state.inputs.uiState.search_visible);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);

  const show_names = useSelector((state) => state.canopy.show_names);
  const is_drawing = useSelector((state) => state.canopy.is_drawing);
  const prev_selected_canopy = usePrevious(selected_canopy);

  const project_name = useSelector((state) => state.canopy.project_name);
  const prev_project_name = usePrevious(project_name);

  const [expand_map_layers, set_expand_map_layers] = useState(false);
  const [show_canopy_tools, set_show_canopy_tools] = useState(false);
  const [show_shade_tools, set_show_shade_tools] = useState(false);
  const [expand_height, set_expand_height] = useState(0);

  // useEffect(() => {
  //   if (selected_shade_object_id && !show_shade_tools) {
  //     toggleShadeTools(!show_shade_tools);
  //   }
  // }, [selected_shade_object_id]);

  useEffect(() => {
    //bootleg way to figure out if a new project has started. Just for reseting the toolbar
    if (prev_project_name && !project_name) {
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (expand_map_layers) set_expand_map_layers(false);
      if (show_canopy_tools) set_show_canopy_tools(false);
      // if (show_shade_tools) set_show_shade_tools(false);
      if (expand_height > 0) set_expand_height(0);
    }
  }, [project_name, prev_project_name]);

  useEffect(() => {
    if (is_drawing) {
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (expand_map_layers) toggle_map_layers(false);
      // if (show_shade_tools) toggleShadeTools(false);
    }
  }, [is_drawing]);

  useEffect(() => {
    if (selected_canopy) {
      toggleDrawTools(true);
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (expand_map_layers) toggle_map_layers(false);
      // if (show_shade_tools) toggleShadeTools(false);
    } else {
      toggleDrawTools(false);
    }

    if (prev_selected_canopy != selected_canopy && expand_map_layers) {
      set_expand_height(40);
    }
  }, [prev_selected_canopy, selected_canopy]);

  const toggle_search = (toggled) => {
    dispatch(inputsActions.update_ui_state("search_visible", !search_visible));
    if (toggled == true) {
      set_expand_height(40);
      if (is_drawing) dispatch(canopyActions.updateCanopyInputs("is_drawing", false));
      if (expand_map_layers) toggle_map_layers(false);
      // if (show_shade_tools) toggleShadeTools(false);
      // if (is_drawing) map.fire("cancelDraw");
    }

    // if (selectedFeatureId && toggled) {
    //   toggle_polygon_tools(true);
    // }
  };

  const toggle_map_layers = (toggled) => {
    set_expand_map_layers(!expand_map_layers);
    if (toggled == true) {
      set_expand_height(430);
      dispatch(inputsActions.update_ui_state("search_visible", false));
      set_show_canopy_tools(false);
      if (show_shade_tools) toggleShadeTools(false);
      // if (is_drawing) map.fire("cancelDraw");
    }
  };

  const toggleDrawTools = (toggled) => {
    set_show_canopy_tools(toggled);
    if (toggled == true) {
      set_expand_height(40);
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
    }
  };

  const toggleShadeTools = (toggled) => {
    set_show_shade_tools(toggled);
    if (toggled == true) {
      set_expand_height(40);
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (selected_canopy) dispatch(canopyActions.updateCanopyInputs("selected_canopy", undefined));
      set_show_canopy_tools(false);
      set_expand_map_layers(false);
    }
  };

  return (
    <LeafletControl position={position} id="canopy-toolbar">
      <ToolbarWrap expand_height={expand_height} expand={search_visible} canopy={true}>
        <section className="map-action-buttons">
          <Tooltip placement="left" title="Search" mouseEnterDelay={0.5}>
            <button className={search_visible ? "button-active" : ""} onClick={() => toggle_search(!search_visible)}>
              <img draggable={false} src={search_map} />
            </button>
          </Tooltip>

          <Tooltip placement="bottom" title="Change Basemap" mouseEnterDelay={0.5}>
            <button className={expand_map_layers ? "button-active" : ""} onClick={() => toggle_map_layers(!expand_map_layers)}>
              <img draggable={false} src={stree_view} />
            </button>
          </Tooltip>

          <DrawCanopy set_show_canopy_tools={set_show_canopy_tools} show_canopy_tools={show_canopy_tools} />

          {/* {config.env !== "prod" && (
            <Tooltip placement="bottom" title="Shade Tools" mouseEnterDelay={0.25}>
              <button className={show_shade_tools ? "button-active" : ""} onClick={() => toggleShadeTools(!show_shade_tools)}>
                <img draggable={false} style={{ width: 30 }} src={shade_tool_icon} />
              </button>
            </Tooltip>
          )} */}

          <Tooltip placement="bottom" title="Show Names" mouseEnterDelay={0.25}>
            <button className={show_names ? "button-active" : ""} onClick={() => dispatch(canopyActions.updateCanopyInputs("show_names", !show_names))}>
              <img draggable={false} src={textbubble} />
            </button>
          </Tooltip>

          <Tooltip placement="bottom" title="Delete All Canopies" mouseEnterDelay={0.25}>
            <Popconfirm title="Delete All Canopies?" placement="bottom" onConfirm={() => dispatch(canopyActions.deleteCanopies(selected_canopy, "delete_all"))} okText="Yes" cancelText="No">
              <button>
                <img draggable={false} src={delete_polygon} />
              </button>
            </Popconfirm>
          </Tooltip>
          {/* <Tooltip placement="bottom" title="Move Canopy" mouseEnterDelay={0.25}>
            <button onClick={() => map.fire("moveCanopy")}>
              <img src={move} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Rotate Canopy" mouseEnterDelay={0.25}>
            <button onClick={() => map.fire("rotateCanopy")}>
              <img src={rotate} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Cut Layer" mouseEnterDelay={0.5}>
            <button className={false ? "button-active" : ""} onClick={() => console.log("cut")}>
              <img src={scissors} />
            </button>
          </Tooltip> */}
        </section>
        <section className={search_visible || show_canopy_tools || expand_map_layers || show_shade_tools ? "expand-down" : "map-action-tray"}>
          {search_visible && <Search />}
          {expand_map_layers && <LayerMenu />}
          {selected_canopy && show_canopy_tools && !search_visible && <CanopyTools id={selected_canopy} />}
          {/* {config.env !== "prod" && show_shade_tools && <ShadeDrawingTools />} */}
        </section>
      </ToolbarWrap>
    </LeafletControl>
  );
};

export { CanopyToolbar };
