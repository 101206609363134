import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";

import { canopyActions, usePrevious } from "../../Redux";
import { useKey } from "../../Map/components/MapEventHandler/useKey";
import { build_shade_object, getCorners } from "../ShadeHelpers/Shade.editor";

// import "../../Map/leaflet.draw.css";

const DrawTreeLine = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const drawing_tree_line = useSelector((state) => state.canopy.drawing_tree_line);
  const prev_drawing_tree_line = usePrevious(drawing_tree_line);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const cancel_key = useKey("escape");

  useEffect(() => {
    if (!prev_drawing_tree_line && drawing_tree_line) {
      drawTreeLine();
      document.getElementById("map").style.cursor = "crosshair";
    } else if (prev_drawing_tree_line && !drawing_tree_line) {
      cancelDraw();
      document.getElementById("map").style.cursor = "grab";
    }
  }, [drawing_tree_line]);

  useEffect(() => {
    if (cancel_key && drawing_tree_line) {
      cancelDraw();
      document.getElementById("map").style.cursor = "grab";
    }
  }, [cancel_key, drawing_tree_line]);

  useEffect(() => {
    map.on("pm:create", (e) => {
      if (e.shape === "Line") {
        let geoJson = e.layer.toGeoJSON();
        let center = e.layer.getBounds().getCenter();
        let corners = e.layer.toGeoJSON().geometry.coordinates[0];
        let shade_object = build_shade_object(geoJson, corners, center, shade_height_unit, e.shape);
        dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
        e.layer.remove();
      }
    });

    return () => map.off("pm:create");
  }, [shade_height_unit]);

  const drawTreeLine = () => {
    let options = {
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 2,
      fillColor: "var(--primary-brand-color)",
    };

    map.pm.enableDraw("Line", {
      snappable: true,
      snapDistance: 20,
      templineStyle: options,
      pathOptions: options,
      tooltips: false,
    });

    // if (drawing_tree_line) {
    //   cancelDraw();
    // } else {
    //   dispatch(canopyActions.updateCanopyInputs("drawing_tree_line", true));
    // }
  };

  const cancelDraw = () => {
    map.pm.disableDraw();
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree_line", false));
  };

  return null;
};

export { DrawTreeLine };
