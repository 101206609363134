import styled from "styled-components";

const ImageCheckboxWrap = styled.section`
  display: grid;
  margin-right: 5px;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
  }
`;

export { ImageCheckboxWrap };
