import { inputsConstants, siftConstants } from "../_constants";
import { authHeader } from "../_helpers";
import * as turf from "@turf/turf";
import config from "config";

import { execute_job, poll_job, upload_to_s3 } from "./basics.jobs";
import fetchUtil from "../_services/fetchUtil";

export const fetch_weather = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_type: "weather-fetch",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux
    async run(dispatch) {
      dispatch({ type: siftConstants.WEATHER_REQUEST });

      // console.log('Automatically pulling weather for ', inputs)
      // let weather_data = await weather_job.run()

      if (inputs.site_features) {
        var featCollection = {
          type: "FeatureCollection",
          features: [...Object.values(inputs.site_features)],
        };
        let centeroid = turf.getCoords(turf.flip(turf.centroid(featCollection)));
        // console.log(centeroid)
        inputs.latitude = centeroid[0];
        inputs.longitude = centeroid[1];
        inputs.map_center = centeroid;
      }

      // if (inputs.elevation == undefined || inputs.timezone == undefined) {
      // get ele/tz
      let ele_tz = await get_ele_tz([inputs.latitude, inputs.longitude]);
      if (ele_tz.data.ele.status === "OK") inputs.elevation = ele_tz.data.ele.results[0].elevation;
      if (ele_tz.data.tz.status === "OK") inputs.timezone = ele_tz.data.tz.rawOffset / 3600;
      if (ele_tz.data.loc.status === "OK") inputs.location = ele_tz.data.loc.results[0].formatted_address;
      // }

      let weather_inputs = {
        latitude: inputs.latitude,
        longitude: inputs.longitude,
        elevation: inputs.elevation,
        timezone: inputs.timezone,
        source: inputs.weather_source,
        job_type: "weather-fetch",
      };

      // console.log(weather_inputs);

      // let weather_data = await get_weather(weather_inputs);
      let init_resp = await execute_job(weather_inputs, "weather-fetch");

      weather_inputs.job_id = init_resp.job_id;

      let upload_resp = await upload_to_s3(init_resp.url, JSON.stringify(weather_inputs));

      if (upload_resp.error) {
        let error = job_resp.error;
        dispatch({ type: siftConstants.WEATHER_FAILURE, error });
      }

      let init_poll_resp = await poll_job(weather_inputs, "weather-fetch", dispatch);

      if (init_poll_resp.error) {
        if (action === "manual-request") {
          return dispatch({ type: siftConstants.WEATHER_FAILURE, error: init_poll_resp.errors });
        }

        return init_poll_resp;
      } else {
        let bulk_inputs = {
          latitude: inputs.latitude,
          longitude: inputs.longitude,
          elevation: inputs.elevation,
          timezone: inputs.timezone,

          weather_lat: init_poll_resp.output.job_output.Latitude,
          weather_lng: init_poll_resp.output.job_output.Longitude,
          weather_ele: init_poll_resp.output.job_output.Elevation,
          weather_tz: init_poll_resp.output.job_output.Timezone,
          weather_id: init_poll_resp.output.job_output.weather_id,
          weather_name: init_poll_resp.output.job_output.LocationId,
          weather_summary: init_poll_resp.output.job_output.Summary,

          weather_source: init_poll_resp.output.job_output.Source,
        };
        dispatch({ type: inputsConstants.UPDATE_BULK_INPUTS, bulk_inputs });

        if (action === "manual-request") {
          dispatch({ type: siftConstants.WEATHER_DATA_SUCCESS, data: bulk_inputs });
        }
        return weather_inputs;
      }
    },
  };
};

export async function get_ele_tz(latlng) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(latlng),
  };

  return fetchUtil(`${config.apiUrl}/dash/swm/eletz`, requestOptions).then((response) => response);
}
