import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { inputsActions, execute_job, fetch_horizon_shading } from "../../Redux";

import { Popconfirm } from "antd";

import { HorizonShadingWrap, ShadingInputsWrap } from "./styles";
import { HorizonShadeGraph } from "../../LiveReport/components";

import { Button, Tooltip, Spin } from "../../BaseComponents";

const ShadingInputs = () => {
  const dispatch = useDispatch();

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const weather_loading = useSelector((state) => state.sift.ioManager.uiState.weather_loading);
  const horizon_shade_loading = useSelector((state) => state.inputs.horizon_shade_loading);
  const horizon_data = useSelector((state) => state.inputs.horizon_data);

  const latitude = useSelector((state) => state.inputs.latitude);
  const longitude = useSelector((state) => state.inputs.longitude);

  // useEffect(() => {
  //   if (!latitude && !longitude) {
  //     console.log("no lat or long");
  //   }
  // }, [latitude, longitude]);

  const saving = account_loading || input_loading;

  const request_hori_shading = () => {
    // console.log("lat", latitude);
    // console.log("long", longitude);

    if (!latitude && !longitude) return;

    dispatch(
      execute_job(
        fetch_horizon_shading(
          {
            lat: parseFloat(latitude),
            long: parseFloat(longitude),
          },
          "sift-import"
        )
      )
    );
  };

  const clear_hori_shading = () => {
    dispatch(inputsActions.update_input("horizon_data", undefined));
  };

  return (
    <>
      {weather_loading ? (
        <Spin text="Getting site location..." />
      ) : (
        <ShadingInputsWrap>
          <HorizonShadingWrap>
            <p> Horizon Shading</p>
            {horizon_data ? (
              <Popconfirm
                placement="right"
                title={`Are you sure you want to clear Horizon Shading?`}
                onCancel={() => console.log("canceled")}
                okText="Yes"
                cancelText="No"
                style={{ maxWidth: "400px" }}
                onConfirm={() => {
                  clear_hori_shading();
                }}
              >
                <Button label="Clear Horizon Shading" type="secondary" loading={horizon_shade_loading} disabled={horizon_shade_loading || weather_loading || saving} size="small" />
              </Popconfirm>
            ) : (
              <Tooltip title={!latitude && !longitude ? "Draw a polygon, load a project, or open the weather tab to establish a site center point." : null}>
                <Button
                  label="Import Horizon Shading"
                  type="secondary"
                  loading={horizon_shade_loading}
                  disabled={(!latitude && !longitude) || horizon_shade_loading || weather_loading || saving}
                  size="small"
                  onClick={() => request_hori_shading()}
                />
              </Tooltip>
            )}
            {horizon_data && <HorizonShadeGraph data={horizon_data} />}
          </HorizonShadingWrap>
        </ShadingInputsWrap>
      )}
    </>
  );
};

export { ShadingInputs };
