import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, canopyActions } from "../../../../../Redux";

import { Input, Radio, Tooltip, Typography } from "antd";

import { InputRow } from "./styles";
import { debouncedUpdateinput } from "./inputs.helper";

function PlaneTypeInput() {
  const dispatch = useDispatch();

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const planeType = useSelector((state) => state.canopy.canopies[selected_canopy]?.planeType);
  const tilt = useSelector((state) => state.canopy.canopies[selected_canopy]?.tilt);
  const towardAzimuth = useSelector((state) => state.canopy.canopies[selected_canopy]?.towardAzimuth);
  const tiltFromAzimuth = useSelector((state) => state.canopy.canopies[selected_canopy]?.tiltFromAzimuth);
  const tiltToAzimuth = useSelector((state) => state.canopy.canopies[selected_canopy]?.tiltToAzimuth);

  const modY = useSelector((state) => state.canopy.canopies[selected_canopy]?.base_dimension?.modY);
  const [planeType_error, set_planeType_error] = useState(undefined);

  function onChangeInput(e) {
    let changed_value;
    let changed_key;

    if (e.target.name == "type") {
      changed_value = e.target.value;
      changed_key = "planeType";
      // changed_key = e.target.value;
    } else {
      changed_value = parseFloat(e.target.value);
      changed_key = e.target.id;
    }

    // if (!isNumber(changed_value)) {
    // 	set_planeType_error('Invalid Input')
    // 	return
    // } else if (changed_value < 0 || changed_value > 359) {
    // 	set_planeType_error('Invalid Input')
    // 	return
    // } else if (planeType_error != undefined) {
    // 	set_planeType_error(undefined);
    // }
    debouncedUpdateinput.cancel();
    debouncedUpdateinput(dispatch, selected_canopy, changed_key, changed_value);
    // dispatch(canopyActions.updateCanopyProperty(selected_canopy, changed_key, changed_value));
  }

  return (
    <>
      <section>
        <p>Plane Type:</p>
      </section>
      <Radio.Group style={{ display: "flex", justifyContent: "space-around" }} value={planeType} onChange={onChangeInput} name="type">
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"single_slope"}
        >
          <Tooltip title="Single Slope">
            <svg width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="https://www.w3.org/2000/svg">
              <rect width="29.7487" height="4.997" transform="matrix(0.965337 0.261005 -0.256648 0.966505 1.28247 0)" fill="#60de4f" />
            </svg>
          </Tooltip>
        </Radio>
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"inverted"}
        >
          <Tooltip title="Inverted">
            <svg width="30" height="11" viewBox="0 0 30 11" fill="none" xmlns="https://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 4.82636L1.00544 0L0 4.82963L14.989 9.99896L14.9905 10.006L15 10.0027L15.0095 10.006L15.011 9.99896L30 4.82963L28.9946 0L15 4.82636Z"
                fill="#60de4f"
              />
            </svg>
          </Tooltip>
        </Radio>
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"dual_tilt"}
        >
          <Tooltip title="Dual Tilt">
            <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="https://www.w3.org/2000/svg">
              <path
                d="M1.09174 3.93968L6.41431 0.939682L11.5917 3.93968L16.3337 0.939682L21.5917 3.93968L26.253 0.939682L31.0917 3.93968V6.93968L26.253 3.93968L21.5917 6.93968L16.3337 3.93968L11.5917 6.93968L6.41431 3.93968L1.09174 6.93968L1.09174 3.93968Z"
                fill="#60de4f"
                stroke="#60de4f"
              />
            </svg>
          </Tooltip>
        </Radio>
      </Radio.Group>
      {planeType != "inverted" && (
        <InputRow>
          <label>Tilt:</label>
          <Input id="tilt" size="small" value={tilt} type="number" min="0" max="15" suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>} onChange={onChangeInput} />
        </InputRow>
      )}
      {(planeType == "single_slope" || planeType == "dual_tilt") && tilt > 7 && (
        <section className="canopy-warning-text">
          <Typography.Text type="danger">Tilt has exceeded 7°</Typography.Text>
        </section>
      )}
      {planeType == "dual_tilt" && (
        <section className="canopy-warning-text">
          <Typography.Text>{`Towards Azimuth (Black)`}</Typography.Text>
          <br />
          <Typography.Text>{`Away From Azimuth (White)`}</Typography.Text>
        </section>
      )}
      {planeType == "inverted" && (
        <Fragment>
          <InputRow>
            <label>Toward Azimuth:</label>
            <Input
              id="towardAzimuth"
              size="small"
              value={towardAzimuth}
              type="number"
              min="0"
              max={modY}
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>}
              onChange={onChangeInput}
            />
          </InputRow>

          <section className="canopy-warning-text">
            <label>{`Away from Azimuth(White): ${modY - towardAzimuth}`}</label>
          </section>
          <InputRow>
            <label>Tilt to Azimuth:</label>
            <Input
              id="tiltToAzimuth"
              size="small"
              value={tiltToAzimuth}
              type="number"
              min="0"
              max="25"
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
              onChange={onChangeInput}
            />
          </InputRow>
          <InputRow>
            <label>Tilt from Azimuth:</label>
            <Input
              id="tiltFromAzimuth"
              size="small"
              value={tiltFromAzimuth}
              type="number"
              min="0"
              max="25"
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
              onChange={onChangeInput}
            />
          </InputRow>
          {tiltToAzimuth > 7 && (
            <section className="canopy-warning-text">
              <Typography.Text type="danger">Tilt to Azimuth has exceeded 7°</Typography.Text>
            </section>
          )}
          {tiltFromAzimuth > 7 && (
            <section className="canopy-warning-text">
              <Typography.Text type="danger">Tilt from Azimuth has exceeded 7°</Typography.Text>
            </section>
          )}
        </Fragment>
      )}
    </>
  );
}

export { PlaneTypeInput };
