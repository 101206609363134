import React from "react";
// import { SIFTHeader } from "./SIFTHeader";
import { SIFTHeader_New } from "./SIFTHeader_new";
import { BaseWrap } from "./styles";

const Base = ({ children }) => {
  return (
    <BaseWrap>
      {/* HEADER */}
      <SIFTHeader_New />
      {/* CONTENT */}
      <section className="inputs">
        <section className="content">{children}</section>
      </section>
    </BaseWrap>
  );
};

export { Base };
