const InvertersDD = {
  0: {
    "id": 0,
    "data": {
      "id": -50,
      "default":0,
      "name":"Custom Inverter",
      "derate_maxoutput":[0],
      "derate_temps":[0],
      "derate_toggle":0,
      "inv_pd_eff_pout":[0],
      "inv_pd_efficiency":[0],
      "inv_pd_pacokw":0,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":0,
      "inverterRating":0,
      "maxEff":0,
      "mppt_hi_inverter":0,
      "mppt_low_inverter":0,
      "pnom":0,
      "v1_eff":[],
      "v1_pl":[],
      "v2_eff":[],
      "v2_pl":[],
      "v3_eff":[],
      "v3_pl":[],
      "vs_effeu":[],
      "vs_effmax":[],
      "vs_vnom":[],
    }
  },  
  "d60603775ea34ac28e85189c1edeac72": {
    "id": "d60603775ea34ac28e85189c1edeac72",
    "data":{
      "id":"d60603775ea34ac28e85189c1edeac72",
      "default":1,
      "name":"Generic 60kW",
      "derate_maxoutput":[66.0, 60.0, 36.0, 0],
      "derate_temps":[30.0, 40.0, 60.0, 62.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[8.0, 40.444, 72.889, 105.333, 137.778, 170.222, 202.667, 235.111, 267.556, 300.0, 466.667, 633.333, 800.0, 966.667, 1133.333, 1300.0, 1466.667, 1633.333, 1800.0, 2333.333, 2866.667, 3400.0, 3933.333, 4466.667, 5000.0, 5533.333, 6066.667, 6600.0],
      "inv_pd_efficiency":[0.0, 68.89, 84.50999999999999, 90.55, 93.46, 95.07, 96.05, 96.69, 97.14, 97.46000000000001, 98.24000000000001, 98.52, 98.65, 98.72, 98.76, 98.79, 98.81, 98.83, 98.83999999999999, 98.95, 98.96000000000001, 98.94, 98.91, 98.88, 98.86, 98.83, 98.77, 98.72],
      "inv_pd_pacokw":66,
      "inv_pd_pnt":2,
      "inv_pd_vdcmax":1500,
      "inverterRating":60,
      "maxEff":100,
      "mppt_hi_inverter":1480,
      "mppt_low_inverter":600,
      "pnom":60,
      "v1_eff":[0.0, 0.98, 0.982, 0.984, 0.985, 0.984, 0.983, 0.982],
      "v1_pl":[0.133, 10.0, 20.0, 30.0, 50.0, 75.0, 100.0, 110.0],
      "v2_eff":[0.0, 0.984, 0.988, 0.989, 0.99, 0.989, 0.988, 0.987],
      "v2_pl":[0.133, 9.167, 18.333, 27.5, 45.833, 68.75, 91.667, 110.0],
      "v3_eff":[0.0, 0.983, 0.988, 0.989, 0.989, 0.988, 0.987, 0.986],
      "v3_pl":[0.133, 10.0, 20.0, 30.0, 50.0, 75.0, 100.0, 110.0],
      "vs_effeu":[98.3, 98.82, 98.75],
      "vs_effmax":[98.45, 98.97, 98.89],
      "vs_vnom":[880.0, 1180.0, 1275.0]
    }
  },
  "cab7410d46034899ae2516b5c0cfe89b": {
    "id": "cab7410d46034899ae2516b5c0cfe89b",
    "data":{
      "id":2,
      "default":1,
      "name":"Generic 120kW",
      "derate_maxoutput":[120.0, 120.0],
      "derate_temps":[25.0, 50.0],
      "derate_toggle":0,
      "inv_pd_eff_pout":[60.0, 120.0, 180.0, 240.0, 300.0, 360.0, 420.0, 480.0, 540.0, 600.0, 933.333, 1266.667, 1600.0, 1933.333, 2266.667, 2600.0, 2933.333, 3266.667, 3600.0, 4556.778, 5513.556, 6470.333, 7427.111, 8383.889, 9340.667, 10297.444, 11254.222, 12211.0],
      "inv_pd_efficiency":[0.0, 70.39, 89.33, 94.82000000000001, 96.77, 97.6, 98.0, 98.22, 98.34, 98.42, 98.56, 98.59, 98.59, 98.6, 98.6, 98.6, 98.6, 98.6, 98.6, 98.79, 98.74000000000001, 98.68, 98.6, 98.52, 98.44000000000001, 98.37, 98.29, 98.22],
      "inv_pd_pacokw":120,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1000,
      "inverterRating":120,
      "maxEff":100,
      "mppt_hi_inverter":1000,
      "mppt_low_inverter":360,
      "pnom":120,
      "v1_eff":[0.0, 0.974, 0.981, 0.985, 0.983, 0.981, 0.978],
      "v1_pl":[0.5, 5.178, 10.197, 25.316, 50.773, 76.367, 102.112],
      "v2_eff":[0.0, 0.984, 0.988, 0.989, 0.987, 0.985, 0.982],
      "v2_pl":[0.5, 5.149, 10.12, 25.228, 50.618, 76.085, 101.758],
      "v3_eff":[0.0, 0.974, 0.984, 0.987, 0.986, 0.984, 0.981],
      "v3_pl":[0.5, 5.203, 10.182, 25.263, 50.627, 76.163, 101.918],
      "vs_effeu":[98.2, 98.63, 98.45],
      "vs_effmax":[98.46, 98.87, 98.67],
      "vs_vnom":[570.0, 720.0, 800.0]
    }
  },
  "8dc81bdb6a614f2fa52d2992f96d28ec": {
    "id": "8dc81bdb6a614f2fa52d2992f96d28ec",
    "data":{
      "id":3,
      "default":1,
      "name":"Generic 220kW",
      "derate_maxoutput":[220.0, 220.0, 0.0, 0],
      "derate_temps":[25.0, 50.0, 60.0, 62.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[110.0, 220.0, 330.0, 440.0, 550.0, 660.0, 770.0, 880.0, 990.0, 1100.0, 1711.111, 2322.222, 2933.333, 3544.444, 4155.556, 4766.667, 5377.778, 5988.889, 6600.0, 8311.111, 10022.222, 11733.333, 13444.444, 15155.556, 16866.667, 18577.778, 20288.889, 22000.0],
      "inv_pd_efficiency":[0.0, 53.22, 72.61, 81.51, 86.25, 89.05, 90.83, 92.04, 92.89, 93.52000000000001, 95.16, 95.72, 95.98, 96.12, 96.21, 96.26, 96.3, 96.33, 96.35000000000001, 96.53, 96.55, 96.54, 96.5, 96.45, 96.41, 96.36, 96.3, 96.25],
      "inv_pd_pacokw":220,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1000,
      "inverterRating":220,
      "maxEff":100,
      "mppt_hi_inverter":800,
      "mppt_low_inverter":485,
      "pnom":220,
      "v1_eff":[0.0, 0.943, 0.957, 0.964, 0.966, 0.966, 0.965, 0.963],
      "v1_pl":[0.5, 5.0, 10.0, 20.0, 30.0, 50.0, 75.0, 100.0],
      "v2_eff":[0.0, 0.936, 0.955, 0.962, 0.965, 0.966, 0.964, 0.963],
      "v2_pl":[0.5, 5.0, 10.0, 20.0, 30.0, 50.0, 75.0, 100.0],
      "v3_eff":[0.0, 0.911, 0.949, 0.96, 0.964, 0.965, 0.964, 0.962],
      "v3_pl":[0.5, 5.0, 10.0, 20.0, 30.0, 50.0, 75.0, 100.0],
      "vs_effeu":[96.41, 96.3, 96.11],
      "vs_effmax":[96.64, 96.56, 96.47],
      "vs_vnom":[485.0, 500.0, 550.0]
    }
  },
  "7ea77a1b94424516960921e822a39ed5": {
    "id": "7ea77a1b94424516960921e822a39ed5",
    "data":{
      "id":4,
      "default":1,
      "name":"Generic 400kW",
      "derate_maxoutput":[400.0, 400.0, 0.0, 0],
      "derate_temps":[25.0, 50.0, 60.0, 62.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[240.0, 435.556, 631.111, 826.667, 1022.222, 1217.778, 1413.333, 1608.889, 1804.444, 2000.0, 3111.111, 4222.222, 5333.333, 6444.444, 7555.556, 8666.667, 9777.778, 10888.889, 12000.0, 15276.333, 18552.667, 21829.0, 25105.333, 28381.667, 31658.0, 34934.333, 38210.667, 41487.0],
      "inv_pd_efficiency":[0.0, 51.61, 71.67, 81.17999999999999, 86.35000000000001, 89.44, 91.43, 92.78, 93.74, 94.45, 96.32, 96.95, 97.24000000000001, 97.39999999999999, 97.49, 97.55, 97.59, 97.61999999999999, 97.64, 97.83, 97.85000000000001, 97.84, 97.8, 97.76, 97.71, 97.66, 97.6, 97.55],
      "inv_pd_pacokw":400,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1000,
      "inverterRating":400,
      "maxEff":100,
      "mppt_hi_inverter":800,
      "mppt_low_inverter":570,
      "pnom":400,
      "v1_eff":[0.0, 0.956, 0.97, 0.977, 0.979, 0.979, 0.978, 0.976],
      "v1_pl":[0.6, 5.298, 10.213, 20.48, 30.915, 51.438, 77.328, 99.985],
      "v2_eff":[0.0, 0.949, 0.968, 0.975, 0.978, 0.979, 0.977, 0.976],
      "v2_pl":[0.6, 5.335, 10.315, 20.812, 30.788, 51.443, 77.53, 103.718],
      "v3_eff":[0.0, 0.934, 0.962, 0.973, 0.977, 0.978, 0.976, 0.975],
      "v3_pl":[0.6, 5.362, 10.582, 20.725, 31.002, 51.565, 77.037, 102.948],
      "vs_effeu":[97.7, 97.59, 97.42],
      "vs_effmax":[97.94, 97.86, 97.77],
      "vs_vnom":[576.0, 594.0, 653.0]
    }
  },
  "77eadee19e384a7eaf2d1a2226ebe41c": {
    "id": "77eadee19e384a7eaf2d1a2226ebe41c",
    "data":{
      "id":5,
      "default":1,
      "name":"Generic 700kW",
      "derate_maxoutput":[780.0, 780.0, 0.0, 0],
      "derate_temps":[25.0, 50.0, 60.0, 62.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[350.0, 744.444, 1138.889, 1533.333, 1927.778, 2322.222, 2716.667, 3111.111, 3505.556, 3900.0, 6066.667, 8233.333, 10400.0, 12566.667, 14733.333, 16900.0, 19066.667, 21233.333, 23400.0, 28767.111, 34134.222, 39501.333, 44868.444, 50235.556, 55602.667, 60969.778, 66336.889, 71704.0],
      "inv_pd_efficiency":[0.0, 60.540000000000006, 80.19, 88.13, 91.91, 93.95, 95.15, 95.92, 96.43, 96.78999999999999, 97.63, 97.88, 97.98, 98.03, 98.06, 98.08, 98.09, 98.1, 98.11, 98.18, 98.06, 97.94, 97.91, 97.88, 97.86, 97.84, 97.81, 97.78999999999999],
      "inv_pd_pacokw":780,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1000,
      "inverterRating":780,
      "maxEff":100,
      "mppt_hi_inverter":850,
      "mppt_low_inverter":470,
      "pnom":780,
      "v1_eff":[0.0, 0.96, 0.975, 0.977, 0.98, 0.977, 0.974, 0.972],
      "v1_pl":[0.449, 4.305, 7.567, 10.871, 29.536, 61.861, 82.179, 92.364],
      "v2_eff":[0.0, 0.964, 0.974, 0.981, 0.985, 0.979, 0.978],
      "v2_pl":[0.449, 4.564, 6.764, 10.053, 19.983, 51.185, 91.928],
      "v3_eff":[0.0, 0.967, 0.976, 0.984, 0.987, 0.982, 0.98, 0.978],
      "v3_pl":[0.449, 4.391, 6.594, 9.851, 19.769, 50.909, 82.428, 92.526],
      "vs_effeu":[97.64, 97.97, 98.17],
      "vs_effmax":[98.02, 98.47, 98.7],
      "vs_vnom":[600.0, 760.0, 800.0]
    }
  },
  "0f14d6b175444d6699dfe4d69f32c243": {
    "id": "0f14d6b175444d6699dfe4d69f32c243",
    "data":{
      "id":6,
      "default":1,
      "name":"Generic 1000kW",
      "derate_maxoutput":[1100.0, 1000.0],
      "derate_temps":[25.0, 50.0],
      "derate_toggle":0,
      "inv_pd_eff_pout":[500.0, 1000.0, 1500.0, 2000.0, 2500.0, 3000.0, 3500.0, 4000.0, 4500.0, 5000.0, 7777.778, 10555.556, 13333.333, 16111.111, 18888.889, 21666.667, 24444.444, 27222.222, 30000.0, 37777.778, 45555.556, 53333.333, 61111.111, 68888.889, 76666.667, 84444.444, 92222.222, 100000.0],
      "inv_pd_efficiency":[0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16, 98.92999999999999, 98.7],
      "inv_pd_pacokw":1100,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1000,
      "inverterRating":1000,
      "maxEff":99,
      "mppt_hi_inverter":850,
      "mppt_low_inverter":460,
      "pnom":1000,
      "v1_eff":[0],
      "v1_pl":[0.0],
      "v2_eff":[0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
      "v2_pl":[0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
      "v3_eff":[0],
      "v3_pl":[0.0],
      "vs_effeu":[],
      "vs_effmax":[],
      "vs_vnom":[]
    }
  },          
  "a071e37800544682a713ab23cf72e834": {
    "id": "a071e37800544682a713ab23cf72e834",
    "data":{
      "id":7,
      "default":1,
      "name":"Generic 1500kW",
      "derate_maxoutput":[1500.0, 1500.0],
      "derate_temps":[25.0, 50.0],
      "derate_toggle":0,
      "inv_pd_eff_pout":[750.0, 1500.0, 2250.0, 3000.0, 3750.0, 4500.0, 5250.0, 6000.0, 6750.0, 7500.0, 11666.667, 15833.333, 20000.0, 24166.667, 28333.333, 32500.0, 36666.667, 40833.333, 45000.0, 56666.667, 68333.333, 80000.0, 91666.667, 103333.333, 115000.0, 126666.667, 138333.333, 150000.0],
      "inv_pd_efficiency":[0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16, 98.92999999999999, 98.7],
      "inv_pd_pacokw":1500,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1500,
      "inverterRating":1500,
      "maxEff":99,
      "mppt_hi_inverter":1300,
      "mppt_low_inverter":900,
      "pnom":1500,
      "v1_eff":[0],
      "v1_pl":[0.0],
      "v2_eff":[0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
      "v2_pl":[0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
      "v3_eff":[0],
      "v3_pl":[0.0],
      "vs_effeu":[],
      "vs_effmax":[],
      "vs_vnom":[]
    }
  },  
  "41f9b46758c94e1696e1efa2342a7c2d": {
    "id": "41f9b46758c94e1696e1efa2342a7c2d",
    "data":{
      "id":8,
      "default":1,
      "name":"Generic 2000kW",
      "derate_maxoutput":[2200.0, 2000.0, 1600.0, 0],
      "derate_temps":[35.0, 50.0, 55.0, 60.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[300.0, 1377.778, 2455.556, 3533.333, 4611.111, 5688.889, 6766.667, 7844.444, 8922.222, 10000.0, 15555.556, 21111.111, 26666.667, 32222.222, 37777.778, 43333.333, 48888.889, 54444.444, 60000.0, 76011.111, 92022.222, 108033.333, 124044.444, 140055.556, 156066.667, 172077.778, 188088.889, 204100.0],
      "inv_pd_efficiency":[0.0, 63.31, 78.83, 85.56, 89.17, 91.36, 92.80000000000001, 93.81, 94.55, 95.11, 96.63000000000001, 97.25, 97.58, 97.78, 97.91, 98.0, 98.07000000000001, 98.11999999999999, 98.16, 98.36, 98.39, 98.38, 98.33, 98.27, 98.21, 98.14, 98.06, 97.99],
      "inv_pd_pacokw":2200,
      "inv_pd_pnt":225,
      "inv_pd_vdcmax":1500,
      "inverterRating":2000,
      "maxEff":98.8,
      "mppt_hi_inverter":1500,
      "mppt_low_inverter":935,
      "pnom":2000,
      "v1_eff":[0.0, 0.96, 0.978, 0.985, 0.987, 0.987, 0.986, 0.984],
      "v1_pl":[0.15, 5.206, 10.226, 20.301, 30.398, 50.66, 76.093, 101.671],
      "v2_eff":[0.0, 0.952, 0.973, 0.982, 0.983, 0.984, 0.982, 0.98],
      "v2_pl":[0.15, 5.252, 10.277, 20.367, 30.506, 50.813, 76.35, 102.05],
      "v3_eff":[0.0, 0.944, 0.968, 0.979, 0.981, 0.982, 0.981, 0.979],
      "v3_pl":[0.15, 5.297, 10.328, 20.437, 30.578, 50.915, 76.457, 102.159],
      "vs_effeu":[98.47, 98.12, 97.89],
      "vs_effmax":[98.7, 98.4, 98.2],
      "vs_vnom":[935.0, 1100.0, 1250.0]
    }
  },  
  "b292ae77a9894169bf4a688ea0e2b4f3": {
    "id": "b292ae77a9894169bf4a688ea0e2b4f3",
    "data":{
      "id":9,
      "default":1,
      "name":"Generic 2500kW",
      "derate_maxoutput":[2500.0, 2500.0, 2250.0, 0],
      "derate_temps":[25.0, 35.0, 50.0, 60.0],
      "derate_toggle":1,
      "inv_pd_eff_pout":[200.0, 1566.667, 2933.333, 4300.0, 5666.667, 7033.333, 8400.0, 9766.667, 11133.333, 12500.0, 19444.444, 26388.889, 33333.333, 40277.778, 47222.222, 54166.667, 61111.111, 68055.556, 75000.0, 95046.089, 115092.178, 135138.267, 155184.356, 175230.444, 195276.533, 215322.622, 235368.711, 255414.8],
      "inv_pd_efficiency":[0.0, 67.06, 80.28, 86.02, 89.17, 91.13, 92.45, 93.39, 94.1, 94.64, 96.16, 96.84, 97.21, 97.44, 97.6, 97.71, 97.78999999999999, 97.86, 97.91, 98.16, 98.19, 98.2, 98.16, 98.11999999999999, 98.08, 98.00999999999999, 97.95, 97.88],
      "inv_pd_pacokw":2500,
      "inv_pd_pnt":370,
      "inv_pd_vdcmax":1500,
      "inverterRating":2500,
      "maxEff":100,
      "mppt_hi_inverter":1425,
      "mppt_low_inverter":850,
      "pnom":2500,
      "v1_eff":[0.0, 0.965, 0.977, 0.985, 0.986, 0.985, 0.983, 0.981],
      "v1_pl":[0.08, 5.181, 10.24, 20.311, 30.438, 50.756, 76.282, 101.947],
      "v2_eff":[0.0, 0.948, 0.967, 0.979, 0.981, 0.982, 0.981, 0.979],
      "v2_pl":[0.08, 5.274, 10.338, 20.425, 30.572, 50.911, 76.46, 102.166],
      "v3_eff":[0.0, 0.941, 0.963, 0.977, 0.979, 0.981, 0.98, 0.978],
      "v3_pl":[0.08, 5.315, 10.383, 20.479, 30.634, 50.989, 76.531, 102.302],
      "vs_effeu":[98.31, 97.91, 97.71],
      "vs_effmax":[98.56, 98.21, 98.06],
      "vs_vnom":[850.0, 1138.0, 1283.0]
    }
  },  
  "20f2277556c24ceab93102e9616df644": {
    "id": "20f2277556c24ceab93102e9616df644",
    "data":{
      "id":10,
      "default":1,
      "name":"Generic 3000kW",
      "derate_maxoutput":[3000.0, 3000.0],
      "derate_temps":[25.0, 50.0],
      "derate_toggle":0,
      "inv_pd_eff_pout":[1500.0, 3000.0, 4500.0, 6000.0, 7500.0, 9000.0, 10500.0, 12000.0, 13500.0, 15000.0, 23333.333, 31666.667, 40000.0, 48333.333, 56666.667, 65000.0, 73333.333, 81666.667, 90000.0, 113333.333, 136666.667, 160000.0, 183333.333, 206666.667, 230000.0, 253333.333, 276666.667, 300000.0],
      "inv_pd_efficiency":[0.01, 56.56, 76.66, 85.6, 90.23, 92.89, 94.56, 95.67, 96.44, 96.99, 98.41, 98.88, 99.08, 99.19, 99.26, 99.3, 99.33, 99.35000000000001, 99.36, 99.89, 99.89, 99.89, 99.86, 99.62, 99.39, 99.16, 98.92999999999999, 98.7],
      "inv_pd_pacokw":3000,
      "inv_pd_pnt":0,
      "inv_pd_vdcmax":1500,
      "inverterRating":3000,
      "maxEff":99,
      "mppt_hi_inverter":1300,
      "mppt_low_inverter":900,
      "pnom":3000,
      "v1_eff":[0],
      "v1_pl":[0.0],
      "v2_eff":[0.0, 0.933, 0.963, 0.985, 0.996, 0.999, 0.999, 0.987],
      "v2_pl":[0.5, 3.0, 5.0, 10.0, 20.0, 30.0, 60.0, 100.0],
      "v3_eff":[0],
      "v3_pl":[0.0],
      "vs_effeu":[],
      "vs_effmax":[],
      "vs_vnom":[]
    }
  },  
}


export { InvertersDD }