import { inputsConstants } from "../_constants";
import { userService, siftService } from "../_services";
import { siftActions } from "./sift.actions";
import { formatEditableLayout } from "../../LayoutEditor/LayoutEditorHelper.js";

export const inputsActions = {
  select_preset,

  update_ui_state,
  update_bulk_ui_state,

  update_input,
  update_offset_type,
  update_input_by_id,
  update_bulk_inputs,

  // map inputs
  update_site_input,

  // bulk input actions
  reset_inputs_to_default,
  reset_weather,
  clear_topo_data,
  topo_complete,
  undoFeatures,
  redoFeatures,

  // Portal Specific
  toggle_sf_modal,
  submit_salesforce_capture,
  reset_sf_capture,
  edit_layout,
};

function select_preset(index, preset) {
  return {
    type: inputsConstants.SELECT_PRESET_BY_INDEX,
    index,
    preset,
  };
}
function update_ui_state(key, value) {
  return {
    type: inputsConstants.UPDATE_INPUT_UI_STATE,
    key,
    value,
  };
}
function update_bulk_ui_state(bulk_inputs) {
  return {
    type: inputsConstants.UPDATE_BULK_UI_STATE,
    bulk_inputs,
  };
}
function update_input(key, value) {
  return {
    type: inputsConstants.UPDATE_INPUT_BY_NAME,
    key,
    value,
  };
}
function update_offset_type(key, value) {
  return {
    type: inputsConstants.UPDATE_OFFSET_TYPE,
    key,
    value,
  };
}
function update_input_by_id(key, id, values) {
  return {
    type: inputsConstants.UPDATE_INPUT_BY_ID,
    key,
    id,
    values,
  };
}
function update_bulk_inputs(bulk_inputs) {
  return {
    type: inputsConstants.UPDATE_BULK_INPUTS,
    bulk_inputs,
  };
}
function update_site_input(key, value) {
  return {
    type: inputsConstants.UPDATE_SITE_INPUT,
    key,
    value,
  };
}
function reset_inputs_to_default() {
  return { type: inputsConstants.RESET_INPUTS_TO_DEFAULT };
}

function reset_weather() {
  return { type: inputsConstants.RESET_WEATHER };
}

function clear_topo_data(which_data = "all") {
  return {
    type: inputsConstants.CLEAR_TOPO_DATA,
    which_data,
  };
}

function topo_complete(results) {
  return {
    type: inputsConstants.TOPO_JOB_COMPLETE,
    results,
  };
}

function undoFeatures(undoState) {
  return {
    type: inputsConstants.UNDO_FEATURES,
    undoState,
  };
}

function redoFeatures(redoState) {
  return {
    type: inputsConstants.REDO_FEATURES,
    redoState,
  };
}

// PORTAL SPECIFIC actions

function toggle_sf_modal(key, value, download_type = undefined) {
  return { type: inputsConstants.TOGGLE_SF_MODAL, key, value, download_type };
}

function submit_salesforce_capture(download_type, url, project_id, bypass = false) {
  return (dispatch) => {
    if (!bypass) {
      dispatch(request());
      userService.log_salesforce_downlaod(download_type, url, project_id).then(
        (response) => {
          // console.log("reponse", response);
          dispatch(success());
        },
        (error) => {
          dispatch(failure());
        }
      );
    } else {
      dispatch(success());
    }
  };

  function request() {
    return { type: inputsConstants.SF_CAPTURE_REQUEST };
  }

  function success() {
    return { type: inputsConstants.SF_CAPTURE_SUCCESS };
  }

  function failure() {
    return { type: inputsConstants.SF_CAPTURE_RESET };
  }
}

function reset_sf_capture() {
  return { type: inputsConstants.SF_CAPTURE_RESET };
}

function edit_layout(key, value = undefined, layoutId = undefined, properties = undefined) {
  if (key == "get_editable_layout") {
    return (dispatch) => {
      // dispatch(request());

      siftService.getEditableLayout(layoutId).then(
        (response) => {
          // dispatch(success(response));
          // dispatch(alertActions.success('Automatic Layout Drawn'));
          siftService.downloadFromS3(response).then((_response) => {
            let formated_layout = formatEditableLayout(_response, layoutId, properties);
            if (formated_layout) {
              dispatch(success("add_edited_layout", formated_layout, layoutId));
            }
          });
        },
        (error) => {
          dispatch(failure(error.toString()));
          // dispatch(alertActions.error(error.toString()));
        }
      );
    };
    function request() {
      return { type: inputsConstants.GET_EDITABLE_LAYOUT_REQUEST };
    }
    function success(key, value = undefined, layoutId = undefined) {
      return { type: inputsConstants.EDIT_LAYOUT, key, value, layoutId };
    }
    function failure(error) {
      return { type: siftActions.GET_EDITABLE_LAYOUT_FAILURE, error };
    }
  } else {
    return { type: inputsConstants.EDIT_LAYOUT, key, value, layoutId };
  }
}
