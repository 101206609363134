import React, { useState, useEffect, useRef, Fragment } from "react";
import { MapContainer, LayerGroup, GeoJSON } from "react-leaflet";

import { MapTileLayer, MapZoomHandler, MapEventHandler } from "../../../Map/components";
import { ShadeLayer, VisualShadeArea } from "../";
import { getBounds } from "../../..";

//styles
import { ReportMapWrap } from "./styles";
// redux
import { useSelector } from "react-redux";
import { ImageEventHandler, ImageLayer, LiveReportImageLayer } from "../../../Map/components/ImageLayer";

const LiveCanopyReportMap = ({ canopyId, interactiveMap, setRef, getRef, setCanopyMapRefs }) => {
  const canopies = useSelector((state) => state.canopy.canopies);
  const map_center = useSelector((state) => state.inputs.map_center);

  //shade stuff
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const shade_results = useSelector((state) => state.canopy.shade_results);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [refresh, setRefresh] = useState();
  const [map, setMap] = useState(null);

  const canopyReportMap = useRef();
  // const canopies = useRef({});

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  useEffect(() => {
    if (!map) return;
    if (interactiveMap) {
      setCanopyMapRefs("mainMap", map);
    } else {
      setCanopyMapRefs(canopyId, map);
    }
    setTimeout(() => {
      handleLiveCanopyZoomExtents();
    }, 500);
  }, [map]);

  const handleCanopyZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleLiveCanopyZoomExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(canopies).length == 0) return;
    // define canopy feature array
    let allCanopyFeatures = [];
    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(canopies).map((canopy) => {
      allCanopyFeatures.push(canopy.geoJson);
    });

    // get the bounds of the features collection
    let bounds = getBounds(allCanopyFeatures);

    if (interactiveMap) {
      if (zoomLevel) {
        map.fitBounds(
          [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ],
          { maxZoom: zoomLevel }
        );
      } else {
        map.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    } else {
      if (zoomLevel) {
        map.fitBounds(
          [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ],
          { maxZoom: zoomLevel }
        );
      } else {
        map.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    }
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <MapContainer
        // whenCreated={setMap}
        whenReady={(map) => setMap(map.target)}
        id={interactiveMap ? "mainMap" : `${canopyId}`}
        key={interactiveMap ? "mainMap" : `${canopyId}`}
        className={interactiveMap ? "mainMap" : `${canopyId}`}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={map_center}
        zoom={zoom}
        minZoom={2}
        maxZoom={20}
        onzoomend={(e) => interactiveMap && console.log("e zoomend", e)}
        zoomControl={false}
        scrollWheelZoom={interactiveMap}
        interactive={interactiveMap}
        dragging={interactiveMap}
        zoomSnap={0.25}
        zoomDelta={0.25}
        style={{ width: interactiveMap ? "650px" : "435px", height: interactiveMap ? "475px" : "300px" }}
      >
        <MapTileLayer liveReportMap={true} />
        <LiveReportImageLayer platform={"/canopy"} map={map} />
        {/* <ImageLayer is_interactive={false} /> */}

        <MapZoomHandler position="topright" />
        <MapEventHandler />

        {/* {shade_objects && shade_results && (
          <>
            <ShadeLayer interactive={false} />
            <VisualShadeArea interactive={false} />
          </>
        )} */}

        {Object.values(canopies).length > 0 && (
          <LayerGroup>
            {Object.values(canopies).map((canopy) => {
              return (
                <Fragment key={`frag_${canopy.id}`}>
                  {Object.values(canopy.geoJson).length > 0 && (
                    <GeoJSON
                      shape="Rectangle"
                      style={{
                        color: canopy.id == canopyId ? "red" : "var(--canopy-color)",
                        fillColor: canopy.id == canopyId ? "red" : "var(--canopy-color)",
                        fillOpacity: 0.2,
                        interactive: false,
                      }}
                      transform={false}
                      draggable={false}
                      scaling={false}
                      data={canopy.geoJson}
                      key={`cell_id_(${canopy.id})`}
                    />
                  )}
                  {interactiveMap &&
                    canopy.editCellsGeoJson.length > 0 &&
                    canopy.editCellsGeoJson.map((cell, index) => (
                      <GeoJSON
                        shape="Rectangle"
                        style={{
                          fillColor: cell.properties.enabled ? "#ffffff" : "none",
                          fillOpacity: 0.1,
                          weight: 1,
                          color: cell.properties.enabled ? (cell.properties.override_color ? cell.properties.override_color : "#666") : "none",
                        }}
                        transform={false}
                        draggable={false}
                        scaling={false}
                        data={cell}
                        key={`cell_${canopy.id}-${index}`}
                      />
                    ))}
                </Fragment>
              );
            })}
          </LayerGroup>
        )}
      </MapContainer>
    </ReportMapWrap>
  );
};

export { LiveCanopyReportMap };
