//import { useDispatch } from "react-redux";
import { inputsConstants, siftConstants, alertConstants } from "../_constants";
import { authHeader } from "../_helpers";
//import * as turf from "@turf/turf";
import config from "config";

import { execute_job, poll_job, download_from_s3, execute_sift_run } from "./basics.jobs";
import { fetch_weather, get_ele_tz } from "./weather-fetch.jobs";
import { siftService } from "../_services";

import { collect_shade_inputs } from "../../EnvironmentalShade";
import { run_shade_job } from "./canopy.job.js";
import fetchUtil from "../_services/fetchUtil";

export const run_job = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "job_id",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux
    async run(dispatch) {
      inputs.job_id = undefined;
      inputs.env = config.env;
      if (inputs.images) {
        inputs.images = undefined;
      }
      // this triggers the UI state to be in a running state
      dispatch({ type: siftConstants.GET_RUNID_REQUEST });
      // ensures the report is reset and the old report isn't shown when running one project after another.
      dispatch({ type: siftConstants.UPDATE_REPORT_DATA, key: "reset" });

      if (!inputs.download_lock) {
        dispatch({ type: inputsConstants.UPDATE_INPUT_UI_STATE, key: "download_lock", value: true });
      }

      if (inputs.weather_id === undefined) {
        let weather_fetch_inputs = {
          weather_source: inputs.weather_source,
          site_features: inputs.site_features,
          elevation: inputs.elevation,
          timezone: inputs.timezone,
        };
        let weather_data = await fetch_weather(weather_fetch_inputs, "sift-init").run(dispatch);
        console.log("weather_data", weather_data);
        if (weather_data.error) {
          return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: weather_data });
        } else {
          // not sure if we truly need both but just covering base for now
          inputs.weather = weather_data.job_id;
          inputs.weather_id = weather_data.job_id;
          inputs.latitude = weather_data.latitude;
          inputs.longitude = weather_data.longitude;
          inputs.elevation = weather_data.elevation;
          inputs.timezone = weather_data.timezone;
          inputs.weather_source = weather_data.source;
        }
      } else {
        inputs.weather = inputs.weather_id;

        // get ele/tz
        let ele_tz = await get_ele_tz([inputs.latitude, inputs.longitude]);
        if (ele_tz.data.ele.status === "OK") inputs.elevation = ele_tz.data.ele.results[0].elevation;
        if (ele_tz.data.tz.status === "OK") inputs.timezone = ele_tz.data.tz.rawOffset / 3600;
        if (ele_tz.data.loc.status === "OK") inputs.location = ele_tz.data.loc.results[0].formatted_address;
      }

      // run some checks to correct anything we notice is wrong
      // Shouldn't be used a lot, but can be a stop-gap till a better
      // solution is developed and implemented
      if (inputs.do_finance === 0) {
        inputs.analysis_period = 1;
      }
      if (!inputs.location_obj && inputs.latitude && inputs.longitude) {
        let loc_obj_res = await siftService.getElevation([inputs.latitude, inputs.longitude]);
        let loc_obj;

        if (loc_obj_res.data.loc.results.length > 0) {
          loc_obj = loc_obj_res.data.loc.results[0]?.address_components
            ? {
                city: loc_obj_res.data.loc.results[0]?.address_components[0]?.long_name,
                state: loc_obj_res.data.loc.results[0]?.address_components[1]?.long_name,
                country: loc_obj_res.data.loc.results[0]?.address_components[2]?.long_name,
              }
            : undefined;
        }

        inputs.location_obj = loc_obj;
      }

      if (inputs.en_shade_impact == 1 && Object.values(inputs.shade_results).length > 0) {
        inputs["env_shade_id"] = inputs.shade_results.env_shade_id;
      } else if (inputs.en_shade_impact == 1 && Object.values(inputs.shade_results).length == 0 && Object.values(inputs.shade_objects).length > 0) {
        let shade_options = {
          latitude: inputs.map_center[0],
          longitude: inputs.map_center[1],
          elevation: 1,
          timeZone: inputs.timezone,
          shade_height_unit: inputs.shade_height_unit,
          shadow_day: "Jan 1 2021",
          type: "ground",
        };

        let shade_inputs = collect_shade_inputs(undefined, inputs.shade_objects, shade_options);
        console.log("shade job inputs", shade_inputs);
        let shade_resp = await run_shade_job(shade_inputs).run(dispatch);
        if (shade_resp.error) {
          console.log("Shade simulation error:", shade_resp.error);
        } else {
          inputs["env_shade_id"] = shade_resp.env_shade_id;
        }
      }

      // if (inputs.shade_objects && Object.values(inputs.shade_objects).length > 0) {
      //   console.log("run shade simulation before running SIFT");
      //   let shade_inputs = collect_shade_inputs(undefined, inputs.shade_objects, inputs.map_center, "ground");

      //   let shade_resp = await run_shade_job(shade_inputs).run(dispatch);

      //   if (shade_resp.error) {
      //     console.log("Shade simulation error:", shade_resp.error);
      //   } else {
      //     inputs["env_shade_id"] = shade_resp.env_shade_id;
      //   }
      // }

      // Got weather now, lets init
      console.log("init run inputs", inputs);

      let job_inputs = {
        job_type: "sift-validation",
        // run_count: inputs['gcrs'].length * inputs['spis'].length
        run_count: 2,
      };
      let init_resp = await execute_job(job_inputs, "sift-validation");

      if (init_resp.error) {
        dispatch({ type: alertConstants.ERROR, message: init_resp.error }); //"SIFT Run has been cancelled."
        return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, failed: true, errors: init_resp.error });
      }

      dispatch({ type: siftConstants.RUN_CAN_CANCEL, can_cancel: true, job_id: init_resp.job_id });
      // console.log("init_resp", init_resp);
      inputs.job_id = init_resp.job_id;
      inputs.url = init_resp.url;
      inputs.job_type = "sift-validation";

      await execute_sift_run(inputs);

      let init_poll_resp = await poll_job(inputs, "sift-validation", dispatch);

      console.log(init_poll_resp);
      if (init_poll_resp.error && init_poll_resp.error.msg && init_poll_resp.error.msg === "Cancelled") {
        return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: init_poll_resp.error.msg });
      }
      let bad_error_msg = "There was an issue with this run. Check inputs. Consider sending a bug report if this continues to happen.";
      if (init_poll_resp.is_error) {
        return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: init_poll_resp });
        if ((init_poll_resp.errors && init_poll_resp.errors.length === 2) || init_poll_resp.error.msg === bad_error_msg) {
        }
      }

      // triggers new ui state for run
      dispatch({ type: siftConstants.GENERATE_RESULTS_VALIDATED });
      inputs.result_handler = 1;

      if (init_poll_resp.output.status == 100) {
        inputs.validation_job_id = inputs.job_id;
        if (inputs.simple_inverter === 1) {
          inputs.spis = [100];
        }
        inputs.job_id = undefined;
        job_inputs = {
          job_type: "sift-init",
          run_count: inputs["gcrs"].length * inputs["spis"].length,
        };

        let run_resp = await execute_job(job_inputs, "sift-init");
        console.log(run_resp);

        inputs.job_id = run_resp.job_id;
        inputs.url = run_resp.url;
        inputs.job_type = "sift-init";
        await execute_sift_run(inputs);

        // cloud scale complete -- layouts running
        dispatch({ type: siftConstants.RUN_SIFT_SUCCESS, job_id: run_resp.job_id });
        let run_poll_resp = await poll_job(inputs, "sift-init", dispatch);
        console.log("run_poll_resp", run_poll_resp);

        if (run_poll_resp.error && run_poll_resp.error.msg && run_poll_resp.error.msg === "Cancelled") {
          dispatch({ type: alertConstants.ERROR, message: run_poll_resp.error.msg }); //"SIFT Run has been cancelled."
          return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: run_poll_resp.error.msg });
        }
        dispatch({ type: siftConstants.RUN_CAN_CANCEL, can_cancel: false });

        if (run_poll_resp.error.msg === undefined && run_poll_resp.output.results.length > 0) {
          var layout_response = await get_layout(run_poll_resp.output.results[0].id);

          var download_json = await download_from_s3(layout_response);

          let response = { layout: download_json, record: run_poll_resp.output.results[0] };
          dispatch({ type: siftConstants.GET_LAYOUT_SUCCESS, response });
          // console.log(download_json)
          run_poll_resp.output.layout = download_json;
          run_poll_resp.output.meta = { ...inputs };
          // console.log('download_from_s3', pre_results)
          dispatch({ type: inputsConstants.UPDATE_INPUT_BY_NAME, key: "res_generated", value: true });
          return dispatch({ type: siftConstants.GENERATE_RESULTS_SUCCESS, data: run_poll_resp.output });
        } else {
          return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: run_poll_resp });
        }
      }
    },
  };
};

async function get_layout(id) {
  const requestOptions = {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetchUtil(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions);
}
