import { undoRedoConstants } from "../_constants";

const initialState = {
  past: [
    {
      features: {},
    },
  ],
  present: 0,
  future: [],
};

export function undoRedo(state = JSON.parse(JSON.stringify(initialState)), action) {
  let { past, present, future } = state;

  let thePast = JSON.parse(JSON.stringify(past));

  switch (action.type) {
    case undoRedoConstants.STORE_FEATURE_STATE:
      if (action.redux_action == "LOAD_PROJECT_COMPLETE") {
        // If Project is being loaded, we can override the state with the project being
        // the first state that no one can go before.
        return {
          past: [
            {
              features: { ...action.pastFeatureSet.features },
            },
          ],
          present: 0,
          future: [],
        };
      }

      if (action.redux_action == "NEW_PROJECT_REQUEST") {
        // If New button hit, we want to reset state back to default so we can't undo back into a previous project or whatnot
        return {
          past: [
            {
              features: {},
            },
          ],
          present: 0,
          future: [],
        };
      }

      let newState = {
        features: { ...action.pastFeatureSet.features },
        // selectedFeatureId: action.pastFeatureSet.selectedFeatureId,
      };
      if (past.length >= 25) {
        past.shift();
      }

      past.push(newState);

      thePast = JSON.parse(JSON.stringify(past));

      present = past.length - 1;
      // console.log(present)
      future = [];
      return {
        past: thePast,
        present,
        future,
      };

    case undoRedoConstants.UNDO:
      thePast = JSON.parse(JSON.stringify(past));
      // let previous = present > 0 ? present - 1 : undefined;
      // changing this so the farthest back we can go is always 0, which is our initial state (when loading a project, we override that initial state)
      let previous = Math.max(present - 1, 0);

      if (previous == 0 && present == 0) {
        // already at the start of the state, just return state
        return { ...state };
      }

      let tempFuture;
      if (present == 0 && future[0] == 0) {
        tempFuture = [...future];
      } else {
        tempFuture = [present, ...future];
      }

      return {
        past: thePast,
        present: previous,
        future: tempFuture,
      };

    case undoRedoConstants.REDO:
      thePast = JSON.parse(JSON.stringify(past));

      let next;
      if (future.length) {
        next = future[0];
      } else {
        next = present;
      }
      let newFuture = future.slice(1);

      return {
        past: thePast,
        present: next,
        future: newFuture,
      };

    default:
      return state;
  }
}
