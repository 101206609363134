import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LayoutEditorWrap } from "./styles";
import { LayoutEditorMap, LayoutLayer, FeatureLayer } from "./";

const LayoutEditor = () => {
  const dispatch = useDispatch();

  const edited_layouts = useSelector((state) => state.inputs.edited_layouts);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  if (!show_layout_editor) return null;

  return (
    <LayoutEditorWrap>
      <LayoutEditorMap>
        {show_layout_editor && <LayoutLayer />}
        <FeatureLayer />
      </LayoutEditorMap>
    </LayoutEditorWrap>
  );
};

export { LayoutEditor };
