import styled from "styled-components";

const ButtonGroupWrapper = styled.section`
  display: flex;
  align-items: center;
  margin: 0px 3px;

  span {
    display: flex;
  }

  button {
    margin: 0px;
    border-radius: 0px;
    padding: 5px 10px;
    z-index: 0;

    &:hover {
      z-index: 1;
    }
  }

  button:not(:first-child) {
    /* border-left: none; */
    margin-left: -1px;
    z-index: 0;

    &:hover {
      z-index: 1;
    }
  }

  button:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  button:last-child {
    border-radius: 0px 4px 4px 0px;
    margin-left: -1px;
  }
`;

export { ButtonGroupWrapper };
