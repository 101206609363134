import React, { useState, useEffect } from "react";

import { DrawTree, DrawTreeLine, DrawBuilding } from "../ShadeDrawing";

const ShadeDrawingLayers = () => {
  return (
    <>
      <DrawTree />
      <DrawTreeLine />
      <DrawBuilding />
    </>
  );
};

export { ShadeDrawingLayers };
