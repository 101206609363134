import styled from "styled-components";

const ShadingInputsWrap = styled.section`
  width: 100%;
  padding: 10px;
`;

const HorizonShadingWrap = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center start;
  margin: 15px 0px;
`;

export { ShadingInputsWrap, HorizonShadingWrap };
