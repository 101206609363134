import React from "react";
import { TitleDividerLayout } from "./style";

const TitleDivider = ({ title, collapseable = false, toggle_var = undefined, toggle_fn = undefined, subHeader = false }) => {
  return (
    <TitleDividerLayout collapseable={collapseable} toggle_var={toggle_var} subHeader={subHeader} onClick={() => (toggle_var == true || toggle_var == false) && toggle_fn && toggle_fn(!toggle_var)}>
      {collapseable && (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="https://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 15.0026C0.5 15.7724 1.33333 16.2535 2 15.8686L14 8.94037C14.6667 8.55547 14.6667 7.59322 14 7.20832L2 0.28012C1.33333 -0.10478 0.5 0.376344 0.5 1.14614V1.56746C0.5 1.92472 0.690599 2.25485 1 2.43348L9.27027 7.20832C9.93694 7.59322 9.93694 8.55547 9.27027 8.94037L1 13.7152C0.690599 13.8938 0.5 14.224 0.5 14.5812L0.5 15.0026Z"
            fill="var(--primary-brand-color)"
          />
        </svg>
      )}
      <p>{title || ""}</p>
    </TitleDividerLayout>
  );
};

export { TitleDivider };
