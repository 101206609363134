export const jobsActions = {
  execute_job,
  run_topo_job,
};

export function run_topo_job(job) {
  //   console.log("job", job);
  return (dispatch) => {
    dispatch(job.request());

    // console.log("job.inputs", job.inputs);

    job.get_job_id(job.inputs).then((response) => {
      // console.log("response", response);
      job.job_id = response[job.job_string];

      job.poll_job(job).then((results) => {
        if (job.pre_complete) {
          job.pre_complete(results).then((pre_results) => {
            // console.log(pre_results)
            dispatch(job.complete(pre_results));
          });
        } else {
          dispatch(job.complete(results));
        }
      });
    });
  };
}

export function execute_job(job) {
  // console.log("The Job", job);
  return (dispatch) => {
    job.run(dispatch);
    // dispatch (job.request());
    // // console.log(job.inputs)
    // job.get_job_id(job.inputs)
    // 	.then(response => {
    // 		// console.log(response)
    // 		job.job_id = response[job.job_string]
    // 		job.poll_job(job)
    // 			.then(results => {
    // 				if (job.pre_complete) {
    // 					job.pre_complete(results)
    // 						.then(pre_results => {
    // 							// console.log(pre_results)
    // 							dispatch(job.complete(pre_results))
    // 						})
    // 				} else {
    // 					dispatch(job.complete(results))
    // 				}
    // 			})
    // })
  };
}

export async function execute_shade_job(job) {
  // console.log("The Job", job);
  return (dispatch) => {
    job.run(dispatch);
  };
}

// request -- updates the redux store for ui state
// get_job_id -- gets a job_id, and url* (run_id) and optionally sends inputs
// upload_to_s3 -- if you don't send inputs when getting job_id, this step uploads them
// poll_job_id -- polls for status update on job and optionally the outputs
// download_from_s3 -- optionally downloads results from s3
// complete -- configures the output to be easily spreadable into store and alerts user (succes or failures)
