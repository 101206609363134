import styled from "styled-components";

const ConfigWrapper = styled.section`
  .spinner {
    width: 100%;
    height: 100%;
		min-height: 150px;
    display: grid;
    place-items: center;
		.ant-spin-text {
			font-size: 12px;
		}
  }

  .config-inputs {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(8, auto);

    .config-err {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
      p {
        margin: 0px;
        font-size: 12px;
      }
      span {
        margin-right: 3px;
      }
    }

    .config-header {
      padding: 5px 10px;
    }

    .config-input-label {
      width: 100%;
      padding: 5px 10px;

      span {
        margin: 0 2px;
      }

      & > .ant-radio-group,
      label {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          margin-left: 2px;
        }
      }
    }

    .config-input.error {
      grid-template-columns: 100%;
    }
    .config-input {
      width: 100%;
      display: grid;
      margin-top: 5px;
      grid-template-columns: 1fr min-content 1fr min-content 1fr min-content;
      place-items: center;

      .min {
        grid-column: 1;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }

      .dash {
        grid-column: 2;
        height: 100%;

        span {
          font-size: 16px;
        }
      }

      .max {
        grid-column: 3;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
      .comma {
        grid-column: 4;
        height: 100%;
        margin-top: 10px;

        span {
          font-size: 16px;
        }
      }

      .increment {
        grid-column: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
      }
    }

    .total {
      grid-column: 6;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      p {
        margin: 0px;
      }
    }

    /* .total {
      margin-top: 5px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0 10px;
      span {
        margin-left: 5px;
      }
    } */
    .spi-total {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      .spi-count,
      .dc-ac {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .config-row {
      margin-top: 15px;
      width: 100%;
      display: grid;
      padding-right: 10px;
      color: #333;
      grid-template-columns: 1fr min-content;
     
      p {
        margin: 0;
        font-size: 16px;
      }

      .config-limit {
        display: grid;

        p {
          margin-left: auto;
          font-size: 16px;
        }

        .underline {
          margin-left: auto;
          display: grid;
          place-items: end;
          margin: 2px;
          font-size: 16px;
  
          &:after {
            content: "";
            display: block !important;
            height: 1px !important;
            width: 70px !important;
            background: #444 !important;
          }
        }
      }

      .config-info-msg {
        display: grid;
        width:100%;
        place-items: center;
        p {
          font-size: 12px;
        }
      
      
      }
    }

    .spacer {
      width: 50px;
      height: 2px;
      margin: 15px auto;
      border-radius: 2px;
      opacity: 0.7;
      background-color: var(--primary-brand-color);
    }

    .config-divider {
        width: 95%;
        height:1px;
        background-color:var(--gutter-background-color) ;
        margin: 10px auto 0px 10px;
    }
    
    .config-input-switch {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr min-content;
        place-items: center self-start;
        padding: 15px 10px;
        box-sizing: border-box;
    }

    .config-three-col {
        display: grid;
    grid-template-columns: 50% 12% 38%;
    padding: 10px;
    margin-top: 8px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: 0px 5px 0px auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
    }
    


`;

export { ConfigWrapper };
