import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import config from "config";
// antd
import { Button } from "../../../BaseComponents";
import { Modal, Empty } from "antd";
import { DownOutlined, RightOutlined, UpOutlined, CaretRightOutlined, CaretLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { results_icon } from "../../../../assets/images";
import { InputHeader } from "../../../InputPanel/InputHeader";
import { ErrorFromSIFT } from "../../../InputPanel/ResultTab";
import { ModuleInputs } from "../../../InputPanel";
import { CanopySelect } from "./CanopySelect";
import { CanopyInputs } from "./CanopyInputs";
import { ShadeInputs } from "../../../EnvironmentalShade/ShadeInputs";
import { useAuth } from "../../../Auth/AuthProvider";

//images and icons
import { module_icon } from "../../../../assets/images";

import { canopy_job, execute_job, siftActions, tutorialActions, authActions, isAuthenticated, canopyActions } from "../../../Redux";
import { getCenterPoint, isNumber } from "../../../Redux/_helpers";

// css
// import './inputs.css'
// import "./datagrid.css";
// import { inverter_icon, layout_icon, module_icon, perf_icon, racking_icon, topo_icon, weather_icon, finance_icon, results_icon, config_icon } from "../../assets/images";
import { InputPanelWrapper } from "../../../InputPanel/StyledComponents";

function getGCRs(min, max, inc) {
  if (!isNumber(min) || !isNumber(max) || !isNumber(inc)) {
    return [0.5];
  }
  let gcrs = [];
  for (let gcr = min; gcr < max; gcr += inc) {
    gcrs.push(gcr);
    if (gcrs.length >= 51) break;
  }
  gcrs.push(parseFloat(max));
  return gcrs;
}

function getTilts(min, max, inc) {
  if (!isNumber(min) || !isNumber(max) || !isNumber(inc)) {
    return [25];
  }
  let tilts = [];
  for (let tilt = min; tilt < max; tilt += inc) {
    tilts.push(tilt);
    if (tilts.length >= 20) break;
  }
  tilts.push(parseFloat(max));
  return tilts;
}

const CanopyInputPanel = () => {
  const { is_authenticated } = useAuth();
  const projects_loading = useSelector((state) => state.projects.loading);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const canopies = useSelector((state) => state.canopy.canopies);

  const show_agreement_modal = useSelector((state) => state.user.show_agreement_modal);
  const show_terms = useSelector((state) => state.user.show_terms);

  const inputs = useSelector((state) => state.inputs);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);

  const sift_inputs = useSelector((state) => state.sift.ioManager.inputs);

  const current_plan = useSelector((state) => state.user.product_plan_paid);

  const [selectedTab, setSelectedTab] = useState("products");

  const siftRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const siftCanCancel = useSelector((state) => state.sift.ioManager.outputs.runState.can_cancel);
  const siftCanceling = useSelector((state) => state.sift.ioManager.outputs.runState.canceling);
  const runId = useSelector((state) => state.sift.ioManager.outputs.runState.runId);
  const job_id = useSelector((state) => state.sift.ioManager.outputs.job_id);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  const show_canopy_results = useSelector((state) => state.canopy.show_canopy_results);
  const canopy_results_generated = useSelector((state) => state.canopy.canopy_results_generated);
  const running_canopy = useSelector((state) => state.canopy.running_canopy);
  const boundary_exists = Object.values(sift_inputs.map.features).findIndex((feature) => feature.properties.identity == 1) >= 0;
  const input_changed_warning = useSelector((state) => state.canopy.input_changed_warning);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const shade_results = useSelector((state) => state.canopy.shade_results);

  const output_detail_visible = useSelector((state) => state.sift.ioManager.uiState.output_detail_visible);
  const toggleMobileInputs = useSelector((state) => state.sift.ioManager.uiState.toggleMobileInputs);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  const is_simplified = useSelector((state) => state.inputs.is_simplified);

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  const [isComplete, setIsComplete] = useState(false);
  const [canopy_select_tab_vis, set_canopy_select_tab_vis] = useState(false);
  const [canopy_input_tab_vis, set_canopy_input_tab_vis] = useState(false);
  const [shade_objects_inputs_vis, set_shade_objects_inputs_vis] = useState(false);

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      setIsComplete(true);
    }
  }, [outputs.results]);

  // using this to open and close tabs and accordians depending on what step the tutorial is at.
  useEffect(() => {
    if (tutorial.tutorialPath === 0) {
      switch (tutorial.currentIndex) {
        case 1:
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 2:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 3:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 4:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 5:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 6:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 7:
          if (selectedTab !== "performance") setSelectedTab("performance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 8:
          if (selectedTab !== "performance") setSelectedTab("performance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 9:
          if (selectedTab !== "finance") setSelectedTab("finance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          dispatch(siftActions.updateFinanceToggle(true));
          break;
        case 10:
          if (selectedTab !== "site") setSelectedTab("site");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          dispatch(siftActions.updateFinanceToggle(false));
          break;
        case 12:
          if (selectedTab !== "site") setSelectedTab("site");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 13:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 14:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 15:
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        default:
          break;
      }
    } else {
      switch (tutorial.currentIndex) {
        case 0:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 1:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 2:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 3:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 4:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
      }
    }
  }, [tutorial.currentIndex]);

  useEffect(() => {
    if (Object.values(outputs.errors).length > 0) {
      Modal.error({
        title: "",
        content: <ErrorFromSIFT error_messages={outputs.errors} />,
        footer: null,
        width: "50%",
        className: "sift-modal-error",
        style: { maxWidth: "1300px" },
        icon: null,
        maskClosable: false,
        // destroyOnClose: true,
        okText: "Close",
        okType: "ghost",
        // onCancel: () => dispatch(siftActions.clearErrors()),
        onOk: () => dispatch(siftActions.clearErrors()),
      });
    } else {
      Modal.destroyAll();
    }
  }, [outputs.errors]);

  function onLogin() {
    dispatch(authActions.toggleLoginVis(true, "login"));
  }

  function onCancelSIFT() {
    if (tutorial.tutorialActive) {
      dispatch(tutorialActions.resetTutorial());
    }
    dispatch(siftActions.cancelRun(job_id));
  }

  function onUpdateTab(_tab) {
    let new_tab = _tab == tab ? undefined : _tab;
    dispatch(siftActions.updateTab(new_tab));
  }

  function init_canopy() {
    // console.log("init_canopy: canopies", canopies);
    // convert to swm inputs
    // let swm_inputs = convert_to_swm_canopy(carport.rectangles);
    if (Object.values(canopies).length === 0) return;
    // if canopy has already generated results and someone clicks Run Canopy againse, clear the results
    if (canopy_results_generated) {
      dispatch(canopyActions.updateCanopyInputs("clear_canopy_results"));
    }

    let center = undefined;
    let features = [];
    Object.values(canopies).forEach((canopy) => features.push(canopy.geoJson));

    // var featCollection = {
    // 	type: "FeatureCollection",
    // 	features: features,
    // };

    let centeroid = getCenterPoint(features);

    // let centeroid = turf.getCoords(turf.centroid(featCollection));
    // dispatch(canopy.getCounty(centeroid[1], centeroid[0]));

    center = [centeroid[1], centeroid[0]];

    let new_canopies = {};
    Object.values(canopies).map((canopy) => {
      new_canopies[canopy.id] = {
        ...canopy,
        // editCellsGeoJson: carport.visual[canopy.properties.index].modules,
      };
    });

    // let swm_inputs = convert_to_swm_canopy(new_canopies, center);
    // console.log("swm_inputs", swm_inputs);

    // console.log(centeroid, new_canopies, default_inputs())
    // if there are shade results, send them to perf
    let shade_results_id;
    if (shade_results?.shade_results_id) {
      shade_results_id = shade_results.shade_results_id;
    }
    let canopy_inputs = { canopy_ids: Object.keys(canopies), center, weather_id: undefined, shade_results_id, shade_objects };

    // return
    // init run in the backend
    // console.log("canopy_inputs", canopy_inputs);
    // dispatch(execute_job(run_canopy(swm_inputs)));
    dispatch(execute_job(canopy_job(canopy_inputs, canopies)));
    // dispatch(canopyActions.initResults(swm_inputs));
  }

  return (
    <InputPanelWrapper internal_sift={internal_sift} canopy_panel={true}>
      {/* MODULE */}
      <div className="input-manager">
        <div className="input-collapse">
          <div className="collapse-item">
            <div className={`collapse-header ${tab === "module" && "selected"}`} id="module" onClick={(e) => onUpdateTab(e.target.id)}>
              <div className="collapse-header-text" onClick={(e) => onUpdateTab("module")}>
                <img src={module_icon} alt="" />
                <span>Module</span>
              </div>
              <span className="collapse-arrow">{tab === "module" ? <DownOutlined /> : <RightOutlined />}</span>
            </div>
            {tab === "module" && (
              <div className="collapse-content-container">
                <div className="input-panel module">
                  <InputHeader header_type="mod" />
                  <ModuleInputs />
                </div>
              </div>
            )}
          </div>
          {/* CANOPIES  */}
          <div className="collapse-item">
            <div
              className={`collapse-header ${tab === "canopies" && "selected"}`}
              id="canopies"
              onClick={(e) => {
                set_canopy_select_tab_vis(!canopy_select_tab_vis);
                onUpdateTab(e.target.id);
              }}
            >
              <div
                className="collapse-header-text"
                onClick={(e) => {
                  set_canopy_select_tab_vis(!canopy_select_tab_vis);
                  onUpdateTab("canopies");
                }}
              >
                <img src={module_icon} alt="" />
                <span>Canopies</span>
              </div>
              <span className="collapse-arrow">{tab === "canopies" ? <DownOutlined /> : <RightOutlined />}</span>
            </div>
            {canopy_select_tab_vis && (
              <div className="collapse-content-container">
                <div className="input-panel module">
                  {Object.values(canopies).length > 0 ? (
                    <CanopySelect />
                  ) : (
                    <div className="canopy-inputs-empty-box">
                      <Empty description="Use the draw tools to create a canopy" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* CANOPY INPUTS */}
          <div className="collapse-item">
            <div
              className={`collapse-header ${tab === "canopy_inputs" && "selected"}`}
              id="canopy_inputs"
              onClick={(e) => {
                set_canopy_input_tab_vis(!canopy_input_tab_vis);
                onUpdateTab(e.target.id);
              }}
            >
              <div
                className="collapse-header-text"
                onClick={(e) => {
                  set_canopy_input_tab_vis(!canopy_input_tab_vis);
                  onUpdateTab("canopy_inputs");
                }}
              >
                <img src={module_icon} alt="" />
                <span>Canopy Inputs</span>
              </div>
              <span className="collapse-arrow">{tab === "canopy_inputs" ? <DownOutlined /> : <RightOutlined />}</span>
            </div>
            {canopy_input_tab_vis && (
              <div className="collapse-content-container">
                <div className="input-panel module">
                  {selected_canopy && canopies[selected_canopy] ? (
                    <CanopyInputs />
                  ) : (
                    <div className="canopy-inputs-empty-box">
                      <Empty description="Please Select a Canopy" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {config.env !== "prod" && (
            <div className="collapse-item">
              <div
                className={`collapse-header ${tab === "shade_objects_inputs" && "selected"}`}
                id="shade_objects_inputs"
                onClick={(e) => {
                  set_shade_objects_inputs_vis(!shade_objects_inputs_vis);
                  onUpdateTab(e.target.id);
                }}
              >
                <div
                  className="collapse-header-text"
                  onClick={(e) => {
                    set_shade_objects_inputs_vis(!shade_objects_inputs_vis);
                    onUpdateTab("shade_objects_inputs");
                  }}
                >
                  <img src={module_icon} alt="" />
                  <span>Environmental Shade</span>
                </div>
                <span className="collapse-arrow">{tab === "shade_objects_inputs" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {shade_objects_inputs_vis && (
                <div className="collapse-content-container">
                  <div className="input-panel module">
                    <ShadeInputs />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {input_changed_warning && (
        <section className="input-changed-warning">
          <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "14px", marginTop: 5 }} />
          <p>Inputs have changed since last results were generated</p>
        </section>
      )}

      <section className="generate-buttons-container">
        {canopy_results_generated && !show_canopy_results && (
          <div className="input-collapse canopy-res-accordian">
            <div className="collapse-item">
              <div className="collapse-content-container results"></div>
              <div className={`collapse-header ${show_canopy_results && "selected"}`} onClick={() => dispatch(canopyActions.updateCanopyInputs("show_canopy_results", !show_canopy_results))}>
                <div className="collapse-header-text">
                  <img src={results_icon} />
                  <span>/Canopy Results</span>
                </div>
                <span className="collapse-arrow">{show_canopy_results ? <RightOutlined /> : <UpOutlined />}</span>
              </div>
            </div>
          </div>
        )}

        <div className="run-button">
          <Button
            // block
            id="run-tip"
            type={running_canopy ? "ghost" : "primary"}
            style={{ fontWeight: "700", fontSize: "16px" }}
            // htmlType="submit"
            onClick={() => (!is_authenticated ? onLogin() : running_canopy ? onCancelSIFT() : init_canopy())}
            // loading={running_canopy}
            disabled={!is_authenticated || show_agreement_modal || show_terms || projects_loading || Object.values(canopies).length === 0}
            loading={siftCanceling || running_canopy || (!siftCanCancel && running_canopy)}
          >
            {!siftCanCancel && running_canopy ? (
              "PREPARING RUN"
            ) : siftCanCancel && running_canopy ? (
              "CANCEL RUN"
            ) : siftCanceling ? (
              "CANCELING RUN"
            ) : (
              <div className="run-button-content">
                <span>RUN CANOPY</span>
                <CaretRightOutlined style={{ fontSize: "18px" }} />
              </div>
            )}
          </Button>
        </div>
      </section>

      <button className="toggle-inputs-button" onClick={() => dispatch(siftActions.toggleMobileInputs(!toggleMobileInputs))}>
        {toggleMobileInputs ? <CaretLeftOutlined style={{ fontSize: "30px" }} /> : <CaretRightOutlined style={{ fontSize: "30px" }} />}
      </button>
    </InputPanelWrapper>
  );
};

export { CanopyInputPanel };
