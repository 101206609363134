import React, { useEffect, Fragment } from "react";
import { usePDF } from "@react-pdf/renderer";
import { CanopyReport, ThirtyPercentReport } from "./Report";

const DownloadCanopyPDF = (props) => {
  const [instance, updateInstance] = usePDF({
    document: props.mode === "canopy" ? <CanopyReport data={props.data} /> : <ThirtyPercentReport data={props.data} />,
  });

  useEffect(() => {
    if (instance.error) {
      console.log("💥💥 Something went wrong with PDF Generation 💥💥", instance.error);
    } else if (!instance.loading && instance.url) {
      openLink(instance.url);
      props.reset_report();
      // props.set_report_generated(false);
      // dispatch(canopyActions.updateCanopyInputs("show_percent_report", !show_percent_report));
    }
  }, [instance.loading, instance.url]);

  useEffect(() => {
    return () => {};
  }, []);

  const openLink = (url) => {
    let fileName = _.isEmpty(props.project_name) ? "Solar Project" : props.project_name;
    let downloadLink = document.createElement("a");

    downloadLink.href = url;
    downloadLink.download = `${fileName}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // window.open(url, "_blank", "noopener,noreferrer");
  };

  return <Fragment>{props.children}</Fragment>;
};

export { DownloadCanopyPDF };
