import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ts_logo_hori } from "../assets/images";

const SIFTHeader_New = () => {
  return (
    <section className="header-area">
      <section className="logo">
        <Link to="/">
          <img src={ts_logo_hori} alt="Terrsmart Logo" />
        </Link>
      </section>
      <section className="sift-link">
        <Link to="/sift">SIFT</Link>
      </section>
    </section>
  );
};

export { SIFTHeader_New };
