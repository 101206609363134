import React, { useState, useEffect } from "react";
import config from "config";

import { useSelector } from "react-redux";

import { MapContainer } from "react-leaflet";

//components
import { MapTileLayer } from "../MapTileLayer";
import { MapEventHandler } from "../MapEventHandler";
import { MapZoomHandler } from "../Toolbar/MapZoomHandler";
import { MapFeatureHandler } from "../MapFeatureHandler";
import { Toolbar } from "../Toolbar";
import { ChangeMapView } from "../ChangeMapView/ChangeMapView";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import { MapLayoutLayer } from "../MapLayoutLayer/MapLayoutLayer";
import { MapMessageBox } from "../MapMessageBox";
import { MapMarker } from "../MapMarker/MapMarker";
import { CoordSystemBox } from "../CoordSystemBox";
import { ImageLayer } from "../ImageLayer";
// import { Tooltip } from "../../../BaseComponents";
import { ShadeLayer, ShadeDrawingLayers, ShadeObjectMarker } from "../../../EnvironmentalShade";
import { VisualShadeArea } from "../../../EnvironmentalShade";
import { Canopy } from "../../../_Internal/Canopy";
//styles
import "../../leaflet.css";

const Map = () => {
  const map_center = useSelector((state) => state.inputs.map_center);
  const url = window.location.pathname;

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  // const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  const [pathname, set_pathname] = useState(window.location.pathname);

  useEffect(() => {
    set_pathname(url);
  }, [url]);

  return (
    <MapContainer
      id="map"
      key="main_map"
      center={map_center}
      zoom={16}
      zoomSnap={0.25}
      // zoomSnap={zoomGranularity}
      // zoomDelta={zoomGranularity}
      animate={true}
      doubleClickZoom={false}
      zoomControl={false}
      worldCopyJump={true}
      attributionControl={false}
      minZoom={2}
      maxZoom={18}

      // preferCanvas={using_canopy ? false : true}
    >
      <ChangeMapView map_center={map_center} zoom={16} />
      <MapZoomHandler position="topright" />

      {internal_sift && (pathname == "/canopy" || pathname == "/canopy/") ? (
        <>
          <Canopy />
        </>
      ) : (
        <>
          <Toolbar position="topleft" />
          <MapFeatureHandler />
          <MapLayoutLayer />
          <ContextMenu />
          <CoordSystemBox />
          <ShadeDrawingLayers />
          <ShadeLayer />
          <ShadeObjectMarker />
          <VisualShadeArea />
        </>
      )}
      <ImageLayer pathname={pathname} />
      <MapTileLayer />
      <MapEventHandler />
      <MapMarker />
    </MapContainer>
  );
};

export { Map };
