import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber } from "../../../../../Redux";

import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;

import { InputRow } from "./styles";
// import { debounce } from '../../../utils'
import { debouncedUpdateinput } from "./inputs.helper";

function ModulesLengthInput() {
  const dispatch = useDispatch();

  // const modX = useSelector((state) => state.carport.selectedInputs.modX);
  // const base_modX = useSelector((state) => state.carport.selectedInputs.base_dimension.modX);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const modX = useSelector((state) => state.canopy.canopies[selected_canopy]?.modX);
  const base_modX = useSelector((state) => state.canopy.canopies[selected_canopy]?.base_dimension?.modX);

  const [local_modX, set_local_modX] = useState(modX);
  const [local_loading, set_local_loading] = useState(false);
  const [modX_error, set_modX_error] = useState(undefined);

  useEffect(() => {
    if (modX && modX == local_modX) {
      set_local_loading(false);
    } else if (!local_loading) {
      set_local_modX(modX);
    }
  }, [modX]);

  function onChangeInput(e) {
    // debounced method
    let changed_value = parseInt(e.target.value);
    set_local_modX(changed_value);

    if (!isNumber(changed_value)) {
      set_modX_error("Invalid Input");
      return;
    } else if (changed_value < base_modX) {
      set_modX_error("Must be bigger than base modX");
      return;
    } else if (modX_error != undefined) {
      set_modX_error(undefined);
    }
    set_local_loading(true);

    debouncedUpdateinput.cancel();
    debouncedUpdateinput(dispatch, selected_canopy, "modX", changed_value);
  }

  return (
    <InputRow>
      <label>Modules Long:</label>
      <Input
        size="small"
        value={local_modX}
        type="number"
        step={1}
        min={0}
        suffix={local_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>}
        max="999"
        onChange={onChangeInput}
      ></Input>
    </InputRow>
  );
}

export { ModulesLengthInput };
