import React from "react";

import "./info.css";

import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

// base
const Info = ({ text }) => {
  return (
    <Tooltip overlayClassName={"tooltip-custom"} title={text} tplacement="topleft">
      <InfoCircleOutlined />
    </Tooltip>
  );
};

// export default Info;

// *********** project tooltips **********
const Info_50MW = () => {
  return (
    <Info
      text={
        <div>
          <strong>Projects up to 100MW</strong>
          <p>Projects in SIFT are limited by MWac capacity.</p>
        </div>
      }
    />
  );
};
const Info_Topo = () => {
  return (
    <Info
      text={
        <div>
          <strong>Topography</strong>
          <p>Automatically import elevation data from the U.S. Geological Survey(US Only) or Google(global). SIFT uses imported elevation data to create slope analysis layers.</p>
          <p>Instantly assess your site's topographic features and decide which products are suitable.</p>
        </div>
      }
    />
  );
};
const Info_TopoAnalysis = () => {
  return (
    <Info
      text={
        <div>
          <strong>Topography</strong>
          <p>Import elevation automatically from USGS (North America) or Google (Global).</p>
        </div>
      }
    />
  );
};
const Info_TopoSlope = () => {
  return (
    <Info
      text={
        <div>
          <strong>Topography & Slope Analysis</strong>
          <p>Import elevation automatically from USGS (North America) or Google (Global). Use SIFT to create slope analysis layers.</p>
        </div>
      }
    />
  );
};
const Info_50runs = () => {
  return (
    <Info
      text={
        <div>
          <strong>Up to 20 SIFT runs per month</strong>
          <p>Each SIFT run creates a layout, performance, cost, and financial model for up to 500 unique GCR and DC:AC configurations.</p>
          <p>The Starter plan is limited to 20 runs per month.</p>
        </div>
      }
    />
  );
};
const Info_customModInv = () => {
  return (
    <Info
      text={
        <div>
          <strong>Custom Modules and Inverters</strong>
          <p>Import any module or inverter using a .PAN or .OND specification file. You can also import directly from PVSyst or manually enter module and inverter specs</p>
        </div>
      }
    />
  );
};
const Info_customModInvWeath = () => {
  return (
    <Info
      text={
        <div>
          <strong>Custom Modules, Inverters, Weather</strong>
          <p>Import modules and inverters with PAN and OND files. Import weather files by dragging and dropping common file types.</p>
        </div>
      }
    />
  );
};
const Info_1000MW = () => {
  return (
    <Info
      text={
        <div>
          <strong>Projects up to 1000MW</strong>
          <p>
            Projects in SIFT are limited by boundary area. This plan allows boundaries up to 2000 hectares(~5000 acres, 30000亩). This is enough area for a 1000MWac single-axis project with common
            wattage modules and typical DC:AC ratios.
          </p>
          <p>Boundary area is shown on the SIFT map.</p>
        </div>
      }
    />
  );
};
const Info_Core = () => {
  return (
    <Info
      text={
        <div>
          <strong>Core SIFT Features</strong>
          <ul>
            <li>Up to 500 unique configurations per run.</li>
            <li>Export Results to CSV.</li>
            <li>Import and Export KMZ.</li>
            <li>Import weather Data from NSRDB or NASA.</li>
            <li>PVsyst-like Performance Modeling.</li>
            <li>Financial Models for LCOE.</li>
            <li>Generic Modules and Inverters.</li>
            <li>Customizable GFT, SAT, and EWF Racking.</li>
          </ul>
        </div>
      }
    />
  );
};
const Info_CustomWeather = () => {
  return (
    <Info
      text={
        <div>
          <strong>Custom Weather</strong>
          <p>Import custom weather data from your preferred meteorological sources. SIFT supports any 8760 with DNI, DHI, GHI, Temperature, and Wind Speed data.</p>
        </div>
      }
    />
  );
};
const Info_AdvFin = () => {
  return (
    <Info
      text={
        <div>
          <strong>Advanced Financial Models</strong>
          <p>The Advanced financial model calculates IRR while factoring in debt, ITC, power based incentives, depreciation, taxes, and more. Full financial tables are available to download.</p>
          <p>LCOE financial calculations are available to all users.</p>
        </div>
      }
    />
  );
};
const Info_AdvFinMod = () => {
  return (
    <Info
      text={
        <div>
          <strong>Advanced Financial Models</strong>
          <p>
            Calculate Internal Rate of Return (IRR). Full financial model includes costs, revenue streams, debt sizing, depreciation, ITC, TOU, and more. SIFT runs with advanced financial models also
            generate complete financial tables for each result.
          </p>
        </div>
      }
    />
  );
};
const Info_UnlimRuns = () => {
  return (
    <Info
      text={
        <div>
          <strong>Unlimited* SIFT runs per months</strong>
          <p>Each SIFT run creates a layout, perfomance, cost, and financial model for up to 500 unique GCR and DC:AC configurations. Users pay a fixed monthly fee for unlimited* SIFT use.</p>
          <p>* All users have a soft limit of 500 runs per month to preven misuse. If this limit is reached, the SIFT team can increase your run allowance.</p>
        </div>
      }
    />
  );
};

const Info_CAD = () => {
  return (
    <Info
      text={
        <div>
          <strong>Export CAD</strong>
          <p>
            File is georeferenced according to selected coordinate system and includes boundary, exclusion, racking, and other polygons on unique layers. If topography is available, the elevation
            point cloud is exported to CAD for surface generation
          </p>
        </div>
      }
    />
  );
};
const Info_PDF = () => {
  return (
    <Info
      text={
        <div>
          <strong>Export PDF</strong>
          <p>For a single configuration, customize and export a PDF with layouts, graphs, product specifications, performance diagrams, financial models, and more</p>
        </div>
      }
    />
  );
};
const Info_Collab = () => {
  return (
    <Info
      text={
        <div>
          <strong>Collaborate</strong>
          <p>
            Share and collaborate on projects with other users. A standard Check In, Check Out system ensures data integrity. Users can add project notes and send update notifications to the
            collaboration group.
            {/* If topography is loaded, elevation data is included for you to create a surface in Civil3D or similar program */}
          </p>
        </div>
      }
    />
  );
};

export {
  Info_50MW,
  Info_Topo,
  Info_50runs,
  Info_customModInv,
  Info_1000MW,
  Info_Core,
  Info_CustomWeather,
  Info_AdvFin,
  Info_UnlimRuns,
  Info_CAD,
  Info_Collab,
  Info,
  Info_customModInvWeath,
  Info_TopoAnalysis,
  Info_TopoSlope,
  Info_AdvFinMod,
  Info_PDF,
};
