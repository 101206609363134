import React, { Fragment, useState, useEffect } from "react";
import { Page, Text, View, Image, Document, StyleSheet, Svg, Path } from "@react-pdf/renderer";

import { TableRows } from "./TableRows";

// Create styles
const styles = StyleSheet.create({
  table: {
    marginVertical: 10,
  },
  infoContainer: {
    // flexDirection: 'row',
    // flexGrow: 1,
    marginHorizontal: 20,
    height: 40,
    borderTopColor: "#333",
    borderTopWidth: 2,
    borderLeftColor: "#333",
    borderLeftWidth: 2,
    borderRightColor: "#333",
    borderRightWidth: 2,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  infoBox: {
    flexGrow: 8,
    // backgroundColor: '#f2f2f2',
    height: 40,
    // paddingVertical: 5,
  },
  infoHeader: {
    fontSize: 10,
    marginVertical: 7.5,
    marginHorizontal: 2,
    textAlign: "center",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
    height: 40,
    // backgroundColor: '#f2f2f2',
  },

  small: {
    flexGrow: 8,
    height: 40,
    width: 45,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  medium: {
    flexGrow: 8,
    height: 40,
    width: 65,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  large: {
    flexGrow: 8,
    height: 40,
    width: 95,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  end: {
    flexGrow: 8,
    height: 40,
    width: 65,
  },
});

// Create Document Component
const SummaryTable = (props) => {
  const checkForInverted = () => {
    let invertedPresent = false;
    props.data.map((data) => {
      if (data["Canopy Type"] == "inverted") {
        invertedPresent = true;
      }
    });
    return invertedPresent;
  };
  return (
    <View style={styles.table}>
      <View style={styles.infoContainer}>
        {/* row 1 */}
        <View style={styles.row}>
          <View style={styles.medium}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Canopy Name</Text>
            </View>
          </View>
          <View style={styles.medium}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Module</Text>
            </View>
          </View>
          <View style={styles.medium}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Canopy Type</Text>
            </View>
          </View>
          <View style={styles.small}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Azimuth</Text>
            </View>
          </View>
          <View style={styles.small}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>{checkForInverted() ? `Tilt (°) To/From` : `Tilt (°)`}</Text>
            </View>
          </View>
          <View style={styles.large}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Dimensions (LxW) Qty</Text>
            </View>
          </View>
          <View style={styles.large}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Dimensions (LxW) Ft</Text>
            </View>
          </View>
          <View style={styles.end}>
            <View style={styles.row}>
              <Text style={styles.infoHeader}>Module Qty</Text>
            </View>
          </View>
        </View>
      </View>

      <TableRows data={props.data} />
    </View>
  );
};

export { SummaryTable };
