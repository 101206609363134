import styled from "styled-components";

const RefreshTokenWrap = styled.section`
  position: absolute;
  inset: 0;
  padding: 15px;
  margin: auto;
  width: 300px;
  height: min-content;
  background: #fff;
  z-index: 606;
  border-radius: 2px;
  border: 1px solid var(--primary-brand-color);
  box-sizing: border-box;

  h3 {
    margin: 0px 0px 10px 0px;
  }

  .reload-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 8px;
      background: #fff;
      color: var(--primary-brand-color);
      border: 1px solid var(--primary-brand-color);
      border-radius: 2px;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
`;

export { RefreshTokenWrap };
