import React, { Fragment } from "react";
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet, Svg, Font } from "@react-pdf/renderer";

import { ts_logo_hori } from "../../../../../assets/images";

import thirtyPercentBg from "../ReportImages/thirtyPercentBg.jpg";
import thirtyPercentBg2 from "../ReportImages/thirtyPercentBg2.jpg";
import thirtyPercentBg3 from "../ReportImages/thirtyPercentBg3.jpg";
import thirtyPercentBg4 from "../ReportImages/thirtyPercentBg4.jpg";
import northIndicator from "../ReportImages/northIndicator.png";

import { SummaryHeader } from "./SummaryHeader";

import { SummaryTable } from "./SummaryTable";
import { SummaryDetail } from "./SummaryDetail";

const sampleData = [
  { Name: "Slope 1", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 2", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 3", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 4", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Perimeter", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 1", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 2", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 3", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 4", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
];

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 44,
    paddingBottom: 65,
  },
  date: {
    position: "absolute",
    fontSize: 12,
    top: 15,
    right: 15,
    color: "grey",
  },

  logo: {
    marginHorizontal: 212,
    width: 176,
  },
  header: {
    color: "#333",
    textAlign: "center",
    marginTop: 7.5,
  },
  topImage: {
    marginVertical: 15,
    marginHorizontal: 20,
    height: 300,
  },
  overviewImageContainer: {
    left: 0,
    right: 0,
    top: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginVertical: 15,
    width: 550,
    height: 385,
  },
  overviewImage: {
    width: "100%",
    height: "100%",
  },
  bySunfig: {
    position: "absolute",
    height: 10,
    right: 20,
    bottom: 20,
  },

  infoContainer: {
    flexGrow: 1,
    marginHorizontal: 20,
  },
  infoBox: {
    flexGrow: 5,
    backgroundColor: "#f2f2f2",
    height: 40,
  },
  infoImage: {
    width: 25,
    marginHorizontal: 10,
    marginVertical: 7.5,
  },
  infoHeader: {
    fontSize: 10,
    textAlign: "justify",
    marginVertical: 2.5,
  },
  infoText: {
    fontSize: 8,
    textAlign: "justify",
    marginVertical: 2.5,
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    height: 40,
  },

  fill1: {
    flexGrow: 5,
    backgroundColor: "#e14427",
    height: 70,
  },
  fill2: {
    flexGrow: 5,
    backgroundColor: "#e6672d",
    height: 70,
  },
  fill3: {
    flexGrow: 5,
    backgroundColor: "#e78632",
    height: 70,
  },
  fill4: {
    flexGrow: 5,
    backgroundColor: "#e29e37",
    height: 70,
  },
  fill5: {
    flexGrow: 5,
    backgroundColor: "#f2f2f2",
    height: 70,
  },

  leftInfo: {
    // flexGrow: 1,
    width: 45,
    // backgroundColor: '#f22332',
    backgroundColor: "#f2f2f2",
    height: 40,
  },
  rightInfo: {
    // flexGrow: 1,
    // backgroundColor: '#f212f2',
    height: 40,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  thirty_percent_container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  thirt_percent_bg: {
    width: "100%",
    height: "100%",
  },
  vicinityMapContainer: {
    position: "absolute",
    height: 485,
    width: 475,
    top: 335,
    left: 300,
  },
  customer_name_container: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 130,
  },
  customer_title: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 190,
  },
  address_one: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 230,
  },
  address_two: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 250,
  },
  customer_name_right: {
    position: "absolute",
    transform: "rotate(-90deg)",
    width: 190,
    height: 40,
    textAlign: "center",
    top: 360,
    left: 1065,
  },
  customer_title_right: {
    position: "absolute",
    width: 90,
    textAlign: "center",
    top: 680,
    left: 1085,
  },
  customer_title_text: {
    fontSize: 8,
    marginBottom: 3,
  },
  job_number: {
    position: "absolute",
    width: 80,
    textAlign: "center",
    top: 733,
    left: 1085,
  },
  drawn_by: {
    position: "absolute",
    width: 20,
    textAlign: "center",
    top: 751,
    left: 1085,
  },
  reviewed_by: {
    position: "absolute",
    width: 20,
    textAlign: "center",
    top: 751,
    left: 1120,
  },
  array_type: {
    position: "absolute",
    fontSize: 7,
    bottom: 26,
    left: 85,
    color: "#333",
  },
  active_pv_modules: {
    position: "absolute",
    fontSize: 7,
    bottom: 38,
    left: 220,
    color: "#333",
  },
  active_rating: {
    position: "absolute",
    fontSize: 7,
    bottom: 38,
    left: 250,
    color: "#333",
  },
  mod_qty_container: {
    position: "absolute",
    fontSize: 7,
    bottom: 26,
    left: 220,
    color: "#333",
  },
  total_watts: {
    position: "absolute",
    fontSize: 7,
    bottom: 20,
    left: 220,
    color: "#333",
  },
  thirty_percent_layout_map_container: {
    position: "absolute",
    height: 755,
    width: 1035,
    top: 40,
    left: 35,
  },
  thirty_percent_map_image: {
    height: "100%",
    width: "100%",
  },
  north_indicator_container: {
    position: "absolute",
    height: 30,
    width: 35,
    bottom: 50,
    right: 130,
    borderRadius: 2,
    opacity: 0.7,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  north_indicator_img: {
    height: 25,
    width: 30,
    borderRadius: 2,
    opacity: 0.7,
  },
});

// Create Document Component
const CanopyReport = (props) => (
  console.log("props", props),
  (
    <Document title={`${props.data.overview.project ? props.data.overview.project : "Solar Canopy"}`}>
      {/* ruler verticalRulerSteps="10%" horizontalRulerSteps="10%" */}
      <Page size="A4" style={styles.body}>
        {/* top fixed */}
        <Text style={styles.date} fixed>{`${props.data.date}`}</Text>
        {/* content */}
        <Image style={styles.logo} src={ts_logo_hori} />
        <Text style={styles.header}>Canopy Design Summary{`: ${props.data.overview.project ? props.data.overview.project : "Solar Canopy"}`}</Text>

        <View style={styles.overviewImageContainer}>{props.data.images["mainMap"] && <Image src={props.data.images["mainMap"]} style={styles.overviewImage} />}</View>

        {/* summary header */}
        <SummaryHeader overview={props.data.overview} />
        {/* summary table */}
        <SummaryTable data={Object.values(props.data.results)} />
        {/* summary detail list */}
        <SummaryDetail results={props.data.results} images={props.data.images} />
        {/* bottom fixed */}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>
    </Document>
  )
);

const ThirtyPercentReport = (props) => {
  return (
    <Document>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg} style={styles.thirt_percent_bg} />
          <View style={styles.vicinityMapContainer}>{props.data.images["vicinityMap"] && <Image src={props.data.images["vicinityMap"]} style={styles.overviewImage} />}</View>
          <View style={styles.customer_name_container}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title}>{props.data.thirtyPercentInputs?.customer_at && <Text>{props.data.thirtyPercentInputs?.customer_at}</Text>}</View>
          <View style={styles.address_one}>{props.data.thirtyPercentInputs?.address_one && <Text>{props.data.thirtyPercentInputs?.address_one}</Text>}</View>
          <View style={styles.address_two}>{props.data.thirtyPercentInputs?.address_two && <Text>{props.data.thirtyPercentInputs?.address_two}</Text>}</View>
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
          <View style={styles.array_type}>{props.data.thirtyPercentInputs?.array_type && <Text>{props.data.thirtyPercentInputs?.array_type}</Text>}</View>
          <View style={styles.active_pv_modules}>{props.data.overview.mod_qty && <Text>{props.data.overview.mod_qty}</Text>}</View>
          <View style={styles.active_rating}>{props.data.overview.rating && <Text>{props.data.overview.rating}</Text>}</View>
          <View style={styles.mod_qty_container}>{props.data.overview.mod_qty && <Text>{props.data.overview.mod_qty}</Text>}</View>
          <View style={styles.total_watts}>{props.data.overview.capacity && <Text>{props.data.overview.mod_qty * props.data.overview.rating}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg2} style={styles.thirt_percent_bg} />
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg3} style={styles.thirt_percent_bg} />
          <View style={styles.thirty_percent_layout_map_container}>
            {props.data.images["thirtyPercentLayoutMap"] && <Image src={props.data.images["thirtyPercentLayoutMap"]} style={styles.thirty_percent_map_image} />}
          </View>
          <View style={styles.north_indicator_container}>
            <Image src={northIndicator} style={styles.north_indicator_img} />
          </View>
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg4} style={styles.thirt_percent_bg} />
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
    </Document>
  );
};

const Report = (props) => (
  <PDFViewer style={{ width: "100%", height: "1200px" }}>
    <GroundMountReport props />
  </PDFViewer>
);

export { Report, CanopyReport, ThirtyPercentReport };
