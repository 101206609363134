import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";

import { inputsActions } from "../../../../Redux";
import { draw_polyline, square, square_green } from "../../../../../assets/images";
import { useKey } from "../../MapEventHandler/useKey";

import L from "leaflet";
// import "../../../leaflet.draw.css";
import { Tooltip } from "antd";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const getTouchIconBase = (platform) => {
  let touch_icon_base;
  if (platform == "internal_sift") {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square_green,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  } else {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  }

  return touch_icon_base;
};

const DrawPolyline = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const platform = useSelector((state) => state.user.platform);
  const is_drawing_polyline = useSelector((state) => state.inputs.uiState.is_drawing_polyline);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);

  const [current_polyline, set_current_polyline] = useState(null);

  useMapEvents({
    cancelDrawLine: () => {
      if (is_drawing_polyline) cancelDraw();
    },
    startDrawingPolyLine: () => drawPolyline(),
  });

  const drawPolyline = () => {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 5,
      },

      icon: getTouchIconBase(platform),
    };

    let polyline = new L.Draw.Polyline(map, options);
    // map.pm.enableDraw("Line", {
    //   snappable: true,
    //   snapDistance: 20,
    //   templineStyle: options,
    //   pathOptions: options,
    //   tooltips: false,
    // });

    if (is_drawing_polyline) {
      cancelDraw();
    } else {
      set_current_polyline(polyline);
      polyline.enable();
      dispatch(inputsActions.update_ui_state("is_drawing_polyline", true));
      if (is_drawing) map.fire("cancelDraw");
    }
  };

  const cancelDraw = () => {
    dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
    current_polyline.disable();
    map.pm.disableDraw();
    set_current_polyline(null);
  };

  return (
    <Tooltip placement="bottom" title="Draw" mouseEnterDelay={0.5}>
      <button className={is_drawing_polyline ? "button-active" : ""} onClick={() => drawPolyline()}>
        <img draggable={false} src={draw_polyline} />
      </button>
    </Tooltip>
  );
};

export { DrawPolyline };
