const WeatherDD = {
  0: {
    "id": 0,
    "data": {
      "weather_id": 0,
      "import": 0,
      "upload": 1,
      "name": "Automatic",
      "default": 2,
      "pull_loc_from_map": 1,
      "search_lat": 0,
      "search_lng": 0,
      "search_ele": 0,
      "search_tz": 0,
      "source": 1,
      "lat": 0,
      "lng": 0,
      "ele": 0,
      "tz": 0,
      "county": "",
      "state": "",
    }
  }  
}

export { WeatherDD }