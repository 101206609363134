import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import { canopyActions, usePrevious } from "../../../Redux";

import { rotate_canopy, drag_canopy, scale_canopy, duplicate_canopy, flip_coords } from "../CanopyHelpers";

const CanopyEventHandler = ({ id }) => {
  const map = useMap();
  const dispatch = useDispatch();

  // store
  const canopy = useSelector((state) => state.canopy.canopies[id]);
  const is_editing = useSelector((state) => state.canopy.is_editing);
  const is_dragging = useSelector((state) => state.canopy.is_dragging);
  const is_rotating = useSelector((state) => state.canopy.is_rotating);
  const is_drawing = useSelector((state) => state.canopy.is_drawing);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const prevSelectedCanopy = usePrevious(selected_canopy);

  const selected_canopy_ref = useRef();

  useMapEvents({
    moveCanopy: () => {
      if (canopy) {
        if (is_dragging) {
          _stopEditingCanopy(getLayer(selected_canopy_ref.current));
        } else {
          moveCanopy(getLayer(selected_canopy_ref.current));
        }
        dispatch(canopyActions.updateCanopyInputs("is_dragging", !is_dragging));
      }
    },
    rotateCanopy: () => {
      if (canopy) {
        if (is_rotating) {
          _stopEditingCanopy(getLayer(selected_canopy_ref.current));
        } else {
          rotateCanopy(getLayer(selected_canopy_ref.current));
        }
        dispatch(canopyActions.updateCanopyInputs("is_rotating", !is_rotating));
      }
    },
    editCanopy: () => {
      if (canopy) {
        if (is_editing) {
          _stopEditingCanopy(getLayer(selected_canopy_ref.current));
        } else {
          editCanopy(getLayer(selected_canopy_ref.current));
        }
        dispatch(canopyActions.updateCanopyInputs("is_editing", !is_editing));
      }
    },
    duplicateCanopy: ({ direction }) => {
      dup_canopy(direction);
    },
  });

  useEffect(() => {
    selected_canopy_ref.current = selected_canopy;
    if (prevSelectedCanopy == id) {
      _stopEditingCanopy(getLayer(id));
    }
    // if (prevSelectedCanopy == id && prevSelectedCanopy != selected_canopy_ref.current) {
    //   _stopEditingCanopy(getLayer(prevSelectedCanopy));
    // }
  }, [selected_canopy]);

  useEffect(() => {
    if (!canopy) return;

    // if this canopy changes at all, we need to remove the layer before adding it back
    map.eachLayer((layer) => {
      if (layer.options && (layer.options.id == canopy.id || layer.options.module_layer_id == canopy.id || layer.options.azi_layer_id == canopy.id)) {
        layer.remove();
      }
    });

    // let rect = L.geoJSON(canopy.geoJson, {
    //   ...canopy,
    //   style: {
    //     color: "var(--primary-brand-color)",
    //     fillColor: "var(--primary-brand-color)",
    //     fillOpacity: 0.2,
    //     interactive: true,
    //   },
    // });

    let bounds = L.geoJSON(canopy.geoJson).getBounds();

    let rect = new L.Rectangle(bounds, {
      ...canopy,
    });
    // console.log("rect", rect);

    rect.setStyle({
      stroke: "var(--canopy-color)",
      color: "var(--canopy-color)",
      fillColor: "var(--canopy-color)",
      fillOpacity: 0.2,
      weight: selected_canopy == id ? 2 : 1,
      interactive: true,
    });

    // console.log(flip_coords(canopy.geoJson).geometry.coordinates[0])
    // console.log(canopy.geoJson.geometry.coordinates[0])
    rect.setLatLngs(flip_coords(canopy.geoJson).geometry.coordinates[0]);
    rect.pm._setAngle(canopy.rotation);

    // console.log("new rect", rect);

    rect.addTo(map);

    rect.on("click", (e) => {
      if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
      if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      _startEditingCanopy(e.target);
    });

    rect.on("pm:edit", (e) => {
      // We only want this to run if the canopy is scaled.
      if (e.source === "Rotation" || e.layer.pm._layerDragged) return;
      _onCanopyScale(e);
      e.target.remove();
      removeTempLayers();
    });

    rect.on("pm:rotateend", (e) => {
      _onRotateEnd(e);
      e.target.remove();
      removeTempLayers();
    });

    rect.on("pm:dragend", (e) => {
      _onDragEnd(e);
      e.target.remove();
    });

    rect.on("pm:rotatestart", () => {
      removeUnnecessaryLayers();
    });

    rect.on("pm:dragstart", (e) => {
      removeUnnecessaryLayers();
    });

    // after adding the canopy back to the map, check to see which action was taken so that we can turn that canopy tool back on again
    if (selected_canopy_ref.current == canopy.id && canopy.action === "pm:dragend") {
      moveCanopy(getLayer(canopy.id));
    }
    if (selected_canopy_ref.current == canopy.id && canopy.action === "pm:rotateend") {
      rotateCanopy(getLayer(canopy.id));
    }
    if (selected_canopy_ref.current == canopy.id && canopy.action === "pm:edit") {
      editCanopy(getLayer(canopy.id));
    }
  }, [canopy]);

  const mapHasFocus = () => {
    return document.getElementById("map") == document.activeElement;
  };

  const removeUnnecessaryLayers = () => {
    map.eachLayer((layer) => {
      if (layer.feature && (layer.options.module_layer_id == id || layer.options.azi_layer_id == id || layer.options.cell_layer_id == id)) {
        layer.remove();
      }
    });
  };

  const removeTempLayers = () => {
    // temp layers are just layers used for things like rotating, editing and such. Within Canopy they are always handles used to minipulate the polygon. For some reason, even after toggling off whatever editing tool you are using, Geoman will leave the handles in the map. This just ensures they are removed.
    map.eachLayer((layer) => layer._pmTempLayer && layer.remove());
  };

  const _startEditingCanopy = (layer, id = undefined) => {
    if (!layer) return;
    layer.setStyle({ color: "var(--canopy-color)", weight: 2 });
    dispatch(canopyActions.updateCanopyInputs("selected_canopy", layer.options.id));
  };

  const _stopEditingCanopy = (layer) => {
    if (!layer) return;
    layer.setStyle({ fillOpacity: 0.2, weight: 1 });

    if (layer.pm.layerDragEnabled()) {
      layer.pm.disableLayerDrag();
    }
    if (layer.pm.rotateEnabled()) {
      layer.pm.disableRotate();
    }
    if (layer.pm.enabled()) {
      layer.pm.disable();
    }
    removeTempLayers();
  };

  const moveCanopy = (layer) => {
    // map.pm.enableGlobalDragMode();
    if (!layer) return;
    layer.pm.enableLayerDrag();
    // dispatch(canopyActions.updateCanopyInputs("is_dragging", ));
  };

  const rotateCanopy = (layer) => {
    // map.pm.enableGlobalRotateMode();
    if (!layer) return;
    layer.pm.enableRotate();
  };

  const editCanopy = (layer) => {
    // map.pm.enableGlobalEditMode();
    if (!layer) return;
    layer.pm.enable();
  };

  const _onDragEnd = (event) => {
    if (!event) return;
    let event_geoJson = event.layer.toGeoJSON(9);
    let canopy_inputs = event.layer.options;
    canopy_inputs.action = event.type;
    let dragged_canopy = drag_canopy(event_geoJson, canopy_inputs);
    dragged_canopy.changed_from_inputs = false;
    dispatch(canopyActions.updateCanopyInputs("edit_canopy", dragged_canopy));
  };

  const _onRotateEnd = (event) => {
    if (!event) return;
    let event_geoJson = event.layer.toGeoJSON(9);
    let canopy_inputs = event.layer.options;
    let provided_rotation = event.angle;
    // console.log("rotation event angle", event.angle);
    canopy_inputs.action = event.type;
    let rotated_canopy = rotate_canopy(event_geoJson, canopy_inputs, provided_rotation);
    rotate_canopy.changed_from_inputs = false;
    dispatch(canopyActions.updateCanopyInputs("edit_canopy", rotated_canopy));
  };

  const _onCanopyScale = (event) => {
    if (!event) return;
    let event_geoJson = event.layer.toGeoJSON(9);
    let canopy_inputs = event.layer.options;
    canopy_inputs.action = event.type;
    let scaled_canopy = scale_canopy(event_geoJson, canopy_inputs);
    scaled_canopy.changed_from_inputs = false;
    dispatch(canopyActions.updateCanopyInputs("edit_canopy", scaled_canopy));
  };

  const dup_canopy = (direction) => {
    if (canopy.id !== selected_canopy_ref.current) return;
    let duplicated_canopy = duplicate_canopy(direction, canopy);
    dispatch(canopyActions.updateCanopyInputs("add_canopy", duplicated_canopy));
  };

  const getLayer = (id) => {
    let temp_layer = false;
    map.eachLayer((layer) => {
      if (layer.options?.shape === "Rectangle" && layer.options.id == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  return <></>;
};

export { CanopyEventHandler };
