import React, { Fragment } from 'react';

const getBoundaryTip = () => (
  <Fragment>
    <b>Starter</b> and <b>Standard</b> users can model sites with a Total Boundary Area up to 100 hectares (~250 acres, 50MWac). <b>Pro</b> users can model sites with a Total Boundary Area up to 2000
    hectares (~5000 acres, ~1GWac).
  </Fragment>
);

const getTopoTip = () => (
  <Fragment>
    Import elevation from USGS or Google and create slope analysis layers to guide project decisions. Topography tools are available to <b>Standard</b> and <b>Pro</b> users.
  </Fragment>
);

const getFinanceTip = () => (
  <Fragment>
    LCOE is available to all users. <b>Pro</b> users can calculate IRR and generate full financial tables. Finance calculations are optional.
  </Fragment>
);

const defaultTipsVisible = {
  default: false,
  help_docs: false,
  config_tip: false,
  define_boundaries: false,
  boundary_area: false,
  import_modules: false,
  import_inverter: false,
  racking_tip: false,
  weather_location: false,
  perf_params: false,
  finance_calcs: false,
  layout_inputs: false,
  topo_inputs: false,
  project_manager: false,
  bug_report: false,
  run_sift: false,
  result_table: false,
  download_CSV: false,
  click_result: false,
  result_tab: false,
  download_buttons: false,
};

const TourContent = {
  step_1: {
    main_text: 'Welcome to SIFT tour use Back/Next to navigate.',
    tip_text: {},
    visible_tips: {},
  },
  step_2: {
    main_text: 'See the help section for full SIFT documentation, tutorial videos, and more. For now, let’s continue the tour.',
    tip_text: {
      config_tip: 'Each SIFT run calculates the Yield, LCOE, and IRR for up to 500 configurations. Set ranges for GCR or Pitch and DC:AC here.',
    },
    visible_tips: {
      config_tip: true,
    },
  },
  step_3: {
    tip_text: {
      hotkey_instructions: 'Select polygons and define them using hotkeys [B] for Boundary, [E] for Exclusion, and [I] for Inactive.',
      inactive: `White polygons are Inactive, areas that will be ignored.`,
      exclusion: `Yellow polygons are Exclusions, area that will not be used.`,
      boundary: `Red polygons are Boundaries, buildable project areas.`,
    },
    visible_tips: {
      define_boundaries: true,
    },
  },
  step_4: {
    tip_text: {
      boundary_area: getBoundaryTip(),
    },
    visible_tips: {
      boundary_area: true,
    },
  },
  step_5: {
    tip_text: {
      import_modules: 'Import modules under the Products tab using a PAN file. Imported modules are saved and stored for later projects.',
    },
    visible_tips: {
      import_modules: true,
    },
  },
  step_6: {
    tip_text: {
      import_inverter: 'Importing an Inverter from an OND file works the same way.',
    },
    visible_tips: {
      import_inverter: true,
    },
  },
  step_7: {
    tip_text: {
      racking_tip: 'Select between Single-Axis Tracker, Ground Fixed Tilt, or East-West Fixed Tilt. Use generic dimensions or customize your own racking.',
    },
    visible_tips: {
      racking_tip: true,
    },
  },
  step_8: {
    tip_text: {
      weather_location: 'In the Weather tab, import data with a CSV/TMY file or request weather data through a connected service.',
    },
    visible_tips: {
      weather_location: true,
    },
  },
  step_9: {
    tip_text: {
      perf_params: 'Perfomance losses and parameters can be adjusted here.',
    },
    visible_tips: {
      perf_params: true,
    },
  },
  step_10: {
    tip_text: {
      finance_calcs: getFinanceTip(),
    },
    visible_tips: {
      finance_calcs: true,
    },
  },
  step_11: {
    tip_text: {
      layout_inputs: 'Specify layout options, interconnection limits, and nameplate targets in the Layout tab.',
    },
    visible_tips: {
      layout_inputs: true,
    },
  },
  step_12: {
    tip_text: {
      topo_inputs: getTopoTip(),
    },
    visible_tips: {
      topo_inputs: true,
    },
  },
  step_13: {
    tip_text: {
      project_manager: 'Save, Load, and Collaborate on projects.',
    },
    visible_tips: {
      project_manager: true,
    },
  },
  step_14: {
    tip_text: {
      help_docs: 'If you have questions, check out SIFT Help menu or contact us.',
    },
    visible_tips: {
      help_docs: true,
    },
  },
  step_15: {
    tip_text: {
      bug_report: 'If you run into any problems, click the Bug Report button. We will get back to you right away.',
    },
    visible_tips: {
      bug_report: true,
    },
  },

  step_16: {
    tip_text: {
      run_sift: 'Those are the basics. Click Run SIFT!',
    },
    visible_tips: {
      run_sift: true,
    },
  },
};

const ExampleContent = {
  step_1: {
    main_text: 'The example run is complete! Use Back/Next to navigate.',
    tip_text: {
      result_table: 'This is the Results panel. It shows every configuration run by SIFT.',
    },
    visible_tips: {
      result_table: true,
    },
  },
  step_2: {
    tip_text: {
      download_CSV: 'Download this list of results to CSV.',
    },
    visible_tips: {
      download_CSV: true,
    },
  },
  step_3: {
    tip_text: {
      click_result: 'Double-click a specific result in the table. That result will be plotted and a new tab will open. You can open as many tabs as you need.',
    },
    visible_tips: {
      click_result: true,
    },
  },
  step_4: {
    tip_text: {
      result_tab: 'Return to the Results table by clicking this tab.',
      download_buttons: 'For this specific result you can download a report, export to CAD, or export to KMZ.',
    },
    visible_tips: {
      result_tab: true,
      download_buttons: true,
    },
  },
  step_5: {
    tip_text: {
      help_docs: 'Questions? Open the SIFT Help menu at any time, or contact us.',
    },
    visible_tips: {
      help_docs: true,
    },
  },
};

export { defaultTipsVisible, TourContent, ExampleContent };
