import config from "config";
import { products } from "../../Funnel";
import { UserConstants, siftConstants } from "../_constants";
import { map_presets_1_8 } from "../../InputPanel/InputHeader/presets";
import { simple_module, simple_inverter } from "./sift_inputs";
import { TerraSmartProducts } from "../../InputPanel/_defaults";

let local_user = JSON.parse(localStorage.getItem("user")) || {};
const initialState = {
  access: {},
  token: {},
  version: config.version,

  loading: false, // are we loading account information?
  loaded: false, // do we have account information loaded?
  loggingIn: false,
  loggedIn: false,
  getting_user_data: true,
  registering: false,
  error: undefined, // was there an error retrieving account information?
  login_error: undefined,
  register_error: [],
  pw_reset_msg: undefined,
  login_visible: false,
  login_tab: "signup",
  refresh_visible: false,
  show_agreement_modal: false,
  show_terms: false,
  openedViaAgreegmentUpdate: false,
  downloading_invoice: false,
  current_plan: 1,
  funnel_visible: false,

  billing: [],
  usage: [],
  invoices: [],
  products: [],
  system_alerts: [],
  presets: {
    module: [],
    inverter: [],
    racking: [],
    weather: [],
    performance: [],
    finance: [],
    layout: [],
  },

  map_center: [34.6964398, -118.2827397],

  ...local_user,

  platform: config.platform,
};
// console.log(initialState);
export function user(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case UserConstants.LOGIN_REQUEST:
      // console.log(action);
      return { ...state, loggingIn: true, registering: false };
    case UserConstants.LOGIN_SUCCESS:
      console.log(action);
      const login_success_state = { ...state, ...action.user };

      // console.log(action);
      return { ...login_success_state };
    case UserConstants.CONTINUE_LOGIN:
      // console.log(action);
      return { ...state, ...action.user };

    case UserConstants.LOGIN_FAILURE:
      // console.log("LOGIN_FAILURE", action);
      let error = action.error;
      return {
        ...state,
        error: true,
        login_error: error,
        loggingIn: false,
      };

    case UserConstants.REGISTER_REQUEST:
      return { ...state, registering: true, register_error: [] };
    case UserConstants.REGISTER_SUCCESS:
      return { ...state, registering: false };
    case UserConstants.REGISTER_FAILURE:
      let email_errors = (action.error.email && action.error.email) || [];
      let password1_errors = (action.error.password1 && action.error.password1) || [];
      let reg_errors = [...email_errors, ...password1_errors];
      return { ...state, registering: false, loggingIn: false, register_error: reg_errors };

    case UserConstants.GET_USER_LIB_REQ:
      // console.log(action);

      return { ...state, getting_user_data: true };

    case UserConstants.GET_USER_LIB_COMP:
      // console.log("GET_USER_LIB_COMP", action);

      let new_user_data = { ...action.user_data };

      // updating products and plan restrictions
      new_user_data.products = products;

      const index = new_user_data.products.map((e) => e.id).indexOf(new_user_data.product_plan_paid);
      new_user_data.plan_restrictions = new_user_data.products[index].plan_restrictions;

      // take the user presets and map them to 1.8 presets
      let new_presets = {};

      new_user_data.presets["racking"] = [...JSON.parse(JSON.stringify(TerraSmartProducts)), ...Object.values(new_user_data.presets["racking"])];
      new_user_data.en_target_inputs = new_user_data.en_target_inputs ? new_user_data.en_target_inputs : config.platform == "internal_sift" ? true : false;
      new_user_data.initial_en_target_inputs = new_user_data.en_target_inputs;
      // new_user_data.presets.inverter = [simple_inverter];
      Object.keys(new_user_data.presets).map((key) => {
        // console.log(key, new_user_data.presets[key])
        new_presets[key] = [];

        let found_keys = [];
        Object.values(new_user_data.presets[key]).map((preset) => {
          let fixed_preset = map_presets_1_8(key, preset);

          if (found_keys.findIndex((p) => p == preset.id) == -1) {
            new_presets[key].push(fixed_preset);
            found_keys.push(preset.id);
          }
        });
      });

      new_user_data.presets = new_presets;
      new_user_data.presets.module = [simple_module, ...new_user_data.presets.module];
      new_user_data.presets.inverter = [simple_inverter, ...new_user_data.presets.inverter];

      return {
        ...state,
        getting_user_data: false,
        ...new_user_data,
      };

    case UserConstants.UPDATE_PRESET:
      // console.log("UPDATE_PRESET", action);
      let updated_presets = { ...state.presets };
      let new_preset = {
        ...action.payload.input,
        ...action.payload.response,
        default: 0,
      };

      if (action.payload.id == "inv_id") {
        new_preset.inv_id = action.payload.input.inv_id ? action.payload.input.inv_id : action.payload.response.id;
      }

      let id_key = Object.keys(action.payload.response)[0];
      // console.log(new_preset[id_key]);

      let updating_tab = [...updated_presets[action.payload.tab]];
      if (action.payload.key == "saveas") {
        // add to the dropdowns
        updating_tab = [...updating_tab, new_preset];
        // updating_tab[new_preset[id_key]] = new_preset;
      } else if (action.payload.key == "delete") {
        updating_tab = updating_tab.filter((pres) => pres[action.payload.id] != action.payload.input[action.payload.id]);
        // let del_index = updating_tab.findIndex(pres => pres[action.payload.id] == action.payload.input[action.payload.id])
        // console.log(del_index)
        // delete updating_tab[del_index];
      } else if (action.payload.key == "save") {
        // update the input in dropdowns

        // check to see if object exists in dropdown
        let index = updating_tab.map((e) => e[id_key]).indexOf(new_preset[id_key]);
        // if it exists, update that specific preset
        // else add it to the preset dropdown array
        if (index > -1) {
          updating_tab[index] = new_preset;
        } else {
          updating_tab = [...updating_tab, new_preset];
          // updating_tab[new_preset[id_key]] = new_preset;
        }
      }

      return { ...state, presets: { ...updated_presets, [action.payload.tab]: [...updating_tab] } };

    // TODO: BELOW NEED TO BE FLESHED OUT TO ADD THE DATA TO THE STATE INSTEAD OF
    // RELYING ON THE getAll() CALL IN THE OLD ACTION
    case UserConstants.ADDPAYMENT_REQUEST:
      // console.log(action);
      return { ...state, loading: true };

    case UserConstants.ADDPAYMENT_SUCCESS:
      // console.log("ADDPAYMENT_SUCCESS", action);

      let add_payment_state = { ...state };
      if (action.info.response.payment_added) {
        add_payment_state.billing.push({ ...action.info.response.payment_added });
      }
      return { ...add_payment_state, loading: false };
    case UserConstants.ADDPAYMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case UserConstants.UPDATESUB_REQUEST:
      // console.log(action);
      return {
        ...state,
        loading: true,
      };

    case UserConstants.UPDATESUB_SUCCESS:
      console.log("UPDATESUB_SUCCESS", action);
      // plan, paid_plan
      let update_sub_state = { ...state };
      console.log("CURRENT STATE", update_sub_state);
      // product_plan, product_plan_paid

      // if (action.info?.response?.delete_success) {
      //   // delete
      //   update_sub_state.billing = update_sub_state.billing.filter((paymeth) => paymeth.id != parseInt(action.info.response.delete_success));
      // }
      // if (action.info?.response?.default_switched) {
      //   // switch default card
      //   let new_def = parseInt(action.info.response.default_switched);
      //   Object.values(update_sub_state.billing).map((paymeth, index) => {
      //     if (paymeth.id == new_def) {
      //       update_sub_state.billing[index].is_default = true;
      //     } else {
      //       update_sub_state.billing[index].is_default = false;
      //     }
      //   });
      // }
      // if (action.info?.response?.plan_updated) {
      //   update_sub_state = {
      //     ...update_sub_state,
      //     ...action.info.response.plan_updated,
      //   };
      // }

      if (action.update_user_settings) {
        let user_defined_map_center = [action.info.default_lat, action.info.default_lng];
        update_sub_state = {
          ...update_sub_state,
          invoice_name: action.info.invoice_name,
          company: action.info.invoice_company,
          invoice_address: action.info.invoice_address,
          map_center: user_defined_map_center ? user_defined_map_center : update_sub_state.map_center,
          use_random_map_location: action.info.use_random_map_location,
          en_target_inputs: action.info.en_target_inputs,
          initial_en_target_inputs: action.info.en_target_inputs,
        };
      }

      return {
        ...update_sub_state,
        loading: false,
        funnel_visible: false,
        // product_plan: action.plan,
        // product_plan_paid: action.paid_plan,
      };

    case UserConstants.UPDATESUB_FAILURE:
      // console.log(action);
      return {
        ...state,
        loading: false,
        error: "Error updating plan.",
      };

    case UserConstants.UPDATE_USER_VALUE:
      const temp_user_state = { ...JSON.parse(JSON.stringify(state)) };
      if (action.key == "revert_to_initial_en_target_inputs") {
        temp_user_state.en_target_inputs = temp_user_state.initial_en_target_inputs;
      } else {
        temp_user_state[action.key] = action.value;
      }
      return { ...temp_user_state };

    case UserConstants.PWRESET_REQUEST:
      return { ...state, loggingIn: true, pw_reset_msg: undefined };
    case UserConstants.PWRESET_SUCCESS:
      return { ...state, loggingIn: false, pw_reset_msg: action.payload };
    case UserConstants.PWRESET_FAILURE:
      return { ...state, loggingIn: false, pw_reset_msg: action.error || action.payload || "Error requesting reset." };

    case UserConstants.FUNNEL_VISIBILITY_TOGGLE:
      console.log("Action", action);
      return { ...state, funnel_visible: action.value };

    case UserConstants.SHOW_TERMS:
      // console.log('SHOW_TERMS', state.show_terms, state.openedViaAgreegmentUpdate)
      return {
        ...state,
        show_terms: action.visible,
        openedViaAgreegmentUpdate: action.openedFromAgreementUpdate,
        show_agreement_modal: state.show_terms && state.openedViaAgreegmentUpdate ? true : false,
        show_declined_modal: false,
      };

    case UserConstants.AGREEMENT_DECLINED:
      return { ...state, show_agreement_modal: false, show_terms: false, openedViaAgreegmentUpdate: false };
    case UserConstants.AGREEMENT_ACCEPTED:
      return { ...state, show_agreement_modal: false, show_terms: false, openedViaAgreegmentUpdate: false };

    default:
      return state;
  }
}

export function accessToken(state) {
  if (state.access) {
    return state.access.token;
  }
}

export function isAccessTokenExpired(state) {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - new Date().getTime() < 5000;
  }
  return true;
}

export function refreshToken(state) {
  if (state.refresh) {
    return state.refresh.token;
  }
  return false;
}

export function isRefreshTokenExpired(state) {
  if (state.refresh && state.refresh.exp) {
    // console.log(`Current time left ${1000* state.refresh.exp - (new Date()).getTime()}`)
    return 1000 * state.refresh.exp - new Date().getTime() < 5000;
  }
  return true;
}
export function isAccessValid(state) {
  if (state.access && state.access.exp) {
    // console.log(`Current time left on access ${1000* state.access.exp - (new Date()).getTime()}`)
    // return false when token expires in less than 10m
    return 1000 * state.access.exp - new Date().getTime() > 600000;
  }
  return false;
}
export function isRefreshValid(state) {
  if (state.refresh && state.refresh.exp) {
    // console.log(`Current time left on refresh ${1000 * state.refresh.exp - new Date().getTime()}`);
    // return false when token expires in less than 12h
    return 1000 * state.refresh.exp - new Date().getTime() > 43200000;
  }
  return false;
}

export function isAuthenticated(state) {
  // return !isRefreshTokenExpired(state)
  // return isAccessValid(state) && isRefreshValid(state);
  return isAccessValid(state);
}

export function errors(state) {
  return state.errors;
}
