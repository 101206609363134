import React, { useState, useEffect } from "react";
import { LayerGroup, Polygon, GeoJSON, Marker, Popup } from "react-leaflet";
import { inputsActions, getCenterPointofFeature } from "../Redux";

import { LayoutLayerHandler } from "./LayoutLayerHandler";

import { useSelector, useDispatch } from "react-redux";

const WiringKeys = ["inverter", "racks"];

const Markers = (coords, index) => {
  return (
    <Marker key={index} position={coords}>
      <Popup>Corner {index + 1}</Popup>
    </Marker>
  );
};

const LayoutLayer = ({}) => {
  const dispatch = useDispatch();

  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);
  const edited_layouts = useSelector((state) => state.inputs.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager.outputs.selectedResult.id);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  if (!show_layout_editor || (edited_layouts && selectedResultId && !edited_layouts[selectedResultId])) return null;

  // if (edited_layouts && selectedResultId && edited_layouts[selectedResultId]) {
  //   console.log("edited_layouts[selectedResultId]", edited_layouts[selectedResultId]);

  // }
  const row_boxes = selectedResultId && edited_layouts && edited_layouts[selectedResultId] ? edited_layouts[selectedResultId]["row_boxes"] : undefined;

  return (
    <>
      {roads && (
        <LayerGroup key="roads-group-editor">
          <Polygon interactive={false} positions={roads.geometry.coordinates} key={799} id={-997} color="#e0710f" fillColor="none" weight={1} />
        </LayerGroup>
      )}

      {/* {row_boxes &&
        Object.values(row_boxes).map((row, index) => {
          return (
            <GeoJSON
              interactive={false}
              data={row}
              key={row.properties.index}
              style={{
                fillColor: "transparent",
                weight: 3,
                color: "#CCFF00  ",
                dashArray: "none",
              }}
            >
              {row.geometry.coordinates[0].map((coord, index) => {
                return (
                  <Marker key={index} position={[coord[1], coord[0]]}>
                    <Popup>{`${index} => ${coord}`}</Popup>
                  </Marker>
                );
              })}
            </GeoJSON>
          );
        })} */}

      <LayerGroup key="rack-group-editor">
        {edited_layouts &&
          Object.values(edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]).length > 0 &&
          Object.values(edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]).map((rack) => {
            return (
              <>
                <LayoutLayerHandler key={rack?.index} id={rack?.index} rack={rack} />
                {/* {rack.geoJson.geometry.coordinates[0].map((coord, index) => {
                  return (
                    <Marker key={index} position={[coord[1], coord[0]]}>
                      <Popup>{`${index} => ${coord}`}</Popup>
                    </Marker>
                  );
                })} */}
              </>
            );
          })}
      </LayerGroup>

      {/* <LayerGroup key="rack--editor">
        {edited_layouts &&
          edited_layouts[selectedResultId]?.disabled_cells.length > 0 &&
          edited_layouts[selectedResultId]?.disabled_cells.map((cell) => {
            return <Polygon interactive={false} positions={[cell.rack]} key={cell.index} id={cell.index} color="#A710A7" fillColor="none" weight={1} />;
          })}
      </LayerGroup> */}
    </>
  );
};

export { LayoutLayer };

{
  /* <GeoJSON
data={feature}
transform={false}
draggable={false}
bubblingMouseEvents={false}
maxVertices={250}
key={`${feature.properties.index}`}
onEachFeature={onEachFeature}
style={{
  fillColor: "transparent",
  weight: 2,
  color: feature.properties.hidden ? "red" : "#33A2FF",
  dashArray: feature.properties.hidden ? "4" : "none",
}}
></GeoJSON> */
}

{
  /* <LayerGroup key="groups">
{Object.values(layout).length > 0 &&
  Object.values(layout.inverter_groups).map((group, group_index) => {
    let group_color = group["wires"]["properties"]["color"];
    return Object.keys(group).map((key, index) => {
      if (!WiringKeys.includes(key)) {
        return;
      }
      let indx = 0;
      // console.log(key, index, group[key]);
      // group[key].color = '#33A2FF'
      if (key == "inverter") {
        group[key].color = "#ee00ff";
        group[key].fillColor = "none";
        group[key].weight = 2;
        indx = 597;
      }
      if (key == "combiner_boxes") {
        if (!layer_toggle.show_combiners) return <></>;
        group[key].color = "#7FFF00";
        group[key].fillColor = "#7FFF00";
        group[key].weight = 4;
      }
      if (key == "racks") {
        group[key].color = group_color; //33A2FF
        group[key].fillColor = "none";
        group[key].weight = 2;
        indx = -599;
      }
      if (key == "wires") {
        if (!layer_toggle.show_wires) return <></>;
        // return
        group[key].color = group[key].properties.color;
        group[key].fillColor = "none";
        group[key].weight = 0.5;

        // #33A2FF
        // #5377ad
        // #33A2FF
        // #33A2FF
        // #33A2FF
        // 131 - #AAFFFF
        // 141 - #AAEAFF
        // 151 - #AAD4FF
        // 161 - #AABFFF
        // 171 - #AAAAFF
        // 181 - #BFAAFF
      }
      if (key == "strings") {
        if (!layer_toggle.show_strings) return <></>;
        group[key].color = group[key].properties.color;
        group[key].fillColor = "none";
        group[key].weight = 0.5;
      }

      return (
        <Polygon
          positions={group[key].geometry.coordinates}
          key={index}
          color={group[key].color}
          fillColor={group[key].fillColor}
          id={indx}
          zIndex={10000}
          weight={group[key].weight}
        ></Polygon>
      );
    });
  })}
</LayerGroup> */
}
