import React, { useEffect, useState } from 'react';

export default function DragDiv(props) {
  const { onPointerDown, onPointerUp, onPointerMove, onDragMove, children, style, className, isSvg = false } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e) => {
    setIsDragging(true);

    onPointerDown(e);
  };

  const handlePointerUp = (e) => {
    setIsDragging(false);

    onPointerUp(e);
  };

  const handlePointerMove = (e) => {
    if (isDragging) onDragMove(e);

    onPointerMove(e);
  };

  useEffect(() => {
    window.addEventListener('pointerup', handlePointerUp);

    return () => {
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, []);

  // Dynamically render a <g> or <div> tag
  const Tag = isSvg ? 'g' : 'div';

  return (
    <Tag onPointerDown={handlePointerDown} onPointerMove={handlePointerMove} style={style} className={className}>
      {children}
    </Tag>
  );
}

DragDiv.defaultProps = {
  onPointerDown: () => {},
  onPointerUp: () => {},
  onPointerMove: () => {},
};
