const FinanceDD = {
  0: {
    id: 0,
    data: {
      default: 0,
      name: "Custom Finance",
      metric: 0,
      analysis_period: 20,
      discount_rate: 8,
      dc_op_cost: 10,
      ac_op_cost: 0,
      fixed_op_cost: 20000,
      lease_cost: 0,
      escalation: 2,
      doCustomSchedule: 0,
      customSchedule: [],

      // summarized
      summarized_dc: 0.95,
      summarized_ac: 0.1,
      foot_print: 0,
      fixed_cost: 1500000,

      toggle_finance_type: 0, // 0 = summarized, 1 = detailed

      // D/C Units
      module_dc_cost: { value: 0.35, type: 0, total: 0.35 },
      rack_a_finance: { value: 0, type: 0, total: 0 },
      rack_b_finance: { value: 0, type: 0, total: 0 },
      rack_c_finance: { value: 0, type: 0, total: 0 },
      bos_other: { value: 0.6, type: 0, total: 0.6 },
      // A/C Units
      inverter: { value: 0.06, type: 0, total: 0.06 },
      ac_aux: { value: 0.04, type: 0, total: 0.04 },
      mv_wire: { value: 0, type: 0, total: 0.0 },
      other_ac: { value: 0, type: 0, total: 0.0 },
      // fixed units
      interconnection: { value: 1000000, type: 0, total: 1000000 },
      permits_fees: { value: 250000, type: 0, total: 250000 },
      engineering: { value: 100000, type: 0, total: 100000 },
      itc_ineligible: { value: 0, type: 0, total: 0 },
      margin: { value: 150000, type: 0, total: 150000 },
      other_fixed: { value: 0, type: 0, total: 0 },
      // Misc Units
      rack_footprint: { value: 0, type: 0, total: 0 },
      boundary_area_per: { value: 0, type: 0, total: 0 },
      contingency: { value: 0, type: 0, total: 0 },
      spacing_gcr: [0.3, 0.45, 0.6],
      spacing_per_wp: [0, 0, 0],

      // footprint_install_cost: 0,
      footprint_op_cost: 0,

      area_install_cost: 0,
      area_op_cost: 0,

      ri_power: 0.06,
      ri_escalation: 1,
      do_include_itc: 0,
      itc_percent: 0,
      do_include_ptc: 0,
      ptc_value: 0,
      do_ri_customSchedule: 0,
      ri_customSchedule: [],

      debt_structure: 1,
      debt_percent: 65,
      debt_dscr: 1.3,
      debt_interest: 4,
      debt_tenor: 15,

      dep_5yrSL: 0,
      dep_15yrSL: 0,
      dep_20yrSL: 100,
      dep_30yrSL: 0,
      dep_35yrSL: 0,
      dep_39yrSL: 0,
      dep_5yrMACRS: 0,
      dep_15yrMACRS: 0,

      state_taxes: 0,
      federal_taxes: 20,

      revenue_type: 0,
      tou_grid: [
        [1, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [2, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
        [3, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [4, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [5, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [6, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [7, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [8, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [9, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [10, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
        [11, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [12, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [13, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [14, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [15, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
        [16, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
        [17, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
        [18, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
        [19, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [20, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04],
        [21, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [22, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [23, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
        [24, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06],
      ],
    },
  },
};

export { FinanceDD };
