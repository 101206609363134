import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";

import { siftActions, inputsActions, canopyActions, getCenterPoint, getSiteCenter } from "../../../Redux";

// import { DEFAULT_MAP_CENTER } from './MapConstants'
import { ContextMenu } from "../ContextMenu/ContextMenu";
import { useKey } from "./useKey";

import { square } from "../../../../assets/images";

export const drawOptions = {
  shapeOptions: {
    stroke: true,
    color: "white",
    opacity: 1.0,
    weight: 4,
    fillColor: "#FFFFFF",
  },
  icon: new L.Icon({
    iconUrl: square,
    iconSize: [16, 16],
    iconAnchor: [7, 7],
  }),
};

export const MapEventHandler = ({ marker_latlng = undefined }) => {
  // get the map from parent
  const map = useMap();

  const dispatch = useDispatch();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const search_visible = useSelector((state) => state.inputs.uiState.search_visible);
  const show_offset_tool = useSelector((state) => state.sift.ioManager.uiState.show_offset_tool);
  const site_features = useSelector((state) => state.inputs.site_features);
  //layout editor
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const selected_rack_layout_id = useSelector((state) => state.inputs.selected_rack_layout_id);

  //canopy
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const canopies = useSelector((state) => state.canopy.canopies);
  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";

  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";
  const url = window.location.pathname;

  // setup leaflet map event handlers
  useMapEvents({
    click: _onClickMap,
    // zoomend: _onZoomMap,
    startdraw: _onStartDraw,
    zoomextents: _onZoomExtents,
  });

  useEffect(() => {
    if (!map) return;
    // this limits the number of draggable markers that are visible and interactive with very large polygons that have many verticies
    map.pm.setGlobalOptions({
      limitMarkersToCount: 20,
    });
  }, []);

  useEffect(() => {
    let the_map_center;
    if (using_canopy && Object.values(canopies).length > 0) {
      the_map_center = getSiteCenter(canopies);
    } else if (url == "/sift" && Object.values(site_features).length > 0) {
      the_map_center = getCenterPoint(site_features);
    }

    if (the_map_center) {
      dispatch(inputsActions.update_input("map_center", the_map_center));
    }
  }, [url]);

  function _onClickMap(e) {
    // console.log("_onClickMap", e);
    if (is_drawing) return;

    if (search_visible) {
      dispatch(inputsActions.update_ui_state("search_visible", false));
    }

    if (show_offset_tool) {
      dispatch(siftActions.updateUIState("show_offset_tool", false));
    }

    if (selectedFeatureId) {
      dispatch(inputsActions.update_site_input("selectedFeatureId", undefined));
      // dispatch(siftActions.selectFeatureById(undefined));
    }

    if (show_layout_editor && selected_rack_layout_id) {
      dispatch(inputsActions.update_input("selected_rack_layout_id", undefined));
    }

    // if (internal_sift && internal_sift && window.location.pathname == "/canopy" || window.location.pathname == "/canopy/" && selected_canopy) {
    //   dispatch(canopyActions.updateCanopyInputs("selected_canopy", undefined));
    // }
  }

  // function _onZoomMap(e) {
  //   console.log("_onZoomMap", e.target._zoom);
  // }

  function _onZoomExtents() {
    if (marker_latlng) {
      map.flyTo(marker_latlng);
      return;
    }

    let bounds = L.latLngBounds();
    map.eachLayer((layer) => {
      if (internal_sift && using_canopy) {
        if (layer.options.shape == "Rectangle") {
          bounds.extend(layer.getBounds());
        }
      } else {
        if (layer instanceof L.Polygon) {
          // console.log("instance of polygon", layer);
          if (layer.feature && layer.feature.properties && !layer.feature.properties.skip_zoom_extent) {
            bounds.extend(layer.getBounds());
          }
        }
      }
    });

    if (Object.keys(bounds).length > 0) {
      map.fitBounds(bounds);
    }
  }

  function _onStartDraw() {
    var polygon = new L.Draw.Polygon(map, drawOptions);
    polygon.enable();
  }

  return null;
};
