import { undoRedoConstants } from '../_constants';

export const undoRedoActions = {
  storeFeatureState,
  undo,
  redo,
};

function storeFeatureState(pastFeatureSet, redux_action) {
  return { type: undoRedoConstants.STORE_FEATURE_STATE, pastFeatureSet, redux_action };
}

function undo() {
  return { type: undoRedoConstants.UNDO };
}

function redo() {
  return { type: undoRedoConstants.REDO };
}
