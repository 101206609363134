export const tutorialConstants = {
  START_TUTORIAL: 'START_TUTORIAL',
  SHOW_TUTORIAL: 'SHOW_TUTORIAL',
  START_TOUR: 'START_TOUR',
  RUN_EXAMPLE: 'RUN_EXAMPLE',
  START_EXAMPLE_TUT: 'START_EXAMPLE_TUT',
  NAVIGATE_TUTORIAL: 'NAVIGATE_TUTORIAL',
  RESET_TUTORIAL: 'RESET_TUTORIAL',
  SHOW_TUTORIAL_FUNNEL: 'SHOW_TUTORIAL_FUNNEL',
  END_TUTORIAL: 'END_TUTORIAL',
  SHOW_TUTORIAL_NAV: 'SHOW_TUTORIAL_NAV',
};
