import styled, { css } from "styled-components";

const CarouselContainer = styled.section`
  position: relative;
  width: 500px;
  height: 355px;
  background-color: #ecf0f1;

  .controls-container {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    /* z-index: 5; */

    .control {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;

      &.prev-control {
        transform: rotate(90deg);
      }

      &.next-control {
        transform: rotate(-90deg);
      }
    }
  }

  .indicators-container {
    position: absolute;
    bottom: -20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 5;

    > span.indicator {
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 9999px;
      cursor: pointer;
      background-color: #d9d9d9;

      &.active {
        background-color: var(--primary-brand-color);
      }
    }
  }

  .carousel-viewport {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .scrollbar-hidden {
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
      background: transparent;
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }
  }

  .carousel-slide {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    counter-increment: item;
    background: #fff;
    display: grid;

    /* ${(props) => props.type == "image" && css``}

    img {
    } */

    /* &:nth-child(even) {
      background-color: #99f;
    } */

    .carousel-text {
      height: 42px;
      /* margin: 0px 0px 10px 0px; */
      p {
        margin: 0px;
        color: var(--primary-brand-color);
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -40%, 70px);
      color: #fff;
      font-size: 2em;
    }
  }

  .carousel-snap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  }
`;

const ArrowDownContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 130%;

  > svg {
    width: 100%;
    height: auto;
    fill: var(--primary-brand-color);
  }
`;

export { CarouselContainer, ArrowDownContainer };
