import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { inputsActions } from "../Redux";

import { ToggleWrap } from "./styles";

const Toggle = ({ id_key, disabled, uiControler = false }) => {
  const dispatch = useDispatch();

  const dynamic_variable = useSelector((state) => (uiControler ? state.inputs.uiState[id_key] : state.inputs[id_key]));

  const is_checked = dynamic_variable == 1;

  const update_input = (id_key, value) => {
    if (uiControler) {
      dispatch(inputsActions.update_ui_state(id_key, value));
    } else {
      dispatch(inputsActions.update_input(id_key, value));
    }
  };

  return (
    <ToggleWrap disabled={disabled}>
      <label className="switch">
        <input type="checkbox" checked={is_checked} onChange={(e) => update_input(id_key, e.target.checked == true ? 1 : 0)} disabled={disabled} />
        <span className="slider round"></span>
      </label>
    </ToggleWrap>
  );
};

export { Toggle };
