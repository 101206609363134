import styled, { keyframes } from "styled-components";
// `${props.elementDims.x + props.elementDims.width + 20}px`

const springIn = keyframes`
 0% { transform: scale(0.4)}
 100% { transform: scale(1)}
`;

const springOut = keyframes`
 0% { transform: scale(1)}
 100% { transform: scale(0.5)}
`;

export const TutorialTipWrap = styled.section`
  /* display: inline-block; */
  position: relative;

  &:before {
    display: ${(props) => (props.highlight ? "block" : "none")};
    content: "";
    z-index: 501;
    pointer-events: none;
    position: fixed;
    height: ${(props) => `${props.elementDims.height}px`};
    width: ${(props) => `${props.customElementWidth || props.elementDims.width}px`};
    outline: ${(props) => (props.visible ? "3px solid var(--primary-brand-color)" : "unset")};
    outline-offset: 2px;
    border-radius: 3px;
  }

  .tutorial-tip {
    box-sizing: border-box;
    display: block;
    position: fixed;
    border-radius: 3px;
    font-size: 14px;
    z-index: 1000;
    background-color: #fff;
    padding: 10px;
    width: auto;
    max-width: 305px;
    height: auto;
    text-align: left;
    border: 1px solid var(--primary-brand-color);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    left: ${(props) =>
      props.placement == "right"
        ? `${props.elementDims.x + props.elementDims.width + 20}px`
        : props.placement == "left"
        ? `${props.elementDims.x - props.elementDims.width - 20}px`
        : props.placement == "top"
        ? `${(props.customElementWidth || props.elementDims.width) / 2 + props.elementDims.x - (props.customTipWidth || 0) / 2}px`
        : props.placement == "bottom"
        ? `${(props.customElementWidth || props.elementDims.width) / 2 + props.elementDims.x - (props.customTipWidth || 0) / 2}px`
        : `${props.elementDims.x + props.elementDims.width + 20}px`};
    top: ${(props) =>
      props.placement == "right"
        ? `${Math.round(props.elementDims.top - 6)}px`
        : props.placement == "left"
        ? `${Math.round(props.elementDims.top - 10)}px`
        : props.placement == "top"
        ? `${Math.round(props.elementDims.top - (props.customTipHeight || 0) - 20)}px`
        : props.placement == "bottom"
        ? `${Math.round(props.elementDims.bottom + 20)}px`
        : `${Math.round(props.elementDims.top - 10)}px`};
    animation-name: ${springIn};
    animation-duration: 200ms;
    animation-timing-function: var(--easing-spring);

    .tip-content {
      .tip-text {
        display: flex;
        align-items: flex-start;
        line-height: 20px;
        text-align: left;
        white-space: normal;

        & > img {
          margin-right: ${(props) => (props.content ? "8px" : "0px")};
        }

        p {
          margin: 0px;
          color: #333;
        }
      }
    }

    .pricing-link {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 3px;
        height: 16px;
        width: 16px;
      }
      a {
        color: var(--primary-brand-color);
        margin-left: 3px;
        &:hover {
          opacity: 0.8;
        }
      }

      p {
        margin-right: 30px;
        margin-bottom: 0px;
        a {
          color: var(--primary-brand-color);

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

// the following Bottom, Top, Right Left are the arrows that display at the side of the tooltip.
// Bottom is the default and the rest are Extended from Bottom
export const Bottom = styled.div`
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: ${(props) => (props.offetArrowLeft ? `${props.offetArrowLeft}` : "50%")};
  margin-left: -35px;
  bottom: -16px;

  &:after {
    content: "";
    position: absolute;
    left: 21px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: 6px 5px 9px -9px black, 5px 6px 9px -9px black;
    transform: rotate(45deg);
    background: #fff;
    border: 1px solid var(--primary-brand-color);
  }
`;

export const Top = styled(Bottom)`
  top: -16px;
  left: ${(props) => (props.offetArrowLeft ? `${props.offetArrowLeft}` : "50%")};
  transform: rotate(180deg);
`;

export const Right = styled(Bottom)`
  left: calc(100% + 8px);
  transform: rotate(-90deg);
  bottom: unset;
  top: 16px;
`;

export const Left = styled(Bottom)`
  left: -8px;
  transform: rotate(90deg);
  bottom: unset;
  top: 15px;

  &:after {
    left: 25%;
  }
`;

// /* Custom properties */
// :root {
//   --tooltip-text-color: white;
//   --tooltip-background-color: black;
//   --tooltip-margin: 30px;
//   --tooltip-arrow-size: 30px;
// }

// /* Wrapping */
// .Tooltip-Wrapper {
//   display: inline-block;
//   position: relative;
// }

// /* Absolute positioning */
// .Tooltip-Tip {
//   position: absolute;
//   border-radius: 4px;
//   left: 50%;
//   transform: translateX(-50%);
//   padding: 6px;
//   color: var(--tooltip-text-color);
//   background: var(--tooltip-background-color);
//   font-size: 14px;
//   font-family: sans-serif;
//   line-height: 1;
//   z-index: 100;
//   white-space: nowrap;
// }

// /* CSS border triangles */
// .Tooltip-Tip::before {
//   content: " ";
//   left: 50%;
//   border: solid transparent;
//   height: 0;
//   width: 0;
//   position: absolute;
//   pointer-events: none;
//   border-width: var(--tooltip-arrow-size);
//   margin-left: calc(var(--tooltip-arrow-size) * -1);
// }

// /* Absolute positioning */
// .Tooltip-Tip.top {
//   top: calc(var(--tooltip-margin) * -1);
// }
// /* CSS border triangles */
// .Tooltip-Tip.top::before {
//   top: 100%;
//   border-top-color: var(--tooltip-background-color);
// }

// /* Absolute positioning */
// .Tooltip-Tip.right {
//   left: calc(100% + var(--tooltip-margin));
//   top: 50%;
//   transform: translateX(0) translateY(-50%);
// }
// /* CSS border triangles */
// .Tooltip-Tip.right::before {
//   left: calc(var(--tooltip-arrow-size) * -1);
//   top: 50%;
//   transform: translateX(0) translateY(-50%);
//   border-right-color: var(--tooltip-background-color);
// }

// /* Absolute positioning */
// .Tooltip-Tip.bottom {
//   bottom: calc(var(--tooltip-margin) * -1);
// }
// /* CSS border triangles */
// .Tooltip-Tip.bottom::before {
//   bottom: 100%;
//   border-bottom-color: var(--tooltip-background-color);
// }

// /* Absolute positioning */
// .Tooltip-Tip.left {
//   left: auto;
//   right: calc(100% + var(--tooltip-margin));
//   top: 50%;
//   transform: translateX(0) translateY(-50%);
// }
// /* CSS border triangles */
// .Tooltip-Tip.left::before {
//   left: auto;
//   right: calc(var(--tooltip-arrow-size) * -2);
//   top: 50%;
//   transform: translateX(-5px) translateY(-50%);
//   border-left-color: var(--tooltip-background-color);
// }

// .Tooltip-Tip.left::after {
//   display: block;
//   content: '';
//   width:300px;
//   height:40px;
//   border: 4px solid red;
//   position: absolute;
//   left:30px;
//   top:50px;

// }
