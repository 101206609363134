import { alertConstants } from "../_constants";
//import { handleAlert } from '../_helpers'

let init_state = {
  canceling: false,
  alert: [],
};

export function alert(state = init_state, action) {
  switch (action.type) {
    // case alertConstants.CANCEL:
    //   return {
    //     canceling: true
    //   };

    case alertConstants.SUCCESS:
      return {
        ...state,
        alert: [...state.alert, { type: "success", msg: action.message }],
      };
    case alertConstants.ERROR:
      return {
        ...state,
        alert: [...state.alert, { type: "error", msg: action.message }],
      };
    case alertConstants.LOADING:
      return {
        ...state,
        alert: [...state.alert, { type: "loading", msg: action.message }],
      };
    case alertConstants.WARNING:
      return {
        ...state,
        alert: [...state.alert, { type: "warning", msg: action.message }],
      };
    case alertConstants.INFO:
      return {
        ...state,
        alert: [...state.alert, { type: "info", msg: action.message }],
      };
    case alertConstants.CLEAR:
      return { ...state, alert: action.messages };
    default:
      return state;
  }
}
