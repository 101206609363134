import styled, { css } from "styled-components";

// *** IMPORTANT INFORMATION ABOUT THE STYLES FOR THIS COMPONENT ***
// This component uses React's createPortal hook and appends itself to the #root element as it's the only way to get our Tooltip to appear over the top of anything in our application no matter where it is located. These are pretty much only used to draw the arrows on the sides of the tooltip box to point to whatever we are mousing over in SIFT. To use the arrow indicators the "TooltipArrowStyles" have to be imported into the  GlobalStyles sheet --Josh

const TooltipWrap = styled.section`
  position: relative;
`;

const TooltipArrowStyles = css`
  #root > span.tooltip--top {
    transform: translate(-50%, -15px) scale(0);

    &::after {
      position: absolute;
      border: solid transparent;
      border-width: 6px;
      content: "";
      top: 100%;
      left: 50%;
      border-top-color: var(--primary-text-color);
      margin-left: -6px;
    }
  }

  #root > span.tooltip--bottom {
    transform: translate(-50%, 15px) scale(0);

    &::after {
      position: absolute;
      border: solid transparent;
      border-width: 6px;
      content: "";
      bottom: 100%;
      left: 50%;
      border-bottom-color: var(--primary-text-color);
      margin-left: -6px;
    }
  }

  #root > span.tooltip--left {
    transform: translate(-15px, -50%) scale(0);
    &::after {
      position: absolute;
      border: solid transparent;
      border-width: 6px;
      content: "";
      top: 50%;
      left: 100%;
      border-left-color: var(--primary-text-color);
      margin-top: -6px;
    }
  }

  #root > span.tooltip--right {
    transform: translate(15px, -50%) scale(0);
    &::after {
      position: absolute;
      border: solid transparent;
      border-width: 6px;
      content: "";
      top: 50%;
      right: 100%;
      border-right-color: var(--primary-text-color);
      margin-top: -6px;
    }
  }
`;

// const TooltipWrapper = styled.div`
//   position: relative;
//   //border-bottom: 1px dotted black;
//   display: inline-block;

//   &:hover {
//     .tooltiptext {
//       visibility: visible;
//     }
//   }
//   .tooltiptext {
//     visibility: hidden;
//     min-width: 150px;
//     max-width: 300px;
//     background-color: ${(props) => props.color};
//     color: #fff;
//     text-align: center;
//     padding: 5px 0;
//     border-radius: 6px;
//     position: absolute;
//     z-index: 1;
//     &::after {
//       content: " ";
//       position: absolute;
//       border-width: 5px;
//       border-style: solid;
//     }
//     &.top {
//       bottom: 130%;
//       left: 50%;
//       margin-left: -60px;
//       &::after {
//         top: 100%;
//         left: 50%;
//         transform: translateX(-50%);
//         border-color: ${(props) => props.color} transparent transparent transparent;
//       }
//     }
//     &.right {
//       top: -5px;
//       left: 110%;
//       &::after {
//         top: 50%;
//         right: 100%;
//         transform: translateY(-50%);
//         border-color: transparent ${(props) => props.color} transparent transparent;
//       }
//     }
//     &.bottom {
//       top: 150%;
//       left: 50%;
//       margin-left: -60px;
//       &::after {
//         bottom: 100%;
//         left: 50%;
//         transform: translateX(-50%);
//         border-color: transparent transparent ${(props) => props.color} transparent;
//       }
//     }
//     &.left {
//       top: -5px;
//       right: 110%;
//       &::after {
//         top: 50%;
//         left: 100%;
//         transform: translateY(-50%);
//         border-color: transparent transparent transparent ${(props) => props.color};
//       }
//     }
//   }
// `;

export { TooltipWrap, TooltipArrowStyles };
