import React, { useState, useEffect } from "react";

//antd
import { Input, Tooltip, Radio, Empty, Button } from "antd";
import { MenuFoldOutlined, MinusCircleOutlined } from "@ant-design/icons";

//components
import { TitleDivider } from "../../TitleDivider";
import { FinanceSelectMenu } from "./";
import { InputPopout } from "../InputPopout";
import { DCWireWrapper } from "./styles";

import ReactDataSheet from "react-datasheet";
import { renderSheet } from "../SheetRenderer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber } from "../../Redux";

const FinanceInstallCosts = ({ expand }) => {
  const dispatch = useDispatch();

  // Store values
  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const rack_a_not_active = useSelector((state) => state.inputs.racks[0].active == 0);
  const rack_b_not_active = useSelector((state) => state.inputs.racks[1].active == 0);
  const rack_c_not_active = useSelector((state) => state.inputs.racks[2].active == 0);
  const doCustomSchedule = useSelector((state) => state.inputs.do_custom_schedule);
  // const finance = useSelector((state) => state.sift.ioManager.inputs.finance);
  const inputs = useSelector((state) => state.inputs);
  const do_finance = useSelector((state) => state.inputs.do_finance);

  //shared inputs between summarized and detailed

  const toggle_finance_type = useSelector((state) => state.inputs.toggle_finance_type);
  // summarized
  const summarized_dc = useSelector((state) => state.inputs.summarized_dc);
  // const summarized_dc_wiring_gcr = useSelector((state) => state.inputs.summarized_dc_wiring_gcr);
  // const summarized_dc_wiring_wp = useSelector((state) => state.inputs.summarized_dc_wiring_wp);
  const summarized_ac = useSelector((state) => state.inputs.summarized_ac);
  const foot_print = useSelector((state) => state.inputs.foot_print);
  const fixed_cost = useSelector((state) => state.inputs.fixed_cost);
  const metric = useSelector((state) => state.inputs.metric);

  // detailed
  const module_dc_cost = useSelector((state) => state.inputs.module_dc_cost);
  const rack_a_finance = useSelector((state) => state.inputs.rack_a_finance);
  const rack_b_finance = useSelector((state) => state.inputs.rack_b_finance);
  const rack_c_finance = useSelector((state) => state.inputs.rack_c_finance);
  const bos_other = useSelector((state) => state.inputs.bos_other);
  const inverter_cost = useSelector((state) => state.inputs.inverter_cost);
  const ac_aux = useSelector((state) => state.inputs.ac_aux);
  const mv_wire = useSelector((state) => state.inputs.mv_wire);
  const other_ac = useSelector((state) => state.inputs.other_ac);
  const interconnection = useSelector((state) => state.inputs.interconnection);
  const permits_fees = useSelector((state) => state.inputs.permits_fees);
  const engineering = useSelector((state) => state.inputs.engineering);
  const margin = useSelector((state) => state.inputs.margin);
  const itc_ineligible = useSelector((state) => state.inputs.itc_ineligible);

  const other_fixed = useSelector((state) => state.inputs.other_fixed);
  const rack_footprint = useSelector((state) => state.inputs.rack_footprint);
  const boundary_area_per = useSelector((state) => state.inputs.boundary_area_per);
  const contingency = useSelector((state) => state.inputs.contingency);
  const spacing_gcr = useSelector((state) => state.inputs.spacing_gcr);
  const spacing_per_wp = useSelector((state) => state.inputs.spacing_per_wp);

  // menu toggles
  const [toggle_install_costs, set_toggle_install_costs] = useState(false);
  const [toggle_dc_units, set_toggle_dc_units] = useState(false);
  const [toggle_ac_units, set_toggle_ac_units] = useState(false);
  const [toggle_fixed_cost, set_toggle_fixed_cost] = useState(false);
  const [toggle_misc_cost, set_toggle_misc_cost] = useState(false);
  const [toggle_dc_wire, set_toggle_dc_wire] = useState(false);
  const [popoutY, setPopoutY] = useState(0);

  useEffect(() => {
    if (expand) {
      set_toggle_install_costs(true);
      set_toggle_dc_units(true);
      set_toggle_ac_units(true);
      set_toggle_fixed_cost(true);
      set_toggle_misc_cost(true);
      set_toggle_dc_wire(true);
    } else {
      set_toggle_install_costs(false);
      set_toggle_dc_units(false);
      set_toggle_ac_units(false);
      set_toggle_fixed_cost(false);
      set_toggle_misc_cost(false);
      set_toggle_dc_wire(false);
    }
  }, [expand]);

  // input values used for updating inputs
  const [summarizedDC, setSummarizedDC] = useState(summarized_dc);
  const [summarizedAC, setSummarizedAC] = useState(summarized_ac);
  const [footPrint, setFootPrint] = useState(foot_print);
  const [fixedCost, setFixedCost] = useState(fixed_cost);
  const [moduleDCCost, setModuleDCCost] = useState(module_dc_cost);
  const [rackA, setRackA] = useState(rack_a_finance);
  const [rackB, setRackB] = useState(rack_b_finance);
  const [rackC, setRackC] = useState(rack_c_finance);
  const [bosOther, setBosOther] = useState(bos_other);
  const [inverterInput, setInverterInput] = useState(inverter_cost);
  const [acAux, setAcAux] = useState(ac_aux);
  const [mvWire, setMvWire] = useState(mv_wire);
  const [otherAC, setOtherAC] = useState(other_ac);
  const [interconnectionInput, setInterconnectionInput] = useState(interconnection);
  const [permitsFees, setPermitsFees] = useState(permits_fees);
  const [engineeringInput, setEngineeringInput] = useState(engineering);
  const [marginInput, setMarginInput] = useState(margin);
  const [itcIneligible, setitcIneligible] = useState(itc_ineligible);

  const [otherFixed, setOtherFixed] = useState(other_fixed);
  const [rackFootprint, setRackFootprint] = useState(rack_footprint);
  const [boundaryArea, setBoundaryArea] = useState(boundary_area_per);
  const [contingencyInput, setContingencyInput] = useState(contingency);
  const [active_rack_total, set_active_rack_total] = useState(0);

  useEffect(() => {
    setSummarizedDC(summarized_dc);
    setSummarizedAC(summarized_ac);
    setFootPrint(foot_print);
    setFixedCost(fixed_cost);
    setModuleDCCost(module_dc_cost);
    setRackA(rack_a_finance);
    setRackB(rack_b_finance);
    setRackC(rack_c_finance);
    setBosOther(bos_other);
    setInverterInput(inverter_cost);
    setAcAux(ac_aux);
    setMvWire(mv_wire);
    setOtherAC(other_ac);
    setInterconnectionInput(interconnection);
    setPermitsFees(permits_fees);
    setEngineeringInput(engineering);
    setMarginInput(margin);
    setitcIneligible(itc_ineligible);
    setOtherFixed(other_fixed);
    setRackFootprint(rack_footprint);
    setBoundaryArea(boundary_area_per);
    setContingencyInput(contingency);
  }, [inputs]);

  useEffect(() => {
    if (do_finance == false) {
      set_toggle_install_costs(false);
      set_toggle_dc_units(false);
      set_toggle_ac_units(false);
      set_toggle_fixed_cost(false);
      set_toggle_misc_cost(false);
      set_toggle_dc_wire(false);
    }
  }, [do_finance]);

  useEffect(() => {
    if (toggle_finance_type == 1) {
      set_toggle_dc_wire(false);
    }
  }, [toggle_finance_type]);

  useEffect(() => {
    calculateRackTotal();
  }, []);

  useEffect(() => {
    calculateRackTotal();
  }, [rack_a_finance, rack_b_finance, rack_c_finance]);

  const calculateRackTotal = () => {
    let rack_a_count = !rack_a_not_active ? rack_a_finance.total : 0;
    let rack_b_count = !rack_b_not_active ? rack_b_finance.total : 0;
    let rack_c_count = !rack_c_not_active ? rack_c_finance.total : 0;
    // set_active_rack_total(rack_a_count + rack_b_count + rack_c_count);
    set_active_rack_total(rack_a_count);
  };

  const toggleDCWire = () => {
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    set_toggle_dc_wire(!toggle_dc_wire);
  };

  let spacing_gcr_data = [];
  let spacing_gcr_index = 0;

  let dc_wiring_data = [];
  let dc_wiring_index = 0;

  const financeType = [
    { label: "Summed", value: 0 },
    { label: "Detail", value: 1 },
  ];

  const updateTableInputs = (key, value) => {
    dispatch(inputsActions.update_input(key, value));
  };

  const updateInput = (key, value, notAnObject = false) => {
    if (notAnObject) {
			let decPart = (value+"").split(".")[1];
			if (decPart && decPart.slice(-1) == '0') return
      let changed_value = parseFloat(value);			
      if (!isNumber(changed_value)) return;
      dispatch(inputsActions.update_input(key, changed_value));
    } else {
			let decPart = (value.value+"").split(".")[1];
			if (decPart && decPart.slice(-1) == '0') return			
      let changed_value = { value: parseFloat(value.value), type: parseInt(value.type), total: parseFloat(value.total) };
      if (!isNumber(changed_value.value)) return;
      dispatch(inputsActions.update_input(key, changed_value));
    }
  };

  const makeItRain = (sum) => {
    return sum.toLocaleString();
  };

  const handleCreateSpacingAdder = () => {
    let dt_data = [
      [
        { value: "GCR", readOnly: true },
        { value: "$/Wp", readOnly: true },
      ],
    ];
    let data = { spacing_gcr, spacing_per_wp };
    let def = false;

    for (var index in data.spacing_gcr) {
      dt_data.push([
        { readOnly: false, value: data.spacing_gcr[index] },
        { readOnly: false, value: data.spacing_per_wp[index] },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={index} size="small" type="primary" ghost onClick={onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={(parseInt(index) + 1) * 10} size="small" type="primary" ghost onClick={onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    if (data.spacing_gcr && data.spacing_gcr.length == 0) {
      dt_data.splice(1, 0, [
        { readOnly: def, value: 0 },
        { readOnly: def, value: 0 },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={0} size="small" type="primary" ghost onClick={onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={10} size="small" type="primary" ghost onClick={onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    spacing_gcr_data = dt_data;
    spacing_gcr_index = index;
  };

  const onInsertRow = (e) => {
    let event = Object.assign({}, e);
    let index = parseInt(event.currentTarget.id);
    let data = { spacing_gcr, spacing_per_wp };
    data.spacing_gcr.splice(index + 1, 0, 0);
    data.spacing_per_wp.splice(index + 1, 0, 0);
    updateTableInputs("spacing_gcr", data.spacing_gcr);
    updateTableInputs("spacing_per_wp", data.spacing_per_wp);
    handleCreateSpacingAdder();
  };

  const onRemoveRow = (e) => {
    let event = Object.assign({}, e);
    let data = { spacing_gcr, spacing_per_wp };
    if (data.spacing_gcr.length == 1) return;
    let index = event.currentTarget.id / 10 - 1;
    data.spacing_gcr.splice(index, 1);
    data.spacing_per_wp.splice(index, 1);
    updateTableInputs("spacing_gcr", data.spacing_gcr);
    updateTableInputs("spacing_per_wp", data.spacing_per_wp);
    handleCreateSpacingAdder();
  };

  const handleChanges = (changes) => {
    const data = spacing_gcr_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        data[row][col] = { ...data[row][col], value };
      }
    });
    let newModData = data.slice(1, data.length);
    let GCR = [];
    let WP = [];
    newModData.forEach((row) => {
      let gcr = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let wp = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      GCR.push(gcr);
      WP.push(wp);
    });

    updateTableInputs("spacing_gcr", GCR);
    updateTableInputs("spacing_per_wp", WP);
  };

  const handleDCChanges = (changes) => {
    const data = dc_wiring_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        data[row][col] = { ...data[row][col], value };
      }
    });
    let newModData = data.slice(1, data.length);
    let dc_gcr = [];
    let dc_wp = [];
    newModData.forEach((row) => {
      let gcr = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let wp = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      dc_gcr.push(gcr);
      dc_wp.push(wp);
    });

    updateTableInputs("summarized_dc_wiring_gcr", dc_gcr);
    updateTableInputs("summarized_dc_wiring_wp", dc_wp);
  };

  // toggle_finance_type == 1 ? handleCreateSpacingAdder() : handleCreateDCWireTable();
  handleCreateSpacingAdder();

  return (
    <>
      <TitleDivider title="Install Costs" collapseable={true} toggle_var={toggle_install_costs} toggle_fn={set_toggle_install_costs} />

      {toggle_install_costs && (
        <>
          <section className="input-row-two-col">
            <label>Cost Breakdown</label>
            <Radio.Group
              className="finance-install-cost-radios"
              style={{ marginLeft: "auto" }}
              name="toggle_finance_type"
              options={financeType}
              onChange={(e) => {
                updateInput(e.target.name, e.target.value, true);
              }}
              value={toggle_finance_type}
              disabled={account_loading || input_loading}
            />
          </section>

          {toggle_finance_type == 0 ? (
            <>
              <section className="input-row-two-col">
                <label>DC</label>
                <Input
                  id="summarized_dc"
                  style={{ maxWidth: 135, marginLeft: "auto" }}
                  size="small"
                  min="0"
                  type="number"
                  step="0.01"
                  autoComplete="off"
                  disabled={account_loading || input_loading}
                  value={summarizedDC}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/Wp</span>}
                  onBlur={(e) => {
                    if (summarizedDC == "") {
                      setSummarizedDC(0);
                      updateInput(e.target.id, 0, true);
                    }
                  }}
                  onChange={(e) => {
                    setSummarizedDC(e.target.value);
                    updateInput(e.target.id, e.target.value, true);
                  }}
                />
              </section>

              {/* <section className="input-row-two-col">
                <label>DC Wire</label>
                <a style={{ marginLeft: "auto" }} onClick={toggleDCWire}>
                  {toggle_dc_wire ? "Close DC Wire Table" : "Open DC Wire Table "}
                </a>
              </section> */}

              <section className="input-row-two-col">
                <label>AC</label>
                <Input
                  id="summarized_ac"
                  style={{ maxWidth: 135, marginLeft: "auto" }}
                  size="small"
                  min="0"
                  type="number"
                  step="0.01"
                  autoComplete="off"
                  disabled={account_loading || input_loading}
                  value={summarizedAC}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/Wac</span>}
                  onBlur={(e) => {
                    if (summarizedAC == "") {
                      setSummarizedAC(0);
                      updateInput(e.target.id, 0, true);
                    }
                  }}
                  onChange={(e) => {
                    setSummarizedAC(e.target.value);
                    updateInput(e.target.id, e.target.value, true);
                  }}
                />
              </section>

              {/* <section className="input-row-two-col">
                <label>Footprint</label>
                <Input
                  id="foot_print"
                  style={{ maxWidth: 135, marginLeft: "auto" }}
                  size="small"
                  min="0"
                  type="number"
                  step="0.01"
                  autoComplete="off"
                  disabled={account_loading || input_loading}
                  value={footPrint}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/ha</span>}
                  onChange={(e) => {
                    setFootPrint(e.target.value);
                    updateInput(e.target.id, e.target.value, true);
                  }}
                />
              </section> */}

              <section className="input-row-two-col">
                <label>Fixed Costs</label>
                <Input
                  id="fixed_cost"
                  style={{ maxWidth: 135, marginLeft: "auto" }}
                  size="small"
                  min="0"
                  type="number"
                  step="0.01"
                  autoComplete="off"
                  disabled={account_loading || input_loading}
                  value={fixedCost}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                  onBlur={(e) => {
                    if (fixedCost == "") {
                      setFixedCost(0);
                      updateInput(e.target.id, 0, true);
                    }
                  }}
                  onChange={(e) => {
                    setFixedCost(e.target.value);
                    updateInput(e.target.id, e.target.value, true);
                  }}
                />
              </section>

              {/* <section className="input-row-two-col">
								<label>Spacing Adders</label>
							</section>
							<section style={{ float: "left", width: 180, marginBottom: "16px" }}>
								<ReactDataSheet
									data={spacing_gcr_data}
									sheetRenderer={renderSheet}
									valueRenderer={(cell) => cell.value}
									onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
									onCellsChanged={handleChanges}
								/>
							</section>							 */}

              {/* <section className="input-row-two-col">
                <p>Total</p>
                <p style={{ marginLeft: 25 }}>{`$${makeItRain(summarized_dc + summarized_ac + foot_print + fixed_cost)}`}</p>
              </section> */}

              {/* <InputPopout visible={toggle_dc_wire} onClose={toggleDCWire} popoutY={popoutY}>
                <DCWireWrapper>
                  <label>DC Wire Table</label>
                  <section className="dc-table">
                    <ReactDataSheet
                      data={dc_wiring_data}
                      sheetRenderer={renderSheet}
                      valueRenderer={(cell) => cell.value}
                      onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                      onCellsChanged={handleDCChanges}
                    />
                  </section>
                </DCWireWrapper>
              </InputPopout> */}
            </>
          ) : (
            <>
              {/* DC Units */}
              <TitleDivider title="DC Units" collapseable={true} toggle_var={toggle_dc_units} toggle_fn={set_toggle_dc_units} subHeader={true} />
              {toggle_dc_units && (
                <>
                  <section className="input-row-three-col">
                    <label>Module</label>
                    <Input
                      id="module_dc_cost"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={moduleDCCost.value}
                      onBlur={(e) => {
                        if (moduleDCCost.value == "") {
                          setModuleDCCost({ value: 0, type: module_dc_cost.type, total: module_dc_cost.total });
                          updateInput(e.target.id, { value: 0, type: module_dc_cost.type, total: module_dc_cost.total });
                        }
                      }}
                      onChange={(e) => {
                        setModuleDCCost({ value: e.target.value, type: module_dc_cost.type, total: module_dc_cost.total });
                        updateInput(e.target.id, { value: e.target.value, type: module_dc_cost.type, total: module_dc_cost.total });
                      }}
                    />
                    <FinanceSelectMenu type="dc_module" id="module_dc_cost" />
                  </section>

                  <Tooltip placement="top" title={rack_a_not_active ? "Rack A is Off" : ""} mouseEnterDelay={0.5}>
                    <section className="input-row-three-col">
                      <label>Rack A</label>
                      <Input
                        id="rack_a_finance"
                        style={{ maxWidth: 135, marginLeft: "auto" }}
                        size="small"
                        min="0"
                        type="number"
                        step="0.01"
                        autoComplete="off"
                        disabled={account_loading || input_loading || rack_a_not_active}
                        value={rackA.value}
                        onBlur={(e) => {
                          if (rackA.value == "") {
                            setRackA({ value: 0, type: rack_a_finance.type, total: rack_a_finance.total });
                            updateInput(e.target.id, { value: 0, type: rack_a_finance.type, total: rack_a_finance.total });
                          }
                        }}
                        onChange={(e) => {
                          setRackA({ value: e.target.value, type: rack_a_finance.type, total: rack_a_finance.total });
                          updateInput(e.target.id, { value: e.target.value, type: rack_a_finance.type, total: rack_a_finance.total });
                        }}
                      />
                      <FinanceSelectMenu type="dc" id="rack_a_finance" disabled={rack_a_not_active} />
                    </section>
                  </Tooltip>

                  <Tooltip placement="top" title={rack_b_not_active ? "Rack B is Off" : ""} mouseEnterDelay={0.5}>
                    <section className="input-row-three-col">
                      <label>Rack B</label>
                      <Input
                        id="rack_b_finance"
                        style={{ maxWidth: 135, marginLeft: "auto" }}
                        size="small"
                        min="0"
                        type="number"
                        step="0.01"
                        autoComplete="off"
                        disabled={account_loading || input_loading || rack_b_not_active}
                        value={rackB.value}
                        onBlur={(e) => {
                          if (rackB.value == "") {
                            setRackB({ value: 0, type: rack_b_finance.type, total: rack_b_finance.total });
                            updateInput(e.target.id, { value: 0, type: rack_b_finance.type, total: rack_b_finance.total });
                          }
                        }}
                        onChange={(e) => {
                          setRackB({ value: e.target.value, type: rack_b_finance.type, total: rack_b_finance.total });
                          updateInput(e.target.id, { value: e.target.value, type: rack_b_finance.type, total: rack_b_finance.total });
                        }}
                      />
                      <FinanceSelectMenu type="dc" id="rack_b_finance" disabled={rack_b_not_active} />
                    </section>
                  </Tooltip>

                  <Tooltip placement="top" title={rack_c_not_active ? "Rack C is Off" : ""} mouseEnterDelay={0.5}>
                    <section className="input-row-three-col">
                      <label>Rack C</label>
                      <Input
                        id="rack_c_finance"
                        style={{ maxWidth: 135, marginLeft: "auto" }}
                        size="small"
                        min="0"
                        type="number"
                        step="0.01"
                        autoComplete="off"
                        disabled={account_loading || input_loading || rack_c_not_active}
                        value={rackC.value}
                        onBlur={(e) => {
                          if (rackC.value == "") {
                            setRackC({ value: 0, type: rack_c_finance.type, total: rack_c_finance.total });
                            updateInput(e.target.id, { value: 0, type: rack_c_finance.type, total: rack_c_finance.total });
                          }
                        }}
                        onChange={(e) => {
                          setRackC({ value: e.target.value, type: rack_c_finance.type, total: rack_c_finance.total });
                          updateInput(e.target.id, { value: e.target.value, type: rack_c_finance.type, total: rack_c_finance.total });
                        }}
                      />
                      <FinanceSelectMenu type="dc" id="rack_c_finance" disabled={rack_c_not_active} />
                    </section>
                  </Tooltip>

                  <section className="input-row-three-col">
                    <label>BOS Other</label>
                    <Input
                      id="bos_other"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={bosOther.value}
                      // suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/Wp</span>}
                      onBlur={(e) => {
                        if (bosOther.value == "") {
                          setBosOther({ value: 0, type: bos_other.type, total: bos_other.total });
                          updateInput(e.target.id, { value: 0, type: bos_other.type, total: bos_other.total });
                        }
                      }}
                      onChange={(e) => {
                        setBosOther({ value: e.target.value, type: bos_other.type, total: bos_other.total });
                        updateInput(e.target.id, { value: e.target.value, type: bos_other.type, total: bos_other.total });
                      }}
                    />
                    <FinanceSelectMenu type="wp_only" id="bos_other" />

                    {/* <Input
                      id="bos_other"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={bosOther.value}
                      onChange={(e) => {
                        setBosOther({ value: e.target.value, type: bos_other.type, total: bos_other.total });
                        updateInput(e.target.id, { value: e.target.value, type: bos_other.type, total: bos_other.total });
                      }}
                    />
                    <FinanceSelectMenu type="wp_only" id="bos_other" /> */}
                  </section>

                  <section className="total-row">
                    <p>Total DC</p>
                    <p>{`${makeItRain(module_dc_cost.total + active_rack_total + bos_other.total)} $/Wp`}</p>
                  </section>
                </>
              )}

              {/* AC Units */}
              <TitleDivider title="AC Units" collapseable={true} toggle_var={toggle_ac_units} toggle_fn={set_toggle_ac_units} subHeader={true} />

              {toggle_ac_units && (
                <>
                  <section className="input-row-three-col">
                    <label>Inverter</label>
                    <Input
                      id="inverter_cost"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={inverterInput.value}
                      onBlur={(e) => {
                        if (inverterInput.value == "") {
                          setInverterInput({ value: 0, type: inverter_cost.type, total: inverter_cost.total });
                          updateInput(e.target.id, { value: 0, type: inverter_cost.type, total: inverter_cost.total });
                        }
                      }}
                      onChange={(e) => {
                        setInverterInput({ value: e.target.value, type: inverter_cost.type, total: inverter_cost.total });
                        updateInput(e.target.id, { value: e.target.value, type: inverter_cost.type, total: inverter_cost.total });
                      }}
                    />
                    <FinanceSelectMenu type="ac_inverter" id="inverter_cost" />
                  </section>

                  <section className="input-row-three-col">
                    <label>AC Aux</label>
                    <Input
                      id="ac_aux"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={acAux.value}
                      onBlur={(e) => {
                        if (acAux.value == "") {
                          setAcAux({ value: 0, type: ac_aux.type, total: ac_aux.total });
                          updateInput(e.target.id, { value: 0, type: ac_aux.type, total: ac_aux.total });
                        }
                      }}
                      onChange={(e) => {
                        setAcAux({ value: e.target.value, type: ac_aux.type, total: ac_aux.total });
                        updateInput(e.target.id, { value: e.target.value, type: ac_aux.type, total: ac_aux.total });
                      }}
                    />
                    <FinanceSelectMenu type="ac" id="ac_aux" />
                  </section>

                  <section className="input-row-three-col">
                    <label>MV Wire</label>
                    <Input
                      id="mv_wire"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={mvWire.value}
                      // suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/Wac</span>}
                      onBlur={(e) => {
                        if (mvWire.value == "") {
                          setMvWire({ value: 0, type: mv_wire.type, total: mv_wire.total });
                          updateInput(e.target.id, { value: 0, type: mv_wire.type, total: mv_wire.total });
                        }
                      }}
                      onChange={(e) => {
                        setMvWire({ value: e.target.value, type: mv_wire.type, total: mv_wire.total });
                        updateInput(e.target.id, { value: e.target.value, type: mv_wire.type, total: mv_wire.total });
                      }}
                    />
                    <FinanceSelectMenu type="wac_only" id="mv_wire" />
                  </section>

                  <section className="input-row-three-col">
                    <label>Other AC</label>
                    <Input
                      id="other_ac"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={otherAC.value}
                      // suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/Wac</span>}
                      onBlur={(e) => {
                        if (otherAC.value == "") {
                          setOtherAC({ value: 0, type: other_ac.type, total: other_ac.total });
                          updateInput(e.target.id, { value: 0, type: other_ac.type, total: other_ac.total });
                        }
                      }}
                      onChange={(e) => {
                        setOtherAC({ value: e.target.value, type: other_ac.type, total: other_ac.total });
                        updateInput(e.target.id, { value: e.target.value, type: other_ac.type, total: other_ac.total });
                      }}
                    />
                    <FinanceSelectMenu type="wac_only" id="other_ac" />
                  </section>
                  <section className="total-row">
                    <p>Total AC</p>
                    <p>{`${makeItRain(inverter_cost.total + ac_aux.total + mv_wire.total + other_ac.total)} $/Wac`}</p>
                  </section>
                </>
              )}
              {/* Fixed Units */}
              <TitleDivider title="Fixed Units" collapseable={true} toggle_var={toggle_fixed_cost} toggle_fn={set_toggle_fixed_cost} subHeader={true} />
              {toggle_fixed_cost && (
                <>
                  <section className="input-row-two-col">
                    <label>Interconnect</label>
                    <Input
                      id="interconnection"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={interconnectionInput.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (interconnectionInput.value == "") {
                          setInterconnectionInput({ value: 0, type: interconnection.type, total: interconnection.total });
                          updateInput(e.target.id, { value: 0, type: interconnection.type, total: interconnection.total });
                        }
                      }}
                      onChange={(e) => {
                        setInterconnectionInput({ value: e.target.value, type: interconnection.type, total: interconnection.total });
                        updateInput(e.target.id, { value: e.target.value, type: interconnection.type, total: interconnection.total });
                      }}
                    />

                    {/* <Input
                      id="interconnection"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={interconnectionInput.value}
                      onChange={(e) => {
                        setInterconnectionInput({ value: e.target.value, type: interconnection.type, total: interconnection.total });
                        updateInput(e.target.id, { value: e.target.value, type: interconnection.type, total: interconnection.total });
                      }}
                    />
                    <FinanceSelectMenu type="fixed" id="interconnection" /> */}
                  </section>

                  <section className="input-row-two-col">
                    <label>Permits, Fees</label>
                    <Input
                      id="permits_fees"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={permitsFees.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (permitsFees.value == "") {
                          setPermitsFees({ value: 0, type: permits_fees.type, total: permits_fees.total });
                          updateInput(e.target.id, { value: 0, type: permits_fees.type, total: permits_fees.total });
                        }
                      }}
                      onChange={(e) => {
                        setPermitsFees({ value: e.target.value, type: permits_fees.type, total: permits_fees.total });
                        updateInput(e.target.id, { value: e.target.value, type: permits_fees.type, total: permits_fees.total });
                      }}
                    />

                    {/* <Input
                      id="permits_fees"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={permitsFees.value}
                      onChange={(e) => {
                        setPermitsFees({ value: e.target.value, type: permits_fees.type, total: permits_fees.total });
                        updateInput(e.target.id, { value: e.target.value, type: permits_fees.type, total: permits_fees.total });
                      }}
                    />
                    <FinanceSelectMenu type="fixed" id="permits_fees" /> */}
                  </section>

                  <section className="input-row-two-col">
                    <label>Engineering</label>
                    <Input
                      id="engineering"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={engineeringInput.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (engineeringInput.value == "") {
                          setEngineeringInput({ value: 0, type: engineering.type, total: engineering.total });
                          updateInput(e.target.id, { value: 0, type: engineering.type, total: engineering.total });
                        }
                      }}
                      onChange={(e) => {
                        setEngineeringInput({ value: e.target.value, type: engineering.type, total: engineering.total });
                        updateInput(e.target.id, { value: e.target.value, type: engineering.type, total: engineering.total });
                      }}
                    />

                    {/* <Input
                      id="engineering"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={engineeringInput.value}
                      onChange={(e) => {
                        setEngineeringInput({ value: e.target.value, type: engineering.type, total: engineering.total });
                        updateInput(e.target.id, { value: e.target.value, type: engineering.type, total: engineering.total });
                      }}
                    />
                    <FinanceSelectMenu type="fixed" id="engineering" /> */}
                  </section>

                  <section className="input-row-two-col">
                    <label>Margin</label>
                    <Input
                      id="margin"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={marginInput.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (marginInput.value == "") {
                          setMarginInput({ value: 0, type: margin.type, total: margin.total });
                          updateInput(e.target.id, { value: 0, type: margin.type, total: margin.total });
                        }
                      }}
                      onChange={(e) => {
                        setMarginInput({ value: e.target.value, type: margin.type, total: margin.total });
                        updateInput(e.target.id, { value: e.target.value, type: margin.type, total: margin.total });
                      }}
                    />
                    {/* <Input
                      id="margin"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={marginInput.value}
                      onChange={(e) => {
                        setMarginInput({ value: e.target.value, type: margin.type, total: margin.total });
                        updateInput(e.target.id, { value: e.target.value, type: margin.type, total: margin.total });
                      }}
                    />
                    <FinanceSelectMenu type="fixed" id="margin" /> */}
                  </section>
                  <section className="input-row-two-col">
                    <label>ITC Ineligible</label>
                    <Input
                      id="itc_ineligible"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={itcIneligible.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (itc_ineligible.value == "") {
                          setitcIneligible({ value: 0, type: itc_ineligible.type, total: itc_ineligible.total });
                          updateInput(e.target.id, { value: 0, type: itc_ineligible.type, total: itc_ineligible.total });
                        }
                      }}
                      onChange={(e) => {
                        setitcIneligible({ value: e.target.value, type: itc_ineligible.type, total: itc_ineligible.total });
                        updateInput(e.target.id, { value: e.target.value, type: itc_ineligible.type, total: itc_ineligible.total });
                      }}
                    />
                  </section>

                  <section className="input-row-two-col">
                    <label>Other Fixed</label>
                    <Input
                      id="other_fixed"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={otherFixed.value}
                      suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
                      onBlur={(e) => {
                        if (otherFixed.value == "") {
                          setOtherFixed({ value: 0, type: other_fixed.type, total: other_fixed.total });
                          updateInput(e.target.id, { value: 0, type: other_fixed.type, total: other_fixed.total });
                        }
                      }}
                      onChange={(e) => {
                        setOtherFixed({ value: e.target.value, type: other_fixed.type, total: other_fixed.total });
                        updateInput(e.target.id, { value: e.target.value, type: other_fixed.type, total: other_fixed.total });
                      }}
                    />
                    {/* <Input
                      id="other_fixed"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={otherFixed.value}
                      onChange={(e) => {
                        setOtherFixed({ value: e.target.value, type: other_fixed.type, total: other_fixed.total });
                        updateInput(e.target.id, { value: e.target.value, type: other_fixed.type, total: other_fixed.total });
                      }}
                    />
                    <FinanceSelectMenu type="fixed" id="other_fixed" /> */}
                  </section>

                  <section className="total-row">
                    <p>Total Fixed</p>
                    <p>{`$${makeItRain(interconnection.value + permits_fees.value + engineering.value + margin.value + other_fixed.value + itc_ineligible.value)}`}</p>
                  </section>
                </>
              )}

              {/* Misc Units */}
              <TitleDivider title="Misc. Units" collapseable={true} toggle_var={toggle_misc_cost} toggle_fn={set_toggle_misc_cost} subHeader={true} />
              {toggle_misc_cost && (
                <>
                  <section className="input-row-three-col">
                    <label>Rack Footprint</label>
                    <Input
                      id="rack_footprint"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={rackFootprint.value}
                      onBlur={(e) => {
                        if (rackFootprint.value == "") {
                          setRackFootprint({ value: 0, type: rack_footprint.type, total: rack_footprint.total });
                          updateInput(e.target.id, { value: 0, type: rack_footprint.type, total: rack_footprint.total });
                        }
                      }}
                      onChange={(e) => {
                        setRackFootprint({ value: e.target.value, type: rack_footprint.type, total: rack_footprint.total });
                        updateInput(e.target.id, { value: e.target.value, type: rack_footprint.type, total: rack_footprint.total });
                      }}
                    />
                    <FinanceSelectMenu type="misc" id="rack_footprint" />
                  </section>

                  <section className="input-row-three-col">
                    <label>Buildable Area</label>
                    <Input
                      id="boundary_area_per"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={boundaryArea.value}
                      onBlur={(e) => {
                        if (boundaryArea.value == "") {
                          setBoundaryArea({ value: 0, type: boundary_area_per.type, total: boundary_area_per.total });
                          updateInput(e.target.id, { value: 0, type: boundary_area_per.type, total: boundary_area_per.total });
                        }
                      }}
                      onChange={(e) => {
                        setBoundaryArea({ value: e.target.value, type: boundary_area_per.type, total: boundary_area_per.total });
                        updateInput(e.target.id, { value: e.target.value, type: boundary_area_per.type, total: boundary_area_per.total });
                      }}
                    />
                    <FinanceSelectMenu type="misc" id="boundary_area_per" />
                  </section>

                  <section className="input-row-three-col">
                    <label>Contingency</label>
                    <Input
                      id="contingency"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="1"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={contingencyInput.value}
                      // suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                      onBlur={(e) => {
                        if (contingencyInput.value == "") {
                          setContingencyInput({ value: 0, type: contingency.type, total: contingency.total });
                          updateInput(e.target.id, { value: 0, type: contingency.type, total: contingency.total });
                        }
                      }}
                      onChange={(e) => {
                        setContingencyInput({ value: e.target.value, type: contingency.type, total: contingency.total });
                        updateInput(e.target.id, { value: e.target.value, type: contingency.type, total: contingency.total });
                      }}
                    />
                    <FinanceSelectMenu type="contingency" id="contingency" />
                    {/* <Input
                      id="contingency"
                      style={{ maxWidth: 135, marginLeft: "auto" }}
                      size="small"
                      min="0"
                      type="number"
                      step="0.01"
                      autoComplete="off"
                      disabled={account_loading || input_loading}
                      value={contingencyInput.value}
                      onChange={(e) => {
                        setContingencyInput({ value: e.target.value, type: contingency.type, total: contingency.total });
                        updateInput(e.target.id, { value: e.target.value, type: contingency.type, total: contingency.total });
                      }}
                    />
                    <FinanceSelectMenu type="contingency" id="contingency" /> */}
                  </section>

                  <section className="input-row-two-col">
                    <label>Spacing Adders</label>
                  </section>
                  <section style={{ float: "left", width: 180, marginBottom: "16px" }}>
                    <ReactDataSheet
                      data={spacing_gcr_data}
                      sheetRenderer={renderSheet}
                      valueRenderer={(cell) => cell.value}
                      onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                      onCellsChanged={handleChanges}
                    />
                  </section>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { FinanceInstallCosts };
