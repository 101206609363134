//import { useDispatch } from "react-redux";
import { alertConstants, canopyConstants, siftConstants } from "../_constants";
import { authHeader } from "../_helpers";
import config from "config";

import { execute_job, poll_job, upload_to_s3 } from "./basics.jobs";
import { fetch_weather, get_ele_tz } from "./weather-fetch.jobs";
import { forEach } from "lodash";
import { get_defaults } from "../_reducers";
import { canopyServices, siftService } from "../_services";
import { collect_shade_inputs, createShadeGeoJson } from "../../EnvironmentalShade/ShadeHelpers";

export const canopy_job = (inputs, canopies, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "job_id",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux
    async run(dispatch) {
      let default_inputs = get_defaults();
      console.log(canopies);
      // console.log(default_inputs)
      // inputs = { ...default_inputs, ...inputs }

      inputs.job_id = undefined;

      // console.log('running job')

      // this triggers the UI state to be in a running state
      dispatch({ type: siftConstants.GET_RUNID_REQUEST });
      dispatch({ type: canopyConstants.UPDATE_CANOPY_RUN, key: "canopy_running" });
      // console.log('--',inputs)
      if (inputs.weather_id === undefined) {
        let weather_fetch_inputs = {
          weather_source: 1,
          latitude: inputs.center[1],
          longitude: inputs.center[0],
          map_center: [inputs.center[1], inputs.center[0]],
          // site_features: inputs.site_features,
          // elevation: inputs.elevation,
          // timezone: inputs.timezone,
        };
        let weather_data = await fetch_weather(weather_fetch_inputs, "sift-init").run(dispatch);
        console.log("weather_data", weather_data);
        if (weather_data.error) {
          dispatch({ type: canopyConstants.UPDATE_CANOPY_RUN, key: "canopy_running_reset" });
          // not sure if we truly need both but just covering base for now
          return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: weather_data });
        } else {
          inputs.weather = weather_data.job_id;
          inputs.weather_id = weather_data.job_id;
          inputs.latitude = weather_data.latitude;
          inputs.longitude = weather_data.longitude;
          inputs.elevation = weather_data.elevation;
          inputs.timezone = weather_data.timezone;
          inputs.weather_source = weather_data.source;
        }
      } else {
        inputs.weather = inputs.weather_id;

        // get ele/tz
        let ele_tz = await get_ele_tz([inputs.latitude, inputs.longitude]);
        if (ele_tz.data.ele.status === "OK") inputs.elevation = ele_tz.data.ele.results[0].elevation;
        if (ele_tz.data.tz.status === "OK") inputs.timezone = ele_tz.data.tz.rawOffset / 3600;
        if (ele_tz.data.loc.status === "OK") inputs.location = ele_tz.data.loc.results[0].formatted_address;
      }

      // collect location data
      if (!inputs.location_obj) {
        if (inputs.latitude && inputs.longitude) {
          let loc_obj_res = await siftService.getElevation([inputs.latitude, inputs.longitude]);

          inputs.location_obj = loc_obj_res.data.loc.results[0].address_components
            ? {
                city: loc_obj_res.data.loc.results[0].address_components[0].long_name,
                state: loc_obj_res.data.loc.results[0].address_components[1].long_name,
                country: loc_obj_res.data.loc.results[0].address_components[2].long_name,
              }
            : undefined;
        }
      }

      if (inputs.shade_results_id === undefined && Object.values(inputs.shade_objects).length > 0) {
        console.log("run shade simulation before running canopy");
        let shade_options = {
          latitude: inputs.center[1],
          longitude: inputs.center[0],
          elevation: 1,
          timeZone: inputs.timezone,
          shade_height_unit: inputs.shade_height_unit,
          shadow_day: "Jan 1 2021",
          type: "canopy",
        };

        let shade_inputs = collect_shade_inputs(canopies, inputs.shade_objects, shade_options);
        let shade_resp = await run_shade_job(shade_inputs).run(dispatch);
        if (shade_resp.error) {
          console.log("Shade simulation error:", shade_resp.error);
        } else {
          inputs.shade_results_id = shade_resp.shade_results_id;
        }
      }

      // run some checks to correct anything we notice is wrong
      // Shouldn't be used a lot, but can be a stop-gap till a better
      // solution is developed and implemented
      if (inputs.do_finance === 0) {
        inputs.analysis_period = 1;
      }

      // Got weather now, lets init
      // console.log("init run", inputs);
      let init_resp = await execute_job(inputs, "canopy-job");
      // console.log("init_resp", init_resp);

      if (init_resp.error) {
        // I really dislike making multiple dispat
        dispatch({ type: canopyConstants.UPDATE_CANOPY_RUN, key: "canopy_running_reset" });
        dispatch({ type: alertConstants.SUCCESS, message: "SIFT Run has been cancelled." });
        return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, failed: true, errors: init_resp.error });
      }

      inputs.layout_id = "canopy";
      inputs.job_id = init_resp.job_id;

      inputs.inverter_qty = 1;
      inputs.simple_inverter = 1;
      inputs.simple_inverter_dcac = 1.4;

      // console.log("-->", inputs)
      forEach(_.zip(init_resp.urls, inputs.canopy_ids), async (url_id, index) => {
        let url = url_id[0];
        let id = url_id[1];
        console.log(canopies[id]);
        let canopy_input = {
          ...JSON.parse(JSON.stringify(default_inputs)),
          ...JSON.parse(JSON.stringify(inputs)),
          layout_id: init_resp.job_id,
          ...canopies[id].module,
          sazm: canopies[id].azimuth,
          string_qty: 1,
          strings_in_parallel: 1,
          strings_per_inv: 1,
          total_string_qty: 1,
          mod_per_string: canopies[id].modX * canopies[id].modY,
          tilts: [canopies[id].tilt],
          tilt_min: canopies[id].tilt,
          tilt_max: canopies[id].tilt,
          track_mode: 0,
          gcr: 1.0,
          en_bifacial: 0,
          simple_inverter: 1,
          env_shade_id: inputs.shade_results_id ? `${inputs.shade_results_id}/${id}` : undefined,
        };
        let combine_iam = [];
        canopy_input.module_iam_ang.forEach((ang, index) => {
          canopy_input.module_iam_ang[index] = parseFloat(ang);
        });
        canopy_input.module_iam_eff.forEach((eff, index) => {
          canopy_input.module_iam_eff[index] = parseFloat(eff);

          combine_iam.push([canopy_input.module_iam_ang[index], canopy_input.module_iam_eff[index]]);
        });
        // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
        combine_iam.sort(function (a, b) {
          if (a[0] === b[0]) {
            return a[1] - b[1];
          }
          return a[0] - b[0];
        });
        let stringArray = combine_iam.map(JSON.stringify);
        let uniqueStringArray = new Set(stringArray);
        canopy_input.module_iam_ang = [];
        canopy_input.module_iam_eff = [];
        Array.from(uniqueStringArray, JSON.parse).forEach((row, index) => {
          canopy_input.module_iam_ang.push(row[0]);
          canopy_input.module_iam_eff.push(row[1]);
        });
        canopy_input.capacity = (canopy_input.mod_per_string * canopy_input.mod_rating) / 1000;

        canopy_input.dc_degrade /= 100;
        canopy_input.dc_module_quality_loss /= 100;
        canopy_input.dc_module_lid_loss /= 100;
        canopy_input.dc_module_mismatch /= 100;
        canopy_input.dc_strings_mismatch /= 100;
        canopy_input.dc_wiring_loss_at_stc /= 100;
        canopy_input.soiling.forEach((soil, index) => {
          canopy_input.soiling[index] = soil / 100;
        });
        canopy_input.bi_back_mismatch /= 100;
        canopy_input.bi_structureShadeFactor /= 100;
        canopy_input.bi_transmissionFactor /= 100;
        canopy_input.ac_wiring_loss_at_stc /= 100;
        canopy_input.ac_transformer_loss_constant /= 100;
        canopy_input.ac_transformer_loss_at_stc /= 100;
        canopy_input.ac_MV_line_loss_stc /= 100;
        canopy_input.ac_hv_transformer_loss_at_stc /= 100;
        canopy_input.ac_transmission_loss /= 100;
        canopy_input.ac_other_loss /= 100;

        canopy_input.platform = "internal";
        console.log("submitting", canopy_input);
        // put the inputs in S3 using the url
        let upload_resp = await upload_to_s3(url, JSON.stringify(canopy_input));

        if (upload_resp.error) {
          dispatch({ type: canopyConstants.RUN_CANOPY_COMPLETE, error: upload_resp.error });
        }

        // let inputValidation = validateInputs(canopy_input);
        // if (inputValidation.valid) {
        // 	// dispatch(execute_job(run_job(inputValidation.validatedInputs)));

        // } else {
        // 	// error
        // 	// dispatch(siftActions.errorSIFT(inputValidation.errors));
        // }
      });

      dispatch({ type: siftConstants.RUN_CAN_CANCEL, can_cancel: true, job_id: init_resp.job_id });
      //   console.log(init_resp);
      let init_poll_resp = await poll_job(inputs, "canopy-job", dispatch);

      // console.log("polling", init_poll_resp);
      if (init_poll_resp.error && init_poll_resp.error.msg && init_poll_resp.error.msg === "Cancelled") {
        return dispatch({ type: canopyConstants.RUN_CANOPY_COMPLETE, error: init_poll_resp.error.msg });
      }
      let bad_error_msg = "There was an issue with this run. Check inputs. Consider sending a bug report if this continues to happen.";
      if (init_poll_resp.error !== false) {
        if ((init_poll_resp.errors && init_poll_resp.errors.length === 2) || init_poll_resp.error.msg === bad_error_msg) {
          dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: init_poll_resp });
          return dispatch({ type: canopyConstants.RUN_CANOPY_COMPLETE, error: init_poll_resp });
        }
      }

      // Used to toggle off some shared UI state that prevents it from looking like SIFT is running after generating canopy results and then navigating back to sift
      dispatch({ type: siftConstants.CANOPY_RUN_COMPLETED });

      return dispatch({ type: canopyConstants.RUN_CANOPY_COMPLETE, response: init_poll_resp });

      // // triggers new ui state for run
      // dispatch({ type: siftConstants.GENERATE_RESULTS_VALIDATED });
      // inputs.result_handler = 1;

      // if (init_poll_resp.output.status == 100) {
      //   inputs.validation_job_id = inputs.job_id;
      //   inputs.job_id = undefined;
      //   let run_resp = await execute_job(inputs, "sift-run");
      //   inputs.job_id = run_resp.job_id;
      //   // cloud scale complete -- layouts running
      //   dispatch({ type: siftConstants.RUN_SIFT_SUCCESS, job_id: run_resp.job_id });
      //   let run_poll_resp = await poll_job(inputs, "sift-run", dispatch);
      //   // console.log(run_poll_resp);
      //   if (init_poll_resp.error && init_poll_resp.error.msg && init_poll_resp.error.msg == "Cancelled") {
      //     dispatch({ type: alertConstants.SUCCESS, message: "SIFT Run has been cancelled." });
      //     return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: init_poll_resp.error.msg });
      //   }
      //   dispatch({ type: siftConstants.RUN_CAN_CANCEL, can_cancel: false });

      //   if (run_poll_resp.error.msg == undefined && run_poll_resp.output.results.length > 0) {
      //     var layout_response = await get_layout(run_poll_resp.output.results[0].id);

      //     var download_json = await download_from_s3(layout_response);

      //     let response = { layout: download_json, record: run_poll_resp.output.results[0] };
      //     dispatch({ type: siftConstants.GET_LAYOUT_SUCCESS, response });
      //     // console.log(download_json)
      //     run_poll_resp.output.layout = download_json;
      //     run_poll_resp.output.meta = { ...inputs };
      //     // console.log('download_from_s3', pre_results)
      //     return dispatch({ type: siftConstants.GENERATE_RESULTS_SUCCESS, data: run_poll_resp.output });
      //   } else {
      //     return dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: run_poll_resp });
      //   }
      // }
    },
  };
};

// async function get_layout(id) {
//   const requestOptions = {
//     method: "GET",
//     headers: { ...authHeader(), "Content-Type": "application/json" },
//   };
//   return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then((response) => response.json());
// }

export const run_shade_job = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "job_id",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux

    async run(dispatch) {
      let error;
      let upload_resp;
      let results = {};
      // tell redux store we are starting a shade run
      // request a url and job_id from the backend
      let job_resp = await canopyServices._initShade();

      let job_id = job_resp.run_id;
      dispatch({ type: canopyConstants.RUN_SHADE_REQUEST, job_id });

      if (job_resp.error) {
        // if error with executing job then handle here
        // error = job_resp.error;
        // console.log("error in shade");
        error = "Shade Simulation failed. Please check your shade object inputs and try again. If the problem persists, consider submitting a bug report";
        dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: error });
        dispatch({ type: canopyConstants.RUN_SHADE_COMPLETE, errors: "shade_failed" });
        // STOP HERE
        return error;
      }

      if (job_resp.url) {
        upload_resp = await canopyServices._uploadShadeInputs(inputs, job_resp.url);
        if (upload_resp.error) {
          error = "Shade Simulation failed. Please check your shade object inputs and try again. If the problem persists, consider submitting a bug report";
          dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: error });
          dispatch({ type: canopyConstants.RUN_SHADE_COMPLETE, errors: "shade_failed" });
          return error;
        } else {
          inputs.job_id = job_resp.run_id;
          results["env_shade_id"] = inputs.job_id;
          let job_poll_resp = await poll_job(inputs, "env-shade", dispatch);
          // console.log("Shade resp", job_poll_resp);
          if (job_poll_resp) {
            if (job_poll_resp.error.msg == "Cancelled") {
              results = job_poll_resp;
            } else {
              // tell redux store we are done
              let shd_resp = await getShadeGeoJson(job_poll_resp.output.url);
              results = { ...results, ...shd_resp };
              dispatch({ type: canopyConstants.RUN_SHADE_COMPLETE, results: results });
            }
          }
        }
      }
      return results;
    },
  };
};

const extractIdFromKey = (key) => {
  // Split the key string based on the underscore character
  const parts = key.split("_");

  // Retrieve the first part of the resulting array
  const id = parts[0];

  return id;
};

const formatTime = (obj) => {
  const formattedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formattedValue = value < 12 ? `${value}am` : `${value == 12 ? 12 : value - 12}pm`;
      formattedObj[key] = formattedValue;
    }
  }

  return formattedObj;
};

const getShadeGeoJson = async (urls) => {
  let shade = {
    shadeAreas: {},
    shade_day_marks: {
      jun21: {},
      dec21: {},
    },
    shade_results: {},
  };

  for (let key in urls) {
    let shade_shapes = await canopyServices._getShadeGeoJson(urls[key]);
    let the_key = extractIdFromKey(key);

    const restructuredData = {};

    Object.keys(shade_shapes).forEach((key) => {
      restructuredData[key] = {};
      shade_shapes[key].forEach((array, index) => {
        if (array.length > 0) {
          if (Object.values(shade.shade_day_marks[key]).indexOf(index) === -1) {
            shade.shade_day_marks[key] = {
              ...shade.shade_day_marks[key],
              [index]: index,
            };
          }
          restructuredData[key][index] = createShadeGeoJson(array);
        }
      });
    });

    shade.shadeAreas[the_key] = restructuredData;
    shade.shade_day_marks["jun21"] = formatTime(shade.shade_day_marks["jun21"]);
    shade.shade_day_marks["dec21"] = formatTime(shade.shade_day_marks["dec21"]);
  }

  if (Object.values(shade.shadeAreas).length > 0) {
    return shade;
  } else {
    return { error: true, err_msg: "Shade simulation error" };
  }
};

// export const run_shade_job = (inputs, action = undefined) => {
//   return {
//     // started undefined till we get a job_id
//     job_id: undefined,
//     // temp var till normalized across all jobs
//     job_string: "job_id",
//     // action the job is taking
//     action: action,
//     // inputs sent to backend -- some jobs won't send any
//     inputs: inputs,
//     // job request action for redux

//     async run(dispatch) {
//       let error;

//       // tell redux store we are starting a shade run
//       dispatch({ type: canopyConstants.RUN_SHADE_REQUEST });

//       // request a url and job_id from the backend
//       let job_resp = await exe_job(inputs, "env-shade");

//       if (job_resp.error) {
//         // if error with executing job then handle here
//         // error = job_resp.error;
//         error = "Shade Simulation failed. Please check your shade object inputs and try again. If the problem persists, consider submitting a bug report";
//         dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: error });
//         dispatch({ type: canopyConstant.RUN_SHADE_COMPLETE, errors: "shade_failed" });
//         // STOP HERE
//         return;
//       }

//       // put the inputs in S3 using the url
//       let upload_resp = await upload_to_s3(job_resp.url, JSON.stringify(inputs));

//       if (upload_resp.error) {
//         // error = job_resp.error;
//         error = "Shade Simulation failed. Please check your shade object inputs and try again. If the problem persists, consider submitting a bug report";
//         dispatch({ type: siftConstants.GENERATE_RESULTS_FAILURE, error: error });
//         dispatch({ type: canopyConstant.RUN_SHADE_COMPLETE, errors: "shade_failed" });
//       }

//       inputs.job_id = job_resp.job_id;
//       // poll backend for updates on job_id
//       let job_poll_resp = await poll_job(inputs, "env-shade", dispatch);

//       if (job_poll_resp) {
//         // tell redux store we are done
//         dispatch({ type: canopyConstant.RUN_SHADE_COMPLETE, job_poll_resp });
//       }
//     },
//   };
// };
