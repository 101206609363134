import styled from "styled-components";

const FeatureManagerWrap = styled.section`
  width: 100%;
  padding: 10px;

  .boundaries,
  .exclusions,
  .inactives,
  .shadeObjectManager,
  .layout-list {
    width: 100%;
    margin-bottom: 10px;
    height: auto;
    transition: height 0.2 var(--easing-spring);

    .folder-header {
      display: flex;
      align-items: center;
      height: 30px;

      input[type="checkbox"] {
        accent-color: var(--background-highlight-color);
      }
      .folder-label {
        display: flex;
        align-items: center;

        img {
          margin: 0px 5px 0px 8px;
          height: 15px;
        }

        p {
          margin: 0;
        }
      }
      &:hover {
        background-color: var(--map-manager-hover-color);
        cursor: pointer;
      }
    }

    ul {
      padding: 0;
      margin: 0px 10px;

      li {
        display: grid;
        grid-template-columns: min-content min-content 1fr min-content;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        list-style: none;
        height: 30px;
        padding-left: 25px;

        &:hover {
          background-color: var(--map-manager-hover-color);
        }

        & > button {
          margin-right: 5px;
          margin-left: auto;
          border: none;
          background: transparent;

          img {
            height: 18px;
            width: 18px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .poly-label {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;

        img,
        label {
          &:hover {
            cursor: pointer;
          }
        }

        img {
          margin-right: 5px;
          height: 15px;
        }
      }

      input[type="checkbox"] {
        margin-right: 10px;
        accent-color: var(--background-highlight-color);

        &:checked {
          background-color: var(--background-highlight-color);
        }
      }
    }

    .rack-li {
      padding-left: 0px;
    }

    .rack-ul {
      margin: 0px;
    }

    .dragging {
      opacity: 0.5;
      /* transition: 0.01s;
      transform: translateX(-9999px); */
    }

    .highlight-selected {
      background-color: var(--map-manager-hover-color);
    }

    .hide-drag-item {
    }
  }
`;

const ToggleArrow = styled.svg`
  width: 15px;
  margin-right: 5px;
  transition: transform 0.2s var(--easing-spring);
  transform: ${(props) => (props.is_open ? "rotate(90deg)" : "rotate(0deg)")};
`;

const DragIconWrap = styled.svg`
  margin-right: 8px;
  &:hover {
    cursor: grab;
  }
`;

const MapLayoutManagerWrap = styled.section`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content 1fr;
  grid-template-areas:
    "rack-title rack-switch"
    "rack-divider rack-divider"
    "rack-actions rack-actions";
  gap: 10px 0px;

  .rack-title {
    grid-area: rack-title;

    h4 {
      margin: 0px;
    }

    p {
      margin: 0px;
    }
  }

  .rack-switch {
    grid-area: rack-switch;
    display: grid;
    margin-top: 3px;
  }

  .rack-divider {
    grid-area: rack-divider;
    border-bottom: 1px solid #e5e7e7;
    margin-bottom: 7px;
    height: 7px;
  }

  .rack-actions {
    grid-area: rack-actions;
    align-items: baseline;
    display: grid;

    .layout-row {
      width: 100%;
      padding: 3px 0px;
      display: grid;
      align-items: center;
      grid-template-columns: min-content 1fr;

      label {
        grid-column: 1;
        margin-right: auto;
        white-space: nowrap;
      }

      .layout-row-buttons {
        display: flex;
        align-items: center;
        margin-left: auto;

        button {
          grid-column: 2;
        }
      }

      .d-pad {
        display: grid;
        justify-self: end;
        margin-right: 10px;
        grid-template-rows: 25px 25px 25px;
        grid-template-columns: 32px 32px 32px;
        grid-template-areas:
          "upleft upCenter upright"
          "leftmid mid rightmid"
          "downLeft downCenter downRight";

        .up {
          grid-area: upCenter;
        }
        .down {
          grid-area: downCenter;
        }
        .left {
          grid-area: leftmid;
        }
        .right {
          grid-area: rightmid;
        }
      }
    }
  }
`;

export { FeatureManagerWrap, ToggleArrow, DragIconWrap, MapLayoutManagerWrap };
