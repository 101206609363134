import React from "react";
import { useSelector } from "react-redux";

import { Popup } from "react-leaflet";

import * as turf from "@turf/turf";

const CanopyNames = ({ id }) => {
  const canopy = useSelector((state) => state.canopy.canopies[id]);
  const show_names = useSelector((state) => state.canopy.show_names);

  if (!canopy) {
    return <></>;
  }

  const getCenter = (geoJson) => {
    let centerPoint = turf.center(geoJson);
    return { lat: centerPoint.geometry.coordinates[1], lng: centerPoint.geometry.coordinates[0] };
  };

  return (
    <>
      {canopy && show_names && (
        <Popup style={{ margin: "5px 20px" }} closeOnClick={false} draggable={false} className="canopy-label" position={getCenter(canopy.geoJson)} autoClose={false}>
          <p style={{ margin: "0px" }}>{canopy.name}</p>
        </Popup>
      )}
    </>
  );
};

export { CanopyNames };
