import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
//import { createLogger } from "redux-logger";
import requestMiddleware from "./requestMiddleware";
import rootReducer from "../_reducers";
import LogRocket from "logrocket";
import config from "config";

//const loggerMiddleware = createLogger();
const initialState = {};
const middleware = [thunkMiddleware, requestMiddleware];
// const middleware = [thunkMiddleware];
export const store =
  config.env === "prod" || config.env === "stage"
    ? // production
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware)
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
        )
      )
    : // development
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware),
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
          window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );

// // console.log(process.env.NODE_ENV, config.env)
// const createStoreWithMiddleware = () => {
//     let enhancers = applyMiddleware(...middleware);

//     if (config.env === "stage") {
//         enhancers = compose(
//             enhancers,
//             LogRocket.reduxMiddleware({
//                 // to ignore actions from logging
//                 /*actionSanitizer: function (action) {
//                     if (action.type === 'ignoreThis') {
//                         return null;
//                     }
//                     return action;
//                 },*/
//                 // Specify state values to exclude from logging
//                 // stateSanitizer: function (state) {
//                 //     return {
//                 //         ...state,
//                 //         removeThisKey: undefined,
//                 //     };
//                 // },
//             })
//         );
//     }

//     return createStore(rootReducer, initialState, enhancers);
// };
// export const store =
//   config.env === "prod" || config.env === "stage"
//     ? createStoreWithMiddleware()
//       // production
//      /* createStore(
//         rootReducer,
//         initialState,
//         compose(
//           applyMiddleware(...middleware,LogRocket.reduxMiddleware({}))
//           // Redux Devtools setup
//           // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
//         )
//       )*/
//     : // development
//       createStore(
//         rootReducer,
//         initialState,
//         compose(
//           applyMiddleware(...middleware),
//           // Redux Devtools setup
//           // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
//           window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//         )
//       );
