import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { ReportPageFooterWrap } from "./styles";

const LiveReportFooter = ({ pageNum, designation }) => {
  const pageLength = useSelector((state) => state.sift.ioManager.report.reportData.numOfPages);

  const [numOfPages, setNumOfPages] = useState(pageLength);

  useEffect(() => {
    setNumOfPages(pageLength);
  }, [pageLength]);

  return (
    <ReportPageFooterWrap>
      <div className="page-designation">
        <p>{designation ? designation : ""}</p>
      </div>
      <div className="powered-by-sunfig">{/* <img src={logo} /> */}</div>
      <div className="page-number">
        {/* <p>{`Page ${pageNum} of ${numOfPages}`}</p> */}
        <p>{`Page ${pageNum}`}</p>
      </div>
    </ReportPageFooterWrap>
  );
};

export { LiveReportFooter };
