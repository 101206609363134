import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber } from "../../../../../Redux";

import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;

import { InputRow } from "./styles";
import { debouncedUpdateinput } from "./inputs.helper";

function ModuleHeightInput() {
  const dispatch = useDispatch();

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const mod_height = useSelector((state) => state.canopy.canopies[selected_canopy]?.module?.mod_height);
  // const mod_height = useSelector((state) => state.carport.selectedInputs.module.mod_height);

  const [local_mod_height, set_local_mod_height] = useState(mod_height);
  const [local_loading, set_local_loading] = useState(false);
  const [mod_height_error, set_mod_height_error] = useState(undefined);

  useEffect(() => {
    if (mod_height && mod_height == local_mod_height) {
      set_local_loading(false);
    } else if (!local_loading) {
      set_local_mod_height(mod_height);
    }
  }, [mod_height]);

  function onChangeInput(e) {
    set_local_mod_height(e.target.value);

    let changed_value = parseFloat(e.target.value);

    if (!isNumber(changed_value)) {
      set_mod_height_error("Invalid Input");
      return;
    } else if (mod_height_error != undefined) {
      set_mod_height_error(undefined);
    }

    set_local_loading(true);
    // debounced method
    debouncedUpdateinput.cancel();
    debouncedUpdateinput(dispatch, selected_canopy, "mod_height", changed_value);
  }

  return (
    <InputRow>
      <label>Module Height:</label>
      <Input
        size="small"
        value={local_mod_height}
        type="number"
        step={0.1}
        min={0}
        suffix={local_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
        max="999"
        onChange={onChangeInput}
      ></Input>
    </InputRow>
  );
}

export { ModuleHeightInput };
