import React from "react";
import { Tag, Tooltip } from "antd";
import { CloudOutlined, CloudDownloadOutlined } from "@ant-design/icons";

export const LockedByYou = (
  <span>
    <Tag color="#f3f3f3">
      <CloudDownloadOutlined style={{ margin: "5px 5px 0px 0px", color: "#333" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked Out by me.</div>
            <div>Project is Read/Write. Check in when finished.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>out by me</u>
      </Tooltip>
    </Tag>
  </span>
);
export const LockedBySomeone = (email, short = false) => (
  <span>
    <Tag color="#f3f3f3">
      <CloudDownloadOutlined style={{ margin: "5px 5px 0px 0px", color: "#333" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked Out by {email}</div>
            <div>Project is Read Only.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>out by other</u>
      </Tooltip>
    </Tag>
  </span>
);
export const NotLocked = (
  <span>
    <Tag color="#f3f3f3">
      <CloudOutlined style={{ margin: "5px 5px 0px 0px", color: "#333" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked In.</div>
            <div>Project is Read Only. Check Out for Read/Write.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>checked in</u>
      </Tooltip>
    </Tag>
  </span>
);

const format_date_ts = (timestamp) => {
  let date = new Date(timestamp * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return format_d.toString();
};

export const collab_columns = [
  {
    title: "Email",
    dataIndex: "email",
    width: "275px",
    // filters: [],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value, record) => record.email.indexOf(value) === 0,
    sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ["descend"],
  },
  {
    title: "Owner",
    dataIndex: "creator",
    width: "275px",
    render: (text, record) => <span>{text == 1 ? "Yes" : "No"}</span>,
  },
  // {
  // 	title: 'Roles',
  // 	dataIndex: 'roles',
  // 	width: '100px',
  // 	// render: (text, record) => <span>{text==1?'Yes':'No'}</span>
  // },
  {
    title: "Status",
    dataIndex: "locked",
    width: "200px",
    render: (text, record) => <span>{text == 1 && "Checked-Out"}</span>,
  },
];

export const notes_columns = [
  {
    title: "Email",
    dataIndex: "email",
    width: "275px",
    // onFilter: (value, record) => record.email.indexOf(value) === 0,
    sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ["descend"],
  },
  {
    title: "Note",
    dataIndex: "note",
    width: "275px",
  },
  {
    title: "Date",
    dataIndex: "posted_dt",
    width: "200px",
    render: (text, record) => <span>{format_date_ts(record.posted_dt)}</span>,
    sorter: (a, b) => a.posted_dt - b.posted_dt,
    sortDirections: ["descend"],
    // sortOrder: collab_sortedInfo.field === "posted_dt" && collab_sortedInfo.order,
  },
];

function getTime(editDate) {
  var date = new Date(editDate * 1000);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return format_d.toString();
}
