//prettier-ignore
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalMaskWrap, ModalWrap } from "./styles";
import { Button } from "../Button";

import { close } from "../../../assets/images";

const Modal = ({
  open = true,
  height = undefined,
  width = undefined,
  maskCloseable = true,
  onCancel = undefined,
  onOk = undefined,
  title = undefined,
  canClose = true,
  showHeader = true,
  showFooter = true,
  showOkText = true,
  okText = "Ok",
  cancelText = "Close",
  footer = undefined,
  containerId = "root",
  centered = false,
  children,
}) => {
  const ModalContent = () => {
    // const [animate, set_animate] = useState(false);

    // useEffect(() => {
    //   if (open) {
    //     set_animate(true);
    //   }
    // }, [open]);

    return (
      <>
        <ModalMaskWrap className={"modal-open"} onClick={() => maskCloseable && onCancel(!open)}>
          <ModalWrap className={ "modal-open"} height={height} width={width} centered={centered} onClick={(event) => event.stopPropagation()}>
            {showHeader && (
              <section className="modal-header">
                {title && <h4 className="header-text">{title}</h4>}
                {canClose && (
                  <section className="modal-close">
                    <a onClick={() => onCancel()}>
                      <img src={close} />
                    </a>
                  </section>
                )}
              </section>
            )}
            <section className="modal-content">{children}</section>
            {showFooter == false ? null : footer ? (
              <section className="custom-footer">{footer}</section>
            ) : (
              <section className="modal-footer">
                <Button size="small" type="ghost" label={cancelText} onClick={() => onCancel(!open)} />
                {showOkText && <Button size="small" label={okText} onClick={() => onOk()} />}
              </section>
            )}
          </ModalWrap>
        </ModalMaskWrap>
      </>
    );
  };

  if (!open) {
    return null;
  }

  return createPortal(<ModalContent />, document.getElementById(containerId));
};

export { Modal };
