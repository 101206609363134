import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useMap } from "react-leaflet";

export function useKey(key) {
  const map = useMap();
  const uiState = useSelector((state) => state.sift.ioManager.uiState);
  const ui_state_two = useSelector((state) => state.inputs.uiState);
  const user = useSelector((state) => state.user);
  const projectManager = useSelector((state) => state.sift.projectManager);
  const search_visible = useSelector((state) => state.inputs.uiState.search_visible);
  const show_sf_modal = useSelector((state) => state.inputs.uiState.show_sf_modal);

  // Keep track of key state
  const [pressed, setPressed] = useState(false);

  const stop_action_ref = useRef();

	const local_checks =  useMemo(() => {
    let new_checks =
      user.funnel_visible ||
      user.login_visible ||
      uiState.bug_visible ||
      uiState.patch_visible ||
      uiState.helper_visible ||
      uiState.collabVisible ||
      uiState.show_live_report ||
      ui_state_two.pm_modal_visible ||
      ui_state_two.collab_modal_visible ||
      ui_state_two.disable_hotkeys ||
      projectManager.modal_visible.visible ||
      show_sf_modal ||
      search_visible;
		return new_checks
  }, [
    user.funnel_visible,
    user.login_visible,
    uiState.bug_visible,
    uiState.patch_visible,
    uiState.helper_visible,
    uiState.collabVisible,
    uiState.show_live_report,
    ui_state_two.pm_modal_visible,
    ui_state_two.collab_modal_visible,
    ui_state_two.disable_hotkeys,
    projectManager.modal_visible.visble,
    show_sf_modal,
    search_visible,
  ]);
  // useEffect(() => {
  //   if (stop_action_ref.current) {
  //     console.log("stop key down", stop_action_ref.current);
  //   }
  // }, [stop_action_ref.current]);

  useEffect(() => {
    const stop_key_down =
      user.funnel_visible ||
      user.login_visible ||
      uiState.bug_visible ||
      uiState.patch_visible ||
      uiState.helper_visible ||
      uiState.collabVisible ||
      uiState.show_live_report ||
      ui_state_two.pm_modal_visible ||
      ui_state_two.collab_modal_visible ||
      ui_state_two.disable_hotkeys ||
      projectManager.modal_visible.visible ||
      show_sf_modal ||
      search_visible;

    stop_action_ref.current = stop_key_down;
    map.fire("disableMapHotkeys", { disable: stop_action_ref.current });
  }, [
    user.funnel_visible,
    user.login_visible,
    uiState.bug_visible,
    uiState.patch_visible,
    uiState.helper_visible,
    uiState.collabVisible,
    uiState.show_live_report,
    ui_state_two.pm_modal_visible,
    ui_state_two.collab_modal_visible,
    ui_state_two.disable_hotkeys,
    projectManager.modal_visible.visble,
    show_sf_modal,
    search_visible,
  ]);

  // Does an event match the key we're watching?
  const match = (event) => key.toLowerCase() == event?.key?.toLowerCase();

  // Event handlers
  const onDown = (event) => {
		if (local_checks) return;
    if (stop_action_ref.current) return;
    if (match(event)) setPressed(true);
  };

  const onUp = (event) => {
		if (local_checks) return;
    if (stop_action_ref.current) return;
    if (match(event)) setPressed(false);
  };

  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown);
    window.addEventListener("keyup", onUp);
    return () => {
      window.removeEventListener("keydown", onDown);
      window.removeEventListener("keyup", onUp);
    };
  }, [key]);

  return pressed;
}
