import styled from "styled-components";

const CanopySelectWrap = styled.section`
  max-height: 250px;
  overflow-y: scroll;

  .canopy-feature {
    width: 100%;
    display: flex;
    place-content: end space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 3px 5px;

    p {
      margin: 0px;
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      cursor: pointer;
    }

    .canopy-select-buttons {
      button {
        margin-right: 5px;
        border: none;
        background: transparent;

        &:hover {
          cursor: pointer;
        }

        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .selected-canopy {
    background-color: var(--background-highlight-color);
    cursor: default;
  }
`;

export { CanopySelectWrap };
