import React, { useState, useEffect } from "react";
import { LayerGroup, Polygon, GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";

const WiringKeys = ["inverter", "racks"];

const EditedLayer = ({ show_racks = true, show_inverters = true }) => {
  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  const layer_toggle = useSelector((state) => state.sift.ioManager.uiState.layer_toggle);
  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);
  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const edited_racks = selectedResultId && edited_layouts && edited_layouts[selectedResultId] ? edited_layouts[selectedResultId].inverter_groups[0].combiner_groups[0]["rack_groups"] : undefined;
  if (!layout_visible) return <></>;

  return (
    <>
      <LayerGroup key="groups">
        {Object.values(layout).length > 0 &&
          Object.values(layout.inverter_groups).map((group, group_index) => {
            let group_color = group?.wires?.properties?.color;
            return Object.keys(group).map((key, index) => {
              if (!WiringKeys.includes(key)) {
                return;
              }
              let indx = 0;
              // console.log(key, index, group[key]);
              // group[key].color = '#33A2FF'
              if (key == "inverter" && show_inverters) {
                group[key].color = "#ee00ff";
                group[key].fillColor = "none";
                group[key].weight = 2;
                indx = 597;
              }
              if (key == "combiner_boxes") {
                if (!layer_toggle.show_combiners) return <></>;
                group[key].color = "#7FFF00";
                group[key].fillColor = "#7FFF00";
                group[key].weight = 4;
              }
              if (key == "racks" && show_racks) {
                if (edited_racks) {
                  return (
                    Object.values(edited_racks).length > 0 &&
                    Object.values(edited_racks).map((feature) => {
                      if (feature.enabled == false) return;
                      return (
                        <GeoJSON
                          interactive={false}
                          data={feature.geoJson}
                          key={`${feature.index}_${show_layout_editor}`}
                          style={{
                            fillColor: "transparent",
                            weight: 2,
                            color: "#33A2FF",
                            dashArray: "none",
                          }}
                        ></GeoJSON>
                      );
                    })
                  );
                } else {
                  group[key].color = group_color; //33A2FF
                  group[key].fillColor = "none";
                  group[key].weight = 2;
                  indx = -599;
                }
                group[key].color = group_color; //33A2FF
                group[key].fillColor = "none";
                group[key].weight = 2;
                indx = -599;
              }
              if (key == "wires") {
                if (!layer_toggle.show_wires) return <></>;
                // return
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;

                // #33A2FF
                // #5377ad
                // #33A2FF
                // #33A2FF
                // #33A2FF
                // 131 - #AAFFFF
                // 141 - #AAEAFF
                // 151 - #AAD4FF
                // 161 - #AABFFF
                // 171 - #AAAAFF
                // 181 - #BFAAFF
              }
              if (key == "strings") {
                if (!layer_toggle.show_strings) return <></>;
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;
              }

              return <Polygon positions={group[key]?.geometry?.coordinates} key={index} color={group[key]?.color} fillColor={group[key]?.fillColor} id={indx} weight={group[key]?.weight}></Polygon>;
            });
          })}
      </LayerGroup>
    </>
  );
};

export { EditedLayer };
