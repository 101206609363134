import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";

import { inputsActions } from "../../../../Redux";
import { draw_polygon, square, square_green } from "../../../../../assets/images";
import { useKey } from "../../MapEventHandler/useKey";

import L from "leaflet";
// import "../../../leaflet.draw.css";
import { Tooltip } from "antd";

const getTouchIconBase = (platform) => {
  let touch_icon_base;
  if (platform == "internal_sift") {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square_green,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  } else {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  }

  return touch_icon_base;
};

const DrawPolygon = ({ toggle_polygon_tools, expand_polygon_tools }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const platform = useSelector((state) => state.user.platform);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const is_drawing_polyline = useSelector((state) => state.inputs.uiState.is_drawing_polyline);

  const [current_polygon, set_current_polygon] = useState(null);

  const drawKey = useKey("d");

  // useEffect(() => {
  //   const targetNode = document.getElementById("map");

  //   const observer = new MutationObserver((mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (mutation.attributeName === "style") {
  //         const cursorStyle = targetNode.style.cursor;
  //         console.log("Cursor style changed to:", cursorStyle);
  //       }
  //     });
  //   });

  //   observer.observe(targetNode, { attributes: true });

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (!map) return;

    let the_map_has_focus = document.getElementById("map") == document.activeElement;

    if (drawKey && the_map_has_focus) {
      drawPolygon();
    }
  }, [drawKey]);

  useMapEvents({
    cancelDraw: () => {
      if (is_drawing) cancelDraw();
    },
    startDrawing: () => drawPolygon(),
  });

  // hotkey stuff
  const complete_poly_hotkey = useKey("c");

  useEffect(() => {
    if (!current_polygon) return;

    if (is_drawing && current_polygon._markers.length > 2) {
      map.fire("completePolyFromHotkey", { current_polygon });
      dispatch(inputsActions.update_ui_state("is_drawing", false));
      current_polygon.disable();
      set_current_polygon(null);
    }
  }, [complete_poly_hotkey]);

  const drawPolygon = () => {
    toggle_polygon_tools(expand_polygon_tools);
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 4,
        fillColor: "#FFFFFF",
      },
      icon: getTouchIconBase(platform),
    };

    let polygon = new L.Draw.Polygon(map, options);

    if (is_drawing) {
      cancelDraw();
    } else {
      set_current_polygon(polygon);
      polygon.enable();
      dispatch(inputsActions.update_ui_state("is_drawing", true));
      if (is_drawing_polyline) map.fire("cancelDrawLine");
    }
  };

  const cancelDraw = () => {
    dispatch(inputsActions.update_ui_state("is_drawing", false));
    current_polygon.disable();
    set_current_polygon(null);
  };

  return (
    <Tooltip placement="bottom" title="Draw" mouseEnterDelay={0.5}>
      <button className={is_drawing ? "button-active" : ""} onClick={() => drawPolygon()}>
        <img draggable={false} src={draw_polygon} />
      </button>
    </Tooltip>
  );
};

export { DrawPolygon };
