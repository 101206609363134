import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSiteCenter, siftActions, isAuthenticated, UserActions } from "../Redux";
import config from "config";
// import { authHeader } from "../Redux/_helpers";
import { useAuth } from "../Auth/AuthProvider";
import useIsTabActive from "../_hooks/useIsTabActive";
import fetchUtil from "../Redux/_services/fetchUtil";


const BugReport = () => {
  const inputs = useSelector((state) => state.inputs);
  const canopy = useSelector((state) => state.canopy);

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  const get_inputs = () => {
    let project_inputs;
    if (internal_sift && using_canopy) {
      project_inputs = {
        project_id: inputs.project_id,
        rectangles: canopy.canopies,
        images: canopy.images,
        project_name: inputs.project_name,
        module: inputs.mod_id,
        map_center: Object.values(canopy.canopies).length > 0 ? getSiteCenter(canopy.canopies) : inputs.map_center,
        uiState: { pm_modal_visible: false },
      };
    } else {
      project_inputs = { ...inputs };
    }
    return project_inputs;
  };


  useEffect(() => { 
    let browser_version = undefined;
    try {
      browser_version = get_browser_and_version();
    } catch (error) {
      console.log(`Couldn't detect browser version`);
    }
  
    // let bug_message = `${bug_msg} ${browser_version ? `(Browser: ${browser_version})` : "(Browser: N/A)"}`;
    // grab either canopy or SIFT inputs
    let project_inputs = get_inputs();
  
    console.log("project_inputs", project_inputs);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        message: "Intercom Triggered Bug Report",
        inputs: project_inputs,
      }),
    };
    fetchUtil(`${config.apiUrl}/dash/bug/`, requestOptions);

  }, []);
  
  return null
};


const WebSockets = () => {
  const dispatch = useDispatch();
  const { is_authenticated } = useAuth();
  const isTabActive = useIsTabActive();

  const user_id = useSelector((state) => state.user.access.user_id);
  const email = useSelector((state) => state.user.email);

  const [do_bug_report, set_do_bug_report] = useState(false);
  const [ws_active, set_ws_active] = useState(false);


  const webSocketUrl = `wss://3a4j2624db.execute-api.us-east-2.amazonaws.com/${config.env}/`;

  useEffect(() => { 
    console.log('isTabActive', isTabActive)
  }, [isTabActive]);
  useEffect(() => { 
    if (do_bug_report) {
      set_do_bug_report(false);
    }
  }, [do_bug_report]);

  useEffect(() => {
    if (is_authenticated) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user && user.access) {
        initWebsocket(webSocketUrl, null, 50000, 10).then(
          function (socket) {
            console.log('socket initialized!');
            set_ws_active(true);
            //do something with socket...

            //if you want to use the socket later again and assure that it is still open:
            initWebsocket(webSocketUrl, socket, 50000, 10).then(function (socket) {
              //if socket is still open, you are using the same "socket" object here
              //if socket was closed, you are using a new opened "socket" object
            });
          },
          function () {
            console.log("init of socket failed!");
            set_ws_active(false);
          }
        );

        // console.log(webSocketUrl);
        // //  + "?Authorization=" + auth.Authorization
        // const socket = new WebSocket(webSocketUrl);

        // socket.onopen = (event) => {
        //   console.log("Connected to WebSocket", event);
        //   let mess = JSON.stringify({
        //     action: "authenticate",
        //     email,
        //     user_id,
        //     auth: user.access.token,
        //   });
        //   socket.send(mess);
        // };

        // socket.onmessage = (event) => {
        //   // console.log("onMessage event", event);
        //   try {
        //     const message = JSON.parse(event.data);
        //     console.log("WS message", message);
        //     if (message?.action === "report_bug") {
        //       //if a ticket has been created, grab the user description and send the bug report
        //       handleBugReport(message);
        //     }

        //     if (message?.action == "update_plan") {
        //       //update plan in store to trigger plan upgrades/downgrades
        //       OnPlanUpdated(message.data.plan_id)
        //     }
        //     // set_socket_id(message.connectionId);
        //     // setMessages((prevMessages) => [...prevMessages, message]);
        //   } catch (error) {
        //     console.log("something blew up 🔥🔥", event, error);
        //   }
        // };

        // socket.onclose = () => {
        //   console.log("Disconnected from WebSocket");
        // };

        // socket.onerror = (error) => {
        //   console.log("error", error);
        // };
        // clean it up!
        return () => {
          // socket.close();
        };
      }
    }
  }, [is_authenticated]);

  const OnPlanUpdated = (plan, paid_plan) => {
    console.log("PLAN CHANGE:", plan, paid_plan);
    dispatch(UserActions.updatePlanTo(plan, paid_plan));
  };

  function initWebsocket(url, existingWebsocket, timeoutMs, numberOfRetries) {
    let user = JSON.parse(localStorage.getItem("user"));
    timeoutMs = timeoutMs ? timeoutMs : 1500;
    numberOfRetries = numberOfRetries ? numberOfRetries : 0;
    var hasReturned = false;
    var promise = new Promise((resolve, reject) => {
      setTimeout(function () {
        if (!hasReturned) {
          console.info("opening websocket timed out: " + url);
          rejectInternal();
        }
      }, timeoutMs);
      if (!existingWebsocket || existingWebsocket.readyState != existingWebsocket.OPEN) {
        // if (existingWebsocket) {
        //   existingWebsocket.close();
        // }
        var websocket = new WebSocket(url);
        websocket.onopen = function () {
          if (hasReturned) {
            websocket.close();
          } else {
            // console.log("Connected to WebSocket", event);
            let mess = JSON.stringify({
              action: "authenticate",
              email,
              user_id,
              auth: user.access.token,
            });
            websocket.send(mess);
            // console.info('websocket to opened! url: ' + url);
            resolve(websocket);
          }
        };
        websocket.onmessage = (event) => {
          // console.log("onMessage event", event);
          try {
            const message = JSON.parse(event.data);
            console.log("WS message", message);
            if (message?.action === "report_bug") {
              //if a ticket has been created, grab the user description and send the bug report
              // handleBugReport(message);
              set_do_bug_report(true);
            }

            if (message?.action == "update_plan") {
              //update plan in store to trigger plan upgrades/downgrades
              OnPlanUpdated(message.data.plan_id, message.data.plan_paid_id);
            }
            // set_socket_id(message.connectionId);
            // setMessages((prevMessages) => [...prevMessages, message]);
          } catch (error) {
            console.log("something blew up 🔥🔥", event, error);
          }
        };
        websocket.onclose = function () {
          console.info("websocket closed! url: " + url);
          rejectInternal();
        };
        websocket.onerror = function () {
          console.info("websocket error! url: " + url);
          rejectInternal();
        };
      } else {
        resolve(existingWebsocket);
      }

      function rejectInternal() {
        if (numberOfRetries <= 0) {
          set_ws_active(false);
          reject();
        } else if (!hasReturned) {
          hasReturned = true;
          console.info("retrying connection to websocket! url: " + url + ", remaining retries: " + (numberOfRetries - 1));
          initWebsocket(url, null, timeoutMs, numberOfRetries - 1).then(resolve, reject);
        }
      }
    });
    promise.then(
      function () {
        hasReturned = true;
      },
      function () {
        hasReturned = true;
      }
    );
    return promise;
  }

  return (
    <>
      {do_bug_report && <BugReport />}
    </>
  );
};

export { WebSockets };
