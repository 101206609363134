import React from "react";
import { LayerGroup, GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";

const FeatureLayer = () => {
  const site_features = useSelector((state) => state.inputs.site_features);

  return (
    <LayerGroup key="Layout-editor-site-features">
      {site_features &&
        Object.values(site_features).map((feature) => {
          return (
            <GeoJSON
              interactive={false}
              data={feature}
              key={`${feature.properties.index}_editorPoly`}
              style={{
                fillColor: "none",
                fillOpacity: 0.01,
                weight: 1,
                color: feature.properties.identity === 0 ? "white" : feature.properties.identity === 1 ? "red" : feature.properties.identity === 2 ? "yellow" : "white",
              }}
            ></GeoJSON>
          );
        })}
    </LayerGroup>
  );
};

export { FeatureLayer };
