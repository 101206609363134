import React, { useEffect, Fragment } from "react";

const LiveCanopySummaryTable = (props) => {
  const checkForInverted = () => {
    let invertedPresent = false;
    Object.values(props.reportData.results).map((data) => {
      if (data["Canopy Type"] == "inverted") {
        invertedPresent = true;
      }
    });
    return invertedPresent;
  };

  const prettifyText = (text) => {
    // takes in the ugly text from the product data planeType and makes it a little more presentable
    switch (text) {
      case "single_slope":
        return "Single Slope";
      case "inverted":
        return "Inverted";
      case "dual_tilt":
        return "Dual Tilt";
      default:
        return "undefined";
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Canopy Name</th>
          <th>Module</th>
          <th>Canopy Type</th>
          <th>Azimuth</th>
          <th>{checkForInverted() ? `Tilt (°) To/From` : `Tilt (°)`}</th>
          <th>Dimensions (LxW) Qty</th>
          <th>Dimensions (LxW) Ft</th>
          <th>Module Qty</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(props.reportData.results).map((result, index) => {
          return (
            <tr key={index}>
              <td className="canopy-name">{result.Name}</td>
              <td>{result.Module}</td>
              <td>{prettifyText(result["Canopy Type"])}</td>
              <td>{`${parseInt(result["Azimuth (°)"])}°`}</td>
              <td>{result["Canopy Type"] == "inverted" ? `${result["Tilt To Azimuth"]}° / ${result["Tilt From Azimuth"]}°` : `${result["Tilt (°)"]}°`}</td>
              <td>{`${result["Mod Dimension X Qty"]}mod by ${result["Mod Dimension Y Qty"]}mod`}</td>
              <td>{`${result["Mod Dimension X Ft"].toFixed(1)}ft by ${result["Mod Dimension Y Ft"].toFixed(1)}ft`}</td>
              <td>{result["Module Qty"]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { LiveCanopySummaryTable };
