import React from "react";

import { Result, Typography, Button } from "antd";
import { IssuesCloseOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const ErrorFromSIFT = (props) => {
  /* inputs    
    error_messages
  */
  console.log("props", props);
  return (
    <Result
      className="error-results"
      status="error"
      title="SIFT Failed"
      subTitle={
        <div style={{ textAlign: "left", paddingLeft: "10%", paddingRight: "10%" }}>
          Please review the errors below and modify your inputs as needed. Report any unresolved issues to the Sunfig team with the bug report button at the bottom of the far left toolbar of the main
          SIFT window.
        </div>
      }
      icon={<div style={{ marginBottom: "-24px" }}></div>}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            SIFT detected the following errors and warnings:
          </Text>
        </Paragraph>
        {/* map errors_messages below */}
        {props.error_messages?.map((message, index) => {
          return (
            <Paragraph key={index}>
              <IssuesCloseOutlined style={{ color: "red" }} type="close-circle" /> {message}
            </Paragraph>
          );
        })}
      </div>
    </Result>
  );
};

export { ErrorFromSIFT };
