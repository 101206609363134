import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "./components/Auth";
import { ProtectedRoute } from "./routes/ProtectedRoute";

import { PasswordReset_New, SIFT } from "./routes/";

const Terrasmart_Proxy = () => {
  return <iframe src="https://www.terrasmart.com/products/software/sift/" style={{ border: "none", width: "100%", height: "100vh" }}></iframe>;
};

const routesForPublic = [
  // {
  //   path: "/siftlauncher/:command",
  //   element: <SIFT/>,
  // },
  {
    path: "/sift",
    element: <SIFT />,
  },
  {
    path: "/canopy",
    element: <SIFT />,
  },
  {
    path: "/pwreset/:code",
    element: <PasswordReset_New />,
  },
  // {
  //   path:"/login",
  //   element:<InternalSIFTLogin />
  // },
  {
    path: "/",
    element: <Terrasmart_Proxy />,
  },
];

const routesForInternal = [
  // {
  //   path:"siftinternal",
  //   element:<SIFTInternal />
  // },
  // {
  //   path:"canopy",
  //   element:<SIFTInternal />
  // },
];

const Routes = () => {
  const router = createBrowserRouter([
    ...routesForPublic,
    // ...(!is_authenticated ? routesForNotAuthenticatedOnly : []),
    // ...routesForInternal,
  ]);
  return <RouterProvider router={router} />;
};

export default Routes;
