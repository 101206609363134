import styled, { css } from "styled-components";

const ModalMaskWrap = styled.section`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s var(--easing-spring), visibility 0.2s var(--easing-spring);

  &.modal-open {
    opacity: 1;
    visibility: visible;
  }
`;

const ModalWrap = styled.section`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height: 200px; */
  width: ${(props) => (props.width ? `${props.width}` : "min-content")};
  height: ${(props) => (props.height ? `${props.height}` : "min-content")};
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    "modal-header"
    "modal-content"
    "modal-footer";

  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0.8); /* Initial scale for a nice zoom effect */
  transform-origin: 50% 50%; /* Set the transform origin to the center */
  transition: opacity 0.2s ease, transform 0.2s var(--easing-spring);

  &.modal-open {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); /* Final scale for centering */
  }

  .modal-header {
    grid-area: modal-header;
    display: grid;
    min-height: 20px;
    align-items: center;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "header-text modal-close";

    .header-text {
      grid-area: header-text;
      font-weight: 600;
      font-size: 14px;
      color: var(--primary-brand-color);
      margin: 0px;
    }

    .modal-close {
      grid-area: modal-close;

      a {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .modal-content {
    grid-area: modal-content;
    display: grid;
    ${({ centered }) =>
      centered &&
      css`
        justify-content: center;
        align-content: center;
      `}
  }

  .custom-footer {
    grid-area: modal-footer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    button {
      margin-right: 5px;
    }
  }

  .modal-footer {
    grid-area: modal-footer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export { ModalMaskWrap, ModalWrap };
