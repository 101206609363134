import styled from "styled-components";

const LoginErrWrap = styled.section`
  width: 100%;
  height: 25px;
  margin: 15px 0px;
  ol {
    list-style: none;
    padding: 0px;
    li {
      color: red;
    }
  }
`;

export { LoginErrWrap };
