import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { SIFTReport } from "./Report";

import { siftActions } from "../Redux";
import { useDispatch } from "react-redux";

const DownloadPDF = (props) => {
  const dispatch = useDispatch();

  const [instance, updateInstance] = usePDF({
    document: <SIFTReport reportData={props.reportData} />,
  });

  useEffect(() => {
    if (instance.error) {
      console.log("💥💥 Something went wrong with PDF Generation 💥💥", instance.error);
    } else if (!instance.loading && instance.url) {
      openLink(instance.url);
      // this just resets the report
      setTimeout(() => {
        dispatch(siftActions.updateReportData("reset"));
      }, 3000);
    }
  }, [instance.loading, instance.url]);

  useEffect(() => {
    return () => {};
  }, []);

  const openLink = (url) => {
    // let PROJECT_NAME = _.isEmpty(props.reportData.project_name) ? "sift_output" : props.reportData.project_name;
    // let TRACK_MODE_STRING = props.reportData.track_mode == 0 ? "GFT" : props.reportData.track_mode == 1 ? "SAT" : props.reportData.track_mode == 2 && "EWF";
    // let MOD_RATING = props.reportData.mod_rating;
    // let INV_RATING = props.reportData.inv_rating;
    // let GCR = props.reportData.gcr;
    // let SPI = props.reportData.spi;

    // let fileName = `${PROJECT_NAME}_${TRACK_MODE_STRING}_${MOD_RATING}W_${INV_RATING}kW_${GCR}_${SPI}_${props.MMDDYYYY}`;

    let downloadLink = document.createElement("a");

    downloadLink.href = url;
    downloadLink.download = `${props.reportData.file_name}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // window.open(url, "_blank", "noopener,noreferrer");
  };

  // const openLink = (url) => {
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };

  return <>{props.children}</>;
};

export { DownloadPDF };
