import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../..";
import { Empty } from "antd";

import { Switch } from "antd";
import { MapLayoutManagerWrap, ToggleArrow, DragIconWrap } from "./styles";

import { Button, Tooltip } from "../../BaseComponents";

import { changeRackSize, changeRackAlignment } from "../..";

const Arrow = ({ is_open = false }) => (
  <ToggleArrow width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="https://www.w3.org/2000/svg" is_open={is_open}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.73488 6L0 1.26512L1.26512 0L7.26512 6L1.26512 12L0 10.7349L4.73488 6Z" fill="#333333" />
  </ToggleArrow>
);

const DragIcon = () => (
  <DragIconWrap width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M0 12V10.2857H1.84615V12H0ZM3.69231 12V10.2857H5.53846V12H3.69231ZM7.38462 12V10.2857H9.23077V12H7.38462ZM0 8.57143V6.85714H1.84615V8.57143H0ZM3.69231 8.57143V6.85714H5.53846V8.57143H3.69231ZM7.38462 8.57143V6.85714H9.23077V8.57143H7.38462ZM0 5.14286V3.42857H1.84615V5.14286H0ZM3.69231 5.14286V3.42857H5.53846V5.14286H3.69231ZM7.38462 5.14286V3.42857H9.23077V5.14286H7.38462ZM0 1.71429V0H1.84615V1.71429H0ZM3.69231 1.71429V0H5.53846V1.71429H3.69231ZM7.38462 1.71429V0H9.23077V1.71429H7.38462Z"
      fill="#333333"
    />
  </DragIconWrap>
);

const MapLayoutManager = () => {
  const dispatch = useDispatch();
  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);
  const selected_rack_layout_id = useSelector((state) => state.inputs.selected_rack_layout_id);

  const disable_resize_and_alignment = selectedResultId && edited_layouts && edited_layouts[selectedResultId].do_rack_align === 0;

  let rack =
    selected_rack_layout_id && edited_layouts && edited_layouts[selectedResultId]
      ? edited_layouts[selectedResultId].inverter_groups[0].combiner_groups[0]["rack_groups"][selected_rack_layout_id]
      : undefined;
  let row_boxes = selected_rack_layout_id && edited_layouts && edited_layouts[selectedResultId] ? edited_layouts[selectedResultId].row_boxes : undefined;

  const handleMouseEnter = (event) => {
    dispatch(inputsActions.update_input("cur_hover_id", event.currentTarget.id));
  };

  const handleMouseLeave = (event) => {
    dispatch(inputsActions.update_input("cur_hover_id", undefined));
  };

  const handleClickLabel = (event) => {
    dispatch(inputsActions.update_input("selected_rack_layout_id", event.currentTarget.id));
  };

  const handleCheck = (event) => {
    dispatch(inputsActions.edit_layout("toggle_rack_vis", selected_rack_layout_id, selectedResultId));
  };

  const handleRackSize = (selected_dim) => {
    let dimensions = edited_layouts[selectedResultId].rack_dims_latlng[selected_dim];
    const row_box = Object.values(row_boxes).filter((box) => box.properties.group == rack.group);
    const scaled_rack = changeRackSize(rack, dimensions, selected_dim, row_box);
    // console.log("scaled", scaled_rack);
    dispatch(inputsActions.edit_layout("change_rack_size", scaled_rack, selectedResultId));
  };

  const undoRackEdits = (e) => {
    dispatch(inputsActions.edit_layout("undo_rack_edits", selected_rack_layout_id, selectedResultId));
  };

  const handleRackAlignment = (alignemnt) => {
    const row_box = Object.values(row_boxes).filter((box) => box.properties.group == rack.group);
    if (row_box) {
      const aligned_rack = changeRackAlignment(rack, row_box, alignemnt);
      console.log("aligned rack", aligned_rack);
      dispatch(inputsActions.edit_layout("change_alignment", aligned_rack, selectedResultId));
    }
  };

  const handle_rack_move = (direction) => {};

  if (selectedResultId && edited_layouts && edited_layouts[selectedResultId] && rack && show_layout_editor) {
    return (
      <MapLayoutManagerWrap>
        <section className="rack-title">
          <h4>{rack.name}</h4>
          {/* <p>mod count: {rack.string_count}</p> */}
        </section>
        <Tooltip title="Restore original rack">
          <Button type="link" size="small" style={{ width: 35, marginLeft: "auto" }} icon="Undo" styles={{ marginLeft: "auto" }} onClick={undoRackEdits} />
        </Tooltip>

        <section className="rack-divider" />

        <section className="rack-actions">
          <section className="layout-row">
            <label>Enabled</label>
            <Switch style={{ marginLeft: "auto" }} id={selected_rack_layout_id} checked={rack.enabled == true} checkedChildren="On" unCheckedChildren="Off" onClick={handleCheck} />
          </section>
          <section className="layout-row">
            <label>Alignment</label>
            <section className="layout-row-buttons">
              <Tooltip title={disable_resize_and_alignment ? "Unavailable when racking is unaligned" : null}>
                <Button
                  type={rack.alignment == 1 ? "primary" : "ghost"}
                  disabled={disable_resize_and_alignment}
                  className="icon-label"
                  size="small"
                  icon={rack.track_mode == 0 ? "alignLeft" : "alignTop"}
                  onClick={() => handleRackAlignment(1)}
                />
              </Tooltip>
              {/* <Button
                type={rack.alignment == 2 ? "primary" : "ghost"}
                className="icon-label"
                size="small"
                icon={rack.track_mode == 0 ? "alignCenter" : "alignMiddle"}
                onClick={() => handleRackAlignment(2)}
              /> */}
              <Tooltip title={disable_resize_and_alignment ? "Unavailable when racking is unaligned" : null}>
                <Button
                  type={rack.alignment == 3 ? "primary" : "ghost"}
                  disabled={disable_resize_and_alignment}
                  className="icon-label"
                  size="small"
                  icon={rack.track_mode == 0 ? "alignRight" : "alignBottom"}
                  onClick={() => handleRackAlignment(3)}
                />
              </Tooltip>
            </section>
          </section>
          <section className="layout-row">
            <label>Rack/String Size</label>
            <section className="layout-row-buttons">
              <Tooltip title={disable_resize_and_alignment ? "Unavailable when racking is unaligned" : null}>
                <Button
                  type={rack.rack_size == 0 ? "primary" : "ghost"}
                  size="small"
                  disabled={disable_resize_and_alignment || rack.racks[0].active == 0}
                  label={`${rack.racks[0].string_count}`}
                  onClick={() => handleRackSize(0)}
                />
              </Tooltip>
              <Tooltip title={disable_resize_and_alignment ? "Unavailable when racking is unaligned" : null}>
                <Button
                  type={rack.rack_size == 1 ? "primary" : "ghost"}
                  size="small"
                  disabled={disable_resize_and_alignment || rack.racks[1].active == 0}
                  label={`${rack.racks[1].string_count}`}
                  onClick={() => handleRackSize(1)}
                />
              </Tooltip>
              <Tooltip title={disable_resize_and_alignment ? "Unavailable when racking is unaligned" : null}>
                <Button
                  type={rack.rack_size == 2 ? "primary" : "ghost"}
                  size="small"
                  disabled={disable_resize_and_alignment || rack.racks[2].active == 0}
                  label={`${rack.racks[2].string_count}`}
                  onClick={() => handleRackSize(2)}
                />
              </Tooltip>
            </section>
          </section>
          {/* <section className="layout-row">
            <label>Rack Position</label>
            <section className="d-pad">
              <Button className="up" type="ghost" size="small" disabled={!rack.valid_locations?.n[0] && !rack.valid_locations?.n[1]} icon="CaretUpOutlined" onClick={() => handle_rack_move("up")} />
              <Button
                className="down"
                type="ghost"
                size="small"
                disabled={!rack.valid_locations?.s[0] && !rack.valid_locations?.s[1]}
                icon="CaretDownOutlined"
                onClick={() => handle_rack_move("down")}
              />
              <Button
                className="left"
                type="ghost"
                size="small"
                disabled={!rack.valid_locations?.e[0] && !rack.valid_locations?.e[1]}
                icon="CaretLeftOutlined"
                onClick={() => handle_rack_move("left")}
              />
              <Button
                className="right"
                type="ghost"
                size="small"
                disabled={!rack.valid_locations?.w[0] && !rack.valid_locations?.w[1]}
                icon="CaretRightOutlined"
                onClick={() => handle_rack_move("riught")}
              />
            </section>
          </section> */}
        </section>
      </MapLayoutManagerWrap>
    );
  } else {
    return <Empty description="Select a Rack by clicking it in the map" />;
  }
};

export { MapLayoutManager };
