import React, { useState, useEffect } from "react";
import { ButtonWrapper, LinkWrapper } from "./styles";
import { iconConstantMap } from "../constant/icon";
import { Loader } from "../Loader";

const Button = ({ label, onClick, styles, size = "medium", type = "primary", disabled, children, icon, htmlType, href, loading = false, shape, block, className }) => {
  const IconToRender = iconConstantMap[icon] || null;
  type = type === "text" ? "btn-text" : type;
  let css_classes = `${className} ${size} ${type} ${loading ? "btn-loading" : ""}`;
  css_classes = css_classes + `${shape === "circle" ? "btn-circle" : shape === "round" ? "btn-round" : ""}`;
  css_classes = css_classes + `${block ? "btn-block" : ""}`;
  css_classes = css_classes + `${disabled ? "disabled" : ""}`;
  return (
    <>
      {!href ? (
        <ButtonWrapper onClick={onClick} style={styles} className={css_classes} disabled={disabled} type={type}>
          {loading ? <Loader type={type} size={size} /> : ""}
          {IconToRender ? <IconToRender /> : ""}
          <span>{label || children}</span>
        </ButtonWrapper>
      ) : (
        <LinkWrapper onClick={onClick} style={styles} className={css_classes} disabled={disabled} type={htmlType || "button"}>
          {loading ? <Loader /> : ""}
          {IconToRender ? <IconToRender /> : ""}
          <span>{label || children}</span>
        </LinkWrapper>
      )}
    </>
  );
};

export { Button };
