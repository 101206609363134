import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
// import { billing } from "../__OLD/billing.reducer";
import { user } from "./user.reducer";
import { sift } from "./sift.reducer";
import { alert } from "./alert.reducer";
import { tutorial } from "./tutorial.reducer";
import { undoRedo } from "./undoRedo.reducer";
import { inputs } from "./inputs.reducer";
import { projects } from "./projects.reducer";
import { canopy } from "./canopy.reducer";

import * as fromUser from "./user.reducer.js";
// import * as fromAccount from "./account.reducer.js";
import * as fromSIFT from "./sift.reducer.js";

export * from "./sift_inputs";

const rootReducer = combineReducers({
  user,
  inputs,
  projects,

  alert,
  tutorial,
  undoRedo,

  // authentication: authentication,
  // registration: registration,
  // payments: billing,
  sift,

  canopy,
});

// Authentication selectors / properties
export const isAuthenticated = (state) => fromUser.isAuthenticated(state.user);
export const accessToken = (state) => fromUser.accessToken(state.user);
export const isAccessTokenExpired = (state) => fromUser.isAccessTokenExpired(state.user);
export const refreshToken = (state) => fromUser.refreshToken(state.user);
export const isRefreshTokenExpired = (state) => fromUser.isRefreshTokenExpired(state.user);
export const isAccessValid = (state) => fromUser.isAccessValid(state.user);
export const isRefreshValid = (state) => fromUser.isRefreshValid(state.user);

export const authErrors = (state) => fromUser.errors(state.user);

// Account selectors / properties
// export const getAllProjects = () => fromAccount.getAllProjects();

// Sift Selectors / properties
export const getProjectInputs = (ioManager) => fromSIFT.getProjectInputs(ioManager);
export const getSIFTInputs = (inputs, plan) => fromSIFT.getSIFTInputs(inputs, plan);

export default rootReducer;
