import React, { useState, useEffect, useMemo } from "react";

//styles and assets
import { FinanceTabLayout } from "./styles";
import { Input, Tooltip, Radio, Empty } from "antd";

//components
import { FinanceInputs_IRR, FinanceInstallCosts, FinanceOperatingCosts } from "./";
import { TitleDivider } from "../../TitleDivider";
//redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber, usePrevious } from "../../Redux";
import _ from "lodash";
// import { FinanceOperatingCosts } from "../..";

const FinanceInputs = () => {
  const dispatch = useDispatch();

  // Store values
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const prev_tab = usePrevious(tab);
  const pro_user = useSelector((state) => state.user.product_plan_paid == 3);
  const standard_user = useSelector((state) => state.user.product_plan_paid == 2);

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const do_finance = useSelector((state) => state.inputs.do_finance);
  const do_ri_customSchedule = useSelector((state) => state.inputs.do_ri_customSchedule);
  const do_custom_schedule = useSelector((state) => state.inputs.do_custom_schedule);
  const revenue_type = useSelector((state) => state.inputs.revenue_type);

  // const doFinance = useSelector((state) => state.inputs.do_finance == 1);
  // const doCustomSchedule = useSelector((state) => state.inputs.do_custom_schedule);
  // const metric = useSelector((state) => state.inputs.metric);

  // const finance = useSelector((state) => state.sift.ioManager.inputs.finance);
  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);

  // let local_inputs = useMemo(() => {
  //   let inputs = {
  //     // do_finance: inputs.do_finance ? inputs.do_finance : 0,
  //     do_ri_customSchedule: inputs.do_ri_customSchedule,
  //     do_custom_schedule: inputs.do_custom_schedule,
  //     revenue_type: inputs.revenue_type,
  //     metric: inputs.metric,
  //     analysis_period: local_inputs.analysis_period,
  //     discount_rate: local_inputs.discount_rate,
  //   };
  //   return inputs;
  // }, [inputs.do_finance, inputs.do_ri_customSchedule, inputs.do_custom_schedule, inputs.revenue_type, inputs.metric, inputs.analysis_period, inputs.discount_rate]);

  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  useEffect(() => {
    if (local_inputs.do_finance == 0) {
      setExpand(false);
    } else if (local_inputs.do_finance == 1) {
      setExpand(true);
    }
  }, [local_inputs.do_finance]);

  //shared inputs between summarized and detailed
  // const analysis_period = useSelector((state) => state.inputs.analysis_period);
  // const discount_rate = useSelector((state) => state.inputs.discount_rate);

  // menu toggles
  const [expand, setExpand] = useState(false);

  // input values used for updating inputs
  // const [analysisPeriod, setAnalysisPeriod] = useState(analysis_period);
  // const [discountRate, setDiscountRate] = useState(discount_rate);

  const MetricOptions = [
    { label: "LCOE", value: 0 },
    { label: "IRR", value: 1, disabled: standard_user || pro_user ? false : true },
  ];

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // do some input validation here
    let bulk_update;
    let fixed_value = value;

		let decPart = (value+"").split(".")[1];
		if (decPart && decPart.slice(-1) == '0') return

    if (key == "discount_rate") {
      if ((_.isString(value) && _.isEmpty(value)) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseFloat(value);
    }

    if (key == "analysis_period") {
      if ((_.isString(value) && _.isEmpty(value)) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseInt(value);
    }

    // if (notAnObject) {
    //   let changed_value = parseFloat(value);
    //   if (!isNumber(changed_value)) return;
    //   // dispatch(siftActions.updateInput(key, changed_value));
    // 	console.log(key, changed_value)
    //   dispatch(inputsActions.update_input(key, changed_value));
    // } else {
    //   let changed_value = { value: parseFloat(value.value), type: parseInt(value.type), total: parseFloat(value.total) };

    //   if (!isNumber(changed_value.value)) return;
    // 	console.log(key, changed_value)
    //   // dispatch(siftActions.updateInput(key, changed_value));
    //   dispatch(inputsActions.update_input(key, changed_value));
    // }

    dispatch(inputsActions.update_input(key, fixed_value));
  };

  // const [toggle_operating_costs, set_toggle_operating_costs] = useState(false);
  const [opShedVisible, setOpShedVisible] = useState(false);
  const [revShedVisible, setRevShedVisible] = useState(false);
  const [touVisible, setTouVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);

  useEffect(() => {
    if (_.isUndefined(prev_tab)) return;

    if (do_custom_schedule === 1 && !opShedVisible) {
      toggleShedPopup(null, true);
    } else if (do_custom_schedule === 0 && opShedVisible) {
      toggleShedPopup(null, false);
    }
  }, [do_custom_schedule]);

  useEffect(() => {
    if (touVisible && opShedVisible) {
      toggleTOUPopup(null, false);
    }
  }, [opShedVisible]);

  useEffect(() => {
    if (touVisible && opShedVisible) {
      toggleTOUPopup(null, true);
      setOpShedVisible(false);
    }
  }, [touVisible]);

  useEffect(() => {
    if (do_finance == 0) {
      setOpShedVisible(false);
      updateInput("do_custom_schedule", 0);
      // set_toggle_operating_costs(false);
    }
  }, [do_finance]);

  useEffect(() => {
    if (touVisible && do_finance === false) {
      toggleTOUPopup(null, false);
      updateInput("revenue_type", 0);
    }

    if (touVisible && revenue_type == 0) {
      toggleTOUPopup(null, false);
    }

    if (revShedVisible && do_finance === false) {
      toggleRevShedPopup(null, false);
      updateInput("do_ri_customSchedule", 0);
    }

    if (!revShedVisible && do_finance === false) {
      updateInput("do_ri_customSchedule", 0);
    }
  }, [do_finance, revShedVisible, revenue_type, revenue_type]);

  useEffect(() => {
    if (_.isUndefined(prev_tab)) return;

    if (do_ri_customSchedule === 1 && !revShedVisible) {
      toggleRevShedPopup(null, true);
    } else if (do_ri_customSchedule === 0 && revShedVisible) {
      toggleRevShedPopup(null, false);
    }
  }, [do_ri_customSchedule]);

  const toggleTOUPopup = (e = undefined, showTOUTable = undefined) => {
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    setRevShedVisible(false);
    setTouVisible(showTOUTable || !touVisible);
  };

  const toggleRevShedPopup = (e = undefined, showRevShedTable = undefined) => {
    let show_popup = showRevShedTable || !revShedVisible;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (show_popup && (opShedVisible || touVisible)) {
      setOpShedVisible(false);
      setTouVisible(false);
      setTimeout(() => {
        setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
        setRevShedVisible(show_popup);
      }, 1000);
    } else {
      setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
      setTouVisible(false);
      setRevShedVisible(show_popup);
    }
  };

  const toggleShedPopup = (e = undefined, showShedTable = undefined) => {
    let show_popup = showShedTable || !opShedVisible;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (show_popup && revShedVisible) {
      setRevShedVisible(false);
      setTimeout(() => {
        setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
        setOpShedVisible(show_popup);
      }, 1000);
    } else {
      setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
      setOpShedVisible(show_popup);
    }
  };

  // const closePopups = () => {
  //   setTouVisible(false);
  //   setOpShedVisible(false);
  //   setRevShedVisible(false);
  // };

  return (
    <FinanceTabLayout>
      {local_inputs.do_finance == 1 ? (
        <>
          <section className="input-row-two-col">
            <label>Metric</label>
            <Tooltip placement="right" title={standard_user || pro_user ? null : "IRR only available for Standard and Pro users"}>
              <Radio.Group
                style={{ marginLeft: "auto" }}
                name="metric"
                options={MetricOptions}
                onChange={(e) => {
                  updateInput(e.target.name, e.target.value);
                }}
                value={local_inputs.metric}
                disabled={account_loading || input_loading}
              />
            </Tooltip>
          </section>

          <Tooltip
            placement="right"
            title={local_inputs.do_custom_schedule == 1 ? "Please toggle off Custom Schedule to enable editing of the Analysis Period field" : ""}
            defaultOpen={local_inputs.do_custom_schedule == 1}
          >
            <section className="input-row-two-col">
              <label>Analysis Period</label>
              <Input
                id="analysis_period"
                value={local_inputs.analysis_period}
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                type="number"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading || local_inputs.do_custom_schedule == 1}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Years</span>}
                onChange={(e) => {
                  // setAnalysisPeriod(e.target.value);
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          </Tooltip>

          <section className="input-row-two-col">
            <label>Discount Rate (Nom)</label>
            <Tooltip placement="right" title={pro_user ? null : ""}>
              <Input
                id="discount_rate"
                value={local_inputs.discount_rate}
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                type="number"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                onChange={(e) => {
                  // setDiscountRate(e.target.value);
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </Tooltip>
          </section>

          <section className="input-row-two-col">
            <a
              className="custom-schedule-link"
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? "Collapse All" : "Expand All"}
            </a>
          </section>

          <FinanceInstallCosts expand={expand} />
          <FinanceOperatingCosts expand={expand} opShedVisible={opShedVisible} popoutY={popoutY} toggleShedPopup={toggleShedPopup} />
          {local_inputs.metric == 1 && (
            <FinanceInputs_IRR expand={expand} toggleRevShedPopup={toggleRevShedPopup} revShedVisible={revShedVisible} touVisible={touVisible} toggleTOUPopup={toggleTOUPopup} popoutY={popoutY} />
          )}
        </>
      ) : (
        <Empty description="Finance inputs are not required to run SIFT. To include a financial calculation, toggle Enable Finance." />
      )}
    </FinanceTabLayout>
  );
};

export { FinanceInputs };
