import React, { PureComponent } from "react";

class SheetRenderer extends PureComponent {
  render() {
    const { className, columns } = this.props;
    return (
      <table className={className} style={{ margin: "5px 0px" }}>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index} className={className} style={{ width: col.width }}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{this.props.children}</tbody>
      </table>
    );
  }
}
const columns = [
  { label: "", width: "25%" },
  { label: "", width: "25%" },
  { label: "", width: "0%" },
];

const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

export { SheetRenderer, renderSheet };
