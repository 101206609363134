import React, { useState, useEffect, useMemo } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";

// redux
import { useSelector, useDispatch } from "react-redux";
import { siftActions, usePrevious, isAuthenticated } from "../../../Redux";
import { useAuth } from "../../../Auth/AuthProvider";

import { locationPin } from "../../../../assets/images";
import { pull } from "lodash";

const weatherMarker = new L.Icon({
  iconUrl: locationPin,
  iconRetinaUrl: locationPin,
  iconAnchor: [20, 40],
  popupAnchor: [0, -35],
  iconSize: [40, 40],
});

const MapMarker = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const { is_authenticated } = useAuth();

  const map_center = useSelector((state) => state.inputs.map_center);
  const lat = useSelector((state) => state.inputs.latitude);
  const lng = useSelector((state) => state.inputs.longitude);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const pull_location = useSelector((state) => state.sift.ioManager.inputs.map.pull_location);
  const boundary_bbox = useSelector((state) => state.inputs.boundary_bbox);
  const site_features = useSelector((state) => state.inputs.site_features);
  const boundaries_present = Object.values(site_features).some((feature) => feature.properties.identity === 1);

  const [marker_position, set_marker_position] = useState({ lat: map_center[0], lng: map_center[1] });
  const [marker_visible, set_marker_visible] = useState(false);

  const prev_pull_location = usePrevious(pull_location);

  useEffect(() => {
    if (boundaries_present && boundary_bbox.length > 0) return;
    set_marker_position({ lat: map_center[0], lng: map_center[1] });
  }, [map_center]);

  useEffect(() => {
    set_marker_position({ lat, lng });
  }, [lat, lng]);

  useEffect(() => {
    if (prev_pull_location != pull_location) {
      if (pull_location && is_authenticated) {
        handleUpdateMarker();
      }
    }
  }, [pull_location]);

  useEffect(() => {
    if (tab == "weather") {
      set_marker_visible(true);
    } else if (marker_visible) {
      set_marker_visible(false);
    }
  }, [tab]);

  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [map]);

  const handleMarkerEvent = useMemo(
    () => ({
      dragend(e) {
        handleUpdateMarker(e.target._latlng);
      },
    }),
    []
  );

  const handleMarkerUpdate = (mark) => {
    // console.log("marker latlng", mark);
    dispatch(siftActions.updateMarker(mark));
  };

  const handleUpdateMarker = (latlng = undefined) => {
    let mark = latlng;
    if (mark == undefined) {
      mark = map.getBounds().getCenter();
    }
    set_marker_position(mark);
    handleMarkerUpdate(mark);
  };

  return (
    <>
      {marker_visible && marker_position.lat && marker_position.lng && (
        <Marker draggable={true} eventHandlers={handleMarkerEvent} icon={weatherMarker} position={marker_position}>
          <Popup>
            <p>{`Site Location ${marker_position.lat?.toFixed(4)}, ${marker_position.lng?.toFixed(4)}`}</p>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export { MapMarker };
