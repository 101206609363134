import React from 'react';
import './contact.css';
import './buttons.css';

// components
import ContactForm from './contactForm';

//images
import contactBanner from '../_images/contactBanner.png';

const Cantact = () => {
  return (
    <section id="contact" className="contact">
      <div className="contact-banner">
        <img src={contactBanner} alt="solar field" />
      </div>
      <div className="contact-actions">
        <div className="project-or-schedule">
          <p className="project-in-mind">Have questions or a project in mind?</p>
          <p className="message-us">Please send us a message.</p>
        </div>
        <div className="contact-form-area">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default Cantact;
