import {
	visa_dark_logo,
	mc_dark_logo,
	diners_dark_logo,
	discover_dark_logo,
	jcb_dark_logo,
	amex_dark_logo
} from '../../assets/images'


const cards = [{
	name: 'Visa',
	icon: visa_dark_logo
}, {
	name: 'MasterCard',
	icon: mc_dark_logo
}, {
	name: 'Diners',
	icon: diners_dark_logo
}, {
	name: 'Discover',
	icon: discover_dark_logo
}, {
	name: 'JCB',
	icon: jcb_dark_logo
}, {
	name: 'American Expres',
	icon: amex_dark_logo
}]

export default cards;