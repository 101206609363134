import styled from "styled-components";

const SearchBar = styled.input`
  height: 30px;
  width: 285px;
  border: 1px solid var(--primary-brand-color);
  border-radius: 3px;
  padding-left: 5px;

  &:focus {
    border-color: var(--primary-brand-color);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(96 222 79 / 20%);
  }
`;

export { SearchBar };
