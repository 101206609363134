import styled, { css } from "styled-components";

export const ResultTableWrapper = styled.section`
  width: 99%;
  height: 360px;
  overflow: auto;
  border: 1px solid #60de4f;

  table {
    text-align: center;
    position: relative;
  }

  table thead {
    position: sticky;
    top: 0px;
    background: #fff;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: rgb(217, 217, 217);
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  table > tbody > tr > td {
    border: 1px solid rgb(217, 217, 217);
  }

  .column-name {
    border: 1px solid #d3d3d3;
    position: relative;
    vertical-align: bottom;
    font-size: 10pt;
    font-weight: 500;
    height: 44px;
    color: #333;
    cursor: pointer;
    white-space: nowrap;
    padding: 0px 5px;
  }
  .column-name.active {
    color: #60de4f;
    border-top: 1px solid #60de4f;
    border-bottom: 1px solid #60de4f;
  }
  .column-name:hover {
    color: #60de4f;
  }

  .mini-sort {
    position: absolute;
    font-size: 15px;
    top: 0;
    right: 2px;
  }
  .mini-sort:hover {
    color: #60de4f;
  }
  .mini-data > td {
    text-align: left;
    padding: 2px 4px;
    min-width: 55px;
    border: 1px solid #d9d9d9;
    width: min-content;
    white-space: nowrap;
  }

  .mini-data.selected,
  .mini-data:hover {
    color: #60de4f;
    background-color: #f3f3f3;
    cursor: pointer;
  }
`;
