const ConfigDefault = {
  "data": {
    platform: "sift",
    gcr_range: [0.35, 0.44],
    gcr_step: 0.01,
    pitch_range: [4.4455, 5.5886],
    pitch_step: 1,
    pitch_min_max: [2.1733, 9.78],
    pitch_count: 10,
    gcr_count: 10,
    gcr_pitch_count: 0,

    dcac_range: [1.2236,1.5109],

    selected_gcr_pitch: 0,
    selected_slider_fixed: 0,

    spi_range: [115,142],
    spi_min_max: [93,187],
    spi_step: 3,
    spi_count: 10,
    dcac: 1.4,
    
    azimuth: 180,
    layout_margin: 95,
    layout_fill: 0,

    do_finance: 0,
    do_continuous: 0,

    do_dc_lock: 0,
    dc_lock_value: 50000,
    
    do_inverter_qty_lock: 0,
    inverter_qty: 2,
    
    do_rack_align: 1,
    do_roads: 1,
    road_spacing_option: 2,
    road_spacing: 300,

    do_inv_spacing: 1,
    inverter_per_cut: 1,    

    
    worker_count: 100,
    worker_limit: 500,    
    designer_margin: 0,
    
    // modules_per_string: 28,
  }
}

const Config = []

export { Config, ConfigDefault }