import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { TooltipWrap } from "./styles";

// const Tooltip = ({ title, children , placement,onClick,mouseEnterDelay,style,color}) => {

//     color = color || 'black';
//     const [isTooltipVisible, setTooltipVisible] = useState(false);
//     const timerRef = useRef(null);
//     mouseEnterDelay = mouseEnterDelay || 0;
//     // Function to handle mouse enter event
//     const handleMouseEnter = () => {
//         // Set a timer to delay the tooltip visibility
//         timerRef.current = setTimeout(() => {
//         setTooltipVisible(true);
//         }, mouseEnterDelay*1000); // Delay in seconds
//     };

//     // Function to handle mouse leave event
//     const handleMouseLeave = () => {
//         // Clear the timer if the user leaves before the delay
//         clearTimeout(timerRef.current);
//         setTooltipVisible(false);
//     };
//   return (

//         <TooltipWrapper onClick={onClick}
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave} color={color}>
//             {children}
//             {  isTooltipVisible && (
//                 <span className={'tooltiptext ' +  placement}  style={style}>
//                     {title}
//                 </span>
//                 )}
//         </TooltipWrapper>
//   );
// };

const Tooltip = ({ children, title, placement = "top", mouseEnterDelay = 200 }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tip_coords, set_tip_coords] = useState(undefined);

  let tooltip_ref = useRef();

  const handleMouseEnter = (e) => {
    const pos = e.currentTarget.getBoundingClientRect();

    if (placement === "top") {
      set_tip_coords({
        top: pos.top + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "bottom") {
      set_tip_coords({
        top: pos.bottom + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "left") {
      set_tip_coords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + window.pageXOffset,
      });
    } else if (placement === "right") {
      set_tip_coords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + pos.width + window.pageXOffset,
      });
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    set_tip_coords(undefined);
  };

  const tooltipStyles = {
    position: "fixed",
    backgroundColor: "#333",
    color: "#fff",
    padding: "5px",
    borderRadius: "5px",
    zIndex: "9999",
    opacity: "0",
    maxWidth: "300px",
    transitionProperty: "opacity, transform",
    transformOrigin: "center center",
    textAlign: "center",
  };

  const tooltipPositionStyles = {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    if (showTooltip && tip_coords && tooltip_ref.current) {
      let el = tooltip_ref.current;
      if (el) {
        el.style.transitionDuration = 0 + "ms";
        setTimeout(() => {
          if (placement === "top") {
            el.style.top = `${tip_coords.top - el.clientHeight}px`;
            el.style.left = `${tip_coords.left}px`;
            el.style.transform = `translate(-50%, -10px) scale(1)`;
          } else if (placement === "bottom") {
            el.style.top = `${tip_coords.top}px`;
            el.style.left = `${tip_coords.left}px`;
            el.style.transform = `translate(-50%, 15px) scale(1)`;
          } else if (placement === "left") {
            el.style.top = `${tip_coords.top}px`;
            el.style.left = `${tip_coords.left - el.clientWidth}px`;
            el.style.transform = `translate(-15px, -50%) scale(1)`;
          } else if (placement === "right") {
            el.style.top = `${tip_coords.top}px`;
            el.style.left = `${tip_coords.left}px`;
            el.style.transform = `translate(15px, -50%) scale(1)`;
          }

          el.style.opacity = "0.9";
        }, mouseEnterDelay);
      }
    }
  }, [showTooltip]);

  const tool_tip = (
    <span ref={tooltip_ref} className={`tooltip--${placement} `} style={{ ...tooltipStyles, ...tooltipPositionStyles }}>
      {title}
    </span>
  );

  return (
    <TooltipWrap onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showTooltip && title !== null && createPortal(tool_tip, document.getElementById("root"))}
    </TooltipWrap>
  );
};

export { Tooltip };
