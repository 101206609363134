import styled from "styled-components";

const InternalLoginWrapper = styled.section`
  .login-panel {
    margin-bottom: 20px;
    display: grid;
    place-items: center;
  }

  .portal-header p {
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 70px;
  }

  .login-panel-body {
    padding: 20px;
    width: 379px;
  }

  .login-header {
    text-align: center;
  }

  .login-header img {
    width: 175px;
    margin-right: 30px;
  }

  .login-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .login-label.reset {
    font-weight: 800;
    color: #178a41;
  }

  .login-button {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  .login-form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 15px !important;
  }

  .login-form .inner-input {
    height: 50px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;

    width: 100%;
    text-align: start;
  }
  .inner-input .ant-input {
    padding-right: 30px !important;
    padding-left: 30px !important;
    min-height: 100% !important;
    font-size: 14px !important;
  }
  .login-form .ant-input-prefix {
    font-size: 15px;
    left: 8px;
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0;
    transform: translateY(-50%);
  }

  .form-load_spinner {
    display: grid;
    margin-top: 50px;
  }

  .forgot-pass-text {
    width: 100%;
    margin-bottom: 35px;
  }
`;

const LoginErrWrap = styled.section`
  width: 100%;
  height: 25px;
  margin: 15px 0px;

  ol {
    list-style: none;
    padding: 0px;
    text-align: center;

    li {
      color: red;
    }
  }
`;

export { InternalLoginWrapper, LoginErrWrap };
