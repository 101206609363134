import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";

import { canopyActions } from "../../../../Redux";
import { square, square_green, rectWithOffsetVerts } from "../../../../../assets/images";

import L from "leaflet";

import { Tooltip } from "antd";
import { useKey } from "../../../../Map/components/MapEventHandler/useKey";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const getTouchIconBase = (platform) => {
  let touch_icon_base;
  if (platform === "internal_sift") {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square_green,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  } else {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  }

  return touch_icon_base;
};

const DrawCanopy = ({ set_show_canopy_tools, show_canopy_tools }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const platform = useSelector((state) => state.user.platform);
  const is_drawing = useSelector((state) => state.canopy.is_drawing);

  const drawKey = useKey("d");
  const cancel_key = useKey("escape");

  // useEffect(() => {
  //   L.Edit.Rectangle.prototype.setOptions({ uniformScaling: false });
  // }, []);

  useEffect(() => {
    if (!map) return;

    let the_map_has_focus = document.getElementById("map") == document.activeElement;

    if (drawKey && the_map_has_focus) {
      drawCanopy();
    }

    if (cancel_key && is_drawing) {
      cancelDraw();
    }
  }, [drawKey, cancel_key]);

  useMapEvents({
    cancelDraw: () => {
      if (is_drawing) cancelDraw();
    },
    startDrawing: () => drawCanopy(),
  });

  const drawCanopy = () => {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 2,
        fillColor: "#FFFFFF",
      },
      icon: getTouchIconBase(platform),
    };

    // let rectangle = new L.Draw.Rectangle(map, options);

    // map.pm.enableDraw("Rectangle", {
    //   snappable: true,
    //   snapDistance: 20,
    //   tooltips: false,
    // });
    // console.log("geoman rectangle", rectangle);

    if (is_drawing) {
      cancelDraw();
    } else {
      dispatch(canopyActions.updateCanopyInputs("is_drawing", true));
    }
  };

  const cancelDraw = () => {
    dispatch(canopyActions.updateCanopyInputs("is_drawing", false));
    map.pm.disableDraw();
  };

  return (
    <Tooltip placement="bottom" title="Draw Canopy" mouseEnterDelay={0.5}>
      <button className={is_drawing ? "button-active" : ""} onClick={() => drawCanopy()}>
        <img draggable={false} src={rectWithOffsetVerts} />
      </button>
    </Tooltip>
  );
};

export { DrawCanopy };
