import _ from "lodash";
import * as turf from "@turf/turf";
import { getBounds, getNewCenterPoint, uuid, EPSGData, calculate_finance } from "../_helpers";

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export function run_side_effects(inputs, keys = undefined) {
  // console.log("running side effects", inputs);
  let fixed_inputs = { ...inputs };
  let inputs_keys = keys || Object.keys(fixed_inputs);

  let run_dim_calc = ["rack_dims", "track_mode", "mod_per_string", "modules_high", "orientation", "racks", "module_gap", "drive_gap", "mod_width", "mod_height", "all"];
  let trigger_dim_calc = false;

  inputs_keys.map((key) => {
    if (run_dim_calc.findIndex((type) => type == key) >= 0) trigger_dim_calc = true;
  });

  if (trigger_dim_calc) {
    let new_racks = fixed_inputs.racks;
    if (fixed_inputs.rack_dims == 0) {
      // console.log("updaing racks automatically");
      new_racks = calculate_racking_dims({
        mod_per_string: fixed_inputs.mod_per_string,
        modules_high: fixed_inputs.modules_high,
        orientation: fixed_inputs.orientation,
        module_gap: fixed_inputs.module_gap,
        drive_gap: fixed_inputs.drive_gap,
        track_mode: fixed_inputs.track_mode,
        racks: fixed_inputs.racks,
        mod_width: fixed_inputs.mod_width,
        mod_height: fixed_inputs.mod_height,
        en_bifacial: inputs.en_bifacial,
        racking_name: fixed_inputs.racking_id,
      });
    }

    let new_racking_errors = check_for_racking_errors({
      mod_per_string: fixed_inputs.mod_per_string,
      racks: new_racks,
      mod_width: fixed_inputs.mod_width,
      mod_height: fixed_inputs.mod_height,
      racking_name: fixed_inputs.racking_id,
      rack_dims: fixed_inputs.rack_dims,
    });

    fixed_inputs = {
      ...fixed_inputs,
      racks: new_racks,
      Fshd_CollWidth: fixed_inputs.track_mode == 0 ? new_racks[0].ydim : new_racks[0].xdim,
      racking_errors: new_racking_errors.contains_errors ? new_racking_errors.errors : undefined,
      pitch_min_limit: fixed_inputs.track_mode == 0 ? _.round(new_racks[0].ydim / 1.0, 1) : _.round(new_racks[0].xdim / 1.0, 1),
      pitch_max_limit: fixed_inputs.track_mode == 0 ? _.round(new_racks[0].ydim / 0.2, 1) : _.round(new_racks[0].xdim / 0.2, 1),
      en_topo_perf: fixed_inputs.track_mode == 2 ? 0 : fixed_inputs.en_topo_perf,
    };
  }

  let run_tilts = ["tilt_min", "tilt_max", "tilt_inc", "all"];
  let trigger_tilts = false;
  inputs_keys.map((key) => {
    if (run_tilts.findIndex((type) => type == key) >= 0) trigger_tilts = true;
  });
  if (trigger_tilts) {
    fixed_inputs = {
      ...fixed_inputs,
      tilts: get_tilts(fixed_inputs.tilt_min, fixed_inputs.tilt_max, fixed_inputs.tilt_inc),
    };
  }

  let run_pitches = ["pitch_min", "pitch_max", "pitch_inc", "all"];
  let trigger_pitches = false;
  inputs_keys.map((key) => {
    if (run_pitches.findIndex((type) => type == key) >= 0) trigger_pitches = true;
  });
  if (trigger_pitches) {
    fixed_inputs = {
      ...fixed_inputs,
      pitches: get_pitches(fixed_inputs.pitch_min, fixed_inputs.pitch_max, fixed_inputs.pitch_inc),
    };
  }

  let run_gcrs = ["gcr_min", "gcr_max", "gcr_inc", "all"];
  let trigger_gcrs = false;
  inputs_keys.map((key) => {
    if (run_gcrs.findIndex((type) => type == key) >= 0) trigger_gcrs = true;
  });
  if (trigger_gcrs) {
    fixed_inputs = {
      ...fixed_inputs,
      gcrs: get_gcrs(fixed_inputs.gcr_min, fixed_inputs.gcr_max, fixed_inputs.gcr_inc),
    };
  }

  let run_spis = ["spi_min", "spi_max", "spi_inc", "all"];
  let trigger_spis = false;
  inputs_keys.map((key) => {
    if (run_spis.findIndex((type) => type == key) >= 0) trigger_spis = true;
  });
  if (trigger_spis) {
    fixed_inputs = {
      ...fixed_inputs,
      spis: get_spis(fixed_inputs.spi_min, fixed_inputs.spi_max, fixed_inputs.spi_inc),
    };
  }

  let run_spis_limits = ["mod_per_string", "mod_rating", "inv_rating", "all"];
  let trigger_spis_limits = false;
  inputs_keys.map((key) => {
    if (run_spis_limits.findIndex((type) => type == key) >= 0) trigger_spis_limits = true;
  });
  if (trigger_spis_limits) {
    fixed_inputs = {
      ...fixed_inputs,
      spi_min_limit: parseInt(Math.floor((fixed_inputs.inv_rating * 0.95) / ((fixed_inputs.mod_per_string * fixed_inputs.mod_rating) / 1000))),
      spi_max_limit: parseInt(Math.ceil((fixed_inputs.inv_rating * 3.01) / ((fixed_inputs.mod_per_string * fixed_inputs.mod_rating) / 1000))),
    };
  }

  if (fixed_inputs.simple_module == 1) {
    let run_mod_dims_calc = ["mod_rating"];
    let trigger_mod_dims_calc = false;
    inputs_keys.map((key) => {
      if (run_mod_dims_calc.findIndex((type) => type == key) >= 0 && fixed_inputs.mod_dims_calc == 0) trigger_mod_dims_calc = true;
    });
    if (trigger_mod_dims_calc) {
      let mod_dims = calc_module_dims(fixed_inputs.mod_rating);
      fixed_inputs.mod_width = mod_dims.dim_x;
      fixed_inputs.mod_height = mod_dims.dim_y;
    }
  }
  if (fixed_inputs.road_spacing_option == 0 && fixed_inputs.inverter_grouping > 0) {
    fixed_inputs.inverter_grouping = 0;
    fixed_inputs.do_inv_spacing = 0;
  }

  if (fixed_inputs.inverter_grouping == 0 && fixed_inputs.do_inv_spacing == 1) {
    fixed_inputs.do_inv_spacing = 0;
  }

  if (fixed_inputs.epsg == 0) {
    fixed_inputs.coord_system_bbox = undefined;
  } else {
    fixed_inputs.coord_system_bbox = create_coord_sys_bbox(fixed_inputs.epsg);
    fixed_inputs.coord_system_bbox.properties.index = uuid();
    fixed_inputs.coord_system_bbox.properties.skip_zoom_extent = true;
  }

  if (fixed_inputs.simple_inverter == 1 && fixed_inputs.do_inverter_limit_lock == 1) {
    fixed_inputs.do_inverter_limit_lock = 0;
  }

  // FINANCE
  let finance_totals = [
    "module_dc_cost",
    "rack_a_finance",
    "rack_b_finance",
    "rack_c_finance",
    "bos_other",
    "inverter_cost",
    "ac_aux",
    "mv_wire",
    "other_ac",
    "interconnection",
    "permits_fees",
    "engineering",
    "margin",
    "other_fixed",
    "rack_footprint",
    "boundary_area_per",
    "contingency",
    "spacing_gcr",
    "spacing_per_wp",
  ];
  let trigger_finance_calc = false;
  inputs_keys.map((key) => {
    if (finance_totals.findIndex((type) => type == key) >= 0) trigger_finance_calc = true;
  });

  if (trigger_finance_calc) {
    // create an object that is shapped the way the calculate_finance() is expecting
    let action = {
      key: inputs_keys[0],
      value: fixed_inputs[inputs_keys[0]],
    };
    let racks = fixed_inputs.racks;
    let mod_rating = fixed_inputs.mod_rating;
    let inv_rating = fixed_inputs.inv_rating;
    let calculated_value = calculate_finance(action, racks, mod_rating, inv_rating);
    fixed_inputs[calculated_value.key] = calculated_value.value;
  }
  // console.log("final fixed_inputs", fixed_inputs);
  return fixed_inputs;
}

export function run_internal_sift_side_effects(inputs, keys = undefined) {
  let fixed_inputs = { ...inputs };
  let inputs_keys = keys || Object.keys(fixed_inputs);

  let run_dim_calc = ["rack_dims", "track_mode", "mod_per_string", "modules_high", "orientation", "racks", "module_gap", "drive_gap", "mod_width", "mod_height"];
  let trigger_dim_update = false;

  inputs_keys.map((key) => {
    if (run_dim_calc.findIndex((type) => type == key) >= 0) trigger_dim_update = true;
  });

  if (trigger_dim_update) {
    if (fixed_inputs.track_mode == 1) {
      fixed_inputs.racks.forEach((rack, index) => {
        let mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
        let Tracker_XY = Terratrak_2P_SAT(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.module_isbifacial == 1);
        rack.module = mod_count;
        rack.xdim = +Tracker_XY[0].toFixed(4);
        rack.ydim = +Tracker_XY[1].toFixed(4);
      });
    } else {
      if (inputs.racking_name == "Terrasmart Glide WAVE") {
        fixed_inputs.racks.forEach((rack, index) => {
          let mod_count = rack.module;
          if (fixed_inputs.string_counts.length == 0) {
            // GLIDE products are LIMITED to 24 modules no matter what
            mod_count = 24;
          } else {
            mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
          }
          let Rack_XY = GlideWave(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.modules_high, fixed_inputs.orientation, fixed_inputs.module_gap, fixed_inputs.drive_gap);
          rack.module = mod_count;
          rack.xdim = +Rack_XY[0].toFixed(4);
          rack.ydim = +Rack_XY[1].toFixed(4);
          fixed_inputs.product_type = "wave";
        });
      } else if (inputs.racking_name == "Terrasmart Glide AGILE") {
        fixed_inputs.racks.forEach((rack, index) => {
          let mod_count = rack.module;
          if (fixed_inputs.string_counts.length == 0) {
            // GLIDE products are LIMITED to 24 modules no matter what
            mod_count = 24;
          } else {
            mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
          }
          let Rack_XY = GlideAgile(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.modules_high, fixed_inputs.orientation, fixed_inputs.module_gap, fixed_inputs.drive_gap);
          rack.module = mod_count;
          rack.xdim = +Rack_XY[0].toFixed(4);
          rack.ydim = +Rack_XY[1].toFixed(4);
          fixed_inputs.product_type = "agile";
        });
      } else {
        // GFT
        fixed_inputs.racks.forEach((rack, index) => {
          let mod_count = rack.module;
          if (fixed_inputs.string_counts.length == 0) {
            // GLIDE products are LIMITED to 24 modules no matter what
            mod_count = 24;
          } else {
            mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
          }
          let Rack_XY = Standard_GFT(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.mods_high, fixed_inputs.orientation, fixed_inputs.vert_gap, fixed_inputs.hori_gap);
          rack.module = mod_count;
          rack.xdim = +Rack_XY[0].toFixed(4);
          rack.ydim = +Rack_XY[1].toFixed(4);
        });
      }
    }
  }

  let run_tilts = ["tilt_min", "tilt_max", "tilt_inc"];
  let trigger_tilts = false;
  inputs_keys.map((key) => {
    if (run_tilts.findIndex((type) => type == key) >= 0) trigger_tilts = true;
  });
  if (trigger_tilts) {
    fixed_inputs = {
      ...fixed_inputs,
      tilts: get_tilts(fixed_inputs.tilt_min, fixed_inputs.tilt_max, fixed_inputs.tilt_inc),
    };
  }

  let run_pitches = ["pitch_min", "pitch_max", "pitch_inc"];
  let trigger_pitches = false;
  inputs_keys.map((key) => {
    if (run_pitches.findIndex((type) => type == key) >= 0) trigger_pitches = true;
  });
  if (trigger_pitches) {
    fixed_inputs = {
      ...fixed_inputs,
      pitches: get_pitches(fixed_inputs.pitch_min, fixed_inputs.pitch_max, fixed_inputs.pitch_inc),
    };
  }

  let run_gcrs = ["gcr_min", "gcr_max", "gcr_inc"];
  let trigger_gcrs = false;
  inputs_keys.map((key) => {
    if (run_gcrs.findIndex((type) => type == key) >= 0) trigger_gcrs = true;
  });
  if (trigger_gcrs) {
    fixed_inputs = {
      ...fixed_inputs,
      gcrs: get_gcrs(fixed_inputs.gcr_min, fixed_inputs.gcr_max, fixed_inputs.gcr_inc),
    };
  }

  let run_spis = ["spi_min", "spi_max", "spi_inc"];
  let trigger_spis = false;
  inputs_keys.map((key) => {
    if (run_spis.findIndex((type) => type == key) >= 0) trigger_spis = true;
  });
  if (trigger_spis) {
    fixed_inputs = {
      ...fixed_inputs,
      spis: get_spis(fixed_inputs.spi_min, fixed_inputs.spi_max, fixed_inputs.spi_inc),
    };
  }

  let run_spis_limits = ["mod_per_string", "mod_rating", "inv_rating"];
  let trigger_spis_limits = false;
  inputs_keys.map((key) => {
    if (run_spis_limits.findIndex((type) => type == key) >= 0) trigger_spis_limits = true;
  });
  if (trigger_spis_limits) {
    fixed_inputs = {
      ...fixed_inputs,
      spi_min_limit: parseInt(Math.floor((fixed_inputs.inv_rating * 0.95) / ((fixed_inputs.mod_per_string * fixed_inputs.mod_rating) / 1000))),
      spi_max_limit: parseInt(Math.ceil((fixed_inputs.inv_rating * 3.01) / ((fixed_inputs.mod_per_string * fixed_inputs.mod_rating) / 1000))),
    };
  }

  if (fixed_inputs.road_spacing_option == 0 && fixed_inputs.inverter_grouping > 0) {
    fixed_inputs.inverter_grouping = 0;
    fixed_inputs.do_inv_spacing = 0;
  }

  if (fixed_inputs.inverter_grouping == 0 && fixed_inputs.do_inv_spacing == 1) {
    fixed_inputs.do_inv_spacing = 0;
  }

  if (fixed_inputs.epsg == 0) {
    fixed_inputs.coord_system_bbox = undefined;
  } else {
    fixed_inputs.coord_system_bbox = create_coord_sys_bbox(fixed_inputs.epsg);
    fixed_inputs.coord_system_bbox.properties.index = uuid();
    fixed_inputs.coord_system_bbox.properties.skip_zoom_extent = true;
  }

  return fixed_inputs;
}

function create_coord_sys_bbox(epsg_code) {
  let c = EPSGData[epsg_code]["bbox"];
  return turf.flip(turf.bboxPolygon([c["south_latitude"], c["west_longitude"], c["north_latitude"], c["east_longitude"]]));
}

export function get_tilts(min, max, inc) {
  if (inc <= 0) {
    return [min, max];
  }
  let tilts = [];
  for (let tilt = min; tilt < max; tilt += inc) {
    tilts.push(tilt);
    if (tilts.length >= 20) break;
  }
  tilts.push(parseFloat(max));
  return tilts;
}
export function get_pitches(min, max, inc) {
  if (inc <= 0) {
    return [min, max];
  }
  let pitches = [];
  for (let pitch = min; pitch < max; pitch += inc) {
    pitches.push(_.round(pitch, 2));
  }
  pitches.push(parseFloat(max));
  return pitches;
}
export function get_gcrs(min, max, inc) {
  if (inc <= 0) {
    return [min, max];
  }
  let gcrs = [];
  for (let gcr = min; _.round(gcr, 3) < max; gcr += inc) {
    gcrs.push(_.round(gcr, 3));
  }
  gcrs.push(parseFloat(max));
  return gcrs;
}
export function get_spis(min, max, inc) {
  if (inc <= 0) {
    return [min, max];
  }
  let spis = [];
  for (let spi = min; spi < max; spi += inc) {
    spis.push(parseInt(spi));
  }
  spis.push(parseInt(max));
  return spis;
}

export function calc_module_dims(rating) {
  // DIMX = Rating * 0.0013 + 0.4541
  // DIMY = Rating * 0.0012 + 1.6192
  let dim_x = +(rating * 0.0013 + 0.4541).toFixed(3);
  let dim_y = +(rating * 0.0012 + 1.6192).toFixed(3);
  return { dim_x, dim_y };
}

function Standard_GFT(mod_width, mod_height, modules_per_rack, modules_high, orientation, module_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = Mods_Wide * mod_width + (Mods_Wide - 1) * module_gap;
    Rack_Y = modules_high * mod_height + (modules_high - 1) * module_gap;
  } else {
    // landscape
    Rack_X = Mods_Wide * mod_height + (Mods_Wide - 1) * module_gap;
    Rack_Y = modules_high * mod_width + (modules_high - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

function Standard_SAT(mod_width, mod_height, modules_per_rack, modules_high, orientation, module_gap, drive_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = modules_high * mod_height + (modules_high - 1) * module_gap;
    Rack_Y = drive_gap + Mods_Wide * mod_width + (Mods_Wide - 1) * module_gap;
  } else {
    // landscape
    Rack_X = modules_high * mod_width + (modules_high - 1) * module_gap;
    Rack_Y = drive_gap + Mods_Wide * mod_height + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

function Standard_EWF(mod_width, mod_height, modules_per_rack, modules_high, orientation, module_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = modules_high * mod_height + (modules_high - 1) * module_gap;
    Rack_Y = Mods_Wide * mod_width + (Mods_Wide - 1) * module_gap;
  } else {
    // landscape
    Rack_X = modules_high * mod_width + (modules_high - 1) * module_gap;
    Rack_Y = Mods_Wide * mod_height + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

const round_to_nearest_half = (num) => {
  return Math.round(num * 2) / 2;
};

//// New racking calcs

function Terratrak_2P_SAT(_Mod_X, _Mod_Y, Mod_per_Tracker, Bifacial) {
  // #Mod_X, Mod_Y in meters. Imperial units come from TS' calculators and are converted within

  // #Below code expects module dims in mm
  let Mod_X = _Mod_X * 1000;
  let Mod_Y = _Mod_Y * 1000;

  // #Inches
  let Torque_Tube_Width = 5.17;
  let Motor_Gap = 26.93;
  let Hole_Spacing = 3.108;
  let Torque_Tube_Overhang = 3;

  // #mm
  let Panel_Flange_Width_Short = 10;
  let Panel_Flange_Width_Long = 30;

  // #25.4 is mm to inch
  let NS_Mod_Gap = Hole_Spacing - (Panel_Flange_Width_Long / 25.4) * 2;

  let EW_Mod_Spacing;
  if (Bifacial == true) {
    // #25.4 is mm to inch
    EW_Mod_Spacing = Torque_Tube_Width - (Panel_Flange_Width_Short / 25.4) * 2;
  } else {
    // #Inches
    EW_Mod_Spacing = 0.5;
  }

  let Tracker_Y = Motor_Gap + Math.floor(Mod_per_Tracker / 2 + 0.5) * (Mod_X / 25.4) + Torque_Tube_Overhang * 2 + NS_Mod_Gap * (Math.floor(Mod_per_Tracker / 2 + 0.5) - 1);
  // #int(Mod_per_Tracker/2 + 0.5) round up for odd, down for even. 13 modules turns to 14, 14 modules stays at 14.

  let Tracker_X = (Mod_Y / 25.4) * 2 + EW_Mod_Spacing;

  Tracker_X = Tracker_X * 0.0254;
  Tracker_Y = Tracker_Y * 0.0254;

  // #Terratrak max dims is 47x2 modules (94) (assuming 2mx1m module). Max mod/string of 31 for a 3 string tracker

  return [Tracker_X, Tracker_Y];
}

function Terratrak_1P_SAT(mod_width, mod_height, modules_per_rack, modules_high, orientation, module_gap, drive_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);
  // Mods_Wide should return modules_per_rack/1 for 1P which is accurate.

  if (orientation == 0) {
    // portrait
    Rack_X = modules_high * mod_height + (modules_high - 1) * module_gap;
    Rack_Y = drive_gap + Mods_Wide * mod_width + (Mods_Wide - 1) * module_gap;
  } else {
    // landscape
    Rack_X = modules_high * mod_width + (modules_high - 1) * module_gap;
    Rack_Y = drive_gap + Mods_Wide * mod_height + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

function GlideFuse(mod_width, mod_height, modules_per_rack, modules_high, orientation, gap_vert, gap_horz) {
  // user inputs modules per rack, returns and x and y dimension. Agnostic of modules per string or string size target.
  // currently no different than standard GFT. Maintaining seperate call for future customization if necessary.

  let Rack_X;
  let Rack_Y;

  // #complex python but it only rounds up. 25 mod/4 high = 7 wide
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = Mods_Wide * mod_width + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_height + (modules_high - 1) * gap_vert;
  } else {
    // landscape
    Rack_X = Mods_Wide * mod_height + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_width + (modules_high - 1) * gap_vert;
  }

  return [Rack_X, Rack_Y];
}

function GlideWave(mod_width, mod_height, modules_per_rack, modules_high, orientation, gap_vert, gap_horz) {
  // user inputs modules per rack, returns and x and y dimension. Agnostic of modules per string or string size target.
  // currently no different than standard GFT. Maintaining seperate call for future customization if necessary.

  let Rack_X;
  let Rack_Y;

  // #complex python but it only rounds up. 25 mod/4 high = 7 wide
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = Mods_Wide * mod_width + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_height + (modules_high - 1) * gap_vert;
  } else {
    // landscape
    Rack_X = Mods_Wide * mod_height + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_width + (modules_high - 1) * gap_vert;
  }

  return [Rack_X, Rack_Y];
}

function GlideAgile(mod_width, mod_height, ___modules_per_rack, modules_high, orientation, gap_vert, gap_horz) {
  // user inputs modules per rack, returns and x and y dimension. Agnostic of modules per string or string size target.

  // modules_per_rack is fixed to 24 for Agile
  let modules_per_rack = 24;

  let Rack_X;
  let Rack_Y;

  // #complex python but it only rounds up. 25 mod/4 high = 7 wide
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_X = Mods_Wide * mod_width + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_height + (modules_high - 1) * gap_vert;
  } else {
    // landscape
    Rack_X = Mods_Wide * mod_height + (Mods_Wide - 1) * gap_horz;
    Rack_Y = modules_high * mod_width + (modules_high - 1) * gap_vert;
  }

  return [Rack_X, Rack_Y];
}

export function calculate_racking_dims(inputs) {
  /*
		required inputs to run calculations:
		mod_per_string, modules_high, orientation, module_gap, drive_gap
		rack: string_count, module
		module: mod_width, mod_height

		ints: mod_per_string, modules_high, module
		floats: module_gap, drive_gap, string_count, 
			mod_width, mod_height
	*/

  let updated_racks = [];
  inputs.racks.forEach((rack, index) => {
    let cur_rack = rack;

    let rack_xy = [];
    if (cur_rack.string_count > 0) {
      // if string count is set to 0.5 AND mods per string is an EVEN number

      let string_cnt_is_float;
      // let string_cnt_is_float = parseFloat(cur_rack.string_count) === 0.5 && inputs.mod_per_string % 2 == 0;
      let str_cnt;
      if ((inputs.track_mode == 0 || inputs.track_mode == 2) && index == 2) {
        // string_cnt_is_float = parseFloat(cur_rack.string_count) === 0.5 && inputs.mod_per_string % 2 == 0;
        str_cnt = round_to_nearest_half(cur_rack.string_count);
      } else {
        string_cnt_is_float = parseFloat(cur_rack.string_count) === 0.5 && inputs.mod_per_string % 2 == 0;
        str_cnt = string_cnt_is_float ? parseFloat(cur_rack.string_count) : parseInt(_.round(cur_rack.string_count, 0));
      }

      cur_rack.module = inputs.mod_per_string * str_cnt;
      cur_rack.string_count = str_cnt;
    } else {
      cur_rack.string_count = 0;
      cur_rack.module = 0;
    }

    if (inputs.track_mode == 0) {
      // GFT
      if (inputs.racking_name == "Terrasmart GLIDE Agile") {
        rack_xy = GlideAgile(inputs.mod_width, inputs.mod_height, 24, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.module_gap);
      } else if (inputs.racking_name == "Terrasmart GLIDE Wave") {
        rack_xy = GlideWave(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.module_gap);
      } else if (inputs.racking_name == "Terrasmart GLIDE Fuse") {
        rack_xy = GlideFuse(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.module_gap);
      } else {
        rack_xy = Standard_GFT(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap);
      }
    } else if (inputs.track_mode == 1) {
      // SAT

      if (inputs.racking_name == "Terrasmart TerraTrak 2P") {
        rack_xy = Terratrak_2P_SAT(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.en_bifacial);
      } else if (inputs.racking_name == "Terrasmart TerraTrak 1P") {
        rack_xy = Terratrak_1P_SAT(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.drive_gap);
      } else {
        rack_xy = Standard_SAT(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.drive_gap);
      }
    } else {
      // EWF
      rack_xy = Standard_EWF(inputs.mod_width, inputs.mod_height, cur_rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap);
    }

    cur_rack.xdim = rack_xy[0];
    cur_rack.ydim = rack_xy[1];

    if (_.isFinite(cur_rack.xdim)) {
      cur_rack.xdim = _.round(parseFloat(cur_rack.xdim), 3);
    }
    if (_.isFinite(cur_rack.ydim)) {
      cur_rack.ydim = _.round(parseFloat(cur_rack.ydim), 3);
    }

    if (inputs.racking_name == "Terrasmart Glide AGILE") {
      cur_rack.module = 26;
    }

    updated_racks.push(cur_rack);
  });

  return updated_racks;
}

export function check_for_racking_errors(inputs) {
  let { mod_per_string, racks, mod_width, mod_height } = inputs;
  const moduleArea = _.round(parseFloat(mod_height * mod_width), 3);
  const rackingNames = ["Rack A", "Rack B", "Rack C"];
  let error_one;
  let error_two;
  let error_three;
  let error_four;
  let error_five;
  const modAreaErrorOne = [];
  const modAreaErrorTwo = [];
  const modHeightError = [];
  const inputError = [];
  const stringCountOrderErr = [];

  let show_in_validation = false;

  // if (inputs.rack_dims == 1) {
  //   racks.map((rack) => (rack.string_count = _.round(rack.module / inputs.mod_per_string, 2)));
  // }

  racks.map((rack, index) => {
    if (rack.active == 1) {
      let rack_area = rack.xdim * rack.ydim;
      let module_area = rack.module * moduleArea;

      if (module_area > rack_area) {
        modAreaErrorOne.push(rackingNames[index]);
      }

      if (module_area < 0.8 * rack_area) {
        modAreaErrorTwo.push(rackingNames[index]);
      }

      if (!_.isFinite(rack.xdim) || !_.isFinite(rack.ydim) || !_.isFinite(rack.module)) {
        inputError.push(rackingNames[index]);
      }

      if (inputs.racking_name == "Terrasmart TerraTrak 2P" && rack.ydim > 60.96) {
        modHeightError.push(rackingNames[index]);
      }

      if (inputs.rack_dims == 0) {
        if ((index == 0 && racks[1].active && racks[1].string_count > rack.string_count) || (racks[2].active && racks[2].string_count > rack.string_count)) {
          stringCountOrderErr.push(rackingNames[index]);
        }

        if (index == 1 && ((racks[0].active && rack.string_count > racks[0].string_count) || (racks[2].active && rack.string_count < racks[2].string_count))) {
          stringCountOrderErr.push(rackingNames[index]);
        }
      } else {
        if ((index == 0 && racks[1].active && racks[1].module > rack.module) || (racks[2].active && racks[2].module > rack.module)) {
          stringCountOrderErr.push(rackingNames[index]);
        }

        if ((index == 1 && racks[0].active && racks[0].module < rack.module) || (racks[2].active && racks[2].module > rack.module)) {
          stringCountOrderErr.push(rackingNames[index]);
        }
      }
    }
  });

  if (modAreaErrorOne.length > 0) {
    error_one = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorOne.join(
      ", "
    )} is greater than your Racking Area  (X * Y). Rack dimensions are not large enough for module count. Reduce module count or check rack dimensions.`;
  }

  if (modAreaErrorTwo.length > 0) {
    error_two = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorTwo.join(
      ", "
    )} accounts for less than 80% of your Racking Area (X * Y). Check the racking dimensions and the module counts.`;
  }

  if (inputError.length > 0 || !Number.isInteger(mod_per_string)) {
    error_three = `Warning: Strings per rack of ${inputError.join(", ")} (Mod Count / Modules per String) is non integer. Check Mod Count per rack and Modules Per String in Performance.`;
  }

  if (modHeightError.length > 0) {
    error_four = `Warning: The Y dimension of ${modHeightError.join(", ")} cannot exceed 60.96m (200ft) for TerraTrak.`;
    show_in_validation = true;
  }
  if (stringCountOrderErr.length > 0) {
    if (inputs.rack_dims == 1) {
      error_five = `Warning: Please adjust your Mod Count to follow the convention A > B > C`;
    } else {
      error_five = `Warning: Please adjust your Strings Per Rack to follow the convention A > B > C`;
    }
  }

  let errors = {
    error_one,
    error_two,
    error_three,
    error_four,
    error_five,
    show_in_validation,
  };

  let error_obj;

  if (errors.error_one || errors.error_two || errors.error_three || errors.error_four || errors.error_five) {
    error_obj = {
      contains_errors: true,
      errors,
    };
    return error_obj;
  } else {
    error_obj = {
      contains_errors: false,
      errors,
    };
    return error_obj;
  }
}
