import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OutputPanelWrapper } from "../../../OutputPanel/sc";
import { CanopyResultTable } from "./CanopyResultTable";
import { LiveCanopyReport, ThirtyPercentSet } from "../LiveCanopyReport";
import { DownloadCanopyPDF } from "../LiveCanopyReport/CanopyPDFReport";
import { getMMDDTYYYY } from "../../../Redux";

import { siftActions, canopyActions, inputsActions } from "../../../Redux";

import { Tooltip, Button, Spin } from "antd";
import { DownOutlined, FileExcelOutlined } from "@ant-design/icons";
import { modules, energy } from "../../../../assets/images";

import { CSVLink } from "react-csv";
import domtoimage from "dom-to-image";

const CanopyResults = () => {
  const dispatch = useDispatch();

  const canopy_results_generated = useSelector((state) => state.canopy.canopy_results_generated);
  const download_lock = useSelector((state) => state.inputs.uiState.download_lock);
  const download_type = useSelector((state) => state.inputs.uiState.download_type);
  const show_sf_modal = useSelector((state) => state.inputs.uiState.show_sf_modal);
  const project_id = useSelector((state) => state.inputs.project_id);

  const show_canopy_report = useSelector((state) => state.canopy.show_canopy_report);
  const show_percent_report = useSelector((state) => state.canopy.show_percent_report);
  const show_canopy_results = useSelector((state) => state.canopy.show_canopy_results);
  const total_modules = useSelector((state) => state.canopy.totalModules);
  const total_capacity = useSelector((state) => state.canopy.total_capacity);
  const tsvResult = useSelector((state) => state.canopy.tsvResult);
  const role = useSelector((state) => state.user.role);
  const canopy_results = useSelector((state) => state.canopy.canopy_results);
  const canopy_images = useSelector((state) => state.canopy.canopy_results.images);
  const report_ready = useSelector((state) => state.canopy.report_ready);

  const textArea = useRef(null);
  const mapRefs = useRef(null);
  const csvRef = useRef();

  const [report_loading, set_report_loading] = useState(false);
  const [generating_report, set_generating_report] = useState(false);
  const [report_generated, set_report_generated] = useState(false);

  const log_download = (download_type, url = undefined) => {
    dispatch(siftActions.log_download(download_type, url, project_id));
  };

  useEffect(() => {
    if (show_canopy_report || show_percent_report) {
      displayCanopyReport();
    }
    if ((show_canopy_report === false || show_percent_report === false) && show_sf_modal) {
      dispatch(inputsActions.toggle_sf_modal("show_sf_modal", false));
    }
  }, [show_canopy_report, show_percent_report]);

  useEffect(() => {
    if (!report_ready && Object.values(canopy_images).length === 0) return;
    set_generating_report(false);
    set_report_generated(true);
  }, [canopy_images]);

  useEffect(() => {
    if (download_lock === false) {
      if (download_type === "canopy_report") {
        generateLiveCanopyReport();
      }
      if (download_type === "thirty_percent_report") {
        generateLiveCanopyReport("thiryPercentReport");
      }
      if (download_type === "canopy_csv") {
        csvRef.current.link.click();
      }
    }
  }, [download_lock]);

  const displayCanopyReport = () => {
    set_report_loading(true);
    setTimeout(() => {
      set_report_loading(false);
    }, 2000);
  };

  const show_report = (report) => {
    if (report === "show_canopy_report") {
      dispatch(canopyActions.updateCanopyInputs("show_canopy_report", !show_canopy_report));
    } else {
      dispatch(canopyActions.updateCanopyInputs("show_percent_report", !show_percent_report));
    }
  };

  const reset_report = () => {
    set_report_generated(false);
    dispatch(canopyActions.updateCanopyInputs("report_ready", false));
  };

  const handleCaptureReportImages = async (keys = undefined) => {
    let tempCanopyImages;
    for (let key in mapRefs.current) {
      let value = await getPngUrl(mapRefs.current[key]);
      tempCanopyImages = { ...tempCanopyImages, [key]: value };
    }
    return tempCanopyImages;
  };

  const getPngUrl = async (mapRef) => {
    let width = mapRef._size.x;
    let height = mapRef._size.y;
    let node = document.getElementById(`${mapRef._container.id}`);
    return await domtoimage.toPng(node, { width, height, quality: mapRef._container.id === "mainMap" ? 1 : 0.5 }).catch((error) => console.log("image capture error", error));

    // return new Promise((resolve, reject) => {
    //   let width = mapRef._size.x;
    //   let height = mapRef._size.y;
    //   let node = document.getElementById(`${mapRef._container.id}`);
    //   domtoimage
    //     .toPng(node, { width, height, quality: mapRef._container.id == "live-canopy-report-map" ? 1 : 0.4 })
    //     .then((url) => resolve({ url, width, height }))
    //     .catch((error) => reject(error));
    // });
  };

  const generateLiveCanopyReport = async (type = "canopyReport") => {
    set_generating_report(true);
    if (report_generated) set_report_generated(false);

    let temp_canopy_results = { ...JSON.parse(JSON.stringify(canopy_results)) };
    let tempCanopyMapImages = await handleCaptureReportImages();
    temp_canopy_results.images = tempCanopyMapImages;
    dispatch(canopyActions.updateCanopyInputs("canopy_results", temp_canopy_results, true));

    // Object.keys(mapRefs.current).map((key) => {
    //   handleCaptureReportImages(key).then((data) => {
    //     images = { ...images, [key]: data.url };
    //     if ((type == "CanopyReport" && Object.values(images).length == Object.values(canopy_results.results).length + 1) || Object.values(images).length == 2) {
    //       let temp_canopy_results = { ...JSON.parse(JSON.stringify(canopy_results)) };
    //       temp_canopy_results.images = images;
    //       dispatch(canopyActions.updateCanopyInputs("canopy_results", temp_canopy_results));
    //     }
    //   });
    // });
  };

  const setCanopyMapRefs = (key, ref) => {
    mapRefs.current = { ...mapRefs.current, [key]: ref };
  };

  const saveThirtyPercentInputs = (key, value) => {
    let temp_canopy_results = { ...JSON.parse(JSON.stringify(canopy_results)) };
    temp_canopy_results.thirtyPercentInputs = value;
    dispatch(canopyActions.updateCanopyInputs("canopy_results", temp_canopy_results));
  };

  return (
    <OutputPanelWrapper generated={canopy_results_generated} detailsVisible={show_canopy_results} show_live_report={show_canopy_report || show_percent_report}>
      {/* <ToggleResultWrapper detailsVisible={detailsVisible}>
        <section className="toggle-result">
          <a
            onClick={() => {
              console.log("click lick click");
              dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", !detailsVisible));
            }}
          >
            {detailsVisible && <DownOutlined />}
          </a>
        </section>
      </ToggleResultWrapper> */}

      <section className="output-data">
        <section className="tab-results">
          <section className="canopy-result-buttons">
            {/* PDF BUTTONS */}
            {!show_percent_report && (
              <Button ghost type="primary" size="small" disabled={generating_report || report_loading} onClick={() => show_report("show_canopy_report")}>
                {show_canopy_report ? "Hide PDF" : "Show PDF"}
              </Button>
            )}
            {show_canopy_report && (
              <Button
                ghost
                type="primary"
                disabled={generating_report || report_loading}
                loading={generating_report}
                size="small"
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "canopy_report"));
                  } else {
                    generateLiveCanopyReport();
                  }
                }}
              >
                {download_lock ? "Unlock Download" : "Download Report"}
              </Button>
            )}

            {/* 30% BUTTONS */}
            {!show_canopy_report && (
              <Button ghost type="primary" size="small" disabled={(role < 10 && role !== 1) || generating_report || report_loading} onClick={() => show_report("show_percent_report")}>
                {show_percent_report ? "Hide 30% Set" : "Show 30% Set"}
              </Button>
            )}
            {show_percent_report && (
              <Button
                ghost
                type="primary"
                size="small"
                disabled={(role < 10 && role !== 1) || generating_report || report_loading}
                loading={generating_report}
                onClick={() => {
                  if (download_lock) {
                    dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "thirty_percent_report"));
                  } else {
                    generateLiveCanopyReport("thiryPercentReport");
                  }
                }}
              >
                {download_lock ? "Unlock Download" : "Download 30% Set"}
              </Button>
            )}

            {!show_canopy_report && !show_percent_report && (
              <span id="download-csv-tip">
                {download_lock ? (
                  <Button ghost type="primary" size="small" onClick={() => dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "canopy_csv"))}>
                    Unlock CSV
                  </Button>
                ) : (
                  <CSVLink ref={csvRef} filename={canopy_results.project ? `${canopy_results.project}_${getMMDDTYYYY()}.csv` : `canopy_project_${getMMDDTYYYY()}.csv`} data={canopy_results.csvResult}>
                    <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5} onClick={() => log_download("canopy_csv")}>
                      <Button ghost type="primary" size="small">
                        <FileExcelOutlined />
                        CSV
                      </Button>
                    </Tooltip>
                  </CSVLink>
                )}
              </span>
            )}
          </section>

          {!show_canopy_report && !show_percent_report ? (
            <>
              <section className="results-stats">
                <section className="stat">
                  <section className="stat-img">
                    <img src={modules} alt="solar panel modules" />
                  </section>
                  <section className="stat-text">
                    <strong>Module Qty:</strong>
                    <p>{total_modules}</p>
                  </section>
                </section>
                <section className="stat">
                  <section className="stat-img">
                    <img src={energy} alt="Solar energy" />
                  </section>
                  <section className="stat-text">
                    <strong>Site Capacity:</strong>
                    <p>{`${parseFloat(total_capacity / 1000).toFixed(2)} kW`}</p>
                  </section>
                </section>
                <section className="canopy-result-minimize">
                  <button onClick={() => dispatch(canopyActions.updateCanopyInputs("show_canopy_results", !show_canopy_results))}>{<DownOutlined />}</button>
                </section>
              </section>

              <CanopyResultTable />
            </>
          ) : (
            <>
              {show_canopy_report &&
                (report_loading ? (
                  <Spin spinning={report_loading} tip="Loading PDF Report..." style={{ marginTop: 200 }} />
                ) : (
                  <LiveCanopyReport reportData={canopy_results} setCanopyMapRefs={setCanopyMapRefs} loadingCanopyLiveReport={report_loading} />
                ))}

              {show_percent_report &&
                (report_loading ? (
                  <Spin spinning={report_loading} tip="Loading 30% Set..." style={{ marginTop: 200 }} />
                ) : (
                  <ThirtyPercentSet loadingCanopyLiveReport={report_loading} reportData={canopy_results} setCanopyMapRefs={setCanopyMapRefs} saveThirtyPercentInputs={saveThirtyPercentInputs} />
                ))}
            </>
          )}

          {report_generated && report_ready && canopy_results && !report_loading && (
            <DownloadCanopyPDF data={canopy_results} reset_report={reset_report} mode={show_canopy_report ? "canopy" : "thirtyPercent"} />
          )}
        </section>
      </section>

      <textarea
        hidden
        ref={textArea}
        name="TextResults"
        cols="40"
        rows="50"
        value={tsvResult}
        onChange={(e) => {
          console.log("copied to clipboard");
        }}
      />
    </OutputPanelWrapper>
  );
};

export { CanopyResults };
