import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber } from "../../../../../Redux";

import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;

import { InputRow } from "./styles";
import { debouncedUpdateinput } from "./inputs.helper";

function AzimuthInput() {
  const dispatch = useDispatch();

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);

  const azimuth = useSelector((state) => state.canopy.canopies[selected_canopy]?.azimuth);

  const [local_azimuth, set_local_azimuth] = useState(azimuth);
  const [local_loading, set_local_loading] = useState(false);
  const [azimuth_error, set_azimuth_error] = useState(undefined);

  useEffect(() => {
    if (azimuth && azimuth == local_azimuth) {
      set_local_loading(false);
    } else if (!local_loading) {
      set_local_azimuth(azimuth);
    }
  }, [azimuth]);

  function onChangeInput(e) {
    set_local_azimuth(e.target.value);

    let changed_value = parseFloat(e.target.value);

    if (!isNumber(changed_value)) {
      set_azimuth_error("Invalid Input");
      return;
    } else if (changed_value < 0 || changed_value > 359) {
      set_azimuth_error("Invalid Input");
      return;
    } else if (azimuth_error != undefined) {
      set_azimuth_error(undefined);
    }
    set_local_loading(true);
    // debounced method
    debouncedUpdateinput.cancel();
    debouncedUpdateinput(dispatch, selected_canopy, "azimuth", changed_value);
  }

  return (
    <InputRow>
      <label>Azimuth</label>
      <Input
        size="small"
        value={local_azimuth}
        type="number"
        step={1}
        min={0}
        max={999}
        suffix={local_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
        onChange={onChangeInput}
      ></Input>
    </InputRow>
  );
}

export { AzimuthInput };
