import { authHeader } from "../_helpers";

const handleResponse = async (response) => {
  if (!response.ok) {
    // Handle a not ok
    const errorText = await response.text();
    return Promise.reject(`Error ${response.status}: ${errorText}`);
  }

  const contentType = response.headers.get("content-type");

  // Clone the response so that we can use it more that once if needed
  const clonedResponse = response.clone();

  if (contentType && contentType.includes("application/json")) {
    try {
      const jsonResponse = await clonedResponse.json();

      if (jsonResponse.success === undefined || jsonResponse.success) {
        return jsonResponse.data || jsonResponse; // Return data if it's present, or the whole response if it's present
      }

      if (jsonResponse.error_code === 401) {
        location.reload(true); // handle a 401 if we need to
      }

      const error = jsonResponse.data || jsonResponse.message || "Unknown error";
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject("Invalid JSON response");
    }
  } else {
    const text = await clonedResponse.text();
    return Promise.reject("Response is not JSON: " + text);
  }
};

// const fetchUtil = async (url, options = {}, includeAuthHeader = true, responseHandler = undefined, noHeaders = false) => {
//   // Add default Content-Type header if not provided
//   let defaultHeaders = {
//     "Content-Type": "application/json",
//     //"ngrok-skip-browser-warning": true,
//     //"Access-Control-Allow-Origin":"",
//     //"User-Agent" : "*/*",
//     ...(includeAuthHeader && authHeader()),
//   };

//   const requestOptions = {
//     ...options,
//     headers: {
//       ...defaultHeaders,
//       ...options.headers,
//     },
//   };

//   let response;

//   if (noHeaders) {
//     response = await fetch(url);
//   } else {
//     response = await fetch(url, requestOptions);
//   }

//   return responseHandler ? responseHandler(response) : handleResponse(response);
// };

const fetchUtil = async (url, options = {}, includeAuthHeader = true, responseHandler = handleResponse, noHeaders = false) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    //"ngrok-skip-browser-warning": true,
    //"Access-Control-Allow-Origin":"",
    //"User-Agent" : "*/*",
    ...(includeAuthHeader && authHeader()),
  };

  const requestOptions = noHeaders
    ? {}
    : {
        ...options,
        headers: {
          ...defaultHeaders,
          ...options.headers,
        },
      };

  const response = await fetch(url, noHeaders ? undefined : requestOptions);

  return responseHandler(response);
};

export default fetchUtil;
