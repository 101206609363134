import React from "react";

import { useSelector } from "react-redux";

//import { ZoomAndLayerControlLayout } from "./styles";
import { ToolbarWrap } from "../../../Map/components/Toolbar/styles";

import { LeafletControl } from "../../../Map/components/LeafletControl";
import { getBounds } from "../../../Redux";

import { zoom_in, zoom_out, zoom_extents } from "../../../../assets/images";
import { Tooltip } from "antd";

const zoomGranularity = 0.25;

const ZoomControl = ({ mapRef, mapName, position }) => {
  const features = useSelector((state) => state.inputs.site_features);

  const zoomExtents = (zoomLevel = undefined) => {
    if (mapName === "vicinityMap") {
      mapRef.flyTo(position, 14);
    } else {
      if (Object.values(features).length === 0) return;

      let bounds = getBounds(Object.values(features));

      if (zoomLevel) {
        mapRef.fitBounds(
          [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ],
          { maxZoom: zoomLevel }
        );
      } else {
        mapRef.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    }
  };

  const zoomIn = () => {
    mapRef.zoomIn(zoomGranularity);
  };
  const zoomOut = () => {
    mapRef.zoomOut(zoomGranularity);
  };

  return (
    <LeafletControl position="topright">
      <ToolbarWrap vertical={true}>
        <section className="map-action-buttons ">
          <Tooltip title="Zoom In" placement="left">
            <button onClick={() => zoomIn()}>
              <img src={zoom_in} />
            </button>
          </Tooltip>
          <Tooltip title="Zoom Out" placement="left">
            <button size="large" onClick={() => zoomOut()}>
              <img src={zoom_out} />
            </button>
          </Tooltip>
          <Tooltip title="Zoom Extents" placement="left">
            <button size="large" onClick={() => zoomExtents()}>
              <img style={{ marginBottom: "2px" }} src={zoom_extents} />
            </button>
          </Tooltip>
        </section>
      </ToolbarWrap>
    </LeafletControl>
  );
};

export { ZoomControl };
