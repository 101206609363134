import React from "react";
import { GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";

const CoordSystemBox = () => {
  const coord_system_bbox = useSelector((state) => state.inputs.coord_system_bbox);

  return (
    <>
      {coord_system_bbox && (
        <GeoJSON
          data={coord_system_bbox}
          transform={false}
          draggable={false}
          interactive={false}
          key={coord_system_bbox.properties.index}
          bubblingMouseEvents={false}
          style={{
            fillColor: "#fff",
            fillOpacity: 0,
            weight: 4,
            color: "#0096FF",
          }}
        ></GeoJSON>
      )}
    </>
  );
};

export { CoordSystemBox };
