import React, { useState, useEffect } from "react";
import { Alert, Spin } from "antd";
import config from "config";
import { Base } from "../../layouts";

import { useParams } from "react-router-dom";

const emailUrl = config.env == "prod" ? "https://api.sunfig.com/emailverify/" : "https://api.sunfig.solar/emailverify/";

const EmailVerification_new = () => {
  let { code } = useParams();

  let [resp_code, set_resp_code] = useState(0);

  useEffect(() => {
    console.log("Verifying email", code);
    const requestOptions = {
      method: "POST",
      // headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type: "emailverify",
        code: code,
      }),
    };
    fetch(emailUrl, requestOptions).then(handleResponse);
  }, []);

  const handleResponse = (response) => {
    return response.text().then((text) => {
      let data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      if (data == "Success") {
        set_resp_code({ code: 1 });
      } else {
        set_resp_code({ code: 2 });
      }

      return data;
    });
  };

  return (
    <Base>
      <section className="email-verify">
        {resp_code == 0 ? (
          <section>
            {/* Loading */}
            <Spin spinning={true}>
              <br />
              <span>Verifying Email</span>
            </Spin>
          </section>
        ) : resp_code == 1 ? (
          <section className="email-verify-response">
            {/* Verified */}
            <Alert
              message="Verified Email"
              description={
                <span>
                  Email has been verified. <a href="/account">Go to Account page.</a>
                </span>
              }
              type="success"
              showIcon
            />
          </section>
        ) : (
          <section className="email-verify-response">
            {/* Error */}
            <Alert message="Verified Email" description="Email unable to be verified, please try again later." type="error" showIcon />
          </section>
        )}
      </section>
    </Base>
  );
};

export { EmailVerification_new };
