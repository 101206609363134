import React from "react";
import "./hero.css";
import "./buttons.css";

import { Button, Row, Col, Typography } from "antd";
import { SIFT_Button } from "./Button";

const Hero = (props) => {
  return (
    <section className="hero">
      <div className="intro-area">
        <div className="intro-text">
          <div className="hero-header-text">
            <div>Become a solar optimization pro!</div>
            <div>Find the best design for your project, instantly.</div>
          </div>
          <div>
            <SIFT_Button onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>Sign Up Now</SIFT_Button>
            <SIFT_Button type="ghost" href="#sift" className="default">
              Learn More
            </SIFT_Button>
          </div>
        </div>

        <div className="intro-tutorial-container">
          <div className="intro-tutorial-video">
            <iframe
              style={{ border: 0 }}
              title="Sunfig Design Optimization with SIFT"
              // src="https://www.youtube.com/embed/2V8PD71AyeI"
              src="https://www.youtube.com/embed/P38eQ9DeX8E"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
