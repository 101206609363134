import React, { useState, useEffect } from "react";
import { bulb, bulb_hide } from "../../../assets/images";
import { ImageCheckboxWrap } from "./styles";

const ImageCheckbox = ({ id, checked = true, onChange, checkedImage = bulb, uncheckedImage = bulb_hide }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onChange({ id, checked: newCheckedState });
  };

  return (
    <ImageCheckboxWrap onClick={handleToggle}>
      <img src={isChecked ? checkedImage : uncheckedImage} alt={isChecked ? "Checked" : "Unchecked"} />
    </ImageCheckboxWrap>
  );
};

export { ImageCheckbox };
