import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { canopyActions } from "../../../Redux";
import { duplicate_canopy, translate_canopy } from "../CanopyHelpers";
import { useMap } from "react-leaflet";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

// const CanopyHotkeys = () => {
//   const map = useMap();
//   const dispatch = useDispatch();
//   const canopies = useSelector((state) => state.canopy.canopies);
//   const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
//   const canopy = canopies[selected_canopy];

//   const canopy_ref = useRef(canopy);
//   const selected_canopy_ref = useRef(selected_canopy);

//   useEffect(() => {
//     canopy_ref.current = canopy;
//   }, [canopy]);

//   useEffect(() => {
//     selected_canopy_ref.current = selected_canopy;
//   }, [selected_canopy]);

//   // hotkeys
//   const control = useKey("control");
//   const meta = useKey("meta");
//   const arrow_up = useKey("ArrowUp");
//   const arrow_down = useKey("ArrowDown");
//   const arrow_left = useKey("ArrowLeft");
//   const arrow_right = useKey("ArrowRight");

//   useEffect(() => {
//     if (selected_canopy_ref.current == canopy_ref?.current?.id) {
//       if (arrow_up && (meta || control)) {
//         dup_canopy("up");
//       }
//       if (arrow_down && (meta || control)) {
//         dup_canopy("down");
//       }
//       if (arrow_left && (meta || control)) {
//         dup_canopy("left");
//       }
//       if (arrow_right && (meta || control)) {
//         dup_canopy("right");
//       }
//     }
//   }, [meta, control, arrow_up, arrow_down, arrow_left, arrow_right]);

//   const dup_canopy = (direction) => {
//     let duplicated_canopy = duplicate_canopy(direction, canopy_ref.current);
//     dispatch(canopyActions.updateCanopyInputs("add_canopy", duplicated_canopy));
//   };

//   const mapHasFocus = () => {
//     return document.getElementById("map") == document.activeElement;
//   };

//   return <></>;
// };

const CanopyHotkeys = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const canopies = useSelector((state) => state.canopy.canopies);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const canopy = canopies[selected_canopy];

  const canopy_ref = useRef(canopy);
  const selected_canopy_ref = useRef(selected_canopy);

  useEffect(() => {
    canopy_ref.current = canopy;
  }, [canopy]);

  useEffect(() => {
    selected_canopy_ref.current = selected_canopy;
  }, [selected_canopy]);

  useEffect(() => {
    map.on("keydown", onKeyDown);

    return () => map.off("keydown");
  }, []);

  const onKeyDown = (e) => {
    if (!mapHasFocus && selected_canopy_ref.current !== canopy_ref?.current?.id) return;

    // if (e.originalEvent.altKey && e.originalEvent.shiftKey) {
    //   // Rotation right
    //   if (e.originalEvent.keyCode == 39) {
    //     dispatch(canopyAations.updateCanopyById(_selected.idRef, undefined, "rotateright"));
    //   }
    //   // Rotation left
    //   if (e.originalEvent.keyCode == 37) {
    //     dispatch(canopyAations.updateCanopyById(_selected.idRef, undefined, "rotateleft"));
    //   }
    // }

    // check to see if control or CMD is pressed
    if ((e.originalEvent.metaKey && e.originalEvent.shiftKey == false) || (e.originalEvent.ctrlKey && e.originalEvent.shiftKey == false)) {
      // delete canopy
      if (e.originalEvent.keyCode == 8) {
        if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
        if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
        dispatch(canopyActions.deleteCanopies(selected_canopy_ref.current));
      }
      // duplicate right
      if (e.originalEvent.keyCode == 39) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dup_canopy("right");
      }
      // duplicate left
      if (e.originalEvent.keyCode == 37) {
        // the prevent default has to be here on the duplicate left as the browser will just go back a page(befault browser behavior)
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dup_canopy("left");
      }
      // duplicate up
      if (e.originalEvent.keyCode == 38) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dup_canopy("up");
      }
      // duplicate down
      if (e.originalEvent.keyCode == 40) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dup_canopy("down");
      }
    }

    if ((e.originalEvent.metaKey && e.originalEvent.shiftKey) || (e.originalEvent.ctrlKey && e.originalEvent.shiftKey)) {
      // translate right
      if (e.originalEvent.keyCode == 39) {
        // 90° = translate right
        handleCanopyTranslate(90);
      }
      // translate left
      if (e.originalEvent.keyCode == 37) {
        // -90° = translate left
        handleCanopyTranslate(-90);
      }
      // translate up
      if (e.originalEvent.keyCode == 38) {
        // 0° = translate up
        handleCanopyTranslate(0);
      }
      // -180° = translate down
      if (e.originalEvent.keyCode == 40) {
        handleCanopyTranslate(-180);
      }
    }
  };

  const dup_canopy = (direction) => {
    if (!canopy_ref.current) return;
    let duplicated_canopy = duplicate_canopy(direction, canopy_ref.current);
    dispatch(canopyActions.updateCanopyInputs("add_canopy", duplicated_canopy));
  };

  const handleCanopyTranslate = (direction) => {
    if (!canopy_ref.current) return;
    let translated_canopy = translate_canopy(canopy_ref.current, direction);
    translate_canopy.changed_from_inputs = false;
    dispatch(canopyActions.updateCanopyInputs("edit_canopy", translated_canopy));
  };

  const mapHasFocus = () => {
    return document.getElementById("map") == document.activeElement;
  };
  return <></>;
};

export { CanopyHotkeys };
