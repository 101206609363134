import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { canopyActions } from "../../../../../Redux";

import { Radio } from "antd";

import { InputRadios } from "./styles";

function OrientationInput() {
  const dispatch = useDispatch();

  // const orientation = useSelector((state) => state.carport.selectedInputs.orientation);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const orientation = useSelector((state) => state.canopy.canopies[selected_canopy]?.orientation);

  function onSelectOrientation(e) {
    dispatch(canopyActions.updateCanopyProperty(selected_canopy, "orientation", e.target.value, true));
  }

  return (
    <InputRadios>
      <Radio.Group value={orientation} onChange={onSelectOrientation}>
        <Radio value={1}>Portrait</Radio>
        <Radio value={2}>Landscape</Radio>
      </Radio.Group>
    </InputRadios>
  );
}

export { OrientationInput };
