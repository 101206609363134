export const ProjectConstants = {
  GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST ",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE: "GET_USER_DATA_FAILURE",

  GOT_PROJECT_DATA: "GOT_PROJECT_DATA",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",

  LOAD_PROJECT_REQUEST: "LOAD_PROJECT_REQUEST",
  LOAD_PROJECT_COMPLETE: "LOAD_PROJECT_COMPLETE",

  REFRESH_PROJECT_REQUEST: "REFRESH_PROJECT_REQUEST",
  REFRESH_PROJECT_SUCCESS: "REFRESH_PROJECT_SUCCESS",
  SHARE_COLLAB_REQUEST: "SHARE_COLLAB_REQUEST",
  COLLAB_SUCCESS: "COLLAB_SUCCESS",
  COLLAB_FAILURE: "COLLAB_FAILURE",
  CHECK_COLLAB_REQUEST: "CHECK_COLLAB_REQUEST",
  CLEAR_PROJECT: "CLEAR_PROJECT",

  NEW_PROJECT: "NEW_PROJECT",

  LOAD_PROJECT: "LOAD_PROJECT",
  LOAD_PROJECT_ROW: "LOAD_PROJECT_ROW",

  SAVE_PROJECT: "SAVE_PROJECT",
  PROJECT_SAVE_AS: "PROJECT_SAVE_AS",

  PROJECT_NAME_UPDATE: "PROJECT_NAME_UPDATE",

  PROJECT_SELECT: "PROJECT_SELECT",

  DELETE_PROJET: "DELETE_PROJECT",

  HANDLE_SHOW: "HANDLE_SHOW",
  HANDLE_CLOSE: "HANDLE_CLOSE",

  FILTER_PROJECTS: "FILTER_PROJECTS",
  UPDATE_PROJECT_INPUT: "UPDATE_PROJECT_INPUT",
};
