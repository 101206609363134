import React from "react";

export const resultColumns = [
  {
    title: "Name",
    key: "Name",
    width: "125px",
    visible: true,
  },
  {
    title: "Module",
    key: "Module",
    width: "120px",
    visible: true,
  },
  {
    title: "Canopy Type",
    key: "Canopy Type",
    width: "120px",
    render: (record) => <div>{prettifyText(record["Canopy Type"])}</div>,
    visible: true,
  },
  {
    title: "Azimuth (°)",
    key: "Azimuth (°)",
    width: "75px",
    visible: true,
    render: (record) => {
      return <div>{parseInt(record["Azimuth (°)"])}</div>;
    },
  },
  {
    title: "Tilt (°)",
    key: "Tilt (°)",
    width: "70px",
    visible: true,
  },
  {
    title: "Dimensions (LxW) Qty",
    key: "Mod Dimension X Qty",
    width: "160px",
    render: (record) => {
      return <div>{`${parseInt(record["Mod Dimension X Qty"])}mod by ${parseInt(record["Mod Dimension Y Qty"])}mod`}</div>;
    },
    visible: true,
  },
  {
    title: "Dimensions (LxW) ft",
    key: "Mod Dimension X Ft",
    width: "150px",
    render: (record) => {
      return <div>{`${parseFloat(record["Mod Dimension X Ft"]).toFixed(1)} ft by ${parseFloat(record["Mod Dimension Y Ft"]).toFixed(1)} ft`}</div>;
    },
    visible: true,
  },
  {
    title: "Module Qty",
    key: "Module Qty",
    width: "95px",
    visible: true,
  },

  {
    title: "Generation (kWh)",
    key: "Generation (KWh)",
    width: "130px",
    visible: true,
  },
  {
    title: "Yield (kWh/kWp)",
    key: "Yield (kWh/kWp)",
    width: "85px",
    visible: true,
  },
  {
    title: "Lead Quantity",
    key: "Lead Quantity",
    width: "85px",
    visible: true,
  },
  {
    title: "Linear Feet",
    key: "Linear Feet",
    width: "85px",
    visible: true,
  },
  {
    title: "MC4 Connectors",
    key: "MC4 Connectors",
    width: "85px",
    visible: true,
  },
];

function prettifyText(text) {
  // takes in the ugly text from the product data planeType and makes it a little more presentable
  switch (text) {
    case "single_slope":
      return "Single Slope";
    case "inverted":
      return "Inverted";
    case "dual_tilt":
      return "Dual Tilt";
    default:
      return "undefined";
  }
}
