import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canopyActions } from "../../Redux";
import { Slider } from "antd";

const ShadeSlider = () => {
  const dispatch = useDispatch();

  const shade_results = useSelector((state) => state.canopy.shade_results);
  const shade_month = useSelector((state) => state.canopy.shade_month);

  //   useEffect(() => {
  //     console.log("results", shade_results);
  //   }, [shade_results]);

  const handleSliderChange = (value) => {
    // console.log("shade hour value", value);
    dispatch(canopyActions.updateShadeObject("shade_hour", value));
  };

  const formatter = (value) => {
    return value < 12 ? `${value}am` : `${value == 12 ? 12 : value - 12}pm`;
  };

  if (shade_results?.shade_day_marks?.[shade_month]) {
    const keysArray = Object.keys(shade_results?.shade_day_marks?.[shade_month]);
    const minNum = parseInt(keysArray[0]);
    const maxNum = parseInt(keysArray[keysArray.length - 1]);
    return <Slider tooltip={{ formatter, placement: "top" }} min={minNum} max={maxNum} defaultValue={[minNum, maxNum]} marks={[minNum, maxNum]} onChange={handleSliderChange} />;
  } else {
    return <Slider tooltip={{ open: false, placement: "top" }} disabled={true} />;
  }
};

export { ShadeSlider };
