import styled from "styled-components";

const SimpToggleWrap = styled.section`
  width: 100%;
  display: grid;
  grid-row: 2;
  grid-template-columns: 1fr min-content;
  place-items: center self-start;
  padding: 0px 5px;
  box-sizing: border-box;
  border-bottom: ${(props) => (props.internal_sift ? "1px solid #d9d9d9" : "none")};
`;

export { SimpToggleWrap };
