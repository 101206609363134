import styled from "styled-components";

const SalesForceCaptureWrap = styled.section`
  /* width: 100%;
  height: 100%; */
  inset: 0px;
  pointer-events: none;
  z-index: 5001;
  display: grid;
  place-items: start center;
  position: absolute;

  .capture-modal {
    pointer-events: all;
    cursor: default;
    width: 340px;
    height: 130px;
    margin-top: 35px;
    background: #fff;
    padding: 10px;
    border: 1px solid #60de4f;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas:
      "capture-text"
      "capture-input"
      "capture-buttons";

    .capture-text {
      grid-area: capture-text;
      display: grid;
      grid-template-columns: 1fr min-content;

      p {
        color: #333;
      }

      svg {
        &:hover {
          cursor: pointer;
          fill: #60de4f;
        }
      }
    }

    .capture-input {
      grid-area: capture-input;
      margin-bottom: 5px;

      input {
        width: 100%;
        border-radius: 2px;
        border: 1px solid #e5e5e5;

        &:focus {
          border-color: #60de4f;
          border-right-width: 1px !important;
          outline: 0;
          box-shadow: 0 0 0 2px rgb(27 172 81 / 20%);
        }
      }
    }

    .capture-buttons {
      grid-area: capture-buttons;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr min-content;
      grid-template-areas: "timer-text the-buttons";

      .timer-text {
        grid-area: timer-text;
        display: grid;
        font-weight: 700;

        p {
          margin: 0px;
        }
      }

      .the-buttons {
        grid-area: the-buttons;
        display: grid;
        grid-template-columns: repeat(2, min-content);
        gap: 0px 5px;
        place-content: end;

        button {
          background: #fff;
          border: 1px solid #60de4f;
          border-radius: 2px;
          padding: 3px 8px;
          color: #60de4f;

          &:hover {
            opacity: 0.7;
          }
        }

        .locked {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25);
          background: #f5f5f5;
          border: none;
        }

        .unlocked {
          cursor: pointer;
          color: #fff;
          background: #60de4f;
        }
      }
    }
  }
`;

export { SalesForceCaptureWrap };
