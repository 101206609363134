import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";
import { canopyActions, create_UUID, history, isAuthenticated, siftActions, tutorialActions, usePrevious } from "../Redux";

// components
import { Agreement, AgreementUpdate, AuthModal, InputPanel, Notification, OutputPanel, projectActions, ProjectManager, IntroModal } from "../index";
import { InputPanelWrapper, MapWrapper, ProjectWrapper } from "./StyledComponents";
import { CanopyInputPanel, CanopyResults, SalesForceCapture } from "../_Internal";
import { LayerToggleMenu } from "../LayerToggleMenu";
import { Map } from "../Map/components";
import { IntercomMessenger } from "../index";
import { WebSockets } from "../WebSockets/WebSockets";
import { ImportToolModal, ErrorPopup } from "../";

// antd
import { Spin, Upload } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";

// kmz/kml libs
import ZipLoader from "zip-loader";
import textEncoding from "text-encoding";

import { useAuth } from "../Auth/AuthProvider";

const Dragger = Upload.Dragger;

export const Project = (props) => {
  const { useApi, is_authenticated } = useAuth();
  const { get_library } = useApi();

  const ioManager = useSelector((state) => state.sift.ioManager);
  //const runId = useSelector((state) => state.sift.ioManager.outputs.runState.runId);

  const show_layer_menu = useSelector((state) => state.sift.ioManager.uiState.layer_toggle.show_layer_toggle_menu);
  const toggleMobileInputs = useSelector((state) => state.sift.ioManager.uiState.toggleMobileInputs);

  const loading_map = useSelector((state) => state.sift.ioManager.inputs.map.loading_map);
  const loading_msg = useSelector((state) => state.sift.ioManager.inputs.map.loading_msg);

  const current_plan = useSelector((state) => state.user.product_plan_paid);
  const show_agreement_modal = useSelector((state) => state.user.show_agreement_modal);
  const agreement_visible = useSelector((state) => state.user.show_agreement_modal);
  const show_sf_modal = useSelector((state) => state.inputs.uiState.show_sf_modal);
  //const map_center = useSelector((state) => state.inputs.map_center);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const dispatch = useDispatch();

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);
  const hideTutorialToken = window.localStorage.getItem("hideTutorial");
  // const exampleRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  // const tutorialActive = useSelector((state) => state.tutorial.tutorialActive);
  // const tutorialPath = useSelector((state) => state.tutorial.tutorialPath);
  // const tutorialNavVisible = useSelector((state) => state.tutorial.showTutorialNav);
  const [isComplete, setIsComplete] = useState(false);

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";
  // const canopy_results_generated = useSelector((state) => state.canopy.canopy_results_generated);

  const using_canopy = window.location.pathname === "/canopy" || window.location.pathname === "/canopy/";
  //const url = window.location;

  const cur_quote_id = useSelector((state) => state.projects.quote_id);

  const results = useSelector((state) => (internal_sift && using_canopy ? state.canopy.canopy_results : state.sift.ioManager.outputs.results));
  const outputs = ioManager.outputs;
  const error_messages = useSelector((state) => (state.sift.ioManager.uiState.error_messages.length > 0 ? state.sift.ioManager.uiState.error_messages : outputs.errors));

  useEffect(() => {
    let prev_logged_in = localStorage.getItem("prev_logged_in");
    if (is_authenticated && prev_logged_in) {
      get_library();
    }
  }, [is_authenticated]);

  useEffect(() => {
    if (is_authenticated) {
      handle_quote_id();
    }
  }, []);

  function handle_quote_id() {
    let urlParams = new URLSearchParams(window.location.search);
    let quote_id = urlParams.get("quote_id");
    if (quote_id && quote_id != cur_quote_id) {
      // console.log("quote_id", quote_id);
      dispatch(projectActions.update_project_inputs("quote_id", quote_id));
      // dispatch(execute_job(get_salesforce_project(quote_id)))
    }
  }

  function get_browser_and_version() {
    let ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  }

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(ioManager.outputs.results).length > 0) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [ioManager.outputs.results]);

  let prev_loading_map = usePrevious(loading_map);
  // const history = useHistory();
  useEffect(() => {
    if (using_canopy && prev_loading_map && !loading_map) {
      history.push("/sift");
    }
  }, [loading_map]);

  useEffect(() => {
    let tutorialVisible = localStorage.getItem("hideTutorial");

    if (tutorialVisible === "false" && tutorial.tutorialActive !== false) {
      dispatch(tutorialActions.startTutorial());
    }
  }, [tutorial.tutorialActive]);

  // used by kmz importer / map -- can be refactored
  // function handleMarkerUpdate(mark) {
  //   dispatch(siftActions.updateMarker(mark));
  // }
  // function handleTopoUpdate(key, value) {
  //   dispatch(siftActions.updateTopo(key, value));
  // }
  // const getImageCorners = (width = undefined, height = undefined) => {
  //   // get center of map to use as origin
  //   let ratio = height / width;
  //   let origin = map_center;
  //   // the react-leaflet-distortable-imageoverlay takes 4 points as the corners for the image and it handles points a little strangely. It goes 1 -> 2 -> 0 -> 3
  //   let corners = [
  //     new L.latLng(origin[0] + 0.0002 * ratio, origin[1] - 0.0002),
  //     new L.latLng(origin[0] + 0.0002 * ratio, origin[1] + 0.0002),
  //     new L.latLng(origin[0] - 0.0002 * ratio, origin[1] - 0.0002),
  //     new L.latLng(origin[0] - 0.0002 * ratio, origin[1] + 0.0002),
  //   ];
  //   return corners;
  // };
  // KML IMPORT FUNCTIONS
  async function handleLoadFile(file, fileList) {
    // old method, front-end parsing of the KMZ/KML file
    // dispatch(siftActions.importKMZ(file));
    // return;

    if (file.type.includes("image")) {
      let imageReader = new FileReader();
      imageReader.addEventListener(
        "load",
        () => {
          // Setting up base64 URL on image
          let image = new Image();
          image.src = imageReader.result;
          image.onload = (event) => {
            // let imgWidth = event.currentTarget.naturalWidth;
            // let imgHeight = event.currentTarget.naturalHeight;
            let imageFile = {
              imageId: create_UUID(),
              imgSrc: event.currentTarget.currentSrc,
              name: file.name,
              type: file.type,
              size: file.size,
              layer_type: "imported_image",
              mode: "scale",
              opacity: 1,
              platform_pathname: using_canopy ? "/canopy" : "/sift",
              // corners: getImageCorners(imgWidth, imgHeight),
              // editMode: "translate",
            };
            // console.log("imagefile", imageFile);
            dispatch(canopyActions.updateImportedImages("add_image", imageFile));
          };
        },
        false
      );
      imageReader.readAsDataURL(file);
    } else {
      // Moved some of the file operation code here, so that we can extract the XML data
      // before sending it to the backend for parsing..
      // TOOD: should we just send the file and unzip it on the backend so that it's smaller in transit???

      let file_type = file.name.toLowerCase().split(".");
      file_type = file_type[file_type.length - 1];
      var options = {
        // xml: undefined,
        // name: '',
        // error: undefined,
        // file_type: file_type,

        // "data_type": importType,
        file_type: "xml",
        file_name: file.name,
        tab: "map",
      };
      if (file_type === "kml") {
        let reader = new FileReader();
        reader.onloadend = function (evt) {
          if (evt.target.readyState == FileReader.DONE) {
            let upload_data = evt.target.result;
            dispatch(siftActions.importData(options, upload_data));
          }
        };
        reader.readAsBinaryString(file);
      } else if (file_type === "kmz") {
        // If file is *.kmz we need to unzip the .kml to get the polygons
        let instance = await ZipLoader.unzip(file);
        // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
        let TextDecoder = textEncoding.TextDecoder;
        try {
          // Decode the buffer into a string
          let files_names = Object.keys(instance.files);
          for (let _index in files_names) {
            let _fileName = files_names[_index].toLowerCase().split(".");
            if (_fileName[_fileName.length - 1] === "kml") {
              // parse the xml document from the string
              let upload_data = new TextDecoder("utf-8").decode(instance.files[files_names[_index]]["buffer"]);
              dispatch(siftActions.importData(options, upload_data));
            }
          }
          // output.features = all_features
        } catch (error) {
          console.log("KMZ Import Error", error);
          // output.error = error;
        }
      }
    }

    // if (file_type == 'kml') {
    //   async function getStringFileOutput(file) {
    //     return new Promise((resolve, reject) => {
    //       var reader = new FileReader();

    //       reader.onload = (function (file) {
    //         // var fileName = file.name;
    //         return function (evt) {
    //           if (evt.target.readyState == FileReader.DONE) {
    //             // DONE == 2
    //             // output.name = file.name;
    //             let upload_data = evt.target.result;
    //             // output.xml = new XMLParser().parseFromString(evt.target.result)
    //             // output.xml = evt.target.result;

    //             dispatch(siftActions.importData(options, upload_data));
    //             resolve(output);
    //           }
    //         };
    //       })(file);
    //       reader.readAsBinaryString(file);
    //     });
    //   }
    //   // let resp = await getStringFileOutput(file)
    //   // return resp
    //   output = await getStringFileOutput(file);
    // } else if (file_type == 'kmz') {
    //   // If file is *.kmz we need to unzip the .kml to get the polygons
    //   var instance = await ZipLoader.unzip(file);
    //   // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
    //   var TextDecoder = textEncoding.TextDecoder;
    //   try {
    //     // Decode the buffer into a string
    //     var all_features = [];
    //     var files_names = Object.keys(instance.files);
    //     output.name = file.name;
    //     for (var file in files_names) {
    //       if (files_names[file].substr(-3, 3).toLowerCase() == 'kml') {
    //         var string = new TextDecoder('utf-8').decode(instance.files[files_names[file]]['buffer']);
    //         // parse the xml document from the string
    //         // output.xml = new XMLParser().parseFromString(string)
    //         // output.xml = string;
    //         let upload_data = string;
    //         dispatch(siftActions.importData(options, upload_data));

    //       }
    //     }
    //     // output.features = all_features
    //   } catch (error) {
    //     console.log(error);
    //     output.error = error;
    //   }
    // }

    // console.log(output);
    // dispatch(siftActions.parseXML(output));
  }

  return (
    <ProjectWrapper>
      <InputPanelWrapper
        toggleMobileInputs={toggleMobileInputs}
        isAuthed={is_authenticated}
        show_agreement_modal={show_agreement_modal}
        internal_sift={internal_sift}
        show_layout_editor={show_layout_editor}
      >
        <ProjectManager />
        {internal_sift && using_canopy ? <CanopyInputPanel /> : <InputPanel toggleMobileInputs={toggleMobileInputs} internal_sift={internal_sift} />}
      </InputPanelWrapper>
      <MapWrapper>
        {is_authenticated && current_plan === 1 && !agreement_visible && <Notification />}

        {is_authenticated && hideTutorialToken === "false" && <IntroModal />}

        {/* <AuthModal internal_sift={internal_sift} /> */}

        {/* {is_authenticated && hideTutorialToken === "false" && tutorialActive && !isComplete && tutorialPath !== 0 && !tutorialNavVisible && <TutorialNew />} */}

        {/* {(tutorialPath == 0 || tutorialPath == 1) && tutorial.content && !exampleRunning && tutorialNavVisible && <TutorialNav />} */}

        {internal_sift && show_sf_modal && <SalesForceCapture />}

        {show_layer_menu && <LayerToggleMenu />}
        {/* {refresh_visible && <RefreshTokenModal />} */}
        {/* <AgreementUpdate /> */}
        {/* <Agreement /> */}

        {/* Portal Salesforce capture */}

        {internal_sift && using_canopy && Object.values(results).length > 0 && <CanopyResults />}

        {(Object.values(results).length > 0 || error_messages.length > 0) && <OutputPanel />}

        <div className="map-container">
          {/* <UploadComponent type="kmz" > </UploadComponent> */}
          <Dragger
            style={{ height: "100%", border: "none" }}
            className="map-uploader"
            action={null}
            customRequest={null}
            beforeUpload={handleLoadFile}
            multiple={false}
            name="kml-file"
            accept=".kmz,.kml,image/*"
            openFileDialogOnClick={false}
            showUploadList={false}
          >
            {/* <SunfigMap onMarkerUpdated={handleMarkerUpdate} OnChangeTopoLayer={handleTopoUpdate}></SunfigMap> */}
            <Map />
          </Dragger>
          {loading_map && (
            <section className="map-loader">
              <Spin spinning={true} tip={loading_msg || "Map Loading..."}>
                <section className="content" />
              </Spin>
            </section>
          )}
        </div>
      </MapWrapper>
      {is_authenticated && <IntercomMessenger />}
      {is_authenticated && <WebSockets />}
      <ImportToolModal />

      <ErrorPopup />
    </ProjectWrapper>
  );
};
