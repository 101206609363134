import React, { useEffect, useState, Fragment, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, getTilts, isNumber, customParseFloat } from "../../Redux";

import { Radio, Tooltip, Table, Input, Spin, Popconfirm, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DynamicInputPopout } from "../DynamicInputPopout";

import { DimsReadOnlyTable } from "../StyledComponents";

import { RackingPanelWrapper } from "./";

//tutorial stuff
import { TutorialTip } from "../../TutorialTip";

const disabled_inputs = {
  wave: [
    "rack_dims",
    "modules_high",
    "landscape",
    "racking_dims",
    //,"module_gap", "gap", "Fshd_Celltrav", "Fshd_StringSteps"
  ],
  agile: [
    "rack_dims",
    "modules_high",
    "landscape",
    "rack_a",
    "rack_b",
    "rack_c",
    "racking_dims",
    // "mod_per_string",
    //, "module_gap","gap", "Fshd_StringSteps", "Fshd_Celltrav"
  ],
  fuse: [
    "rack_dims",
    "modules_high",
    "landscape",
    "racking_dims",
    //,"rack_grade_limit","module_gap","tilt_min", "tilt_max", "tilt_inc","gap","Fshd_StringSteps","Fshd_Celltrav",
  ],
  terraTrak_2P: [
    "rack_dims",
    "modules_high",
    "landscape",
    //"module_gap",
    // "drive_gap",
    "racking_dims",
    //"rack_grade_limit",
    //"gap",
    "backtracking_off",
    "irrad_on",
    // "track_angle",
    // "rlim",
    //"Fshd_StringSteps",
    //"Fshd_Celltrav",
  ],
  terraTrak_1P: [
    "rack_dims",
    "modules_high",
    "landscape",
    //"module_gap",
    //"drive_gap",
    // "racking_dims",
    //"rack_grade_limit",
    //"gap",
    // "backtracking_off",
    // "irrad_on",
    "track_angle",
    "rlim",
    //"Fshd_StringSteps",
    //"Fshd_Celltrav",
  ],
  default: [],
};

// helper functions
import { calculate_racking_dims, check_for_racking_errors } from "../utils";

const letters = ["A", "B", "C"];
const RadioGroup = Radio.Group;

const RackingOptions = [
  { label: "Ground Fixed TIlt", value: 0 },
  { label: "Single-Axis Tracker", value: 1 },
  { label: "East-West Fixed", value: 2 },
];
const ShadingOption = [
  { label: "Linear", value: 0 },
  { label: "Electrical", value: 1 },
];
const backtrackOption = [
  { label: "Off", value: 0 },
  { label: "On", value: 1 },
];

const RackingInputs = memo(() => {
  const dispatch = useDispatch();
  //
  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);
  const [suggested, set_suggested] = useState(26);
  const [linearValue, setLinearValue] = useState(1);

  const handleRadioChange = (event) => {
    setLinearValue(event.target.value);
  };
  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);

  const saving = account_loading || input_loading;
  const [rackPopupVisible, setRackPopupVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);
  const [shadeModeError, setShadeModeError] = useState(false);
  const [disable_inp, set_disabed_inp] = useState("default");
  const [new_racking_values, set_new_racking_values] = useState(local_inputs.racks);

  const [tilt_error, set_tilt_error] = useState(undefined);
  // const [tilts_total, set_tilts_total] = useState(inputs.tilts.length);

  // useEffect(() => {
  //   set_tilts_total(inputs.tilts.length);
  //   console.log("inputs.tilts.length", inputs.tilts.length);
  // }, [inputs.tilts]);

  // tutorial controls
  const tutorial = useSelector((state) => state.tutorial);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  const dropdown = useSelector((state) => state.user.presets["racking"]);
  const [local_dropdown, set_local_dropdown] = useState(dropdown);

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";

  useEffect(() => {
    set_local_dropdown(dropdown);
  }, [dropdown]);

  useEffect(() => {
    calc_suggested();
  }, []);

  useEffect(() => {
    // prettier-ignore
    calc_suggested()
    if (local_inputs.tilt_min < 0 || local_inputs.tilt_min > 60) {
      set_tilt_error("Invalid Tilt Min");
    } else if (local_inputs.tilt_max < 0 || local_inputs.tilt_max > 60) {
      set_tilt_error("Invalid Tilt Max");
    } else if (local_inputs.tilt_min > local_inputs.tilt_max) {
      set_tilt_error("Min must be <= Max");
    } else if (local_inputs.tilts.length > 20) {
      set_tilt_error("Total possible tilts within range > 20");
    } else if (tilt_error !== undefined) {
      set_tilt_error(undefined);
    }
  }, [local_inputs, local_inputs.design_temp]);

  const calc_suggested = () => {
    set_suggested(parseInt(Math.floor(local_inputs.inv_pd_vdcmax / (local_inputs.mlm_V_oc_ref - (local_inputs.mlm_beta_voc_spec / 1000) * (25 - parseFloat(local_inputs.design_temp))))));
  };
  const rackingIdToDisabledInput = {
    "Terrasmart GLIDE Wave": "wave",
    "Terrasmart GLIDE Agile": "agile",
    "Terrasmart GLIDE Fuse": "fuse",
    "Terrasmart TerraTrak 2P": "terraTrak_2P",
    "Terrasmart TerraTrak 1P": "terraTrak_1P",
  };

  useEffect(() => {
    console.log("local_inputs.racking_id", local_inputs.racking_id);
    set_disabed_inp(rackingIdToDisabledInput[local_inputs.racking_id] || "default");
  }, [local_inputs.racking_id]);

  const rack_float_vars = [
    "module_gap",
    "drive_gap",
    "string_count",
    "rack_grade_limit",
    "gap",
    "bi_groundClearanceHeight",
    "bi_transmissionFactor",
    "bi_structureShadeFactor",
    "rlim",
    "Fshd_Celltrav",
  ];
  const rack_int_vars = ["mod_per_string", "modules_high", "module", "Fshd_StringSteps"];

  const validateInputValue = (value) => {
    return (_.isString(value) && _.isEmpty(value)) || _.isUndefined(value) || _.isNaN(value);
  };
  const updateInput = (key, value) => {
    // console.log(key, value);
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // console.log(key, value);
    // do some input validation here
    let bulk_update;
    let fixed_value = value;
    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;

    if (key === "track_mode") {
      set_disabed_inp("default");
      if (((local_inputs.racking_id === "Terrasmart TerraTrak 2P" || local_inputs.racking_id === "Terrasmart TerraTrak 1P") && inputs.track_mode == 1) || (inputs.track_mode != 1 && value == 1)) {
        // switched TO SAT

        bulk_update = {
          [key]: fixed_value,
          racking_id: "Generic SAT",
          racks: [
            { key: 1, active: 1, xdim: 1.956, ydim: 88.303, module: 84, string_count: 3 },
            { key: 2, active: 1, xdim: 1.956, ydim: 59.41, module: 56, string_count: 2 },
            { key: 3, active: 0, xdim: 1.956, ydim: 30.516, module: 28, string_count: 1 },
          ],
          rack_dims: 0,
          mod_per_string: 26,
          gap: 2,
          tilts: [0],
          rlim: 60,
          en_backtrack: 1,
          en_irradtrack: 0,
          shade_mode: 1,
          modules_high: 1,
          orientation: 0,
          module_gap: 0.02,
          drive_gap: 2.0,
          rack_grade_limit: 10,
          bi_groundClearanceHeight: 1.5,
          bi_transmissionFactor: 0,
          bi_structureShadeFactor: 10,
          Fshd_StringSteps: 1,
          Fshd_Celltrav: 0.156,
          racking_name: "Generic SAT 26mod/str",
        };
      }

      if (
        ((local_inputs.racking_id === "Terrasmart Glide AGILE" || local_inputs.racking_id === "Terrasmart Glide WAVE" || local_inputs.racking_id === "Terrasmart Glide FUSE") &&
          inputs.track_mode === 0) ||
        (inputs.track_mode !== 0 && value === 0)
      ) {
        // switched TO GFT
        bulk_update = {
          [key]: fixed_value,
          racking_id: "Generic GFT",
          racks: [
            { key: 1, active: 1, xdim: 27.906, ydim: 3.917, module: 56, string_count: 2 },
            { key: 2, active: 1, xdim: 14.018, ydim: 3.917, module: 28, string_count: 1 },
            { key: 3, active: 0, xdim: 7.074, ydim: 3.917, module: 14, string_count: 0.5 },
          ],
          rack_dims: 0,
          mod_per_string: 26,
          gap: 0,
          tilt_min: 10,
          tilt_max: 30,
          tilt_inc: 5,
          tilts: [10, 15, 20, 25, 30],
          rlim: 0,
          en_backtrack: 0,
          en_irradtrack: 0,
          shade_mode: 1,
          modules_high: 2,
          orientation: 0,
          module_gap: 0.02,
          drive_gap: 0,
          rack_grade_limit: 20,
          bi_groundClearanceHeight: 0.5,
          bi_transmissionFactor: 0,
          bi_structureShadeFactor: 10,
          Fshd_StringSteps: 2,
          Fshd_Celltrav: 0.156,
          racking_name: "Generic GFT 2HP 26mod/str",
        };
      }

      if (inputs.track_mode !== 2 && value === 2) {
        // switched TO EWF
        bulk_update = {
          [key]: fixed_value,
          racking_id: "Generic EWF",
          racks: [
            { key: 1, active: 1, xdim: 1.96, ydim: 27.91, module: 56, string_count: 3 },
            { key: 2, active: 1, xdim: 1.96, ydim: 14.02, module: 28, string_count: 2 },
            { key: 3, active: 0, xdim: 1.96, ydim: 7.07, module: 14, string_count: 1 },
          ],
          rack_dims: 0,
          mod_per_string: 26,
          gap: 0,
          tilt_min: 8,
          tilt_max: 8,
          tilt_inc: 5,
          tilts: [8],
          rlim: 0,
          en_backtrack: 0,
          en_irradtrack: 0,
          shade_mode: 1,
          modules_high: 2,
          orientation: 0,
          module_gap: 0.02,
          drive_gap: 0,
          rack_grade_limit: 20,
          bi_groundClearanceHeight: 0.5,
          bi_transmissionFactor: 0,
          bi_structureShadeFactor: 10,
          Fshd_StringSteps: 1,
          Fshd_Celltrav: 0.156,
          racking_name: "Generic EWF 1HP 26mod/str",
        };
      }
    }

    if (key === "tilt_min" || key === "tilt_max" || key === "tilt_inc") {
      fixed_value = parseFloat(value);

      if (validateInputValue(value)) {
        set_tilt_error("Invalid Tilt Inputs");
        return;
      }

      // little bit odd, but by the time we get here in our code, local_inputs still had the old value and updated the tilts incorrectly.
      // fixed_value contains the new value, so in here we check to see which was has been updated and send the most recent value
      // in order to get the correct calculation.
      let updated_tilts = getTilts(
        parseFloat(key === "tilt_min" ? fixed_value : local_inputs.tilt_min),
        parseFloat(key === "tilt_max" ? fixed_value : local_inputs.tilt_max),
        parseFloat(key === "tilt_inc" ? fixed_value : local_inputs.tilt_inc)
      );

      bulk_update = { [key]: fixed_value, tilts: updated_tilts };

      // console.log("updated", updated_tilts);

      if (updated_tilts === false) {
        set_tilt_error("Invalid Tilt Inputs");
        return;
      }

      // else if (updated_tilts.length > 20) {
      //   set_tilt_error("Tilt count max is 20");
      //   return;
      // }
    }

    if (key === "en_bifacial" || key === "shade_mode") {
      if (local_inputs.en_bifacial === 1 && local_inputs.shade_mode === 0) {
        setShadeModeError(true);
        updateInput("shade_mode", 1);
      } else {
        setShadeModeError(false);
      }
    }

    if (rack_float_vars.includes(key)) {
      if (!validateInputValue(value)) {
        fixed_value = parseFloat(value);
      }
    }
    if (rack_int_vars.includes(key)) {
      if (!validateInputValue(value)) {
        fixed_value = parseInt(value);
      }
    }

    if (key === "design_temp" && !validateInputValue(value)) {
      fixed_value = parseInt(value);
    }

    if (bulk_update) {
      // console.log("bulk", bulk_update);
      dispatch(inputsActions.update_bulk_inputs(bulk_update));
    } else {
      // console.log("fixed:", key, fixed_value);
      dispatch(inputsActions.update_input(key, fixed_value));
    }
  };

  const updateRackData = useMemo(
    () => (index, key, value) => {
      let new_racks = local_inputs.racks.slice(0);
      new_racks[index][key] = value;

      set_local_inputs({
        ...local_inputs,
        racks: new_racks,
      });

      if (key === "string_count") {
        if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
          return;
        }

        new_racks[index][key] = parseFloat(value);
      }
      updateInput("racks", new_racks);

      //  set_new_racking_values(new_racks);

      // if (key == "active") {
      //   updateInput("racks", new_racks);
      // }
    },
    [local_inputs, updateInput]
  );

  const toggleRackPopup = useMemo(
    () => (e) => {
      // let cy = e.clientY;
      // if (disabled_inputs[disable_inp].indexOf("racking_dims") != -1) {
      //   return;
      // }
      let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      setPopoutY(Math.min(pageHeight - 361, pageHeight * 0.35));
      setRackPopupVisible(!rackPopupVisible);
    },
    [rackPopupVisible]
  );

  return (
    <RackingPanelWrapper>
      <DynamicInputPopout visible={rackPopupVisible} onClose={toggleRackPopup} autoRack={local_inputs.rack_dims} />
      <TutorialTip
        elementKey="dims-table"
        placement={screenWidth < 768 ? "top" : "right"}
        customTipWidth={screenWidth < 768 ? 120 : undefined}
        customTipHeight={screenWidth < 768 ? 100 : undefined}
        visible={tutorial.visibleTips.racking_tip}
        content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.racking_tip}
        highlight={true}
      >
        <div id="dims-table">
          <div className="input-2-col">
            <label>Rack Type</label>
            <Radio.Group buttonStyle="solid" size="small" value={local_inputs.track_mode} style={{ marginLeft: "auto" }}>
              <Tooltip placement="top" title="Single-Axis Tracker">
                <Popconfirm title="Load Generic Single Axis Tracker?" onConfirm={() => updateInput("track_mode", 1)} okText="OK" cancelText="Cancel">
                  <Radio.Button key="rack_type2" value={1}>
                    SAT
                  </Radio.Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title="Ground Fixed Tilt">
                <Popconfirm title="Load Generic Ground Fixed?" onConfirm={() => updateInput("track_mode", 0)} okText="OK" cancelText="Cancel">
                  <Radio.Button key="rack_type1" value={0}>
                    GFT
                  </Radio.Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip placement="top" title="East-West Fixed Tilt">
                <Popconfirm title="Load Generic East-West Fixed?" onConfirm={() => updateInput("track_mode", 2)} okText="OK" cancelText="Cancel">
                  <Radio.Button key="rack_type3" value={2}>
                    EWF
                  </Radio.Button>
                </Popconfirm>
              </Tooltip>
            </Radio.Group>
          </div>
        </div>
      </TutorialTip>
      <section className="input-2-col">
        <label>Modules Per String</label>
        <Input
          id="mod_per_string"
          size="small"
          value={local_inputs.mod_per_string}
          onChange={(e) => updateInput(e.target.id, e.target.value)}
          disabled={disabled_inputs[disable_inp].indexOf("mod_per_string") !== -1 ? true : undefined}
        />
      </section>
      <section className="input-2-col">
        <label>Design Temp</label>
        <Input
          id="design_temp"
          size="small"
          style={{ width: 120, marginLeft: "auto" }}
          type="number"
          min="-50"
          max="50"
          step="1"
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>C°</span>}
          value={local_inputs.design_temp}
          onChange={(e) => updateInput(e.target.id, e.target.value)}
          disabled={disabled_inputs[disable_inp].indexOf("design_temp") !== -1 ? true : undefined}
        />
      </section>
      <section className="info-col">{isNaN(suggested) ? <p></p> : <p>{`Suggested Modules Per String: ${suggested}`}</p>}</section>
      <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />
      <section className="input-2-col">
        <label>Rack Dimensions</label>
        <Radio.Group value={local_inputs.rack_dims} style={{ marginLeft: "auto" }} size="small">
          <Popconfirm title="Auto will overwrite all data entered in Manual." onConfirm={() => updateInput("rack_dims", 0)} disabled={local_inputs.rack_dims === 0} okText="Ok" cancelText="Cancel">
            <Radio id="rack_dims" key="auto" value={0}>
              Auto
            </Radio>
          </Popconfirm>
          <Radio id="rack_dims" key="manual" value={1} onClick={() => updateInput("rack_dims", 1)} disabled={disabled_inputs[disable_inp].indexOf("landscape") !== -1}>
            Manual
          </Radio>
        </Radio.Group>
      </section>
      {/* IF AUTO RACKING DIMS */}
      {local_inputs.rack_dims === 0 && (
        <Fragment>
          <section className="input-2-col">
            <label>Module Dimensions</label>
            <p>{`${local_inputs.mod_width}m x ${local_inputs.mod_height}m`}</p>
          </section>

          <section className="input-2-col">
            <label>Modules High</label>
            <Input
              id="modules_high"
              size="small"
              type="number"
              min="1"
              step="1"
              value={local_inputs.modules_high}
              onChange={(e) => updateInput(e.target.id, e.target.value)}
              disabled={disabled_inputs[disable_inp].indexOf("modules_high") !== -1 ? true : undefined}
              // onChange={(e) => onChangeModulesHigh(e.target.id, e.target.value.replace(/[^0-9]*/g, ""))}
            />
          </section>

          <section className="input-row">
            <label>Module Orientation</label>
            <Radio.Group value={local_inputs.orientation} style={{ display: "flex", flexDirection: "column" }} onChange={(e) => updateInput(e.target.id, e.target.value)}>
              <Radio id="orientation" key="portrait" value={0}>
                Portrait
              </Radio>
              <Radio id="orientation" key="landscape" value={1} disabled={disabled_inputs[disable_inp].indexOf("landscape") !== -1}>
                Landscape
              </Radio>
            </Radio.Group>
          </section>

          {/*<div className="info-message">*/}
          {/*  <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", marginRight: 5, marginTop: 5 }} />*/}
          {/*  <p>{local_inputs.track_mode == 2 ? `Rack A, B must be Integers. Rack C can be increments of 0.5.` : `Rack A, B, C must be Integers`}</p>*/}
          {/*</div>*/}

          <section className="input-header-right">
            <p>Strings per {local_inputs.track_mode === 2 ? "Face" : "Rack"}</p>
          </section>

          <section className="input-3-col">
            <label>Rack A</label>
            <section className="toggle">
              <Switch
                checked={local_inputs.racks[0].active === 1}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={() => updateRackData(0, "active", local_inputs.racks[0].active === 0 ? 1 : 0)}
                disabled={disabled_inputs[disable_inp].indexOf("rack_a") !== -1 ? true : undefined}
              />
            </section>
            <Input
              id="string_count"
              size="small"
              type="number"
              min="0"
              step="1.0"
              disabled={local_inputs.racks[0].active === 0 || (disabled_inputs[disable_inp].indexOf("rack_a") !== -1 ? true : undefined)}
              value={local_inputs.racks[0].string_count}
              onChange={(e) => updateRackData(0, e.target.id, e.target.value)}
              // onBlur={() => updateInput("racks", new_racking_values)}
              // onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 0)}
            />
          </section>
          <section className="input-3-col">
            <label>Rack B</label>
            <section className="toggle">
              <Switch
                checked={local_inputs.racks[1].active === 1}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={() => updateRackData(1, "active", local_inputs.racks[1].active === 0 ? 1 : 0)}
                disabled={disabled_inputs[disable_inp].indexOf("rack_b") !== -1 ? true : undefined}

                // onChange={() => onChangeActive(1)}
              />
            </section>
            <Input
              id="string_count"
              size="small"
              type="number"
              min="0"
              step="1.0"
              disabled={local_inputs.racks[1].active === 0 || (disabled_inputs[disable_inp].indexOf("rack_b") !== -1 ? true : undefined)}
              value={local_inputs.racks[1].string_count}
              onChange={(e) => updateRackData(1, e.target.id, e.target.value)}
              // onBlur={() => updateInput("racks", new_racking_values)}
              // onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 1)}
            />
          </section>
          <section className="input-3-col">
            <label>Rack C</label>
            <section className="toggle">
              <Switch
                checked={local_inputs.racks[2].active === 1}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={(e) => updateRackData(2, "active", local_inputs.racks[2].active === 0 ? 1 : 0)}
                disabled={disabled_inputs[disable_inp].indexOf("rack_c") !== -1 ? true : undefined}

                // onChange={() => onChangeActive(2)}
              />
            </section>
            <Input
              id="string_count"
              size="small"
              type="number"
              min="0"
              step={local_inputs.track_mode == 2 ? "0.5" : "1"}
              disabled={local_inputs.racks[2].active === 0 || (disabled_inputs[disable_inp].indexOf("rack_a") !== -1 ? true : undefined)}
              value={local_inputs.racks[2].string_count}
              onChange={(e) => updateRackData(2, e.target.id, e.target.value)}
              // onBlur={() => updateInput("racks", new_racking_values)}
              // onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 2)}
            />
          </section>

          {local_inputs.racking_id === "Terrasmart TerraTrak 2P" && local_inputs.track_mode === 1 ? (
            <></>
          ) : (
            <section className="input-2-col">
              <label>Mod Gap</label>
              <Input
                id="module_gap"
                style={{ maxWidth: 95, marginLeft: "auto" }}
                size="small"
                type="number"
                min="0"
                step="0.01"
                value={local_inputs.module_gap}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                onChange={(e) => updateInput(e.target.id, e.target.value)}
                disabled={disabled_inputs[disable_inp].indexOf("module_gap") !== -1 ? true : undefined}
              />
            </section>
          )}

          {local_inputs.track_mode === 1 && (
            <section className="input-2-col">
              <label>Drive Gap</label>
              <Input
                id="drive_gap"
                style={{ maxWidth: 95, marginLeft: "auto" }}
                size="small"
                type="number"
                step="0.01"
                min="0"
                value={local_inputs.drive_gap}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                onChange={(e) => updateInput(e.target.id, e.target.value)}
                disabled={disabled_inputs[disable_inp].indexOf("drive_gap") !== -1 ? true : undefined}

                // onChange={(e) => onChangeDriveGap(e.target.id, e.target.value)}
              />
            </section>
          )}
        </Fragment>
      )}
      {local_inputs.racking_errors && (
        <section className="racking_error_warning">
          <a onClick={toggleRackPopup}>
            <p>
              ** <u>Racking dimension warning!</u> **
            </p>
            <p>
              * <u>Click for more details.</u> *
            </p>
          </a>
        </section>
      )}
      <div>
        <div className="">
          <label>Racking Dimensions {local_inputs.track_mode === 2 && "per Face"}</label>
        </div>

        <DimsReadOnlyTable>
          <div className="read-only-table" onClick={toggleRackPopup}>
            <table>
              <tbody>
                <tr>
                  <td></td>
                  <td>Active</td>
                  <td>X(m)</td>
                  <td>Y(m)</td>
                  <td>Mods</td>
                </tr>
                {local_inputs.racks.map((rack, index) => {
                  return (
                    <tr key={rack.key}>
                      <td>{letters[index]}</td>
                      <td>{rack.active === 1 ? "On" : "Off"}</td>
                      <td>{rack.active === 1 && _.round(rack.xdim, 2)}</td>
                      <td>{rack.active === 1 && _.round(rack.ydim, 2)}</td>
                      <td>{rack.active === 1 && rack.module}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <a>
              <p>
                <u>{local_inputs.rack_dims === 0 ? "** Click table to view dimensions **" : "** Click table to edit dimensions **"}</u>
              </p>
            </a>
          </div>
        </DimsReadOnlyTable>
      </div>
      <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />
      <div className="input-row">
        <label>Rack Grade Limit</label>
        <Input
          type="number"
          id="rack_grade_limit"
          size="small"
          disabled={saving || (disabled_inputs[disable_inp].indexOf("rack_grade_limit") !== -1 ? true : undefined)}
          value={local_inputs.rack_grade_limit}
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>
      <div className="input-row">
        <label>Rack to Rack Gap</label>
        <Input
          type="number"
          id="gap"
          size="small"
          disabled={saving || (disabled_inputs[disable_inp].indexOf("gap") !== -1 ? true : undefined)}
          value={local_inputs.gap}
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
        />
      </div>
      {internal_sift && local_inputs.track_mode === 2 && (
        <section className="input-row">
          <label>Display Continuous</label>
          <Switch
            size="small"
            id="do_continuous"
            checked={local_inputs.do_continuous === 1}
            onChange={(checked) => {
              updateInput("do_continuous", checked ? 1 : 0);
            }}
            disabled={saving}
          />
        </section>
      )}
      <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />
      <div className="input-row">
        <label>Height Above Ground</label>
        <Input
          type="number"
          id="bi_groundClearanceHeight"
          size="small"
          disabled={saving}
          value={local_inputs.bi_groundClearanceHeight}
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
          disable={disabled_inputs[disable_inp].indexOf("bi_groundClearanceHeight") !== -1 ? true : undefined}
        />
      </div>
      <div className="input-row">
        <label>Transparent Fraction</label>
        <Input
          type="number"
          id="bi_transmissionFactor"
          size="small"
          disabled={saving || (disabled_inputs[disable_inp].indexOf("bi_transmissionFactor") !== -1 ? true : undefined)}
          value={local_inputs.bi_transmissionFactor}
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>
      <div className="input-row">
        <label>Structure Shade Factor</label>
        <Input
          type="number"
          id="bi_structureShadeFactor"
          size="small"
          disabled={saving || (disabled_inputs[disable_inp].indexOf("bi_structureShadeFactor") !== -1 ? true : undefined)}
          value={local_inputs.bi_structureShadeFactor}
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>
      {local_inputs.track_mode === 1 && (
        // SAT INPUTS
        <>
          <div className="input-row">
            <label>Backtracking</label>
            {/*<Radio.Group*/}
            {/*  options={backtrackOption}*/}
            {/*  name="en_backtrack"*/}
            {/*  style={{ marginRight: "auto" }}*/}
            {/*  value={local_inputs.en_backtrack}*/}
            {/*  onChange={(event) => {*/}
            {/*    if (event.target.value == 0) {*/}
            {/*      updateInput("en_irradtrack", 0);*/}
            {/*    }*/}
            {/*    updateInput(event.target.name, event.target.value);*/}
            {/*  }}*/}
            {/*/>*/}
            <Switch
              checked={local_inputs.en_backtrack}
              style={{ marginRight: "auto" }}
              checkedChildren="On"
              unCheckedChildren="Off"
              id="en_backtrack"
              name="en_backtrack"
              onChange={(checked) => {
                if (!checked) {
                  updateInput("en_irradtrack", 0);
                }
                updateInput("en_backtrack", checked ? 1 : 0);
              }}
              disabled={disabled_inputs[disable_inp].indexOf("rack_c") !== -1 ? true : undefined}

              // onChange={() => onChangeActive(2)}
            />
          </div>

          <div className="input-row">
            <label>Irrad. Optimized Tracking</label>
            <Tooltip placement="top" title={local_inputs.en_backtrack === 0 ? "Backtracking must be on to utilize Irrad. Optimized Tracking" : ""}>
              {/*<Radio.Group*/}
              {/*  options={backtrackOption}*/}
              {/*  disabled={local_inputs.en_backtrack === 0}*/}
              {/*  name="en_irradtrack"*/}
              {/*  style={{ marginRight: "auto" }}*/}
              {/*  value={local_inputs.en_irradtrack}*/}
              {/*  onChange={(event) => {*/}
              {/*    updateInput(event.target.name, event.target.value);*/}
              {/*  }}*/}
              {/*/>*/}
              <Switch
                checked={local_inputs.en_irradtrack}
                style={{ marginRight: "auto" }}
                checkedChildren="On"
                unCheckedChildren="Off"
                id="en_irradtrack"
                name="en_irradtrack"
                onChange={(checked) => {
                  updateInput("en_irradtrack", checked ? 1 : 0);
                }}
                disabled={local_inputs.en_backtrack === 0}

                // onChange={() => onChangeActive(2)}
              />
            </Tooltip>
          </div>

          <div className="input-row">
            <label>Tracking Angle</label>
            <Input
              type="number"
              id="rlim"
              size="small"
              value={local_inputs.rlim}
              disabled={saving || (disabled_inputs[disable_inp].indexOf("rlim") !== -1 ? true : undefined)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
            />
          </div>
        </>
      )}
      {local_inputs.track_mode === 2 && (
        // EWF INPUT
        <div className="input-row">
          <label>Tilt Angle</label>
          <Input
            id="tilt_min"
            size="small"
            disabled={saving || (disabled_inputs[disable_inp].indexOf("tilt_min") !== -1 ? true : undefined)}
            value={local_inputs.tilt_min}
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
          />
        </div>
      )}
      {local_inputs.track_mode === 0 && (
        <>
          <div className="tilt-input-label">
            <label>Tilts</label>
          </div>
          <div className="config-inputs" id="config-tip">
            <div className="config-err" style={{ height: 19 }}>
              {tilt_error && (
                <>
                  <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "12px" }} />
                  <p>{tilt_error}</p>
                </>
              )}
            </div>
            <div className="config-input" style={{ marginBottom: 14 }}>
              <div className="min">
                <label>Min</label>
                <Input
                  style={{ width: "75px" }}
                  id="tilt_min"
                  size="small"
                  type="number"
                  step={local_inputs.tilt_inc}
                  min={0}
                  max={60}
                  value={local_inputs.tilt_min}
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  disabled={disabled_inputs[disable_inp].indexOf("tilt_min") !== -1 ? true : undefined}
                />
              </div>
              <div className="dash">
                <span>-</span>
              </div>
              <div className="max">
                <label>Max</label>
                <Input
                  style={{ width: "75px" }}
                  id="tilt_max"
                  size="small"
                  type="number"
                  step={local_inputs.tilt_inc}
                  min={0}
                  max={local_inputs.tilt_max_value || 60}
                  value={local_inputs.tilt_max}
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  disabled={disabled_inputs[disable_inp].indexOf("tilt_max") !== -1 ? true : undefined}
                />
              </div>
              <div className="comma">
                <span>,</span>
              </div>
              <div className="increment">
                <label>Step</label>
                <Input
                  style={{ width: "60px" }}
                  id="tilt_inc"
                  size="small"
                  type="number"
                  step={1}
                  min={1}
                  max={60}
                  value={local_inputs.tilt_inc}
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  disabled={disabled_inputs[disable_inp].indexOf("tilt_inc") !== -1 ? true : undefined}
                />
              </div>
              <Tooltip placement="top" title={"Tilts: [" + Object.values(local_inputs.tilts).map((tilt) => tilt.toString()) + "]"}>
                <div className="total">
                  <p>
                    <b>
                      <u>{local_inputs.tilts.length}</u>
                    </b>
                  </p>
                  <label>Total</label>
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      )}
      <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />
      {/* {	(local_inputs.track_mode != 1
				|| (local_inputs.track_mode == 1 && local_inputs.en_backtrack==0)) && (
				// Shade mode always enabled for GFT / EWF
				// Shade mode disabled for SAT if Backtrack is off 
				After looking at the new performance code, it appears shade mode is always available
				And the below inputs (Fshd_CollWidth, Fshd_StringSteps, Fshd_Celltrav) are always available
				*/}
      <div className="input-row">
        <label>Shade Mode</label>
        <Radio.Group
          style={{ display: "flex", flexDirection: "column" }}
          name="shade_mode"
          value={local_inputs.shade_mode}
          onChange={(event) => {
            updateInput(event.target.name, event.target.value);
          }}
        >
          {/* REMOVING PER TASK {local_inputs.en_bifacial === 1 ? (
            <Tooltip placement="top" title={`Linear Shade mode is disabled when using a Bifacial Module. To enable toggle off 'Bifacial' in the Module menu.`}>
              <Radio name="Linear" value={2} disabled={true} onChange={handleRadioChange}>
                Linear
              </Radio>
            </Tooltip>
          ) : (
							)} */}
          <Radio name="Linear" value={2} disabled={disabled_inputs[disable_inp].indexOf("linear") !== -1} onChange={handleRadioChange}>
            Linear
          </Radio>
          <Radio name="Electrical" value={1} onChange={handleRadioChange}>
            Electrical
          </Radio>
        </Radio.Group>
      </div>
      {/* )} */}
      {/* {	((local_inputs.shade_mode == 1 && local_inputs.track_mode != 1)
				|| (local_inputs.shade_mode == 1 && local_inputs.track_mode == 1 && local_inputs.en_backtrack==0)) && ( */}
      <div>
        <div className="input-row">
          <label>Collector Width</label>
          <span>{local_inputs.track_mode === 0 ? local_inputs.racks[0].ydim : local_inputs.racks[0].xdim} m</span>
          {/* <span>{local_inputs.Fshd_CollWidth} m</span> */}
        </div>

        {linearValue !== 2 && (
          <div className="input-row">
            <label>String Steps</label>
            <Input
              id="Fshd_StringSteps"
              size="small"
              type="number"
              disabled={saving || (disabled_inputs[disable_inp].indexOf("Fshd_StringSteps") !== -1 ? true : undefined)}
              value={local_inputs.Fshd_StringSteps}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
            />
          </div>
        )}

        {linearValue !== 2 && (
          <div className="input-row">
            <label>Cell Transverse</label>
            <Input
              type="number"
              id="Fshd_Celltrav"
              size="small"
              disabled={saving || (disabled_inputs[disable_inp].indexOf("Fshd_Celltrav") !== -1 ? true : undefined)}
              value={local_inputs.Fshd_Celltrav}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
        )}
      </div>
      {/* )} */}
    </RackingPanelWrapper>
  );
});

export { RackingInputs };
