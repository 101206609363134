import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "config";
import { Form, Spin, Input, Button, Result } from "antd";
const FormItem = Form.Item;
import { LockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Base } from "../../layouts";
import { LoginErrWrap } from "./styles";

import { usePrevious } from "../../components";

const Reset_error = ({ errors }) => {
  return (
    <LoginErrWrap>
      <ol>
        {errors?.map((error, index) => {
          return (
            <li key={index}>
              <InfoCircleOutlined style={{ marginRight: 5 }} />
              {error}
            </li>
          );
        })}
      </ol>
    </LoginErrWrap>
  );
};

const PasswordReset_New = () => {
  let { code } = useParams();

  let [submitted, set_submitted] = useState(false);
  let [reset, set_reset] = useState(false);
  let [confirmDirty, set_confirmDirty] = useState(false);
  let [error, set_error] = useState([]);

  const [comparePassError, set_comparePassError] = useState(false);
  const [passCriteria, set_passCriteria] = useState(false);

	
  let [user, set_user] = useState({
		code: code,
    password1: "",
    password2: "",
  });
	// const prev_user = usePrevious(user);

  const handleChange = (event) => {
    const { name, value } = event.target;
    set_user({
      ...user,
      [name]: value,
    });
  };

  const handleConfirmBlur = (e) => {
    const value = e.target.value;
    set_confirmDirty(confirmDirty || !!value);
  };

  const handleSubmit = (values) => {
    set_submitted(true);
    set_error(undefined);

    // return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: values.password1,
        token: user.code,
      }),
    };
    fetch(`${config.apiUrl}/api/password_reset/confirm/`, requestOptions).then(handleResponse);
  };

  useEffect(() => {
    setTimeout(() => {
      if (user.password1 && user.password2) {
        setTimeout(() => {
          comparePassword();
        }, 1000);
      }

      // if (prev_user?.password1.length > 0 && prev_user_form?.password1.length < 8) {
      //   set_passCriteria(true);
      // } else {
      //   set_passCriteria(false);
      // }
    }, 1000);
  }, [user.password1, user.password2]);

  const compareToFirstPassword = (rule, value, callback) => {
    // const form = this.props.form;
    // if (value && value !== form.getFieldValue("password1")) {
    //   callback("Passwords do not match.");
    // } else {
    //   callback();
    // }
  };

  const validateToNextPassword = (rule, value, callback) => {
    // const form = this.props.form;
    // if (value && this.state.confirmDirty) {
    //   form.validateFields(["password2"], { force: true });
    // }
    // callback();
  };

  const comparePassword = () => {
    let pw_valid = user.password1 === user.password2;
    if (!pw_valid) {
      set_comparePassError(true);
      let match_err = [`Passwords do not match.`];
      set_error(match_err);
    } else if (pw_valid) {
      set_comparePassError(false);
      set_error(undefined);
    }
  };

  const handleResponse = (response) => {
    return response.text().then((text) => {
      let data = text && JSON.parse(text);
      if (!response.ok) {
        const error = ["Password Reset Token Expired."];
        set_submitted(false);
        set_error(data.password || error);

        return Promise.reject(error);
      } else {
        set_submitted(false);
        set_error(undefined);
        set_reset(true);
      }
      return data;
    });
  };

  return (
    <Base>
      <div className="password-reset-wrapper">
        <div className="password-reset">
          <Spin spinning={submitted}>
            {reset ? (
              <Result className="sift-result" status="success" title="Successfully Reset Password!" subTitle="You can now login with your new password." />
            ) : (
              <Form onFinish={handleSubmit} className="login-form">
                <span>
                  <h3>Reset SIFT Password</h3>
                </span>
                <p style={{ textAlign: "left" }}>Password requirements: 8 character minimum, at least 1 non-numeric, no common passwords.</p>
                <FormItem name="password1" className="input" rules={[{ required: true, message: "Please input your password!" }]}>
                  <Input name="password1" onChange={handleChange} className="inner-input" prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Password" />
                </FormItem>
                <div style={{ height: 6 }}></div>
                <FormItem name="password2" className="input" rules={[{ required: true, message: "Please confirm your password!" }]}>
                  <Input
                    name="password2"
                    onChange={handleChange}
                    onBlur={handleConfirmBlur}
                    className="inner-input"
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    placeholder="Confirm Password"
                  />
                </FormItem>

                <Reset_error errors={error} />

                <FormItem>
                  <Button type="primary" htmlType="submit" loading={submitted} className="login-form-button">
                    Reset Password
                  </Button>
                </FormItem>
              </Form>
            )}
          </Spin>
        </div>
      </div>
    </Base>
  );
};

export { PasswordReset_New };
