import React, { useState, useEffect } from "react";
import ReactDataSheet from "react-datasheet";

// antd
import { Input, Switch, Tooltip } from "antd";

//components
import { TitleDivider } from "../../TitleDivider";

import { SheetRenderer } from "../SheetRenderer";
import { InputPopout } from "../InputPopout";

// redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../Redux";
import _ from "lodash";

const op_columns = [
  { label: "", width: "10%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
];

const renderSheet = (props) => {
  return <SheetRenderer columns={props.cols} {...props} />;
};

const FinanceOperatingCosts = ({ expand, opShedVisible, popoutY, toggleShedPopup }) => {
  const dispatch = useDispatch();

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);

  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);
  useEffect(() => {
    set_local_inputs(inputs);
    createCustomSchedTable(inputs);
  }, [inputs]);

  const do_finance = useSelector((state) => state.inputs.do_finance);
  const analysis_period = useSelector((state) => state.inputs.analysis_period);
  const discount_rate = useSelector((state) => state.inputs.discount_rate);
  const do_custom_schedule = useSelector((state) => state.inputs.do_custom_schedule);
  const customSchedule = useSelector((state) => state.inputs.customSchedule);
  const module_dc_cost = useSelector((state) => state.inputs.module_dc_cost);
  const dc_op_cost = useSelector((state) => state.inputs.dc_op_cost);
  const ac_op_cost = useSelector((state) => state.inputs.ac_op_cost);
  const footprint_op_cost = useSelector((state) => state.inputs.footprint_op_cost);
  const fixed_op_cost = useSelector((state) => state.inputs.fixed_op_cost);
  const lease_cost = useSelector((state) => state.inputs.lease_cost);
  const escalation = useSelector((state) => state.inputs.escalation);
  const ac_cost = useSelector((state) => state.inputs.ac_cost);
  const fixed_cost = useSelector((state) => state.inputs.fixed_cost);

  const [toggle_operating_costs, set_toggle_operating_costs] = useState(false);

  useEffect(() => {
    if (expand) {
      set_toggle_operating_costs(true);
    } else {
      set_toggle_operating_costs(false);
    }
  }, [expand]);

  //  poput toggle stuff
  // const [opShedVisible, setOpShedVisible] = useState(false);
  // const [popoutY, setPopoutY] = useState(0);

  // useEffect(() => {
  //   if (expand) {
  //     set_toggle_operating_costs(true);
  //   } else {
  //     set_toggle_operating_costs(false);
  //   }
  // }, [expand]);

  // useEffect(() => {
  //   if (do_custom_schedule === 1 && !opShedVisible) {
  //     toggleShedPopup(null, true);
  //   } else if (do_custom_schedule === 0 && opShedVisible) {
  //     toggleShedPopup(null, false);
  //   }
  // }, [do_custom_schedule]);

  // useEffect(() => {
  //   if (do_finance == 0) {
  //     setOpShedVisible(false);
  //     updateInput("do_custom_schedule", 0);
  //     set_toggle_operating_costs(false);
  //   }
  // }, [do_finance]);

  // const checkInputs = () => {
  //   let check = [
  //     isNaN(analysis_period),
  //     isNaN(discount_rate),
  //     // isNaN(module_dc_cost.value),
  //     isNaN(dc_op_cost),
  //     isNaN(ac_op_cost),
  //     isNaN(fixed_cost),
  //     isNaN(dc_op_cost),
  //     isNaN(ac_op_cost),
  //     isNaN(fixed_op_cost),
  //     isNaN(escalation),
  //     // ...isNan(...finance.customSchedule),
  //   ];

  //   return check.every((value) => {
  //     return value == false;
  //   });
  // };

  // const toggleShedPopup = (e = undefined, showShedTable = undefined) => {
  //   let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  //   setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
  //   setOpShedVisible(showShedTable || !opShedVisible);
  // };

  // const closePopups = () => {
  //   setTouVisible(false);
  //   setOpShedVisible(false);
  //   setRevShedVisible(false);
  // };

  // let schedule = [];
  let inputError = "";
  let dirtyInputs = false;
  const [custom_schedule_dt, set_custom_schedule_dt] = useState([]);

  const createCustomSchedTable = (inputs, calculate = false) => {
    // let valid_inputs = checkInputs();
    let valid_inputs = true;
    let custom_schedule = [];
    if (inputs.do_custom_schedule == 1 && valid_inputs) {
      var data = [
        [
          { value: "Year", readOnly: true },
          { value: "$/kWp", readOnly: true },
          { value: "$/kWac", readOnly: true },
          { value: "$/ha", readOnly: true },
          { value: "$", readOnly: true },
        ],
      ];

      if (inputs.customSchedule != undefined && inputs.customSchedule.length > 0 && !calculate && inputs.customSchedule.length == analysis_period) {
        // load from provided schedule
        for (var i = 0; i < inputs.analysis_period; i++) {
          if (inputs.customSchedule[i].length == 4) {
            // insert item for new $/ha field
            inputs.customSchedule[i].splice(3, 0, 0.0);
          }
          let year = i + 1;
          let col_$Wp = inputs.customSchedule[i][1];
          let col_$Wac = inputs.customSchedule[i][2];
          let col_$ha = inputs.customSchedule[i][3];
          let col_$fix = inputs.customSchedule[i][4];

          data.push([
            { readOnly: true, value: year },
            { readOnly: false, value: col_$Wp },
            { readOnly: false, value: col_$Wac },
            { readOnly: false, value: col_$ha },
            { readOnly: false, value: col_$fix },
          ]);
        }
      } else {
        // build the excel chart off current input values
        let base_$Wp = inputs.dc_op_cost;
        let base_$Wac = inputs.ac_op_cost;
        let base_$ha = inputs.footprint_op_cost;
        let base_$fix = Number(inputs.fixed_op_cost) + Number(inputs.lease_cost);
        for (var i = 0; i < inputs.analysis_period; i++) {
          let year = i + 1;
          let col_$Wp = (base_$Wp * Math.pow(1 + inputs.escalation / 100, year - 1)).toFixed(3);
          let col_$Wac = (base_$Wac * Math.pow(1 + inputs.escalation / 100, year - 1)).toFixed(3);
          let col_$ha = (base_$ha * Math.pow(1 + inputs.escalation / 100, year - 1)).toFixed(3);
          let col_$fix = (base_$fix * Math.pow(1 + inputs.escalation / 100, year - 1)).toFixed(1);

          // Year N = Year 1 * (1 + %/100)^N
          // %/100 = 0.02 for 2%
          custom_schedule.push([year, parseFloat(col_$Wp), parseFloat(col_$Wac), parseFloat(col_$ha), parseFloat(col_$fix)]);
          data.push([
            { readOnly: true, value: year },
            { readOnly: false, value: col_$Wp },
            { readOnly: false, value: col_$Wac },
            { readOnly: false, value: col_$ha },
            { readOnly: false, value: col_$fix },
          ]);
        }

        updateInput("customSchedule", custom_schedule);
      }

      // schedule = data;
      set_custom_schedule_dt(data);
      dirtyInputs = false;
      inputError = undefined;
    }

    if (!valid_inputs) {
      inputError = "Error: Input not a number.";
    }
  };

  const handleChanges = (changes) => {
    const data = custom_schedule_dt;
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        if(value && value !== ""){
          let fixed_value = value.replace("$", "").replace("%", "").replace(",", "");
          data[row][col] = { ...data[row][col], value: fixed_value };
        }
      }
    });
    // console.log(data)
    let newScheduleData = data.slice(1, data.length);
    var Schedule = [];
    let year = 1;
    newScheduleData.forEach((row) => {
      let col_$Wp = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      let col_$Wac = parseFloat(row[2].value) == row[2].value ? parseFloat(row[2].value) : 0;
      let col_$ha = parseFloat(row[3].value) == row[3].value ? parseFloat(row[3].value) : 0;
      let col_$fix = parseFloat(row[4].value) == row[4].value ? parseFloat(row[4].value) : 0;
      Schedule.push([year, col_$Wp, col_$Wac, col_$ha, col_$fix]);
      year++;
    });
    updateInput("customSchedule", Schedule);
  };

  const updateInput = (key, value) => {
    // console.log("operating costs:", key, value);
    dispatch(inputsActions.update_input(key, value));
  };

  // handleLoadCustomSchedule();

  return (
    <>
      <TitleDivider title="Operating Costs" collapseable={true} toggle_var={toggle_operating_costs} toggle_fn={set_toggle_operating_costs} />

      {toggle_operating_costs && (
        <>
          <section className="input-row-two-col">
            <label>DC</label>
            <Input
              id="dc_op_cost"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={dc_op_cost}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/kWp</span>}
              onBlur={(e) => dc_op_cost == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="input-row-two-col">
            <label>AC</label>
            <Input
              id="ac_op_cost"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={ac_op_cost}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/kWac</span>}
              onBlur={(e) => ac_op_cost == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="input-row-two-col">
            <label>Rack Footprint</label>
            <Input
              id="footprint_op_cost"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={footprint_op_cost}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/ha</span>}
              onBlur={(e) => footprint_op_cost == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="input-row-two-col">
            <label>Fixed Op Costs</label>
            <Input
              id="fixed_op_cost"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={fixed_op_cost}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
              onBlur={(e) => fixed_op_cost == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="input-row-two-col">
            <label>Lease Costs</label>
            <Input
              id="lease_cost"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={lease_cost}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$</span>}
              onBlur={(e) => lease_cost == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <section className="input-row-two-col">
            <label>Escalation</label>
            <Input
              id="escalation"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              type="number"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={escalation}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              onBlur={(e) => escalation == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>
          <Tooltip
            placement="top"
            title={
              analysis_period == 0 || analysis_period < 0 || analysis_period == "" || analysis_period == undefined
                ? "Please enter a positive non-zero integer into the Analysis Period field to enable Custom Schedule"
                : ""
            }
            defaultOpen={analysis_period == 0 || analysis_period < 0 || analysis_period == "" || analysis_period == undefined}
            mouseEnterDelay={0.25}
          >
            <section className="custom-schedule-row">
              <label>Custom Schedule</label>
              <Switch
                size="small"
                disabled={analysis_period == undefined || analysis_period == 0 || analysis_period < 0 || analysis_period == ""}
                id="do_custom_schedule"
                checked={do_custom_schedule == 1}
                onChange={(b, e) => {
                  updateInput("do_custom_schedule", b ? 1 : 0);
                }}
              />
              {do_custom_schedule == 1 ? <a onClick={toggleShedPopup}>{opShedVisible ? "Close Schedule" : "Open Schedule "}</a> : <p></p>}
            </section>
          </Tooltip>
          <InputPopout visible={opShedVisible} onClose={toggleShedPopup} popoutY={popoutY}>
            <section className="tou-container">
              <label className="custom-schedule-label">Operating Cost Custom Schedule</label>
              <a
                className="custom-schedule-link"
                onClick={() => {
                  createCustomSchedTable(inputs, true);
                }}
              >
                Overwrite based on Inputs(Left)
              </a>
              <section className="custom-schedule-table">
                <ReactDataSheet
                  data={custom_schedule_dt}
                  sheetRenderer={(props) => renderSheet({ ...props, cols: op_columns })}
                  valueRenderer={(cell) => cell.value}
                  onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                  onCellsChanged={handleChanges}
                />
              </section>
            </section>
          </InputPopout>
        </>
      )}
    </>
  );
};

export { FinanceOperatingCosts };
