import React, { useState, useEffect } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { useSelector } from "react-redux";
import config from "config";
import { Modal, Button } from "../BaseComponents";


const VersionCheck = () => {
  const [is_open, set_is_open] = useState(false);
  const { is_authenticated } = useAuth();
  const version = useSelector((state) => state.user.version);

  useEffect(() => {
    if (is_authenticated) {
      // console.log("VersionCheck", config.version, version);
      if (config.version != version) {
        set_is_open(true);
      }
    }
  }, [is_authenticated, version]);
  // useEffect(() => { }, [version]);
  useEffect(() => {
    if (is_authenticated) {
      // console.log("initial VersionCheck", config.version,version);
      if (config.version != version) {
        set_is_open(true);
      }
    }
  }, []);

  return (
    <Modal
      className="sift-modal"
      closable={true}
      maskClosable={true}
      width="15%"
      open={is_open}
      onCancel={() => {
        set_is_open(false);
      }}
      footer={
        <section style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <Button label="Close" size="small" type="ghost" onClick={() => set_is_open(false)} />
          <Button label="Refresh" size="small" onClick={() => window.location.reload()} />
        </section>
      }
    >
      <div className="modal-body">
        <p>You are using an old version of the SIFT application. Please refresh your browser to get the latest version.</p>
      </div>
    </Modal>
  );
};


export default VersionCheck;