import React, { useState, useEffect } from "react";
import { LayerGroup, Polygon, GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";
import { LayoutLayer, EditedLayer } from "./";

const MapLayoutLayer = ({ show_roads = true, show_racks = true, show_inverters = true, liveReportMap = false }) => {
  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);
  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);
  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);

  if (!layout_visible) return <></>;

  return (
    <>
      {liveReportMap ? (
        <>
          {roads && show_roads && (
            <LayerGroup key="roads">
              <Polygon positions={roads?.geometry?.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />
            </LayerGroup>
          )}
          {selectedResultId && edited_layouts && edited_layouts[selectedResultId] ? (
            <EditedLayer show_inverters={show_inverters} show_racks={show_racks} />
          ) : (
            <LayoutLayer show_inverters={show_inverters} show_racks={show_racks} />
          )}
        </>
      ) : (
        <>
          {roads && (
            <LayerGroup key="roads">
              <Polygon positions={roads?.geometry?.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />
            </LayerGroup>
          )}
          {selectedResultId && edited_layouts && edited_layouts[selectedResultId] ? <EditedLayer /> : <LayoutLayer />};
        </>
      )}
    </>
  );
};

export { MapLayoutLayer };
