import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";

import { canopyActions, usePrevious } from "../../Redux";
import { useKey } from "../../Map/components/MapEventHandler/useKey";
import { build_shade_object, getCorners } from "../ShadeHelpers/Shade.editor";

// import "../../Map/leaflet.draw.css";

const DrawTree = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const drawing_tree = useSelector((state) => state.canopy.drawing_tree);
  const prev_drawing_tree = usePrevious(drawing_tree);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const drawing_tree_ref = useRef();

  const cancel_key = useKey("escape");

  useEffect(() => {
    drawing_tree_ref.current = drawing_tree;
    if (cancel_key && drawing_tree) {
      cancelDraw();
    }
  }, [cancel_key, drawing_tree]);

  useEffect(() => {
    if (!prev_drawing_tree && drawing_tree) {
      // drawTree();
      document.getElementById("map").style.cursor = "crosshair";
    } else if (prev_drawing_tree && !drawing_tree) {
      document.getElementById("map").style.cursor = "grab";
      cancelDraw();
    }
  }, [drawing_tree]);

  useEffect(() => {
    if (!map) return;
    // map.on("pm:create", (e) => {
    //   console.log("e", e);
    //   if (e.shape == "Circle") {
    //     let geoJson = e.layer.toGeoJSON();
    //     let corners = getCorners(e.layer);
    //     // let radius = e.layer.getRadius();
    //     let radius = 10;
    //     let shade_object = build_shade_object(geoJson, corners, e.shape);
    //     shade_object.radius = radius;
    //     dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
    //     e.layer.remove();
    //   }
    // });

    map.on("click", (e) => {
      if (drawing_tree_ref.current) {
        let lat_lng = [e.latlng.lat, e.latlng.lng];
        let the_tree = new L.Circle(lat_lng, { radius: 10 });
        let tree_layer = the_tree.addTo(map);
        let tree_geoJson = tree_layer.toGeoJSON();
        let center = tree_layer.getBounds().getCenter();
        let corners = getCorners(tree_layer);
        let new_tree = build_shade_object(tree_geoJson, corners, center, shade_height_unit, "Circle");
        dispatch(canopyActions.updateShadeObject("add_shade_object", new_tree));
        tree_layer.remove();
      }
    });

    return () => {
      // map.off("pm:create");
      map.off("click");
    };
  }, [shade_height_unit]);

  const drawTree = () => {
    let options = {
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 1,
      fillColor: "var(--primary-brand-color)",
    };

    map.pm.enableDraw("Circle", {
      snappable: true,
      snapDistance: 20,
      templineStyle: options,
      pathOptions: options,
      tooltips: false,
    });

    // if (drawing_tree) {
    //   cancelDraw();
    // } else {
    //   dispatch(canopyActions.updateCanopyInputs("drawing_tree", true));
    // }
  };

  const cancelDraw = () => {
    map.pm.disableDraw();
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree", false));
  };

  return null;
};

export { DrawTree };
