import React, { useState, useEffect } from "react";
import { Modal, Carousel, Button, Checkbox } from "../BaseComponents";

import { QuickLookVideo } from "../HelpDrawer/DrawerContent";
import { help_intro, bug_intro } from "../../assets/images";

const carousel_items = [
  {
    ele: <QuickLookVideo />,
    text: "Getting Started",
    type: "iframe",
  },
  {
    ele: <img src={help_intro} />,
    text: "Check out our help menu for answers to common questions or to schedule a demo.",
    type: "image",
  },
  {
    ele: <img src={bug_intro} />,
    text: "If you have any issues, use our bug report feature.",
    type: "image",
  },
];

const FooterContent = ({ handleCancel }) => {
  const [is_checked, set_is_checked] = useState(false);

  const hideTutorial = window.localStorage.getItem("hideTutorial");

  useEffect(() => {
    if (is_checked) {
      window.localStorage.setItem("hideTutorial", true);
    } else {
      window.localStorage.setItem("hideTutorial", false);
    }
  }, [is_checked]);

  return (
    <>
      <Checkbox label="Don't show again" value={is_checked} onChange={() => set_is_checked(!is_checked)} />
      <Button label="Close" type="ghost" size="medium" onClick={handleCancel} />
    </>
  );
};

const IntroModal = () => {
  const hideTutorialToken = window.localStorage.getItem("hideTutorial");

  const [show_intro_modal, set_show_intro_modal] = useState(true);

  const handleCancel = () => {
    set_show_intro_modal(false);
  };

  return (
    <Modal
      open={show_intro_modal}
      centered={true}
      maskCloseable={false}
      onCancel={handleCancel}
      title="Welcome to SIFT"
      width={"590px"}
      height={"490px"}
      footer={<FooterContent handleCancel={handleCancel} />}
    >
      <Carousel items={carousel_items} />
    </Modal>
  );
};

export { IntroModal };
