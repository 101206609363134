import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, siftActions, isAuthenticated, fetch_horizon_shading, fetch_weather, execute_job } from "../../Redux";

//tutorial stuff
import { TutorialTip } from "../../TutorialTip";
import { useAuth } from "../../Auth/AuthProvider";

import { Radio, Input, Button, Empty, Divider, Popconfirm, Spin, Switch, Checkbox, Upload } from "antd";
const RadioGroup = Radio.Group;
const Dragger = Upload.Dragger;

import { CheckCircleOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;
import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";
import { InputPopout } from "../InputPopout";

const Months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const WeatherOptions = [
  { label: "NSRDB", value: 0 },
  { label: "NASA POWER", value: 1 },
  // { label: "PVGIS", value: 2 },
];
const weatherSources = [
  { label: "NSRDB", value: 0 },
  { label: "NASA POWER", value: 1 },
  // { label: "PVGIS", value: 2 },
];
const columns = [
  { label: "", width: "8%" },
  { label: "", width: "20%" },
  { label: "", width: "20%" },
  { label: "", width: "15%" },
  { label: "", width: "12%" },
];
const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const WeatherInputs = (props) => {
  const inputs = useSelector((state) => state.inputs);
  // const inputs = useSelector((state) => state.sift.ioManager.inputs.weather.data);
  const { is_authenticated } = useAuth();

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const weather_loading = useSelector((state) => state.sift.ioManager.uiState.weather_loading);

  const saving = account_loading || input_loading;
  const [lat, set_lat] = useState(undefined);
  const [lng, set_lng] = useState(undefined);

  const [weatherDataVisible, setWeatherDataVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();
  const [local_inputs, set_local_inputs] = useState(inputs);
  const [local_loading, set_local_loading] = useState(false);
  const [weather_dt, set_weather_dt] = useState([]);

  useEffect(() => {
    set_local_inputs(inputs);
    create_weather_summary(inputs);
    set_local_loading(false);
    if (!lat || !lng) {
      set_lat(inputs.latitude);
      set_lng(inputs.longitude);
    }
  }, [inputs]);

  useEffect(() => {
    set_lat(inputs.latitude);
    set_lng(inputs.longitude);
  }, [inputs.latitude, inputs.longitude]);

  const update_lat_lng = (id) => {
    if (id == "latitude") {
      updateInput(id, parseFloat(lat));
    } else {
      updateInput(id, parseFloat(lng));
    }
  };

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // do some input validation here
    let fixed_value = value;
    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;
    // console.log(key, fixed_value);
    if (_.isUndefined(value) || _.isNaN(value)) {
      return;
    }

    if (key == "latitude" || key == "longitude") {
      fixed_value = parseFloat(value);
    }

    if (key == "timezone" && (_.isUndefined(value) || _.isNaN(value) || _.isEmpty(value))) {
      return;
    } else {
      fixed_value = parseInt(value);
    }

    if (key == "elevation" && (_.isUndefined(value) || _.isNaN(value) || _.isEmpty(value))) {
      return;
    } else {
      fixed_value = parseFloat(value);
    }

    dispatch(inputsActions.update_input(key, fixed_value));
  };

  // useEffect(() => {
  //   if (inputs.weather_summary !== undefined) {
  //     let lat = parseFloat(inputs.lat);
  //     let lng = parseFloat(inputs.lng);
  //     let search_lat = parseFloat(inputs.search_lat);
  //     let search_lng = parseFloat(inputs.search_lng);

  //     // we should only fix the weather inputs IF there is no boundaries available
  //     if (Object.keys(features).length > 0) {
  //       return;
  //     }

  //     // console.log('weather summary updated', features)

  //     if (lat.toFixed(0) != search_lat.toFixed(0) || lng.toFixed(0) != search_lng.toFixed(0)) {
  //       let lat_valid = !isNaN(lat) && lat <= 90 && lat >= -90 && lat != 0;
  //       let lng_valid = !isNaN(lng) && lng <= 180 && lng >= -180 && lng != 0;
  //       if (lat_valid && lng_valid) {
  //         // lat/lng aren't close to weather file AND valid, lets correct this
  //         dispatch(
  //           siftActions.fixWeatherInputs({
  //             lat: inputs.lat,
  //             lng: inputs.lng,
  //             tz: inputs.tz,
  //             ele: inputs.ele,
  //           })
  //         );
  //       }
  //     }
  //   }
  // }, [inputs.weather_summary]);

  function toggleWeatherPopup(e) {
    let cy = e.clientY;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    setWeatherDataVisible(!weatherDataVisible);
  }

  function create_weather_summary(inputs) {
    if (inputs.weather_summary == undefined) return;

    let data = [
      [
        { value: "", readOnly: true },
        { value: "GHI (kWh/m²/mo)", readOnly: true },
        { value: "DHI (kWh/m²/mo)", readOnly: true },
        { value: "Temp (°C)", readOnly: true },
        { value: "Wind Velocity (m/s)", readOnly: true },
      ],
    ];

    let t_HorizGlobIrrad = 0;
    let t_HorizDiffIrrad = 0;
    let t_Temp = 0;
    let t_Wind = 0;

    for (var w in inputs.weather_summary[0]) {
      let row = inputs.weather_summary[0][w];
      t_HorizGlobIrrad += row["HorizGlobIrrad"];
      t_HorizDiffIrrad += row["HorizDiffIrrad"];
      t_Temp += row["Temp"];
      t_Wind += row["Wind"];

      data.push([
        { value: Months[row["month"] - 1], readOnly: true },
        { value: row["HorizGlobIrrad"].toFixed(1), readOnly: true },
        { value: row["HorizDiffIrrad"].toFixed(1), readOnly: true },
        { value: row["Temp"].toFixed(1), readOnly: true },
        { value: row["Wind"].toFixed(2), readOnly: true },
      ]);
    }

    data.splice(1, 0, [
      { value: "Year", readOnly: true },
      { value: t_HorizGlobIrrad.toFixed(1), readOnly: true },
      { value: t_HorizDiffIrrad.toFixed(1), readOnly: true },
      { value: (t_Temp / 12).toFixed(1), readOnly: true },
      { value: (t_Wind / 12).toFixed(1), readOnly: true },
    ]);

    // weather_dt = data;
    // console.log("data", data);
    set_weather_dt(data);
  }

  // try to create the weather summary datatable
  // handleCreateWeatherSummary();

  function onPullLocFromMapClick() {
    // set_local_loading(true);
    dispatch(siftActions.pullMapLoc());
  }

  function onRequestWeather() {
    set_local_loading(true);

    dispatch(
      execute_job(
        fetch_weather(
          {
            latitude: parseFloat(local_inputs.latitude),
            longitude: parseFloat(local_inputs.longitude),
            elevation: local_inputs.elevation,
            timezone: local_inputs.timezone,
            weather_source: local_inputs.weather_source,
          },
          "manual-request"
        )
      )
    );

    // dispatch(
    //   siftActions.requestWeather({
    //     lat: parseFloat(local_inputs.latitude),
    //     lng: parseFloat(local_inputs.longitude),
    //     source: local_inputs.weather_source,
    //   })
    // );
  }

  function onDownloadWeather() {
    dispatch(siftActions.downloadWeather(local_inputs.weather_id));
  }
  function onClearWeatherData() {
    dispatch(siftActions.clearWeather());
  }

  return (
    <div className="input-content-box">
      <InputPopout visible={weatherDataVisible && local_inputs.weather_id} onClose={toggleWeatherPopup} popoutY={popoutY}>
        <div className="weather-popup">
          {/* SUMMARY OF WEATHER FILE */}
          <div className="weather-overview">
            <div>
              <span>
                Latitude & Longitude: ({local_inputs.weather_lat},{local_inputs.weather_lng})
              </span>
            </div>
            <div>
              <span>Elevation: {local_inputs.weather_ele}</span>
            </div>
            <div>
              <span>Timezone: UTC{local_inputs.weather_tz}</span>
            </div>
          </div>
          <ReactDataSheet
            data={weather_dt}
            sheetRenderer={renderSheet}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
            className="weather-grid"
            // onCellsChanged={this.handleChanges}
          />
        </div>
      </InputPopout>

      <Spin spinning={saving} className="sift-loader">
        <div>
          <Divider className="weather-divider" orientation="left">
            Location
          </Divider>
          <div className="input-row">
            <label>Latitude</label>
            <Input
              id="latitude"
              type="number"
              onBlur={(e) => {
                update_lat_lng(e.target.id);
              }}
              onChange={(e) => {
                set_lat(e.target.value);
                // updateInput(e.target.id, e.target.value);
              }}
              value={lat}
              size="small"
              disabled={weather_loading}
              suffix={weather_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
            />
          </div>
          <div className="input-row">
            <label>Longitude</label>
            <Input
              id="longitude"
              type="number"
              onBlur={(event) => {
                update_lat_lng(event.target.id);
              }}
              onChange={(e) => {
                set_lng(e.target.value);
                // updateInput(e.target.id, e.target.value);
              }}
              value={lng}
              size="small"
              disabled={weather_loading}
              suffix={weather_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
            />
          </div>

          <div className="input-row">
            <label>Elevation</label>
            <Input
              id="elevation"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.elevation}
              size="small"
              disabled={weather_loading}
              suffix={weather_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="input-row">
            <label>Timezone</label>
            <Input
              id="timezone"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.timezone}
              size="small"
              max={12}
              min={-12}
              disabled={weather_loading}
              suffix={weather_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)" }}>UTC</span>}
            />
          </div>

          <div className="input-row">
            <span>{saving ? <span>Recenter pin</span> : <a onClick={() => onPullLocFromMapClick()}>Recenter pin</a>}</span>
            <span style={{ marginLeft: "auto", marginRight: "3px" }}>
              {local_inputs.location}
              {/* {local_inputs.county} */}
              {/* {local_inputs.state} */}
            </span>
          </div>
        </div>

        <Divider className="weather-divider" orientation="left">
          Data
        </Divider>

        {/* {local_inputs.technology == 2 && local_inputs.weather_summary && (local_inputs.weather_summary[0][0]["ColumnPrecip cm"] || local_inputs.weather_summary[0][0]["RH"]) && (
          <section className="info">
            <CheckCircleOutlined style={{ color: "#002bcb", fontSize: "12px" }} />
            <p>Sufficient data for spectral correction.</p>
          </section>
        )}

        {local_inputs.technology == 2 && local_inputs.weather_summary && (!local_inputs.weather_summary[0][0]["ColumnPrecip cm"] || !local_inputs.weather_summary[0][0]["RH"]) && (
          <section className="info" style={{ alignItems: "unset" }}>
            <CheckCircleOutlined style={{ color: "#002bcb", fontSize: "12px", marginTop: 6 }} />
            <p>
              Insufficient data for spectral correction. <br /> See help menu.
            </p>
          </section>
        )} */}

        {/* 8760 table resized with fixed headers */}
        {/* totals for each column? */}
        {local_inputs.weather_id != 0 && local_inputs.weather_summary ? (
          <a onClick={toggleWeatherPopup}>View Weather Summary</a>
        ) : (
          <div style={{ padding: "1%", width: "50%" }}>{/* <Empty description='Import Weather from source or local file'/> */}</div>
        )}

        {local_inputs.weather_id == undefined && (
          <div className="input-row one center">
            <RadioGroup
              options={WeatherOptions}
              name="weather_source"
              onChange={(event) => {
                updateInput(event.target.name, event.target.value);
              }}
              value={local_inputs.weather_source}
              disabled={local_inputs.weather_summary != undefined || saving}
            />
          </div>
        )}
        {local_inputs.weather_id == undefined && (
          <div className="input-row one center">
            {!is_authenticated ? (
              <span>You must login to request weather.</span>
            ) : (local_inputs.latitude == 0 && local_inputs.longitude == 0) || saving ? (
              <span>Manual Request</span>
            ) : weather_loading ? (
              <span>
                <LoadingOutlined /> Loading...
              </span>
            ) : local_inputs.weather_id == undefined ? (
              <span>
                <a onClick={() => onRequestWeather()}> Manual Request</a>
              </span>
            ) : (
              <span>Weather data loaded.</span>
            )}
          </div>
        )}

        {local_inputs.weather_id && (
          <div>
            <div>
              <span>
                <a onClick={() => onDownloadWeather()}>Download Data</a>
              </span>
            </div>
            <div>
              <span>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    onClearWeatherData();
                  }}
                >
                  <a>Clear Data</a>
                </Popconfirm>
              </span>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

const RecenterPinButton = () => {
  const dispatch = useDispatch();
  return <a onClick={() => dispatch(siftActions.pullMapLoc())}>Recenter pin</a>;
};

export { WeatherInputs, RecenterPinButton };
