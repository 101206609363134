import * as turf from "@turf/turf";
import { create_UUID } from "../../../../Redux";

import { square, square_green } from "../../../../../assets/images";
import { geoJSON } from "leaflet";

export const getArea = (coords) => {
  let turfPoly = turf.polygon(coords);
  return (turf.area(turfPoly) / 1000000) * 100;
};

export const getLineArea = (coords) => {
  let turfPoly = turf.lineString(coords);
  return (turf.area(turfPoly) / 1000000) * 100;
};

export const getAcreage = (coords) => {
  // let turfPoly = turf.polygon(coords);
  return getArea(coords) * 2.4710538;
};

// Function to validate if the coordinates represent a valid Polygon
export const isValidPolygon = (feature) => {
  if (feature.geometry && feature.geometry.type === "Polygon" && Array.isArray(feature.geometry.coordinates)) {
    const coordinates = feature.geometry.coordinates;

    if (coordinates.length > 1) {
      console.log("Invalid Polygon: Multiple rings detected");
      return false;
    }

    return true;
  }
  return false;
};

export const isLinearRing = (geojson) => {
  if (geojson.type !== "Feature" || geojson.geometry.type !== "Polygon") {
    return false;
  }

  const coordinates = geojson.geometry.coordinates;

  try {
    return coordinates.every((ring) => {
      // The first and last coordinate in a ring has to be the same
      return turf.booleanEqual(ring[0], ring[ring.length - 1]);
    });
  } catch (error) {
    return false;
  }
};

export const getLineAcreage = (coords) => {
  // let turfPoly = turf.polygon(coords);
  return getLineArea(coords) * 2.4710538;
};

export const getTouchIconBase = (platform) => {
  let touch_icon_base;
  if (platform == "internal_sift") {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square_green,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  } else {
    touch_icon_base = new L.Icon({
      // iconUrl: hexagon_2,
      // changing icon to square
      iconUrl: square,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
    });
  }

  return touch_icon_base;
};

export const handleFeatureCreated = (e, completeFromHotkey = false) => {
  let new_id = create_UUID();
  let latlngs;

  if (!completeFromHotkey) {
    latlngs = e.layer.getLatLngs()[0];
  } else {
    let layerCoords = [];
    e._markers.map((marker) => layerCoords.push(marker._latlng));
    latlngs = layerCoords;
  }

  let coords = [];
  latlngs.forEach((latlng) => {
    let wrapped = latlng.wrap();
    coords.push([wrapped.lat, wrapped.lng]);
  });
  coords.push(coords[0]);
  let real_poly = turf.flip(turf.polygon([coords]));

  let geoJson = completeFromHotkey ? real_poly : e.layer.toGeoJSON();

  geoJson.properties["index"] = new_id;
  geoJson.properties["identity"] = 0;
  geoJson.properties["active"] = true;
  geoJson.properties["name"] = undefined;
  geoJson.properties["sortIndex"] = 1;
  geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
  geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
  geoJson.properties["center"] = turf.getCoords(turf.centroid(real_poly));

  return geoJson;
};

export const handlePolylineCreated = (e, completeFromHotkey = false) => {
  let new_id = create_UUID();
  let latlngs;

  // if (!completeFromHotkey) {
  //   latlngs = e.layer.getLatLngs()[0];
  // } else {
  //   let layerCoords = [];
  //   e._markers.map((marker) => layerCoords.push(marker._latlng));
  //   latlngs = layerCoords;
  // }

  // let coords = [];
  // latlngs.forEach((latlng) => {
  //   let wrapped = latlng.wrap();
  //   coords.push([wrapped.lat, wrapped.lng]);
  // });
  // coords.push(coords[0]);
  // let real_poly = turf.flip(turf.polygon([coords]));

  // let geoJson = completeFromHotkey ? real_poly : e.layer.toGeoJSON();

  let geoJson = e.layer.toGeoJSON();

  geoJson.properties["index"] = new_id;
  geoJson.properties["identity"] = 0;
  geoJson.properties["active"] = true;
  geoJson.properties["name"] = undefined;
  geoJson.properties["sortIndex"] = 1;
  geoJson.properties["type"] = "line";
  // geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
  // geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
  geoJson.properties["center"] = turf.center(geoJson).geometry.coordinates;

  return geoJson;
};
