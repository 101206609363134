/*
	VisualShadeLayer.js
	This is a visual representation of shade being cast on canopies
*/

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePrevious } from "../../Redux";

import { FeatureGroup, useMap, GeoJSON } from "react-leaflet";

const VisualShadeLayer = ({ shade_id, shade_month, shade_hour, interactive = true }) => {
  const map = useMap();
  const shade = useSelector((state) => state.canopy.shade_results.shadeAreas[shade_month][shade_hour].geoJson);

  useEffect(() => {
    if (!shade) return;
    createShadeArea();
  }, [shade]);

  function createShadeArea() {
    let shadeArea = L.geoJSON(shade, {
      id: shade_id,
      interactive,
      layer_type: "shade",
      transform: false,
      draggable: false,
      scaling: false,
      style: {
        stroke: 1,
        color: "#666", //🤘
        fillColor: "#666", //🤘
        fillOpacity: 0.5,
        opacity: 0.9,
        interactive: false,
      },
    });
    // console.log("shadeArea", shadeArea);

    shadeArea.addTo(map);

    shadeArea.bringToBack();
  }

  return <></>;
};

const VisualShadeArea = () => {
  //   const dispatch = useDispatch();
  const map = useMap();
  const shade_results = useSelector((state) => state.canopy.shade_results);
  const shade_month = useSelector((state) => state.canopy.shade_month);
  const shade_hour = useSelector((state) => state.canopy.shade_hour);

  const prev_shade_results = usePrevious(shade_results);

  useEffect(() => {
    if (prev_shade_results && Object.values(shade_results).length == 0 && Object.values(prev_shade_results).length > 0) {
      map.eachLayer((layer) => layer.options.layer_type && layer.options.layer_type == "shade" && layer.remove());
    }
  }, [shade_results]);

  //   useEffect(() => {
  //     console.log("shade_hour", shade_hour);
  //   }, [shade_hour]);

  if (Object.values(shade_results).length === 0) return null;
  return (
    <FeatureGroup>
      {Object.values(shade_results.shadeAreas).map((shade) => {
        return Object.keys(shade[shade_month]).map((key) => {
          if (key == shade_hour) {
            return (
              <GeoJSON
                data={shade[shade_month][key]}
                key={shade[shade_month][key].properties.index}
                transform={false}
                draggable={false}
                interactive={false}
                maxVertices={250}
                type="shade_feature"
                style={{
                  stroke: 1,
                  color: "#666", //🤘
                  fillColor: "#666", //🤘
                  fillOpacity: 0.5,
                  opacity: 0.9,
                }}
              />
            );
          } else {
            return null;
          }
        });
      })}
    </FeatureGroup>
  );
};

export { VisualShadeArea };
