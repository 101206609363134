import styled from "styled-components";

const InputPopoutWrapper = styled.section`
  position: relative;

  .input-popout {
    position: fixed;
    left: 385px;
    top: ${(props) => props.popoutY}px;
    width: auto;
    /* max-width: 600px; */
    height: auto;
    background-color: #fff;
    /* box-shadow: var(--subtle-box-shadow); */
    display: grid;
    grid-template-rows: auto auto 1fr;
    z-index: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);

    .popout-close {
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr auto;

      p {
        grid-column: 1;
        margin: 0px;
        place-self: center;
      }

      a {
        grid-column: 2;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .popout-content {
      max-width: 700px;
      padding: 8px;
      height: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 768px) {
    left: 20px;
    right: 20px;
    margin: auto;
    z-index: 510;
  }

  .weather-popup {
    width: 400px;
  }
`;

const RackingSpecErrors = styled.section`
  position: fixed;
  left: 385px;
  top: ${(props) => (props.showError ? "668px " : "520px")};
  width: 680px;
  margin: 0 10px;
  padding: 10px;
  border-radius: 4px;
  /* border: 1px solid #002bcb; */
  z-index: 999;
  background: #fafafa;
  transition: all 0.45s var(--easing-spring);

  ul {
    list-style: none;
    padding: 0px;
    li {
      color: #333;

      .error_list {
        display: flex;
        align-items: flex-start;
        p {
          margin: 0px;
        }
      }
    }
  }
`;

const SATWrap = styled.section`
  width: 100%;
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, min-content);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  opacity: ${(props) => (props.active == 1 && props.autoRack == 1 ? 1.0 : 0.6)};

  input {
    width: 75px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &:focus {
      outline: none;
      border: 1px solid var(--primary-brand-color);
    }
  }

  .popout-header {
    grid-row: 1;
    grid-column: 1 /-1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 43, 203, 0.4);
    padding-bottom: 8px;

    h4 {
      margin: 0px 5px;
    }

    .toggle-active {
      display: flex;
      align-items: center;

      p {
        margin: 0px 5px;
      }
    }
  }

  .mod-qty {
    grid-row: 4;
    grid-column: 1 / 4;
    margin-top: 20px;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;

    p {
      margin: 2px 0px;
    }
  }

  .y-input {
    grid-row: 3;
    grid-column: 1 / 3;
    margin-top: 20px;
  }

  .y-input-read-only {
    height: 44px;
    grid-row: 3;
    grid-column: 1 / 3;
    display: grid;
    place-items: center;
    p {
      margin: 0px 5px 0px 0px;
      white-space: nowrap;
    }
  }

  .x-input {
    grid-row: 2;
    grid-column: 1 / 3;
    margin-top: 20px;
  }
  .x-input-read-only {
    height: 44px;
    grid-row: 5;
    grid-column: 4 / 6;
    display: grid;
    place-items: center;
    p {
      margin: 10px 0px 0px 0px;
      white-space: nowrap;
    }
  }

  .racking-information {
    grid-row: 5;
    grid-column: 1 / -1;

    .string_count {
      display: flex;
      align-items: center;
      grid-row: 5;
      white-space: nowrap;
      grid-column: 1 / -1;

      p {
        margin: 0px;
      }
    }
  }

  .racking-image {
    grid-column: 3 / -1;
    grid-row: 2 / 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SATWrapNoBorder = styled(SATWrap)`
  border-right: none;
`;

export { InputPopoutWrapper, SATWrap, SATWrapNoBorder, RackingSpecErrors };
