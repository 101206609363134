import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { canopyActions, inputsActions } from "../../../../../Redux";

import { Typography, Tooltip, Button, Popconfirm, message } from "antd";

import { InputRow } from "./styles";
import { delete_polygon, copy } from "../../../../../../assets/images";

function NameInput() {
  const dispatch = useDispatch();
  // const name = useSelector((state) => state.carport.selectedInputs.name);
  // const canopyId = useSelector((state) => state.carport.selectedInputs.id);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const name = useSelector((state) => state.canopy.canopies[selected_canopy]?.name);

  const copy_canopy_id = useSelector((state) => state.canopy.copy_canopy_id);

  const [name_error, set_name_error] = useState(undefined);

  function onChangeName(newCanopyName) {
    if (newCanopyName == "") {
      set_name_error("Canopy must have a name.");
      return;
    } else if (name_error != undefined) {
      set_name_error(undefined);
    }
    if (newCanopyName != name) {
      dispatch(canopyActions.updateCanopyProperty(selected_canopy, "name", newCanopyName, true));
    }
  }

  return (
    <InputRow canopy_label={true}>
      <Typography.Text
        strong
        editable={{
          onChange: onChangeName,
        }}
      >
        {name}
      </Typography.Text>
      <>
        <div className="copy-delete">
          <Tooltip title="Delete">
            <Popconfirm
              placement="right"
              title={`Delete ${name}`}
              onCancel={() => console.log("canceled")}
              okText="Yes"
              cancelText="No"
              onConfirm={(e) => {
                if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
                if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
                dispatch(canopyActions.deleteCanopies(selected_canopy));
              }}
            >
              <Button type="link">
                <img src={delete_polygon} />
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      </>
    </InputRow>
  );
}

export { NameInput };
