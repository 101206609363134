import React, { useEffect } from "react";
// import ReactDOM from "react-dom";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { getTouchIconBase } from "./_draw.helpers";

import L from "leaflet";
import "leaflet-draw";

const MapFeatureOverrides = () => {
  const map = useMap();
  const platform = useSelector((state) => state.user.platform);

  let override = function (method, fn, hookAfter) {
    if (!hookAfter) {
      return function () {
        let originalReturnValue = method.apply(this, arguments);
        let args = Array.prototype.slice.call(arguments);
        args.push(originalReturnValue);
        return fn.apply(this, args);
      };
    } else {
      return function () {
        fn.apply(this, arguments);
        return method.apply(this, arguments);
      };
    }
  };

  useEffect(() => {
    let changed = false;

    if (!changed) {
      L.DomUtil.addClass(map.getContainer(), "custom-vertex");
    } else {
      L.DomUtil.removeClass(map.getContainer(), "custom-vertex");
    }
    changed = !changed;

    L.Edit.PolyVerticesEdit.prototype._createMarker = function (latlng, index) {
      // Extending L.Marker in TouchEvents.js to include touch.
      let marker = new L.Marker.Touch(latlng, {
        draggable: true,
        icon: getTouchIconBase(platform),
      });

      marker._origLatLng = latlng;
      marker._index = index;

      marker
        .on("dragstart", this._onMarkerDragStart, this)
        .on("drag", this._onMarkerDrag, this)
        .on("dragend", this._fireEdit, this)
        .on("touchmove", this._onTouchMove, this)
        .on("touchend", this._fireEdit, this);
      // .on('MSPointerMove', this._onTouchMove, this)
      // .on('MSPointerUp', this._fireEdit, this);

      this._markerGroup.addLayer(marker);

      return marker;
    };

    L.Edit.PolyVerticesEdit.include({
      addHooks: override(L.Edit.PolyVerticesEdit.prototype.addHooks, function (originalReturnValue) {
        this.showMeasurements();
        return originalReturnValue;
      }),
      removeHooks: override(L.Edit.PolyVerticesEdit.prototype.removeHooks, function (originalReturnValue) {
        this.hideMeasurements();
        return originalReturnValue;
      }),
      updateMarkers: override(L.Edit.PolyVerticesEdit.prototype.updateMarkers, function (originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerDrag: override(L.Edit.PolyVerticesEdit.prototype._onMarkerDrag, function (originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerClick: override(L.Edit.PolyVerticesEdit.prototype._onMarkerClick, function (originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),

      _initMarkers: override(L.Edit.PolyVerticesEdit.prototype._initMarkers, function (originalReturnValue) {
        this.overrideInitMarkers();
        return originalReturnValue;
      }),

      overrideInitMarkers: function () {
        //clear some previous stuff, turn some stuff off yada yada.
        this._markerGroup?.clearLayers();
        map.off("zoomend", this.recalculateMarkers, this);
        map.off("moveend", this.recalculateMarkers, this);

        if (!this._markerGroup) {
          this._markerGroup = new L.LayerGroup();
        }
        this._markers = [];
        let latlngs = this._defaultShape(),
          i,
          j,
          len,
          marker,
          originLatlngLength = latlngs.length,
          mapBounds = map.getBounds(),
          maxVertices = this._poly?.options?.maxVertices || 250;

        if (latlngs.length > maxVertices) {
          // get points that are contained within the map's viewable area if there are more than the max number of vertices allowed.
          let points_within_bounds = [];
          for (let i = 0; i < latlngs.length; i++) {
            if (mapBounds.contains(latlngs[i])) {
              points_within_bounds.push(latlngs[i]);
            }
          }
          // console.log("points_within_bounds", points_within_bounds);

          // calculate the ratio to be used based on the max number of verts allowed and how many of the points are currently within the map bounds
          const ratio = maxVertices / points_within_bounds.length;
          const simplifiedPoints = [];
          // go through all the points and pull out the points based on the above ratio in order to get *mostly* evenly spaced verts along the polygon sides
          for (let i = 0; i < points_within_bounds.length; i++) {
            if (i % Math.ceil(1 / ratio) === 0) {
              simplifiedPoints.push(points_within_bounds[i]);
            }
          }

          latlngs = simplifiedPoints;
        }
        // create all the markers
        for (i = 0, len = latlngs.length; i < len; i++) {
          marker = this._createMarker(latlngs[i], i);
          marker.on("click", this._onMarkerClick, this);
          marker.on("contextmenu", this._onContextMenu, this);
          this._markers.push(marker);
        }

        // This skips the middleMarker creation when loading or drawing a polygon with an excessive amount of vertices. Done for performance reasons.
        // Removing the check isn't too bad, but I think it's probably unnecessary for 99% of cases.
        if (originLatlngLength < maxVertices) {
          let markerLeft, markerRight;
          for (i = 0, j = len - 1; i < len; j = i++) {
            if (i === 0 && !(L.Polygon && this._poly instanceof L.Polygon)) {
              continue;
            }
            markerLeft = this._markers[j];
            markerRight = this._markers[i];
            this._createMiddleMarker(markerLeft, markerRight);
            this._updatePrevNext(markerLeft, markerRight);
          }
        }

        if (originLatlngLength > maxVertices) {
          map.on("zoomend", this.recalculateMarkers, this);
          map.on("moveend", this.recalculateMarkers, this);
        }
      },

      recalculateMarkers: function () {
        this.overrideInitMarkers();
      },

      showMeasurements: function (options) {
        if (!map || this._measurementLayer) return this;
        this._measurementOptions = L.extend(
          {
            // this can be true as well
            imperial: true,
            // showOnHover: false,
            minPixelDistance: 30,
            showDistances: true,
            showArea: true,
            lang: {
              totalLength: "Total length",
              totalArea: "Total area",
              segmentLength: "Segment length",
              segmentAzimuth: "Segment Azimuth",
            },
          },
          options || {}
        );

        this._measurementLayer = L.layerGroup().addTo(map);
        this.updateMeasurements();
        map.on("zoomend", this.updateMeasurements, this);

        return this;
      },

      hideMeasurements: function () {
        if (!map) return this;

        map.off("zoomend", this.updateMeasurements, this);

        if (!this._measurementLayer) return this;
        map.removeLayer(this._measurementLayer);
        this._measurementLayer = null;

        return this;
      },

      updateMeasurements: function () {
        if (!this._measurementLayer) return this;
        let latLngs = this._poly.getLatLngs(),
          isPolygon = true,
          options = this._measurementOptions,
          totalDist = 0,
          formatter,
          ll1,
          ll2,
          p1,
          p2,
          pixelDist,
          dist;
        if (latLngs && latLngs.length && L.Util.isArray(latLngs[0])) {
          // Outer ring is stored as an array in the first element,
          // use that instead.
          latLngs = latLngs[0];
        }

        // check which mode we're in
        let isDimension = L.DomUtil.hasClass(document.body, "dimension-mode");
        let isAzimuth = L.DomUtil.hasClass(document.body, "azimuth-mode");
        // console.log(`isDimension?:${isDimension} - isAzimuth?:${isAzimuth}`)

        this._measurementLayer.clearLayers();
        if (latLngs.length > 1 && (isDimension || isAzimuth)) {
          this._measurementOptions.imperial = false;
          formatter = L.bind(this.formatDistance, this);
          let sumEdges = 0;
          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            let _p1 = map.project(ll1),
              _p2 = map.project(ll2);

            let sum = (_p2.x - _p1.x) * (_p2.y + _p1.y);
            sumEdges += sum;
          }
          let isClockWise = sumEdges <= 0;

          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            dist = ll1.distanceTo(ll2);
            totalDist += dist;

            p1 = map.latLngToLayerPoint(ll1);
            p2 = map.latLngToLayerPoint(ll2);

            pixelDist = p1.distanceTo(p2);

            if (pixelDist >= options.minPixelDistance) {
              let _p1 = map.project(ll1),
                _p2 = map.project(ll2);
              let rotation = Math.atan((_p2.y - _p1.y) / (_p2.x - _p1.x));
              let mode = isDimension ? "dimension" : "azimuth";
              let title = isDimension ? options.lang.segmentLength : options.lang.segmentAzimuth;
              let sp = map.layerPointToLatLng([(p1.x + p2.x) / 2, (p1.y + p2.y) / 2]);
              L.marker.measurement(sp, formatter(dist), title, rotation, options, _p2.x > _p1.x, _p2.y > _p1.y, mode, isClockWise).addTo(this._measurementLayer);
            }
          }

          // Show total length for polylines
          if (!isPolygon) {
            L.marker.measurement(ll2, formatter(totalDist), options.lang.totalLength, 0, options).addTo(this._measurementLayer);
          }
        }
        return this;
      },

      formatDistance: function (d) {
        let unit, feet;

        if (this._measurementOptions.imperial) {
          feet = d / 0.3048;
          d = feet;
          unit = "ft";
        } else {
          if (d > 1000) {
            d = d / 1000;
            unit = "km";
          } else {
            unit = "m";
          }
        }

        if (d < 100) {
          return d.toFixed(1) + " " + unit;
        } else {
          return Math.round(d) + " " + unit;
        }
      },

      formatArea: function (a) {
        let unit;

        if (this._measurementOptions.imperial) {
          if (a > 404.685642) {
            a = a / 4046.85642;
            unit = "ac";
          } else {
            a = a / 0.09290304;
            unit = "ft²";
          }
        } else {
          if (a > 1000000) {
            a = a / 1000000;
            unit = "km²";
          } else {
            unit = "m²";
          }
        }

        if (a < 100) {
          return a.toFixed(1) + " " + unit;
        } else {
          return Math.round(a) + " " + unit;
        }
      },

      ringArea: function ringArea(coords) {
        let rad = function rad(_) {
          return (_ * Math.PI) / 180;
        };
        let p1,
          p2,
          p3,
          lowerIndex,
          middleIndex,
          upperIndex,
          area = 0,
          coordsLength = coords.length;

        if (coordsLength > 2) {
          for (let i = 0; i < coordsLength; i++) {
            if (i === coordsLength - 2) {
              // i = N-2
              lowerIndex = coordsLength - 2;
              middleIndex = coordsLength - 1;
              upperIndex = 0;
            } else if (i === coordsLength - 1) {
              // i = N-1
              lowerIndex = coordsLength - 1;
              middleIndex = 0;
              upperIndex = 1;
            } else {
              // i = 0 to N-3
              lowerIndex = i;
              middleIndex = i + 1;
              upperIndex = i + 2;
            }
            p1 = coords[lowerIndex];
            p2 = coords[middleIndex];
            p3 = coords[upperIndex];
            area += (rad(p3.lng) - rad(p1.lng)) * Math.sin(rad(p2.lat));
          }

          area = (area * 6378137 * 6378137) / 2;
        }

        return Math.abs(area);
      },
    });

    L.Marker.Measurement = L.Marker.extend({
      options: {
        pane: "markerPane",
      },

      initialize: function (latlng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
        L.setOptions(this, options);
        this._latlng = latlng;
        this._measurement = measurement;
        this._title = title;
        this._inside = inside;
        this._movingUp = movingUp;
        this._rotation = rotation;
        this._rot_def = `${Math.round(180 + (rotation * 180) / Math.PI).toFixed(0)}°`;
        this._mode = mode;
        this._clockwise = isClockWise;
      },

      addTo: function (map) {
        map.addLayer(this);
        return this;
      },

      onAdd: function (map) {
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        let el = (this._element = L.DomUtil.create("div", "leaflet-zoom-animated leaflet-measure-path-measurement", pane));
        el.style["position"] = "absolute";
        el.style["fontSize"] = "12px";
        el.style["color"] = "black";
        el.style["font-weight"] = "bold";
        el.style["white-space"] = "nowrap";
        el.style["transform-origin"] = "0";
        el.style["pointer-events"] = "none";
        el.style["width"] = "40px";
        el.style["text-align"] = "center";

        let inner = L.DomUtil.create("div", "leaflet-interactive", el);
        inner.title = this._title;

        if (this._mode === "dimension") {
          inner.innerHTML = this._measurement;
        } else if (this._mode === "azimuth") {
          inner.innerHTML = this._rot_def;
        }

        inner.style["position"] = "relative";
        inner.style["background"] = "#ffffffb0";
        inner.style["color"] = "black";
        inner.style["font-weight"] = "bold";

        if (this._inside && !this._movingUp) {
          inner.style["left"] = `${-35}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (this._inside && this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (!this._inside && !this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        } else {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        }

        map.on("zoomanim", this._animateZoom, this);

        this._setPosition();
      },

      onRemove: function (map) {
        map.off("zoomanim", this._animateZoom, this);
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        pane.removeChild(this._element);
        map = null;
      },

      _setPosition: function () {
        let point = map.latLngToLayerPoint(this._latlng);

        L.DomUtil.setPosition(this._element, point);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },

      _animateZoom: function (opt) {
        let pos = map._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center).round();
        L.DomUtil.setPosition(this._element, pos);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },
    });

    L.marker.measurement = function (latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
      return new L.Marker.Measurement(latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise);
    };

    L.drawLocal = {
      draw: {
        handlers: {
          polygon: {
            tooltip: {
              start: "",
              cont: "",
              end: "",
            },
          },
          polyline: {
            error: "<strong>Error:</strong> shape edges cannot cross!",
            tooltip: {
              start: "Click to start drawing line.",
              cont: "Click to continue drawing line.",
              end: "Click last point to finish line.",
            },
          },
        },
      },
    };

    // L.Polygon.include({
    //   // options: {
    //   //   maxVertices: 100, // Maximum number of vertices to display
    //   // },

    //   _updatePath: override(L.Polygon.prototype._updatePath, function (originalReturnValue) {
    //     this.updateThePath();
    //     return originalReturnValue;
    //   }),

    //   updateThePath: function () {
    //     if (this.options.type != "user_map_feature") return this;
    //     let editing_verts = this.feature.geometry.coordinates[0];

    //     if (editing_verts.length > this.options.maxVertices) {
    //       const ratio = this.options.maxVertices / editing_verts.length;
    //       const simplifiedParts = [];
    //       const simplifiedPoints = [];
    //       for (let i = 0; i < editing_verts.length; i++) {
    //         if (i % Math.ceil(1 / ratio) === 0) {
    //           simplifiedParts.push(map.latLngToLayerPoint(editing_verts[i]));
    //           simplifiedPoints.push(L.latLng([editing_verts[i][1], editing_verts[i][0]]));
    //         }
    //       }
    //       this.editing.latlngs[0] = simplifiedPoints;
    //       this._parts[0] = simplifiedParts;
    //     }

    //     return this;

    //     // let layer_parts = this.feature.geometry.coordinates[0].map((latlng) => map.latLngToLayerPoint(latlng));
    //     // this._parts[0] = layer_parts;
    //     // // debugger;
    //     // // Override the _updatePath method to display a portion of the vertices
    //     // if (this._parts[0].length > this.options.maxVertices) {
    //     //   const ratio = this.options.maxVertices / this._parts[0].length;
    //     //   const simplifiedParts = [];
    //     //   for (let i = 0; i < this._parts[0].length; i++) {
    //     //     if (i % Math.ceil(1 / ratio) === 0) {
    //     //       simplifiedParts.push(this._parts[0][i]);
    //     //     }
    //     //   }
    //     //   this._parts[0] = simplifiedParts;
    //     // }
    //   },
    // });
  }, []);

  return null;
};

export { MapFeatureOverrides };
