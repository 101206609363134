import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { DropdownWrapper } from "./styles";

const Dropdown = ({ children, overlay = <></>, placement = "bottom", trigger = "click", disabled = false }) => {
  const [show_dropdown, set_show_dropdown] = useState(false);
  const [dropdown_coords, set_dropdown_coords] = useState(undefined);

  const dropdown_ref = useRef();
  const show_drop_ref = useRef(show_dropdown);

  //   useEffect(() => {
  //     console.log("dropdown_coords", dropdown_coords);
  //   }, [dropdown_coords]);

  const handleDropdownClick = (e) => {
    const pos = e.currentTarget.getBoundingClientRect();

    if (placement === "top") {
      set_dropdown_coords({
        top: pos.top + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "bottom") {
      set_dropdown_coords({
        top: pos.bottom + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "left") {
      set_dropdown_coords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + window.pageXOffset,
      });
    } else if (placement === "right") {
      set_dropdown_coords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + pos.width + window.pageXOffset,
      });
    }

    set_show_dropdown(!show_dropdown);
  };

  const dropDownStyles = {
    position: "fixed",
    borderRadius: "4px",
    zIndex: "9999",
    opacity: "0",
    maxWidth: "300px",
    transitionProperty: "opacity, transform",
    transformOrigin: "center center",
    textAlign: "center",
  };

  const dropDownPositionStyles = {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    //     console.log("show_dropdown", show_dropdown);

    if (show_dropdown && dropdown_coords && dropdown_ref.current) {
      let el = dropdown_ref.current;
      if (el) {
        el.style.transitionDuration = 0.4 + "ms";
        setTimeout(() => {
          if (placement === "top") {
            el.style.top = `${dropdown_coords.top - el.clientHeight}px`;
            el.style.left = `${dropdown_coords.left}px`;
            el.style.transform = `translate(-50%, -2px) scale(1)`;
          } else if (placement === "bottom") {
            el.style.top = `${dropdown_coords.top}px`;
            el.style.left = `${dropdown_coords.left}px`;
            el.style.transform = `translate(-50%, 2px) scale(1)`;
          } else if (placement === "left") {
            el.style.top = `${dropdown_coords.top}px`;
            el.style.left = `${dropdown_coords.left - el.clientWidth}px`;
            el.style.transform = `translate(-15px, -50%) scale(1)`;
          } else if (placement === "right") {
            el.style.top = `${dropdown_coords.top}px`;
            el.style.left = `${dropdown_coords.left}px`;
            el.style.transform = `translate(15px, -50%) scale(1)`;
          }

          el.style.opacity = "1";
        }, 20);
      }
    }
  }, [show_dropdown]);

  const clear_dropdown = () => {
    setTimeout(() => {
      set_show_dropdown(false);
      set_dropdown_coords(undefined);
    }, 300);
  };

  const menu_overlay = (
    <section ref={dropdown_ref} className={`dropdown dropdown--${placement}`} style={{ ...dropDownStyles, ...dropDownPositionStyles }}>
      {overlay}
    </section>
  );

  return (
    <DropdownWrapper onClick={(e) => !disabled && handleDropdownClick(e)} disabled={disabled} onBlur={() => clear_dropdown()}>
      {children}
      {show_dropdown && createPortal(menu_overlay, document.getElementById("root"))}
    </DropdownWrapper>
  );
};

export { Dropdown };
