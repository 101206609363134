import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { siftActions, authActions, accountActions, Alert } from "../../components";
import { BugReport, HelpDrawer, Account, Funnel } from "../../components";

import { Tooltip, Menu, Popconfirm, Button } from "antd";

import { useAuth } from "../../components";

import { logo, logo_small, logo_panels, question_mark, price, ts_logo, ts_logo_white, panelIcon } from "../../assets/images";

// icons
import {
  CloudOutlined,
  UserOutlined,
  DiffOutlined,
  LogoutOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  LoginOutlined,
  BugOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
  HomeOutlined,
  DollarCircleOutlined,
  CarOutlined,
} from "@ant-design/icons";

// sc
import { Nav } from "./StyledComponents";

const Navigation = () => {
  const { useApi, is_authenticated } = useAuth();

  // const isAuthed = useSelector((state) => isAuthenticated(state));
  const show_agreement_modal = useSelector((state) => state.user.show_agreement_modal);
  const system_alerts = useSelector((state) => state.sift.system_alerts);

  // platform
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";

  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const [selectedKey, setSelectedKey] = useState(undefined);
  const [toggleMobileNav, setToggleMobileNav] = useState(false);

  const dispatch = useDispatch();

  // Used by both menu's in the nav
  function handleMenuClick(key) {
    if (key == "bug") {
      dispatch(siftActions.toggleHelpToolbarVisibility("bug_visible", true));
    }
    if (key == "patch") {
      dispatch(siftActions.toggleHelpToolbarVisibility("patch_visible", true));
    }
    if (key == "login") {
      dispatch(authActions.toggleLoginVis(true, "login"));
    }
    if (key == "help") {
      dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", true));
    }
    // if (key == 'collab') {
    //   dispatch(siftActions.toggleCollabModal());
    // }
    if (key == "account") {
      if (is_authenticated) {
        dispatch(siftActions.toggleAccountModal());
      } else {
        dispatch(accountActions.toggleFunnelVis(true));
      }
    }
  }

  // DEPRECATE THIS
  function handleButtonClick(event) {
    dispatch(authActions.logout());
  }

  return (
    <Fragment>
      <Nav toggleMobileNav={toggleMobileNav} isAuthed={is_authenticated} show_agreement_modal={show_agreement_modal} internal_sift={internal_sift}>
        {/* logo */}

        <div className="brand-logo">
          {screenWidth >= 768 ? (
            <a href="https://www.Terrasmart.com/products/software/sift/" target="_blank" rel="noopener noreferrer">
              <img src={internal_sift ? ts_logo_white : ts_logo} />
            </a>
          ) : (
            <Button type="link" onClick={() => setToggleMobileNav(!toggleMobileNav)}>
              <img src={internal_sift ? ts_logo_white : ts_logo} />
            </Button>
          )}
        </div>

        {internal_sift && (
          <section className="portal-navigation">
            <NavLink
              className={({ isActive, isPending }) => (isPending ? null : isActive ? "active-link" : null)}
              exact="true"
              to="/sift"
              title="Ground Mount"
              onClick={(e) => {
                // console.log("nav to portal");
              }}
            >
              <img src={panelIcon} alt="" />
              Ground
            </NavLink>
            <NavLink className={({ isActive, isPending }) => (isPending ? null : isActive ? "active-link" : null)} exact="true" to="/canopy" title="Canopy">
              <CarOutlined style={{ fontSize: "30px" }} />
              Canopy
            </NavLink>
          </section>
        )}

        <div className="mobile-background"></div>

        <div className={screenWidth <= 768 ? (toggleMobileNav ? "nav-links open" : "nav-links closed") : "nav-links"}>
          {system_alerts.poll_message.length > 0 && <Alert />}
          {is_authenticated && (
            <>
              <Tooltip placement="right" title="Help">
                <button id="help-tip" style={{ border: "2px solid transparent" }} onClick={() => handleMenuClick("help")}>
                  <QuestionCircleOutlined />
                </button>
              </Tooltip>

              <Tooltip placement="right" title="Send Bug Report">
                <button id="bug-tip" style={{ border: "2px solid transparent" }} onClick={() => Intercom("show")}>
                  <BugOutlined />
                </button>
              </Tooltip>

              {/* Removed Patch notes button
          <Tooltip placement="right" title="Patch Notes">
            <button onClick={() => handleMenuClick("patch")}>
              <DiffOutlined />
            </button>
          </Tooltip> */}

              {/* <Tooltip placement="right" title="See Pricing">
            <button onClick={() => dispatch(accountActions.toggleFunnelVis(true))}>
              <DollarCircleOutlined />
            </button>
          </Tooltip> */}

              <Tooltip placement="right" title="Account">
                <button onClick={() => handleMenuClick("account")}>
                  <UserOutlined />
                </button>
              </Tooltip>

              <Tooltip placement="right" title="Logout">
                <Popconfirm title="Logout?" onConfirm={(event) => handleButtonClick(event)} okText="Yes" cancelText="No">
                  <button>
                    <LogoutOutlined />
                  </button>
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </div>
      </Nav>

      <BugReport />
      <Funnel />

      <HelpDrawer />

      <Account />
    </Fragment>
  );
};

Navigation.propTypes = {
  history: PropTypes.object,
};

export default Navigation;
