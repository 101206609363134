import React, { useRef, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { siftActions, alertActions, handleAlert } from "../../components/Redux/";

// components
import { Project } from "../../components";

// css
import "./sift.css";
import "react-datasheet/lib/react-datasheet.css";

import { PageLayout } from "./StyledComponents";
import Navigation from "./Navigation";

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const SIFT = (props) => {
  const alerts = useSelector((state) => state.alert);
  // const isAuthed = useSelector((state) => isAuthenticated(state));
  // const { command } = props.match.params;
  // const { search } = props.location;

  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts.alert.length > 0) {
      let cur_alert = alerts.alert[0];
      handleAlert(cur_alert);
      dispatch(alertActions.clear([]));
    }
  }, [alerts]);

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 400 milliseconds
      timeoutId = setTimeout(() => dispatch(siftActions.updateScreenWidth(getWidth())), 400);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  // run one time when component mounts
  useEffect(() => {
    dispatch(siftActions.updateScreenWidth(getWidth()));
  }, []);

  return (
    <PageLayout>
      {/* column 1 */}
      <Navigation />

      {/* column 2+3 */}
      <Project />

      {/* global modal components */}
      {/* {isAuthed && <Tutorial />} */}

      {/* <BugReport />

      <HelpDrawer />

      <Account /> */}

      {/* <Docs /> */}

      {/* <ImportToolModal />

      <ErrorPopup /> */}

      {/* not sure where to put the below Alert yet.. */}
      {/* {this.props.user && this.props.user.free_trial_status == 1 &&
          <div className="right">
            <Alert
              className="sift-alert free-trial"
              message={<span>SIFT Free Trial expires: {this.props.user.free_trial_end_dt}</span>}
              type="info"
              showIcon
            />
          </div>
        } */}
    </PageLayout>
  );
};
