import React, { useState, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, inputsActions } from "../../../Redux";

import { LeafletControl } from "../LeafletControl";

// antd
import { Menu } from "antd";
import { EditOutlined, ExportOutlined, GatewayOutlined, BuildOutlined, FontColorsOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import L from "leaflet";

const ContextMenu = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);
  const site_features = useSelector((state) => state.inputs.site_features);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);

  const show_azi = useSelector((state) => state.inputs.uiState.show_azi);
  const show_dims = useSelector((state) => state.inputs.uiState.show_dims);
  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);

  const [menu_visible, set_menu_visible] = useState(false);
  const [menu_features, set_menu_features] = useState([]);
  const [menu_x, set_menu_x] = useState(null);
  const [menu_y, set_menu_y] = useState(null);
  const [menu_latlng, set_menu_latlng] = useState(null);
  const [azi_visible, set_azi_visible] = useState(false);
  const [dims_visible, set_dims_visible] = useState(false);

  const site_features_ref = useRef();

  useEffect(() => {
    map.on("contextmenu", (e) => onContextMenu(e), map);
    map.on(
      "click",
      (e) => {
        if (e.originalEvent.which == 1) {
          set_menu_visible(false);
        }
      },
      map
    );
    return () => {
      map.off("contextmenu");
      map.off("click");
    };
  }, []);

  useEffect(() => {
    site_features_ref.current = site_features;
  }, [site_features]);

  const onContextMenu = (e) => {
    if (show_live_report) {
      return;
    }
    let map = document.getElementById("map");
    let menuHeight = 100;
    let menuWidth = 140;
    let latlng;

    if (e.originalEvent.which == 3) {
      let openSunfigMenu = false;
      let clickedPolygon = false;

      if (map.nodeName === "DIV") {
        if (map.className.includes("leaflet")) {
          openSunfigMenu = true;
        }
      }

      if (e.originalEvent.srcElement.localName === "path") {
        if (e.originalEvent.srcElement.outerHTML.includes("leaflet")) {
          openSunfigMenu = true;
          clickedPolygon = true;
        }
      }

      if (openSunfigMenu) {
        if (clickedPolygon) {
          menuHeight = 125;
          // dispatch(siftActions.selectFeatureById(undefined));
          // get latlng of mouse click
          latlng = e.latlng;
          // turn into bounds
          let clickBounds = L.latLngBounds(latlng, latlng);
          let intersectingFeatures = [];

          Object.values(site_features_ref.current).forEach((feature) => {
            let featureBounds = getLayer(feature.properties.index).getBounds();
            if (featureBounds.contains(clickBounds)) {
              intersectingFeatures.push(feature);
            }
          });

          set_menu_features(intersectingFeatures);
        } else {
          set_menu_features([]);
        }

        // e.view.L.DomEvent.stopPropagation(e);
        // e.preventDefault();

        let mapWidth = map.clientWidth;
        let mapHeight = map.clientHeight;
        let menuXPos = e.containerPoint.x;
        let menuYPos = e.containerPoint.y;

        if (menuYPos + menuHeight > mapHeight) {
          menuYPos = menuYPos - menuHeight;
        }

        if (menuXPos + menuWidth > mapWidth) {
          menuXPos = menuXPos - menuWidth;
        }

        set_menu_x(menuXPos);
        set_menu_y(menuYPos);
        set_menu_latlng(latlng);
        set_menu_visible(!menu_visible);

        return false;
      }
    }
    return false;
  };

  const _highlightLayer = (feature) => {
    let layer = getLayer(feature.properties.index);
    if (layer) {
      if (layer.feature.geometry.type == "Polygon") {
        layer.setStyle({ weight: 3, fillOpacity: 0.5, fillColor: "var(--primary-brand-color)" });
      } else {
        layer.setStyle({ weight: 3, color: "var(--primary-brand-color)" });
      }
    }
  };

  const _unhighlightLayer = (feature = undefined) => {
    if (feature) {
      let layer = getLayer(feature.properties.index);
      if (layer) {
        layer.setStyle({ weight: 1, fillOpacity: 0.01, fillColor: "#ffffff" });
      }
    }
  };

  const handleFeatureClicked = (id) => {
    dispatch(inputsActions.update_site_input("selectedFeatureId", id));
    // dispatch(siftActions.selectFeatureById(id));
  };

  const handleShowOffset = async (id) => {
    await dispatch(inputsActions.update_site_input("selectedFeatureId", id));

    map.fire("toggleOffset", { id, selected_from: "context" });

    set_menu_visible(false);
  };
  const updateIdentity = (identity, feature) => {
    if (feature) {
      feature.properties.identity = identity;

      if (identity == 0) feature.properties.active = !feature.properties.active;
      else feature.properties.active = true;
      //   let layer = this.getLayer(feature.properties.index);
      //   layer?.setStyle({ color: colors[identity] });
      dispatch(siftActions.updateFeature(feature));
      dispatch(inputsActions.update_site_input("edit_site_feature", feature));
      set_menu_visible(false);
    }
  };

  const toggleAziFromContext = () => {
    map.fire("toggleAzi");

    let checked = !show_azi;
    dispatch(inputsActions.update_ui_state("show_azi", checked));
    if (show_dims) dispatch(inputsActions.update_ui_state("show_dims", false));
    if (checked) {
      L.DomUtil.addClass(document.body, "azimuth-mode");
      L.DomUtil.removeClass(document.body, "dimension-mode");
    } else {
      L.DomUtil.removeClass(document.body, "azimuth-mode");
    }

    set_menu_visible(false);
  };
  const toggleDimsFromContext = () => {
    map.fire("toggleDims");

    let checked = !show_dims;
    dispatch(inputsActions.update_ui_state("show_dims", checked));
    if (show_azi) dispatch(inputsActions.update_ui_state("show_azi", false));

    if (checked) {
      L.DomUtil.addClass(document.body, "dimension-mode");
      L.DomUtil.removeClass(document.body, "azimuth-mode");
    } else {
      L.DomUtil.removeClass(document.body, "dimension-mode");
    }

    set_menu_visible(false);
  };

  const handleDeleteFeature = (id) => {
    dispatch(inputsActions.update_site_input("remove_site_feature", id));
    set_menu_visible(false);
  };

  const getLayer = (id) => {
    let temp_layer;
    map.eachLayer((layer) => {
      if (layer.feature && layer.feature.properties.index == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  const handleDrawTool = () => {
    if (is_drawing) {
      map.fire("cancelDraw");
    } else {
      map.fire("startDrawing");
    }
    set_menu_visible(false);
  };

  return (
    <LeafletControl position="controlmenu">
      {menu_visible && (
        <section style={{ position: "absolute", left: menu_x, top: menu_y, opacity: 1, background: "#fff", cursor: "pointer" }}>
          <Menu defaultSelectedKeys={[]} defaultOpenKeys={[]} mode="vertical" theme="light" className="map-contextMenu">
            <Menu.Item
              key="3"
              onClick={() => {
                handleDrawTool();
              }}
            >
              <EditOutlined />
              <span>{is_drawing ? "Cancel Draw" : "Draw"}</span>
            </Menu.Item>

            {/* {generated && (
              <Menu.Item
                key="8"
                onClick={() => {
                  // this.handleToolbarPressed(8);
                }}
              >
                <section className="layers_menu_item" style={{ display: "flex", alignItems: "center" }}>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="https://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.25177 0.182154L12.485 3.08952C13.1717 3.47098 13.1717 4.32902 12.485 4.71048L11.6039 5.2L12.485 5.68952C13.1717 6.07098 13.1717 6.92903 12.485 7.31049L11.6039 7.8L12.485 8.28952C13.1717 8.67098 13.1717 9.52903 12.485 9.91049L7.25177 12.8179C6.8146 13.0607 6.1854 13.0607 5.74823 12.8179L0.514973 9.91049C-0.171658 9.52903 -0.171658 8.67098 0.514973 8.28952L1.3961 7.8L0.514973 7.31049C-0.171658 6.92903 -0.171658 6.07098 0.514973 5.68952L1.39611 5.2L0.514973 4.71048C-0.171658 4.32902 -0.171658 3.47098 0.514973 3.08952L5.74823 0.182154C6.1854 -0.0607179 6.8146 -0.0607179 7.25177 0.182154ZM7.25177 7.61785L10.2655 5.94358L11.267 6.5L10.2655 7.05643L8.92703 7.8L6.62044 9.08145C6.5759 9.10619 6.4241 9.10619 6.37956 9.08145L4.07297 7.8L2.73454 7.05643L1.73297 6.5L2.73454 5.94358L5.74823 7.61785C6.1854 7.86072 6.8146 7.86072 7.25177 7.61785ZM2.73454 8.54358L1.73297 9.10001L6.37956 11.6814C6.4241 11.7062 6.5759 11.7062 6.62044 11.6814L11.267 9.10001L10.2655 8.54358L7.25177 10.2179C6.8146 10.4607 6.1854 10.4607 5.74823 10.2179L2.73454 8.54358ZM6.37958 1.31856C6.42412 1.29381 6.57592 1.29381 6.62046 1.31856L11.2671 3.9L6.62046 6.48144C6.57592 6.50619 6.42412 6.50619 6.37958 6.48144L1.73299 3.9L6.37958 1.31856Z"
                      fill="#333300"
                    />
                  </svg>
                  <span style={{ marginLeft: 12 }}>Layer Menu</span>
                </section>
              </Menu.Item>
            )} */}

            {generated && (
              <Menu.Item
                key="toggle_layout"
                style={{ textOverflow: "unset" }}
                onClick={() => {
                  dispatch(inputsActions.update_ui_state("layout_visible", layout_visible ? 0 : 1));
                }}
              >
                {layout_visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                <span>{`${layout_visible ? "Hide Layout " : "Show Layout "}`}</span>
              </Menu.Item>
            )}

            <Menu.Item
              key="1"
              style={{ textOverflow: "unset" }}
              onClick={() => {
                toggleDimsFromContext();
              }}
            >
              <BuildOutlined />
              <span>{`Toggle Dims ${show_dims ? "Off" : "On"}`}</span>
            </Menu.Item>

            <Menu.Item
              key="2"
              style={{ textOverflow: "unset" }}
              onClick={() => {
                toggleAziFromContext();
              }}
            >
              <FontColorsOutlined />
              <span>{`Toggle Azi ${show_azi ? "Off" : "On"}`}</span>
            </Menu.Item>

            <Menu.SubMenu
              key="KMZ"
              title={
                <span>
                  <ExportOutlined />
                  <span>Export Map</span>
                </span>
              }
            >
              <Menu.Item
                key="KML"
                disabled={!plan_restrictions?.can_kmz}
                onClick={() => {
                  map.fire("exportKMZ");
                }}
              >
                Export to KMZ
              </Menu.Item>

              <Menu.Item
                key="DXF"
                disabled={!plan_restrictions?.can_dxf}
                onClick={() => {
                  map.fire("exportCAD");
                }}
              >
                Export to CAD
              </Menu.Item>
            </Menu.SubMenu>

            {menu_features.map((feature, index) => {
              return (
                <Menu.SubMenu
                  onMouseEnter={() => _highlightLayer(feature)}
                  onMouseLeave={() => _unhighlightLayer(feature)}
                  onTitleClick={() => {
                    handleFeatureClicked(feature.properties.index);
                  }}
                  key={feature.properties.index}
                  title={
                    <span>
                      <GatewayOutlined />
                      <span>{feature.geometry.type == "Polygon" ? `Polygon ${feature.properties.index.slice(0, 3)}` : "Polyline"}</span>
                    </span>
                  }
                >
                  <Menu.Item
                    key={`offset_${feature.properties.index}`}
                    disabled={feature.geometry.type == "LineString"}
                    onClick={() => {
                      handleShowOffset(feature.properties.index);
                    }}
                  >
                    Offset Polygon
                  </Menu.Item>

                  <Menu.Item
                    key={`bound_${feature.properties.index}`}
                    disabled={feature.geometry.type == "LineString"}
                    onClick={(e) => {
                      updateIdentity(1, feature);
                    }}
                  >
                    Set as Boundary
                  </Menu.Item>
                  <Menu.Item
                    key={`exclusion_${feature.properties.index}`}
                    disabled={feature.geometry.type == "LineString"}
                    onClick={(e) => {
                      updateIdentity(2, feature);
                    }}
                  >
                    Set as Exclusion
                  </Menu.Item>
                  <Menu.Item
                    key={`inactive_${feature.properties.index}`}
                    disabled={feature.geometry.type == "LineString"}
                    onClick={(e) => {
                      updateIdentity(0, feature);
                    }}
                  >
                    Set as Inactive
                  </Menu.Item>
                  <Menu.Item
                    key={`delete_${feature.properties.index}`}
                    onClick={() => {
                      handleDeleteFeature(feature.properties.index);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu.SubMenu>
              );
            })}
          </Menu>
        </section>
      )}
    </LeafletControl>
  );
};

export { ContextMenu };
