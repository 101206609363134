import styled from "styled-components";

const TopoScaleWrap = styled.section`
  position: absolute;
  left: 80%;
  top: 20%;
  width: 80px;
  z-index: 500;
  background-color: #fff;
  text-align: center;
  border: 1px solid #d3d3d3;
  cursor: move;

  .topo-scale-box-header {
    padding: 5px;
    cursor: move;
    z-index: 10;
    background-color: var(--primary-brand-color);
    color: #fff;
  }
`;

export { TopoScaleWrap };
