import React, { useState } from "react";
import { TextAreaWrapper, StyledTextArea } from "./styles";

const TextArea = ({ id, rows, style, onChange, placeholder, value, maxLength, width, onPressEnter, defaultValue }) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    onChange(event);
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && onPressEnter) {
      onPressEnter(event);
    }
  };
  return (
    <TextAreaWrapper width={width}>
      <StyledTextArea id={id} rows={rows} style={style} onChange={handleChange} placeholder={placeholder} value={currentValue} maxLength={maxLength} onKeyDown={handleKeyDown}></StyledTextArea>
    </TextAreaWrapper>
  );
};

export { TextArea };
