// prettier-ignore
import { checkCoordSystemBounds, checkSiteLocation } from "./";
import { ModulesDD, InvertersDD, RackingDD, PerformanceDD, WeatherDD, FinanceDD, ConfigDefault } from "../../InputPanel";
import { getBounds } from "../_helpers";
import * as turf from "@turf/turf";
import _ from "lodash";
//import { simple_inverter } from "../_reducers";

// const tabDefaults = {
//   module: JSON.parse(JSON.stringify(ModulesDD["364aa9e4cee04535aed7806302f3f652"])),
//   inverter: JSON.parse(JSON.stringify(InvertersDD["0f14d6b175444d6699dfe4d69f32c243"])),
//   racking: JSON.parse(JSON.stringify(RackingDD["49aa35992cf4480e9a2f1152c43edcda"])),
//   weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
//   performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
//   finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
//   config: JSON.parse(JSON.stringify(ConfigDefault)),
// };

export function validateInputs(inputs) {
  let errors = [];
  let warnings = [];

  let boundary_site_features = Object.values(inputs.site_features).filter((feature) => feature.properties.identity == 1);
  let exclusion_site_features = Object.values(inputs.site_features).filter((feature) => feature.properties.identity == 2);
  // this is used to check for min/max GCR errors if the racking is EWF. We need the INITIAL value as later in validation it gets reasigned back to 1 and dual_tilt is enabled.
  let initial_track_mode = inputs.track_mode;

  // map feature validation
  if (inputs.site_features.length === 0 && boundary_site_features) {
    errors.push(
      "SIFT requires at least one project boundary to run. Use the draw tool to define your project boundaries, or drop a KMZ into the map. Be sure to set at least one polygon to a boundary (red). See help for additional information and tutorials."
    );
  } else if (inputs.site_features.findIndex((poly) => poly.properties.identity == 1) === -1) {
    errors.push(
      "SIFT requires at least one project boundary to run. Use the draw tool to define your project boundaries, or drop a KMZ into the map. Be sure to set at least one polygon to a boundary (red). See help for additional information and tutorials."
    );
  }

  // SIZE LIMIT
  // let areaLimit = inputs.currentPlan == 3 ? 2000 : 100;
  // if (inputs.totalArea > areaLimit) {
  //   if (inputs.currentPlan == 3) {
  //     errors.push(
  //       "Projects in SIFT are limited by boundary area. Your plan allows boundaries up to 2000 hectares(~5000 acres, 30000亩). This is enough area for a 1000MWac single-axis project with common wattage modules and typical DC:AC ratios."
  //     );
  //   } else {
  //     errors.push(
  //       "Projects in SIFT are limited by boundary area. This tier allows boundaries up to 100 hectares(~250 acres, 1500亩). This is enough area for a 50MWac single-axis project with common wattage moldules and typical DC:AC ratios."
  //     );
  //   }
  //   // errors.push("The total boundary area for this project exceeds your limit. Total boundary area is displayed at the bottom left of the map. Your boundary limit can be found and upgraded in the Account tab. PRO accounts can operate on boundaries up to 20 sq km (approximately 2500 acres).")
  // }

  // POLYGON CHECKS
  if (inputs.site_features.length >= 2 && exclusion_site_features.length >= 1) {
    // assume no boundary is buildable
    let no_buildable_area = true;
    // loop through each boundary and check to see if it's inside an exclusion
    Object.values(boundary_site_features).map((boundary) => {
      Object.values(exclusion_site_features).map((exclusion) => {
        // If there is a boundary located outside of any present exclusion, there are buildable areas. change no_buildable_area to false
        if (!turf.booleanContains(exclusion, boundary)) {
          no_buildable_area = false;
        }
      });
    });
    // if there are no boundaries located ouside of any of the present exclusions, there are no buildable areas, display error
    if (no_buildable_area) {
      errors.push("There is no buildable area (Boundaries - Exclusions = Buildable). Check your project polygons. Polygons set as boundaries are red, polygons set as exclusions are yellow.");
    }
  }

  if (!isFinite(inputs.latitude) || !isFinite(inputs.longitude) || isNaN(inputs.latitude) || isNaN(inputs.longitude)) {
    errors.push(`Latitude or Longitude appear to be incorrect. Please navigate to the Weather Tab and check your inputs. You can click "Recenter Pin" to refresh the location`);
  }

  // COORD SYSTEM VALIDATION
  if (inputs.site_features.length > 0 && inputs.coord_system_bbox) {
    let coordSystemCheck = checkCoordSystemBounds(inputs.site_features, inputs.coord_system_bbox);
    if (coordSystemCheck === false) {
      errors.push(
        `Your project boundary exists outside of the selected layout coordinate system and would adversely affect your layout results. Please select a more specific layout coordinate system, or select "WGS84" from the Layout Coordinate System dropdown in the Layout menu.`
      );
    }

    if (inputs.weather !== undefined || inputs.latitude !== 0) {
      console.log("timezone", inputs.timezone);
      let _boundaries = inputs.site_features.filter((poly) => poly.properties.identity == 1);
      let weather_center = [inputs.latitude, inputs.longitude];
      if (!checkSiteLocation(_boundaries, weather_center)) {
        // errors.push("Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter site location in weather tab.");
        errors.push("show_recenter_pin_error");
        // errors.push(`Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter pin in weather tab or click this link → ${ReactDOM.render(<RecenterPinButton />)}`);
      }
    }
  }

  //  LAYOUT VALIDATION
  // ROADS
  if (inputs.road_spacing_option > 0) {
    if (inputs.road_width < 2 || inputs.road_width > 6) {
      errors.push("Invalid Road Width. Must be between 2-6 . Please adjust your inputs in the Layout Menu");
    }

    if (inputs.road_to_rack < 1 || inputs.road_to_rack > 10) {
      errors.push("Invalid Road to Rack. Must be between 1-10 . Please adjust your inputs in the Layout Menu");
    }
  }
  // Limits
  if (inputs.do_grid_poi_lim === 1 && (inputs.ac_grid_poi_lim == null || inputs.ac_grid_poi_lim < 0)) {
    errors.push("POI limit is enabled so value should be greater than zero");
  }
  if (inputs.do_inverter_limit_lock === 1 && (inputs.inverter_limit == null || inputs.inverter_limit < 0)) {
    errors.push("Fixed Inverter Qty limit is enabled so value should be greater than zero");
  }

  // INVETERS & GROUPING
  if (inputs.inverter_grouping > 0) {
    if (inputs.inverter_grouping === 1) {
      inputs.inverter_grouping = 0;
    }
    if (inputs.do_inv_spacing === 1 && (inputs.inverter_per_cut < 1 || inputs.inverter_per_cut > 10)) {
      errors.push("Invalid Draw Inverters. Must be between 1-10 . Please adjust your inputs in the Layout Menu");
    }
    if (inputs.do_inv_spacing === 1 && (inputs.inv_pad_x < 1 || inputs.inv_pad_x > 10)) {
      errors.push("Invalid Pad X Must be between 1-10. Please adjust your inputs in the Layout Menu");
    }

    if (inputs.do_inv_spacing === 1 && (inputs.inv_pad_Y < 1 || inputs.inv_pad_Y > 4)) {
      errors.push("Invalid Pad Y Must be between 1-4. Please adjust your inputs in the Layout Menu");
    }

    if (inputs.do_inv_spacing === 1 && (inputs.road_to_rack_inv < inputs.inv_pad_y || inputs.road_to_rack_inv < 1 || inputs.road_to_rack_inv > 10)) {
      errors.push("Invalid Road to Rack, Inv. Must be betweeen 1-10 but >= Pad Y. Please adjust your inputs in the Layout Menu");
    }
  }

  // MODULE  VALIDATION
  if (inputs.mod_rating <= 0 || isNaN(inputs.mod_rating)) {
    errors.push("Invalid module rating. Select or import a module in the Module Tab.");
  }
  inputs.technology = parseInt(inputs.technology);
  inputs.mod_rating = parseFloat(inputs.mod_rating);
  inputs.mlm_D2MuTau = parseFloat(inputs.mlm_D2MuTau);
  inputs.mlm_E_g = parseFloat(inputs.mlm_E_g);
  inputs.mlm_I_mp_ref = parseFloat(inputs.mlm_I_mp_ref);
  inputs.mlm_I_sc_ref = parseFloat(inputs.mlm_I_sc_ref);
  inputs.mlm_Length = parseFloat(inputs.mlm_Length);
  inputs.mlm_N_diodes = parseFloat(inputs.mlm_N_diodes);
  inputs.mlm_N_parallel = parseFloat(inputs.mlm_N_parallel);
  inputs.mlm_N_series = parseFloat(inputs.mlm_N_series);
  inputs.mlm_R_s = parseFloat(inputs.mlm_R_s);
  inputs.mlm_R_sh0 = parseFloat(inputs.mlm_R_sh0);
  inputs.mlm_R_shexp = parseFloat(inputs.mlm_R_shexp);
  inputs.mlm_R_shref = parseFloat(inputs.mlm_R_shref);
  inputs.mlm_S_ref = parseFloat(inputs.mlm_S_ref);
  inputs.mlm_T_c_fa_alpha = parseFloat(inputs.mlm_T_c_fa_alpha);
  inputs.mlm_T_ref = parseFloat(inputs.mlm_T_ref);
  inputs.mlm_V_mp_ref = parseFloat(inputs.mlm_V_mp_ref);
  inputs.mlm_V_oc_ref = parseFloat(inputs.mlm_V_oc_ref);
  inputs.mlm_Width = parseFloat(inputs.mlm_Width);
  inputs.mlm_alpha_isc = parseFloat(inputs.mlm_alpha_isc);
  inputs.mlm_beta_voc_spec = parseFloat(inputs.mlm_beta_voc_spec);
  inputs.mlm_mu_n = parseFloat(inputs.mlm_mu_n);
  inputs.mlm_n_0 = parseFloat(inputs.mlm_n_0);

  inputs.mod_width = parseFloat(inputs.mod_width);
  inputs.mod_height = parseFloat(inputs.mod_height);
  inputs.mod_area = _.round(inputs.mod_width * inputs.mod_height, 2);

  let combine_iam = [];
  inputs.module_iam_ang.forEach((ang, index) => {
    inputs.module_iam_ang[index] = parseFloat(ang);
  });
  inputs.module_iam_eff.forEach((eff, index) => {
    inputs.module_iam_eff[index] = parseFloat(eff);

    combine_iam.push([inputs.module_iam_ang[index], inputs.module_iam_eff[index]]);
  });
  // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
  combine_iam.sort(function (a, b) {
    if (a[0] === b[0]) {
      return a[1] - b[1];
    }
    return a[0] - b[0];
  });
  let stringArray = combine_iam.map(JSON.stringify);
  let uniqueStringArray = new Set(stringArray);
  inputs.module_iam_ang = [];
  inputs.module_iam_eff = [];
  Array.from(uniqueStringArray, JSON.parse).forEach((row) => {
    inputs.module_iam_ang.push(row[0]);
    inputs.module_iam_eff.push(row[1]);
  });

  // field for bifacial patch
  inputs.bi_bifaciality = parseFloat(inputs.bi_bifaciality);
  inputs.mod_area = parseFloat(inputs.mod_area);

  // INVERTER VALIDATION
  if (inputs.simple_inverter === 1) {
    inputs.simple_inverter_dcac = parseFloat(inputs.simple_inverter_dcac);
  }

  if (inputs.inv_rating <= 0 || isNaN(inputs.inv_rating)) {
    errors.push("Invalid Inverter rating. Select or import an inverter in the Inverter Tab.");
  }
  if (inputs.maxEff <= 0 || inputs.maxEff > 100 || isNaN(inputs.maxEff)) {
    errors.push("Invalid Inverter Max Efficiency. Must be >0 and <=100.");
  }

  let combine_temp_derate = [];
  inputs.derate_maxoutput.forEach((output, index) => {
    inputs.derate_maxoutput[index] = parseFloat(output);
  });
  inputs.derate_temps.forEach((temp, index) => {
    inputs.derate_temps[index] = parseFloat(temp);

    combine_temp_derate.push([inputs.derate_maxoutput[index], inputs.derate_temps[index]]);
  });

  let stringTemps = combine_temp_derate.map(JSON.stringify);
  let uniqueStringTempArray = new Set(stringTemps);
  inputs.derate_maxoutput = [];
  inputs.derate_temps = [];
  Array.from(uniqueStringTempArray, JSON.parse).forEach((row) => {
    inputs.derate_maxoutput.push(row[0]);
    inputs.derate_temps.push(row[1]);
  });

  inputs.inv_pd_eff_pout.forEach((pout, index) => {
    inputs.inv_pd_eff_pout[index] = parseFloat(pout);
  });
  inputs.inv_pd_efficiency.forEach((eff, index) => {
    inputs.inv_pd_efficiency[index] = parseFloat(eff);
  });

  inputs.inv_pd_vdcmax = parseFloat(inputs.inv_pd_vdcmax);
  inputs.inv_pd_pacokw = parseFloat(inputs.inv_pd_pacokw);
  inputs.inv_pd_pnt = parseFloat(inputs.inv_pd_pnt);
  inputs.inv_rating = parseFloat(inputs.inv_rating);
  inputs.maxEff = parseFloat(inputs.maxEff);
  inputs.mppt_hi_inverter = parseFloat(inputs.mppt_hi_inverter);
  inputs.mppt_low_inverter = parseFloat(inputs.mppt_low_inverter);
  inputs.inv_pnom = parseFloat(inputs.inv_pnom);
  inputs.pthresh = 0;
  // inputs.inverter.inv_pd_pdco = parseFloat(inputs.inverter.inv_pd_pdco);

  // RACKING VALIDATION
  //
  if (inputs.racking_errors && inputs.racking_errors?.show_in_validation) {
    Object.values(inputs.racking_errors).map((error) => {
      if (!_.isUndefined(error) && typeof error != "boolean") {
        errors.push(error);
      }
    });
  }

  inputs.racks.forEach((rack, index) => {
    if (isNaN(parseFloat(rack.xdim)) || isNaN(parseFloat(rack.ydim)) || isNaN(parseFloat(rack.module))) {
      inputs.racks[index].active = 0;
      inputs.racks[index].xdim = 0;
      inputs.racks[index].ydim = 0;
      inputs.racks[index].module = 0;
    } else {
      inputs.racks[index].xdim = parseFloat(rack.xdim);
      inputs.racks[index].ydim = parseFloat(rack.ydim);
      inputs.racks[index].module = parseFloat(rack.module);
    }
  });

  // Rack A check
  if (inputs.racks[0].active === 1) {
    if (inputs.racks[0].module < inputs.racks[1].module && inputs.racks[1].active === 1) errors.push("Rack A is smaller than Rack B. Navigate to the Racking Tab and adjust the Racks.");
    if (inputs.racks[0].module < inputs.racks[2].module && inputs.racks[2].active === 1) errors.push("Rack A is smaller than Rack C. Navigate to the Racking Tab and adjust the Racks.");
  }
  // Rack B check
  if (inputs.racks[1].active === 1) {
    if (inputs.racks[1].module < inputs.racks[2].module && inputs.racks[2].active === 1) errors.push("Rack B is smaller than Rack C. Navigate to the Racking Tab and adjust the Racks.");
  }

  for (var i = 0; i < inputs.racks.length; i++) {
    //
    inputs.racks[i];
  }

  if (inputs.track_mode === 2) {
    inputs.track_mode = 1;
    inputs.do_dual_tilt = 1;
    inputs.tilts = [inputs.tilt_min];
  }

  // check for at least 1 active rack
  if (inputs.racks.findIndex((r) => r.active == true) === -1) {
    errors.push("There are no active Racking dimesions. Navigate to the Racking Tab and mark at least one Type (A,B,or C) as Active.");
  }
  if (inputs.track_mode === 0) {
    if (inputs.tilts.length === 0) {
      errors.push("Invalid Tilt Inputs in Racking tab");
    }
    if (inputs.tilts.length > 20) {
      errors.push("Invalid tilt range. Total possible tilts within range exceeds 20. Navigate to the Racking Tab and adjust your tilt range.");
    }
  }

  inputs.track_mode = parseInt(inputs.track_mode);
  inputs.en_backtrack = parseInt(inputs.en_backtrack);
  inputs.shade_mode = parseInt(inputs.shade_mode);

  inputs.gap = parseFloat(inputs.gap);
  inputs.rlim = parseFloat(inputs.rlim);
  inputs.Fshd_StringSteps = parseFloat(inputs.Fshd_StringSteps);
  inputs.Fshd_Celltrav = parseFloat(inputs.Fshd_Celltrav);
  // 2 new fields for Bifacial patch
  inputs.bi_groundClearanceHeight = parseFloat(inputs.bi_groundClearanceHeight);
  inputs.bi_transmissionFactor = parseFloat(inputs.bi_transmissionFactor);
  inputs.bi_structureShadeFactor = parseFloat(inputs.bi_structureShadeFactor);

  // TOPO INPUTS
  if (inputs.topo_bbox && inputs.topo_id) {
    let fixed_bbox = [inputs.topo_bbox[0] - 0.0009, inputs.topo_bbox[1] - 0.0009, inputs.topo_bbox[2] + 0.0009, inputs.topo_bbox[3] + 0.0009];
    let topo_bounding_box = turf.bboxPolygon(fixed_bbox);
    let feature_bounding_box = turf.bboxPolygon(getBounds(boundary_site_features));
    if (!turf.booleanContains(topo_bounding_box, feature_bounding_box)) {
      errors.push(
        "There are polygons classed as Boundaries (red) located outside of the topography area. Please delete and re-import Topography or remove boundaries that are outside of the topography area. If the problem persists, please submit a bug report."
      );
    }
  }

  inputs.rack_grade_limit = parseInt(inputs.rack_grade_limit);

  if (inputs.shade_mode === 1 && inputs.en_backtrack === 0) {
    if (inputs.Fshd_StringSteps <= 0) {
      errors.push("String Steps in Racking tab must be greater than 0.");
    }
    if (inputs.Fshd_Celltrav <= 0) {
      errors.push("Cell Transverse in Racking tab must be greater than 0.");
    }
  }

  if (inputs.config_spacing_toggle === "gcr") {
    if (initial_track_mode === 2) {
      if (inputs.gcrs[0] < 0.2) {
        errors.push("The GCR Min is invalid. Adjust value in the /config tab");
      }
      if (inputs.gcrs[inputs.gcrs.length - 1] > 0.5) {
        errors.push("The GCR Max is invalid. Adjust value in the /config tab");
      }
    } else {
      if (inputs.gcrs[0] < 0.2) {
        errors.push("The GCR Min is invalid. Adjust value in the /config tab");
      }
      if (inputs.gcrs[inputs.gcrs.length - 1] > 1.0) {
        errors.push("The GCR Max is invalid. Adjust value in the /config tab");
      }
    }

    if (inputs.simple_inverter !== 1 && inputs.gcrs.length * inputs.spis.length > inputs.worker_limit) {
      errors.push(
        "The number of workers required for this SIFT run are over your limit. Adjust the range or Increment fields in the /config tab. The number of workers and your limit are shown in the /config tab."
      );
    }
  }
  if (inputs.config_spacing_toggle === "pitch") {
    if (inputs.pitches[0] < inputs.pitch_min_limit) {
      errors.push("The Pitch Min is invalid. Adjust value in the /config tab");
    }
    if (inputs.pitches[inputs.pitches.length - 1] > inputs.pitch_max_limit) {
      errors.push("The Pitch Max is invalid. Adjust value in the /config tab");
    }

    if (inputs.en_mod_target !== 1 && inputs.pitches.length * inputs.spis.length > inputs.worker_limit) {
      errors.push(
        "The number of workers required for this SIFT run are over your limit. Adjust the range or Increment fields in the /config tab. The number of workers and your limit are shown in the /config tab."
      );
    }
  }

  if (inputs.simple_inverter !== 1 && inputs.spis[0] < inputs.spi_min_limit) {
    errors.push("The Strings Per Inverter Min is invalid. Adjust value in the /config tab");
  }
  if (inputs.simple_inverter !== 1 && inputs.spis[inputs.spis.length - 1] > inputs.spi_max_limit) {
    errors.push("The Strings Per Inverter Max is invalid. Adjust value in the /config tab");
  }

  if (inputs.simple_inverter === 1 && inputs.simple_inverter_dcac > 1.8) {
    errors.push("DC:AC is greater than 1.8. Adjust value in the /config tab");
  }
  if (inputs.simple_inverter === 1 && inputs.simple_inverter_dcac < 0.9) {
    errors.push("DC:AC is less than 0.9. Adjust value in the /config tab");
  }

  // let input_check = [
  //   // { key: "do_inverter_qty_lock", value: parseInt(inputs.config.do_inverter_qty_lock), isNaN: isNaN(parseInt(inputs.config.do_inverter_qty_lock)) },
  //   // { key: "inverter_qty", value: parseInt(inputs.config.inverter_qty), isNaN: isNaN(parseInt(inputs.config.inverter_qty)) },
  //   // { key: "do_inv_spacing", value: parseInt(inputs.config.do_inv_spacing), isNaN: isNaN(parseInt(inputs.config.do_inv_spacing)) },
  //   // { key: "inverter_per_cut", value: parseInt(inputs.config.inverter_per_cut), isNaN: isNaN(parseInt(inputs.config.inverter_per_cut)) },
  //   // { key: "layout_margin", value: parseFloat(inputs.config.layout_margin), isNaN: isNaN(parseFloat(inputs.config.layout_margin)) },
  //   // { key: "road_spacing_option", value: parseInt(inputs.config.road_spacing_option), isNaN: isNaN(parseInt(inputs.config.road_spacing_option)) },
  //   // { key: "road_spacing", value: parseFloat(inputs.config.road_spacing), isNaN: isNaN(parseFloat(inputs.config.road_spacing)) },
  //   // { key: "do_roads", value: parseInt(inputs.config.do_roads), isNaN: isNaN(parseInt(inputs.config.do_roads)) },
  //   // { key: "do_rack_align", value: parseInt(inputs.config.do_rack_align), isNaN: isNaN(parseInt(inputs.config.do_rack_align)) },
  //   // { key: "designer_margin", value: parseFloat(inputs.config.designer_margin), isNaN: isNaN(parseFloat(inputs.config.designer_margin)) },
  //   // { key: "azimuth", value: parseFloat(inputs.config.azimuth), isNaN: isNaN(parseFloat(inputs.config.azimuth)) },
  // ];

  // WEATHER VALIDATION
  if (inputs.weather === undefined || inputs.weather === 0) {
    // THIS WILL BE AN AUTO_GEN WEATHER RUN
    // errors.push("Input Validation Failed. No weather data loaded to the project. Navigate to the Weather tab and import weather information for this location.")
  }
  if (inputs.weather_summary) {
    let t_HorizGlobIrrad = 0;
    let t_HorizDiffIrrad = 0;

    for (var w in inputs.weather_summary[0]) {
      let row = inputs.weather_summary[0][w];
      t_HorizGlobIrrad += row["HorizGlobIrrad"];
      t_HorizDiffIrrad += row["HorizDiffIrrad"];
    }
    if (t_HorizGlobIrrad <= 0) {
      errors.push("Weather validation failed. Please check your inputs in the Weather Tab.");
    }

    if (t_HorizGlobIrrad <= 0 && t_HorizDiffIrrad <= 0) {
      errors.push("Input Validation Failed. No weather data loaded to the project. Navigate to the Weather tab and import weather information for this location.");
    }

    if (_.isNaN(inputs.timezone) || inputs.timezone > 12 || inputs.timezone < -12) {
      errors.push("Performance error. Please check the Timezone input in the weather tab.");
    }
  }

  // if (inputs.elevation == undefined || inputs.timezone == undefined) {
  //   errors.push("show_elevation_timezone_error");
  // }

  inputs.mod_per_string = parseFloat(inputs.mod_per_string);

  // SITE Inputs
  inputs.inverter_limit = parseInt(inputs.inverter_limit);
  inputs.ac_grid_poi_lim = parseFloat(inputs.ac_grid_poi_lim);
  inputs.sazm = parseFloat(inputs.sazm);

  inputs.road_spacing = parseInt(inputs.road_spacing);
  inputs.road_width = parseFloat(inputs.road_width);
  inputs.road_to_rack = parseFloat(inputs.road_to_rack);
  inputs.inv_pad_x = parseInt(inputs.inv_pad_x);
  inputs.inv_pad_y = parseInt(inputs.inv_pad_y);
  inputs.road_to_rack_inv = parseInt(inputs.road_to_rack_inv);
  inputs.designer_margin = parseInt(inputs.designer_margin);

  // PERFORMANCE VALIDATION
  // force convert all performance variables
  inputs.dc_degrade = parseFloat(inputs.dc_degrade);
  inputs.dc_thermal_Uc = parseFloat(inputs.dc_thermal_Uc);
  inputs.dc_thermal_Uv = parseFloat(inputs.dc_thermal_Uv);
  inputs.dc_module_quality_loss = parseFloat(inputs.dc_module_quality_loss);
  inputs.dc_module_lid_loss = parseFloat(inputs.dc_module_lid_loss);
  inputs.dc_module_mismatch = parseFloat(inputs.dc_module_mismatch);
  inputs.dc_strings_mismatch = parseFloat(inputs.dc_strings_mismatch);
  inputs.dc_wiring_loss_at_stc = parseFloat(inputs.dc_wiring_loss_at_stc);
  inputs.bi_back_mismatch = parseFloat(inputs.bi_back_mismatch);
  inputs.power_factor = parseFloat(inputs.power_factor);
  inputs.ac_aux_kw = parseFloat(inputs.ac_aux_kw);
  inputs.ac_wiring_loss_at_stc = parseFloat(inputs.ac_wiring_loss_at_stc);
  inputs.ac_transformer_loss_constant = parseFloat(inputs.ac_transformer_loss_constant);
  inputs.ac_transformer_loss_at_stc = parseFloat(inputs.ac_transformer_loss_at_stc);
  inputs.ac_MV_line_loss_stc = parseFloat(inputs.ac_MV_line_loss_stc);
  inputs.ac_hv_transformer_loss_at_stc = parseFloat(inputs.ac_hv_transformer_loss_at_stc);
  inputs.ac_transmission_loss = parseFloat(inputs.ac_transmission_loss);
  inputs.ac_other_loss = parseFloat(inputs.ac_other_loss);

  inputs.soiling_single = parseFloat(inputs.soiling_single);
  inputs.soiling.forEach((soil, index) => {
    if (inputs.do_monthly_losses === 1) {
      inputs.soiling[index] = parseFloat(soil);
    } else {
      inputs.soiling[index] = inputs.soiling_single;
    }
  });

  inputs.albedo_single = parseFloat(inputs.albedo_single);
  inputs.albedo.forEach((albedo, index) => {
    if (inputs.do_monthly_losses === 1) {
      inputs.albedo[index] = parseFloat(albedo);
    } else {
      inputs.albedo[index] = inputs.albedo_single;
    }
  });

  if (inputs.mod_per_string === undefined || inputs.mod_per_string <= 0) {
    errors.push("Invalid Modules per String. Review the Modules Per String input in the Racking Tab.");
  }

  // FINANCE VALIDATION
  if (inputs.do_finance === 1) {
    // console.log(inputs.finance)
    // _.isFinite()
    let finance_check = [
      { key: "analysis_period", value: parseInt(inputs.analysis_period), isNaN: isNaN(parseInt(inputs.analysis_period)) },
      { key: "discount_rate", value: parseFloat(inputs.discount_rate), isNaN: isNaN(parseFloat(inputs.discount_rate)) },

      { key: "dc_op_cost", value: parseFloat(inputs.dc_op_cost), isNaN: isNaN(parseFloat(inputs.dc_op_cost)) },
      { key: "ac_op_cost", value: parseFloat(inputs.ac_op_cost), isNaN: isNaN(parseFloat(inputs.ac_op_cost)) },
      { key: "footprint_op_cost", value: parseFloat(inputs.footprint_op_cost), isNaN: isNaN(parseFloat(inputs.footprint_op_cost)) },
      { key: "fixed_op_cost", value: parseFloat(inputs.fixed_op_cost), isNaN: isNaN(parseFloat(inputs.fixed_op_cost)) },
      { key: "lease_cost", value: parseFloat(inputs.lease_cost), isNaN: isNaN(parseFloat(inputs.lease_cost)) },
      { key: "escalation", value: parseFloat(inputs.escalation), isNaN: isNaN(parseFloat(inputs.escalation)) },
      // ...isNan(...customSchedule),
      { key: "ri_power", value: parseFloat(inputs.ri_power), isNaN: isNaN(parseFloat(inputs.ri_power)) },
      { key: "ri_escalation", value: parseFloat(inputs.ri_escalation), isNaN: isNaN(parseFloat(inputs.ri_escalation)) },
      { key: "itc_percent", value: parseFloat(inputs.itc_percent), isNaN: isNaN(parseFloat(inputs.itc_percent)) },
      { key: "debt_percent", value: parseFloat(inputs.debt_percent), isNaN: isNaN(parseFloat(inputs.debt_percent)) },
      { key: "debt_dscr", value: parseFloat(inputs.debt_dscr), isNaN: isNaN(parseFloat(inputs.debt_dscr)) },
      { key: "debt_interest", value: parseFloat(inputs.debt_interest), isNaN: isNaN(parseFloat(inputs.debt_interest)) },
      { key: "debt_tenor", value: parseFloat(inputs.debt_tenor), isNaN: isNaN(parseFloat(inputs.debt_tenor)) },
      { key: "dep_5yrSL", value: parseFloat(inputs.dep_5yrSL), isNaN: isNaN(parseFloat(inputs.dep_5yrSL)) },
      { key: "dep_15yrSL", value: parseFloat(inputs.dep_15yrSL), isNaN: isNaN(parseFloat(inputs.dep_15yrSL)) },
      { key: "dep_20yrSL", value: parseFloat(inputs.dep_20yrSL), isNaN: isNaN(parseFloat(inputs.dep_20yrSL)) },
      { key: "dep_30yrSL", value: parseFloat(inputs.dep_30yrSL), isNaN: isNaN(parseFloat(inputs.dep_30yrSL)) },
      { key: "dep_35yrSL", value: parseFloat(inputs.dep_35yrSL), isNaN: isNaN(parseFloat(inputs.dep_35yrSL)) },
      { key: "dep_39yrSL", value: parseFloat(inputs.dep_39yrSL), isNaN: isNaN(parseFloat(inputs.dep_39yrSL)) },
      { key: "dep_5yrMACRS", value: parseFloat(inputs.dep_5yrMACRS), isNaN: isNaN(parseFloat(inputs.dep_5yrMACRS)) },
      { key: "dep_15yrMACRS", value: parseFloat(inputs.dep_15yrMACRS), isNaN: isNaN(parseFloat(inputs.dep_15yrMACRS)) },
      { key: "state_taxes", value: parseFloat(inputs.state_taxes), isNaN: isNaN(parseFloat(inputs.state_taxes)) },
      { key: "federal_taxes", value: parseFloat(inputs.federal_taxes), isNaN: isNaN(parseFloat(inputs.federal_taxes)) },

      { key: "do_include_itc", value: parseInt(inputs.do_include_itc), isNaN: isNaN(parseInt(inputs.do_include_itc)) },
      { key: "do_include_ptc", value: parseInt(inputs.do_include_ptc), isNaN: isNaN(parseInt(inputs.do_include_ptc)) },
      { key: "ptc_value", value: parseFloat(inputs.ptc_value), isNaN: isNaN(parseFloat(inputs.ptc_value)) },

      { key: "do_ri_customSchedule", value: parseFloat(inputs.do_ri_customSchedule), isNaN: isNaN(parseFloat(inputs.do_ri_customSchedule)) },
    ];
    // toggle_finance_type: inputs.data.toggle_finance_type ? inputs.data.toggle_finance_type : tabDefaults.data.toggle_finance_type,
    if (inputs.toggle_finance_type === 0) {
      // summarized data
      finance_check.push({ key: "summarized_dc", value: parseFloat(inputs.summarized_dc), isNaN: isNaN(parseFloat(inputs.summarized_dc)) });
      finance_check.push({ key: "summarized_ac", value: parseFloat(inputs.summarized_ac), isNaN: isNaN(parseFloat(inputs.summarized_ac)) });
      // finance_check.push({ key: "summarized_dc_wiring_wp", value: parseFloat(inputs.summarized_dc_wiring_wp.total), isNaN: isNaN(parseFloat(inputs.summarized_dc_wiring_wp.total)) })
      // finance_check.push({ key: "summarized_dc_wiring_gcr", value: parseFloat(inputs.summarized_dc_wiring_gcr.total), isNaN: isNaN(parseFloat(inputs.summarized_dc_wiring_gcr.total)) })
      // finance_check.push({ key: "foot_print", value: parseFloat(inputs.foot_print), isNaN: isNaN(parseFloat(inputs.foot_print)) })
    } else {
      // D/C Units
      finance_check.push({
        key: "module_dc_cost",
        value: { type: inputs.module_dc_cost.type, value: parseFloat(inputs.module_dc_cost.value) },
        isNaN: isNaN(parseFloat(inputs.module_dc_cost.value)),
      });
      finance_check.push({
        key: "rack_a_finance",
        value: { type: inputs.rack_a_finance.type, value: parseFloat(inputs.rack_a_finance.value) },
        isNaN: isNaN(parseFloat(inputs.rack_a_finance.value)),
      });
      finance_check.push({
        key: "rack_b_finance",
        value: { type: inputs.rack_b_finance.type, value: parseFloat(inputs.rack_b_finance.value) },
        isNaN: isNaN(parseFloat(inputs.rack_b_finance.value)),
      });
      finance_check.push({
        key: "rack_c_finance",
        value: { type: inputs.rack_c_finance.type, value: parseFloat(inputs.rack_c_finance.value) },
        isNaN: isNaN(parseFloat(inputs.rack_c_finance.value)),
      });
      finance_check.push({
        key: "bos_other",
        value: { type: inputs.bos_other.type, value: parseFloat(inputs.bos_other.value) },
        isNaN: isNaN(parseFloat(inputs.bos_other.value)),
      });

      // fixed units
      finance_check.push({
        key: "interconnection",
        value: { type: inputs.interconnection.type, value: parseFloat(inputs.interconnection.value) },
        isNaN: isNaN(parseFloat(inputs.interconnection.value)),
      });
      finance_check.push({
        key: "permits_fees",
        value: { type: inputs.permits_fees.type, value: parseFloat(inputs.permits_fees.value) },
        isNaN: isNaN(parseFloat(inputs.permits_fees.value)),
      });
      finance_check.push({
        key: "engineering",
        value: { type: inputs.engineering.type, value: parseFloat(inputs.engineering.value) },
        isNaN: isNaN(parseFloat(inputs.engineering.value)),
      });
      finance_check.push({ key: "margin", value: { type: inputs.margin.type, value: parseFloat(inputs.margin.value) }, isNaN: isNaN(parseFloat(inputs.margin.value)) });
      finance_check.push({ key: "itc_ineligible", value: { type: inputs.itc_ineligible.type, value: parseFloat(inputs.itc_ineligible.value) }, isNaN: isNaN(parseFloat(inputs.itc_ineligible.value)) });
      finance_check.push({
        key: "other_fixed",
        value: { type: inputs.other_fixed.type, value: parseFloat(inputs.other_fixed.value) },
        isNaN: isNaN(parseFloat(inputs.other_fixed.value)),
      });

      // A/C Units
      finance_check.push({ key: "inverter", value: { type: inputs.inverter_cost.type, value: parseFloat(inputs.inverter_cost.value) }, isNaN: isNaN(parseFloat(inputs.inverter_cost.value)) });
      finance_check.push({ key: "ac_aux", value: { type: inputs.ac_aux.type, value: parseFloat(inputs.ac_aux.value) }, isNaN: isNaN(parseFloat(inputs.ac_aux.value)) });
      finance_check.push({ key: "mv_wire", value: { type: inputs.mv_wire.type, value: parseFloat(inputs.mv_wire.value) }, isNaN: isNaN(parseFloat(inputs.mv_wire.value)) });
      finance_check.push({ key: "other_ac", value: { type: inputs.other_ac.type, value: parseFloat(inputs.other_ac.value) }, isNaN: isNaN(parseFloat(inputs.other_ac.value)) });

      // Misc Units
      finance_check.push({
        key: "rack_footprint",
        value: { type: inputs.rack_footprint.type, value: parseFloat(inputs.rack_footprint.value) },
        isNaN: isNaN(parseFloat(inputs.rack_footprint.value)),
      });
      finance_check.push({
        key: "boundary_area_per",
        value: { type: inputs.boundary_area_per.type, value: parseFloat(inputs.boundary_area_per.value) },
        isNaN: isNaN(parseFloat(inputs.boundary_area_per.value)),
      });
      finance_check.push({
        key: "contingency",
        value: { type: inputs.contingency.type, value: parseFloat(inputs.contingency.value) },
        isNaN: isNaN(parseFloat(inputs.contingency.value)),
      });

      // spacing adder
      let spacing_gcr = [];
      _.forEach(inputs.spacing_gcr, (gcr) => {
        let _gcr = isNaN(parseFloat(gcr)) === true ? 0.0 : parseFloat(gcr);
        spacing_gcr.push(_gcr);
      });
      let spacing_per_wp = [];
      _.forEach(inputs.spacing_per_wp, (wp) => {
        let _wp = isNaN(parseFloat(wp)) === true ? 0.0 : parseFloat(wp);
        spacing_per_wp.push(_wp);
      });
      inputs.spacing_gcr = spacing_gcr;
      // ex: [0.3,0.45,0.6,0,0.2,0.1,1,0]
      inputs.spacing_per_wp = spacing_per_wp;
      // ex: [30,45,60,0,20,10,100,0]

      // we need to fix the sorting of these now that we've fixed the variable type(*)
      let sort_array = [];
      _.forEach(inputs.spacing_gcr, (gcr, index) => {
        sort_array.push({ gcr: inputs.spacing_gcr[index], wp: inputs.spacing_per_wp[index] });
      });
      // console.log(sort_array)
      /*
				SAMPLE unorganized object
					{gcr: 0.3, wp: 30}
					{gcr: 0.45, wp: 45}
					{gcr: 0.6, wp: 60}
					{gcr: 0, wp: 0}
					{gcr: 0.2, wp: 20}
					{gcr: 0.1, wp: 10}
					{gcr: 1, wp: 100}
					{gcr: 0, wp: 0}
			*/

      // Sort the object by GCR ascending for backend
      let sorted_array = _.sortBy(sort_array, ["gcr"], ["asc"]);
      // console.log(sorted_array)
      /*
				AFTER sorting
					{gcr: 0, wp: 0}
					{gcr: 0, wp: 0}
					{gcr: 0.1, wp: 10}
					{gcr: 0.2, wp: 20}
					{gcr: 0.3, wp: 30}
					{gcr: 0.45, wp: 45}
					{gcr: 0.6, wp: 60}
					{gcr: 1, wp: 100}
			*/

      inputs.spacing_gcr = _.map(sorted_array, "gcr");
      // console.log(inputs.spacing_gcr)
      // ex: [0,0,0.1,0.2,0.3,0.45,0.6,1]
      inputs.spacing_per_wp = _.map(sorted_array, "wp");
      // console.log(inputs.spacing_per_wp)
      // ex: [0,0,10,20,30,45,60,100]
    }
    // finance_check.push({ key: "spacing_gcr", value: parseFloat(inputs.spacing_gcr.value), isNaN: isNaN(parseFloat(inputs.spacing_gcr.value)) })
    // finance_check.push({ key: "spacing_per_wp", value: parseFloat(inputs.spacing_per_wp.value), isNaN: isNaN(parseFloat(inputs.spacing_per_wp.value)) })

    inputs.en_shade_impact = parseInt(inputs.en_shade_impact);

    finance_check.forEach((input) => {
      if (input.isNaN === true) {
        // inputs.finance[input.key] = tabDefaults.data[input.key];
        // warnings.push(`Finance Input (${input.key}) found blank, setting to default:${tabDefaults.data[input.key]}`);
        inputs[input.key] = 0;
        errors.push(`Finance Input (${input.key}) found incorrect. Correct in the Finance Tab`);
      } else {
        inputs[input.key] = input.value;
      }
    });

    if (inputs.analysis_period <= 0 || inputs.analysis_period > 50) {
      errors.push("Invalid Analysis Period. In the Finance Tab, Analysis Period must be between 1 and 50 years. You may need to toggle off Custom Schedule to change the Analysis Period field.");
    }
    if (inputs.metric === 1 && inputs.debt_structure > 0 && inputs.analysis_period < inputs.debt_tenor) {
      errors.push("Invalid Debt Tenor. In the Finance Tab, Debt Tenor must be less than or equal to Analysis Period.");
    }
  }

  let valid = true;
  if (errors.length > 0) {
    valid = false;
  }

  // console.log("validatedInputs", inputs);
  return { valid: valid, validatedInputs: inputs, errors: errors, warnings: warnings };
}
