import { execute_job, poll_job, upload_to_s3 } from "./basics.jobs";
import { siftConstants } from "../_constants";
// Export_data is probably a better name
// THis will be used for exporting excel, cad, kmz, future files
export const export_data = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "job_id",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux

    async run(dispatch) {

      console.log("export_data.run", inputs, action)
      let error;
      let msg =
        inputs.export_type === "kmz-export"
          ? "Preparing KMZ..."
          : inputs.export_type === "pvsyst-export"
          ? "Preparing SHD..."
          : inputs.export_type === "pdf-export-bq"
          ? "Preparing Budgetary Quote PDF..."
          : "Preparing CAD...";
      // tell redux store we are starting a DXF/KMZ Export
      dispatch({ type: siftConstants.EXPORT_MAP_STARTED, msg });
      // request a url and job_id from the backend

      let job_resp = await execute_job(inputs, "export-job");

      if (job_resp.error) {
        // if error with executing job then handle here
        error = job_resp.error;
        dispatch({ type: siftConstants.EXPORT_MAP_COMPLETED, error });
        // STOP HERE
        return;
      }

      // put the inputs in S3 using the url
      let upload_resp;
      if (inputs.export_type === "pdf-export-bq") {
        upload_resp = await upload_to_s3(job_resp.url, JSON.stringify(inputs.BQ_data));
      } else {
        upload_resp = await upload_to_s3(job_resp.url, JSON.stringify(inputs));
      }

      if (upload_resp.error) {
        error = job_resp.error;
        dispatch({ type: siftConstants.EXPORT_MAP_COMPLETED, error });
      }

      inputs.job_id = job_resp.job_id;

      // poll backend for updates on job_id
      let job_poll_resp = await poll_job(inputs, "export-job", dispatch);

      if (job_poll_resp) {
        // tell redux store we are done
        dispatch({ type: siftConstants.EXPORT_MAP_COMPLETED });
      }
    },
  };
};
