import React from 'react';
import { Form, Spin, Input, Button, Result } from 'antd';
import { LockOutlined } from '@ant-design/icons';
const FormItem = Form.Item;
import config from 'config';
import { Base } from '../../layouts';

import { Info } from '../../components'

const Info_Reg_Error = ({errors}) =>{
  return (
    <Info text={
      <div>
      	<strong>Registration Errors</strong>
				<ol>
					{errors.map( (error, index) => {
						return(
							<li key={index}>{error}</li>
						)
					})}
				</ol>			
    	</div>
    } /> 
  )
}

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      reset: false,
      confirmDirty: false,
      error: [],
      user: {
        code: undefined,
        password1: '',
        password2: '',
      }
    }
  }
  componentDidMount () {
    const { code } = this.props.match.params;
    const { user } = this.state;
    this.setState({user: {...user, code:code}})
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
        user: {
            ...user,
            [name]: value
        }
    });
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }    
  handleSubmit = (values) => {
    // event.preventDefault();
    this.setState({ submitted: true, error: undefined });    
          
    // return;   
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "password": values.password1,
        "token": this.state.user.code
      })
    };
    fetch(`${config.apiUrl}/api/password_reset/confirm/`, requestOptions).then(this.handleResponse)

  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password1')) {
      callback('Passwords do not match.');
    } else {
      callback();
    }
  }
  validateToNextPassword = (rule, value, callback) => {
      const form = this.props.form;
      if (value && this.state.confirmDirty) {
        form.validateFields(['password2'], { force: true });
      }
      callback();
  }

  handleResponse = (response) => {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        if (!response.ok) {
          const error = ['Password Reset Token Expired.'];
          this.setState({ submitted: false, error: data.password || error }); 
          return Promise.reject(error);
        } else {
          this.setState({ submitted: false, error: undefined, reset: true }); 
        }
        return data;
    });
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <Base>
      <div className='password-reset-wrapper'>
      <div className='password-reset'>
        <Spin spinning={this.state.submitted}>

        { this.state.reset ? 
        
        <Result
          className="sift-result"
          status="success"
          title="Successfully Reset Password!"
          subTitle="You can now login with your new password."
          // extra={[
          //   <Button type="primary" key="console">
          //     Goto SIFT
          //   </Button>,
          // ]}
        />  :      
        <Form onFinish={this.handleSubmit} className="login-form">
          <span>
            <h3>Reset Password</h3>
          </span>          
          <FormItem     
						name='password1'
						className="input"
            rules={[{ required: true, message: 'Please input your password!' }]}                            
          >
            <Input name="password1" 
              onChange={this.handleChange} 
              className="inner-input"
              prefix={
                <LockOutlined
                  style={{ color: 'rgba(0,0,0,.25)' }} 
                />
              } 
              type="password" placeholder="Password" />
              
          </FormItem>
          <div style={{ height: 6 }}></div>
          <FormItem
						name='password2'
						className="input"
            rules={[{ required: true, message: 'Please confirm your password!' }]}          
            >
              <Input name="password2" 
                onChange={this.handleChange} 
                onBlur={this.handleConfirmBlur} 
                className="inner-input"
                prefix={
                  <LockOutlined
                    style={{ color: 'rgba(0,0,0,.25)' }} 
                  />
                } 
                type="password" placeholder="Confirm Password"/>
          </FormItem>
          <div style={{ height: 6 }}></div>
          <FormItem>
            {this.state.error && this.state.error.length > 0 &&
            <span style={{color:'red'}} className="input">						
							<span><Info_Reg_Error errors={this.state.error}/> Password requirements: 8 character minimum, 
                at least 1 non-numeric, no common passwords </span>
						</span>
            }
          </FormItem>
          <div style={{ height: 6 }}></div>
          <FormItem>
            <Button type="primary" htmlType="submit" loading={this.state.submitted} className='login-form-button'>
              Reset Password
            </Button>
          </FormItem>
        </Form>
        }
        </Spin>
      </div>

      </div>
      </Base>
    )
  }
}

// const PasswordResetForm = Form.create()(PasswordReset);
export { PasswordReset };