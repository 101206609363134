import React from "react";

// styles
import "./SIFTIntro.css";
import "./buttons.css";

import sunFlare from "../_images/sun-flare.svg";
import budgetFlare from "../_images/budget-flare.svg";
import trophyFlare from "../_images/trophy-flare.svg";
import topoFlare from "../_images/topo-flare.svg";

import boxDev from "../_images/box-dev.png";
import success from "../_images/success.jpeg";

import quote_1 from "../_images/testimonial1.png";
import quote_2 from "../_images/testimonial2.png";
import quote_3 from "../_images/testimonial3.png";
import quote_4 from "../_images/testimonial4.png";

const quotes = [quote_1, quote_2, quote_3, quote_4];

import APICarousel from "./apiCarousel";
import { Carousel } from "antd";

// components
import { Button } from "antd";
import { SIFT_Button } from "./Button";

const SIFTIntro = (props) => {
  return (
    <section className="siftIntro">
      <div className="siftIntro-container">
        <div className="siftIntro-features">
          <h2>More Value from Every Project</h2>

          <ul style={{ listStyleType: "none" }}>
            <li>You need a way to define winning projects fast, while eliminating all doubt that you've selected the most optimum configuration.</li>
            <li>We understand how frustrating it is to commit to a design strategy knowing that if you had the time or data, you could find something better.</li>
            <li>Don't lose out on revenue or submit an uncompetitive bid because you are missing the data you need.</li>
            <li>Use the Sunfig Instant Feasibility Tool (SIFT) to gain confidence in your decisions, maximize margins, and submit winning bids with a competitive edge.</li>
          </ul>
        </div>

        <div className="siftIntro-flare">
          <div className="flare">
            <img src={budgetFlare} alt="calulator with coins and parchment" />
            <p>Unlimited SIFT runs and simple $ / user / month pricing</p>
          </div>
          <div className="flare">
            <img src={sunFlare} alt="small sun" />
            <p>PVsyst-like performance modeling & outputs</p>
          </div>
          <div className="flare">
            <img src={trophyFlare} alt="trophy on a podium with the number 1 on it" />
            <p>1000 GCR & DC:AC options per run, modeled in seconds</p>
          </div>
          <div className="flare">
            <img src={topoFlare} alt="a unfolded map with topography lines on it" />
            <p>Import topography from USGS or Google</p>
          </div>
        </div>
      </div>

      <div className="siftIntro-try-free">
        <SIFT_Button type="ghost" className="default" onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>
          Try SIFT for FREE
        </SIFT_Button>
        <SIFT_Button type="ghost" className="default" href="#sift">
          Learn More
        </SIFT_Button>
      </div>

      <div className="siftIntro-try">
        <div className="siftIntro-try-text">
          <div style={{ textAlign: "center" }}>
            <h2>Getting Started is Easy</h2>
          </div>
          <div className="siftIntro-list">
            <div>
              <img src={success} alt="women stretching at her desk" />
            </div>
            <div>
              <ol>
                <li>Sign up for SIFT and start your free trial</li>
                <li>Schedule a demo</li>
                <li>Get coaching through your first project</li>
                <li>See immediate and actionable results</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="foot">Complimentary training and support. Nothing to install. Cancel any time.</div>

      <div className="signup-btn-container">
        <SIFT_Button onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>Sign Up Now</SIFT_Button>
        <SIFT_Button onClick={() => window.open("https://calendly.com/siftdemo/demo", "_blank", "noopener,noreferrer")}>Schedule a Demo</SIFT_Button>
      </div>

      <div className="siftIntro-carousel-container">
        <div className="api-carousel">
          <APICarousel items={quotes} altDescription={"testimonial from one of our cutomers"} height={50} />
        </div>
      </div>

      {/* <div className="siftIntro-try">
        <div className="split">
          <div className="left">
            <img src={success}></img>
          </div>
          <div className="right">
            <h2>Getting Started is Easy</h2>
            <ol>
              <li>Sign up for SIFT and start a free trial</li>
              <li>Schedule a free 1-on-1 demo session</li>
              <li>Work through your project together with Sunfig</li>
              <li>See immediate and actionable results</li>
            </ol>
          </div>
        </div>

        <div className="foot">Complimentary training and support. Nothing to install. Cancel any time.</div>

        <div className="center">
          <Button type="primary" href="/">
            Sign Up Now
          </Button>
          <Button type="primary" href="https://calendly.com/siftdemo/demo">
            Schedule a Demo
          </Button>
        </div>

        <div className="api-carousel">
          <APICarousel items={quotes} height={50} />
        </div>
      </div> */}
    </section>
  );
};

export default SIFTIntro;
