import styled from "styled-components";

const LabelWrap = styled.label`
  display: flex;
  align-items: center;
  margin: 0px 8px 0px 0px;
  color: #333;
  flex-direction: ${({ labelPosition }) => (labelPosition == "left" ? "row-reverse" : null)};

  input[type="checkbox"] {
    margin: 0px 5px;
    height: 20px;

    &:checked {
      background-color: var(--primary-brand-color);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export { LabelWrap };
