import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";

import { canopyActions, usePrevious } from "../../Redux";
import { useKey } from "../../Map/components/MapEventHandler/useKey";

import { build_shade_object, getCorners } from "../ShadeHelpers/Shade.editor";
// import "../../Map/leaflet.draw.css";

const DrawBuilding = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const drawing_building = useSelector((state) => state.canopy.drawing_building);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const prev_drawing_building = usePrevious(drawing_building);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const [current_building, set_current_building] = useState(null);

  const cancel_key = useKey("escape");

  useEffect(() => {
    if (!prev_drawing_building && drawing_building) {
      drawBuilding();
      // document.getElementById("map").style.cursor = "crosshair";
    } else if (prev_drawing_building && !drawing_building) {
      cancelDraw();
      // document.getElementById("map").style.cursor = "grab";
    }
  }, [drawing_building]);

  useEffect(() => {
    if (cancel_key && drawing_building) {
      cancelDraw();
    }
  }, [cancel_key, drawing_building]);

  useEffect(() => {
    map.on("pm:create", (e) => {
      if (e.shape === "Rectangle") {
        let geoJson = e.layer.toGeoJSON();
        let center = e.layer.getBounds().getCenter();
        let corners = getCorners(e.layer);
        let shade_object = build_shade_object(geoJson, corners, center, shade_height_unit, e.shape);
        dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
        e.layer.remove();
      }
    });

    return () => map.off("pm:create");
  }, [shade_height_unit]);

  const drawBuilding = () => {
    let options = {
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 2,
      fillColor: "var(--primary-brand-color)",
    };

    map.pm.enableDraw("Rectangle", {
      snappable: true,
      snapDistance: 10,
      templineStyle: options,
      pathOptions: options,
      tooltips: false,
    });

    // if (drawing_building) {
    //   cancelDraw();
    // } else {
    //   dispatch(canopyActions.updateCanopyInputs("drawing_building", true));
    // }
  };

  const cancelDraw = () => {
    map.pm.disableDraw();
    dispatch(canopyActions.update_canopy_toolbar("drawing_building", false));
  };

  return null;
};

export { DrawBuilding };
