import React, { useEffect, useState } from "react";

// antd
import { Select } from "antd";

//redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../Redux";

const dd_values = {
  dc: [
    { key: "$/Wp", value: 0 },
    { key: "$/mod", value: 1 },
    { key: "$", value: 2 },
  ],
  dc_module: [
    { key: "$/Wp", value: 0 },
    { key: "$", value: 1 },
  ],
  ac_inverter: [
    { key: "$/Wac", value: 0 },
    { key: "$", value: 1 },
  ],
  ac: [
    { key: "$/Wac", value: 0 },
    { key: "$/inv", value: 1 },
  ],
  fixed: [{ key: "$", value: 0 }],
  misc: [
    { key: "$/ha", value: 0 },
    { key: "$/acre", value: 1 },
  ],
  contingency: [{ key: "%", value: 0 }],
  wp_only: [{ key: "$/Wp", value: 0 }],
  wac_only: [{ key: "$/Wac", value: 0 }],
};

const FinanceSelectMenu = ({ type, id, disabled = false }) => {
  const dispatch = useDispatch();
  const dynamic_id_value = useSelector((state) => state.inputs[id]);

  const updateInput = (key, value) => {
    dispatch(inputsActions.update_input(key, value));
  };

  return (
    <Select
      size="small"
      value={dynamic_id_value?.type}
      disabled={disabled}
      style={{ minWidth: 75 }}
      onChange={(value) => updateInput(id, { value: dynamic_id_value?.value, type: value, total: dynamic_id_value?.total, prev_type: dynamic_id_value?.type })}
    >
      {dd_values[type].map((opt, index) => (
        <Select.Option key={index} value={opt.value}>
          {opt.key}
        </Select.Option>
      ))}
    </Select>
  );
};

export { FinanceSelectMenu };
