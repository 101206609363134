import React from 'react';
import './team.css';

// images
import teamBanner from '../_images/teamBanner.png';
import ashton from '../_images/ashton.png';
import nick from '../_images/nick.png';
import joshua from '../_images/joshua.png';
import rohit from '../_images/rohit.png';
import jake from '../_images/jake.png';

const Team = () => {
  return (
    <section className="team" id="team">
      <div className="team-banner">
        <img src={teamBanner} alt="city skyline" />
      </div>
      {/* <div className="team-mission-header-text">
        <h2>Our Team and Mission</h2>
      </div>
      <div className="team-mission-text">
        <p>
          While in the industry we were routinely faced with questions like “What’s the best DC:AC?” and “What product
          will be best here?”. Each site is unique, and the industry was missing a tool capable of modeling the
          thousands of potential project configurations necessary to answer the questions. At the intersection to solar
          and software, Sunfig was founded to address this challenge. Through our cloud-based framework and products
          like SIFT, the industry now has the power to model all configurations and build the best possible projects.
          With our customers we have improved gigawatts of solar projects, ensuring each project is as valuable as
          possible in an effort to outcompete traditional energy sources.
        </p>
      </div> */}
      <div className="team-pictures">
        <div className="team-member">
          <img src={ashton} alt="Ashton's headshot" />
          <p className="name">Ashton Vandemark</p>
          <p>Founder, CEO</p>
        </div>
        <div className="team-member">
          <img src={nick} alt="Nick's headshot" />
          <p className="name">Nick Sanderson</p>
          <p>Founder, CTO</p>
        </div>
        <div className="team-member">
          <img src={joshua} alt="Josh's headshot" />
          <p className="name">Joshua Belknap</p>
          <p>Front-end Developer</p>
        </div>
        <div className="team-member">
          <img src={rohit} alt="Rohit's headshot" style={{ marginBottom: '5px' }} />
          <p className="name">Rohit Jain</p>
          <p>Back End Developer</p>
        </div>
        <div className="team-member">
          <img src={jake} alt="Jake's headshot" style={{ marginBottom: '5px', clipPath: 'circle(50% at 50% 50%)' }} />
          <p className="name">Jake Bartosch</p>
          <p>Sales Engineer</p>
        </div>
      </div>
      <div className="team-footer-text">
        <p>Located in Oakland, California</p>
        <p>Founded in 2017</p>
      </div>
    </section>
  );
};

export default Team;
