import styled from "styled-components";

// project mananger style
const ProjectManagerWrap = styled.section`
  padding: 0px;
  width: inherit;
  height: 100%;
  display: grid;

  grid-row: 1;
  grid-template-rows: 35px 35px;

  .project-manager-buttons {
    grid-row: 1 / auto;
    margin: 3px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-title {
    width: 100%;
    max-width: 325px;
    grid-row: 2;
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 0px 10px;
    box-sizing: border-box;
    position: relative;

    label {
      grid-column: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      margin: auto 0;

      & > span {
        height: 20px;
      }
    }
    span {
      grid-column: 2;
      margin-left: auto;
      margin: auto 0;
    }
  }
`;

export { ProjectManagerWrap };
