import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginWrapper } from "./authstyles";
import { Spinner } from "../Spinner";
import { AgreementUpdate } from "./";

import { useAuth } from "./AuthProvider";

import { accountActions, UserActions, isAuthenticated } from "../../components/Redux";
import { triAlert, pass_hidden, pass_visible } from "../../assets/images";

const Login = () => {
  const { useApi, setUser } = useAuth();
  const { login, get_library } = useApi();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const authenticated = useSelector((state) => isAuthenticated(state));

  const [submitted, set_submitted] = useState(false);
  const [reset_pw, set_reset_pw] = useState(false);
  const [remember, set_remember] = useState(false);
  const [continue_login, set_continue_login] = useState(false);
  const [refresh_token, set_refresh_token] = useState(false);
  const [pw_reset_msg, set_pw_reset_msg] = useState(undefined);
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [tos, set_tos] = useState(0);
  const [visible, set_visible] = useState(false);
  const [error, set_error] = useState(undefined);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    set_remember(localStorage.getItem("remember"));
  }, []);

  const toggleAgreement = () => {
    set_visible(!visible);
  };

  const handleForgotPassword = () => {
    set_reset_pw(true);
    set_error(undefined);
  };

  const handleCancel = () => {
    set_reset_pw(false);
    set_error(undefined);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    set_submitted(true);
    set_pw_reset_msg(undefined);

    try {
      if (reset_pw) {
        dispatch(UserActions.pwReset(email));
      } else {
        if (email && password) {
          if (remember) {
            localStorage.setItem("remember", 1);
          } else {
            localStorage.setItem("remember", 0);
          }
          let user = await login(email.trimEnd(), password);
          if (user == "Unauthorized") {
            set_error("Invalid email or password");
          } else {
            // setUser(user);
            // console.log("login success", user);
            let user_library = await get_library();
            // console.log(user_library);
            // dispatch(UserActions.get_library_data());
            dispatch(UserActions.identifyUser(user.user_id, user.email));
          }
          // dispatch(UserActions.login(email.trimEnd(), password));
        }
      }
    } catch (err) {
      console.log(err.message);
    }
    set_submitted(false);
  };

  return (
    <LoginWrapper>
      {!reset_pw ? (
        <form onSubmit={handleSubmit}>
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input name="email" type="text" value={email} onChange={(e) => set_email(e.target.value)} required />
          </div>
          <div className="form-input">
            <div className="password-box">
              <label htmlFor="password">
                <span>Password</span>
                <span>
                  <a onClick={() => handleForgotPassword()}>Forgot your password?</a>
                </span>
              </label>
            </div>
            <div className="password-input">
              <input name="Password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => set_password(e.target.value)} required />
              <span className="toggle-password" onClick={togglePasswordVisibility}>
                <img src={showPassword ? pass_visible : pass_hidden} />
              </span>
            </div>
          </div>
          {user.login_error && (
            <span className="form-alert">
              <img src={triAlert} />
              {user.login_error}
            </span>
          )}
          <button type="submit">
            <span className="button-content">
              {submitted && <Spinner />}
              Login
            </span>
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-input">
            <p>Enter the email you registered with, and we'll email you a link to reset your password.</p>
          </div>
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input type="text" value={email} onChange={(e) => set_email(e.target.value)} required />
          </div>
          {user.pw_reset_msg && <p style={{ color: "#4CAF50", marginTop: "5px", textAlign: "left", padding: 10 }}>{user.pw_reset_msg}</p>}

          <button type="submit" style={{ marginBottom: "10px" }}>
            <span className="button-content">
              {user.loggingIn && <Spinner />}
              Request Password Reset
            </span>
          </button>
          <a style={{ paddingBottom: "20px", paddingLeft: "10px", textAlign: "center" }} onClick={handleCancel}>
            Return to Login
          </a>
        </form>
      )}
    </LoginWrapper>
  );
};

export { Login };
