import React, { Component } from "react";
import PropTypes from "prop-types";
import * as vis from "vis-graph3d";
import * as visData from "vis-data";
import { v4 as uuid4 } from "uuid";
import isEqual from "lodash";

class Graph3D extends Component {
  constructor(props) {
    super(props);

    const { identifier } = props;
    this.state = {
      identifier: identifier !== undefined ? identifier : uuid4(),
    };

    this.updateGraph = this.updateGraph.bind(this);
    this.removeDuplicateIdsIfThereAreAny = this.removeDuplicateIdsIfThereAreAny.bind(this);
  }

  componentDidMount() {
    // only way I can find to override the tooltip positioning.
    // I looked this function up in the vis-graph3d.js,
    // then just copied/pasted it here and changed the functionality I needed
    vis.Graph3d.prototype._showTooltip = function (dataPoint) {
      var content, line, dot;

      if (!this.tooltip) {
        content = document.createElement("div");

        Object.assign(content.style, {}, this.tooltipStyle.content);

        content.style.position = "absolute";
        line = document.createElement("div");

        // Object.assign(line.style, {}, this.tooltipStyle.line);

        line.style.position = "absolute";
        dot = document.createElement("div");

        Object.assign(dot.style, {}, this.tooltipStyle.dot);

        dot.style.position = "absolute";
        this.tooltip = {
          dataPoint: null,
          dom: {
            content: content,
            line: line,
            dot: dot,
          },
        };
      } else {
        content = this.tooltip.dom.content;
        line = this.tooltip.dom.line;
        dot = this.tooltip.dom.dot;
      }

      this._hideTooltip();

      this.tooltip.dataPoint = dataPoint;

      if (typeof this.showTooltip === "function") {
        content.innerHTML = this.showTooltip(dataPoint.point);
      } else {
        content.innerHTML =
          "<table>" +
          "<tr><td>" +
          this.xLabel +
          ":</td><td>" +
          dataPoint.point.x +
          "</td></tr>" +
          "<tr><td>" +
          this.yLabel +
          ":</td><td>" +
          dataPoint.point.y +
          "</td></tr>" +
          "<tr><td>" +
          this.zLabel +
          ":</td><td>" +
          dataPoint.point.z +
          "</td></tr>" +
          "</table>";
      }

      content.style.left = "0";
      content.style.top = "0";
      this.frame.appendChild(content);
      this.frame.appendChild(line);
      this.frame.appendChild(dot); // calculate sizes

      var contentWidth = content.offsetWidth;
      var contentHeight = content.offsetHeight;
      var lineHeight = line.offsetHeight;
      var dotWidth = dot.offsetWidth;
      var dotHeight = dot.offsetHeight;
      var left = dataPoint.screen.x - contentWidth / 2;
      left = Math.min(Math.max(left, 10), this.frame.clientWidth - 10 - contentWidth);
      // line.style.left = dataPoint.screen.x + 'px';
      // line.style.top = dataPoint.screen.y - lineHeight + 'px';
      // content.style.left = left + 'px';
      // content.style.top = dataPoint.screen.y - lineHeight - contentHeight + 'px';
      content.style.left = "auto";
      content.style.right = "5px";
      content.style.top = "5px";
      dot.style.left = dataPoint.screen.x - dotWidth / 2 + "px";
      dot.style.top = dataPoint.screen.y - dotHeight / 2 + "px";
    };

    const container = document.getElementById(this.state.identifier);
    this.$el = new vis.Graph3d(container, undefined, this.props.options);
    this.$el.on("cameraPositionChange", this.props.cameraPositionChangeHandler);
    this.$el._onMouseUp = function (event) {
      this.frame.style.cursor = "auto";
      this.leftButtonDown = false;
      // The mouseUp function is broken in version 6.0.4 of Vis-graph3d. This is a temp work around until it's fixed.
      // remove event listeners to prevent the mouseup error.
      document.removeEventListener("mousemove", this.onmousemove);
      document.removeEventListener("mouseup", this.onmouseup);
      event.preventDefault();
    };

    this.$el._onMouseUp = function (event) {
      this.frame.style.cursor = "auto";
      this.leftButtonDown = false;
      // The mouseUp function is broken in version 6.0.4 of Vis-graph3d. This is a temp work around until it's fixed.
      // remove event listeners to prevent the mouseup error.
      document.removeEventListener("mousemove", this.onmousemove);
      document.removeEventListener("mouseup", this.onmouseup);
      event.preventDefault();
    };
    this.updateGraph();
  }

  shouldComponentUpdate(nextProps) {
    const { options, data, code, graph } = this.props;

    const optionsChange = !isEqual(options, nextProps.options) || options.length !== nextProps.options.length;
    const dataChange = !isEqual(data, nextProps.data) || data.length !== nextProps.data.length;
    const codeChange = code != nextProps.code;
    const graphChange = graph != nextProps.graph;

    return optionsChange || dataChange || codeChange || graphChange;
  }

  componentDidUpdate() {
    this.updateGraph();
  }

  removeDuplicateIdsIfThereAreAny(array) {
    const seenIds = new Set();
    return array.filter((item) => {
      if (seenIds.has(item.id)) {
        return false;
      } else {
        seenIds.add(item.id);
        return true;
      }
    });
  }

  updateGraph() {
    let data_arr = this.removeDuplicateIdsIfThereAreAny(this.props.data);
    this.$el.setData(data_arr);
    this.$el.setOptions(this.props.options);
    setTimeout(() => {
      this.$el.redraw();
    }, 120);
  }

  render() {
    const { identifier } = this.state;
    return <div id={identifier}></div>;
  }
}

Graph3D.defaultProps = {
  options: {
    width: "440px",
    height: "330px",
    xCenter: "55%",
    yCenter: "40%",
    style: "dot-color",
    colormap: ["#fff", "#333", "#3d3d3d", "#002bcb"],
    showPerspective: false,
    showGrid: true,
    keepAspectRatio: false,
    showLegend: false,
    xLabel: "GCR",
    yLabel: "DC:AC",
    zLabel: "$/MWh",
    axisFontSize: 30,
    tooltip: true,
    tooltipStyle: {
      line: {
        height: "20px",
      },
    },
    verticalRatio: 1.0,
    cameraPosition: {
      horizontal: -0.65,
      vertical: 0.55,
      distance: 2.2,
    },
    dotSizeRatio: 0.012,
    dotSizeMinFraction: 0.6,
    dotSizeMaxFraction: 0.6,
    zValueLabel: function (z) {
      return z * -1;
    },
  },
  cameraPositionChangeHandler: function () {},
};

Graph3D.propTypes = {
  options: PropTypes.object,
  data: PropTypes.array.isRequired,
  cameraPositionChangeHandler: PropTypes.func,
};

export { Graph3D };
