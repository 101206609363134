import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";
import { non_rect_edit } from "../CanopyHelpers";

import { canopyActions, usePrevious } from "../../../Redux";
import { removeClassName } from "vis-util";

const ModulesLayer = ({ id }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  const url = window.location.pathname;

  //store
  const canopy = useSelector((state) => state.canopy.canopies[id]);
  const is_editing_modules = useSelector((state) => state.canopy.is_editing_modules);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);

  const [edited_layer, set_edited_layer] = useState(undefined);

  const prevSelectedCanopy = usePrevious(selected_canopy);
  const prevIsEditingModules = usePrevious(is_editing_modules);

  const selected_canopy_ref = useRef();

  useEffect(() => {
    if (internal_sift) {
      if (url === "/sift" && is_editing_modules) {
        updateAndRemoveLayers();
      }
    }
  }, [url]);

  useEffect(() => {
    selected_canopy_ref.current = selected_canopy;
    if (prevIsEditingModules && !is_editing_modules && prevSelectedCanopy == id) {
      updateActiveCells();
      map.eachLayer((layer) => layer.options?.shape == "Rectangle" && layer.options.id == prevSelectedCanopy && layer.remove());
    }
  }, [selected_canopy]);

  useMapEvents({
    editModules: () => {
      if (selected_canopy_ref.current != id) return;
      if (!is_editing_modules) {
        dispatch(canopyActions.updateCanopyInputs("is_editing_modules", true));
        removeCells();
        if (selected_canopy_ref.current == id) addEditableCells();
      } else {
        updateAndRemoveLayers(selected_canopy_ref.current);
      }
    },
  });

  const updateAndRemoveLayers = (remove_id = undefined) => {
    dispatch(canopyActions.updateCanopyInputs("is_editing_modules", false));
    if (selected_canopy_ref.current == id) updateActiveCells();
    map.eachLayer((layer) => layer.feature && layer.options.id == selected_canopy_ref.current && layer.remove());
  };

  useEffect(() => {
    if (!canopy) return;
    addCells();
  }, [canopy.editCellsGeoJson]);

  const addCells = () => {
    let cells = L.geoJSON([...canopy.editCellsGeoJson], {
      module_layer_id: canopy.id,
      transform: false,
      draggable: false,
      scaling: false,
      style: (module) => {
        return {
          fillColor: module.properties.enabled ? "#ffffff" : "none",
          fillOpacity: 0.1,
          weight: 2,
          color: module.properties.enabled ? (module.properties.override_color ? module.properties.override_color : "#333") : "none",
          interactive: true,
        };
      },
    });

    cells.addTo(map);
    cells.bringToBack();
  };

  const removeCells = (remove_id = id) => {
    map.eachLayer((layer) => layer.feature && layer.options.module_layer_id == selected_canopy_ref.current && layer.remove());
  };

  const addEditableCells = () => {
    if (!canopy) return;

    let modLayers = [];

    Object.values(canopy.editCellsGeoJson).map((geoJson, index) => {
      let modules = L.geoJSON(geoJson, {
        module_cell_id: canopy.id,
        transform: false,
        draggable: false,
        scaling: false,
      });

      modules.addTo(map);

      let modIndex = modules._leaflet_id - 1;
      let modLayer = modules._layers[modIndex];

      //   modLayer.bringToFront();
      modLayers.push(modLayer);

      modLayer.setStyle({
        color: "#333",
        weight: 1,
        fillColor: geoJson.properties.enabled ? "#60de4f" : "#fc2003",
        fillOpacity: "0.8",
      });

      modLayer.on("click", (e) => {
        if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
        if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);

        modLayer.feature.properties.enabled = !modLayer.feature.properties.enabled;

        modLayer.setStyle({
          fillColor: e.target.feature.properties.enabled ? "#60de4f" : "#fc2003",
        });
      });
    });

    set_edited_layer(modLayers);
  };

  const updateActiveCells = (prev_canopy_id = undefined) => {
    let geoJsons = [];
    Object.values(edited_layer).map((layer) => {
      layer.off("onclick");
      let _lyrJson = layer.toGeoJSON();
      if (!_lyrJson.properties.enabled) {
        geoJsons.push(_lyrJson.properties.indexes);
      }
      map.removeLayer(layer);
    });
    let updated_canopy = non_rect_edit(geoJsons, canopy);
    updated_canopy.changed_from_inputs = false;
    dispatch(canopyActions.updateCanopyInputs("edit_canopy", updated_canopy));
  };

  // const getLayer = (id) => {
  //   let temp_layer = false;
  //   map.eachLayer((layer) => {
  //     if (layer.feature && layer.options.module_layer_id == id) {
  //       temp_layer = layer;
  //       return;
  //     }
  //   });
  //   return temp_layer;
  // };

  return <></>;
};

export { ModulesLayer };
