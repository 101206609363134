import React from 'react';

import './developmentServices.css';
import { Button } from 'antd';

// images
import devBanner from '../_images/DevBanner.png';
import serviceChart from '../_images/devServices_chart.png';

const DevelopmentServices = () => {
  return (
    <section id="services" className="devServices">
      <div className="devServices-banner">
        <img src={devBanner} alt="solar field" />
      </div>

      <div className="devServices-content">
        <div className="devServices-header-text">
          <p>
            We help you optimize against increasingly complex contract and project requirements. Don't lose out on potential margin due to lack of
            data or a complicated RFP. We provide you the most advanced and customizable optimization solutions on the market, far superior to batch
            performance models and spreadsheets full of assumptions.
          </p>
        </div>
        <div className="devServices-services">
          <h2>Development Services Menu</h2>
          <ul>
            <li>Solar Project Optimization for Maximum LCOE, IRR, or PPA</li>
            <li>Solar + Storage Project Optimization</li>
            <li>Storage Dispatch Modeling (Economic, Arbitrage, Time of Use, Timed)</li>
            <li>Project Financial Modeling</li>
          </ul>
        </div>
        <div className="devServices-chart">
          <img src={serviceChart} alt="" />
        </div>
        <div className="devServices-highlights">
          <div className="hightlight-card">
            <p className="highlight-number">10,000</p>
            <p className="highlight">Configurations Explored</p>
            <p className="heightlight-description">Highly granular and accurate data allows you to make optimal project choices.</p>
          </div>
          <div className="hightlight-card">
            <p className="highlight-number">+5-15%</p>
            <p className="highlight">Project Value</p>
            <p className="heightlight-description">Outperform other approaches and be confident no money is left on the table.</p>
          </div>
          <div className="hightlight-card">
            <p className="highlight-number">1</p>
            <p className="highlight">Detailed Project Design</p>
            <p className="heightlight-description">Free up valuable time and resources by commiting early to a single design.</p>
          </div>
        </div>
        <div className="devServices-footer-text">
          <p>
            Data-driven and iterative, our models generate a precise project roadmap to guide your team. We help you solve the most challenging
            questions in project development.
          </p>
          <div className="devServices-footer-questions">
            <ul>
              <li>What configuration maximizes the value of a project or bid?</li>
              <li>Which products and technologies are best for a site?</li>
              <li>Does the optimal project change with an update to cost, tax, or tariff?</li>
              <li>How can project demand and time of use data be used to inform the design and maximize project value?</li>
            </ul>
          </div>
        </div>

        <div className="devServices-footer-contact">
          <Button className="default" href="#contact">
            Contact Us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentServices;
