import styled from "styled-components";

const ThirtyPercentFormLayout = styled.section`
  position: absolute;
  width: 100%;
  inset: 0;
  margin: auto;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: all;

  .thirty-percent-form {
    position: absolute;
    width: 350px;
    height: 435px;
    padding: 10px;
    inset: 0;
    margin: auto;
    z-index: 3;
    pointer-events: all;
    border: 1px solid #60de4f;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    display: grid;
    gap: 15px 0px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      "title thirty-percent-close"
      "body body"
      "thirty-percent-buttons thirty-percent-buttons";

    .thiry-percent-title {
      grid-area: title;
      border-bottom: 1px solid #60de4f;
    }
    .thirty-percent-form-body {
      grid-area: body;
    }
    .thirty-percent-close {
      grid-area: thirty-percent-close;
    }
    .thirty-percent-buttons {
      grid-area: thirty-percent-buttons;
      display: grid;
      grid-template-columns: repeat(2, min-content);
      gap: 0px 10px;
      place-content: end;

      .cancel-tp-button {
        white-space: nowrap;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        height: 32px;
        padding: 4.8px 15px;
        font-size: 13px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
        background: #fff;
        border-color: #d9d9d9;

        &:hover {
          color: #0f853e;
          border-color: #0f853e;
        }
      }
      .save-tp-button {
        white-space: nowrap;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        height: 32px;
        padding: 4.8px 15px;
        font-size: 13px;
        border-radius: 4px;
        color: #fff;
        background: #60de4f;
        border-color: #60de4f;
        display: flex;
        align-items: center;

        &:hover {
          background: #3bb864;
          border-color: #3bb864;
        }
      }
    }
  }
`;

export { ThirtyPercentFormLayout };
