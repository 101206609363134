import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import cards from "./cards";
import { PlanCards } from "./PlanCards";
import { Button, Divider, Card, Col, Row, Tabs, Spin, Alert, Empty } from "antd";
import { Modal } from "../BaseComponents";
const TabPane = Tabs.TabPane;
import _ from "lodash";

import "./funnel.css";
import "../../routes/Home/_components/buttons.css";

import { Info_50MW, Info_Topo, Info_50runs, Info_customModInv, Info_1000MW, Info_Core, Info_CustomWeather, Info_AdvFin, Info_UnlimRuns, Info_CAD, Info_Collab } from "./info";
import { plan_specs } from "./plan_specs";
import { ArrowLeftOutlined, IssuesCloseOutlined, QuestionOutlined } from "@ant-design/icons";

import { CheckoutForm } from "../Account/_components/CheckoutForm";
import { accountActions, UserActions } from "../Redux";

import { AuthCard } from "../Auth/AuthCard";
import { useAuth } from "../Auth";
import config from "config";
import fetchUtil from "../Redux/_services/fetchUtil";

const header_titles = ["Manage Your Plan", "Account Setup", "Summary of Changes"];

const Funnel = () => {
  const dispatch = useDispatch();
  const { is_authenticated } = useAuth();

  const user = useSelector((state) => state.user);
  const billing = useSelector((state) => state.user.billing);
  const products = useSelector((state) => state.user.products);
  const funnel_visible = useSelector((state) => state.user.funnel_visible);
  const user_loading = useSelector((state) => state.user.loading);

  const [plan_stage, set_plan_stage] = useState(0);
  const [selected_plan, set_selected_plan] = useState("");
  const [selected_plan_id, set_selected_plan_id] = useState(0);
  const [active_tab, set_active_tab] = useState("signup");
  const [adding_card, set_adding_card] = useState(false);
  const [managing_plan, set_managing_plan] = useState(false);
  const [plan_card, set_plan_card] = useState(0);

  const [current_plan_id, set_current_plan_id] = useState(user.product_paid_plan);
  // console.log("CURRENT USER:", user);
  const [free_trial_status, set_free_trial_status] = useState(0);
  const [prev_plan, set_prev_plan] = useState(-1);
  const [days_remaining_in_cycle, set_days_remaining_in_cycle] = useState(1);
  const [plan_charge_dt, set_plan_charge_dt] = useState("xx/xx/xx");
  const [free_trial_end_dt, set_free_trial_end_dt] = useState("xx/xx/xx");
  const [user_type, set_user_type] = useState(0);
  const [prorated_amt, set_prorated_amt] = useState(0);

  const funnelState = useMemo(() => {
    let state = {
      current_plan_paid: user.product_plan_paid,
      current_plan_id: user.product_plan,
      // plan_stage: user.product_plan > 1 ? 1: 0
      plan_stage: 0,
    };
    return state;
  }, [user]);

  useEffect(() => {
    if (!_.isUndefined(user.free_trial_status)) {
      set_current_plan_id(user.product_plan_paid);
      set_free_trial_status(user.free_trial_status);
      set_prev_plan(user.product_plan);
      set_days_remaining_in_cycle(user.days_remaining_in_cycle);
      set_plan_charge_dt(user.plan_charge_dt);
      set_free_trial_end_dt(user.free_trial_end_dt);
      set_user_type(user.user_type);
      // 3 == devs
      if (selected_plan_id > 0 && selected_plan_id !== "3") {
        let pr_amt = ((days_remaining_in_cycle - 1) * (products[selected_plan_id]?.monthly_cost / 30 - products[prev_plan]?.monthly_cost / 30)).toFixed(0);
        set_prorated_amt(pr_amt);
      }
    }
  }, [user]);

  useEffect(() => {
    // 3 == devs
    if (selected_plan_id > 0 && selected_plan_id !== "3") {
      let pr_amt = ((days_remaining_in_cycle - 1) * (products[selected_plan_id]?.monthly_cost / 30 - products[prev_plan]?.monthly_cost / 30)).toFixed(0);
      set_prorated_amt(pr_amt);
    }
  }, [selected_plan_id]);

  //   const authenticated = isAuthenticated(state);
  const defaultPayment = billing && billing.length > 0 ? billing.filter((p) => p.is_default)[0] : undefined;
  const can_confirm = defaultPayment != undefined || selected_plan_id == 1;
  const last_page = is_authenticated && plan_stage >= 1;
  const upgrading = current_plan_id < selected_plan_id;

  const getStripePortalUrl = (plan_id) => {
    // POST request to our backend to requet a url and then redirect to it
    // console.log("getStripePortalUrl");
    set_managing_plan(true);
    console.log("loading it up");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ plan: plan_id }),
    };

    fetchUtil(`${config.apiUrl}/billing/portal/`, requestOptions, true, (response) => {
      response.text().then((text) => {
        let resp_d = text && JSON.parse(text);
        if (!response.ok) {
          message.error("Error requesting reset.", 2.5);
          return Promise.reject(error);
        } else {
          window.open(resp_d.data.data.url);
          set_managing_plan(false);
        }
        return resp_d;
      });
    });
    // handleCloseFunnel();
  };

  const handleSelectSub = (plan_id) => {
    set_selected_plan_id(plan_id);
    let new_stage = is_authenticated ? 2 : 1;
    set_plan_stage(new_stage);
  };

  const handleUpdatePlan = () => {
    dispatch(UserActions.updatePlanTo(selected_plan_id));
  };

  const handleCloseFunnel = () => {
    dispatch(accountActions.toggleFunnelVis(false));
  };

  const goBack = () => {
    set_plan_stage(0);
    set_active_tab("signup");
    set_adding_card(false);
  };

  const afterModalClose = () => {
    set_plan_stage(0);
    set_active_tab("signup");
    set_selected_plan_id(0);
  };

  const handleReturn = (token, name) => {
    set_adding_card(false);
    let _token = {
      ...token,
      name: name,
    };
    dispatch(UserActions.addPayment(_token));
  };

  const okButtonProps = {
    type: "link",
    disabled: !can_confirm,
    style: last_page ? {} : { display: "none" },
  };

  // console.log(funnelState);
  return (
    <Modal
      title={header_titles[funnelState.plan_stage]}
      style={{ maxWidth: "1300px", zIndex: 5000 }}
      width="80%"
      showOkText={false}
      cancelText={
        "Cancel"
        // funnelState.plan_stage > 0 ? (
        //   <span>
        //     <ArrowLeftOutlined /> Back
        //   </span>
        // ) : (
        //   "Cancel"
        // )
      }
      open={funnel_visible}
      // onOk={handleUpdatePlan}
      // okType="link"
      // okButtonProps={okButtonProps}
      confirmLoading={user_loading}
      onCancel={() => handleCloseFunnel()}
      afterClose={afterModalClose}
      className="funnel-modal"
    >
      <Spin spinning={managing_plan} tip="Stripe Loading..." className="sift-loader">
        {/* PLAN SELECTOR */}
        {funnelState.plan_stage == 0 && (
          <>
            <div className="funnel-banner">{user.free_trial_status == 0 && <h2>Sign up for Standard or Pro and get 1 week free!</h2>}</div>
            <div className="plan-cards">
              <div className="plan-card">
                <PlanCards selected={1} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
              </div>
              <div className="plan-card">
                <PlanCards selected={2} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
              </div>{" "}
              <div className="plan-card">
                <PlanCards selected={3} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
              </div>
            </div>
            <div className="plan-cards-mobile">
              <ul className="plan-cards-mobile-tabs">
                <li>
                  <button className={plan_card === 0 ? "active-tab" : null} onClick={() => set_plan_card(0)}>
                    Starter
                  </button>
                </li>
                <li>
                  <button className={plan_card === 1 ? "active-tab" : null} onClick={() => set_plan_card(1)}>
                    Standard
                  </button>
                </li>
                <li>
                  <button className={plan_card === 2 ? "active-tab" : null} onClick={() => set_plan_card(2)}>
                    Pro
                  </button>
                </li>
              </ul>
              {plan_card === 0 && (
                <div className="plan-card-mobile">
                  <PlanCards selected={1} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
                </div>
              )}
              {plan_card === 1 && (
                <div className="plan-card-mobile">
                  <PlanCards selected={2} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
                </div>
              )}
              {plan_card === 2 && (
                <div className="plan-card-mobile">
                  <PlanCards selected={3} handleSelectSub={getStripePortalUrl} current_plan={funnelState.current_plan_id} current_plan_paid={funnelState.current_plan_paid} />
                </div>
              )}
            </div>
          </>
        )}

        {/* CURRENT PLAN MODIFY */}
        {funnelState.plan_stage == 1 && (
          <section className="summary-of-changes">
            <div className="summary-card">
              <PlanCards
                selected={funnelState.current_plan_id}
                summary={true}
                handleSelectSub={handleSelectSub}
                current_plan={funnelState.current_plan_id}
                current_plan_paid={funnelState.current_plan_paid}
              />
            </div>
            <div className="summary-modal-text">
              {selected_plan_id == current_plan_id && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> <b>{products[current_plan_id]?.name}</b> plan is currently active. No changes to your billing.
                  </span>
                </div>
              )}

              {/* UPGRADING TO A NEW PLAN OR TRIAL IS ACTIVE */}
              {upgrading ? (
                <div className="summary-text">
                  {free_trial_status == 0 ? (
                    <span>
                      <IssuesCloseOutlined /> You are activating your SIFT Free Trial, which will expire in 7 days. You will be charged at the end of your trial.
                    </span>
                  ) : free_trial_status == 1 || free_trial_status == 3 ? (
                    <span>
                      <IssuesCloseOutlined /> Your SIFT Free Trial is still active and will expire on {free_trial_end_dt}.{" "}
                      {selected_plan_id > 1 && <span>You will be charged at the end of your trial.</span>}
                    </span>
                  ) : free_trial_status == 2 && current_plan_id == 2 && selected_plan_id == 3 ? (
                    <span>
                      <IssuesCloseOutlined /> You will be charged a prorated <b>${prorated_amt} now</b> for the remainder of this billing cycle.
                    </span>
                  ) : (
                    <span>
                      <IssuesCloseOutlined /> You will be charged <b>${products[selected_plan_id - 1]?.monthly_cost}</b> today.
                    </span>
                  )}
                </div>
              ) : (
                // DOWNGRADING PLAN (Already paid)
                current_plan_id > selected_plan_id && (
                  <div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> <b>{products[current_plan_id - 1]?.name}</b> plan is paid and active for the remainder of the billing cycle.
                      </span>
                    </div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> Your plan will change to <b>{products[selected_plan_id - 1]?.name}</b> on {plan_charge_dt}.
                      </span>
                    </div>
                  </div>
                )
              )}
              {selected_plan_id > 1 && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> Each billing cycle covers one month of SIFT service.
                  </span>
                </div>
              )}
              {selected_plan_id > 1 && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> Cancel future charges any time by downgrading to a STARTER plan.
                  </span>
                </div>
              )}
            </div>
            <div className="summary-card-payment">
              <Spin className="funnel-spin" spinning={user_loading} style={{ width: "100%" }}>
                {defaultPayment && !adding_card ? (
                  <div>
                    <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>This is the payment method you have selected:</div>
                    <div className="payment-text-holder">
                      <div className="payment-text">
                        {defaultPayment.brand} (**** {defaultPayment.last4})
                      </div>
                      <div className="payment-text">
                        Expiration Date {defaultPayment.exp_month}/{defaultPayment.exp_year}
                      </div>
                      <div className="payment-text">
                        <a onClick={() => set_adding_card(true)}>Add different card.</a>
                      </div>
                    </div>
                    <div>
                      {cards.findIndex((card) => card.name == defaultPayment.brand) > -1 ? (
                        <img src={cards[cards.findIndex((card) => card.name == defaultPayment.brand)].icon} />
                      ) : (
                        <QuestionOutlined />
                      )}
                    </div>
                  </div>
                ) : adding_card ? (
                  <CheckoutForm visible={true} onReturn={handleReturn} onCancel={() => set_adding_card(false)} />
                ) : (
                  <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE}>
                    <Button type="primary" className="btn" onClick={() => set_adding_card(true)}>
                      Add Payment Method
                    </Button>
                  </Empty>
                )}
              </Spin>
            </div>
          </section>
        )}
      </Spin>
    </Modal>
  );
};

export { Funnel };

{
  /* {plan_stage == 0 && (
         (
        <Row gutter={16}>
          <Col span={12} style={{ margin: "0 auto", width: "425px" }}>
            <AuthCard initial_tab="signup" />
          </Col>
        </Row>
      )} */
}
