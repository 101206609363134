// I wrote this tool to create an easy way to change the zoom levels of all the random map locations that we got from a google database. I created a small backend that had an endpoint that took in the location ID, and the zoom level. it appended that object to a new "random map location" object and saved it to a new file on the little local server that I wrote. The backend file I wrote is long gone, but should be simple enough to recreate. Then you load this into the map component. It appears in the lower right corner of the map. Use the arrow keys to navigate back and forth, zoom in and out to frame the satelite images in a way that is appealing and then click save. After you go through all 1000+ locations, you just take the file that was created and put it in the "NaturalLocations" file that is located in the redux folder.

import React, { useState, useEffect } from "react";
import styled from "styled-components";

//locations
import { NaturalLocations } from "../Redux/_helpers";

const EarthViewTesterWrap = styled.section`
  height: 175px;
  width: 300px;
  display: grid;
  grid-template-rows: repeat(7, auto);
  position: absolute;
  bottom: 20px;
  right: 25px;
  background: #fff;
  color: #333;
  z-index: 1000;
  padding: 10px;
  place-items: start;
  border-radius: 2px;
  border: 1px solid #002bcb;

  p {
    margin: 0px;
  }

  .prev-next {
    display: flex;
    align-items: center;

    .controls {
      display: flex;
      align-items: center;

      input {
        width: 50px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      margin-left: 8px;

      button {
        margin-right: 5px;
      }
      p {
        margin: 0px 5px 0px 0px;
      }
    }
  }

  .save-zoom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      margin-right: 5px;
    }
    p {
      margin: 0px 5px 0px 0px;
    }
  }
`;

const EarthViewTester = ({ zoom, earthViewFn }) => {
  const [earthViewIndex, setEarthViewIndex] = useState(0);

  const handlePrevNext = (direction) => {
    let index;
    if (direction == "prev" && earthViewIndex !== 0) {
      index = earthViewIndex - 1;
      setEarthViewIndex(index);
    }

    if (direction == "next" && earthViewIndex < Object.values(NaturalLocations).length) {
      index = earthViewIndex + 1;
      setEarthViewIndex(index);
    }
  };

  useEffect(() => {
    earthViewFn.current(earthViewIndex);
  }, [earthViewIndex]);

  const createLocationJson = () => {
    let newZoom = {
      earthViewIndex,
      zoom,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newZoom),
    };

    fetch("https://localhost:9945/locations", options);
  };

  return (
    <EarthViewTesterWrap>
      <p>{`Name: ${NaturalLocations[earthViewIndex].name}`}</p>
      <p>{`Region: ${NaturalLocations[earthViewIndex].region || "N/A"}`}</p>
      <p>{`latlng: 'lat': ${NaturalLocations[earthViewIndex].latlng.lat}, 'lng': ${NaturalLocations[earthViewIndex].latlng.lng} `}</p>
      <p>{`Earthview Zoom Level: ${NaturalLocations[earthViewIndex].zoomLevel}`}</p>
      <div className="prev-next">
        <div className="controls">
          <input type="text" value={earthViewIndex} onChange={(e) => setEarthViewIndex(e.target.value)} />
          <p>{` / ${Object.values(NaturalLocations).length}`}</p>
        </div>
        <div className="buttons">
          <button onClick={() => handlePrevNext("prev")}>prev</button>
          <button onClick={() => handlePrevNext("next")}>next</button>
        </div>
      </div>
      <div className="save-zoom">
        <p>{`Current zoom Level: ${zoom}`}</p>
        <button onClick={() => createLocationJson()}>Save Zoom</button>
      </div>
    </EarthViewTesterWrap>
  );
};

export { EarthViewTester };
