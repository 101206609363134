import styled from "styled-components";

const CanopyInputsWrapper = styled.section`
  padding: 10px;

  .canopy-warning-text {
    margin: 8px auto;
    width: 100%;
    text-align: center;
  }

  .canopy-inputs-empty-box {
    padding: 20px;
  }
`;

export { CanopyInputsWrapper };
