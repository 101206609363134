import React from 'react';

// styles
import './productsAndServices.css';
import './buttons.css';

import Card from './card';

// images
import ProductsBanner from '../_images/ProductsAndServicesBanner.png';
import sift from '../_images/sift_thumbnail.png';
import api from '../_images/api_thumbnail.png';
import services from '../_images/services_thumbnail.png';

import { Typography } from 'antd';

const ProductsAndServices = () => {
  return (
    <section className="productsAndServices">
      <div className="banner">
        <img src={ProductsBanner} alt="solar panels and palm trees" />
      </div>
      <div className="product-cards">
        <Card
          image={sift}
          title={'SIFT'}
          description={'Sunfig Intelligent Feasibility Tool allows you quickly determine the optimum design for your projects.'}
          link={'#sift'}
        />
        <Card
          image={services}
          title={'Development Services'}
          description={'We work with you directly on complex or custom project optimization.'}
          link={'#services'}
        />
        <Card image={api} title={'API'} description={"Many of the technologies we've developed are available to you via API."} link={'#api'} />
      </div>
    </section>
  );
};

export default ProductsAndServices;
