import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, history } from "./components/Redux/_helpers";
// import { Router, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
// import { AppRoutes } from "./AppRoutes";

import AuthProvider from "./components/Auth/AuthProvider";
import Routes from "./Routes";
import config from "config";

// intercom app id
let app_id = "py7g789m";

// Importing Antd default style overrides depending on platform. Couldn't get nested buttons(popup, modal etc.) to change color any other way.
let config_theme = require("./basic_theme.js");
// if (config.platform == "internal_sift") {
//   config_theme = require("../src/components/_Internal/Stylesheets/internal_theme.js");
// } else {
//   config_theme = require("./basic_theme.js");
// }

import { GlobalStyles } from "./GlobalStyles";

import LogRocket from "logrocket";
// import ReactGA from "react-ga";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("ppao5i/sift");
  // ReactGA.initialize("UA-106909670-1");
}

if (process.env.NODE_ENV !== "production") {
  console.log(`Looks like we are in ${process.env.NODE_ENV} mode!`);
  if (process.env.NODE_ENV === "stage") {
    LogRocket.init("ppao5i/sift-portal-stage");
  }
  // ReactGA.initialize("UA-165577939-1");
}

// history.listen((location) => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

let platform = config.platform;
let internal_sift = config.platform == "internal_sift";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // specify basename below if running
  // in a subdirectory or set as "/" if app runs in root
  <Provider store={store}>
    <ConfigProvider theme={config_theme.default}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ConfigProvider>
    <GlobalStyles internal_sift={internal_sift} />


      {/* platform={platform} internal_sift={internal_sift} */}
      {/* <HistoryRouter basename={"/"} history={history} location={history.location} > 
        <AppRoutes history={history} platform={platform} internal_sift={internal_sift} />
      </HistoryRouter> */}


  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
