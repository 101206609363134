import React from "react";
import { useMap } from "react-leaflet";

import { LeafletControl } from "../../LeafletControl";

import { ToolbarWrap } from "../styles";

import { zoom_in, zoom_out, zoom_extents } from "../../../../../assets/images";

// const zoomGranularity = 0.25;
// map.setZoom will only work if the zoom granularity is an int...not sure why
const zoomGranularity = 1;

const MapZoomHandler = ({ position }) => {
  // get the map from parent
  const map = useMap();

  const _onZoomIn = () => {
    map.setZoom(map._zoom + zoomGranularity);
  };
  const _onZoomOut = () => {
    map.setZoom(map._zoom - zoomGranularity);
  };

  const _onZoomExtents = () => {
    map.fire("zoomextents");
  };

  return (
    <LeafletControl position={position}>
      <ToolbarWrap vertical={true}>
        <section className="map-action-buttons ">
          <button onClick={_onZoomIn}>
            <img src={zoom_in}></img>
          </button>
          <button onClick={_onZoomOut}>
            <img src={zoom_out}></img>
          </button>
          <button onClick={_onZoomExtents}>
            <img src={zoom_extents}></img>
          </button>
        </section>
      </ToolbarWrap>
    </LeafletControl>
  );
};

export { MapZoomHandler };
