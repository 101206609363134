import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  tableRows: {
    marginHorizontal: 20,

    borderLeftColor: "#333",
    borderLeftWidth: 2,
    borderRightColor: "#333",
    borderRightWidth: 2,
    borderBottomColor: "#333",
    borderBottomWidth: 2,
  },

  infoContainer: {
    // flexDirection: 'row',
    // flexGrow: 1,
    height: 25,
  },
  infoBox: {
    flexGrow: 8,
    // backgroundColor: '#f2f2f2',
  },
  infoText: {
    fontSize: 8,
    marginVertical: 2.5,
    marginHorizontal: 2,
    textAlign: "center",
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    // backgroundColor: '#f2f2f2',
  },

  small: {
    flexGrow: 8,
    width: 45,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  medium: {
    flexGrow: 8,
    width: 65,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  large: {
    flexGrow: 8,
    width: 95,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  end: {
    flexGrow: 8,
    // height: 40,
    width: 65,
  },
});

function prettifyText(text) {
  // takes in the ugly text from the product data planeType and makes it a little more presentable
  switch (text) {
    case "single_slope":
      return "Single Slope";
    case "inverted":
      return "Inverted";
    case "dual_tilt":
      return "Dual Tilt";
    default:
      return "undefined";
  }
}

// Create Document Component
const TableRows = (props) => (
  <View style={styles.tableRows}>
    {Object.values(props.data).map((row, index) => {
      return (
        <View style={styles.infoContainer} key={index}>
          <View style={styles.row}>
            <View style={styles.medium}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{row.Name}</Text>
              </View>
            </View>
            <View style={styles.medium}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{row.Module}</Text>
              </View>
            </View>
            <View style={styles.medium}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{prettifyText(row["Canopy Type"])}</Text>
              </View>
            </View>
            <View style={styles.small}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{parseInt(row["Azimuth (°)"])}°</Text>
              </View>
            </View>
            <View style={styles.small}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{row["Canopy Type"] === "inverted" ? `${row["Tilt To Azimuth"]}° / ${row["Tilt From Azimuth"]}°` : `${row["Tilt (°)"]}°`}</Text>
              </View>
            </View>
            <View style={styles.large}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{`${row["Mod Dimension X Qty"]}mod by ${row["Mod Dimension Y Qty"]}mod`}</Text>
              </View>
            </View>
            <View style={styles.large}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{`${row["Mod Dimension X Ft"].toFixed(1)}ft by ${row["Mod Dimension Y Ft"].toFixed(1)}ft`}</Text>
              </View>
            </View>
            <View style={styles.end}>
              <View style={styles.row}>
                <Text style={styles.infoText}>{row["Module Qty"]}</Text>
              </View>
            </View>
          </View>
        </View>
      );
    })}
  </View>
);

export { TableRows };
