const ModulesDD = {
  0: {
    "id": 0,
    "data": {
        "default":0,
        "name":"Custom Module",
        "rating":0,
        "mlm_D2MuTau":0,
        "mlm_E_g":0,
        "mlm_I_mp_ref":0,
        "mlm_I_sc_ref":0,
        "mlm_Length":0,
        "mlm_N_diodes":0,
        "mlm_N_parallel":0,
        "mlm_N_series":0,
        "mlm_R_s":0,
        "mlm_R_sh0":0,
        "mlm_R_shexp":0,
        "mlm_R_shref":0,
        "mlm_S_ref":0,
        "mlm_T_c_fa_alpha":0,
        "mlm_T_ref":0,
        "mlm_V_mp_ref":0,
        "mlm_V_oc_ref":0,
        "mlm_Width":0,
        "mlm_alpha_isc":0,
        "mlm_beta_voc_spec":-0,
        "mlm_mu_n":0,
        "mlm_n_0":0,
        "module_area":0,
        "module_bifaciality":0.7,
        "module_iam_ang":	[0, 30, 50, 60, 70, 75, 80, 85, 90],
        "module_iam_eff":	[1, .992, .972, .95, .904, .857, .762, .476, 0],
        "module_isbifacial":0,
        "muPmpReq":0,
        "technology":0,
        "modules_per_string": 28   
    }
  },
  "ca10cf99d29b45808ce6e1fbcbcfab3b": {
    "id": "ca10cf99d29b45808ce6e1fbcbcfab3b",
    "data":{
      "id":1,
      "default":1,
      "name":"Generic 340W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":8.79,
      "mlm_I_sc_ref":9.24,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.313,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":450,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":38.7,
      "mlm_V_oc_ref":47.1,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.44,
      "mlm_beta_voc_spec":-131,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.948,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.4,
      "rating":340,
      "technology":0,
    }
  },
  "bfa4ef562227433fa54cec7b5869aa61": {
    "id": "bfa4ef562227433fa54cec7b5869aa61",
    "data":{
      "id":2,
      "default":1,
      "name":"Generic 340W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":8.79,
      "mlm_I_sc_ref":9.24,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.313,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":450,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":38.7,
      "mlm_V_oc_ref":47.1,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.44,
      "mlm_beta_voc_spec":-131,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.948,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.4,
      "rating":340,
      "technology":0,
    }
  },
  "01b38472774841a190ca5e7f7008d59c": {
    "id": "01b38472774841a190ca5e7f7008d59c",
    "data":{
      "id":3,
      "default":1,
      "name":"Generic 350W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":8.95,
      "mlm_I_sc_ref":9.38,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.29,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":450,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.1,
      "mlm_V_oc_ref":47.5,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.5,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.952,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.4,
      "rating":350,
      "technology":0,
    }
  },
  "f22c663644bb4c00b3516865c50d93d1": {
    "id": "f22c663644bb4c00b3516865c50d93d1",
    "data":{
      "id":4,
      "default":1,
      "name":"Generic 350W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":8.95,
      "mlm_I_sc_ref":9.38,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.29,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":450,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.1,
      "mlm_V_oc_ref":47.5,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.5,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.952,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.4,
      "rating":350,
      "technology":0,
    }
  },
  "283efb20bf434daab2aece883c4f2f26": {
    "id": "283efb20bf434daab2aece883c4f2f26",
    "data":{
      "id":5,
      "default":1,
      "name":"Generic 360W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.12,
      "mlm_I_sc_ref":9.51,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.268,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":500,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.5,
      "mlm_V_oc_ref":48,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.56,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.96,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.39,
      "rating":360,
      "technology":0,
    }
  },
  "9ef11000c8d54e07a9e67bb9e9ef106d": {
    "id": "9ef11000c8d54e07a9e67bb9e9ef106d",
    "data":{
      "id":6,
      "default":1,
      "name":"Generic 360W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.12,
      "mlm_I_sc_ref":9.51,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.268,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":500,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.5,
      "mlm_V_oc_ref":48,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.56,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0006,
      "mlm_n_0":0.96,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.39,
      "rating":360,
      "technology":0,
    }
  },
  "c0b906564700425ebcb6e2ce13cb1287": {
    "id": "c0b906564700425ebcb6e2ce13cb1287",
    "data":{
      "id":7,
      "default":1,
      "name":"Generic 370W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.28,
      "mlm_I_sc_ref":9.61,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.236,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":600,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.9,
      "mlm_V_oc_ref":48.5,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.61,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0007,
      "mlm_n_0":0.967,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.39,
      "rating":370,
      "technology":0,
    }
  },
  "5d667414c4e4469b9934a8d7c13539e7": {
    "id": "5d667414c4e4469b9934a8d7c13539e7",
    "data":{
      "id":8,
      "default":1,
      "name":"Generic 370W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.28,
      "mlm_I_sc_ref":9.61,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.236,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":600,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":39.9,
      "mlm_V_oc_ref":48.5,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.61,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0007,
      "mlm_n_0":0.967,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.39,
      "rating":370,
      "technology":0,
    }
  },
  "364aa9e4cee04535aed7806302f3f652": {
    "id": "364aa9e4cee04535aed7806302f3f652",
    "data":{
      "id":9,
      "default":1,
      "name":"Generic 380W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.44,
      "mlm_I_sc_ref":9.75,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.213,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":650,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":40.3,
      "mlm_V_oc_ref":48.9,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.61,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0008,
      "mlm_n_0":0.973,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.39,
      "rating":380,
      "technology":0,
    }
  },
  "7afbba3c8af34d688a048ff566b2ea9a": {
    "id": "7afbba3c8af34d688a048ff566b2ea9a",
    "data":{
      "id":10,
      "default":1,
      "name":"Generic 380W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.44,
      "mlm_I_sc_ref":9.75,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.213,
      "mlm_R_sh0":1800,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":650,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":40.3,
      "mlm_V_oc_ref":48.9,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.61,
      "mlm_beta_voc_spec":-135,
      "mlm_mu_n":-0.0008,
      "mlm_n_0":0.973,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.39,
      "rating":380,
      "technology":0,
    }
  },
  "f9b75927bf654a8d98d0a8a5ff0639eb": {
    "id": "f9b75927bf654a8d98d0a8a5ff0639eb",
    "data":{
      "id":11,
      "default":1,
      "name":"Generic 390W Mono",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.54,
      "mlm_I_sc_ref":9.93,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.274,
      "mlm_R_sh0":2000,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":500,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":40.59,
      "mlm_V_oc_ref":49.55,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.87,
      "mlm_beta_voc_spec":-140.6,
      "mlm_mu_n":-0.0008,
      "mlm_n_0":0.991,
      "module_area":1.94,
      "module_bifaciality":0,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":0,
      "muPmpReq":-0.39,
      "rating":390,
      "technology":0,
    }
  },
  "62e02e6b5aa94d208755766e686a65f3": {
    "id": "62e02e6b5aa94d208755766e686a65f3",
    "data":{
      "id":12,
      "default":1,
      "name":"Generic 390W Bifacial",
      "mlm_D2MuTau":0,
      "mlm_E_g":1.121,
      "mlm_I_mp_ref":9.54,
      "mlm_I_sc_ref":9.93,
      "mlm_Length":1.956,
      "mlm_N_diodes":3,
      "mlm_N_parallel":1,
      "mlm_N_series":72,
      "mlm_R_s":0.274,
      "mlm_R_sh0":2000,
      "mlm_R_shexp":5.5,
      "mlm_R_shref":500,
      "mlm_S_ref":1000,
      "mlm_T_c_fa_alpha":0.9,
      "mlm_T_ref":25,
      "mlm_V_mp_ref":40.59,
      "mlm_V_oc_ref":49.55,
      "mlm_Width":0.992,
      "mlm_alpha_isc":4.87,
      "mlm_beta_voc_spec":-140.6,
      "mlm_mu_n":-0.0008,
      "mlm_n_0":0.991,
      "module_area":1.94,
      "module_bifaciality":0.7,
      "module_iam_ang":[0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
      "module_iam_eff":[1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
      "module_isbifacial":1,
      "muPmpReq":-0.39,
      "rating":390,
      "technology":0,
    }
  },
}

export { ModulesDD }