import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Input, Alert, Popover, Badge, Tooltip, Spin, Radio, Switch, Slider } from "antd";

import { isNumber, inputsActions, isFloat } from "../../Redux";

import { ConfigWrapper } from "../StyledComponents";

import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const gcr_or_pitch = ["GCR", "Pitch (m)"];
const slider_or_fixed = ["Slider", "Range"];

const required_inputs = (module_rating, nameplate, modules_per_string) => (
  <div>
    <p>
      <Badge status={module_rating > 0 ? "success" : "error"} />
      Module Rating
    </p>
    <p>
      <Badge status={nameplate > 0 ? "success" : "error"} />
      Inverter Rating
    </p>
    <p>
      <Badge status={modules_per_string > 0 ? "success" : "error"} />
      Modules per String
    </p>
  </div>
);

//
const ConfigInputs = () => {
  // const inputs = useSelector((state) => state.sift.ioManager.inputs.config.data);
  const inputs = useSelector((state) => state.inputs);
  const project_loaded = useSelector((state) => state.projects.loaded);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const track_mode = useSelector((state) => state.inputs.track_mode);

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading || project_loaded;

  const [this_loaded, set_this_loaded] = useState(false);

  const [rangeError, setRangeError] = useState(false);
  const [errMessage, setErrMessage] = useState(undefined);

  const [worker_count, set_worker_count] = useState(0);
  const [p_minmax, set_p_minmax] = useState({ min: 0, max: 100 });
  const [spi_minmax, set_spi_minmax] = useState({ min: 0, max: 100 });
  const [dcac_range, set_dcac_range] = useState({ min: 0, max: 100 });

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";
  const en_target_inputs = useSelector((state) => state.user.en_target_inputs);
  // const en_target_inputs_from_project = useSelector((state) => state.inputs.en_target_inputs);

  const [inputValue, setInputValue] = useState(1);
  const [local_mod_target, set_local_mod_target] = useState(0);
  const [local_mwh_target, set_local_mwh_target] = useState(0);
  const [local_simple_inverter_dcac, set_local_simple_inverter_dcac] = useState(1.4);
  const [gcr_max_limit, set_gcr_max_limit] = useState(0.5);

  const onChange = (value) => {
    if (isNaN(value)) {
      return;
    }
    updateInput("simple_inverter_dcac", value);
  };

  const dispatch = useDispatch();

  const [local_spacing, set_local_spacing] = useState({
    gcr_min: inputs.gcr_min,
    gcr_max: inputs.gcr_max,
    gcr_inc: inputs.gcr_inc,
    pitch_min: inputs.pitch_min,
    pitch_max: inputs.pitch_max,
    pitch_inc: inputs.pitch_inc,
  });
  // Update local_spacing whenever inputs changes
  useEffect(() => {
    set_local_spacing({
      gcr_min: inputs.gcr_min,
      gcr_max: inputs.gcr_max,
      gcr_inc: inputs.gcr_inc,
      pitch_min: inputs.pitch_min,
      pitch_max: inputs.pitch_max,
      pitch_inc: inputs.pitch_inc,
    });
  }, [inputs]);
  const [local_spi, set_local_spi] = useState({
    spi_min: inputs.spi_min,
    spi_max: inputs.spi_max,
    spi_inc: inputs.spi_inc,
  });

  // useMemo is called every render, but if the depenedencies are the same, it will return the memoized value
  // which should save us from a lot of unnecessary renders
  const local_inputs = useMemo(() => {
    let dimension = inputs.track_mode == 0 ? inputs.racks[0].ydim : inputs.racks[0].xdim;

    let new_local = {
      // gcr_min: inputs.gcr_min,
      // gcr_max: inputs.gcr_max,
      // gcr_inc: inputs.gcr_inc,
      config_spacing_toggle: inputs.config_spacing_toggle,
      // pitch_min: inputs.pitch_min,
      // pitch_max: inputs.pitch_max,
      // pitch_inc: inputs.pitch_inc,
      gcr_count: inputs.gcr_count,
      racks: inputs.racks,
      tilt_min: inputs.tilt_min,
      track_mode: inputs.track_mode,
      gcrs: inputs.gcrs,
      pitch_count: inputs.pitch_count,
      pitches: inputs.pitches,
      simple_inverter: inputs.simple_inverter,
      // spi_min: inputs.spi_min,
      // spi_max: inputs.spi_max,
      // spi_inc: inputs.spi_inc,
      spis: inputs.spis,
      mod_rating: inputs.mod_rating,
      inv_rating: inputs.inv_rating,
      mod_per_string: inputs.mod_per_string,
      en_mod_target: inputs.en_mod_target,
      mod_target: inputs.mod_target,
      en_mwh_toggle: inputs.en_mwh_toggle,
      mwh_target: inputs.mwh_target,
      worker_limit: inputs.worker_limit,
      portfolio_sweep: inputs.portfolio_sweep,
      spi_minmax: {
        min: parseInt(Math.floor((inputs.inv_rating * 0.95) / ((inputs.mod_per_string * inputs.mod_rating) / 1000))),
        max: parseInt(Math.ceil((inputs.inv_rating * 3.01) / ((inputs.mod_per_string * inputs.mod_rating) / 1000))),
      },
      p_minmax: {
        min: _.round(dimension / 1.0, 1),
        max: _.round(dimension / 0.2, 1),
      },
      dcac_range: {
        min: _.round((inputs.spi_min * inputs.mod_per_string * inputs.mod_rating) / 1000 / inputs.inv_rating, 4),
        max: _.round((inputs.spi_max * inputs.mod_per_string * inputs.mod_rating) / 1000 / inputs.inv_rating, 4),
      },
      simple_inverter_dcac: inputs.simple_inverter_dcac,
    };
    return new_local;
  }, [
    inputs.gcr_min,
    inputs.gcr_max,
    inputs.gcr_inc,
    inputs.config_spacing_toggle,
    inputs.pitch_min,
    inputs.pitch_max,
    inputs.pitch_inc,
    inputs.gcr_count,
    inputs.gcrs,
    inputs.racks,
    inputs.track_mode,
    inputs.tilt_min,
    inputs.pitch_count,
    inputs.pitches,
    inputs.simple_inverter,
    inputs.spi_min,
    inputs.spi_max,
    inputs.spi_inc,
    inputs.spis,
    inputs.mod_rating,
    inputs.inv_rating,
    inputs.mod_per_string,
    inputs.en_mod_target,
    inputs.mod_target,
    inputs.en_mwh_toggle,
    inputs.mwh_target,
    inputs.worker_limit,
    inputs.portfolio_sweep,
    inputs.simple_inverter_dcac,
  ]);

  useEffect(() => {
    set_local_mod_target(inputs.mod_target);
  }, [inputs.mod_target]);

  useEffect(() => {
    if (tab == "config" && inputs.track_mode == 2) {
      recalculate_gcr_max_limit();
    }
  }, [tab]);

  const recalculate_gcr_max_limit = () => {
    let rack_x_dim = local_inputs.racks[0].xdim; // 4.592
    let tilt = local_inputs.tilt_min; // 8
    let max_gcr_limit = rack_x_dim / (2 * rack_x_dim * Math.cos(tilt * (Math.PI / 180))); // 0.504913786259309
    set_gcr_max_limit(+max_gcr_limit.toFixed(2));
  };

  // useEffect(() => {
  //   set_local_mwh_target(inputs.mwh_target);
  // }, [inputs.mwh_target]);

  useEffect(() => {
    set_local_simple_inverter_dcac(inputs.simple_inverter_dcac);
  }, [inputs.simple_inverter_dcac]);

  // useEffect(() => {
  //   const { inv_rating, mod_per_string, mod_rating, track_mode, racks } = inputs;
  //   // dispatch(siftActions.updateInput("recalc", null));
  //   // update the config tab
  //   let minSPI = parseInt(Math.floor((inv_rating * 0.95) / ((mod_per_string * mod_rating) / 1000)));
  //   let maxSPI = parseInt(Math.ceil((inv_rating * 3.01) / ((mod_per_string * mod_rating) / 1000)));
  //   set_spi_minmax({ min: minSPI, max: maxSPI });

  //   // var targetMinSPI = parseInt((1.1 * inv_rating * 1000) / (mod_per_string * mod_rating));
  //   // var targetMaxSPI = parseInt((1.5 * inv_rating * 1000) / (mod_per_string * mod_rating));

  //   // code that updates pitch_min_max and makes sure pitch_range is within a real range
  //   let dimension = track_mode == 0 ? racks[0].ydim : racks[0].xdim;
  //   set_p_minmax({ min: _.round(dimension / 1.0, 1), max: _.round(dimension / 0.2, 1) });

  //   let dcac_min = _.round((inputs.spi_min * mod_per_string * mod_rating) / 1000 / inv_rating, 4);
  //   let dcac_max = _.round((inputs.spi_max * mod_per_string * mod_rating) / 1000 / inv_rating, 4);
  //   set_dcac_range({ min: dcac_min, max: dcac_max });

  //   set_local_inputs(inputs);
  // }, [inputs]);

  useEffect(() => {
    if (account_loading || !project_loaded) set_this_loaded(false);
    else set_this_loaded(true);
  }, [account_loading, project_loaded]);

  const validateInput = (value) => {
    let decimalPart = String(value).split(".")[1];
    if (decimalPart && decimalPart.endsWith("0"))
      return false;
    return !_.isNaN(value);

  }
  const updateInput = (key, value) => {
    // console.log("orig:", key, value);
    // set_local_spacing({
    //   ...local_spacing,
    //   [key]: value,
    // });
    // do some input validation here

    if (key === "mod_target") {
      set_local_mod_target(value);
    }

    // if (key == "mwh_target") {
    //   set_local_mwh_target(value);
    // }
    if (key === "simple_inverter_dcac") {
      set_local_simple_inverter_dcac(value);
    }

    let bulk_update;

    let fixed_value = value;

    if (key === "gcr_min" || key === "gcr_max" || key === "pitch_min" || key === "pitch_max" || key === "gcr_inc" || key === "pitch_inc") {
      if (!validateInput(value)) {
        return;
      }
      fixed_value = parseFloat(value);
      set_local_spacing({
        ...local_spacing,
        [key]: fixed_value,
      });

      if (track_mode == 2) {
        if (key === "gcr_min" && fixed_value > gcr_max_limit && fixed_value <= gcr_max_limit) {
          bulk_update = { [key]: fixed_value, gcr_max: fixed_value };
        }
        if (key === "gcr_max" && (fixed_value < inputs.gcr_min || gcr_max_limit == inputs.gcr_min) && fixed_value >= 0.2) {
          bulk_update = { [key]: fixed_value, gcr_min: fixed_value };
        }
      } else {
        if (key === "gcr_min" && fixed_value > inputs.gcr_max && fixed_value <= 1.0) {
          bulk_update = { [key]: fixed_value, gcr_max: fixed_value };
        }
        if (key === "gcr_max" && fixed_value < inputs.gcr_min && fixed_value >= 0.2) {
          bulk_update = { [key]: fixed_value, gcr_min: fixed_value };
        }
      }
      if (key === "pitch_min" && fixed_value > inputs.pitch_max && fixed_value <= local_inputs.p_minmax.max) {
        bulk_update = { [key]: fixed_value, pitch_max: fixed_value };
      }
      if (key === "pitch_max" && (fixed_value < inputs.pitch_min || inputs.pitch_max == inputs.pitch_min) && fixed_value >= local_inputs.p_minmax.min) {
        bulk_update = { [key]: fixed_value, pitch_min: fixed_value };
      }
    }

    if (key === "en_mod_target" && fixed_value == 1) {
      bulk_update = { [key]: fixed_value, en_mwh_toggle: 0 };
    }
    // if (key == "en_mwh_toggle" && fixed_value == 1) {
    //   bulk_update = { [key]: fixed_value, en_mod_target: 0 };
    // }

    if (key === "spi_min" || key === "spi_max" || key === "spi_inc" || key === "mod_target" || key === "mwh_target" || key === "simple_inverter_dcac") {
      if (!validateInput(value)) {
        return;
      }

      fixed_value = parseInt(value);
      if (key === "spi_min" || key === "spi_max" || key === "spi_inc") {
        set_local_spi({
          ...local_spi,
          [key]: fixed_value,
        });
      }



      if (key === "spi_min" && fixed_value > inputs.spi_max && fixed_value <= local_inputs.spi_minmax.max) {
        bulk_update = { [key]: fixed_value, spi_max: fixed_value };
      }
      if (key === "spi_max" && (fixed_value < inputs.spi_min || inputs.spi_max == inputs.spi_min) && fixed_value >= local_inputs.spi_minmax.min) {
        bulk_update = { [key]: fixed_value, spi_min: fixed_value };
      }
    }

    if (key === "config_spacing_toggle" && value === "pitch") {
      if (inputs.pitch_min == 0 || inputs.pitch_max == 0) {
        // calculate a good min/max for pitch
        let p_dim = inputs.track_mode == 0 ? inputs.racks[0].ydim : inputs.racks[0].xdim;
        let p_min = _.round(p_dim / 0.44, 1);
        let p_max = _.round(p_dim / 0.35, 1);
        bulk_update = { [key]: value, pitch_min: p_min, pitch_max: p_max };
      }
    }

    if (bulk_update) {
      console.log("bulk", bulk_update);
      dispatch(inputsActions.update_bulk_inputs(bulk_update));
    } else {
      // console.log("fixed:", key, fixed_value);
      dispatch(inputsActions.update_input(key, fixed_value));
    }
  };

  // useEffect(() => {}, [tab, project_loaded]);

  useEffect(() => {
    if (isNumber(inputs.gcr_min) && isNumber(inputs.gcr_max) && isNumber(inputs.gcr_inc)) {
      if (track_mode == 2) {
        if (inputs.gcr_min < 0.2 || inputs.gcr_max > gcr_max_limit) {
          if (local_inputs.config_spacing_toggle === "gcr") {
            setRangeError(true);
            setErrMessage(`GCR values should be between 0.2 - ${gcr_max_limit}`);
            // set_worker_count(0);
          } else {
            setRangeError(false);
            setErrMessage(undefined);
          }
        }
      } else {
        if (inputs.gcr_min < 0.2 || inputs.gcr_max > 1.0) {
          if (local_inputs.config_spacing_toggle === "gcr") {
            setRangeError(true);
            setErrMessage(`GCR values should be between 0.2 - 1.0`);
            // set_worker_count(0);
          } else {
            setRangeError(false);
            setErrMessage(undefined);
          }
        }
      }
    } else {
      setRangeError(true);
      setErrMessage(track_mode == 2 ? `GCR values should be between 0.2 - 0.5` : `GCR values should be between 0.2 - 1.0`);
      // set_worker_count(0);
    }

    set_local_spacing({ ...local_spacing, gcr_min: inputs.gcr_min, gcr_max: inputs.gcr_max });
  }, [inputs.gcr_min, inputs.gcr_max, inputs.gcr_inc]);

  useEffect(() => {
    if (local_inputs.config_spacing_toggle !== "pitch") return;
    if (isNumber(local_spacing.pitch_min) && isNumber(local_spacing.pitch_max) && isNumber(local_spacing.pitch_inc)) {
      if (local_inputs.pitch_min < local_inputs.p_minmax.min || local_inputs.pitch_max > local_inputs.p_minmax.max) {
        if (local_inputs.config_spacing_toggle === "pitch") {
          setRangeError(true);
          setErrMessage(`Pitch values should be between ${local_inputs.p_minmax.min} - ${local_inputs.p_minmax.max}`);
          set_worker_count(0);
        }
      } else {
        setRangeError(false);
        setErrMessage(undefined);
      }
    } else {
      setRangeError(true);
      setErrMessage(`Pitch values should be between ${local_inputs.p_minmax.min} - ${local_inputs.p_minmax.max}`);
      set_worker_count(0);
    }
    set_local_spacing({ ...local_spacing, pitch_min: inputs.pitch_min, pitch_max: inputs.pitch_max });
  }, [inputs.pitch_min, inputs.pitch_max, inputs.pitch_inc]);

  useEffect(() => {
    set_local_spi({
      spi_min: inputs.spi_min,
      spi_max: inputs.spi_max,
      spi_inc: inputs.spi_inc,
    });

    // let spis = [];
    // if (isNumber(inputs.spi_min) && isNumber(inputs.spi_max) && isNumber(inputs.spi_inc)) {
    // 	for (let spi = inputs.spi_min; spi < inputs.spi_max; spi += inputs.spi_inc) {
    // 		spis.push(parseInt(spi));
    // 	}
    // 	spis.push(parseInt(inputs.spi_max));
    // }
    // let dcac_min = (inputs.spi_min * inputs.mod_per_string * inputs.mod_rating) / 1000 / inputs.inv_rating;
    // let dcac_max = (inputs.spi_max * inputs.mod_per_string * inputs.mod_rating) / 1000 / inputs.inv_rating;
    // set_dcac_range({min:_.round(dcac_min,4), max:_.round(dcac_max,4)})
    // updateInput('spis', spis);
  }, [inputs.spi_min, inputs.spi_max, inputs.spi_inc]);

  useEffect(() => {
    let count = 0;
    if (inputs.config_spacing_toggle === "gcr") {
      count = inputs.simple_inverter == 1 ? inputs.gcrs.length : inputs.gcrs.length * inputs.spis.length;
      // count = inputs.gcrs.length * inputs.spis.length;
    } else {
      count = inputs.simple_inverter == 1 ? inputs.gcrs.length : inputs.pitches.length * inputs.spis.length;
      // count = inputs.pitches.length * inputs.spis.length;

      // console.log("count", count);
      // console.log("worker", worker_count);
    }
    if (count != worker_count) set_worker_count(count);
  }, [inputs.gcrs, inputs.pitches, inputs.spis, inputs.simple_inverter]);

  if (tab !== "config") {
    return <></>;
  }

  return (
    <ConfigWrapper>
      {!this_loaded ? (
        <div className="spinner">
          <Spin style={{ padding: "0px", margin: "50px auto" }} size="large" spinning={!this_loaded} className="sift-loader" tip="Loading...">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div className="config-inputs" id="config-tip">
          <div className="config-input-label">
            <Radio.Group buttonStyle="solid" size="small" value={inputs.config_spacing_toggle}>
              <Radio.Button value={"gcr"} onClick={() => updateInput("config_spacing_toggle", "gcr")}>
                GCR
              </Radio.Button>
              <Radio.Button value={"pitch"} onClick={() => updateInput("config_spacing_toggle", "pitch")}>
                Pitch
              </Radio.Button>
            </Radio.Group>
          </div>
          {rangeError && (
            <div className="config-err">
              <InfoCircleOutlined style={{ color: "var(-primary-brand-color)", fontSize: "12px" }} />
              <p>{errMessage}</p>
            </div>
          )}

          <div className="config-input">
            {local_inputs.config_spacing_toggle === "gcr" && (
              <Fragment>
                <div className="min">
                  <label>Min(0.2)</label>
                  <Input
                    style={{ width: "75px" }}
                    id="gcr_min"
                    size="small"
                    type="number"
                    step={0.01}
                    value={local_spacing.gcr_min}
                    onBlur={(e) => {
                      if (track_mode == 2) {
                        if (e.target.value < 0.2 || e.target.value > gcr_max_limit) {
                          updateInput("gcr_min", local_spacing.gcr_max);
                        }
                      } else {
                        if (e.target.value < 0.2 || e.target.value > local_spacing.gcr_max) {
                          updateInput("gcr_min", local_spacing.gcr_max);
                        }
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        // checkInputRange(e.target.value, 0.2, local_inputs.gcr_max, `Min value should be between 0.2 - ${local_inputs.gcr_max}`);
                        updateInput("gcr_min", e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="dash">
                  <span>-</span>
                </div>
                <div className="max">
                  <label>{`${track_mode == 2 && gcr_max_limit ? `Max(${gcr_max_limit})` : "Max(1.0)"}`}</label>
                  <Input
                    style={{ width: "75px" }}
                    id="gcr_max"
                    size="small"
                    type="number"
                    step={0.01}
                    value={local_spacing.gcr_max}
                    onBlur={(e) => {
                      if (track_mode == 2) {
                        if (e.target.value < 0.2 || e.target.value > gcr_max_limit) {
                          updateInput("gcr_max", gcr_max_limit);
                        }
                      } else {
                        if (e.target.value < 0.2 || e.target.value > 1.0) {
                          updateInput("gcr_max", local_spacing.gcr_min);
                        }
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        // checkInputRange(e.target.value, 0.2, 1.0, `Max value should be between 0.2 - 1.0`);
                        updateInput("gcr_max", e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="comma">
                  <span>,</span>
                </div>
                <div className="increment">
                  <label>Step</label>
                  <Input
                    style={{ width: "60px" }}
                    id="gcr_inc"
                    size="small"
                    type="number"
                    step={0.01}
                    min={0.01}
                    max={0.1}
                    value={local_spacing.gcr_inc}
                    // onChange={(value)=> {OnUpdateInput('gcr_inc', value)}}
                    onChange={(e) => {
                      updateInput("gcr_inc", e.target.value);
                    }}
                  />
                </div>
                {/* <div className="total">
                  <p>{local_inputs.gcr_count}</p>
                  <label>Total</label>
                </div> */}
                <Tooltip placement="top" title={"GCR's: [" + Object.values(local_inputs.gcrs).map((gcr) => gcr.toString()) + "]"}>
                  <div className="total">
                    <p>
                      <b>
                        <u>{local_inputs.gcrs.length}</u>
                      </b>
                    </p>
                    <label>Total</label>
                  </div>
                </Tooltip>
              </Fragment>
            )}
            {local_inputs.config_spacing_toggle === "pitch" && (
              <Fragment>
                <div className="min">
                  <label>Min({local_inputs.p_minmax.min})</label>
                  <Input
                    style={{ width: "75px" }}
                    id="pitch_min"
                    size="small"
                    type="number"
                    step={0.01}
                    suffix={<span style={{ fontSize: "12px" }}>m</span>}
                    value={local_spacing.pitch_min}
                    onBlur={(e) => {
                      if (e.target.value > local_inputs.p_minmax.max) {
                        updateInput("pitch_min", local_inputs.p_minmax.max);
                      } else if (e.target.value < local_inputs.p_minmax.min) {
                        updateInput("pitch_min", local_inputs.p_minmax.min);
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        // checkInputRange(
                        //   e.target.value,
                        //   local_inputs.pitch_min,
                        //   local_inputs.pitch_max,
                        //   `Min value should be between ${local_inputs.pitch_min.toFixed(4)} - ${local_inputs.pitch_max.toFixed(4)}`
                        // );
                        updateInput("pitch_min", e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="dash">
                  <span>-</span>
                </div>
                <div className="max">
                  <label>Max({local_inputs.p_minmax.max})</label>
                  <Input
                    style={{ width: "75px" }}
                    id="pitch_max"
                    size="small"
                    type="number"
                    step={0.01}
                    suffix={<span style={{ fontSize: "12px" }}>m</span>}
                    value={local_spacing.pitch_max}
                    onBlur={(e) => {
                      if (e.target.value > local_inputs.p_minmax.max) {
                        updateInput("pitch_max", local_inputs.p_minmax.max);
                      } else if (e.target.value < local_inputs.p_minmax.min) {
                        updateInput("pitch_max", local_inputs.p_minmax.min);
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        // checkInputRange(
                        //   e.target.value,
                        //   local_inputs.pitch_min,
                        //   local_inputs.pitch_max,
                        //   `Max value should be between ${local_inputs.pitch_min.toFixed(4)} - ${local_inputs.pitch_max.toFixed(4)}`
                        // );
                        updateInput("pitch_max", e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="comma">
                  <span>,</span>
                </div>
                <div className="increment">
                  <label>Step</label>
                  <Input
                    style={{ width: "60px" }}
                    id="pitch_inc"
                    size="small"
                    type="number"
                    step={0.01}
                    min={0.01}
                    max={10}
                    value={local_spacing.pitch_inc}
                    defaultValue={local_spacing.pitch_inc}
                    onChange={(e) => {
                      updateInput("pitch_inc", e.target.value);
                    }}
                  />
                </div>
                <div className="total">
                  {/* <p>{local_inputs.pitch_count}</p>
                  <label>Total</label> */}
                  <Tooltip placement="top" title={"Pitches: [" + Object.values(local_inputs.pitches).map((pitch) => pitch.toString()) + "]"}>
                    <div className="total">
                      <p>
                        {" "}
                        <b>
                          {" "}
                          <u>{local_inputs.pitches.length}</u>{" "}
                        </b>{" "}
                      </p>
                      <label>Total</label>
                    </div>
                  </Tooltip>
                </div>
              </Fragment>
            )}
          </div>

          <div style={{ height: 10 }}></div>
          {local_inputs.simple_inverter == 0 && (
            <>
              <div className="config-input-label">
                <label>Strings Per Inverter</label>
              </div>
              <div className={`config-input ${local_inputs.spi_minmax.min <= 0 && local_inputs.spi_minmax.max <= 0 ? "error" : ""}`} style={{ marginLeft: 3 }}>
                {true ? (
                  <Fragment>
                    <div className="min">
                      <label>Min({local_inputs.spi_minmax.min})</label>
                      <Input
                        id="spi_min"
                        style={{ width: "75px" }}
                        step={1}
                        type="number"
                        size="small"
                        // disabled={internal_sift && local_inputs.simple_inverter == 0}
                        // min={stringsPerInverterMin}
                        min="1"
                        // max={stringsPerInverterMax}
                        // defaultValue={stringsPerInverterMin}
                        value={local_spi.spi_min}
                        onChange={(e) => {
                          updateInput("spi_min", e.target.value);
                        }}
                        onBlur={() => {
                          if ((_.isEmpty(local_spi.spi_min) || !Number.isInteger(local_spi.spi_min)) && inputs.spi_min) {
                            updateInput("spi_min", inputs.spi_min);
                          }
                        }}
                      />
                    </div>
                    <div className="dash">
                      <span>-</span>
                    </div>
                    <div className="max">
                      <label>Max({local_inputs.spi_minmax.max})</label>
                      <Input
                        id="spi_max"
                        style={{ width: "75px" }}
                        step={1}
                        type="number"
                        size="small"
                        // disabled={internal_sift && local_inputs.simple_inverter == 0}
                        // min={stringsPerInverterMin}
                        // max={stringsPerInverterMax}
                        value={local_spi.spi_max}
                        // defaultValue={stringsPerInverterMax}
                        onChange={(e) => {
                          updateInput("spi_max", e.target.value);
                          // OnUpdateInput("spi_range", [inputs.spi_range[0], parseInt(e.target.value)]);
                        }}
                        onBlur={() => {
                          if ((_.isEmpty(local_spi.spi_max) || !Number.isInteger(local_spi.spi_max)) && inputs.spi_max) {
                            updateInput("spi_max", inputs.spi_max);
                          }
                        }}
                      />
                    </div>
                    <div className="comma">
                      <span>,</span>
                    </div>
                    <div className="increment">
                      <label>Step</label>
                      <Input
                        style={{ width: "60px" }}
                        id="spi_inc"
                        type="number"
                        size="small"
                        // disabled={internal_sift && local_inputs.simple_inverter == 0}
                        step={1}
                        min={1}
                        max={50}
                        value={local_spi.spi_inc}
                        onChange={(e) => {
                          updateInput("spi_inc", e.target.value);
                        }}
                        onBlur={() => {
                          if ((_.isEmpty(local_spi.spi_inc) || !Number.isInteger(local_spi.spi_inc)) && inputs.spi_inc) {
                            updateInput("spi_inc", inputs.spi_inc);
                          }
                        }}
                      />
                    </div>
                    <div className="total" style={{ marginRight: 2 }}>
                      <Tooltip placement="top" title={"SPI's: [" + Object.values(local_inputs.spis).map((spi) => spi.toString()) + "]"}>
                        <div className="total">
                          <p>
                            <b>
                              <u>{local_inputs.spis.length}</u>
                            </b>
                          </p>
                          <label>Total</label>
                        </div>
                      </Tooltip>
                      {/* <p>{inputs.spi_count}</p>
                  <label>Total</label> */}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Alert
                      className="sift-alert"
                      message={
                        <span>
                          <Popover content={required_inputs(local_inputs.mod_rating, local_inputs.inv_rating, local_inputs.mod_per_string)} trigger="hover" placement="bottomLeft">
                            Missing Required Inputs
                          </Popover>
                        </span>
                      }
                      type="warning"
                      showIcon
                    />
                  </Fragment>
                )}
              </div>
            </>
          )}

          {/* {en_target_inputs && (
            <>
              <section className="config-three-col">
                <label>DC:AC</label>
                <section></section>
                <Input
                  size="small"
                  id="simple_inverter_dcac"
                  min={0.9}
                  max={1.8}
                  value={local_simple_inverter_dcac}
                  onChange={(e) => {
                    updateInput(e.target.id, e.target.value);
                  }}
                />
              </section>
            </>
          )} */}

          {/* {internal_sift && local_inputs.simple_inverter == 0 ? (
            <div className="spi-total">
              <div className="dc-ac">
                <p>{`DC:AC (ILR):`}</p>
                <span>{+local_inputs.simple_inverter_dcac}</span>
              </div>
            </div>
          ) : local_inputs.simple_inverter == 1 ? (
            <></>
          ) : (
            <div className="spi-total">
              <div className="dc-ac">
                <p>{`DC:AC (ILR):`}</p>
                <span>
                  {+local_inputs.dcac_range.min.toFixed(3)} - {+local_inputs.dcac_range.max.toFixed(3)}
                </span>
              </div>
            </div>
          )} */}

          {local_inputs.simple_inverter == 1 ? (
            <></>
          ) : (
            <div className="spi-total">
              <div className="dc-ac">
                <p>{`DC:AC (ILR):`}</p>
                <span>
                  {+local_inputs.dcac_range.min.toFixed(3)} - {+local_inputs.dcac_range.max.toFixed(3)}
                </span>
              </div>
            </div>
          )}

          {local_inputs.simple_inverter == 1 && (
            <>
              <div style={{ height: 15 }}></div>
              <section className="config-three-col">
                <label>Module Target</label>
                <Switch
                  size="small"
                  id="en_mod_target"
                  checked={local_inputs.en_mod_target == 1}
                  onChange={(checked) => {
                    updateInput("en_mod_target", checked ? 1 : 0);
                  }}
                />
                <Input
                  // style={{ width: '136px', marginLeft: 'auto' }}
                  size="small"
                  disabled={local_inputs.en_mod_target != 1}
                  id="mod_target"
                  type="number"
                  min={1}
                  max={10000000000}
                  value={local_mod_target}
                  onChange={(e) => {
                    updateInput(e.target.id, e.target.value);
                  }}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mod</span>}
                />
              </section>

              {/* <section className="config-three-col">
                <label>MWh Target</label>
                <Switch
                  size="small"
                  id="en_mwh_toggle"
                  checked={local_inputs.en_mwh_toggle == 1}
                  onChange={(checked) => {
                    updateInput("en_mwh_toggle", checked ? 1 : 0);
                  }}
                />
                <Input
                  // style={{ width: '136px', marginLeft: 'auto' }}
                  size="small"
                  disabled={local_inputs.en_mwh_toggle != 1}
                  id="mwh_target"
                  type="number"
                  min={1}
                  max={10000000000}
                  value={local_mwh_target}
                  onChange={(e) => {
                    updateInput(e.target.id, e.target.value);
                  }}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>MWh</span>}
                />
              </section>
               */}
              <section style={{ height: 15 }}></section>
            </>
          )}

          <section className="config-row">
            <section className="config-info-msg">
              {worker_count > local_inputs.worker_limit && (
                <div className="config-err">
                  <InfoCircleOutlined style={{ color: "var(-primary-brand-color)", fontSize: "12px" }} />
                  <p>{`Limit exceeded. Please adjust inputs`}</p>
                </div>
              )}
            </section>
            <section className="config-limit">
              <p className="underline">{`= ${worker_count}`}</p>
              <p>{`${local_inputs.worker_limit} Limit`}</p>
            </section>
          </section>
          {/* <section className="config-input-switch">
            <label>Portfolio Sweep</label>
            <Switch
              size="small"
              id="portfolio_sweep"
              checked={local_inputs.portfolio_sweep == 1}
              onChange={(checked) => {
                updateInput("portfolio_sweep", checked ? 1 : 0);
              }}
            />
          </section> */}

          {/* {!internal_sift && local_inputs.simple_inverter == 1 && (
            <section className="config-err" style={{ margin: "8px 0px", color: "red" }}>
              <InfoCircleOutlined style={{ fontSize: "12px" }} />
              <p>Finance diabled while running with Simple Inverter Input</p>
            </section>
          )} */}
        </div>
      )}
    </ConfigWrapper>
  );
};

export { ConfigInputs };
