import React, { useRef, useEffect } from "react";

import { getTopoScale } from "../Helpers";

const TopoScaleView = ({ name, topo_id, mode, setLiveReportMapRefs }) => {
  const scale = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLiveReportMapRefs(name, { height: scale.current.clientHeight, width: scale.current.clientWidth, className: name, type: "scale" });
    }, 500);
  }, []);

  return <img ref={scale} id={name} className={name} src={getTopoScale(topo_id, mode)} />;
};

export { TopoScaleView };
