//
import "./leaflet.bing";

// BELOW SUPPORTS REACT-LEAFLET V2
// import {GridLayer, withLeaflet} from 'react-leaflet';
// class BingLayer extends GridLayer {

//     createLeafletElement(props) {
//       return new L.bingLayer(props.bingkey, props);
//     }
// }

// export default withLeaflet(BingLayer);

// BELOW SUPPORTS REACT-LEAFLET V3
// import { createLayerComponent } from '@react-leaflet/core';

// const createLeafletElement = (props) => {

// 	const instance = L.bingLayer(props.bingkey, props);

// 	return { instance };
// }
// export default createLayerComponent(createLeafletElement);

// BELOW SUPPORTS REACT-LEAFLET V4
import { useEffect } from "react";
import { useMap } from "react-leaflet";

const BingLayer = (props) => {
  const map = useMap();

  useEffect(() => {
    // console.log("map", map)
    const instance = new L.BingLayer(props.bingkey, { ...props });
    map.addLayer(instance);
    // const container = context.layerContainer || context.map
    // container.addLayer(instance)

    return () => {
      map.removeLayer(instance);
    };
  }, []);

  return null;
};

export { BingLayer };
