import styled from "styled-components";

export const InputRow = styled.section`
  display: grid;
  grid-template-columns: ${(props) => (props.canopy_label ? "3fr 1fr " : "repeat(2, 1fr)")};
  place-items: center;
  padding: 0px;
  margin: 8px auto;

  label {
    grid-column: 1 / auto;
    margin-right: auto;
  }

  .copy-delete {
    display: flex;
    margin: 0 15px 0px auto;

    button {
      padding: 5px;
      img {
        height: 20px;
        width: 20px;
        filter: brightness(2);
      }
    }
  }
`;
