import React, { useState, Children } from "react";
import { GroupWrapper, InlineWrapper, ButtonWrapper } from "./styles";

const RadioButton = ({ value, checked, onChange, children, style, name, disabled, size, id, onClick }) => {
  // let labelClasses = `radio-button-wrapper ${checked ? "radio-button-wrapper-checked" : ""}`;
  // labelClasses += `${disabled ? " radio-button-wrapper-disabled" : ""}`;
  // labelClasses += ` radio-button-${size}`;
  // let spanClasses = `radio-button ${checked ? "radio-checked" : ""}`;
  // spanClasses += `${disabled ? " radio-disabled" : ""}`;
  // let inputClasses = `radio-button-input ${disabled ? "radio-input-disabled" : ""}`;
  const labelClasses = `radio-button-wrapper${checked ? " radio-button-wrapper-checked" : ""}${disabled ? " radio-button-wrapper-disabled" : ""} radio-button-${size}`;
  const spanClasses = `radio-button${checked ? " radio-checked" : ""}${disabled ? " radio-disabled" : ""}`;
  const inputClasses = `radio-button-input${disabled ? " radio-input-disabled" : ""}`;
  

  return (
    <ButtonWrapper style={style} className={labelClasses}>
      <span className={spanClasses}>
        <input id={id} name={name} className={inputClasses} type="radio" value={value} checked={checked} onChange={onChange} disabled={disabled} onClick={onClick}/>
        <span className="radio-inner"></span>
      </span>
      <span style={{ paddingLeft: 4, paddingRight: 4 }}>{children}</span>
    </ButtonWrapper>
  );
};
const Radio = ({ value, checked, onChange, children, style, name, disabled, id, onClick }) => {
  // let labelClasses = `radio-wrapper ${checked ? "radio-wrapper-checked" : ""}`;
  // labelClasses += `${disabled ? " radio-wrapper-disabled" : ""}`;
  // let spanClasses = `radio ${checked ? "radio-checked" : ""}`;
  // spanClasses += `${disabled ? " radio-disabled" : ""}`;
  // let inputClasses = `radio-input ${disabled ? "radio-input-disabled" : ""}`;
  const labelClasses = `radio-wrapper${checked ? " radio-wrapper-checked" : ""}${disabled ? " radio-wrapper-disabled" : ""}`;
  const spanClasses = `radio${checked ? " radio-checked" : ""}${disabled ? " radio-disabled" : ""}`;
  const inputClasses = `radio-input${disabled ? " radio-input-disabled" : ""}`;


  return (
    <InlineWrapper style={style} className={labelClasses}>
      <span className={spanClasses}>
        <input id={id} name={name} className={inputClasses} type="radio" value={value} checked={checked}
               onClick={onClick} onChange={onChange} disabled={disabled} />
        <span className="radio-inner"></span>
      </span>
      <span>{children}</span>
    </InlineWrapper>
  );
};

const RadioGroup = ({ options, value, onChange, children, style, name, disabled, buttonStyle, size, onClick }) => {
  let groupClasses = `${buttonStyle === "solid" ? "radio-group-solid " : ""}`;
  //groupClasses += `${ size === 'small' ? 'radio-group-small ' : ''}`;
  const handleRadioChange = (e) => {
    // console.log('radio checked', e.target.value);
    //setSelectedValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  const recursivelyCloneChildren = (children) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type.name === "RadioButton" || child.type.name === "Radio") {
          return React.cloneElement(child, {
            id: child.props.id || "",
            style: child.props.style,
            name: name,
            size: size,
            disabled: child.props.disabled,
            checked: child.props.value == value,
            onChange: handleRadioChange,
          });
        } else {
          const clonedChild = React.cloneElement(child, {}, recursivelyCloneChildren(child.props.children));
          return clonedChild;
        }
      }
      return child;
    });
  };
  if (buttonStyle === "solid") {
    if (options) {
      return (
        <GroupWrapper style={style} className={groupClasses}>
          {options?.map((option) => {
            return (
              <RadioButton key={option.label} value={option.value} name={name} size={size} disabled={disabled} checked={option.value == value} onChange={handleRadioChange}>
                {option.label}
              </RadioButton>
            );
          })}
        </GroupWrapper>
      );
    } else {
      return (
        <GroupWrapper style={style} className={groupClasses}>
          {recursivelyCloneChildren(children)}
          {/* {children?.map((element) => {
                        return (
                            <RadioButton 
                                key={element.props.value}
                                value={element.props.value} 
                                style={element.props.style}
                                name={name}
                                size={size}
                                disabled={disabled}
                                checked={element.props.value == value}
                                onChange={onChange}>
                                    {element.props.children}
                            </RadioButton>
                        )
                    })}  */}
        </GroupWrapper>
      );
    }
  } else {
    if (options) {
      return (
        <GroupWrapper style={style}>
          {options?.map((option) => {
            return (
              <Radio key={option.value} value={option.value} name={name} disabled={disabled} checked={option.value == value} onChange={handleRadioChange} onClick={onClick}>
                {option.label}
              </Radio>
            );
          })}
        </GroupWrapper>
      );
    } else {
      return (
        <GroupWrapper style={style}>
          {recursivelyCloneChildren(children)}
          {/* {children?.map((element) => {
                        return (
                            <Radio 
                                key={element.props.value}
                                value={element.props.value} 
                                style={element.props.style}
                                name={name}
                                disabled={disabled}
                                checked={element.props.value == value}
                                onChange={onChange}>
                                    {element.props.children}
                            </Radio>
                        )
                    })}  */}
        </GroupWrapper>
      );
    }
  }
};

export { Radio, RadioGroup, RadioButton };
