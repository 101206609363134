import styled from "styled-components";

const GroupWrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &.radio-group-solid > span:first-child label {
    border-radius: 4px 0 0 4px !important;
  }

  &.radio-group-solid > span:last-child label {
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
  }

  &.radio-group-solid > span label {
    border-radius: 0 0 0 0 !important;
  }
  &.radio-group-solid > section:first-child label {
    border-radius: 4px 0 0 4px !important;
  }

  &.radio-group-solid > section:last-child label {
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
  }

  &.radio-group-solid > section label {
    border-radius: 0 0 0 0 !important;
  }
`;
const InlineWrapper = styled.label`
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin: 0px 3px;
  cursor: pointer;

  &.radio-wrapper-disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
  }

  &::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: "\a0";
  }

  .radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    position: relative;
    display: inline-block;
    outline: none;
    cursor: pointer;
    align-self: center;

    &.radio-disabled {
      cursor: not-allowed;
    }

    &.radio-checked {
      .radio-inner {
        border-color: #002bcb; //var(--primary-brand-color);
        background-color: #002bcb; //var(--primary-brand-color);
        &::after {
          transform: scale(0.375);
          opacity: 1;
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        }
      }

      &::after {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #002bcb; //var(--primary-brand-color);
        border-radius: 50%;
        visibility: hidden;
        animation-name: css-dev-only-do-not-override-15rg2km-antRadioEffect;
        animation-duration: 0.3s;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-fill-mode: both;
        content: "";
      }
    }
  }

  &.radio-wrapper span.radio + * {
    padding-inline-start: 4px;
    /* padding-inline-end: 8px; */
  }

  .radio-checked {
    .radio-inner {
      border-color: #002bcb; //var(--primary-brand-color);
      background-color: #002bcb; //var(--primary-brand-color);
      &::after {
        transform: scale(0.375);
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
    }

    &::after {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #002bcb; //var(--primary-brand-color);
      border-radius: 50%;
      visibility: hidden;
      animation-name: css-dev-only-do-not-override-15rg2km-antRadioEffect;
      animation-duration: 0.3s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: both;
      content: "";
    }
  }

  .radio-input {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    &.radio-input-disabled {
      cursor: not-allowed;
    }
  }

  .radio-disabled {
    .radio-inner {
      background-color: rgba(0, 0, 0, 0.04) !important;
      border-color: #d9d9d9 !important;
      cursor: not-allowed;
      ::after {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .radio-inner {
    box-sizing: border-box;
    position: relative;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.2s;
    &::after {
      box-sizing: border-box;
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      display: block;
      width: 16px;
      height: 16px;
      margin-block-start: -8px;
      margin-inline-start: -8px;
      background-color: #fff;
      border-block-start: 0;
      border-inline-start: 0;
      border-radius: 16px;
      transform: scale(0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: "";
    }
  }
`;

const ButtonWrapper = styled.label`
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-block-start-width: 1.02px;
  border-inline-start-width: 0;
  border-inline-end-width: 1px;
  cursor: pointer;
  transition: color 0.2s, background 0.2s, border-color 0.2s, box-shadow 0.2s;

  &:not(:first-child)::before {
    position: absolute;
    inset-block-start: -1px;
    inset-inline-start: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding-block: 1px;
    padding-inline: 0;
    background-color: #d9d9d9;
    transition: background-color 0.3s;
    content: "";
  }

  &:first-child {
    border-inline-start: 1px solid #d9d9d9;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
  }

  &:last-child {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }

  &.radio-button-small {
    height: 24px;
    padding-inline: 2px;
    padding-block: 0;
    line-height: 22px;
  }

  &.radio-button-large {
    height: 40px;
    font-size: 16px;
    line-height: 38px;
  }

  &.radio-button-wrapper-checked {
    color: #fff;
    background: #002bcb; //var(--primary-brand-color);
    border-color: #002bcb; //var(--primary-brand-color);
    z-index: 1;
  }
  &.radio-button-wrapper-disabled {
    //color: rgba(0, 0, 0, 0.25);
    //background-color: rgba(0, 0, 0, 0.15);
    border-color: #d9d9d9;
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  .radio-button {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .radio-button-input {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
`;

export { GroupWrapper, InlineWrapper, ButtonWrapper };
