import React, { useState, useEffect } from "react";
import { ThirtyPercentSelectLayout } from "./styles";

// component

const ThirtyPercentSelect = ({ id, labelText, input_value, options = undefined, updateThirtyPercentInputs }) => {
  return (
    <ThirtyPercentSelectLayout key={id}>
      <label>{labelText}</label>
      <select id={id} value={input_value} onChange={(e) => updateThirtyPercentInputs(e.target.id, e.target.value)}>
        {Object.values(options).map((opt, index) => (
          <option key={index} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </ThirtyPercentSelectLayout>
  );
};

export { ThirtyPercentSelect };
