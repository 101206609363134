import React from "react";
import { SpinWrapper, DivWrapper } from "./styles";

const Spin = ({ style, size, children, spinning, text, indicator }) => {
  let css_classes = `${spinning === undefined || spinning ? "spin-spinning" : ""} ${size} `;
  const dot_classes = `spin-dot-item ${size}`;
  const spin_classes = `spin-dot spin-dot-spin ${size}`;

  const spinWrapper = (
    <SpinWrapper className={css_classes + "spin"} aria-live="polite" aria-busy="true" style={style}>
      {!indicator && (
        <span className={spin_classes}>
          <i className={dot_classes}></i>
          <i className={dot_classes}></i>
          <i className={dot_classes}></i>
          <i className={dot_classes}></i>
        </span>
      )}
      {indicator && <>{indicator}</>}
      {text && <div className="spin-text">{text}</div>}
    </SpinWrapper>
  );
  return (
    <>
      {!children && <div>{spinWrapper}</div>}
      {children && (
        <DivWrapper className="spin-nested-loading">
          <div>{spinWrapper}</div>
          <div className="spin-container spin-blur">{children}</div>
        </DivWrapper>
      )}
    </>
  );
};

export { Spin };
