import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { siftActions, getProjectInputs, getSiteCenter } from "../Redux";

import { Result, Spin, Input } from "antd";
import { Modal, TextArea } from "../BaseComponents";

const BugReportTextArea = ({ handleBugDescUpdate, bugText }) => {
  return <TextArea rows={6} style={{ width: "100%", padding: 3 }} placeholder="Enter a short description of the bug." onChange={handleBugDescUpdate} value={bugText.current} />;
};

const BugReport = () => {
  const [bugDesc, setBugDesc] = useState("");
  const [bugSent, setBugSent] = useState(false);
  const [bugSending, setBugSending] = useState(false);
  const dispatch = useDispatch();

  const bugText = useRef();

  const inputs = useSelector((state) => state.inputs);
  const canopy = useSelector((state) => state.canopy);
  const ioManager = useSelector((state) => state.sift.ioManager);
  const bugVisible = ioManager.uiState.bug_visible;

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  function hideBugReport() {
    setBugSending(false);
    setBugSent(false);
    bugText.current = undefined;
    // setBugDesc("");
    dispatch(siftActions.toggleHelpToolbarVisibility("bug_visible", false));
  }

  function get_inputs() {
    let project_inputs;
    if (internal_sift && using_canopy) {
      project_inputs = {
        project_id: inputs.project_id,
        rectangles: canopy.canopies,
        images: canopy.images,
        project_name: inputs.project_name,
        module: inputs.mod_id,
        map_center: Object.values(canopy.canopies).length > 0 ? getSiteCenter(canopy.canopies) : inputs.map_center,
        uiState: { pm_modal_visible: false },
      };
    } else {
      project_inputs = { ...inputs };
    }
    return project_inputs;
  }

  function handleBugReport(event) {
    setBugSending(true);

    let browser_version = undefined;
    try {
      browser_version = get_browser_and_version();
    } catch (error) {
      console.log(`Couldn't detect browser version`);
    }

    let bug_message = `${bugText.current} ${browser_version ? `(Browser: ${browser_version})` : "(Browser: N/A)"}`;
    // grab either canopy or SIFT inputs
    let project_inputs = get_inputs();
    let params = {
      message: bug_message,
      project_type: 4,
      inputs: {
        message: bugText.current,
        ...project_inputs,
      },
    };

    // console.log("params", params);

    dispatch(siftActions.sendBugReport({ ...params }));

    setTimeout(() => {
      setBugSent(true);
      setBugSending(false);

      setTimeout(() => {
        hideBugReport();
      }, 2000);
    }, 2000);
  }

  function handleBugDescUpdate(event) {
    bugText.current = event.target.value;
    // setBugDesc(desc);
  }

  function get_browser_and_version() {
    let ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  }

  return (
    <Modal
      title="Bug Report"
      // visible={bugVisible}
      // afterOpenChange={(e) => {
      //   manage_modal_zIndex(e);
      // }}
      open={bugVisible}
      onOk={handleBugReport}
      okText="Send"
      width={"500px"}
      height={"275px"}
      confirmLoading={bugSending}
      onCancel={hideBugReport}
      okButtonProps={{ disabled: bugSent }}
      className="sift-modal bug-report"
      destroyOnClose={true}
    >
      {bugSent ? (
        <Result status="success" title="Thank you for the report!" subTitle="This window will close in 2 seconds" />
      ) : (
        <div>
          <div style={{ height: "5px" }}></div>
          {bugSending ? (
            <Spin spinning={bugSending} tip="Sending report...">
              <div style={{ width: "100%", height: "75px" }}></div>
            </Spin>
          ) : (
            <>
              <i>SIFT is updated regularly. Before submitting a bug report, try refreshing the page. Old tabs cause bugs!</i>
              <br></br>
              <BugReportTextArea handleBugDescUpdate={handleBugDescUpdate} bugText={bugText} />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export { BugReport };
