import { message } from 'antd';

export function handleAlert(alert) {
  switch (alert.type) {
    case "success":
      message.success(alert.msg, 3.5)
    break;

    case "error":
      message.error(alert.msg, 4)
    break;

    case "info":
      message.info(alert.msg, 3.5)
    break;

    case "warning":
      message.warning(alert.msg, 3.5)
    break;

    case "loading":
      message.loading(alert.msg, 3.5)
    break;

    case "clear":
      message.destroy();
    break;
  }
}

