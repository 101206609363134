const PerformanceDD = {
  0: {
    "id": 0,
    "data": {
      "default": 0,
      "name": "Custom Performance",
      "performance_model": 0,
      "doGridPower": 0,
      "grid_power_limit": 0,
      "modules_per_string": 28,
      "dc_degradation": 0.5,
      "irrad_mode": 2,
      "mismatch_loss": 1,
      "sky_model": 2,
      "dc_loss_stc": 1.5,
      "quality_loss": 0,
      "lid_loss": 2,
      "ac_losses": 4,
      "thermal_uc": 29,
      "thermal_uv": 0,
      "albedo": 0.2,
      "doMonthlyAlbedo": 0,
      "albedo_monthly": [0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2],
      "soiling": 2,
      "doMonthlySoiling": 0,
      "soiling_monthly": [2,2,2,2,2,2,2,2,2,2,2,2],
      "g_input": 0.5, 
    }
  }  
}

export { PerformanceDD }