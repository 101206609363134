import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, canopyActions, usePrevious } from "../../../../../Redux";
import { module_input_keys, pick } from "../../../CanopyHelpers";

import { Button, Menu, Dropdown, Popconfirm, Select } from "antd";
import { DownOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { ModuleSelectRow, LockButton } from "./styles";
import { down, left, right, up } from "../../../../../../assets/images";

// function ModuleOverlay(selected_canopy, local_modules, dispatch) {
//   function onSelectModule(e) {
//     dispatch(canopyActions.updateCanopyProperty(selected_canopy, "module", local_modules[parseInt(e.key)], true));
//   }
//
//   return (
//     <Menu style={{ height: "300px", overflowY: "auto" }} onClick={onSelectModule}>
//       {Object.values(local_modules).map((module, index) => (
//         <Menu.Item key={index} eventKey={index}>
//           {module.mod_name}
//         </Menu.Item>
//       ))}
//     </Menu>
//   );
// }

function ModuleSelect() {
  const dispatch = useDispatch();

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const selected_canopy_mod_id = useSelector((state) => state.canopy.canopies[selected_canopy]?.module?.mod_id);

  const mod_name = useSelector((state) => state.canopy.canopies[selected_canopy]?.module?.mod_name);
  const module_locked = useSelector((state) => state.canopy.canopies[selected_canopy]?.module_locked);
  const prev_modules_locked = usePrevious(module_locked);
  const modules = useSelector((state) => state.user.presets["module"]);
  const current_primary_module = useSelector((state) => pick(state.inputs, module_input_keys));
  const [local_modules, set_local_modules] = useState(modules);

  useEffect(() => {
    if (prev_modules_locked !== undefined && prev_modules_locked == false && module_locked) {
      if (selected_canopy_mod_id !== current_primary_module) {
        dispatch(canopyActions.updateCanopyProperty(selected_canopy, "module", current_primary_module, true));
      }
    }
  }, [module_locked]);

  const onSelectModule = (value) => {
    dispatch(canopyActions.updateCanopyProperty(selected_canopy, "module", local_modules[parseInt(value)], true));
  };
  const items = [];
  Object.values(local_modules).map((module, index) => items.push({ label: module.mod_name, value: index }));
  // const menuProps = {
  //   items,
  //   onClick: onSelectModule,
  // };

  return (
    <ModuleSelectRow>
      {selected_canopy_mod_id == current_primary_module.mod_id || module_locked ? (
        <LockButton onClick={() => dispatch(canopyActions.updateCanopyProperty(selected_canopy, "module_locked", !module_locked, true))}>
          {module_locked ? <LockOutlined /> : <UnlockOutlined />}
        </LockButton>
      ) : (
        <Popconfirm
          placement="right"
          title={`By locking this canopy's module it will be changed to your currently selected module in the module tab(${current_primary_module.mod_name})`}
          onCancel={() => console.log("canceled")}
          okText="Yes"
          cancelText="No"
          style={{ maxWidth: "400px" }}
          onConfirm={() => {
            dispatch(canopyActions.updateCanopyProperty(selected_canopy, "module_locked", !module_locked, true));
          }}
        >
          <LockButton>{module_locked ? <LockOutlined /> : <UnlockOutlined />}</LockButton>
        </Popconfirm>
      )}

      {/*<Dropdown disabled={module_locked} menu={menuProps}  placement="bottom" trigger={["click"]}>*/}
      {/*  <Button style={{ width: "200px", overflowX: "auto" }}>*/}
      {/*    {mod_name}*/}
      {/*    <DownOutlined />*/}
      {/*  </Button>*/}
      {/*</Dropdown>*/}
      <Select
        key={mod_name}
        defaultValue={mod_name}
        onChange={onSelectModule}
        style={{
          width: 200,
        }}
        options={items}
        disabled={module_locked}
      ></Select>
    </ModuleSelectRow>
  );
}

export { ModuleSelect };
