import styled from "styled-components";

const LayoutEditorWrap = styled.section`
  width: 100%;
  height:100%
  display: grid;
  overflow-y: auto;

  .editor-map {
    height:100%;
    width:100%;
  }
 
`;

const LayoutEditorToolsWrap = styled.section`
  width: 100%;
  padding: 10px;

  .rack {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "rackName actionButtons";
    margin: 3px 0px;

    .rack-name {
      grid-area: rackName;
      white-space: nowrap;
    }

    .rack-actions {
      grid-area: actionButtons;
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
`;

export { LayoutEditorWrap, LayoutEditorToolsWrap };
