import styled, { keyframes } from 'styled-components';

const springIn = keyframes`
 0% { transform: scale(0.5)}
 100% { transform: scale(1)}
`;
const TutorialNavWrap = styled.section`
  position: absolute;
  z-index: 601;
  box-sizing: border-box;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  height: auto;
  width: 375px;
  border-radius: 3px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-left: 6px solid var(--primary-brand-color);
  padding: 10px 12px;
  animation-name: ${springIn};
  animation-duration: 200ms;
  animation-timing-function: var(--easing-spring);
  transition: all 0.8s var(--easing-spring);

  @media (max-width: 768px) {
    top: ${(props) => (props.moveNavToBottom ? '70%' : '60px')};
  }

  .tutorial-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto auto;

    .close-button {
      position: absolute;
      top: 5px;
      right: 10px;

      &:hover {
        opacity: 0.8;
      }
    }

    .tutorial-main-text {
      grid-row: 1;
      display: grid;
      grid-template-rows: auto 30px;

      .tutorial-nav-header {
        grid-row: 1;
        display: flex;

        img {
          margin-right: 8px;
        }
        h3 {
          margin: 0px;
        }
      }

      p {
        white-space: nowrap;
      }
    }

    .tutorial-next-steps-buttons {
      grid-row: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tutorial-footer {
      grid-row: 2;
      grid-column: 1 / -1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0px 10px;
      }

      .tutorial-buttons {
        button {
          box-sizing: border-box;
          height: 20px;
          margin: 0px 5px;
          color: var(--primary-brand-color);
          background-color: #fff;
          border: 1px solid var(--primary-brand-color);
          border-radius: 3px;
          line-height: 15px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

          &:hover {
            cursor: pointer;
            color: #fff;
            background-color: var(--primary-brand-color);
          }
        }
      }
    }
  }

  /* .tutorial-content {
    display: flex;
    flex-direction: column;
    grid-row: 1;

    .tutorial-main-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      img {
        margin: 3px 10px 0px 0px;
      }
    }

    .tutorial-buttons {
      display: flex;
      justify-content: flex-end;

    
    }
  } */
`;

const TutorialWrap = styled.section`
  position: absolute;
  z-index: 601;
  inset: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #fff;
  width: 345px;
  height: 220px;
  border-radius: 3px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-left: 6px solid var(--primary-brand-color);
  padding: 15px 10px 10px 10px;
  animation-name: ${springIn};
  animation-duration: 200ms;
  animation-timing-function: var(--easing-spring);

  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  .tutorial-start {
    display: flex;
    align-items: flex-start;

    .icon {
      img {
        margin-right: 8px;
      }
    }

    .tutorial-text {
      h3 {
        margin: 0px auto 8px auto;
        font-weight: 500;
      }

      p {
        margin: 0px auto 15px auto;
        color: #333;
      }
    }
  }

  .tutorial-start-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      padding: 5px;
      font-size: 14px;
      width: 70%;
      margin: 5px auto;
      background: #fff;
      color: var(--primary-brand-color);
      border: 1px solid var(--primary-brand-color);
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      outline-color: var(--primary-brand-color);

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: var(--primary-brand-color);
      }
    }
  }

  .dont-show {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const TutorialFunnelWrap = styled(TutorialWrap)`
  position: absolute;
  z-index: 500;
  box-sizing: border-box;
  inset: unset;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  height: auto;
  width: 375px;
  border-radius: 3px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-left: 6px solid var(--primary-brand-color);
  padding: 10px 15px;
  animation-name: ${springIn};
  animation-duration: 200ms;
  animation-timing-function: var(--easing-spring);

  @media (max-width: 768px) {
    top: 60px;
  }
`;

export { TutorialNavWrap, TutorialWrap, TutorialFunnelWrap };
