import styled from "styled-components";

export const InputRadios = styled.section`
  margin: 5px auto;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
`;
