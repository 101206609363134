import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../Redux";
import { useMap, useMapEvents, GeoJSON } from "react-leaflet";
import { usePrevious, create_UUID } from "../Redux";
import { swapRacks } from "./LayoutEditorHelper";

const LayoutLayerHandler = ({ id, rack }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id);
  const selected_rack_layout_id = useSelector((state) => state.inputs.selected_rack_layout_id);
  const prev_selected_rack_layout_id = usePrevious(selected_rack_layout_id);

  const [rack_data, set_rack_data] = useState(rack);
  const [force_update_id, set_force_update_id] = useState();

  if (!rack_data) return null;

  useEffect(() => {
    // console.log("updated", rack);
    set_rack_data(rack);
    // console.log("Rack", rack);
    if (rack.index == selected_rack_layout_id) {
      set_force_update_id(create_UUID());
    }
  }, [rack]);

  useEffect(() => {
    if (selected_rack_layout_id == id) {
      // highlight
      let layer = getLayer(selected_rack_layout_id);

      if (layer) {
        layer.setStyle({ weight: 2, fillColor: "orangered", fillOpacity: 0.7 });
      }
    } else if (prev_selected_rack_layout_id == id || prev_selected_rack_layout_id == undefined) {
      // unhighlight
      let layer = getLayer(prev_selected_rack_layout_id);
      if (layer) {
        layer.setStyle({ weight: 2, fillColor: "transparent" });
      }
    }
  }, [selected_rack_layout_id]);

  const onEachFeature = (item, layer) => {
    layer.on({
      click: () => {
        // if (layer.options.index == id) {
        //   console.log("rack", rack);
        // }
        dispatch(inputsActions.update_input("selected_rack_layout_id", layer.options.index));
      },
    });
  };

  const getLayer = (id) => {
    let temp_layer;
    map.eachLayer((layer) => {
      if (layer.feature && layer.options.index == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  const memoizedGeoJSON = useMemo(
    () => (
      <GeoJSON
        data={rack_data.geoJson}
        transform={false}
        draggable={false}
        key={`${rack_data.index}-${force_update_id}`}
        disabled_rack={rack_data.disabled_rack}
        index={rack_data.index}
        onEachFeature={onEachFeature}
        bubblingMouseEvents={false}
        type="editable_rack"
        style={{
          fillColor: selected_rack_layout_id === id ? "orangered" : "transparent",
          fillOpacity: selected_rack_layout_id === id ? 0.7 : 0,
          weight: 2,
          color: selected_rack_layout_id === id ? "orangered" : rack_data.enabled === false ? "#A710A7" : "#33A2FF",
        }}
      />
    ),
    [rack_data, selected_rack_layout_id, id, force_update_id]
  );

  return memoizedGeoJSON;

  // const cur_hover_id = useSelector((state) => state.inputs.cur_hover_id);

  // useEffect(() => {
  //   if (cur_hover_id == feature.index) {
  //     // highlight
  //     let layer = getLayer(cur_hover_id);

  //     if (layer) {
  //       layer.setStyle({ weight: 2, fillColor: "hotpink", fillOpacity: 0.9 });
  //     }
  //   } else {
  //     // unhighlight
  //     let layer = getLayer(feature.index);
  //     if (layer) {
  //       layer.setStyle({ weight: 2, fillColor: "transparent" });
  //     }
  //   }
  // }, [cur_hover_id]);

  // useEffect(() => {
  //   if (selected_rack_layout_id == id) {
  //     // highlight
  //     let layer = getLayer(selected_rack_layout_id);

  //     if (layer) {
  //       layer.setStyle({ weight: 2, fillColor: "hotpink", fillOpacity: 0.9 });
  //     }
  //   } else if (prev_selected_rack_layout_id == id) {
  //     // unhighlight
  //     let layer = getLayer(prev_selected_rack_layout_id);
  //     if (layer) {
  //       layer.setStyle({ weight: 2, fillColor: "transparent" });
  //     }
  //   }
  // }, [selected_rack_layout_id]);
};

export { LayoutLayerHandler };
