import React from 'react';

import { connect } from 'react-redux';
import { authActions, isAuthenticated } from '../../components/Redux';

import { Agreement } from './agreement';
import { Info } from '../../components/Funnel/info'

import {
	Form, Input, Select, Checkbox, Button, AutoComplete,
} from 'antd';
import {
	UserOutlined,
	LockOutlined
} from '@ant-design/icons';

const FormItem = Form.Item;

const Info_Reg_Error = ({errors}) =>{
  return (
    <Info text={
      <div>
      	<strong>Registration Errors</strong>
				<ol>
					{errors.map( (error, index) => {
						return(
							<li key={index}>{error}</li>
						)
					})}
				</ol>			
    	</div>
    } /> 
  )
}


class Register extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: {
				first_name: '',
				company: '',
				email: '',
				password1: '',
				password2: '',
				apiKey: 'none',
				apiKeyId: '',
				stripeId: '',
				product_plan: ''
			},
			submitted: false,
			error: undefined,
			confirmDirty: false,
			autoCompleteResult: [],
			agree_terms: false,
			visible: false
		};

		this.formRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
		this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
		this.validateToNextPassword = this.validateToNextPassword.bind(this);
	}

	handleOk = e => {
		// console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleCancel = e => {
		// console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleChange(event) {
		const { name, value } = event.target;
		const { user } = this.state;
		this.setState({
			error: undefined,
			user: {
				...user,
				[name]: value
			}
		});
	}

	handleSubmit = values => {
		const { dispatch } = this.props;
		const { user } = this.state;
		
		let pw_valid = user.password1 === user.password2 && user.password1.length > 4
		if (!pw_valid) {
			this.setState({error: "Passwords must match and be at least 8 characters." })
		} else if (!this.state.agree_terms) {
			this.setState({error: "Must accept Terms to continue" })

		} else {
			this.setState({ submitted: true, error: undefined });
			dispatch(authActions.register(user, this.props.forward));
		}

	}
	handleConfirmBlur(e) {
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	}
	compareToFirstPassword(rule, value, callback) {
		const form = this.props.form;
		if (value && value !== form.getFieldValue('password1')) {
			callback('Passwords do not match.');
		} else {
			callback();
		}
	}
	validateToNextPassword(rule, value, callback) {
		const form = this.props.form;
		if (value && this.state.confirmDirty) {
			form.validateFields(['password2'], { force: true });
		}
		callback();
	}


	render() {
		const { registering, loggingIn } = this.props;
		const loading = registering || loggingIn;
		const validateMessages = {
			required: 'This field is required!',
			types: {
				email: 'Not a validate email!',
				number: 'Not a validate number!',
			},
			number: {
				range: 'Must be between ${min} and ${max}',
			},
		};		

		return (
			// justify='center' type='flex' style={{ padding: '25px' }}
			<div >

				<Form onFinish={this.handleSubmit} className="login-form" validateMessages={validateMessages} ref={this.formRef}>
					<div style={{ height: 15 }}></div>
					<FormItem
            name='email'
            className="input"
            rules={[{ required: true, message: 'Please input your email!' }]}
					>
						<Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							placeholder="Email" name="email"  disabled={loading}
							// ref={(input) => input && input.focus()}
							onChange={this.handleChange} className="inner-input"/>
					</FormItem>

					<div style={{ height: 6 }}></div>

					<FormItem
						rules={[{ required: true}]}
						name='password1'
						className="input"
						rules={[{ required: true, message: null }]}
					>
						<Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							name="password1" onChange={this.handleChange} type="password" disabled={loading}
							placeholder="Password" className="inner-input"
						/>
					</FormItem>

					<div style={{ height: 6 }}></div>

					<FormItem
						name='password2'
						className="input"
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input name="password2" onChange={this.handleChange}
							onBlur={this.handleConfirmBlur} disabled={loading}
							prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password" placeholder="Confirm Password" className="inner-input"
						/>
					</FormItem>

					<div style={{ height: 6 }}></div>

					<FormItem
						valuePropName='agree_terms'
						className="input"
						rules={[{ required: true, message: 'Must accept Terms to continue' }]}
					>
						<Checkbox name="agree_terms" disabled={loading}
							checked={this.state.agree_terms} onChange={(e)=> this.setState({agree_terms: e.target.checked})}
						>
							I accept the <a onClick={() => this.setState({ visible: true })}>Terms and Conditions</a>							
						</Checkbox>
					</FormItem>

					<FormItem>
						{this.state.error &&
						<span style={{color:'red'}} className="input">						
							{this.state.error}
						</span>
						}
						{this.props.register_error && this.props.register_error.length > 0 &&
						<span style={{color:'red'}} className="input">						
							<span><Info_Reg_Error errors={this.props.register_error}/> Must be a valid email address. 
							Password requirements: 8 character minimum, at least 1 non-numeric, no common passwords </span>
						</span>
						}
					</FormItem>

					<FormItem  className="button-wrapper">
						<Button type="primary" htmlType="submit" 
							loading={loading} className='button'
						>
							{registering ? "Registering..." : loggingIn ? "Logging in..." : "Register"}
						</Button>
					</FormItem>
				</Form>


				<Agreement 					
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel} 
				/>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { registering, loggingIn, register_error } = state.user;
	return {
		registering,
		loggingIn,
		register_error,
		isAuthenticated: isAuthenticated(state),
	};
}

const connectedRegister = connect(mapStateToProps)(Register);
export { connectedRegister as Register };