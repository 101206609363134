export const NaturalLocations = [
  { name: "Australia", region: "", latlng: { lat: -10.040181, lng: 143.560709 }, zoomLevel: 11 },
  { name: "Chile", region: "Tamarugal", latlng: { lat: -19.140249, lng: -68.683995 }, zoomLevel: "15" },
  { name: "China", region: "Ngari", latlng: { lat: 30.109104, lng: 84.817876 }, zoomLevel: "15" },
  { name: "China", region: "Ngari", latlng: { lat: 30.248502, lng: 85.047215 }, zoomLevel: "16" },
  { name: "China", region: "Ngari", latlng: { lat: 30.349926, lng: 85.157309 }, zoomLevel: "17" },
  { name: "Antarctica", region: "", latlng: { lat: -76.646349, lng: 160.41473 }, zoomLevel: 10.25 },
  { name: "Antarctica", region: "", latlng: { lat: -71.924341, lng: 25.157817 }, zoomLevel: "10" },
  { name: "China", region: "Shannan", latlng: { lat: 29.14274, lng: 90.513512 }, zoomLevel: "15" },
  { name: "Kazakhstan", region: "Beyneu District", latlng: { lat: 45.351844, lng: 53.524919 }, zoomLevel: "14" },
  { name: "Libya", region: "Al Kufrah", latlng: { lat: 24.659177, lng: 24.968061 }, zoomLevel: "15" },
  { name: "Egypt", region: "Qesm Al Wahat Ad Dakhlah", latlng: { lat: 23.604734, lng: 25.315547 }, zoomLevel: "15" },
  { name: "United States", region: "Porcupine", latlng: { lat: 43.536867, lng: -102.360299 }, zoomLevel: "17" },
  { name: "China", region: "Hainan", latlng: { lat: 35.286769, lng: 100.291448 }, zoomLevel: "16" },
  { name: "China", region: "Ngari", latlng: { lat: 33.68746, lng: 79.859533 }, zoomLevel: "15" },
  { name: "Libya", region: "", latlng: { lat: 32.688049, lng: 22.87596 }, zoomLevel: "16" },
  { name: "Chad", region: "Ennedi", latlng: { lat: 18.431694, lng: 21.068799 }, zoomLevel: "15" },
  { name: "The Bahamas", region: "South Eleuthera", latlng: { lat: 24.936413, lng: -76.333179 }, zoomLevel: "14" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -17.369726, lng: -145.549783 }, zoomLevel: "15" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.989079, lng: -87.901827 }, zoomLevel: "18" },
  { name: "United States", region: "Camptonville", latlng: { lat: 39.39742, lng: -121.141797 }, zoomLevel: "17" },
  { name: "Germany", region: "Niederzier", latlng: { lat: 50.915255, lng: 6.491187 }, zoomLevel: "16" },
  { name: "Greenland", region: "Kujalleq", latlng: { lat: 60.997524, lng: -45.010386 }, zoomLevel: 12 },
  { name: "Iceland", region: "", latlng: { lat: 64.181119, lng: -17.140045 }, zoomLevel: "13" },
  { name: "Switzerland", region: "Zermatt", latlng: { lat: 45.962714, lng: 7.724891 }, zoomLevel: "16" },
  { name: "Italy", region: "Verbano-Cusio-Ossola", latlng: { lat: 45.946972, lng: 7.853036 }, zoomLevel: "16" },
  { name: "Switzerland", region: "Saas-Almagell", latlng: { lat: 46.054866, lng: 7.973993 }, zoomLevel: "17" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 80.884801, lng: -82.867126 }, zoomLevel: "11" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.812713, lng: -112.649732 }, zoomLevel: "16" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.831581, lng: -112.609434 }, zoomLevel: "16" },
  { name: "United States", region: "Box Elder County", latlng: { lat: 41.306577, lng: -113.015885 }, zoomLevel: "15" },
  { name: "United States", region: "Box Elder County", latlng: { lat: 41.505554, lng: -112.772333 }, zoomLevel: "18" },
  { name: "United States", region: "Corinne", latlng: { lat: 41.432562, lng: -112.104795 }, zoomLevel: "16" },
  { name: "United States", region: "Davis County", latlng: { lat: 40.938934, lng: -112.032394 }, zoomLevel: "17" },
  { name: "United States", region: "Grantsville", latlng: { lat: 40.665112, lng: -112.365546 }, zoomLevel: "16" },
  { name: "United States", region: "Grantsville", latlng: { lat: 40.725633, lng: -112.49794 }, zoomLevel: "16" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.812193, lng: -112.661517 }, zoomLevel: "17" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.781728, lng: -112.622872 }, zoomLevel: "18" },
  { name: "Spain", region: "Calvià", latlng: { lat: 39.532597, lng: 2.587215 }, zoomLevel: "18" },
  { name: "China", region: "Haibei", latlng: { lat: 36.956476, lng: 99.857483 }, zoomLevel: "14" },
  { name: "China", region: "Hainan", latlng: { lat: 36.72994, lng: 99.895484 }, zoomLevel: "16" },
  { name: "China", region: "Hainan", latlng: { lat: 36.654612, lng: 100.473769 }, zoomLevel: "14" },
  { name: "China", region: "Haibei", latlng: { lat: 36.94742, lng: 100.50705 }, zoomLevel: "16" },
  { name: "China", region: "Haibei", latlng: { lat: 36.746482, lng: 100.70961 }, zoomLevel: "15" },
  { name: "China", region: "Hainan", latlng: { lat: 36.180008, lng: 100.8078 }, zoomLevel: "14" },
  { name: "China", region: "Golog", latlng: { lat: 35.270342, lng: 98.689198 }, zoomLevel: "14" },
  { name: "China", region: "Yushu", latlng: { lat: 34.96925, lng: 97.094421 }, zoomLevel: "14" },
  { name: "China", region: "Ngari", latlng: { lat: 31.923391, lng: 83.117709 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Aralsk", latlng: { lat: 46.666756, lng: 61.502323 }, zoomLevel: "14" },
  { name: "China", region: "Haibei", latlng: { lat: 37.002237, lng: 99.895136 }, zoomLevel: "17" },
  { name: "China", region: "Haixi", latlng: { lat: 37.410572, lng: 97.34001 }, zoomLevel: "16" },
  { name: "China", region: "Haixi", latlng: { lat: 37.327917, lng: 97.158054 }, zoomLevel: 16.75 },
  { name: "China", region: "Haixi", latlng: { lat: 37.259966, lng: 97.412352 }, zoomLevel: "16" },
  { name: "China", region: "Haixi", latlng: { lat: 37.058462, lng: 97.385798 }, zoomLevel: "17" },
  { name: "China", region: "Haixi", latlng: { lat: 36.954131, lng: 95.629734 }, zoomLevel: "17" },
  { name: "China", region: "Haixi", latlng: { lat: 37.679854, lng: 93.374705 }, zoomLevel: "15" },
  { name: "Tajikistan", region: "Gorno-Badakhshan Autonomous Province", latlng: { lat: 38.894614, lng: 73.298504 }, zoomLevel: "17" },
  { name: "Kazakhstan", region: "Moiynkum District", latlng: { lat: 45.742273, lng: 73.555854 }, zoomLevel: "16" },
  { name: "Chile", region: "Arica and Parinacota Region", latlng: { lat: -18.784436, lng: -69.24246 }, zoomLevel: "16" },
  { name: "Peru", region: "Mariscal Nieto Province", latlng: { lat: -16.632427, lng: -70.198892 }, zoomLevel: "17" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.382218, lng: -71.121049 }, zoomLevel: "17" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.36284, lng: -71.116197 }, zoomLevel: 16.5 },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.40911, lng: -71.253599 }, zoomLevel: "17" },
  { name: "Peru", region: "General Sánchez Cerro Province", latlng: { lat: -16.531462, lng: -70.987073 }, zoomLevel: "16" },
  { name: "Peru", region: "Mariscal Nieto Province", latlng: { lat: -17.105065, lng: -71.307511 }, zoomLevel: "16" },
  { name: "Peru", region: "Islay Province", latlng: { lat: -17.279111, lng: -71.49577 }, zoomLevel: "16" },
  { name: "Peru", region: "Ilo Province", latlng: { lat: -17.565184, lng: -71.191715 }, zoomLevel: "16" },
  { name: "Peru", region: "Villa Botiflaca", latlng: { lat: -17.0673, lng: -70.777984 }, zoomLevel: "16" },
  { name: "Peru", region: "Mariscal Nieto Province", latlng: { lat: -17.054767, lng: -70.727322 }, zoomLevel: "16" },
  { name: "Libya", region: "Murzuq", latlng: { lat: 24.908349, lng: 17.750339 }, zoomLevel: "17" },
  { name: "Algeria", region: "Illizi", latlng: { lat: 26.547684, lng: 7.710187 }, zoomLevel: "12" },
  { name: "Algeria", region: "Tamanrasset", latlng: { lat: 27.112022, lng: 2.235948 }, zoomLevel: "15" },
  { name: "Algeria", region: "Béchar", latlng: { lat: 29.725045, lng: -2.512742 }, zoomLevel: "12" },
  { name: "Turkey", region: "Seyfe", latlng: { lat: 39.217343, lng: 34.359827 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.26842, lng: -11.109281 }, zoomLevel: "10" },
  { name: "Namibia", region: "Oshikoto", latlng: { lat: -18.427746, lng: 16.05135 }, zoomLevel: "17" },
  { name: "Namibia", region: "Oshikoto", latlng: { lat: -18.444108, lng: 16.008853 }, zoomLevel: 15.75 },
  { name: "China", region: "Ngari", latlng: { lat: 35.100308, lng: 83.138242 }, zoomLevel: "14" },
  { name: "Peru", region: "Carhuaz", latlng: { lat: -9.37423, lng: -77.327464 }, zoomLevel: "16" },
  { name: "Sudan", region: "Karary", latlng: { lat: 16.48679, lng: 31.740875 }, zoomLevel: "15" },
  { name: "Germany", region: "Elsdorf", latlng: { lat: 50.909006, lng: 6.542559 }, zoomLevel: "18" },
  { name: "Germany", region: "Grevenbroich", latlng: { lat: 51.05611, lng: 6.541228 }, zoomLevel: "18" },
  { name: "Germany", region: "Aldenhoven", latlng: { lat: 50.873097, lng: 6.318117 }, zoomLevel: "16" },
  { name: "Germany", region: "Drebkau", latlng: { lat: 51.604798, lng: 14.242012 }, zoomLevel: "17" },
  { name: "Germany", region: "Drebkau", latlng: { lat: 51.610435, lng: 14.272799 }, zoomLevel: "18" },
  { name: "Chile", region: "Chañaral Province", latlng: { lat: -26.658045, lng: -68.512952 }, zoomLevel: "17" },
  { name: "Chile", region: "Antofagasta Province", latlng: { lat: -24.397797, lng: -69.119968 }, zoomLevel: "15" },
  { name: "Chile", region: "Antofagasta Province", latlng: { lat: -24.262557, lng: -69.052334 }, zoomLevel: "16" },
  { name: "Chile", region: "Copiapó Province", latlng: { lat: -27.465975, lng: -69.195971 }, zoomLevel: "15" },
  { name: "Argentina", region: "Iglesia Department", latlng: { lat: -28.534804, lng: -69.305454 }, zoomLevel: "16" },
  { name: "Chile", region: "Vicuña", latlng: { lat: -29.593162, lng: -70.333056 }, zoomLevel: "15" },
  { name: "Argentina", region: "General Roca", latlng: { lat: -39.074777, lng: -67.314177 }, zoomLevel: "13" },
  { name: "Chile", region: "Antofagasta Province", latlng: { lat: -23.923571, lng: -68.876563 }, zoomLevel: "16" },
  { name: "Chile", region: "San Pedro de Atacama", latlng: { lat: -23.473285, lng: -68.353736 }, zoomLevel: "16" },
  { name: "Chile", region: "El Loa", latlng: { lat: -22.809158, lng: -67.782404 }, zoomLevel: "16" },
  { name: "Russia", region: "Kysyl-Syr", latlng: { lat: 62.757241, lng: 129.711263 }, zoomLevel: "12" },
  { name: "China", region: "Haixi", latlng: { lat: 37.056292, lng: 97.38947 }, zoomLevel: "16" },
  { name: "United Arab Emirates", region: "Al Gharbia", latlng: { lat: 24.108645, lng: 53.144184 }, zoomLevel: "16" },
  { name: "Qatar", region: "Doha", latlng: { lat: 25.36776, lng: 51.557078 }, zoomLevel: "15" },
  { name: "Qatar", region: "Lusail", latlng: { lat: 25.444699, lng: 51.500334 }, zoomLevel: "17" },
  { name: "Chile", region: "", latlng: { lat: -54.850988, lng: -70.147008 }, zoomLevel: "11" },
  { name: "Falkland Islands (Islas Malvinas)", region: "West Falkland", latlng: { lat: -51.887054, lng: -60.143291 }, zoomLevel: "17" },
  { name: "Falkland Islands (Islas Malvinas)", region: "West Falkland", latlng: { lat: -51.765426, lng: -60.205795 }, zoomLevel: "17" },
  { name: "Libya", region: "Murzuq", latlng: { lat: 24.792423, lng: 17.007523 }, zoomLevel: "15" },
  { name: "Chad", region: "Lac", latlng: { lat: 13.970052, lng: 13.94474 }, zoomLevel: "11" },
  { name: "Italy", region: "Lido di Jesolo", latlng: { lat: 45.488117, lng: 12.612173 }, zoomLevel: "17" },
  { name: "United States", region: "Shoshoni", latlng: { lat: 43.392403, lng: -108.195827 }, zoomLevel: "17" },
  { name: "Antarctica", region: "", latlng: { lat: -74.547767, lng: -111.881332 }, zoomLevel: 11.25 },
  { name: "Antarctica", region: "", latlng: { lat: -74.539075, lng: -111.795588 }, zoomLevel: "13" },
  { name: "Italy", region: "Genoa", latlng: { lat: 44.41416, lng: 8.846156 }, zoomLevel: "20" },
  { name: "Italy", region: "Genoa", latlng: { lat: 44.415331, lng: 8.828158 }, zoomLevel: "18" },
  { name: "United States", region: "Reeves County", latlng: { lat: 31.955658, lng: -103.975983 }, zoomLevel: "13" },
  { name: "United States", region: "Loving", latlng: { lat: 31.993446, lng: -103.985392 }, zoomLevel: "18" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 25.253243, lng: 55.373591 }, zoomLevel: "18" },
  { name: "Japan", region: "Ota", latlng: { lat: 35.555713, lng: 139.770269 }, zoomLevel: "18" },
  { name: "United States", region: "Atlanta", latlng: { lat: 33.744923, lng: -84.391062 }, zoomLevel: "18" },
  { name: "China", region: "Shanghai", latlng: { lat: 31.210137, lng: 121.497945 }, zoomLevel: "18" },
  { name: "United States", region: "Los Angeles County", latlng: { lat: 33.930459, lng: -118.368858 }, zoomLevel: "19" },
  { name: "The Netherlands", region: "Maasvlakte Rotterdam", latlng: { lat: 51.954807, lng: 4.043167 }, zoomLevel: "19" },
  { name: "The Netherlands", region: "Maasvlakte Rotterdam", latlng: { lat: 51.957009, lng: 4.067441 }, zoomLevel: "19" },
  { name: "The Netherlands", region: "Maasvlakte Rotterdam", latlng: { lat: 51.956913, lng: 4.062254 }, zoomLevel: "19" },
  { name: "Saudi Arabia", region: "Al Ahsa", latlng: { lat: 21.895376, lng: 54.132105 }, zoomLevel: "14" },
  { name: "Algeria", region: "Illizi", latlng: { lat: 24.659472, lng: 8.033999 }, zoomLevel: "16" },
  { name: "Libya", region: "Ġaryān", latlng: { lat: 32.003052, lng: 12.562068 }, zoomLevel: "16" },
  { name: "Libya", region: "Alrujban", latlng: { lat: 31.988753, lng: 12.136391 }, zoomLevel: "15" },
  { name: "Russia", region: "Chaunsky District", latlng: { lat: 68.750408, lng: 170.700961 }, zoomLevel: "14" },
  { name: "China", region: "Bayingol", latlng: { lat: 38.013794, lng: 86.957636 }, zoomLevel: "14" },
  { name: "China", region: "Kizilsu", latlng: { lat: 39.495378, lng: 74.256196 }, zoomLevel: "15" },
  { name: "Algeria", region: "Guerzim", latlng: { lat: 29.585037, lng: -1.811038 }, zoomLevel: 16.25 },
  { name: "Libya", region: "Al Kufrah", latlng: { lat: 24.136133, lng: 23.34783 }, zoomLevel: "15" },
  { name: "Libya", region: "Al-Jawf", latlng: { lat: 24.16838, lng: 23.229641 }, zoomLevel: "16" },
  { name: "Iran", region: "Khour va Biabanak County", latlng: { lat: 33.618305, lng: 55.153116 }, zoomLevel: 16.5 },
  { name: "Russia", region: "Staropoltavsky District", latlng: { lat: 50.319609, lng: 46.416762 }, zoomLevel: "15" },
  { name: "Spain", region: "Amposta", latlng: { lat: 40.677106, lng: 0.641326 }, zoomLevel: "15" },
  { name: "Spain", region: "Deltebre", latlng: { lat: 40.736396, lng: 0.725118 }, zoomLevel: "16" },
  { name: "Spain", region: "Amposta", latlng: { lat: 40.703253, lng: 0.647871 }, zoomLevel: "14" },
  { name: "Chile", region: "Juan Fernández Islands", latlng: { lat: -33.656146, lng: -78.912186 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Befandriana-Nord", latlng: { lat: -15.072732, lng: 49.323138 }, zoomLevel: "17" },
  { name: "United States", region: "Grand Canyon Village", latlng: { lat: 36.07427, lng: -112.148669 }, zoomLevel: "14" },
  { name: "Russia", region: "Shuryshkarsky District", latlng: { lat: 65.48669, lng: 65.322229 }, zoomLevel: "12" },
  { name: "Haiti", region: "Ouest", latlng: { lat: 18.455374, lng: -72.563056 }, zoomLevel: "17" },
  { name: "Republic of the Union of Myanmar", region: "", latlng: { lat: 16.626858, lng: 97.078928 }, zoomLevel: "13" },
  { name: "China", region: "Baiyin", latlng: { lat: 36.416583, lng: 104.779087 }, zoomLevel: "13" },
  { name: "Sudan", region: "Red Sea", latlng: { lat: 18.192438, lng: 38.462588 }, zoomLevel: 16 },
  { name: "United States", region: "Somerton", latlng: { lat: 32.530558, lng: -114.648983 }, zoomLevel: "14" },
  { name: "Madagascar", region: "Befandriana-Nord", latlng: { lat: -15.072732, lng: 49.323138 }, zoomLevel: "17" },
  { name: "United States", region: "Grand Canyon Village", latlng: { lat: 36.07427, lng: -112.148669 }, zoomLevel: "14" },
  { name: "Russia", region: "Shuryshkarsky District", latlng: { lat: 65.48669, lng: 65.322229 }, zoomLevel: "12" },
  { name: "Haiti", region: "Ouest", latlng: { lat: 18.455374, lng: -72.563056 }, zoomLevel: "17" },
  { name: "Republic of the Union of Myanmar", region: "", latlng: { lat: 16.626858, lng: 97.078928 }, zoomLevel: "13" },
  { name: "China", region: "Baiyin", latlng: { lat: 36.416583, lng: 104.779087 }, zoomLevel: "13" },
  { name: "Sudan", region: "Red Sea", latlng: { lat: 18.192438, lng: 38.462588 }, zoomLevel: 16.5 },
  { name: "United States", region: "Somerton", latlng: { lat: 32.530558, lng: -114.648983 }, zoomLevel: "14" },
  { name: "Chile", region: "Parinacota Province", latlng: { lat: -18.128244, lng: -69.131781 }, zoomLevel: "16" },
  { name: "Chile", region: "Parinacota Province", latlng: { lat: -17.855578, lng: -69.491315 }, zoomLevel: "16" },
  { name: "Belgium", region: "Zaventem", latlng: { lat: 50.901784, lng: 4.48795 }, zoomLevel: "18" },
  { name: "Spain", region: "Deltebre", latlng: { lat: 40.721053, lng: 0.711965 }, zoomLevel: "17" },
  { name: "Spain", region: "Camarles", latlng: { lat: 40.787672, lng: 0.73927 }, zoomLevel: "16" },
  { name: "Argentina", region: "Confluencia Department", latlng: { lat: -39.106666, lng: -68.590571 }, zoomLevel: "16" },
  { name: "Argentina", region: "General Roca", latlng: { lat: -38.018476, lng: -67.863928 }, zoomLevel: "16" },
  { name: "Argentina", region: "General Roca", latlng: { lat: -37.888737, lng: -67.763678 }, zoomLevel: "14" },
  { name: "Canada", region: "Minton", latlng: { lat: 49.060688, lng: -104.564841 }, zoomLevel: "17" },
  { name: "New Caledonia", region: "Noumea", latlng: { lat: -22.246438, lng: 166.520768 }, zoomLevel: "17" },
  { name: "New Caledonia", region: "Noumea", latlng: { lat: -22.285279, lng: 166.439567 }, zoomLevel: "18" },
  { name: "New Caledonia", region: "Noumea", latlng: { lat: -22.333146, lng: 166.401758 }, zoomLevel: "16" },
  { name: "New Caledonia", region: "", latlng: { lat: -22.115198, lng: 166.055211 }, zoomLevel: "16" },
  { name: "New Caledonia", region: "Boulouparis", latlng: { lat: -22.09154, lng: 166.008294 }, zoomLevel: "16" },
  { name: "New Caledonia", region: "", latlng: { lat: -22.021209, lng: 165.93827 }, zoomLevel: "17" },
  { name: "New Caledonia", region: "Moindou", latlng: { lat: -21.829473, lng: 165.698032 }, zoomLevel: "16" },
  { name: "United States", region: "Winterhaven", latlng: { lat: 32.753138, lng: -114.659843 }, zoomLevel: "16" },
  { name: "Sudan", region: "Al Fasher", latlng: { lat: 13.674511, lng: 25.393353 }, zoomLevel: "17" },
  { name: "Sudan", region: "Al Fasher", latlng: { lat: 13.6748, lng: 25.39291 }, zoomLevel: 16.75 },
  { name: "United States", region: "Kayenta", latlng: { lat: 36.927304, lng: -110.149999 }, zoomLevel: "15" },
  { name: "United States", region: "Oljato-Monument Valley", latlng: { lat: 37.07604, lng: -110.3023 }, zoomLevel: "14" },
  { name: "United States", region: "Dennehotso", latlng: { lat: 36.888172, lng: -109.98485 }, zoomLevel: "17" },
  { name: "United States", region: "Kane County", latlng: { lat: 37.167533, lng: -111.328197 }, zoomLevel: "14" },
  { name: "United States", region: "Kane County", latlng: { lat: 37.079649, lng: -111.538047 }, zoomLevel: "19" },
  { name: "United States", region: "Kane County", latlng: { lat: 37.084832, lng: -111.53699 }, zoomLevel: "18" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.674511, lng: -111.735163 }, zoomLevel: "14" },
  { name: "United States", region: "Williams", latlng: { lat: 36.904504, lng: -111.401796 }, zoomLevel: "15" },
  { name: "United States", region: "Page", latlng: { lat: 36.913293, lng: -111.398355 }, zoomLevel: "17" },
  { name: "United States", region: "Austin", latlng: { lat: 30.435058, lng: -97.936936 }, zoomLevel: "15" },
  { name: "Italy", region: "Raffineria", latlng: { lat: 45.096632, lng: 8.882908 }, zoomLevel: "16" },
  { name: "France", region: "Paris", latlng: { lat: 48.86875, lng: 2.367403 }, zoomLevel: "17" },
  { name: "France", region: "Paris", latlng: { lat: 48.862001, lng: 2.3473 }, zoomLevel: "19" },
  { name: "United States", region: "Colby", latlng: { lat: 39.375301, lng: -100.983196 }, zoomLevel: "18" },
  { name: "United States", region: "Windermere", latlng: { lat: 28.459536, lng: -81.557604 }, zoomLevel: "16" },
  { name: "United States", region: "Queens County", latlng: { lat: 40.645078, lng: -73.789493 }, zoomLevel: "19" },
  { name: "United States", region: "Moab", latlng: { lat: 38.487829, lng: -109.679743 }, zoomLevel: "18" },
  { name: "France", region: "Paris", latlng: { lat: 48.848278, lng: 2.357944 }, zoomLevel: "16" },
  { name: "United States", region: "Woodbridge", latlng: { lat: 38.710119, lng: -77.319911 }, zoomLevel: "15" },
  { name: "United States", region: "Miami Beach", latlng: { lat: 25.786646, lng: -80.15316 }, zoomLevel: "16" },
  { name: "Monaco", region: "Monaco", latlng: { lat: 43.734846, lng: 7.425299 }, zoomLevel: "18" },
  { name: "United States", region: "Key Biscayne", latlng: { lat: 25.68895, lng: -80.170772 }, zoomLevel: "17" },
  { name: "Brazil", region: "Brasilia", latlng: { lat: -15.847397, lng: -47.793596 }, zoomLevel: "17" },
  { name: "United States", region: "Miami", latlng: { lat: 25.780404, lng: -80.368958 }, zoomLevel: "18" },
  { name: "United States", region: "Florence", latlng: { lat: 43.89963, lng: -124.128702 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "London", latlng: { lat: 51.508386, lng: -0.163223 }, zoomLevel: "18" },
  { name: "United Kingdom", region: "Herefordshire", latlng: { lat: 52.358318, lng: -2.905637 }, zoomLevel: "16" },
  { name: "United States", region: "Riverside County", latlng: { lat: 33.898442, lng: -115.936386 }, zoomLevel: "16" },
  { name: "Germany", region: "Offenbach", latlng: { lat: 50.066606, lng: 8.727629 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Herefordshire", latlng: { lat: 52.039415, lng: -2.770135 }, zoomLevel: "17" },
  { name: "The Netherlands", region: "Leidschendam", latlng: { lat: 52.101296, lng: 4.379092 }, zoomLevel: "17" },
  { name: "Germany", region: "Heikendorf", latlng: { lat: 54.368, lng: 10.235318 }, zoomLevel: "16" },
  { name: "The Netherlands", region: "The Hague", latlng: { lat: 52.081021, lng: 4.323466 }, zoomLevel: "19" },
  { name: "Jordan", region: "Al Karak", latlng: { lat: 31.179721, lng: 35.480757 }, zoomLevel: "13" },
  { name: "Czech Republic", region: "Kadaň", latlng: { lat: 50.410713, lng: 13.335257 }, zoomLevel: "17" },
  { name: "United States", region: "Orlando", latlng: { lat: 28.480866, lng: -81.273852 }, zoomLevel: "17" },
  { name: "Kiribati", region: "", latlng: { lat: 1.90835, lng: -157.432194 }, zoomLevel: "15" },
  { name: "Kiribati", region: "", latlng: { lat: 1.932369, lng: -157.487812 }, zoomLevel: "16" },
  { name: "Bahrain", region: "Manama", latlng: { lat: 26.249473, lng: 50.573668 }, zoomLevel: "15" },
  { name: "The Netherlands", region: "Europoort Rotterdam", latlng: { lat: 51.911648, lng: 4.206583 }, zoomLevel: "17" },
  { name: "United States", region: "Tonalea", latlng: { lat: 37.006632, lng: -110.722446 }, zoomLevel: "15" },
  { name: "United States", region: "Niagara Falls", latlng: { lat: 43.076065, lng: -79.071336 }, zoomLevel: "17" },
  { name: "Australia", region: "Northern Territory", latlng: { lat: -11.824027, lng: 131.828561 }, zoomLevel: "10" },
  { name: "Nepal", region: "Chaurikharka", latlng: { lat: 27.789645, lng: 86.775444 }, zoomLevel: "17" },
  { name: "Ecuador", region: "Galapagos", latlng: { lat: -0.370973, lng: -91.548408 }, zoomLevel: "15" },
  { name: "China", region: "Rikaze", latlng: { lat: 31.521932, lng: 84.19345 }, zoomLevel: "9" },
  { name: "China", region: "Nyingchi", latlng: { lat: 29.022111, lng: 97.530852 }, zoomLevel: "9" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 20.80314, lng: -13.233114 }, zoomLevel: "10" },
  { name: "Turkey", region: "Yemişli", latlng: { lat: 37.608897, lng: 38.488268 }, zoomLevel: "11" },
  { name: "France", region: "Colombier-Saugnieu", latlng: { lat: 45.720302, lng: 5.076441 }, zoomLevel: "17" },
  { name: "United States", region: "Moab", latlng: { lat: 38.486796, lng: -109.685644 }, zoomLevel: "18" },
  { name: "Monaco", region: "Monaco", latlng: { lat: 43.734786, lng: 7.423193 }, zoomLevel: "19" },
  { name: "Kiribati", region: "Line Islands", latlng: { lat: 1.913014, lng: -157.50825 }, zoomLevel: "16" },
  { name: "China", region: "Beijing", latlng: { lat: 40.106232, lng: 116.646126 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Herefordshire", latlng: { lat: 52.228043, lng: -2.795345 }, zoomLevel: "18" },
  { name: "Turkey", region: "Nizip", latlng: { lat: 37.11605, lng: 37.617269 }, zoomLevel: "17" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 25.237165, lng: 55.176751 }, zoomLevel: "16" },
  { name: "United States", region: "St. Petersburg", latlng: { lat: 27.746758, lng: -82.626366 }, zoomLevel: "18" },
  { name: "United States", region: "Ocean City", latlng: { lat: 38.334251, lng: -75.085716 }, zoomLevel: "19" },
  { name: "Haiti", region: "Port-au-Prince", latlng: { lat: 18.566939, lng: -72.349624 }, zoomLevel: "20" },
  { name: "Germany", region: "Hamburg", latlng: { lat: 53.53211, lng: 9.945913 }, zoomLevel: "17" },
  { name: "United States", region: "Los Angeles", latlng: { lat: 34.031345, lng: -118.435001 }, zoomLevel: "18" },
  { name: "Spain", region: "Cullera", latlng: { lat: 39.200193, lng: -0.242461 }, zoomLevel: "16" },
  { name: "Spain", region: "Cullera", latlng: { lat: 39.200039, lng: -0.240841 }, zoomLevel: "19" },
  { name: "United States", region: "Pinal County", latlng: { lat: 32.675813, lng: -111.961318 }, zoomLevel: "15" },
  { name: "South Africa", region: "Johannesburg", latlng: { lat: -26.209861, lng: 28.026977 }, zoomLevel: "17" },
  { name: "United States", region: "Casa Grande", latlng: { lat: 32.939017, lng: -111.884457 }, zoomLevel: "13" },
  { name: "United States", region: "Eloy", latlng: { lat: 32.641977, lng: -111.56908 }, zoomLevel: "14" },
  { name: "United States", region: "Boca Raton", latlng: { lat: 26.392898, lng: -80.152345 }, zoomLevel: "15" },
  { name: "United States", region: "Marana", latlng: { lat: 32.433242, lng: -111.524888 }, zoomLevel: "19" },
  { name: "Montenegro", region: "Podgorica", latlng: { lat: 42.313988, lng: 19.101208 }, zoomLevel: "13" },
  { name: "Australia", region: "Sydney", latlng: { lat: -33.856829, lng: 151.214679 }, zoomLevel: "19" },
  { name: "Australia", region: "Ayers Rock", latlng: { lat: -25.344772, lng: 131.038441 }, zoomLevel: "16" },
  { name: "Qatar", region: "Ad-Daẖirah", latlng: { lat: 25.914864, lng: 51.634809 }, zoomLevel: "15" },
  { name: "Qatar", region: "Ad-Daẖirah", latlng: { lat: 25.919853, lng: 51.579931 }, zoomLevel: "18" },
  { name: "United States", region: "San Bernardino", latlng: { lat: 34.185234, lng: -117.414429 }, zoomLevel: "18" },
  { name: "Poland", region: "Warsaw", latlng: { lat: 52.112541, lng: 21.080312 }, zoomLevel: "18" },
  { name: "Poland", region: "Warsaw", latlng: { lat: 52.126002, lng: 21.057189 }, zoomLevel: "19" },
  { name: "United States", region: "Paramount", latlng: { lat: 33.912283, lng: -118.17858 }, zoomLevel: "17" },
  { name: "France", region: "Granville", latlng: { lat: 48.88303, lng: -1.826482 }, zoomLevel: "15" },
  { name: "The Minquiers", region: "", latlng: { lat: 48.971565, lng: -2.125688 }, zoomLevel: "15" },
  { name: "France", region: "L'Épine, Vendée", latlng: { lat: 46.99188, lng: -2.28477 }, zoomLevel: "15" },
  { name: "France", region: "Île d'Yeu", latlng: { lat: 46.726438, lng: -2.344186 }, zoomLevel: "17" },
  { name: "France", region: "La Rochelle", latlng: { lat: 46.165883, lng: -1.232271 }, zoomLevel: "15" },
  { name: "France", region: "Angoulins", latlng: { lat: 46.106771, lng: -1.129993 }, zoomLevel: "16" },
  { name: "United Kingdom", region: "Isles of Scilly", latlng: { lat: 49.918399, lng: -6.311675 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Isles of Scilly", latlng: { lat: 49.894132, lng: -6.339785 }, zoomLevel: "17" },
  { name: "France", region: "Paris", latlng: { lat: 48.861467, lng: 2.333723 }, zoomLevel: "20" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.891438, lng: -87.611117 }, zoomLevel: "16" },
  { name: "United States", region: "Cook County", latlng: { lat: 41.884863, lng: -87.613252 }, zoomLevel: "18" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.963367, lng: -87.637961 }, zoomLevel: "16" },
  { name: "United States", region: "New York", latlng: { lat: 40.750482, lng: -73.993743 }, zoomLevel: "21" },
  { name: "Germany", region: "Frankfurt", latlng: { lat: 50.039356, lng: 8.563777 }, zoomLevel: "16" },
  { name: "Kiribati", region: "Line Islands", latlng: { lat: 1.725472, lng: -157.210515 }, zoomLevel: "16" },
  { name: "United States", region: "Honolulu County", latlng: { lat: 26.06138, lng: -173.979428 }, zoomLevel: "15" },
  { name: "United States", region: "Honolulu County", latlng: { lat: 28.398092, lng: -178.311689 }, zoomLevel: "15" },
  { name: "United States", region: "Maunaloa", latlng: { lat: 21.197406, lng: -157.153995 }, zoomLevel: "16" },
  { name: "United States", region: "Atlanta", latlng: { lat: 33.745334, lng: -84.390004 }, zoomLevel: "18" },
  { name: "United States", region: "Atlanta", latlng: { lat: 33.757222, lng: -84.40275 }, zoomLevel: "18" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.328076, lng: -71.63964 }, zoomLevel: "18" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.278063, lng: -71.729221 }, zoomLevel: "16" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.235545, lng: -71.778231 }, zoomLevel: "16" },
  { name: "India", region: "Mumbai", latlng: { lat: 19.096782, lng: 72.870413 }, zoomLevel: "17" },
  { name: "United States", region: "Westlake Village", latlng: { lat: 34.144443, lng: -118.830142 }, zoomLevel: "17" },
  { name: "Saudi Arabia", region: "Riyadh", latlng: { lat: 25.053247, lng: 46.998846 }, zoomLevel: "17" },
  { name: "Botswana", region: "Orapa", latlng: { lat: -21.519547, lng: 25.676315 }, zoomLevel: "15" },
  { name: "Austria", region: "Kaprun", latlng: { lat: 47.176037, lng: 12.695003 }, zoomLevel: "16" },
  { name: "United States", region: "Keweenaw County", latlng: { lat: 48.112286, lng: -88.624574 }, zoomLevel: "15" },
  { name: "United States", region: "Bernville", latlng: { lat: 40.494873, lng: -76.081352 }, zoomLevel: "15" },
  { name: "France", region: "Paris", latlng: { lat: 48.86209, lng: 2.325261 }, zoomLevel: "16" },
  { name: "France", region: "Paris", latlng: { lat: 48.856041, lng: 2.315243 }, zoomLevel: "17" },
  { name: "France", region: "Paris", latlng: { lat: 48.846832, lng: 2.340735 }, zoomLevel: "17" },
  { name: "The Netherlands", region: "Leusden", latlng: { lat: 52.10119, lng: 5.342209 }, zoomLevel: "17" },
  { name: "The Netherlands", region: "Amersfoort", latlng: { lat: 52.156431, lng: 5.390419 }, zoomLevel: "19" },
  { name: "United States", region: "Anchorage", latlng: { lat: 61.173468, lng: -149.987937 }, zoomLevel: "16" },
  { name: "Germany", region: "Frankfurt", latlng: { lat: 50.038423, lng: 8.563929 }, zoomLevel: "16" },
  { name: "Libya", region: "An Nuqat al Khams", latlng: { lat: 32.466141, lng: 11.990215 }, zoomLevel: "16" },
  { name: "Libya", region: "Al Jabal al Gharbi", latlng: { lat: 32.112095, lng: 11.878452 }, zoomLevel: "16" },
  { name: "United Kingdom", region: "Musselburgh", latlng: { lat: 55.953981, lng: -3.041486 }, zoomLevel: "16" },
  { name: "United Kingdom", region: "Musselburgh", latlng: { lat: 55.950656, lng: -3.067058 }, zoomLevel: "17" },
  { name: "Italy", region: "Bari", latlng: { lat: 40.91605, lng: 17.067934 }, zoomLevel: "18" },
  { name: "Italy", region: "Bari", latlng: { lat: 40.964911, lng: 17.053742 }, zoomLevel: "15" },
  { name: "United Kingdom", region: "Herefordshire", latlng: { lat: 51.856303, lng: -2.679921 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Llangrove", latlng: { lat: 51.868932, lng: -2.680307 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Herefordshire", latlng: { lat: 51.850472, lng: -2.688718 }, zoomLevel: "17" },
  { name: "United States", region: "Little Rock", latlng: { lat: 34.710212, lng: -92.272225 }, zoomLevel: "16" },
  { name: "Mexico", region: "Ciudad Nezahualcóyotl", latlng: { lat: 19.413052, lng: -99.043358 }, zoomLevel: "17" },
  { name: "Mexico", region: "Ciudad Nezahualcóyotl", latlng: { lat: 19.426239, lng: -99.04192 }, zoomLevel: "17" },
  { name: "Mexico", region: "Ecatepec", latlng: { lat: 19.496688, lng: -99.024073 }, zoomLevel: "19" },
  { name: "Mexico", region: "Mexico City", latlng: { lat: 19.494924, lng: -99.013896 }, zoomLevel: "17" },
  { name: "Mexico", region: "Mexico City", latlng: { lat: 19.493316, lng: -99.164057 }, zoomLevel: "18" },
  { name: "Mexico", region: "Mexico City", latlng: { lat: 19.500203, lng: -99.163976 }, zoomLevel: "18" },
  { name: "United States", region: "San Antonio", latlng: { lat: 29.606527, lng: -98.584201 }, zoomLevel: "18" },
  { name: "Afghanistan", region: "Deh Sabz", latlng: { lat: 34.596951, lng: 69.297211 }, zoomLevel: "17" },
  { name: "Greece", region: "Crete", latlng: { lat: 35.296472, lng: 25.742691 }, zoomLevel: "17" },
  { name: "Greece", region: "Crete", latlng: { lat: 35.280025, lng: 25.736906 }, zoomLevel: "17" },
  { name: "Greece", region: "Agios Nikolaos", latlng: { lat: 35.204071, lng: 25.724219 }, zoomLevel: 16.25 },
  { name: "Spain", region: "Madrid", latlng: { lat: 40.415652, lng: -3.703396 }, zoomLevel: "17" },
  { name: "Spain", region: "Segovia", latlng: { lat: 40.978225, lng: -4.119599 }, zoomLevel: "17" },
  { name: "Spain", region: "Segovia", latlng: { lat: 40.980203, lng: -4.162582 }, zoomLevel: "17" },
  { name: "France", region: "Paris", latlng: { lat: 48.866839, lng: 2.310347 }, zoomLevel: "17" },
  { name: "Chile", region: "El Loa", latlng: { lat: -23.643369, lng: -68.318921 }, zoomLevel: "16" },
  { name: "United States", region: "Tacoma", latlng: { lat: 47.263648, lng: -122.36357 }, zoomLevel: "18" },
  { name: "United States", region: "Seattle", latlng: { lat: 47.602792, lng: -122.340685 }, zoomLevel: "18" },
  { name: "United States", region: "Seattle", latlng: { lat: 47.628163, lng: -122.391827 }, zoomLevel: "18" },
  { name: "Algeria", region: "Adrar", latlng: { lat: 29.119748, lng: 0.022799 }, zoomLevel: "16" },
  { name: "Monaco", region: "Monaco", latlng: { lat: 43.735403, lng: 7.426372 }, zoomLevel: "17" },
  { name: "United States", region: "Calabasas", latlng: { lat: 34.160668, lng: -118.682744 }, zoomLevel: "18" },
  { name: "Italy", region: "Syracuse", latlng: { lat: 37.199664, lng: 15.191689 }, zoomLevel: "15" },
  { name: "United States", region: "Las Vegas", latlng: { lat: 36.081473, lng: -115.137611 }, zoomLevel: "18" },
  { name: "United States", region: "Las Vegas", latlng: { lat: 36.06877, lng: -115.27173 }, zoomLevel: "15" },
  { name: "United States", region: "Las Vegas", latlng: { lat: 36.022551, lng: -115.174785 }, zoomLevel: "15" },
  { name: "United States", region: "Johnstown", latlng: { lat: 42.523827, lng: -100.09162 }, zoomLevel: "18" },
  { name: "United States", region: "Cedar Valley", latlng: { lat: 40.447406, lng: -112.229237 }, zoomLevel: "14" },
  { name: "The Netherlands", region: "Westbroek", latlng: { lat: 52.171119, lng: 5.129424 }, zoomLevel: "19" },
  { name: "The Netherlands", region: "Westbroek", latlng: { lat: 52.169372, lng: 5.12326 }, zoomLevel: "16" },
  { name: "Argentina", region: "Corpen Aike Department", latlng: { lat: -50.06259, lng: -68.454665 }, zoomLevel: "16" },
  { name: "Argentina", region: "Corpen Aike Department", latlng: { lat: -50.063857, lng: -68.451875 }, zoomLevel: "16" },
  { name: "United States", region: "Dugway", latlng: { lat: 40.763353, lng: -112.639637 }, zoomLevel: "17" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.765791, lng: -112.608159 }, zoomLevel: "17" },
  { name: "Portugal", region: "Faro District", latlng: { lat: 37.210867, lng: -7.471838 }, zoomLevel: "19" },
  { name: "Portugal", region: "Faro District", latlng: { lat: 37.209038, lng: -7.470084 }, zoomLevel: "19" },
  { name: "Spain", region: "Ayamonte", latlng: { lat: 37.214576, lng: -7.349045 }, zoomLevel: "18" },
  { name: "Portugal", region: "Castro Marim Municipality", latlng: { lat: 37.205335, lng: -7.442456 }, zoomLevel: "19" },
  { name: "Spain", region: "Aznalcóllar", latlng: { lat: 37.57945, lng: -6.300412 }, zoomLevel: "17" },
  { name: "Spain", region: "Aznalcóllar", latlng: { lat: 37.572818, lng: -6.271487 }, zoomLevel: "17" },
  { name: "Portugal", region: "Tavira", latlng: { lat: 37.115784, lng: -7.62704 }, zoomLevel: 16.75 },
  { name: "Indonesia", region: "Kapuas", latlng: { lat: -2.01215, lng: 114.594499 }, zoomLevel: 12.75 },
  { name: "Indonesia", region: "Kapuas", latlng: { lat: -2.124816, lng: 114.627844 }, zoomLevel: "16" },
  { name: "Indonesia", region: "Kapuas", latlng: { lat: -2.119669, lng: 114.620935 }, zoomLevel: "15" },
  { name: "Spain", region: "Isla Cristina", latlng: { lat: 37.246042, lng: -7.286028 }, zoomLevel: "19" },
  { name: "Spain", region: "Isla Cristina", latlng: { lat: 37.245107, lng: -7.284362 }, zoomLevel: "19" },
  { name: "Spain", region: "Ayamonte", latlng: { lat: 37.197326, lng: -7.364796 }, zoomLevel: "17" },
  { name: "Australia", region: "Gairdner", latlng: { lat: -31.517649, lng: 135.990296 }, zoomLevel: "15" },
  { name: "Russia", region: "Yelizovsky District", latlng: { lat: 52.451614, lng: 158.205391 }, zoomLevel: "15" },
  { name: "United States", region: "Kotzebue", latlng: { lat: 67.398529, lng: -161.671272 }, zoomLevel: "16" },
  { name: "Argentina", region: "Confluencia Department", latlng: { lat: -38.428089, lng: -68.885829 }, zoomLevel: "17" },
  { name: "Argentina", region: "Loncopué Department", latlng: { lat: -37.836182, lng: -69.863155 }, zoomLevel: "15" },
  { name: "Argentina", region: "Villarino Partido", latlng: { lat: -38.738352, lng: -62.946917 }, zoomLevel: "16" },
  { name: "United States", region: "Moab", latlng: { lat: 38.487491, lng: -109.678409 }, zoomLevel: "16" },
  { name: "United States", region: "Moab", latlng: { lat: 38.471502, lng: -109.681571 }, zoomLevel: "18" },
  { name: "United States", region: "Ogden", latlng: { lat: 41.283775, lng: -112.275232 }, zoomLevel: "18" },
  { name: "Russia", region: "Karaginsky District", latlng: { lat: 57.368198, lng: 162.515293 }, zoomLevel: "12" },
  { name: "Russia", region: "Yelizovsky District", latlng: { lat: 53.199039, lng: 159.848706 }, zoomLevel: "13" },
  { name: "United States", region: "North Slope", latlng: { lat: 70.874223, lng: -156.213188 }, zoomLevel: "14" },
  { name: "Saudi Arabia", region: "Al Jowf", latlng: { lat: 30.259146, lng: 38.300166 }, zoomLevel: "12" },
  { name: "Saudi Arabia", region: "Al Jowf", latlng: { lat: 30.183423, lng: 38.365054 }, zoomLevel: "15" },
  { name: "United Arab Emirates", region: "Abu Dhabi", latlng: { lat: 24.551374, lng: 54.479424 }, zoomLevel: "15" },
  { name: "United Arab Emirates", region: "Abu Dhabi", latlng: { lat: 24.526819, lng: 54.553614 }, zoomLevel: "16" },
  { name: "Turkey", region: "Şereflikoçhisar", latlng: { lat: 38.864777, lng: 33.40229 }, zoomLevel: "14" },
  { name: "Turkey", region: "Şereflikoçhisar", latlng: { lat: 38.999109, lng: 33.404297 }, zoomLevel: "16" },
  { name: "Japan", region: "Osaka", latlng: { lat: 34.669704, lng: 135.478018 }, zoomLevel: "18" },
  { name: "United States", region: "Washta", latlng: { lat: 42.5814, lng: -95.692367 }, zoomLevel: "19" },
  { name: "Spain", region: "Utrera", latlng: { lat: 37.097117, lng: -5.909308 }, zoomLevel: "17" },
  { name: "United States", region: "Ogden", latlng: { lat: 41.287273, lng: -112.27749 }, zoomLevel: "19" },
  { name: "United States", region: "Ogden", latlng: { lat: 41.287439, lng: -112.26807 }, zoomLevel: "17" },
  { name: "Spain", region: "Trebujena", latlng: { lat: 36.897608, lng: -6.273935 }, zoomLevel: "18" },
  { name: "United States", region: "Redwood City", latlng: { lat: 37.489485, lng: -122.199265 }, zoomLevel: "17" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 24.855289, lng: 55.008438 }, zoomLevel: "17" },
  { name: "Argentina", region: "Corpen Aike Department", latlng: { lat: -50.119473, lng: -69.128006 }, zoomLevel: "16" },
  { name: "Argentina", region: "Corpen Aike Department", latlng: { lat: -50.11485, lng: -69.102343 }, zoomLevel: "16" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 24.872587, lng: 55.134512 }, zoomLevel: "16" },
  { name: "France", region: "Jouques", latlng: { lat: 43.664954, lng: 5.689162 }, zoomLevel: "18" },
  { name: "Spain", region: "Beas", latlng: { lat: 37.402543, lng: -6.805253 }, zoomLevel: "18" },
  { name: "Spain", region: "Beas", latlng: { lat: 37.408696, lng: -6.797603 }, zoomLevel: "18" },
  { name: "Spain", region: "Beas", latlng: { lat: 37.413762, lng: -6.783334 }, zoomLevel: "19" },
  { name: "France", region: "Cucuron", latlng: { lat: 43.761472, lng: 5.446742 }, zoomLevel: "16" },
  { name: "United States", region: "Washington", latlng: { lat: 38.909325, lng: -77.040843 }, zoomLevel: "17" },
  { name: "The Netherlands", region: "Zwiggelte", latlng: { lat: 52.900645, lng: 6.577099 }, zoomLevel: "17" },
  { name: "Portugal", region: "Faro District", latlng: { lat: 37.147384, lng: -8.524998 }, zoomLevel: "19" },
  { name: "Portugal", region: "Faro District", latlng: { lat: 37.148816, lng: -8.524794 }, zoomLevel: "19" },
  { name: "Germany", region: "Twist", latlng: { lat: 52.707232, lng: 7.071183 }, zoomLevel: "16" },
  { name: "Australia", region: "Yardea", latlng: { lat: -32.064781, lng: 135.38689 }, zoomLevel: "15" },
  { name: "Australia", region: "South Australia", latlng: { lat: -32.016179, lng: 135.437101 }, zoomLevel: "15" },
  { name: "Uzbekistan", region: "Muynak District", latlng: { lat: 44.487063, lng: 60.865137 }, zoomLevel: "11" },
  { name: "Uzbekistan", region: "Muynak District", latlng: { lat: 43.269568, lng: 59.152901 }, zoomLevel: "13" },
  { name: "United States", region: "Lebanon", latlng: { lat: 39.77735, lng: -98.489355 }, zoomLevel: "17" },
  { name: "Australia", region: "Wittenoom Hills", latlng: { lat: -33.476187, lng: 122.29956 }, zoomLevel: "17" },
  { name: "Australia", region: "Mount Ney", latlng: { lat: -33.2678, lng: 122.219952 }, zoomLevel: "14" },
  { name: "France", region: "Paris", latlng: { lat: 48.877147, lng: 2.343883 }, zoomLevel: "17" },
  { name: "United States", region: "Kotzebue", latlng: { lat: 67.110567, lng: -163.51646 }, zoomLevel: "14" },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.554852, lng: 48.258407 }, zoomLevel: 16.75 },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.524635, lng: 48.257699 }, zoomLevel: "17" },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.546278, lng: 48.207016 }, zoomLevel: "17" },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.521678, lng: 48.21826 }, zoomLevel: "17" },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.572276, lng: 48.249674 }, zoomLevel: "17" },
  { name: "United States", region: "North Slope", latlng: { lat: 68.986534, lng: -163.062634 }, zoomLevel: "14" },
  { name: "United States", region: "North Slope", latlng: { lat: 68.988934, lng: -163.054738 }, zoomLevel: "13" },
  { name: "Diomede Ilands", region: "", latlng: { lat: 65.831292, lng: -169.007113 }, zoomLevel: "15" },
  { name: "Diomede Ilands", region: "", latlng: { lat: 65.828076, lng: -169.013056 }, zoomLevel: "17" },
  { name: "Russia", region: "Chukotsky District", latlng: { lat: 65.819077, lng: -169.075691 }, zoomLevel: "16" },
  { name: "Japan", region: "Ashoro", latlng: { lat: 43.383424, lng: 144.012104 }, zoomLevel: "16" },
  { name: "Japan", region: "Ashoro", latlng: { lat: 43.384422, lng: 144.011203 }, zoomLevel: "15" },
  { name: "United States", region: "Gustavus", latlng: { lat: 58.745544, lng: -137.434637 }, zoomLevel: "12" },
  { name: "Saudi Arabia", region: "Al Jowf", latlng: { lat: 30.277308, lng: 38.434878 }, zoomLevel: "16" },
  { name: "Portugal", region: "Sintra", latlng: { lat: 38.781564, lng: -9.499429 }, zoomLevel: "18" },
  { name: "France", region: "Cagnes-sur-Mer", latlng: { lat: 43.648455, lng: 7.147239 }, zoomLevel: "17" },
  { name: "France", region: "Nice", latlng: { lat: 43.659972, lng: 7.211002 }, zoomLevel: "18" },
  { name: "Iran", region: "Khorramshahr", latlng: { lat: 30.549761, lng: 48.266003 }, zoomLevel: "17" },
  { name: "United States", region: "Peru", latlng: { lat: 40.772665, lng: -86.124857 }, zoomLevel: "17" },
  { name: "New Zealand", region: "Wellington", latlng: { lat: -41.27929, lng: 174.784713 }, zoomLevel: "17" },
  { name: "United States", region: "Mooresville", latlng: { lat: 35.617156, lng: -80.885719 }, zoomLevel: "17" },
  { name: "China", region: "Beijing", latlng: { lat: 40.078433, lng: 116.604767 }, zoomLevel: "14" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 30.823593, lng: 7.905156 }, zoomLevel: "16" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 30.998542, lng: 7.930616 }, zoomLevel: "15" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 30.388285, lng: 7.900532 }, zoomLevel: "16" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 30.595033, lng: 7.928406 }, zoomLevel: 17 },
  { name: "Saudi Arabia", region: "Eastern Province", latlng: { lat: 27.993407, lng: 48.781569 }, zoomLevel: "17" },
  { name: "Japan", region: "Fujinomiya", latlng: { lat: 35.359601, lng: 138.732278 }, zoomLevel: 17 },
  { name: "Antarctica", region: "", latlng: { lat: -67.311189, lng: -66.668359 }, zoomLevel: "11" },
  { name: "Niger", region: "Goure", latlng: { lat: 16.001822, lng: 11.365184 }, zoomLevel: "13" },
  { name: "The Bahamas", region: "", latlng: { lat: 23.624945, lng: -77.862596 }, zoomLevel: 11.25 },
  { name: "The Bahamas", region: "North Andros", latlng: { lat: 24.553291, lng: -78.368053 }, zoomLevel: "15" },
  { name: "The Bahamas", region: "", latlng: { lat: 24.696076, lng: -78.370199 }, zoomLevel: "15" },
  { name: "The Bahamas", region: "North Andros", latlng: { lat: 24.693269, lng: -78.272181 }, zoomLevel: "15" },
  { name: "The Bahamas", region: "Exuma", latlng: { lat: 23.474977, lng: -75.852614 }, zoomLevel: "15" },
  { name: "Saudi Arabia", region: "Eastern Province", latlng: { lat: 28.18554, lng: 48.638586 }, zoomLevel: "15" },
  { name: "France", region: "Paris", latlng: { lat: 48.873815, lng: 2.293753 }, zoomLevel: "16" },
  { name: "United States", region: "Washington", latlng: { lat: 38.889728, lng: -77.009233 }, zoomLevel: "18" },
  { name: "United States", region: "North Bend", latlng: { lat: 43.593965, lng: -124.227949 }, zoomLevel: "18" },
  { name: "Egypt", region: "Giza", latlng: { lat: 29.795509, lng: 31.228144 }, zoomLevel: "17" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 31.155293, lng: 7.900586 }, zoomLevel: "14" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 31.125844, lng: 7.90164 }, zoomLevel: 16.25 },
  { name: "United States", region: "Tucson", latlng: { lat: 32.2275, lng: -110.850259 }, zoomLevel: "15" },
  { name: "France", region: "Versailles", latlng: { lat: 48.809912, lng: 2.099437 }, zoomLevel: "17" },
  { name: "France", region: "Versailles", latlng: { lat: 48.805856, lng: 2.115552 }, zoomLevel: "17" },
  { name: "United States", region: "Reno", latlng: { lat: 39.49691, lng: -119.768496 }, zoomLevel: "17" },
  { name: "China", region: "Garze", latlng: { lat: 32.394493, lng: 100.412088 }, zoomLevel: "15" },
  { name: "Spain", region: "Pozoantiguo", latlng: { lat: 41.59763, lng: -5.436283 }, zoomLevel: "16" },
  { name: "United States", region: "Atlanta", latlng: { lat: 33.797638, lng: -84.443223 }, zoomLevel: "17" },
  { name: "Egypt", region: "Giza", latlng: { lat: 29.337928, lng: 30.075082 }, zoomLevel: "16" },
  { name: "United States", region: "Homestead", latlng: { lat: 25.523246, lng: -80.480533 }, zoomLevel: "19" },
  { name: "Egypt", region: "Giza", latlng: { lat: 29.302219, lng: 30.04973 }, zoomLevel: "16" },
  { name: "Egypt", region: "Giza", latlng: { lat: 29.285106, lng: 30.050491 }, zoomLevel: "17" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.313904, lng: 30.154089 }, zoomLevel: "16" },
  { name: "India", region: "Deori", latlng: { lat: 22.940492, lng: 79.986947 }, zoomLevel: "15" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.254286, lng: 30.162849 }, zoomLevel: "17" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.25321, lng: 30.130384 }, zoomLevel: 16.5 },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.139313, lng: 30.301377 }, zoomLevel: "17" },
  { name: "Kuwait", region: "Al Jahra", latlng: { lat: 29.957621, lng: 48.069971 }, zoomLevel: "15" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.082101, lng: 30.116138 }, zoomLevel: "17" },
  { name: "United States", region: "Loraine", latlng: { lat: 32.535802, lng: -100.709944 }, zoomLevel: "16" },
  { name: "United States", region: "Sweetwater", latlng: { lat: 32.631906, lng: -100.54396 }, zoomLevel: "18" },
  { name: "Egypt", region: "Bani Sweif Governorate", latlng: { lat: 29.008875, lng: 30.146683 }, zoomLevel: "17" },
  { name: "Egypt", region: "Bani Sweif Governorate", latlng: { lat: 28.974345, lng: 30.126036 }, zoomLevel: "16" },
  { name: "Egypt", region: "Bani Sweif Governorate", latlng: { lat: 28.940548, lng: 30.130984 }, zoomLevel: "16" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.017322, lng: 30.224154 }, zoomLevel: "16" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.09311, lng: 30.211976 }, zoomLevel: "16" },
  { name: "United States", region: "Electra", latlng: { lat: 33.754572, lng: -99.250754 }, zoomLevel: "19" },
  { name: "United States", region: "Fresno County", latlng: { lat: 36.305023, lng: -120.143322 }, zoomLevel: "14" },
  { name: "United States", region: "Fresno", latlng: { lat: 36.730075, lng: -119.993461 }, zoomLevel: "13" },
  { name: "United States", region: "Acampo", latlng: { lat: 37.74333, lng: -120.938071 }, zoomLevel: "13" },
  { name: "United States", region: "Menlo Park", latlng: { lat: 37.49281, lng: -122.187687 }, zoomLevel: "17" },
  { name: "Brazil", region: "Belo Horizonte", latlng: { lat: -19.939314, lng: -43.893645 }, zoomLevel: "16" },
  { name: "Brazil", region: "Belo Horizonte", latlng: { lat: -19.931977, lng: -43.90837 }, zoomLevel: "17" },
  { name: "Madagascar", region: "Mitsinjo", latlng: { lat: -16.131441, lng: 45.962107 }, zoomLevel: "12" },
  { name: "United States", region: "Lambert", latlng: { lat: 34.109012, lng: -90.219531 }, zoomLevel: "17" },
  { name: "United States", region: "Keenesburg", latlng: { lat: 40.101869, lng: -104.415055 }, zoomLevel: "16" },
  { name: "United States", region: "Keenesburg", latlng: { lat: 40.101869, lng: -104.415055 }, zoomLevel: "15" },
  { name: "China", region: "Qamdo", latlng: { lat: 30.96311, lng: 94.475995 }, zoomLevel: "10" },
  { name: "Greece", region: "Elliniko", latlng: { lat: 37.87697, lng: 23.732743 }, zoomLevel: "16" },
  { name: "China", region: "Nyingchi", latlng: { lat: 30.547699, lng: 94.838544 }, zoomLevel: "9" },
  { name: "China", region: "Nyingchi", latlng: { lat: 30.013582, lng: 95.358418 }, zoomLevel: "9" },
  { name: "Greece", region: "Alimos", latlng: { lat: 37.911226, lng: 23.703775 }, zoomLevel: "16" },
  { name: "Afghanistan", region: "Kajaki", latlng: { lat: 32.328483, lng: 65.194072 }, zoomLevel: "13" },
  { name: "Djibouti", region: "Ali Sabieh", latlng: { lat: 11.159088, lng: 42.90465 }, zoomLevel: "17" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.78664, lng: -87.749407 }, zoomLevel: "17" },
  { name: "United States", region: "Oak Park", latlng: { lat: 41.900567, lng: -87.780264 }, zoomLevel: "16" },
  { name: "Australia", region: "Bedourie", latlng: { lat: -24.083251, lng: 139.849641 }, zoomLevel: "9" },
  { name: "United States", region: "Webster County", latlng: { lat: 40.201946, lng: -98.632555 }, zoomLevel: "16" },
  { name: "Canada", region: "North Vancouver", latlng: { lat: 49.310634, lng: -123.114248 }, zoomLevel: "16" },
  { name: "Mexico", region: "", latlng: { lat: 15.70599, lng: -96.204293 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Balkhash District", latlng: { lat: 45.549765, lng: 75.600772 }, zoomLevel: "8" },
  { name: "Kyrgyzstan", region: "Ysyk Kol Province", latlng: { lat: 42.241868, lng: 77.776065 }, zoomLevel: "8" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 25.161651, lng: 55.294128 }, zoomLevel: "17" },
  { name: "United States", region: "Taylor County", latlng: { lat: 30.040513, lng: -83.915798 }, zoomLevel: "16" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 25.115671, lng: 55.132401 }, zoomLevel: "14" },
  { name: "United States", region: "Taylor County", latlng: { lat: 30.058924, lng: -83.941756 }, zoomLevel: "17" },
  { name: "United States", region: "Calipatria", latlng: { lat: 33.213486, lng: -115.606384 }, zoomLevel: "14" },
  { name: "United States", region: "La Quinta", latlng: { lat: 33.640902, lng: -116.253408 }, zoomLevel: "16" },
  { name: "United States", region: "Palm Desert", latlng: { lat: 33.736537, lng: -116.367428 }, zoomLevel: "16" },
  { name: "United States", region: "La Quinta", latlng: { lat: 33.665309, lng: -116.319964 }, zoomLevel: "16" },
  { name: "United States", region: "Thermal", latlng: { lat: 33.470158, lng: -116.128648 }, zoomLevel: "15" },
  { name: "United States", region: "Riverside County", latlng: { lat: 33.595434, lng: -116.03653 }, zoomLevel: "15" },
  { name: "Germany", region: "Helgoland", latlng: { lat: 54.188894, lng: 7.873009 }, zoomLevel: "16" },
  { name: "Austria", region: "Pusterwald", latlng: { lat: 47.32094, lng: 14.381811 }, zoomLevel: "10" },
  { name: "Brazil", region: "Rio de Janeiro", latlng: { lat: -22.967565, lng: -43.176947 }, zoomLevel: "19" },
  { name: "Australia", region: "Ayers Rock", latlng: { lat: -25.350924, lng: 131.033805 }, zoomLevel: "17" },
  { name: "Botswana", region: "Seronga", latlng: { lat: -18.820211, lng: 22.429451 }, zoomLevel: "15" },
  { name: "Peru", region: "Castilla Province", latlng: { lat: -15.551956, lng: -72.552052 }, zoomLevel: "15" },
  { name: "Germany", region: "Wangerland", latlng: { lat: 53.722873, lng: 8.168953 }, zoomLevel: "16" },
  { name: "Guernsey", region: "Sark", latlng: { lat: 49.431147, lng: -2.362848 }, zoomLevel: "15" },
  { name: "Morocco", region: "Settat Province", latlng: { lat: 32.440671, lng: -7.515291 }, zoomLevel: "14" },
  { name: "United States", region: "Scottsdale", latlng: { lat: 33.451584, lng: -111.89631 }, zoomLevel: "18" },
  { name: "United States", region: "Scottsdale", latlng: { lat: 33.460374, lng: -111.881107 }, zoomLevel: "18" },
  { name: "United States", region: "Scottsdale", latlng: { lat: 33.473315, lng: -111.878457 }, zoomLevel: "16" },
  { name: "United States", region: "Maricopa", latlng: { lat: 32.943995, lng: -111.991786 }, zoomLevel: "15" },
  { name: "United States", region: "Pinal County", latlng: { lat: 33.084843, lng: -111.88218 }, zoomLevel: "15" },
  { name: "United States", region: "Sun Lakes", latlng: { lat: 33.208692, lng: -111.884439 }, zoomLevel: "17" },
  { name: "United States", region: "Dennehotso", latlng: { lat: 36.908279, lng: -110.013742 }, zoomLevel: "15" },
  { name: "Denmark", region: "Kastrup", latlng: { lat: 55.702119, lng: 12.73324 }, zoomLevel: "17" },
  { name: "Spain", region: "Valencia", latlng: { lat: 39.455347, lng: -0.350448 }, zoomLevel: "17" },
  { name: "Iceland", region: "", latlng: { lat: 64.032299, lng: -17.202616 }, zoomLevel: "15" },
  { name: "Iceland", region: "South", latlng: { lat: 63.749262, lng: -17.503828 }, zoomLevel: "15" },
  { name: "Iceland", region: "South", latlng: { lat: 63.83182, lng: -17.548181 }, zoomLevel: "15" },
  { name: "Iceland", region: "South", latlng: { lat: 63.649529, lng: -20.439871 }, zoomLevel: "15" },
  { name: "Iceland", region: "South", latlng: { lat: 63.784659, lng: -20.790489 }, zoomLevel: "16" },
  { name: "Faroe Islands", region: "Skali", latlng: { lat: 62.150583, lng: -6.784594 }, zoomLevel: "14" },
  { name: "United States", region: "Bakersfield", latlng: { lat: 35.421091, lng: -118.82598 }, zoomLevel: "18" },
  { name: "Iran", region: "Ilam", latlng: { lat: 33.610911, lng: 45.932089 }, zoomLevel: "14" },
  { name: "China", region: "Rikaze", latlng: { lat: 29.238907, lng: 88.896947 }, zoomLevel: "15" },
  { name: "China", region: "Rikaze", latlng: { lat: 29.360091, lng: 89.468321 }, zoomLevel: "12" },
  { name: "China", region: "Rikaze", latlng: { lat: 29.76976, lng: 86.863532 }, zoomLevel: "16" },
  { name: "China", region: "Rikaze", latlng: { lat: 29.802722, lng: 86.958632 }, zoomLevel: "14" },
  { name: "China", region: "Rikaze", latlng: { lat: 30.4765, lng: 86.094328 }, zoomLevel: "14" },
  { name: "China", region: "Nagqu", latlng: { lat: 31.522946, lng: 85.714827 }, zoomLevel: "15" },
  { name: "China", region: "Nagqu", latlng: { lat: 31.643078, lng: 85.524541 }, zoomLevel: "14" },
  { name: "China", region: "Nagqu", latlng: { lat: 31.645471, lng: 85.554163 }, zoomLevel: "17" },
  { name: "China", region: "Nagqu", latlng: { lat: 32.174045, lng: 85.116013 }, zoomLevel: "15" },
  { name: "China", region: "Nagqu", latlng: { lat: 32.441439, lng: 85.744981 }, zoomLevel: "14" },
  { name: "China", region: "Ngari", latlng: { lat: 32.965786, lng: 84.480351 }, zoomLevel: "14" },
  { name: "France", region: "Paris", latlng: { lat: 48.873732, lng: 2.295059 }, zoomLevel: "17" },
  { name: "United States", region: "Lititz", latlng: { lat: 40.171397, lng: -76.245289 }, zoomLevel: "15" },
  { name: "Germany", region: "Hanover", latlng: { lat: 52.392486, lng: 9.740407 }, zoomLevel: "17" },
  { name: "West Sahara", region: "", latlng: { lat: 26.738712, lng: -13.026609 }, zoomLevel: "15" },
  { name: "West Sahara", region: "", latlng: { lat: 26.49074, lng: -13.024807 }, zoomLevel: "16" },
  { name: "West Sahara", region: "", latlng: { lat: 26.483634, lng: -13.083301 }, zoomLevel: "16" },
  { name: "Germany", region: "Bottrop", latlng: { lat: 51.526574, lng: 6.956121 }, zoomLevel: "17" },
  { name: "Sudan", region: "Port Sudan", latlng: { lat: 19.210609, lng: 37.339826 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Glynneath", latlng: { lat: 51.757162, lng: -3.646388 }, zoomLevel: "16" },
  { name: "United Kingdom", region: "Shetland Islands", latlng: { lat: 60.310303, lng: -1.514751 }, zoomLevel: "15" },
  { name: "Senegal", region: "Matam", latlng: { lat: 15.685993, lng: -13.851914 }, zoomLevel: "12" },
  { name: "United States", region: "Youngstown", latlng: { lat: 43.252556, lng: -79.049399 }, zoomLevel: "18" },
  { name: "United States", region: "Grand Rapids", latlng: { lat: 42.848568, lng: -85.676708 }, zoomLevel: "14" },
  { name: "United States", region: "Kent County", latlng: { lat: 42.848946, lng: -85.679025 }, zoomLevel: "16" },
  { name: "Peru", region: "Nazca Province", latlng: { lat: -14.772595, lng: -74.8668 }, zoomLevel: "16" },
  { name: "United States", region: "Bolivar Peninsula", latlng: { lat: 29.408683, lng: -94.631172 }, zoomLevel: "17" },
  { name: "France", region: "Paris", latlng: { lat: 48.880439, lng: 2.32065 }, zoomLevel: "17" },
  { name: "China", region: "Suihua", latlng: { lat: 45.98423, lng: 125.84693 }, zoomLevel: "14" },
  { name: "China", region: "Haerbin", latlng: { lat: 45.370871, lng: 126.591254 }, zoomLevel: "14" },
  { name: "South Africa", region: "Port Elizabeth", latlng: { lat: -33.951247, lng: 25.612855 }, zoomLevel: "16" },
  { name: "Antarctica", region: "", latlng: { lat: -68.17272, lng: -67.090588 }, zoomLevel: 11.25 },
  { name: "Italy", region: "Ancona", latlng: { lat: 43.546196, lng: 13.628529 }, zoomLevel: "19" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.889649, lng: -87.613778 }, zoomLevel: "16" },
  { name: "Canada", region: "Alert", latlng: { lat: 82.403898, lng: -62.744035 }, zoomLevel: "14" },
  { name: "United States", region: "Clayton County", latlng: { lat: 33.641295, lng: -84.425061 }, zoomLevel: "16" },
  { name: "Mexico", region: "Mexico City", latlng: { lat: 19.501599, lng: -99.216585 }, zoomLevel: "16" },
  { name: "United States", region: "Plainfield", latlng: { lat: 43.563819, lng: -72.378488 }, zoomLevel: "14" },
  { name: "Mexico", region: "Naucalpan", latlng: { lat: 19.46832, lng: -99.221033 }, zoomLevel: "17" },
  { name: "Turkey", region: "Istanbul", latlng: { lat: 41.102942, lng: 28.991188 }, zoomLevel: "14" },
  { name: "United States", region: "Norfolk", latlng: { lat: 36.920374, lng: -76.177204 }, zoomLevel: "16" },
  { name: "United States", region: "Longboat Key", latlng: { lat: 27.442993, lng: -82.690272 }, zoomLevel: "17" },
  { name: "United States", region: "New York", latlng: { lat: 40.690169, lng: -74.04467 }, zoomLevel: "18" },
  { name: "Poland", region: "Lipie, Lubaczów County", latlng: { lat: 50.367776, lng: 23.4184 }, zoomLevel: "16" },
  { name: "Peru", region: "Casma Province", latlng: { lat: -9.558518, lng: -78.188061 }, zoomLevel: "16" },
  { name: "Russia", region: "Moscow", latlng: { lat: 55.75245, lng: 37.623013 }, zoomLevel: "14" },
  { name: "United States", region: "Boston", latlng: { lat: 42.362712, lng: -71.060365 }, zoomLevel: "16" },
  { name: "United States", region: "Woodland Park", latlng: { lat: 38.921356, lng: -105.047923 }, zoomLevel: "13" },
  { name: "United Kingdom", region: "Bletchingley", latlng: { lat: 51.264721, lng: -0.128617 }, zoomLevel: "16" },
  { name: "United States", region: "Washington", latlng: { lat: 38.872778, lng: -77.028408 }, zoomLevel: "16" },
  { name: "United States", region: "Easton", latlng: { lat: 38.763052, lng: -76.11135 }, zoomLevel: "15" },
  { name: "United States", region: "Lake Lure", latlng: { lat: 35.431582, lng: -82.189407 }, zoomLevel: "14" },
  { name: "Australia", region: "Queensland", latlng: { lat: -24.624394, lng: 153.277205 }, zoomLevel: "16" },
  { name: "Australia", region: "Sylvania Waters", latlng: { lat: -34.017768, lng: 151.112137 }, zoomLevel: "17" },
  { name: "Japan", region: "Ichikawa", latlng: { lat: 35.671232, lng: 139.954314 }, zoomLevel: "15" },
  { name: "Bermuda", region: "St.George's", latlng: { lat: 32.362554, lng: -64.682629 }, zoomLevel: "15" },
  { name: "United States", region: "Winifrede", latlng: { lat: 38.148435, lng: -81.57207 }, zoomLevel: "17" },
  { name: "France", region: "Paray-Vieille-Poste", latlng: { lat: 48.729714, lng: 2.365725 }, zoomLevel: "17" },
  { name: "Maldives", region: "Malé", latlng: { lat: 4.250097, lng: 73.544465 }, zoomLevel: "18" },
  { name: "United States", region: "Honolulu", latlng: { lat: 21.261825, lng: -157.809431 }, zoomLevel: "16" },
  { name: "United States", region: "Long Island", latlng: { lat: 43.697549, lng: -70.153788 }, zoomLevel: "17" },
  { name: "Libya", region: "Al Kufrah", latlng: { lat: 24.671608, lng: 24.486809 }, zoomLevel: "14" },
  { name: "Panama", region: "Panama", latlng: { lat: 8.914909, lng: -79.526006 }, zoomLevel: "16" },
  { name: "France", region: "Grimaud", latlng: { lat: 43.270175, lng: 6.583847 }, zoomLevel: "17" },
  { name: "Afghanistan", region: "Salang", latlng: { lat: 35.133115, lng: 69.303034 }, zoomLevel: "16" },
  { name: "Chile", region: "Isla de Pascua", latlng: { lat: -27.189029, lng: -109.431767 }, zoomLevel: "16" },
  { name: "France", region: "Paris", latlng: { lat: 48.863402, lng: 2.3017 }, zoomLevel: "14" },
  { name: "France", region: "Paris", latlng: { lat: 48.867471, lng: 2.364952 }, zoomLevel: "14" },
  { name: "Belgium", region: "Koekelberg", latlng: { lat: 50.865845, lng: 4.322734 }, zoomLevel: "16" },
  { name: "Tanzania", region: "Rombo", latlng: { lat: -3.063939, lng: 37.358065 }, zoomLevel: "17" },
  { name: "United States", region: "Metairie", latlng: { lat: 29.996822, lng: -90.136449 }, zoomLevel: "17" },
  { name: "Austria", region: "Kaprun", latlng: { lat: 47.19962, lng: 12.688053 }, zoomLevel: "14" },
  { name: "Norway", region: "Skedsmo", latlng: { lat: 59.974439, lng: 11.088295 }, zoomLevel: "16" },
  { name: "Canada", region: "Alert", latlng: { lat: 82.421623, lng: -62.457103 }, zoomLevel: "12" },
  { name: "Hong Kong", region: "Hong Kong", latlng: { lat: 22.284753, lng: 114.257555 }, zoomLevel: "15" },
  { name: "Heard Island and McDonald Islands", region: "", latlng: { lat: -53.107577, lng: 73.680839 }, zoomLevel: 11.5 },
  { name: "Germany", region: "Berlin", latlng: { lat: 52.514131, lng: 13.351396 }, zoomLevel: "17" },
  { name: "The Netherlands", region: "Linne", latlng: { lat: 51.170114, lng: 5.93399 }, zoomLevel: "16" },
  { name: "Poland", region: "Malbork", latlng: { lat: 54.041147, lng: 19.032965 }, zoomLevel: "15" },
  { name: "United States", region: "Cleveland", latlng: { lat: 41.406895, lng: -81.856792 }, zoomLevel: "16" },
  { name: "Australia", region: "Kimbolton", latlng: { lat: -16.383201, lng: 123.957775 }, zoomLevel: "14" },
  { name: "Australia", region: "Kimbolton", latlng: { lat: -16.358002, lng: 124.169863 }, zoomLevel: "15" },
  { name: "Argentina", region: "General Sarmiento", latlng: { lat: -28.683312, lng: -68.066826 }, zoomLevel: "10" },
  { name: "China", region: "Jiuquan", latlng: { lat: 40.451254, lng: 93.743736 }, zoomLevel: 17 },
  { name: "Syria", region: "Al-Hasakah District", latlng: { lat: 36.532657, lng: 40.858197 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.170487, lng: -11.593061 }, zoomLevel: "11" },
  { name: "Algeria", region: "Sfissifa", latlng: { lat: 32.514342, lng: -0.908907 }, zoomLevel: "15" },
  { name: "Algeria", region: "Djeniene Bourezg", latlng: { lat: 32.466198, lng: -0.902555 }, zoomLevel: "15" },
  { name: "Mexico", region: "La Biznaga", latlng: { lat: 25.381825, lng: -100.640928 }, zoomLevel: "11" },
  { name: "Mexico", region: "El Arco", latlng: { lat: 26.136435, lng: -100.597841 }, zoomLevel: "14" },
  { name: "Mexico", region: "El Arco", latlng: { lat: 26.121948, lng: -100.775338 }, zoomLevel: "11" },
  { name: "Iran", region: "Tabas", latlng: { lat: 33.532819, lng: 56.206672 }, zoomLevel: "15" },
  { name: "Iran", region: "Tabas", latlng: { lat: 33.53196, lng: 56.185815 }, zoomLevel: "15" },
  { name: "Iran", region: "Tabas", latlng: { lat: 34.030479, lng: 56.042478 }, zoomLevel: "14" },
  { name: "Brazil", region: "Barcelos", latlng: { lat: -0.294916, lng: -62.761214 }, zoomLevel: "13" },
  { name: "Mongolia", region: "Erdene", latlng: { lat: 44.283436, lng: 97.827763 }, zoomLevel: "15" },
  { name: "United States", region: "Naples", latlng: { lat: 25.867137, lng: -81.484717 }, zoomLevel: "15" },
  { name: "United States", region: "Naples", latlng: { lat: 25.88683, lng: -81.514071 }, zoomLevel: "15" },
  { name: "United States", region: "Naples", latlng: { lat: 25.872963, lng: -81.497892 }, zoomLevel: "19" },
  { name: "United States", region: "Naples", latlng: { lat: 25.875512, lng: -81.502012 }, zoomLevel: "16" },
  { name: "India", region: "Chargheri", latlng: { lat: 22.012136, lng: 89.005729 }, zoomLevel: "16" },
  { name: "India", region: "", latlng: { lat: 21.431661, lng: 88.57237 }, zoomLevel: "13" },
  { name: "United States", region: "Garden City", latlng: { lat: 37.900202, lng: -100.858047 }, zoomLevel: "11" },
  { name: "United States", region: "Sublette", latlng: { lat: 37.692936, lng: -100.822341 }, zoomLevel: "10" },
  { name: "Algeria", region: "Ouargla", latlng: { lat: 30.378725, lng: 9.050503 }, zoomLevel: "14" },
  { name: "Namibia", region: "Hardap", latlng: { lat: -24.706592, lng: 15.057544 }, zoomLevel: 16.5 },
  { name: "Namibia", region: "Hardap", latlng: { lat: -24.710803, lng: 15.338725 }, zoomLevel: "13" },
  { name: "Argentina", region: "San Fernando (Islas)", latlng: { lat: -34.154097, lng: -58.487403 }, zoomLevel: "13" },
  { name: "Pakistan", region: "Chitral", latlng: { lat: 35.460091, lng: 71.688914 }, zoomLevel: "9" },
  { name: "Pakistan", region: "Lakki Marwat District", latlng: { lat: 32.333466, lng: 70.886483 }, zoomLevel: "15" },
  { name: "Pakistan", region: "Federally Administered Tribal Areas", latlng: { lat: 31.542532, lng: 69.88647 }, zoomLevel: "13" },
  { name: "Chad", region: "Ennedi", latlng: { lat: 19.148888, lng: 20.456283 }, zoomLevel: "15" },
  { name: "Namibia", region: "Omaheke", latlng: { lat: -23.572551, lng: 18.641068 }, zoomLevel: "15" },
  { name: "Russia", region: "Privolzhsky District, Astrakhan Oblast", latlng: { lat: 46.294003, lng: 48.299029 }, zoomLevel: "11" },
  { name: "Russia", region: "Volodarsky District, Astrakhan Oblast", latlng: { lat: 46.314993, lng: 48.514121 }, zoomLevel: "14" },
  { name: "Greenland", region: "", latlng: { lat: 73.574157, lng: -25.716172 }, zoomLevel: "9" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 60.387346, lng: -68.127762 }, zoomLevel: "14" },
  { name: "Argentina", region: "Chilecito", latlng: { lat: -29.487228, lng: -67.533167 }, zoomLevel: "14" },
  { name: "Argentina", region: "Tinogasta Department", latlng: { lat: -26.913832, lng: -67.224262 }, zoomLevel: "15" },
  { name: "Argentina", region: "Tinogasta Department", latlng: { lat: -26.905452, lng: -67.24645 }, zoomLevel: "13" },
  { name: "Argentina", region: "Antofagasta de la Sierra Department", latlng: { lat: -25.91041, lng: -68.430398 }, zoomLevel: "14" },
  { name: "Chile", region: "Chañaral Province", latlng: { lat: -26.110879, lng: -68.933237 }, zoomLevel: "14" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.960795, lng: -111.753736 }, zoomLevel: "17" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.98243, lng: -111.778048 }, zoomLevel: "17" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.990931, lng: -111.814386 }, zoomLevel: "18" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.985335, lng: -111.845983 }, zoomLevel: "18" },
  { name: "United States", region: "Marble Canyon", latlng: { lat: 36.989663, lng: -111.836681 }, zoomLevel: "15" },
  { name: "The Netherlands", region: "Beegden", latlng: { lat: 51.178858, lng: 5.91339 }, zoomLevel: "15" },
  { name: "Libya", region: "Darnah", latlng: { lat: 32.863845, lng: 22.327974 }, zoomLevel: "15" },
  { name: "Australia", region: "Rose Bay", latlng: { lat: -33.869168, lng: 151.259036 }, zoomLevel: "17" },
  { name: "The Bahamas", region: "Exuma", latlng: { lat: 23.460097, lng: -75.919733 }, zoomLevel: "15" },
  { name: "Libya", region: "Murzuq", latlng: { lat: 23.233262, lng: 13.985574 }, zoomLevel: "15" },
  { name: "United States", region: "Tooele County", latlng: { lat: 40.782458, lng: -112.602653 }, zoomLevel: "16" },
  { name: "United States", region: "Ogden", latlng: { lat: 41.304475, lng: -112.247574 }, zoomLevel: "15" },
  { name: "Antarctica", region: "McMurdo", latlng: { lat: -77.846157, lng: 166.74334 }, zoomLevel: 11 },
  { name: "Iraq", region: "Al-Qa'im", latlng: { lat: 34.231408, lng: 41.098491 }, zoomLevel: "15" },
  { name: "France", region: "Nice", latlng: { lat: 43.69388, lng: 7.282734 }, zoomLevel: "17" },
  { name: "United States", region: "Beale AFB", latlng: { lat: 39.136397, lng: -121.357513 }, zoomLevel: "16" },
  { name: "United Arab Emirates", region: "Abu Dhabi", latlng: { lat: 24.780578, lng: 54.705412 }, zoomLevel: "17" },
  { name: "United States", region: "Amherst", latlng: { lat: 34.034061, lng: -102.469289 }, zoomLevel: "13" },
  { name: "United States", region: "Dover", latlng: { lat: 39.126903, lng: -75.467288 }, zoomLevel: "17" },
  { name: "China", region: "Hetian", latlng: { lat: 36.169547, lng: 80.933102 }, zoomLevel: "15" },
  { name: "China", region: "Hetian", latlng: { lat: 36.18323, lng: 80.951706 }, zoomLevel: "17" },
  { name: "Argentina", region: "Santa Carlos", latlng: { lat: -25.423245, lng: -65.937969 }, zoomLevel: "16" },
  { name: "Argentina", region: "Santa Carlos", latlng: { lat: -25.38413, lng: -65.931575 }, zoomLevel: "16" },
  { name: "Namibia", region: "Erongo", latlng: { lat: -21.119563, lng: 14.496129 }, zoomLevel: "12" },
  { name: "Argentina", region: "Tordillo Partido", latlng: { lat: -36.412217, lng: -56.962582 }, zoomLevel: "15" },
  { name: "United Kingdom", region: "London", latlng: { lat: 51.512806, lng: -0.141317 }, zoomLevel: "17" },
  { name: "Japan", region: "Shibuya", latlng: { lat: 35.658148, lng: 139.701636 }, zoomLevel: "18" },
  { name: "Mexico", region: "Zapotiltic", latlng: { lat: 19.569198, lng: -103.589269 }, zoomLevel: "17" },
  { name: "Mexico", region: "Zapotiltic", latlng: { lat: 19.551102, lng: -103.621885 }, zoomLevel: "11" },
  { name: "The Netherlands", region: "Breezanddijk", latlng: { lat: 53.173562, lng: 5.044709 }, zoomLevel: "13" },
  { name: "Germany", region: "Rhede", latlng: { lat: 51.837027, lng: 6.704646 }, zoomLevel: "12" },
  { name: "United States", region: "Carbon County", latlng: { lat: 39.792582, lng: -109.96048 }, zoomLevel: "13" },
  { name: "United States", region: "Carbon County", latlng: { lat: 39.781634, lng: -110.214882 }, zoomLevel: "16" },
  { name: "Australia", region: "Namatjira", latlng: { lat: -23.819515, lng: 132.306281 }, zoomLevel: "15" },
  { name: "Australia", region: "Mereenie", latlng: { lat: -23.995241, lng: 132.179681 }, zoomLevel: "14" },
  { name: "Iran", region: "Semnan", latlng: { lat: 34.59372, lng: 54.116421 }, zoomLevel: "12" },
  { name: "Jordan", region: "Amman", latlng: { lat: 31.497723, lng: 36.642227 }, zoomLevel: "12" },
  { name: "Jordan", region: "Amman", latlng: { lat: 31.547914, lng: 36.655788 }, zoomLevel: "14" },
  { name: "Australia", region: "Petermann", latlng: { lat: -24.743024, lng: 130.871187 }, zoomLevel: "14" },
  { name: "Australia", region: "Petermann", latlng: { lat: -24.64581, lng: 130.702208 }, zoomLevel: "14" },
  { name: "Mexico", region: "Los Vidrios", latlng: { lat: 31.952495, lng: -113.517226 }, zoomLevel: "13" },
  { name: "Mexico", region: "Los Vidrios", latlng: { lat: 31.897932, lng: -113.47298 }, zoomLevel: "16" },
  { name: "Libya", region: "Banghazi", latlng: { lat: 32.037626, lng: 20.422899 }, zoomLevel: "14" },
  { name: "Libya", region: "Banghazi", latlng: { lat: 32.340816, lng: 20.559541 }, zoomLevel: "15" },
  { name: "Libya", region: "Al Marj", latlng: { lat: 32.351548, lng: 20.633141 }, zoomLevel: "15" },
  { name: "Libya", region: "Al Butnan", latlng: { lat: 29.718848, lng: 24.68362 }, zoomLevel: "14" },
  { name: "Germany", region: "Nördlingen", latlng: { lat: 48.853962, lng: 10.486914 }, zoomLevel: "15" },
  { name: "France", region: "Saint-Raphaël", latlng: { lat: 43.499877, lng: 6.827457 }, zoomLevel: "14" },
  { name: "Italy", region: "Aosta Valley", latlng: { lat: 45.909421, lng: 6.951708 }, zoomLevel: "10" },
  { name: "Namibia", region: "Kunene", latlng: { lat: -20.985528, lng: 13.984594 }, zoomLevel: "14" },
  { name: "Iceland", region: "", latlng: { lat: 64.134283, lng: -16.30326 }, zoomLevel: "15" },
  { name: "Namibia", region: "Erongo", latlng: { lat: -21.68828, lng: 15.518713 }, zoomLevel: "14" },
  { name: "Canada", region: "Coral Harbour", latlng: { lat: 63.990125, lng: -83.144452 }, zoomLevel: "15" },
  { name: "Iceland", region: "Northeast", latlng: { lat: 65.841634, lng: -18.90856 }, zoomLevel: "12" },
  { name: "Algeria", region: "Djeniene Bourezg", latlng: { lat: 32.325866, lng: -1.106008 }, zoomLevel: "14" },
  { name: "United Kingdom", region: "Cumbria", latlng: { lat: 54.518901, lng: -3.173769 }, zoomLevel: "12" },
  { name: "Australia", region: "The Sill", latlng: { lat: -28.330174, lng: 137.72485 }, zoomLevel: "14" },
  { name: "Tajikistan", region: "Roziyon", latlng: { lat: 38.295787, lng: 69.324718 }, zoomLevel: "16" },
  { name: "Antarctica", region: "", latlng: { lat: -80.421482, lng: 157.319092 }, zoomLevel: "8" },
  { name: "Saudi Arabia", region: "Al Ahsa", latlng: { lat: 22.088843, lng: 54.65362 }, zoomLevel: "13" },
  { name: "Saudi Arabia", region: "Al Ahsa", latlng: { lat: 22.083196, lng: 54.680399 }, zoomLevel: "15" },
  { name: "Oman", region: "Dhofar Governorate", latlng: { lat: 17.196003, lng: 54.087909 }, zoomLevel: "14" },
  { name: "Oman", region: "Dhofar Governorate", latlng: { lat: 17.357297, lng: 55.050586 }, zoomLevel: "14" },
  { name: "Oman", region: "Dhofar Governorate", latlng: { lat: 17.351317, lng: 54.931968 }, zoomLevel: "15" },
  { name: "Oman", region: "Dhofar Governorate", latlng: { lat: 17.34466, lng: 54.945207 }, zoomLevel: "17" },
  { name: "Yemen", region: "Shabwah", latlng: { lat: 15.589197, lng: 47.50992 }, zoomLevel: "15" },
  { name: "France", region: "Roissy-en-France", latlng: { lat: 49.00739, lng: 2.525694 }, zoomLevel: "18" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.97415, lng: -87.912128 }, zoomLevel: "19" },
  { name: "United States", region: "Chicago", latlng: { lat: 41.975909, lng: -87.915143 }, zoomLevel: "19" },
  { name: "United States", region: "Blackfoot", latlng: { lat: 43.391486, lng: -112.368915 }, zoomLevel: "18" },
  { name: "United States", region: "San Bernardino County", latlng: { lat: 35.660172, lng: -117.318781 }, zoomLevel: "16" },
  { name: "United States", region: "Inyo County", latlng: { lat: 36.432245, lng: -117.956589 }, zoomLevel: "14" },
  { name: "United States", region: "Yellowstone National Park", latlng: { lat: 44.525082, lng: -110.83752 }, zoomLevel: "19" },
  { name: "United States", region: "Torrey", latlng: { lat: 37.957827, lng: -111.037424 }, zoomLevel: "13" },
  { name: "Germany", region: "Osterheide", latlng: { lat: 52.831097, lng: 9.807518 }, zoomLevel: "16" },
  { name: "Somalia", region: "El Wak", latlng: { lat: 2.448005, lng: 41.542311 }, zoomLevel: "16" },
  { name: "Germany", region: "Erfurt", latlng: { lat: 50.946159, lng: 11.029068 }, zoomLevel: "17" },
  { name: "Germany", region: "Erfurt", latlng: { lat: 50.979128, lng: 10.966342 }, zoomLevel: "19" },
  { name: "The Netherlands", region: "Drieborg", latlng: { lat: 53.246451, lng: 7.155114 }, zoomLevel: "14" },
  { name: "Peru", region: "Ica", latlng: { lat: -14.094326, lng: -75.77183 }, zoomLevel: 17 },
  { name: "Peru", region: "Yauli Province", latlng: { lat: -11.51225, lng: -75.897079 }, zoomLevel: 17.25 },
  { name: "Cambodia", region: "Krong Siem Reap", latlng: { lat: 13.411671, lng: 103.866289 }, zoomLevel: "16" },
  { name: "Democratic Republic of the Congo", region: "Tanganyika", latlng: { lat: -7.113647, lng: 29.977548 }, zoomLevel: "16" },
  { name: "Democratic Republic of the Congo", region: "Kasai", latlng: { lat: -3.837557, lng: 21.61515 }, zoomLevel: "13" },
  { name: "Vietnam", region: "Sa Thay District", latlng: { lat: 14.255316, lng: 107.806435 }, zoomLevel: "15" },
  { name: "Japan", region: "Yoron", latlng: { lat: 27.04195, lng: 128.466728 }, zoomLevel: 17 },
  { name: "Japan", region: "Yoron", latlng: { lat: 27.049279, lng: 128.461052 }, zoomLevel: 15.75 },
  { name: "Canada", region: "Niagara Falls", latlng: { lat: 43.116005, lng: -79.065191 }, zoomLevel: "16" },
  { name: "Ecuador", region: "", latlng: { lat: -0.97441, lng: -90.959179 }, zoomLevel: "17" },
  { name: "Japan", region: "Yoron", latlng: { lat: 27.057869, lng: 128.406614 }, zoomLevel: 16.75 },
  { name: "France", region: "Plouescat", latlng: { lat: 48.654126, lng: -4.21624 }, zoomLevel: "18" },
  { name: "Bolivia", region: "Atahuallpa", latlng: { lat: -19.213263, lng: -68.036442 }, zoomLevel: "14" },
  { name: "Argentina", region: "Humahuaca", latlng: { lat: -23.253253, lng: -65.114808 }, zoomLevel: "15" },
  { name: "Argentina", region: "Humahuaca", latlng: { lat: -22.793748, lng: -65.130601 }, zoomLevel: "11" },
  { name: "United States", region: "Runnells", latlng: { lat: 41.497668, lng: -93.41177 }, zoomLevel: "16" },
  { name: "Peru", region: "Arequipa", latlng: { lat: -16.299587, lng: -71.404267 }, zoomLevel: "16" },
  { name: "United States", region: "Los Angeles", latlng: { lat: 34.073329, lng: -118.24063 }, zoomLevel: "18" },
  { name: "Antarctica", region: "", latlng: { lat: -76.919025, lng: 161.065155 }, zoomLevel: 10.75 },
  { name: "Libya", region: "Al Wahat", latlng: { lat: 30.579203, lng: 20.349276 }, zoomLevel: "17" },
  { name: "Iran", region: "Ābādān", latlng: { lat: 30.130059, lng: 48.796163 }, zoomLevel: "17" },
  { name: "Iran", region: "Ābādān", latlng: { lat: 30.067061, lng: 48.84169 }, zoomLevel: "17" },
  { name: "Iran", region: "", latlng: { lat: 30.349908, lng: 48.871495 }, zoomLevel: "15" },
  { name: "Iran", region: "Mah'shahr", latlng: { lat: 30.142631, lng: 49.271209 }, zoomLevel: "16" },
  { name: "Italy", region: "Cagliari", latlng: { lat: 39.206907, lng: 9.003649 }, zoomLevel: "17" },
  { name: "United States", region: "Los Angeles", latlng: { lat: 34.081977, lng: -118.358834 }, zoomLevel: "16" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 61.784147, lng: -80.118452 }, zoomLevel: "14" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 62.614732, lng: -82.842375 }, zoomLevel: "12" },
  { name: "United States", region: "Aleutians West", latlng: { lat: 53.392933, lng: -168.110014 }, zoomLevel: "14" },
  { name: "Russia", region: "Zhigansky District", latlng: { lat: 65.344312, lng: 122.582392 }, zoomLevel: "11" },
  { name: "Kazakhstan", region: "Korgalzhyn District", latlng: { lat: 50.319964, lng: 69.08687 }, zoomLevel: "13" },
  { name: "Iran", region: "Kerman", latlng: { lat: 30.461779, lng: 58.205341 }, zoomLevel: "14" },
  { name: "Iran", region: "Kerman", latlng: { lat: 30.4135, lng: 57.640573 }, zoomLevel: "15" },
  { name: "Iran", region: "Kerman", latlng: { lat: 29.995825, lng: 59.163316 }, zoomLevel: "14" },
  { name: "Iran", region: "Zabol", latlng: { lat: 31.219773, lng: 61.279901 }, zoomLevel: "14" },
  { name: "Afghanistan", region: "Dishu", latlng: { lat: 30.506721, lng: 63.514053 }, zoomLevel: "13" },
  { name: "Afghanistan", region: "Daman", latlng: { lat: 31.035633, lng: 65.719987 }, zoomLevel: "13" },
  { name: "Ethiopia", region: "Gode", latlng: { lat: 5.402569, lng: 44.568945 }, zoomLevel: "14" },
  { name: "Somalia", region: "Darkein Genyo", latlng: { lat: 8.006928, lng: 46.974543 }, zoomLevel: "14" },
  { name: "Somalia", region: "Iskushuban", latlng: { lat: 10.471742, lng: 51.043604 }, zoomLevel: "15" },
  { name: "Somalia", region: "Iskushuban", latlng: { lat: 10.507273, lng: 51.067809 }, zoomLevel: "16" },
  { name: "Somalia", region: "Iskushuban", latlng: { lat: 10.413943, lng: 51.115917 }, zoomLevel: "16" },
  { name: "Somalia", region: "Iskushuban", latlng: { lat: 10.53818, lng: 51.190332 }, zoomLevel: "17" },
  { name: "Somalia", region: "Ceel Dheer", latlng: { lat: 4.278784, lng: 47.327415 }, zoomLevel: "15" },
  { name: "Somalia", region: "Ceel Dheer", latlng: { lat: 4.037871, lng: 46.612166 }, zoomLevel: "15" },
  { name: "France", region: "Marseille", latlng: { lat: 43.211971, lng: 5.444842 }, zoomLevel: "18" },
  { name: "Antigua and Barbuda", region: "Saint Mary", latlng: { lat: 17.058241, lng: -61.905126 }, zoomLevel: "17" },
  { name: "Botswana", region: "Gaborone", latlng: { lat: -24.663262, lng: 25.907532 }, zoomLevel: "15" },
  { name: "United States", region: "Oregon", latlng: { lat: 41.685252, lng: -83.45584 }, zoomLevel: "15" },
  { name: "Philippines", region: "Olongapo", latlng: { lat: 14.808283, lng: 120.282269 }, zoomLevel: "15" },
  { name: "United Kingdom", region: "Newhaven", latlng: { lat: 50.781317, lng: 0.036686 }, zoomLevel: "18" },
  { name: "United Kingdom", region: "Devon", latlng: { lat: 51.199806, lng: -4.674247 }, zoomLevel: "17" },
  { name: "United States", region: "Kansas City", latlng: { lat: 39.115386, lng: -94.608631 }, zoomLevel: "15" },
  { name: "Canada", region: "Levis", latlng: { lat: 46.745621, lng: -71.289548 }, zoomLevel: "18" },
  { name: "Germany", region: "Riedstadt", latlng: { lat: 49.8197, lng: 8.531347 }, zoomLevel: "18" },
  { name: "United Kingdom", region: "Brean", latlng: { lat: 51.376144, lng: -3.124064 }, zoomLevel: "18" },
  { name: "Mexico", region: "Baja California Sur", latlng: { lat: 24.912551, lng: -112.113118 }, zoomLevel: "14" },
  { name: "Mexico", region: "Santo Domingo", latlng: { lat: 25.097506, lng: -112.123353 }, zoomLevel: "16" },
  { name: "Mexico", region: "Baja California Sur", latlng: { lat: 25.108582, lng: -111.772563 }, zoomLevel: "14" },
  { name: "Mexico", region: "Guerrero Negro", latlng: { lat: 27.832354, lng: -114.050124 }, zoomLevel: "17" },
  { name: "Mexico", region: "Guerrero Negro", latlng: { lat: 27.829456, lng: -114.120365 }, zoomLevel: "16" },
  { name: "Mexico", region: "Baja California Sur", latlng: { lat: 27.819336, lng: -114.36126 }, zoomLevel: "16" },
  { name: "Mexico", region: "Baja California Sur", latlng: { lat: 27.851461, lng: -114.31283 }, zoomLevel: "16" },
  { name: "Mexico", region: "Baja California", latlng: { lat: 28.695364, lng: -112.91542 }, zoomLevel: "16" },
  { name: "Mexico", region: "San Clemente", latlng: { lat: 28.773927, lng: -111.84023 }, zoomLevel: "17" },
  { name: "Mexico", region: "Sonora", latlng: { lat: 28.689689, lng: -111.83802 }, zoomLevel: "16" },
  { name: "Mexico", region: "Sonora", latlng: { lat: 28.626433, lng: -111.780256 }, zoomLevel: "16" },
  { name: "Mexico", region: "Cuauhtémoc", latlng: { lat: 28.578205, lng: -111.729916 }, zoomLevel: "16" },
  { name: "Mexico", region: "Sonora", latlng: { lat: 28.520566, lng: -111.70597 }, zoomLevel: "17" },
  { name: "Mexico", region: "Sonora", latlng: { lat: 27.941522, lng: -111.096132 }, zoomLevel: "16" },
  { name: "Mexico", region: "Vícam", latlng: { lat: 27.817035, lng: -110.566792 }, zoomLevel: "16" },
  { name: "United States", region: "Mount Pleasant", latlng: { lat: 32.831374, lng: -79.890148 }, zoomLevel: "18" },
  { name: "The Netherlands", region: "Botlek Rotterdam", latlng: { lat: 51.880348, lng: 4.315076 }, zoomLevel: "18" },
  { name: "The Netherlands", region: "Maasvlakte Rotterdam", latlng: { lat: 51.938344, lng: 4.048856 }, zoomLevel: "17" },
  { name: "Singapore", region: "", latlng: { lat: 1.240879, lng: 103.563374 }, zoomLevel: "15" },
  { name: "Hong Kong", region: "Hong Kong", latlng: { lat: 22.286731, lng: 114.102204 }, zoomLevel: "17" },
  { name: "Spain", region: "Algeciras", latlng: { lat: 36.129389, lng: -5.424017 }, zoomLevel: "18" },
  { name: "Yemen", region: "Hadramaut", latlng: { lat: 16.012115, lng: 48.850762 }, zoomLevel: "11" },
  { name: "United Kingdom", region: "Cornwall", latlng: { lat: 50.320497, lng: -4.20882 }, zoomLevel: "17" },
  { name: "Oman", region: "Seeb", latlng: { lat: 23.709895, lng: 58.095499 }, zoomLevel: "17" },
  { name: "Austria", region: "Raasdorf", latlng: { lat: 48.248633, lng: 16.550963 }, zoomLevel: "18" },
  { name: "China", region: "Qingdao", latlng: { lat: 36.17547, lng: 120.298082 }, zoomLevel: "16" },
  { name: "United States", region: "Banning", latlng: { lat: 33.916423, lng: -116.938154 }, zoomLevel: "17" },
  { name: "Namibia", region: "Hardap", latlng: { lat: -24.495428, lng: 18.104009 }, zoomLevel: "14" },
  { name: "Israel", region: "Be'er Sheva", latlng: { lat: 30.63694, lng: 34.961615 }, zoomLevel: "16" },
  { name: "The Bahamas", region: "Ragged Island", latlng: { lat: 22.229166, lng: -75.739119 }, zoomLevel: "17" },
  { name: "United States", region: "Grassy Butte", latlng: { lat: 47.583187, lng: -103.37941 }, zoomLevel: "14" },
  { name: "Iceland", region: "Northeast", latlng: { lat: 64.712634, lng: -17.193152 }, zoomLevel: 11 },
  { name: "Maldives", region: "Guraidhoo", latlng: { lat: 4.021773, lng: 73.474775 }, zoomLevel: 11 },
  { name: "Cuba", region: "", latlng: { lat: 21.573325, lng: -82.208891 }, zoomLevel: "14" },
  { name: "The Bahamas", region: "", latlng: { lat: 24.301108, lng: -77.850409 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Kyzylorda Province", latlng: { lat: 46.080614, lng: 60.719033 }, zoomLevel: "14" },
  { name: "Peru", region: "Candarave Province", latlng: { lat: -16.990883, lng: -70.408287 }, zoomLevel: "15" },
  { name: "Australia", region: "Telfer", latlng: { lat: -23.263427, lng: 123.065758 }, zoomLevel: "14" },
  { name: "Chile", region: "Chañaral Province", latlng: { lat: -26.286393, lng: -68.573184 }, zoomLevel: "15" },
  { name: "Chile", region: "Antofagasta Province", latlng: { lat: -24.220148, lng: -69.059281 }, zoomLevel: "15" },
  { name: "Chile", region: "El Loa", latlng: { lat: -23.547071, lng: -68.426713 }, zoomLevel: "15" },
  { name: "Chile", region: "El Loa", latlng: { lat: -23.642873, lng: -68.295736 }, zoomLevel: "14" },
  { name: "Costa Rica", region: "Guanacaste", latlng: { lat: 10.828651, lng: -85.351359 }, zoomLevel: "17" },
  { name: "United States", region: "Delta Junction", latlng: { lat: 63.307118, lng: -145.13003 }, zoomLevel: "11" },
  { name: "Chad", region: "Ennedi", latlng: { lat: 18.805792, lng: 22.877479 }, zoomLevel: "15" },
  { name: "Australia", region: "Northern Territory", latlng: { lat: -11.992309, lng: 131.807527 }, zoomLevel: 10 },
  { name: "Germany", region: "Übersee", latlng: { lat: 47.864352, lng: 12.499242 }, zoomLevel: "17" },
  { name: "Tanzania", region: "Rombo", latlng: { lat: -3.063896, lng: 37.339911 }, zoomLevel: "15" },
  { name: "United States", region: "Tyonek", latlng: { lat: 60.994336, lng: -151.873922 }, zoomLevel: "14" },
  { name: "Iceland", region: "South", latlng: { lat: 64.082499, lng: -18.943005 }, zoomLevel: "14" },
  { name: "Chile", region: "Palena Province", latlng: { lat: -41.879671, lng: -72.437325 }, zoomLevel: "16" },
  { name: "Germany", region: "Bad Hindelang", latlng: { lat: 47.377937, lng: 10.414048 }, zoomLevel: "16" },
  { name: "New Caledonia", region: "Mouidou", latlng: { lat: -21.776303, lng: 165.620855 }, zoomLevel: 16.75 },
  { name: "Northern Mariana Islands", region: "", latlng: { lat: 20.546028, lng: 144.8973 }, zoomLevel: "17" },
  { name: "Iceland", region: "Northwest", latlng: { lat: 65.822777, lng: -19.74213 }, zoomLevel: "16" },
  { name: "Spain", region: "Madrid", latlng: { lat: 40.468889, lng: -3.722294 }, zoomLevel: "17" },
  { name: "Ireland", region: "Galway", latlng: { lat: 53.096602, lng: -9.58228 }, zoomLevel: "17" },
  { name: "Ecuador", region: "Galapagos", latlng: { lat: -0.377823, lng: -91.498642 }, zoomLevel: "16" },
  { name: "Russia", region: "Bulunsky District", latlng: { lat: 73.268982, lng: 124.90327 }, zoomLevel: "10" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.486581, lng: -16.380096 }, zoomLevel: "15" },
  { name: "Turkey", region: "Dibecik", latlng: { lat: 36.823302, lng: 37.660617 }, zoomLevel: "14" },
  { name: "Syria", region: "Tall Rifat", latlng: { lat: 36.473082, lng: 37.084028 }, zoomLevel: "15" },
  { name: "United States", region: "Hanapepe", latlng: { lat: 21.894115, lng: -159.605953 }, zoomLevel: "18" },
  { name: "United Kingdom", region: "Isles of Scilly", latlng: { lat: 49.952876, lng: -6.33085 }, zoomLevel: "16" },
  { name: "Ireland", region: "Mayo", latlng: { lat: 53.868809, lng: -9.604827 }, zoomLevel: "15" },
  { name: "Kiribati", region: "", latlng: { lat: 3.850211, lng: -159.267233 }, zoomLevel: "15" },
  { name: "United States", region: "Honolulu County", latlng: { lat: 23.793405, lng: -166.188018 }, zoomLevel: "15" },
  { name: "United States", region: "Honolulu County", latlng: { lat: 25.769296, lng: -171.766928 }, zoomLevel: "14" },
  { name: "United States", region: "", latlng: { lat: 28.206483, lng: -177.371071 }, zoomLevel: "15" },
  { name: "Colombia", region: "Uribia", latlng: { lat: 12.413842, lng: -71.654549 }, zoomLevel: "15" },
  { name: "Russia", region: "Anadyrsky District", latlng: { lat: 65.666757, lng: 173.389046 }, zoomLevel: "12" },
  { name: "Russia", region: "Anadyrsky District", latlng: { lat: 65.430862, lng: 173.440887 }, zoomLevel: "12" },
  { name: "Australia", region: "Gairdner", latlng: { lat: -32.013123, lng: 135.351527 }, zoomLevel: "15" },
  { name: "Argentina", region: "Añelo Department", latlng: { lat: -38.315374, lng: -69.30462 }, zoomLevel: "14" },
  { name: "Argentina", region: "Añelo Department", latlng: { lat: -38.421026, lng: -69.550096 }, zoomLevel: "14" },
  { name: "Diomede Ilands", region: "", latlng: { lat: 65.741067, lng: -169.016554 }, zoomLevel: "14" },
  { name: "Argentina", region: "Loncopué Department", latlng: { lat: -38.022921, lng: -69.924052 }, zoomLevel: "16" },
  { name: "Iraq", region: "Baghdad", latlng: { lat: 33.253187, lng: 44.273507 }, zoomLevel: "15" },
  { name: "Afghanistan", region: "Musa Qala", latlng: { lat: 32.076029, lng: 64.842166 }, zoomLevel: "15" },
  { name: "Australia", region: "Mcarthur", latlng: { lat: -15.827114, lng: 136.643879 }, zoomLevel: "14" },
  { name: "United Arab Emirates", region: "Umm Al Quwain", latlng: { lat: 25.616368, lng: 55.649874 }, zoomLevel: "15" },
  { name: "United States", region: "Lakeshore", latlng: { lat: 37.33198, lng: -118.943513 }, zoomLevel: "12" },
  { name: "China", region: "Ngari", latlng: { lat: 31.240638, lng: 85.092322 }, zoomLevel: "15" },
  { name: "Iceland", region: "", latlng: { lat: 63.980665, lng: -17.17029 }, zoomLevel: "15" },
  { name: "Iceland", region: "South", latlng: { lat: 63.989187, lng: -17.423791 }, zoomLevel: "15" },
  { name: "United Kingdom", region: "Isle of Anglesey", latlng: { lat: 53.231934, lng: -4.153648 }, zoomLevel: "14" },
  { name: "Spain", region: "Palma de Mallorca", latlng: { lat: 39.570483, lng: 2.644315 }, zoomLevel: "16" },
  { name: "United States", region: "Keweenaw County", latlng: { lat: 48.019842, lng: -88.697477 }, zoomLevel: "16" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -20.290376, lng: -67.844181 }, zoomLevel: "14" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -19.836556, lng: -67.647393 }, zoomLevel: "17" },
  { name: "Bolivia", region: "Atahuallpa", latlng: { lat: -19.683562, lng: -67.622282 }, zoomLevel: "17" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -19.773565, lng: -67.296597 }, zoomLevel: "17" },
  { name: "Bolivia", region: "Ladislao Cabrera", latlng: { lat: -19.657073, lng: -67.39232 }, zoomLevel: "15" },
  { name: "Bolivia", region: "Antonio Quijarro", latlng: { lat: -19.946985, lng: -67.050113 }, zoomLevel: "15" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -19.974152, lng: -67.166199 }, zoomLevel: "17" },
  { name: "Bolivia", region: "Antonio Quijarro", latlng: { lat: -20.09678, lng: -67.146758 }, zoomLevel: "16" },
  { name: "Bolivia", region: "Antonio Quijarro", latlng: { lat: -20.151038, lng: -67.135536 }, zoomLevel: "16" },
  { name: "Bolivia", region: "Nor Lípez", latlng: { lat: -20.577588, lng: -67.078158 }, zoomLevel: "14" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -19.977621, lng: -68.184945 }, zoomLevel: "15" },
  { name: "Mexico", region: "Guaymas", latlng: { lat: 27.909159, lng: -110.884087 }, zoomLevel: "16" },
  { name: "Ethiopia", region: "Afar Zone 2", latlng: { lat: 14.241522, lng: 40.298383 }, zoomLevel: "17" },
  { name: "France", region: "Camaret-sur-Mer", latlng: { lat: 48.280435, lng: -4.628913 }, zoomLevel: "18" },
  { name: "France", region: "La Bréole", latlng: { lat: 44.46494, lng: 6.310588 }, zoomLevel: "14" },
  { name: "France", region: "Marseille", latlng: { lat: 43.210144, lng: 5.422626 }, zoomLevel: "18" },
  { name: "Chile", region: "Antofagasta Province", latlng: { lat: -25.073513, lng: -68.533192 }, zoomLevel: "14" },
  { name: "Iceland", region: "", latlng: { lat: 63.916203, lng: -21.244136 }, zoomLevel: "14" },
  { name: "Namibia", region: "Karas", latlng: { lat: -27.588219, lng: 17.288751 }, zoomLevel: "12" },
  { name: "Chile", region: "Chañaral Province", latlng: { lat: -26.411097, lng: -69.818933 }, zoomLevel: "16" },
  { name: "United States", region: "Kanab", latlng: { lat: 37.274018, lng: -111.989679 }, zoomLevel: "12" },
  { name: "United States", region: "Chitina", latlng: { lat: 61.51006, lng: -144.403437 }, zoomLevel: "16" },
  { name: "United States", region: "Norfolk", latlng: { lat: 36.834514, lng: -76.292823 }, zoomLevel: "14" },
  { name: "Saudi Arabia", region: "Riyadh", latlng: { lat: 24.657665, lng: 46.644559 }, zoomLevel: "14" },
  { name: "United States", region: "Juneau", latlng: { lat: 58.529994, lng: -134.474801 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.386742, lng: -16.520021 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.374536, lng: -16.482781 }, zoomLevel: "17" },
  { name: "Antarctica", region: "", latlng: { lat: -73.185729, lng: 66.461244 }, zoomLevel: "8" },
  { name: "Australia", region: "Robinvale Irrigation District Section C", latlng: { lat: -34.624335, lng: 142.756916 }, zoomLevel: "16" },
  { name: "Australia", region: "Oxley", latlng: { lat: -34.125474, lng: 143.843318 }, zoomLevel: "15" },
  { name: "Australia", region: "Menindee", latlng: { lat: -32.617788, lng: 142.185069 }, zoomLevel: "12" },
  { name: "Australia", region: "Anabranch North", latlng: { lat: -33.422299, lng: 141.862517 }, zoomLevel: "12" },
  { name: "Australia", region: "Gairdner", latlng: { lat: -31.432938, lng: 136.762114 }, zoomLevel: "14" },
  { name: "Australia", region: "Cameron Corner", latlng: { lat: -27.849881, lng: 142.009116 }, zoomLevel: "12" },
  { name: "Australia", region: "Eromanga", latlng: { lat: -26.455388, lng: 141.791106 }, zoomLevel: "14" },
  { name: "Australia", region: "Tanbar", latlng: { lat: -26.121101, lng: 141.554986 }, zoomLevel: "15" },
  { name: "Australia", region: "Tanbar", latlng: { lat: -26.131427, lng: 141.713429 }, zoomLevel: "14" },
  { name: "Australia", region: "Windorah", latlng: { lat: -25.723548, lng: 142.275277 }, zoomLevel: "14" },
  { name: "Australia", region: "Burketown", latlng: { lat: -17.750842, lng: 139.648343 }, zoomLevel: "14" },
  { name: "Australia", region: "Burketown", latlng: { lat: -17.736945, lng: 139.615169 }, zoomLevel: "15" },
  { name: "Australia", region: "Creswell", latlng: { lat: -18.02874, lng: 136.425508 }, zoomLevel: "16" },
  { name: "Australia", region: "Tablelands", latlng: { lat: -18.610143, lng: 135.614408 }, zoomLevel: "15" },
  { name: "Australia", region: "Tanami", latlng: { lat: -20.939206, lng: 130.019728 }, zoomLevel: "15" },
  { name: "Australia", region: "Tanami", latlng: { lat: -20.982728, lng: 130.138946 }, zoomLevel: "15" },
  { name: "Australia", region: "Tanami", latlng: { lat: -20.867123, lng: 130.438238 }, zoomLevel: "15" },
  { name: "Australia", region: "Tanami", latlng: { lat: -20.874902, lng: 129.70018 }, zoomLevel: "14" },
  { name: "Australia", region: "Widgiemooltha", latlng: { lat: -31.225325, lng: 122.119442 }, zoomLevel: "14" },
  { name: "Australia", region: "Salmon Gums", latlng: { lat: -32.59561, lng: 121.792599 }, zoomLevel: "14" },
  { name: "Australia", region: "Norseman", latlng: { lat: -32.399141, lng: 120.886741 }, zoomLevel: "11" },
  { name: "Australia", region: "Ularring", latlng: { lat: -29.246912, lng: 119.385734 }, zoomLevel: "12" },
  { name: "Australia", region: "Leigh Creek", latlng: { lat: -30.602283, lng: 138.360754 }, zoomLevel: "14" },
  { name: "Australia", region: "Andamooka", latlng: { lat: -30.526899, lng: 137.564588 }, zoomLevel: "14" },
  { name: "Australia", region: "Olympic Dam", latlng: { lat: -30.432218, lng: 136.861034 }, zoomLevel: "14" },
  { name: "Australia", region: "Olympic Dam", latlng: { lat: -30.411772, lng: 136.831235 }, zoomLevel: "17" },
  { name: "Australia", region: "Glendambo", latlng: { lat: -30.871482, lng: 136.037516 }, zoomLevel: "15" },
  { name: "Australia", region: "Andamooka", latlng: { lat: -29.430833, lng: 137.353021 }, zoomLevel: "17" },
  { name: "Australia", region: "South Australia", latlng: { lat: -29.395954, lng: 137.327057 }, zoomLevel: "16" },
  { name: "Australia", region: "Andamooka", latlng: { lat: -29.442718, lng: 137.302596 }, zoomLevel: "15" },
  { name: "Australia", region: "Andamooka", latlng: { lat: -29.440626, lng: 137.150075 }, zoomLevel: "14" },
  { name: "Australia", region: "Marree", latlng: { lat: -29.47534, lng: 138.198261 }, zoomLevel: "15" },
  { name: "Australia", region: "Marree", latlng: { lat: -29.810059, lng: 137.842407 }, zoomLevel: "14" },
  { name: "Australia", region: "Moomba", latlng: { lat: -28.368728, lng: 139.261617 }, zoomLevel: "13" },
  { name: "Australia", region: "Moomba", latlng: { lat: -27.661466, lng: 140.119237 }, zoomLevel: "15" },
  { name: "Australia", region: "Bourke", latlng: { lat: -29.98529, lng: 146.43938 }, zoomLevel: "14" },
  { name: "Australia", region: "Hungerford", latlng: { lat: -28.691285, lng: 144.344854 }, zoomLevel: "14" },
  { name: "Australia", region: "Maralinga", latlng: { lat: -29.183134, lng: 130.468607 }, zoomLevel: "12" },
  { name: "Australia", region: "Plumridge Lakes", latlng: { lat: -29.660032, lng: 123.401393 }, zoomLevel: "13" },
  { name: "Australia", region: "Kumarina", latlng: { lat: -25.583034, lng: 120.054683 }, zoomLevel: "12" },
  { name: "Australia", region: "Kumarina", latlng: { lat: -25.56515, lng: 119.922504 }, zoomLevel: "15" },
  { name: "Australia", region: "Western Australia", latlng: { lat: -26.139933, lng: 113.248739 }, zoomLevel: "14" },
  { name: "Australia", region: "Ningaloo", latlng: { lat: -22.658631, lng: 113.669481 }, zoomLevel: "15" },
  { name: "Australia", region: "Onslow", latlng: { lat: -21.714075, lng: 115.093755 }, zoomLevel: "14" },
  { name: "Australia", region: "Peedamulla", latlng: { lat: -21.586993, lng: 115.474414 }, zoomLevel: "15" },
  { name: "Australia", region: "Peedamulla", latlng: { lat: -21.43803, lng: 115.58329 }, zoomLevel: "15" },
  { name: "Australia", region: "Western Australia", latlng: { lat: -20.865008, lng: 115.339703 }, zoomLevel: "16" },
  { name: "Australia", region: "Port Hedland", latlng: { lat: -20.357885, lng: 118.619701 }, zoomLevel: "17" },
  { name: "Australia", region: "Pippingarra", latlng: { lat: -20.344184, lng: 118.734027 }, zoomLevel: "16" },
  { name: "Australia", region: "Strelley", latlng: { lat: -20.286673, lng: 118.948732 }, zoomLevel: "14" },
  { name: "Australia", region: "Dampier Peninsula", latlng: { lat: -17.139027, lng: 122.273212 }, zoomLevel: "15" },
  { name: "Antarctica", region: "", latlng: { lat: -69.746959, lng: 10.463253 }, zoomLevel: "10" },
  { name: "Azerbaijan", region: "Baku", latlng: { lat: 40.165334, lng: 49.291894 }, zoomLevel: "16" },
  { name: "France", region: "Arles", latlng: { lat: 43.390624, lng: 4.727092 }, zoomLevel: "16" },
  { name: "France", region: "Le Grau-du-Roi", latlng: { lat: 43.51482, lng: 4.131888 }, zoomLevel: "18" },
  { name: "France", region: "Le Grau-du-Roi", latlng: { lat: 43.534657, lng: 4.157954 }, zoomLevel: "17" },
  { name: "France", region: "Aigues-Mortes", latlng: { lat: 43.525883, lng: 4.208058 }, zoomLevel: "16" },
  { name: "Egypt", region: "Faiyum", latlng: { lat: 29.175169, lng: 30.305446 }, zoomLevel: "16" },
  { name: "Spain", region: "Yaiza", latlng: { lat: 28.937346, lng: -13.825236 }, zoomLevel: "17" },
  { name: "Spain", region: "Solana Matorral", latlng: { lat: 28.044992, lng: -14.334643 }, zoomLevel: "18" },
  { name: "Spain", region: "Morro Jable", latlng: { lat: 28.049684, lng: -14.361331 }, zoomLevel: "18" },
  { name: "Spain", region: "La Orotava", latlng: { lat: 28.277881, lng: -16.65049 }, zoomLevel: "16" },
  { name: "Canada", region: "Niagara Falls", latlng: { lat: 43.073538, lng: -79.064559 }, zoomLevel: "17" },
  { name: "United States", region: "Tucson", latlng: { lat: 32.167284, lng: -110.860695 }, zoomLevel: "17" },
  { name: "United States", region: "Tucson", latlng: { lat: 32.175939, lng: -110.849119 }, zoomLevel: "17" },
  { name: "United States", region: "Tucson", latlng: { lat: 32.16836, lng: -110.855331 }, zoomLevel: "19" },
  { name: "Canada", region: "Niagara Falls", latlng: { lat: 43.124227, lng: -79.063233 }, zoomLevel: "19" },
  { name: "United States", region: "St. Clair County", latlng: { lat: 42.642927, lng: -82.640029 }, zoomLevel: "16" },
  { name: "United States", region: "St. Clair County", latlng: { lat: 42.632505, lng: -82.626543 }, zoomLevel: "17" },
  { name: "United States", region: "Detroit", latlng: { lat: 42.283491, lng: -83.108901 }, zoomLevel: "17" },
  { name: "United States", region: "Detroit", latlng: { lat: 42.287511, lng: -83.124972 }, zoomLevel: "19" },
  { name: "United States", region: "Hickory Corners", latlng: { lat: 42.391976, lng: -85.396763 }, zoomLevel: "17" },
  { name: "United States", region: "Glen Arbor", latlng: { lat: 44.86168, lng: -85.999316 }, zoomLevel: "17" },
  { name: "United States", region: "Traverse City", latlng: { lat: 44.756639, lng: -85.55422 }, zoomLevel: "16" },
  { name: "United States", region: "Traverse City", latlng: { lat: 44.998463, lng: -85.490404 }, zoomLevel: "17" },
  { name: "United States", region: "Traverse City", latlng: { lat: 44.996187, lng: -85.474783 }, zoomLevel: "17" },
  { name: "United States", region: "Alden", latlng: { lat: 44.879928, lng: -85.280827 }, zoomLevel: "17" },
  { name: "United States", region: "Charlevoix", latlng: { lat: 45.190529, lng: -85.388137 }, zoomLevel: "17" },
  { name: "United States", region: "Harbor Springs", latlng: { lat: 45.676832, lng: -85.170095 }, zoomLevel: "17" },
  { name: "United States", region: "Beaver Island", latlng: { lat: 45.785707, lng: -85.423617 }, zoomLevel: "15" },
  { name: "United States", region: "Carp Lake", latlng: { lat: 45.764096, lng: -85.045447 }, zoomLevel: "15" },
  { name: "United States", region: "Germfask", latlng: { lat: 46.068229, lng: -85.879582 }, zoomLevel: "17" },
  { name: "United States", region: "Germfask", latlng: { lat: 46.06718, lng: -85.9045 }, zoomLevel: "18" },
  { name: "United States", region: "Negaunee", latlng: { lat: 46.427764, lng: -87.539944 }, zoomLevel: "18" },
  { name: "United States", region: "Negaunee", latlng: { lat: 46.415561, lng: -87.540277 }, zoomLevel: "17" },
  { name: "United States", region: "Negaunee", latlng: { lat: 46.404953, lng: -87.528819 }, zoomLevel: "17" },
  { name: "Canada", region: "Division No. 18", latlng: { lat: 59.116711, lng: -109.390775 }, zoomLevel: "13" },
  { name: "Canada", region: "Division No. 18", latlng: { lat: 59.456186, lng: -108.058339 }, zoomLevel: "12" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 61.737783, lng: -80.044661 }, zoomLevel: "15" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 62.017821, lng: -80.251685 }, zoomLevel: "15" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 61.931599, lng: -80.249539 }, zoomLevel: "15" },
  { name: "Canada", region: "Baffin Region", latlng: { lat: 61.806269, lng: -79.541737 }, zoomLevel: "15" },
  { name: "Canada", region: "Nunavut", latlng: { lat: 61.733068, lng: -79.678207 }, zoomLevel: "14" },
  { name: "Canada", region: "Baffin Region", latlng: { lat: 61.690521, lng: -79.641643 }, zoomLevel: "15" },
  { name: "Cuba", region: "", latlng: { lat: 22.115273, lng: -77.802832 }, zoomLevel: "14" },
  { name: "Cuba", region: "Camaguey", latlng: { lat: 22.137059, lng: -77.967798 }, zoomLevel: "14" },
  { name: "Cuba", region: "", latlng: { lat: 22.62753, lng: -78.691844 }, zoomLevel: "16" },
  { name: "United States", region: "Grand Canyon Village", latlng: { lat: 36.046117, lng: -111.971604 }, zoomLevel: "14" },
  { name: "United States", region: "North Rim", latlng: { lat: 36.152363, lng: -111.959417 }, zoomLevel: "14" },
  { name: "United States", region: "North Rim", latlng: { lat: 36.233822, lng: -112.171247 }, zoomLevel: "14" },
  { name: "United States", region: "Supai", latlng: { lat: 36.174537, lng: -112.38033 }, zoomLevel: "14" },
  { name: "United States", region: "Supai", latlng: { lat: 36.14266, lng: -112.705114 }, zoomLevel: "14" },
  { name: "United States", region: "Supai", latlng: { lat: 36.221498, lng: -112.704255 }, zoomLevel: "14" },
  { name: "United States", region: "Mohave County", latlng: { lat: 36.298873, lng: -112.776696 }, zoomLevel: "14" },
  { name: "France", region: "Peille", latlng: { lat: 43.767099, lng: 7.415746 }, zoomLevel: "18" },
  { name: "United States", region: "Kailua", latlng: { lat: 21.408502, lng: -157.739232 }, zoomLevel: "17" },
  { name: "United States", region: "Kahului", latlng: { lat: 20.90111, lng: -156.432261 }, zoomLevel: "19" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.792655, lng: -156.031137 }, zoomLevel: "17" },
  { name: "Paraguay", region: "Alto Paraná", latlng: { lat: -25.72693, lng: -54.835742 }, zoomLevel: "15" },
  { name: "Netherlands", region: "Lelystad", latlng: { lat: 52.569084, lng: 5.460898 }, zoomLevel: "15" },
  { name: "Mali", region: "Mopti", latlng: { lat: 15.003676, lng: -3.929028 }, zoomLevel: "12" },
  { name: "United States", region: "Imperial", latlng: { lat: 32.979082, lng: -115.710554 }, zoomLevel: "13" },
  { name: "China", region: "Haibei Zangzuzizhizhou", latlng: { lat: 36.904483, lng: 100.480919 }, zoomLevel: "14" },
  { name: "China", region: "Haibei Zangzuzizhizhou", latlng: { lat: 36.841726, lng: 100.566234 }, zoomLevel: "15" },
  { name: "China", region: "Haibei Zangzuzizhizhou", latlng: { lat: 36.839528, lng: 100.604171 }, zoomLevel: "15" },
  { name: "China", region: "Hainan Zangzuzizhizhou", latlng: { lat: 36.696584, lng: 100.734548 }, zoomLevel: "14" },
  { name: "China", region: "Hainan Zangzuzizhizhou", latlng: { lat: 36.634897, lng: 100.730428 }, zoomLevel: "14" },
  { name: "China", region: "Lanzhou Shi", latlng: { lat: 36.685357, lng: 103.881573 }, zoomLevel: "16" },
  { name: "China", region: "Lanzhou Shi", latlng: { lat: 36.727968, lng: 103.89036 }, zoomLevel: "17" },
  { name: "United States", region: "Othello", latlng: { lat: 46.795506, lng: -118.997845 }, zoomLevel: "15" },
  { name: "United States", region: "Saint John", latlng: { lat: 47.127966, lng: -117.674872 }, zoomLevel: "17" },
  { name: "United States", region: "Saint John", latlng: { lat: 47.112664, lng: -117.592303 }, zoomLevel: "17" },
  { name: "United States", region: "Saint John", latlng: { lat: 47.158746, lng: -117.559988 }, zoomLevel: "17" },
  { name: "United States", region: "Rosalia", latlng: { lat: 47.203535, lng: -117.491559 }, zoomLevel: "16" },
  { name: "United States", region: "Kane County", latlng: { lat: 37.277128, lng: -110.86234 }, zoomLevel: "17" },
  { name: "United States", region: "Kane County", latlng: { lat: 37.245141, lng: -110.879076 }, zoomLevel: "15" },
  { name: "United States", region: "Oljato-Monument Valley", latlng: { lat: 37.133534, lng: -110.476337 }, zoomLevel: "16" },
  { name: "Spain", region: "Arguamul", latlng: { lat: 28.2003, lng: -17.295444 }, zoomLevel: "18" },
  { name: "Spain", region: "Vallehermoso", latlng: { lat: 28.210176, lng: -17.28811 }, zoomLevel: "18" },
  { name: "Spain", region: "Playa Blanca", latlng: { lat: 28.867707, lng: -13.836705 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Aktogay District", latlng: { lat: 46.560631, lng: 77.16711 }, zoomLevel: "14" },
  { name: "Germany", region: "Thörnich", latlng: { lat: 49.836302, lng: 6.849632 }, zoomLevel: "15" },
  { name: "Germany", region: "Emmingen-Liptingen", latlng: { lat: 47.934338, lng: 8.920126 }, zoomLevel: "15" },
  { name: "Morocco", region: "Guelmim", latlng: { lat: 28.832835, lng: -10.837273 }, zoomLevel: "15" },
  { name: "Portugal", region: "Conceição dos Mártires", latlng: { lat: 38.733607, lng: -8.992439 }, zoomLevel: "18" },
  { name: "Spain", region: "Pájara", latlng: { lat: 28.063242, lng: -14.419358 }, zoomLevel: "18" },
  { name: "Spain", region: "Pájara", latlng: { lat: 28.10278, lng: -14.469258 }, zoomLevel: "18" },
  { name: "Spain", region: "Pájara", latlng: { lat: 28.126177, lng: -14.357335 }, zoomLevel: "19" },
  { name: "Spain", region: "Pájara", latlng: { lat: 28.132393, lng: -14.337867 }, zoomLevel: "19" },
  { name: "South Africa", region: "Xhariep", latlng: { lat: -29.32023, lng: 24.96377 }, zoomLevel: "15" },
  { name: "South Africa", region: "Xhariep", latlng: { lat: -29.323373, lng: 24.825325 }, zoomLevel: "14" },
  { name: "South Africa", region: "Xhariep", latlng: { lat: -29.152911, lng: 24.681602 }, zoomLevel: "16" },
  { name: "South Africa", region: "Barkly West", latlng: { lat: -28.577211, lng: 24.585385 }, zoomLevel: "12" },
  { name: "United States", region: "Rosamond", latlng: { lat: 34.826276, lng: -118.403284 }, zoomLevel: "14" },
  { name: "United States", region: "Rosamond", latlng: { lat: 34.891493, lng: -118.446564 }, zoomLevel: "17" },
  { name: "United States", region: "Kern County", latlng: { lat: 34.951911, lng: -118.471369 }, zoomLevel: "16" },
  { name: "Australia", region: "Junortoun", latlng: { lat: -36.749708, lng: 144.368125 }, zoomLevel: "17" },
  { name: "Croatia", region: "Vrataruša", latlng: { lat: 44.996437, lng: 14.831746 }, zoomLevel: "17" },
  { name: "Germany", region: "Rhauderfehn", latlng: { lat: 53.053926, lng: 7.609542 }, zoomLevel: "14" },
  { name: "Germany", region: "Bockhorst", latlng: { lat: 53.042233, lng: 7.603319 }, zoomLevel: "16" },
  { name: "France", region: "Laneuveville-devant-Nancy", latlng: { lat: 48.641159, lng: 6.280947 }, zoomLevel: "18" },
  { name: "France", region: "Fouras", latlng: { lat: 46.002797, lng: -1.132911 }, zoomLevel: "16" },
  { name: "Niger", region: "Bilma", latlng: { lat: 18.657546, lng: 12.882678 }, zoomLevel: "16" },
  { name: "Iran", region: "East Azerbaijan", latlng: { lat: 37.307729, lng: 46.222444 }, zoomLevel: "16" },
  { name: "United States", region: "Fremont", latlng: { lat: 37.506466, lng: -122.090539 }, zoomLevel: "15" },
  { name: "United States", region: "Newark", latlng: { lat: 37.495571, lng: -122.048053 }, zoomLevel: "15" },
  { name: "United States", region: "Oakland", latlng: { lat: 37.706682, lng: -122.202998 }, zoomLevel: "17" },
  { name: "Netherlands", region: "Grootschermer", latlng: { lat: 52.58625, lng: 4.865076 }, zoomLevel: "17" },
  { name: "Antigua and Barbuda", region: "Saint George", latlng: { lat: 17.155963, lng: -61.751811 }, zoomLevel: "17" },
  { name: "Israel", region: "", latlng: { lat: 31.154604, lng: 35.436641 }, zoomLevel: "11" },
  { name: "Saudi Arabia", region: "Ras ِAlsheikh Hamid", latlng: { lat: 28.078924, lng: 34.659723 }, zoomLevel: "14" },
  { name: "Australia", region: "Mission River", latlng: { lat: -12.654865, lng: 141.971735 }, zoomLevel: "13" },
  { name: "Germany", region: "Bodman-Ludwigshafen", latlng: { lat: 47.802351, lng: 9.003547 }, zoomLevel: "18" },
  { name: "Israel", region: "Hadera", latlng: { lat: 32.471409, lng: 34.878795 }, zoomLevel: "16" },
  { name: "Chad", region: "Ennedi", latlng: { lat: 19.859702, lng: 22.88013 }, zoomLevel: "9" },
  { name: "Ireland", region: "Mayo", latlng: { lat: 53.685312, lng: -9.317125 }, zoomLevel: "16" },
  { name: "United States", region: "Santa Fe", latlng: { lat: 35.590981, lng: -106.070981 }, zoomLevel: "16" },
  { name: "Brazil", region: "Brasília", latlng: { lat: -15.870581, lng: -47.924622 }, zoomLevel: "17" },
  { name: "United States", region: "Los Angeles", latlng: { lat: 34.172304, lng: -118.491212 }, zoomLevel: "18" },
  { name: "United States", region: "Los Angeles", latlng: { lat: 34.292546, lng: -118.487242 }, zoomLevel: "19" },
  { name: "Kazakhstan", region: "Kazaly District", latlng: { lat: 45.717694, lng: 62.643835 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Kazaly District", latlng: { lat: 45.394871, lng: 62.566244 }, zoomLevel: "13" },
  { name: "Mongolia", region: "Renchinlkhumbe", latlng: { lat: 50.843542, lng: 99.940616 }, zoomLevel: "14" },
  { name: "Spain", region: "Gáldar", latlng: { lat: 28.161006, lng: -15.691191 }, zoomLevel: "18" },
  { name: "Spain", region: "Mogán", latlng: { lat: 27.905849, lng: -15.666611 }, zoomLevel: "18" },
  { name: "Argentina", region: "Los Andes", latlng: { lat: -25.341001, lng: -66.944675 }, zoomLevel: "15" },
  { name: "Argentina", region: "Los Andes", latlng: { lat: -25.332332, lng: -66.973075 }, zoomLevel: 15.75 },
  { name: "Argentina", region: "Los Andes", latlng: { lat: -25.319977, lng: -67.027137 }, zoomLevel: 17 },
  { name: "Argentina", region: "Antofagasta de la Sierra Department", latlng: { lat: -25.548646, lng: -67.293502 }, zoomLevel: 13.5 },
  { name: "Argentina", region: "Antofagasta de la Sierra Department", latlng: { lat: -25.672638, lng: -67.762706 }, zoomLevel: "17" },
  { name: "Peru", region: "Puno", latlng: { lat: -15.66844, lng: -69.696609 }, zoomLevel: "17" },
  { name: "France", region: "Saintes-Maries-de-la-Mer", latlng: { lat: 43.518747, lng: 4.532941 }, zoomLevel: "17" },
  { name: "Portugal", region: "São Gonçalo", latlng: { lat: 32.647249, lng: -16.865996 }, zoomLevel: "19" },
  { name: "France", region: "Arles", latlng: { lat: 43.393499, lng: 4.712885 }, zoomLevel: "14" },
  { name: "Colombia", region: "Puerto López", latlng: { lat: 4.005177, lng: -72.180068 }, zoomLevel: 17 },
  { name: "China", region: "Subi Reef", latlng: { lat: 10.916357, lng: 114.072998 }, zoomLevel: "16" },
  { name: "China", region: "Subi Reef", latlng: { lat: 10.936794, lng: 114.088576 }, zoomLevel: "16" },
  { name: "United States", region: "Cape Coral", latlng: { lat: 26.615638, lng: -81.991696 }, zoomLevel: "13" },
  { name: "Greece", region: "", latlng: { lat: 34.985982, lng: 25.488083 }, zoomLevel: 16.5 },
  { name: "Christmas Island", region: "", latlng: { lat: -10.477389, lng: 105.620585 }, zoomLevel: "15" },
  { name: "Poland", region: "Polańczyk", latlng: { lat: 49.377978, lng: 22.449616 }, zoomLevel: "14" },
  { name: "France", region: "Roscanvel", latlng: { lat: 48.304593, lng: -4.534671 }, zoomLevel: "17" },
  { name: "Poland", region: "Polańczyk", latlng: { lat: 49.365054, lng: 22.428158 }, zoomLevel: "16" },
  { name: "Netherlands", region: "Lelystad", latlng: { lat: 52.459043, lng: 5.504772 }, zoomLevel: "16" },
  { name: "Netherlands", region: "Dronten", latlng: { lat: 52.603132, lng: 5.732556 }, zoomLevel: "15" },
  { name: "United Kingdom", region: "West Berkshire", latlng: { lat: 51.468453, lng: -1.404748 }, zoomLevel: "17" },
  { name: "France", region: "Nice", latlng: { lat: 43.6963, lng: 7.265482 }, zoomLevel: "17" },
  { name: "United States", region: "Traphill", latlng: { lat: 36.391453, lng: -81.043383 }, zoomLevel: "18" },
  { name: "United States", region: "Hays", latlng: { lat: 36.376657, lng: -81.093208 }, zoomLevel: "18" },
  { name: "Estonia", region: "Narva", latlng: { lat: 59.342633, lng: 28.059575 }, zoomLevel: "16" },
  { name: "Estonia", region: "Narva", latlng: { lat: 59.341845, lng: 28.096525 }, zoomLevel: "16" },
  { name: "United Kingdom", region: "Essex", latlng: { lat: 51.823033, lng: 0.410861 }, zoomLevel: "16" },
  { name: "Spain", region: "El Gastor", latlng: { lat: 36.831884, lng: -5.339269 }, zoomLevel: "18" },
  { name: "Spain", region: "Setenil de las Bodegas", latlng: { lat: 36.844632, lng: -5.168402 }, zoomLevel: "19" },
  { name: "Spain", region: "Setenil de las Bodegas", latlng: { lat: 36.847479, lng: -5.172978 }, zoomLevel: "18" },
  { name: "Spain", region: "Setenil de las Bodegas", latlng: { lat: 36.875287, lng: -5.142213 }, zoomLevel: "18" },
  { name: "France", region: "Grimaud", latlng: { lat: 43.269643, lng: 6.579352 }, zoomLevel: "16" },
  { name: "Antarctica", region: "", latlng: { lat: -69.128826, lng: 39.702359 }, zoomLevel: 10.5 },
  { name: "Antarctica", region: "", latlng: { lat: -69.143498, lng: 39.767418 }, zoomLevel: 11 },
  { name: "Spain", region: "Cuevas del Becerro", latlng: { lat: 36.878522, lng: -5.070303 }, zoomLevel: "19" },
  { name: "Morocco", region: "Province de Sidi Kacem", latlng: { lat: 34.646157, lng: -5.164068 }, zoomLevel: "16" },
  { name: "Morocco", region: "Province de Sidi Kacem", latlng: { lat: 34.64922, lng: -5.173177 }, zoomLevel: 16.75 },
  { name: "Egypt", region: "Aswan Governorate", latlng: { lat: 23.941236, lng: 32.866174 }, zoomLevel: "14" },
  { name: "Morocco", region: "Province de Sidi Kacem", latlng: { lat: 34.636739, lng: -5.167984 }, zoomLevel: "17" },
  { name: "Spain", region: "Teba", latlng: { lat: 36.937404, lng: -4.932476 }, zoomLevel: "18" },
  { name: "Spain", region: "Estepa", latlng: { lat: 37.191268, lng: -4.859091 }, zoomLevel: "17" },
  { name: "United States", region: "Rio Arriba County", latlng: { lat: 36.341553, lng: -106.488322 }, zoomLevel: "18" },
  { name: "United States", region: "Gallina", latlng: { lat: 36.308222, lng: -106.451275 }, zoomLevel: "17" },
  { name: "Namibia", region: "Gobabeb", latlng: { lat: -23.537088, lng: 15.023289 }, zoomLevel: "17" },
  { name: "Namibia", region: "Gobabeb", latlng: { lat: -23.574971, lng: 15.050669 }, zoomLevel: "15" },
  { name: "United States", region: "Palmdale", latlng: { lat: 34.627638, lng: -118.045467 }, zoomLevel: "18" },
  { name: "Namibia", region: "Hardap", latlng: { lat: -23.707851, lng: 15.455275 }, zoomLevel: "14" },
  { name: "United States", region: "Rupert", latlng: { lat: 42.634784, lng: -113.697922 }, zoomLevel: "18" },
  { name: "Vietnam", region: "Thoại Sơn", latlng: { lat: 10.297804, lng: 105.262062 }, zoomLevel: "15" },
  { name: "Kazakhstan", region: "Kaztal District", latlng: { lat: 49.322825, lng: 48.478058 }, zoomLevel: "14" },
  { name: "Russia", region: "", latlng: { lat: 48.171234, lng: 46.921691 }, zoomLevel: "15" },
  { name: "Vietnam", region: "tp. Hạ Long", latlng: { lat: 20.875488, lng: 107.190615 }, zoomLevel: "15" },
  { name: "Russia", region: "", latlng: { lat: 48.17504, lng: 46.913494 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Karmakshy District", latlng: { lat: 46.064712, lng: 63.271246 }, zoomLevel: "14" },
  { name: "China", region: "Qoqek", latlng: { lat: 45.758224, lng: 85.766997 }, zoomLevel: "15" },
  { name: "China", region: "Qoqek", latlng: { lat: 45.850128, lng: 85.988741 }, zoomLevel: "16" },
  { name: "China", region: "Qoqek", latlng: { lat: 45.866029, lng: 86.139417 }, zoomLevel: "16" },
  { name: "British Indian Ocean Territory", region: "Diego Garcia", latlng: { lat: -7.394812, lng: 72.43615 }, zoomLevel: "15" },
  { name: "Spain", region: "Sanlúcar la Mayor", latlng: { lat: 37.44569, lng: -6.250419 }, zoomLevel: "16" },
  { name: "British Indian Ocean Territory", region: "Diego Garcia", latlng: { lat: -7.232423, lng: 72.429842 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Rybachje", latlng: { lat: 46.372618, lng: 81.636267 }, zoomLevel: "13" },
  { name: "Vietnam", region: "Lai Vung", latlng: { lat: 10.230076, lng: 105.708904 }, zoomLevel: "14" },
  { name: "Spain", region: "Sanlúcar la Mayor", latlng: { lat: 37.419228, lng: -6.274173 }, zoomLevel: "16" },
  { name: "Spain", region: "Barcelona", latlng: { lat: 41.406985, lng: 2.175912 }, zoomLevel: "18" },
  { name: "China", region: "Dandong Shi", latlng: { lat: 39.850537, lng: 123.620391 }, zoomLevel: "16" },
  { name: "China", region: "Dalian Shi", latlng: { lat: 39.436488, lng: 122.495408 }, zoomLevel: "16" },
  { name: "China", region: "Dalian Shi", latlng: { lat: 39.436687, lng: 122.420693 }, zoomLevel: "16" },
  { name: "China", region: "Dalian Shi", latlng: { lat: 39.586571, lng: 122.785559 }, zoomLevel: "16" },
  { name: "United States", region: "Cincinnati", latlng: { lat: 39.095413, lng: -84.516095 }, zoomLevel: 17.75 },
  { name: "Saudi Arabia", region: "Thuwal", latlng: { lat: 22.116376, lng: 38.983998 }, zoomLevel: "15" },
  { name: "Saudi Arabia", region: "Thuwal", latlng: { lat: 22.213907, lng: 38.994298 }, zoomLevel: "15" },
  { name: "China", region: "Weifang Shi", latlng: { lat: 37.082585, lng: 119.113293 }, zoomLevel: "16" },
  { name: "China", region: "Weifang Shi", latlng: { lat: 37.209564, lng: 118.9971 }, zoomLevel: "16" },
  { name: "Germany", region: "Hamburg", latlng: { lat: 53.502148, lng: 9.93361 }, zoomLevel: "19" },
  { name: "Antarctica", region: "", latlng: { lat: -69.392702, lng: 76.335153 }, zoomLevel: 10.75 },
  { name: "China", region: "Tangshan Shi", latlng: { lat: 38.982536, lng: 118.465658 }, zoomLevel: "17" },
  { name: "Netherlands", region: "Zeewolde", latlng: { lat: 52.390501, lng: 5.360251 }, zoomLevel: "16" },
  { name: "China", region: "Binzhou Shi", latlng: { lat: 38.057449, lng: 117.984276 }, zoomLevel: "16" },
  { name: "Brazil", region: "Carauari", latlng: { lat: -5.44694, lng: -67.428347 }, zoomLevel: "12" },
  { name: "United States", region: "Sierra County", latlng: { lat: 33.23912, lng: -106.385551 }, zoomLevel: "17" },
  { name: "Indonesia", region: "Kota Batam", latlng: { lat: 1.124203, lng: 103.880585 }, zoomLevel: "16" },
  { name: "Japan", region: "Minato-ku", latlng: { lat: 35.636509, lng: 139.760143 }, zoomLevel: "17" },
  { name: "Japan", region: "Ōta-ku", latlng: { lat: 35.547519, lng: 139.779283 }, zoomLevel: "16" },
  { name: "Japan", region: "Kawasaki-shi", latlng: { lat: 35.472377, lng: 139.735166 }, zoomLevel: "17" },
  { name: "United Kingdom", region: "Dunfield", latlng: { lat: 51.682844, lng: -1.788985 }, zoomLevel: "15" },
  { name: "Iraq", region: "Basrah", latlng: { lat: 30.595171, lng: 47.498553 }, zoomLevel: "15" },
  { name: "Republic of the Union of Myanmar", region: "Pyin Hpyu Gyi", latlng: { lat: 13.719322, lng: 98.302268 }, zoomLevel: "15" },
  { name: "United Arab Emirates", region: "Dubai", latlng: { lat: 25.127063, lng: 55.141217 }, zoomLevel: "17" },
  { name: "Germany", region: "Frankfurt am Main", latlng: { lat: 50.047138, lng: 8.573972 }, zoomLevel: "18" },
  { name: "Niger", region: "Dirkou", latlng: { lat: 19.371271, lng: 12.898587 }, zoomLevel: "14" },
  { name: "Spain", region: "Illa de Arousa", latlng: { lat: 42.576312, lng: -8.859047 }, zoomLevel: "17" },
  { name: "The Bahamas", region: "Acklins", latlng: { lat: 22.683336, lng: -73.881346 }, zoomLevel: "15" },
  { name: "Australia", region: "Carbla", latlng: { lat: -26.269644, lng: 114.21303 }, zoomLevel: "14" },
  { name: "Canada", region: "Alert", latlng: { lat: 82.435714, lng: -62.596492 }, zoomLevel: "13" },
  { name: "United States", region: "Flagstaff", latlng: { lat: 35.515915, lng: -111.352658 }, zoomLevel: "14" },
  { name: "Switzerland", region: "Zermatt", latlng: { lat: 45.981218, lng: 7.681332 }, zoomLevel: "14" },
  { name: "Algeria", region: "Haïnoune", latlng: { lat: 27.326921, lng: 2.790356 }, zoomLevel: "16" },
  { name: "United States", region: "Chinle", latlng: { lat: 36.142189, lng: -109.483405 }, zoomLevel: "14" },
  { name: "United States", region: "Leupp", latlng: { lat: 35.156811, lng: -111.128511 }, zoomLevel: "17" },
  { name: "Thailand", region: "Tambon Song Khlong", latlng: { lat: 13.4877, lng: 100.89729 }, zoomLevel: 15.75 },
  { name: "Spain", region: "Sanlúcar la Mayor", latlng: { lat: 37.444374, lng: -6.260724 }, zoomLevel: "18" },
  { name: "Spain", region: "Huévar del Aljarafe", latlng: { lat: 37.418585, lng: -6.282042 }, zoomLevel: "18" },
  { name: "Spain", region: "El Puerto de Santa María", latlng: { lat: 36.589221, lng: -6.171216 }, zoomLevel: "17" },
  { name: "United States", region: "El Centro", latlng: { lat: 32.773853, lng: -115.540638 }, zoomLevel: "13" },
  { name: "United States", region: "Kenai", latlng: { lat: 60.699401, lng: -151.325769 }, zoomLevel: "15" },
  { name: "China", region: "Alashan Meng", latlng: { lat: 41.059356, lng: 100.230476 }, zoomLevel: "17" },
  { name: "Libya", region: "Al Jawf", latlng: { lat: 24.132197, lng: 23.243723 }, zoomLevel: "15" },
  { name: "New Zealand", region: "Dorie", latlng: { lat: -43.851881, lng: 172.146173 }, zoomLevel: "16" },
  { name: "New Zealand", region: "South Bay", latlng: { lat: -42.417966, lng: 173.683057 }, zoomLevel: "15" },
  { name: "New Caledonia", region: "Poum", latlng: { lat: -20.21977, lng: 164.079087 }, zoomLevel: "15" },
  { name: "New Caledonia", region: "Poum", latlng: { lat: -20.21083, lng: 164.057758 }, zoomLevel: "16" },
  { name: "New Caledonia", region: "Poum", latlng: { lat: -20.145212, lng: 164.056921 }, zoomLevel: "17" },
  { name: "Vanuatu", region: "Sanma Province", latlng: { lat: -15.417629, lng: 167.202484 }, zoomLevel: "17" },
  { name: "Vanuatu", region: "", latlng: { lat: -15.437817, lng: 167.211818 }, zoomLevel: "17" },
  { name: "Vanuatu", region: "Luganville", latlng: { lat: -15.612332, lng: 167.191594 }, zoomLevel: "17" },
  { name: "Vanuatu", region: "Luganville", latlng: { lat: -15.683173, lng: 167.235089 }, zoomLevel: "17" },
  { name: "Cook Islands", region: "", latlng: { lat: -18.911533, lng: -159.82983 }, zoomLevel: "16" },
  { name: "Cook Islands", region: "", latlng: { lat: -18.906032, lng: -159.732863 }, zoomLevel: "17" },
  { name: "Madagascar", region: "Taolanaro", latlng: { lat: -25.13715, lng: 46.516356 }, zoomLevel: "16" },
  { name: "Australia", region: "Farina", latlng: { lat: -30.075455, lng: 138.037905 }, zoomLevel: "11" },
  { name: "Australia", region: "Farina", latlng: { lat: -30.050198, lng: 137.90122 }, zoomLevel: "16" },
  { name: "Australia", region: "Farina", latlng: { lat: -29.862485, lng: 137.737154 }, zoomLevel: "17" },
  { name: "Australia", region: "Andamooka", latlng: { lat: -29.859079, lng: 137.637312 }, zoomLevel: "15" },
  { name: "Germany", region: "Rostock", latlng: { lat: 54.181651, lng: 12.098685 }, zoomLevel: "18" },
  { name: "Germany", region: "Sankt Peter-Ording", latlng: { lat: 54.280779, lng: 8.624383 }, zoomLevel: "17" },
  { name: "Germany", region: "Utersum", latlng: { lat: 54.745471, lng: 8.361071 }, zoomLevel: "16" },
  { name: "Anguilla", region: "", latlng: { lat: 18.244789, lng: -63.00361 }, zoomLevel: "15" },
  { name: "Venezuela", region: "El Bichar", latlng: { lat: 10.703508, lng: -63.812006 }, zoomLevel: "17" },
  { name: "China", region: "Kashi(Kaxgar)", latlng: { lat: 35.809431, lng: 76.968584 }, zoomLevel: "12" },
  { name: "United States", region: "Pomeroy", latlng: { lat: 46.504507, lng: -117.466636 }, zoomLevel: "11" },
  { name: "United Kingdom", region: "Thealby", latlng: { lat: 53.658669, lng: -0.643548 }, zoomLevel: 15 },
  { name: "Chile", region: "Capitan Prat", latlng: { lat: -48.113508, lng: -74.553336 }, zoomLevel: "15" },
  { name: "Argentina", region: "Río Chico", latlng: { lat: -48.357848, lng: -71.46431 }, zoomLevel: "14" },
  { name: "Chile", region: "Antartica Chilena", latlng: { lat: -55.534291, lng: -68.30145 }, zoomLevel: "14" },
  { name: "Bolivia", region: "Nor Lípez", latlng: { lat: -20.528732, lng: -68.162533 }, zoomLevel: "14" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -19.863042, lng: -67.193161 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Syrdariya District", latlng: { lat: 44.77109, lng: 64.626093 }, zoomLevel: "15" },
  { name: "Kazakhstan", region: "Syrdariya District", latlng: { lat: 44.758354, lng: 64.698405 }, zoomLevel: "16" },
  { name: "Australia", region: "Cape Barren Island", latlng: { lat: -40.308573, lng: 148.208785 }, zoomLevel: "15" },
  { name: "Australia", region: "Loccota", latlng: { lat: -40.295154, lng: 148.173766 }, zoomLevel: "16" },
  { name: "Australia", region: "Loccota", latlng: { lat: -40.298092, lng: 148.122879 }, zoomLevel: "18" },
  { name: "United Kingdom", region: "West Sussex", latlng: { lat: 51.158205, lng: -0.167592 }, zoomLevel: "18" },
  { name: "Australia", region: "Cape Barren Island", latlng: { lat: -40.307661, lng: 148.125454 }, zoomLevel: "18" },
  { name: "Kazakhstan", region: "Syrdariya District", latlng: { lat: 44.659322, lng: 64.955725 }, zoomLevel: "14" },
  { name: "Australia", region: "Rheban", latlng: { lat: -42.675859, lng: 148.067679 }, zoomLevel: "16" },
  { name: "United States", region: "Pensacola Beach", latlng: { lat: 30.363095, lng: -86.977349 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Zhanakorgan District", latlng: { lat: 43.41734, lng: 67.51554 }, zoomLevel: "14" },
  { name: "United States", region: "Tiki Island", latlng: { lat: 29.298267, lng: -94.915239 }, zoomLevel: "16" },
  { name: "United States", region: "Hitchcock", latlng: { lat: 29.324294, lng: -94.946127 }, zoomLevel: "17" },
  { name: "Kazakhstan", region: "Shardara", latlng: { lat: 41.845917, lng: 68.045201 }, zoomLevel: "13" },
  { name: "Uzbekistan", region: "Forish District", latlng: { lat: 40.873814, lng: 67.892766 }, zoomLevel: "14" },
  { name: "Uzbekistan", region: "Forish District", latlng: { lat: 40.821142, lng: 67.592959 }, zoomLevel: "15" },
  { name: "Uzbekistan", region: "Forish District", latlng: { lat: 40.737837, lng: 67.467979 }, zoomLevel: "14" },
  { name: "France", region: "Colombiers", latlng: { lat: 43.319839, lng: 3.122671 }, zoomLevel: "17" },
  { name: "United States", region: "Jasper", latlng: { lat: 30.448413, lng: -82.830584 }, zoomLevel: "14" },
  { name: "China", region: "Hetian", latlng: { lat: 36.446031, lng: 82.529784 }, zoomLevel: "16" },
  { name: "China", region: "Hetian", latlng: { lat: 36.446031, lng: 82.529784 }, zoomLevel: "16" },
  { name: "Spain", region: "Cullera", latlng: { lat: 39.151155, lng: -0.229029 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Taolanaro", latlng: { lat: -25.172703, lng: 46.546079 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Taolanaro", latlng: { lat: -25.142113, lng: 46.523892 }, zoomLevel: "17" },
  { name: "Madagascar", region: "Ampanihy", latlng: { lat: -24.666284, lng: 43.934755 }, zoomLevel: "16" },
  { name: "Madagascar", region: "", latlng: { lat: -23.889134, lng: 43.654389 }, zoomLevel: "16" },
  { name: "Madagascar", region: "", latlng: { lat: -23.680962, lng: 43.639884 }, zoomLevel: "15" },
  { name: "Madagascar", region: "Toliara II", latlng: { lat: -23.055528, lng: 43.554397 }, zoomLevel: "15" },
  { name: "Madagascar", region: "", latlng: { lat: -22.683717, lng: 43.304758 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Manja", latlng: { lat: -21.26502, lng: 43.749104 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Belon'i Tsiribihina", latlng: { lat: -19.977685, lng: 44.49877 }, zoomLevel: "17" },
  { name: "Madagascar", region: "Soalala", latlng: { lat: -16.125727, lng: 45.007296 }, zoomLevel: "17" },
  { name: "Madagascar", region: "Soalala", latlng: { lat: -15.950972, lng: 45.550776 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Marovoay", latlng: { lat: -15.951488, lng: 46.384578 }, zoomLevel: "14" },
  { name: "Madagascar", region: "Mahajanga Rural", latlng: { lat: -15.575192, lng: 46.96681 }, zoomLevel: "16" },
  { name: "Madagascar", region: "Mahajanga Rural", latlng: { lat: -15.619958, lng: 47.034745 }, zoomLevel: "15" },
  { name: "Madagascar", region: "Mahajanga Rural", latlng: { lat: -15.472769, lng: 47.068305 }, zoomLevel: "14" },
  { name: "Madagascar", region: "Vohimarina", latlng: { lat: -12.832598, lng: 49.854798 }, zoomLevel: "16" },
  { name: "Panama", region: "La Chorrera", latlng: { lat: 9.079256, lng: -79.997807 }, zoomLevel: "16" },
  { name: "Bolivia", region: "Sur Carangas", latlng: { lat: -18.815968, lng: -67.289028 }, zoomLevel: "15" },
  { name: "Malaysia", region: "", latlng: { lat: 4.600434, lng: 118.63771 }, zoomLevel: "15" },
  { name: "Indonesia", region: "Kabupaten Aceh Utara", latlng: { lat: 5.217454, lng: 97.410276 }, zoomLevel: "15" },
  { name: "Indonesia", region: "Kabupaten Aceh Utara", latlng: { lat: 5.188905, lng: 97.330925 }, zoomLevel: "16" },
  { name: "Kazakhstan", region: "Tupkaragan District", latlng: { lat: 44.536929, lng: 51.551628 }, zoomLevel: "15" },
  { name: "Kazakhstan", region: "Mangystau Region", latlng: { lat: 46.157481, lng: 53.068342 }, zoomLevel: "15" },
  { name: "Turkmenistan", region: "Dashoguz Province", latlng: { lat: 41.728537, lng: 57.422276 }, zoomLevel: "14" },
  { name: "Uzbekistan", region: "", latlng: { lat: 44.576285, lng: 58.517475 }, zoomLevel: "13" },
  { name: "Kazakhstan", region: "", latlng: { lat: 46.430285, lng: 60.364552 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Aral District", latlng: { lat: 46.157599, lng: 61.697245 }, zoomLevel: "15" },
  { name: "Kazakhstan", region: "Aral District", latlng: { lat: 46.160691, lng: 60.900049 }, zoomLevel: "14" },
  { name: "China", region: "Boertala Mengguzizhizhou", latlng: { lat: 44.554087, lng: 81.240721 }, zoomLevel: "14" },
  { name: "Kazakhstan", region: "Urzhar District", latlng: { lat: 46.32589, lng: 81.918011 }, zoomLevel: "14" },
  { name: "Mongolia", region: "Durgun", latlng: { lat: 48.191439, lng: 93.277703 }, zoomLevel: "13" },
  { name: "Crimea", region: "", latlng: { lat: 46.07396, lng: 33.900633 }, zoomLevel: "16" },
  { name: "Crimea", region: "", latlng: { lat: 46.117617, lng: 33.896899 }, zoomLevel: "15" },
  { name: "United States", region: "Alamosa County", latlng: { lat: 37.75144, lng: -105.573292 }, zoomLevel: "15" },
  { name: "Spain", region: "Alaior", latlng: { lat: 39.896973, lng: 4.070116 }, zoomLevel: "17" },
  { name: "Bahamas", region: "East Coast", latlng: { lat: 25.07918, lng: -76.591772 }, zoomLevel: "14" },
  { name: "Bahamas", region: "East Coast", latlng: { lat: 23.385232, lng: -76.286386 }, zoomLevel: "12" },
  { name: "Cuba", region: "", latlng: { lat: 21.600096, lng: -81.712087 }, zoomLevel: "15" },
  { name: "China", region: "Qingdao Shi", latlng: { lat: 36.170386, lng: 120.302867 }, zoomLevel: "17" },
  { name: "Turkey", region: "İznik", latlng: { lat: 40.468009, lng: 29.511938 }, zoomLevel: "14" },
  { name: "Turkey", region: "Orhangazi", latlng: { lat: 40.395751, lng: 29.528932 }, zoomLevel: "14" },
  { name: "Turkey", region: "Gelendost", latlng: { lat: 38.048936, lng: 30.950288 }, zoomLevel: "14" },
  { name: "Syria", region: "As-Safirah", latlng: { lat: 36.052265, lng: 37.529211 }, zoomLevel: "15" },
  { name: "Saudi Arabia", region: "", latlng: { lat: 28.064217, lng: 35.005532 }, zoomLevel: "16" },
  { name: "United States", region: "", latlng: { lat: 26.054774, lng: -173.943808 }, zoomLevel: "14" },
  { name: "United States", region: "Waimanalo", latlng: { lat: 21.324194, lng: -157.665589 }, zoomLevel: "16" },
  { name: "United States", region: "Honolulu", latlng: { lat: 21.268375, lng: -157.729276 }, zoomLevel: "16" },
  { name: "United States", region: "Ewa Beach", latlng: { lat: 21.302565, lng: -158.028224 }, zoomLevel: "16" },
  { name: "United States", region: "Waialua", latlng: { lat: 21.571125, lng: -158.272455 }, zoomLevel: "16" },
  { name: "United States", region: "Waialua", latlng: { lat: 21.589228, lng: -158.144095 }, zoomLevel: "17" },
  { name: "United States", region: "Kaunakakai", latlng: { lat: 21.035746, lng: -156.888779 }, zoomLevel: "15" },
  { name: "United States", region: "Kaunakakai", latlng: { lat: 21.056113, lng: -156.811563 }, zoomLevel: "17" },
  { name: "United States", region: "Kaunakakai", latlng: { lat: 21.087729, lng: -156.759872 }, zoomLevel: "16" },
  { name: "United States", region: "Lahaina", latlng: { lat: 21.163047, lng: -156.854285 }, zoomLevel: "16" },
  { name: "United States", region: "Maunaloa", latlng: { lat: 21.199382, lng: -157.143878 }, zoomLevel: "16" },
  { name: "United States", region: "Maunaloa", latlng: { lat: 21.159506, lng: -157.275703 }, zoomLevel: "16" },
  { name: "United States", region: "Maunaloa", latlng: { lat: 21.087449, lng: -157.171762 }, zoomLevel: "16" },
  { name: "United States", region: "Maunaloa", latlng: { lat: 21.100722, lng: -157.102132 }, zoomLevel: "17" },
  { name: "United States", region: "Lanai City", latlng: { lat: 20.918278, lng: -156.901728 }, zoomLevel: "17" },
  { name: "United States", region: "Lanai City", latlng: { lat: 20.824169, lng: -156.990075 }, zoomLevel: "17" },
  { name: "United States", region: "Maui County", latlng: { lat: 20.55952, lng: -156.579804 }, zoomLevel: "17" },
  { name: "United States", region: "Maui County", latlng: { lat: 20.513817, lng: -156.54191 }, zoomLevel: "18" },
  { name: "United States", region: "Maui County", latlng: { lat: 20.534457, lng: -156.539686 }, zoomLevel: "18" },
  { name: "United States", region: "Kihei", latlng: { lat: 20.604636, lng: -156.570419 }, zoomLevel: "18" },
  { name: "United States", region: "Kihei", latlng: { lat: 20.632124, lng: -156.495162 }, zoomLevel: "18" },
  { name: "United States", region: "Paia", latlng: { lat: 20.908193, lng: -156.432055 }, zoomLevel: "16" },
  { name: "United States", region: "Lahaina", latlng: { lat: 20.970319, lng: -156.680427 }, zoomLevel: "17" },
  { name: "United States", region: "Lahaina", latlng: { lat: 20.891259, lng: -156.687511 }, zoomLevel: "18" },
  { name: "United States", region: "Lahaina", latlng: { lat: 20.803528, lng: -156.600371 }, zoomLevel: "17" },
  { name: "United States", region: "Kihei", latlng: { lat: 20.713493, lng: -156.449127 }, zoomLevel: "17" },
  { name: "United States", region: "Kihei", latlng: { lat: 20.630679, lng: -156.447603 }, zoomLevel: "18" },
  { name: "United States", region: "Kihei", latlng: { lat: 20.579508, lng: -156.393326 }, zoomLevel: "17" },
  { name: "United States", region: "Kula", latlng: { lat: 20.589733, lng: -156.318654 }, zoomLevel: "16" },
  { name: "United States", region: "Hōlualoa", latlng: { lat: 19.694627, lng: -155.855742 }, zoomLevel: "16" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.825646, lng: -155.992341 }, zoomLevel: "16" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.784098, lng: -156.035557 }, zoomLevel: "16" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.725114, lng: -156.058072 }, zoomLevel: "17" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.725276, lng: -156.054376 }, zoomLevel: "19" },
  { name: "United States", region: "Kailua-Kona", latlng: { lat: 19.716883, lng: -156.04348 }, zoomLevel: "19" },
  { name: "Kiribati", region: "Johnston Island", latlng: { lat: 16.739141, lng: -169.510723 }, zoomLevel: "15" },
  { name: "Marshall Islands", region: "", latlng: { lat: 14.630525, lng: 169.005383 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Enewetak Atoll", latlng: { lat: 11.416653, lng: 162.362818 }, zoomLevel: "15" },
  { name: "Marshall Islands", region: "Enewetak Atoll", latlng: { lat: 11.625308, lng: 162.29941 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Enewetak Atoll", latlng: { lat: 11.521042, lng: 162.360372 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Enewetak Atoll", latlng: { lat: 11.667635, lng: 162.1915 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Enewetak Atoll", latlng: { lat: 11.341192, lng: 162.320675 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.629233, lng: 165.209822 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.567646, lng: 165.23467 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.507034, lng: 165.395087 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.636127, lng: 165.524069 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.151592, lng: 166.523053 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.180243, lng: 166.498119 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.388778, lng: 167.371253 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.288296, lng: 167.43009 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Kwajalein Atoll", latlng: { lat: 9.315951, lng: 167.205664 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Kwajalein Atoll", latlng: { lat: 9.397633, lng: 167.474357 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Kwajalein Atoll", latlng: { lat: 9.118548, lng: 167.334067 }, zoomLevel: "15" },
  { name: "Marshall Islands", region: "", latlng: { lat: 11.150203, lng: 169.657117 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Arno Atoll", latlng: { lat: 7.086381, lng: 171.846015 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Mili Atoll", latlng: { lat: 5.903272, lng: 172.156121 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Mili Atoll", latlng: { lat: 5.938638, lng: 172.136562 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Mili Atoll", latlng: { lat: 5.997903, lng: 172.099912 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Mili Atoll", latlng: { lat: 6.221609, lng: 171.762812 }, zoomLevel: "17" },
  { name: "Marshall Islands", region: "Mili Atoll", latlng: { lat: 6.211199, lng: 172.078927 }, zoomLevel: "16" },
  { name: "Marshall Islands", region: "Jaluit Atoll", latlng: { lat: 6.150059, lng: 169.490874 }, zoomLevel: "15" },
  { name: "Marshall Islands", region: "Jaluit Atoll", latlng: { lat: 6.288457, lng: 169.421223 }, zoomLevel: "17" },
  { name: "Kiribati", region: "", latlng: { lat: -1.81626, lng: 175.527636 }, zoomLevel: "16" },
  { name: "Kiribati", region: "", latlng: { lat: -1.875023, lng: 175.523044 }, zoomLevel: "16" },
  { name: "Tuvalu", region: "", latlng: { lat: -7.976151, lng: 178.367023 }, zoomLevel: "15" },
  { name: "Tuvalu", region: "Vaiaku", latlng: { lat: -8.463756, lng: 179.092292 }, zoomLevel: "15" },
  { name: "South Pacific", region: "", latlng: { lat: -11.61468, lng: 169.843628 }, zoomLevel: "15" },
  { name: "United States", region: "Rose Atoll National Wildlife Refuge", latlng: { lat: -14.545516, lng: -168.15565 }, zoomLevel: "16" },
  { name: "Samoa", region: "Salelologa", latlng: { lat: -13.733945, lng: -172.192954 }, zoomLevel: "16" },
  { name: "Samoa", region: "Tuasivi", latlng: { lat: -13.668027, lng: -172.176903 }, zoomLevel: "16" },
  { name: "Cook Islands", region: "", latlng: { lat: -18.035811, lng: -163.123182 }, zoomLevel: 13.25 },
  { name: "Cook Islands", region: "", latlng: { lat: -18.016702, lng: -163.144597 }, zoomLevel: 12 },
  { name: "Cook Islands", region: "", latlng: { lat: -19.813102, lng: -158.288704 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tubuai Islands", latlng: { lat: -21.813238, lng: -154.721023 }, zoomLevel: "17" },
  { name: "French Polynesia", region: "Îles Tubuaï", latlng: { lat: -23.344857, lng: -149.452241 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Îles Tubuaï", latlng: { lat: -23.394652, lng: -149.446019 }, zoomLevel: "15" },
  { name: "French Polynesia", region: "Îles Tubuaï", latlng: { lat: -23.374287, lng: -149.533652 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "", latlng: { lat: -16.485308, lng: -151.696928 }, zoomLevel: "17" },
  { name: "French Polynesia", region: "The Tuamotu and Gambier Islands", latlng: { lat: -15.827476, lng: -145.103672 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -15.968261, lng: -142.32426 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "The Tuamotu and Gambier Islands", latlng: { lat: -15.932527, lng: -142.323359 }, zoomLevel: "17" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -15.949673, lng: -142.374535 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -16.060887, lng: -142.468348 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -14.185365, lng: -141.22655 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -14.814653, lng: -138.812776 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -18.74139, lng: -141.85526 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "Tuamotus Islands", latlng: { lat: -21.355173, lng: -136.550365 }, zoomLevel: "16" },
  { name: "French Polynesia", region: "", latlng: { lat: -9.984579, lng: -150.218323 }, zoomLevel: "16" },
  { name: "Kiribati", region: "", latlng: { lat: 1.960028, lng: -157.477486 }, zoomLevel: "16" },
  { name: "Libya", region: "", latlng: { lat: 30.27192, lng: 19.11097 }, zoomLevel: "16" },
  { name: "Libya", region: "Ajdabiya", latlng: { lat: 30.593066, lng: 19.854564 }, zoomLevel: "15" },
  { name: "Libya", region: "Ajdabiya", latlng: { lat: 30.898625, lng: 20.074269 }, zoomLevel: "16" },
  { name: "Libya", region: "Al Hizam Al Akhdar", latlng: { lat: 31.307531, lng: 20.099289 }, zoomLevel: "16" },
  { name: "Libya", region: "", latlng: { lat: 31.952189, lng: 19.944515 }, zoomLevel: "16" },
  { name: "Libya", region: "Al Jabal al Akhd¸ar", latlng: { lat: 32.774037, lng: 21.332338 }, zoomLevel: "16" },
  { name: "Libya", region: "Massah", latlng: { lat: 32.872239, lng: 21.559918 }, zoomLevel: "16" },
  { name: "Libya", region: "Al Wahat", latlng: { lat: 28.922688, lng: 22.544289 }, zoomLevel: "17" },
  { name: "Libya", region: "Al Jufrah", latlng: { lat: 27.556207, lng: 18.346245 }, zoomLevel: "17" },
  { name: "Libya", region: "Al Jufrah", latlng: { lat: 27.626305, lng: 18.285155 }, zoomLevel: "17" },
  { name: "Libya", region: "Al Jufrah", latlng: { lat: 28.161928, lng: 17.511585 }, zoomLevel: "15" },
  { name: "Libya", region: "Al Jufrah", latlng: { lat: 29.267977, lng: 16.039481 }, zoomLevel: "17" },
  { name: "Libya", region: "Waddan", latlng: { lat: 29.279769, lng: 16.030576 }, zoomLevel: 16.25 },
  { name: "Libya", region: "Waddan", latlng: { lat: 29.283849, lng: 16.046294 }, zoomLevel: "17" },
  { name: "Libya", region: "Waddan", latlng: { lat: 29.31218, lng: 16.049502 }, zoomLevel: "17" },
  { name: "Libya", region: "Waddan", latlng: { lat: 29.285646, lng: 16.02224 }, zoomLevel: 15.5 },
  { name: "Egypt", region: "Qesm Al Wahat Ad Dakhlah", latlng: { lat: 26.298417, lng: 27.772236 }, zoomLevel: "15" },
  { name: "Egypt", region: "Qesm Al Wahat Ad Dakhlah", latlng: { lat: 26.404094, lng: 27.850428 }, zoomLevel: "15" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.203042, lng: 27.440801 }, zoomLevel: "17" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.205141, lng: 27.41076 }, zoomLevel: "17" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.227131, lng: 27.438912 }, zoomLevel: "17" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.537792, lng: 27.127068 }, zoomLevel: "17" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.562125, lng: 27.083917 }, zoomLevel: "16" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.578973, lng: 26.975169 }, zoomLevel: "16" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.524426, lng: 26.950879 }, zoomLevel: "16" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.467229, lng: 27.183652 }, zoomLevel: "17" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.621201, lng: 26.800332 }, zoomLevel: "16" },
  { name: "Egypt", region: "Qesm Siwah", latlng: { lat: 28.783552, lng: 27.085676 }, zoomLevel: "15" },
  { name: "Egypt", region: "Qesm Al Wahat Ad Dakhlah", latlng: { lat: 23.486175, lng: 30.594778 }, zoomLevel: "15" },
  { name: "Egypt", region: "Qesm Al Wahat Al Khargah", latlng: { lat: 23.079435, lng: 31.2537 }, zoomLevel: "14" },
  { name: "Egypt", region: "Aswan Governorate", latlng: { lat: 23.234583, lng: 32.948341 }, zoomLevel: "15" },
  { name: "Egypt", region: "", latlng: { lat: 23.268493, lng: 32.847404 }, zoomLevel: "14" },
  { name: "Egypt", region: "Aswan Governorate", latlng: { lat: 22.828264, lng: 32.597294 }, zoomLevel: "14" },
  { name: "Egypt", region: "Qesm as Saloum", latlng: { lat: 31.525819, lng: 25.188904 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.722261, lng: -16.505113 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.643568, lng: -16.526098 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.589423, lng: -16.523996 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.572388, lng: -16.465545 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.664329, lng: -16.478377 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 20.954105, lng: -17.02234 }, zoomLevel: "16" },
  { name: "Western Sahara", region: "", latlng: { lat: 20.9129, lng: -17.091391 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Dakhlet Nouâdhibou", latlng: { lat: 20.812857, lng: -17.047639 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 21.141579, lng: -16.936617 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 21.087994, lng: -16.8819 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 21.038574, lng: -16.856258 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 21.007048, lng: -16.819479 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 20.919916, lng: -16.775866 }, zoomLevel: 16.75 },
  { name: "Mauritania", region: "", latlng: { lat: 20.801604, lng: -16.725216 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 20.585721, lng: -16.568145 }, zoomLevel: "17" },
  { name: "Mauritania", region: "", latlng: { lat: 20.559063, lng: -16.529221 }, zoomLevel: "17" },
  { name: "Mauritania", region: "", latlng: { lat: 20.612857, lng: -16.433413 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.890526, lng: -16.127459 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.902471, lng: -15.970775 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.878661, lng: -16.063515 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.793886, lng: -16.421214 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.652535, lng: -16.450096 }, zoomLevel: "15" },
  { name: "Mauritania", region: "", latlng: { lat: 19.632366, lng: -16.493569 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.659648, lng: -16.479493 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.70846, lng: -16.516229 }, zoomLevel: "15" },
  { name: "Mauritania", region: "", latlng: { lat: 19.611649, lng: -16.448208 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.669407, lng: -16.379329 }, zoomLevel: "15" },
  { name: "Mauritania", region: "", latlng: { lat: 19.594144, lng: -16.380809 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.622059, lng: -16.375069 }, zoomLevel: 15 },
  { name: "Mauritania", region: "Dakhlet Nouadhibou", latlng: { lat: 19.529969, lng: -16.412513 }, zoomLevel: "16" },
  { name: "Mauritania", region: "", latlng: { lat: 19.509926, lng: -16.387536 }, zoomLevel: "17" },
  { name: "Mauritania", region: "", latlng: { lat: 19.400438, lng: -16.400368 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.323242, lng: -15.873748 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 20.935298, lng: -13.057085 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 21.086972, lng: -13.387705 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 21.26753, lng: -13.022066 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.489721, lng: -11.56243 }, zoomLevel: "13" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.714922, lng: -11.070792 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.634402, lng: -10.575591 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.708263, lng: -10.604688 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.636756, lng: -10.673782 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.584867, lng: -10.635694 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.534098, lng: -10.783709 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Fderik", latlng: { lat: 21.816375, lng: -12.222211 }, zoomLevel: "15" },
  { name: "Western Sahara", region: "Zug", latlng: { lat: 21.640087, lng: -14.15941 }, zoomLevel: "14" },
  { name: "Western Sahara", region: "", latlng: { lat: 21.811156, lng: -14.790823 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Nouadhibou", latlng: { lat: 21.170166, lng: -14.809116 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 20.930017, lng: -14.387687 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 21.329655, lng: -14.219191 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Atar", latlng: { lat: 20.453694, lng: -13.228833 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Tichit", latlng: { lat: 18.340819, lng: -10.644643 }, zoomLevel: "12" },
  { name: "Mauritania", region: "Aoujeft", latlng: { lat: 19.263204, lng: -14.06918 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Aoujeft", latlng: { lat: 19.215351, lng: -14.163336 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Ouad Naga", latlng: { lat: 18.997003, lng: -14.253373 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Boutilimit", latlng: { lat: 18.630203, lng: -14.159152 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Boutilimit", latlng: { lat: 18.300608, lng: -13.88767 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Ouad Naga", latlng: { lat: 18.74017, lng: -15.584714 }, zoomLevel: "16" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.882228, lng: -14.359352 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.663826, lng: -14.191553 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Akjoujt", latlng: { lat: 19.751013, lng: -14.438059 }, zoomLevel: "15" },
  { name: "Bolivia", region: "Nor Lípez", latlng: { lat: -20.643206, lng: -67.164466 }, zoomLevel: "17" },
  { name: "Peru", region: "Contralmirante Villar Province", latlng: { lat: -4.087391, lng: -80.753803 }, zoomLevel: "14" },
  { name: "Peru", region: "Sullana", latlng: { lat: -4.829286, lng: -80.929756 }, zoomLevel: "14" },
  { name: "Peru", region: "Virú", latlng: { lat: -8.735622, lng: -78.734636 }, zoomLevel: "16" },
  { name: "Thailand", region: "", latlng: { lat: 6.505374, lng: 99.31054 }, zoomLevel: 16.5 },
  { name: "Thailand", region: "", latlng: { lat: 6.496761, lng: 99.312471 }, zoomLevel: 14.75 },
  { name: "Venezuela", region: "Dabajuro", latlng: { lat: 11.190443, lng: -70.800877 }, zoomLevel: "15" },
  { name: "Colombia", region: "Purísima", latlng: { lat: 9.1851, lng: -75.730562 }, zoomLevel: "16" },
  { name: "Canada", region: "Buchans", latlng: { lat: 48.907153, lng: -56.943539 }, zoomLevel: "14" },
  { name: "Bolivia", region: "Daniel Campos", latlng: { lat: -20.583698, lng: -67.558537 }, zoomLevel: "15" },
  { name: "Bolivia", region: "Nor Lípez", latlng: { lat: -21.614944, lng: -67.766547 }, zoomLevel: "15" },
  { name: "Bolivia", region: "Sur Lípez", latlng: { lat: -22.480837, lng: -67.564201 }, zoomLevel: "14" },
  { name: "Chile", region: "Natales", latlng: { lat: -49.477884, lng: -73.141909 }, zoomLevel: "15" },
  { name: "Argentina", region: "Lago Argentino Department", latlng: { lat: -49.525236, lng: -73.032217 }, zoomLevel: "16" },
  { name: "Antarctica", region: "", latlng: { lat: -71.536547, lng: -14.480667 }, zoomLevel: "10" },
  { name: "Mali", region: "Timbuktu", latlng: { lat: 16.743184, lng: -3.05656 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Nema", latlng: { lat: 16.362948, lng: -7.689593 }, zoomLevel: "17" },
  { name: "Mauritania", region: "Nema", latlng: { lat: 16.350327, lng: -7.684464 }, zoomLevel: "17" },
  { name: "Mali", region: "Mopti", latlng: { lat: 15.085052, lng: -4.096699 }, zoomLevel: "12" },
  { name: "Mali", region: "Niafunke", latlng: { lat: 15.930299, lng: -3.641968 }, zoomLevel: "14" },
  { name: "Mauritania", region: "Moudjeria", latlng: { lat: 17.642877, lng: -12.432919 }, zoomLevel: "15" },
  { name: "Mauritania", region: "Magta-Lahjar", latlng: { lat: 18.236281, lng: -12.671442 }, zoomLevel: "14" },
  { name: "Iran", region: "Hormozgan", latlng: { lat: 25.854898, lng: 58.566828 }, zoomLevel: "15" },
  { name: "Iran", region: "Kerman", latlng: { lat: 26.145499, lng: 58.814363 }, zoomLevel: "15" },
  { name: "Iraq", region: "Khanik", latlng: { lat: 36.712811, lng: 42.773724 }, zoomLevel: "15" },
  { name: "Turkey", region: "Yeşerdi", latlng: { lat: 37.654878, lng: 38.629303 }, zoomLevel: "14" },
  { name: "Iran", region: "Birjand", latlng: { lat: 32.908145, lng: 59.494947 }, zoomLevel: 17 },
  { name: "Iran", region: "Birjand", latlng: { lat: 32.921169, lng: 59.493467 }, zoomLevel: "17" },
  { name: "Iran", region: "Birjand", latlng: { lat: 32.913666, lng: 59.502178 }, zoomLevel: 16.75 },
  { name: "Turkmenistan", region: "Mary", latlng: { lat: 37.058191, lng: 62.603531 }, zoomLevel: "12" },
  { name: "Oman", region: "", latlng: { lat: 20.625153, lng: 58.606224 }, zoomLevel: "16" },
  { name: "Oman", region: "Ash Sharqiyah North Governorate", latlng: { lat: 20.667963, lng: 58.692055 }, zoomLevel: "15" },
  { name: "Somalia", region: "El Wak", latlng: { lat: 2.475831, lng: 41.552653 }, zoomLevel: "17" },
  { name: "Somalia", region: "El Wak", latlng: { lat: 2.33783, lng: 41.552632 }, zoomLevel: "17" },
  { name: "Somalia", region: "Baar-Dheere", latlng: { lat: 2.318191, lng: 41.523321 }, zoomLevel: "17" },
  { name: "Somalia", region: "Bulo-Burte", latlng: { lat: 3.971207, lng: 46.287246 }, zoomLevel: "17" },
  { name: "Somalia", region: "Bulo-Burte", latlng: { lat: 4.036348, lng: 46.316385 }, zoomLevel: "15" },
  { name: "Somalia", region: "Bulo-Burte", latlng: { lat: 4.05848, lng: 46.380157 }, zoomLevel: "16" },
  { name: "Somalia", region: "Calawla", latlng: { lat: 11.828374, lng: 50.639849 }, zoomLevel: "15" },
  { name: "Somalia", region: "Badhan", latlng: { lat: 10.982776, lng: 48.422713 }, zoomLevel: "16" },
  { name: "Somalia", region: "Badhan", latlng: { lat: 10.927624, lng: 48.36525 }, zoomLevel: "15" },
  { name: "Somalia", region: "Badhan", latlng: { lat: 10.907397, lng: 48.610125 }, zoomLevel: "14" },
  { name: "Ethiopia", region: "West Gojam", latlng: { lat: 11.772167, lng: 37.064266 }, zoomLevel: "16" },
  { name: "China", region: "Hetian", latlng: { lat: 36.440956, lng: 82.537938 }, zoomLevel: "17" },
  { name: "Saudi Arabia", region: "", latlng: { lat: 25.926854, lng: 49.656199 }, zoomLevel: "11" },
  { name: "Egypt ", region: "", latlng: { lat: 31.054044, lng: 29.698734 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 33.800308, lng: -117.882732 }, zoomLevel: "11" },
  { name: "Greece", region: "", latlng: { lat: 37.970606, lng: 23.724844 }, zoomLevel: "11" },
  { name: "New Zealand", region: "", latlng: { lat: -36.874954, lng: 174.744756 }, zoomLevel: "11" },
  { name: "Spain", region: "", latlng: { lat: 41.3727, lng: 2.187801 }, zoomLevel: "11" },
  { name: "Mauritius", region: "", latlng: { lat: -20.271696, lng: 57.815551 }, zoomLevel: "11" },
  { name: "Madagascar", region: "", latlng: { lat: -16.680156, lng: 44.453102 }, zoomLevel: "11" },
  { name: "Ireland", region: "", latlng: { lat: 53.37062, lng: -6.253765 }, zoomLevel: "11" },
  { name: "The Bahamas", region: "", latlng: { lat: 26.52852, lng: -78.763192 }, zoomLevel: "11" },
  { name: "India ", region: "", latlng: { lat: 23.011095, lng: 70.18346 }, zoomLevel: "11" },
  { name: "Kenya", region: "", latlng: { lat: 2.255778, lng: 37.939909 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 37.413379, lng: -122.049101 }, zoomLevel: "11" },
  { name: "Italy", region: "", latlng: { lat: 41.89021, lng: 12.492231 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 32.733801, lng: -117.193304 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 32.763635, lng: -117.23055 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 37.764531, lng: -122.435248 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 37.820149, lng: -122.368987 }, zoomLevel: "11" },
  { name: "Singapore", region: "", latlng: { lat: 1.252223, lng: 103.833382 }, zoomLevel: "11" },
  { name: "United States", region: "", latlng: { lat: 27.93766, lng: -82.446334 }, zoomLevel: "11" },
  { name: "Belize", region: "", latlng: { lat: 17.315571, lng: -87.534514 }, zoomLevel: "11" },
  { name: "Italy", region: "", latlng: { lat: 45.427999, lng: 12.344211 }, zoomLevel: "11" },
  { name: "Lake Carnegie, Australia", latlng: { lat: -26.1734, lng: 122.5001 }, zoomLevel: 11.75 },
  { name: "Grand Canyon National Park, US", latlng: { lat: 36.3219, lng: -112.5874 }, zoomLevel: 10 },
  { name: "Victoria Falls, Zimbabwe", latlng: { lat: -17.9154, lng: 25.8538 }, zoomLevel: 15.5 },
  { name: "Amazon River Delta", latlng: { lat: -3.366, lng: -60.9879 }, zoomLevel: 9.75 },
  { name: "Gulf of Mexixo", latlng: { lat: 22.4771, lng: -89.7066 }, zoomLevel: 12.75 },
  { name: "The Great Barrier Reef", latlng: { lat: -20.7599, lng: 150.558 }, zoomLevel: 10 },
  { name: "The Himalayas, China", latlng: { lat: 28.6329, lng: 84.0739 }, zoomLevel: 11.5 },
  { name: "Maldives, Republic of the Maldives", latlng: { lat: 3.6694, lng: 73.2028 }, zoomLevel: 9.5 },
  { name: "Mount Nimba, West Africa", latlng: { lat: 7.6496, lng: -8.3966 }, zoomLevel: 13.75 },
  { name: "The Solway Firth, United Kingdom", latlng: { lat: 54.6648, lng: -3.8026 }, zoomLevel: 9.75 },
  { name: "Lena River Delta, Siberia", latlng: { lat: 73.196, lng: 125.2873 }, zoomLevel: 11.75 },
  { name: " Lake Eyre Basin, Australia", latlng: { lat: -27.8796, lng: 137.1764 }, zoomLevel: 12.5 },
];

export const TerrasmartLocations = [
  { name: "TerraSmart Cols, Ohio - Ground Mount Racking", latlng: { lat: 39.919, lng: -82.968 }, zoomLevel: 17 },
  { name: "TerraSmart Cinci, Ohio – Ground Mount Racking", latlng: { lat: 39.186, lng: -84.474 }, zoomLevel: 17 },
  { name: "TerraSmart Brentwood, CA - eBos", latlng: { lat: 37.955, lng: -121.689 }, zoomLevel: 17 },
  { name: "TerraSmart Oakland, CA - SIFT", latlng: { lat: 37.832, lng: -122.263 }, zoomLevel: 17 },
  { name: "TerraSmart Fort Meyer, FL - Ground Mount Racking", latlng: { lat: 26.527, lng: -81.788 }, zoomLevel: 17 },
  { name: "TerraSmart Grand Rapids, MI - eBos", latlng: { lat: 43.024, lng: -85.735 }, zoomLevel: 17 },
];
