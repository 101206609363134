import styled from "styled-components";

const BQWrap = styled.section`
  .table-header {
    text-align: center;
  }

  .bq-table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
  }
  .bq-table td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 2px;
    word-break: normal;
  }
  .bq-table th {
    border-color: black;
    border-style: bold;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 2px;
    word-break: normal;
  }

  .bq-table .td-hl {
    font-weight: bold;
  }

  .bq-results {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    max-width: 400px;
    margin: 0px auto;

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      margin-right: 5px;

      &:focus {
        border-color: #60de4f;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0px 3px 15px rgba(96, 222, 79, 0.2);
      }
    }
  }

  .quote-id-input {
  }
`;

export { BQWrap };
