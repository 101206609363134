import React from "react";
import { Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { ground, sunPower, lightbulb, counting, icon } from "../ReportImages";

// Create styles
const styles = StyleSheet.create({
  infoContainer: {
    // flexGrow: 1,
    marginHorizontal: 20,
    height: 40,
    // flexDirection: 'row',
    // backgroundColor: '#e29e37',
  },
  infoBox: {
    flexGrow: 5,
    // backgroundColor: '#f2f2f2',
    height: 40,
  },
  infoImage: {
    width: 20,
    marginHorizontal: 10,
    marginVertical: 7.5,
    backgroundColor: "#f2f2f2",
  },
  infoHeader: {
    fontSize: 10,
    textAlign: "justify",
    marginVertical: 2.5,
    marginHorizontal: 2,
    height: 13,
  },
  infoText: {
    fontSize: 9,
    // textAlign: 'center',
    marginVertical: 2.5,
    marginHorizontal: 2,
    height: 11,
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    height: 40,
  },

  leftInfo: {
    // flexGrow: 1,
    width: 40,
    // backgroundColor: '#f22332',
    backgroundColor: "#f2f2f2",
    height: 40,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
  },
  rightInfo: {
    // flexGrow: 1,
    // backgroundColor: '#f212f2',
    height: 40,
  },
});

// Create Document Component
const SummaryHeader = (props) => (
  <View style={styles.infoContainer}>
    <View style={styles.row}>
      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={lightbulb} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Module Rating:</Text>
            <Text style={styles.infoText}>{props.overview.rating}W</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={counting} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Module Qty:</Text>
            <Text style={styles.infoText}>{props.overview.mod_qty}</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={sunPower} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Site Capacity:</Text>
            <Text style={styles.infoText}>{(props.overview.capacity / 1000).toFixed(2)}kW</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={ground} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Canopy Count:</Text>
            <Text style={styles.infoText}>{props.overview.count}</Text>
          </View>
        </View>
      </View>
      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={icon} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Location:</Text>
            <Text style={styles.infoText}>
              {props.overview.center[0].toFixed(3)}°, {props.overview.center[1].toFixed(3)}°
            </Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);

export { SummaryHeader };
