import React, { useEffect, useRef } from "react";

import L from "leaflet"; // Same as `import * as L from 'leaflet'` with webpack

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  controlmenu: "controlmenu",
};

const LeafletControl = ({ children, position }) => {
  const toolbar_ref = useRef();

  useEffect(() => {
    L.DomEvent.disableClickPropagation(toolbar_ref.current);
  }, []);

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  return (
    <section className={positionClass} ref={toolbar_ref}>
      <section className="leaflet-control leaflet-bar">{children}</section>
    </section>
  );
};

export { LeafletControl };
