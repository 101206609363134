function getTopoImages(id, mode, limit = undefined) {
  if (limit) {
    return `https://topo-tiles.sunfig.com/test/${id}/${mode}/${limit}/{z}/{x}/{y}.png`;
  } else {
    return `https://topo-tiles.sunfig.com/test/${id}/${mode}/{z}/{x}/{y}.png`;
  }
}
function getTopoScale(id, mode, limit = undefined) {
  if (limit) {
    return `https://topo-tiles.sunfig.com/test/${id}/${mode}/${limit}/scale.png`;
  } else {
    return `https://topo-tiles.sunfig.com/test/${id}/${mode}/scale.png`;
  }
}

export { getTopoImages, getTopoScale };
