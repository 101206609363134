import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../../../../Redux";

import { Button, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;
import { InputRow, SelectRow } from "./styles";

function GenerateWireDiagram() {

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const azimuth = useSelector((state) => state.canopy.canopies[selected_canopy]?.azimuth);
  const canopy = useSelector((state) => state.canopy.canopies[selected_canopy]);
  const wiring_loading = useSelector((state) => state.inputs.uiState.wiring_loading);

  const [local_mps, set_local_mps] = useState(12);
  // console.log([...canopy.modules])
  // console.log(canopy.structure)

  function onClick() {
    let inputs = {
      name: selected_canopy,
      azimuth: azimuth,
      orientation: 1,
      modules_per_string: parseInt(local_mps),
      strings_per_combiner: 6,
      Modules: [...canopy.modules],
      Structure: canopy.structure,
    };
    console.log("GENERATE WIRE DIAGRAM");
    // dispatch(inputsActions.generateWireDiagram(canopy_id, inputs));
  }

  function onChangeInput(e) {
    set_local_mps(e.target.value);
  }

  return (
    <>
      <InputRow>
        <label>Modules per String</label>
        <Input
          size="small"
          value={local_mps}
          type="number"
          step={1}
          min={0}
          max={999}
          suffix={wiring_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>#</span>}
          onChange={onChangeInput}
        ></Input>
      </InputRow>
      <SelectRow>
        <Button style={{ width: "200px" }} onClick={onClick} loading={wiring_loading}>
          Generate Wire Diagram
        </Button>
      </SelectRow>
    </>
  );
}

export { GenerateWireDiagram };
