import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";
let app_id = "py7g789m";

const IntercomMessenger = () => {
  const user_id = useSelector((state) => state.user.access.user_id);
  const invoice_name = useSelector((state) => state.user.invoice_name);
  const email = useSelector((state) => state.user.email);
  const project_name = useSelector((state) => state.inputs.project_name);
  const project_id = useSelector((state) => state.inputs.project_id);

  Intercom({
    app_id,
    user_id: user_id,
    name: email || "",
    email,
    project_name,
    project_id,
    project_type: 4,
  });

  return null;
};

export { IntercomMessenger };
