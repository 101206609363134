import React, { useState } from "react";
import { useMap } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { rotate, move, edit_canopies, draw_polygon, copy, duplicate, up, down, left, right } from "../../../../../assets/images";

import { Tooltip, Dropdown, Menu } from "antd";
import { canopyActions } from "../../../../Redux";
import {CloudDownloadOutlined, CloudUploadOutlined, SaveOutlined} from "@ant-design/icons";


const items = [
    {
        //label: '',
        key: 'up',
        icon: <img src={up} />,
    },
    {
        //label: '',
        key: 'down',
        icon: <img src={down} />,
    },
    {
        //label: '',
        key: 'left',
        icon: <img src={left} />,
    },
    {
        //label: '',
        key: 'right',
        icon: <img src={right} />,
    },
];
const duplicateCanopy = (e) =>{
    const map = useMap();
    map.fire("duplicateCanopy", { direction: e.key })
}
const menuProps = {
    items,
    onClick: duplicateCanopy,
};


// const DuplicateDropdown = () => {
//   const map = useMap();
//   return (
//     <Menu onClick={(e) => map.fire("duplicateCanopy", { direction: e.key })}>
//       <Menu.Item key="up" eventKey="up">
//         <img src={up} />
//       </Menu.Item>
//       <Menu.Item key="down" eventKey="down">
//         <img src={down} />
//       </Menu.Item>
//       <Menu.Item key="left" eventKey="left">
//         <img src={left} />
//       </Menu.Item>
//       <Menu.Item key="right" eventKey="right">
//         <img src={right} />
//       </Menu.Item>
//     </Menu>
//   );
// };

const CanopyTools = ({ id }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const is_editing_modules = useSelector((state) => state.canopy.is_editing_modules);
  const copy_canopy_id = useSelector((state) => state.canopy.copy_canopy_id);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);

  const [dup_tool_active, set_dup_tool_active] = useState(false);

  const is_editing = useSelector((state) => state.canopy.is_editing);
  const is_rotating = useSelector((state) => state.canopy.is_rotating);
  const is_dragging = useSelector((state) => state.canopy.is_dragging);

  return (
    <section className="map-action-buttons">
      <Tooltip placement="bottom" title="Edit Canopy" mouseEnterDelay={0.25}>
        <button className={is_editing ? "button-active" : ""} onClick={() => map.fire("editCanopy")}>
          <img draggable={false} src={edit_canopies} />
        </button>
      </Tooltip>
      <Tooltip placement="bottom" title="Move Canopy" mouseEnterDelay={0.25}>
        <button className={is_dragging ? "button-active" : ""} onClick={() => map.fire("moveCanopy")}>
          <img draggable={false} src={move} />
        </button>
      </Tooltip>
      <Tooltip placement="bottom" title="Rotate Canopy" mouseEnterDelay={0.25}>
        <button className={is_rotating ? "button-active" : ""} onClick={() => map.fire("rotateCanopy")}>
          <img draggable={false} src={rotate} />
        </button>
      </Tooltip>
      {/* <section className="vert-line"></section> */}
      <Dropdown menu={menuProps} trigger={["click"]} placement="bottom">
        {/* <Tooltip placement="bottom" title="Duplicate Canopy" mouseEnterDelay={0.25}> */}
        <button
          className={dup_tool_active ? "button-active" : ""}
          onClick={() => {
            set_dup_tool_active(!dup_tool_active);
            map.fire("duplicate_tool");
          }}
        >
          <img draggable={false} src={duplicate} />
        </button>
        {/* </Tooltip> */}
      </Dropdown>
      <Tooltip placement="bottom" title="Edit Modules" mouseEnterDelay={0.25}>
        <button className={is_editing_modules ? "button-active" : ""} onClick={() => map.fire("editModules")}>
          <img draggable={false} src={draw_polygon} />
        </button>
      </Tooltip>
      <Tooltip placement="bottom" title="Copy Canopy" mouseEnterDelay={0.25}>
        <button className={copy_canopy_id ? "button-active" : ""} onClick={() => dispatch(canopyActions.updateCanopyInputs("copy_canopy_id", copy_canopy_id ? undefined : selected_canopy))}>
          <img draggable={false} src={copy} />
        </button>
      </Tooltip>
    </section>
  );
};

export { CanopyTools };
