import { ProjectConstants, UserConstants } from "../_constants";
//import { map_to_1_8 } from "./sift_inputs";

const initialState = {
  loading: false,
  saving: false,
  loaded: false,
  local: {},
  collab_project: {},
  quote_id: undefined,
};

export function projects(state = initialState, action) {
  switch (action.type) {
    case UserConstants.GET_USER_LIB_REQ:
    case ProjectConstants.LOAD_PROJECT_REQUEST:
    case ProjectConstants.SHARE_COLLAB_REQUEST:
    case ProjectConstants.REFRESH_PROJECT_REQUEST:
    case ProjectConstants.CHECK_COLLAB_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ProjectConstants.GOT_PROJECT_DATA:
      // console.log("GOT PROJECTS", action.projects);
      let projects = {};
      let sorted_project_data = action.projects.sort(function (a, b) {
        return a.edit_dt - b.edit_dt;
      });

      Object.values(sorted_project_data).map((proj) => {
        // console.log("proj in GOT_PROJECT_DATA ", proj);
        // update_project_data = map_to_1_8(proj);

        projects[proj.project_id] = proj;

        // console.log(projects[proj.project_id])
      });

      return {
        ...state,
        loaded: true,
        loading: false,
        local: projects,
      };

    case ProjectConstants.GET_USER_DATA_FAILURE:
      return JSON.parse(JSON.stringify(initialState));

    case ProjectConstants.UPDATE_PROJECT_REQUEST:
      return { ...state, loading: true, loaded: false };

    case ProjectConstants.UPDATE_PROJECT_SUCCESS:
      console.log("UPDATE_PROJECT_SUCCESS", action);

      if (action.response.error) {
        return { ...state, loading: false, loaded: true, saving: false };
      }

      let updated_inputs = action.inputs;
      let updated_local = state.local;

      if (updated_inputs.active == 0) {
        // deleted project
        delete updated_local[action.response.project_id];
      } else {
        // update project / overwrite previous
        updated_inputs.project_id = action.response.project_id;
        updated_inputs.project_name = action.inputs.project_name;

        let updated_project = {
          project_id: updated_inputs.project_id,
          project_name: updated_inputs.project_name,
          project_type: updated_inputs.project_type,
          edit_dt: action.response.editDate,
          inputs: updated_inputs,
        };

        if (action.inputs.collab_on == 1) {
          updated_project.collab_on = 1;
          updated_project.creator = action.inputs.creator;
          updated_project.locked = action.inputs.locked;
          updated_project.locked_by_email = action.inputs.locked_by_email;
          updated_project.locked_by_me = action.inputs.locked_by_me;
          updated_project.notes = action.inputs.notes;
          updated_project.user_list = action.inputs.user_list;
        }

        updated_local[action.response.project_id] = updated_project;
      }
      // console.log(updated_local);

      return { local: { ...updated_local }, loading: false, loaded: true, saving: false };

    case ProjectConstants.LOAD_PROJECT_COMPLETE:
      // console.log(action);
      if (action.error) {
        console.log(action.error);
      }

      // projectManager.loading = false;
      // projectManager.modal_visible = false;
      // ioManager.uiState.input_loading = false;
      return { ...state, loading: false, loaded: true };

    case ProjectConstants.REFRESH_PROJECT_SUCCESS:
    case ProjectConstants.COLLAB_SUCCESS:
      // console.log("collab success", action.response);

      let p_id = action.response.updated_project.project_id;
      return {
        ...state,
        loaded: true,
        loading: false,
        local: { ...state.local, [p_id]: action.response.updated_project },
      };

    case ProjectConstants.COLLAB_FAILURE:
      console.log(action);
      return state;

    case ProjectConstants.UPDATE_PROJECT_INPUT:
      const temp_state = { ...JSON.parse(JSON.stringify(state)) };

      temp_state[action.key] = action.value;

      return { ...temp_state };

    default:
      return state;
  }
}
