import styled from "styled-components";

export const PanelBody = styled.section`
  width: 100%;
  padding: 10px;

  h3 {
    margin: 0px 0px 5px 0px;
  }

  .inputs-panel {
    display: grid;
    grid-template-rows: repeat(19, auto);
  }

  .input-one-col-label {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 5px;
    margin-top: 5px;
    align-content: center;

    label {
      background-color: #fff;
    }
  }

  .input-one-col {
    display: grid;
    width: 100%;
    height: 20px;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    /* padding: 0 5px; */
    margin: 4px auto 10px auto;

    span {
      font-size: 11px;
    }
  }
  .input-one-col.coord {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    place-items: normal;
  }
  .input-one-col.indent {
    margin-left: 10px;
  }

  .topo-vis {
    grid-template-columns: 35% 65%;
    Input {
      grid-column: auto;
    }
  }
  .input-topo-vis {
    margin: auto;
    display: grid;
    grid-template-columns: 12% 12% 74%;
    align-items: center;
    column-gap: 1%;
  }

  .input-two-col {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px 5px;
    margin-top: 5px;
    align-content: center;

    label {
      grid-column: 1;
      background-color: #fff;
    }
    Button {
      grid-column: 2;
      margin: auto 0px auto auto;
    }
    Input {
      grid-column: 2;
    }
  }

  .small {
    grid-template-columns: 50% 12% 38%;
    span {
      grid-column-start: 3;
    }
  }

  .radio {
    div {
      display: inline-grid;
    }
  }
  .noinline {
    div {
      display: block;
    }
  }
  .grid-start-3 {
    div {
      grid-column-start: 3;
    }
  }

  .input-do-roads {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 5px;
    margin-top: 8px;
    align-content: center;
  }

  .input-three-col {
    display: grid;
    grid-template-columns: 50% 12% 38%;
    padding: 0 5px;
    margin-top: 5px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: 0px 5px 0px auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
  }
  .topo3 {
    grid-template-columns: 26% 30% 44%;
    div {
      display: inline-grid;
    }
  }

  .racking-input {
    padding: 5px 5px;
    display: grid;
    grid-template-columns: 1fr;

    .radio-buttons {
      grid-column: 1;
      display: grid;
      place-items: center;
    }
  }

  .text-block {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    padding: 0 5px;

    .ant-select-selector {
      width: 290px;
    }
  }

  .weather-divider {
    margin: 8px -15px !important;
    font-size: 10pt;
    pointer-events: none;

    .ant-divider-inner-text {
      padding-left: 0px;
      cursor: pointer;
    }
    .ant-divider-inner-text:hover {
      color: var(--primary-brand-color);
    }
  }

  .err {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 5px 0px;
    p {
      margin: 3px 0px 0px 0px;
      font-size: 12px;
    }
    span {
      margin-right: 3px;
    }
  }
`;
