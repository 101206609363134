import styled, { css } from "styled-components";

const TitleDividerLayout = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.subHeader ? `0px 10px` : "undet")};

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

  svg {
    margin-right: 5px;
    min-height: 12px;
    min-width: 12px;
    transition: all 0.2s ease-out;
    transform: ${(props) => (props.toggle_var ? `rotate(90deg)` : "rotate(0deg)")};
  }

  p {
    margin: 0px 5px 0px 0px;
    white-space: nowrap;
    font-weight: ${(props) => (props.subHeader ? `400` : "600")};
  }

  svg,
  p {
    &:hover {
      cursor: ${(props) => (props.collapseable ? "pointer" : "default")};
      opacity: ${(props) => (props.collapseable ? "0.8" : "1")};
    }
  }
`;

export { TitleDividerLayout };
