import styled from 'styled-components';

const ReportPageFooterWrap = styled.section`
  grid-area: report-footer;
  width: 100%;
  height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.9);
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;

  .page-designation {
    grid-column: 1;
    display: grid;
    place-items: center;

    p {
      margin: 0px;
    }
  }

  .powered-by-sunfig {
    grid-column: 2;
    display: grid;
    place-items: center;
    border-left: 1px solid #333;
    border-right: 1px solid #333;

    img {
      width: 100px;
    }
  }

  .page-number {
    grid-column: 3;
    display: grid;
    place-items: center;

    p {
      margin: 0px;
    }
  }
`;

export { ReportPageFooterWrap };
