export const authConstants = {
  LOGIN_VISIBILITY_TOGGLE: "LOGIN_VISIBILITY_TOGGLE",
  LOGIN_TAB_TOGGLE: "LOGIN_TAB_TOGGLE",
  REFRESH_VISIBILITY_TOGGLE: "REFRESH_VISIBILITY_TOGGLE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  CONTINUE_LOGIN: "CONTINUE_LOGIN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  PWRESET_REQUEST: "PWRESET_REQUEST",
  PWRESET_SUCCESS: "PWRESET_SUCCESS",
  PWRESET_FAILURE: "PWRESET_FAILURE",
  TOGGLE_NEW_AGREEMENT_MODAL: "TOGGLE_NEW_AGREEMENT_MODAL",
  SHOW_TERMS: "SHOW_TERMS",
  AGREEMENT_DECLINED: "AGREEMENT_DECLINED",
  AGREEMENT_ACCEPTED: "AGREEMENT_ACCEPTED",
};

export const UserConstants = {
  LOGIN_VISIBILITY_TOGGLE: "LOGIN_VISIBILITY_TOGGLE",
  LOGIN_TAB_TOGGLE: "LOGIN_TAB_TOGGLE",
  REFRESH_VISIBILITY_TOGGLE: "REFRESH_VISIBILITY_TOGGLE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  CONTINUE_LOGIN: "CONTINUE_LOGIN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  PWRESET_REQUEST: "PWRESET_REQUEST",
  PWRESET_SUCCESS: "PWRESET_SUCCESS",
  PWRESET_FAILURE: "PWRESET_FAILURE",
  TOGGLE_NEW_AGREEMENT_MODAL: "TOGGLE_NEW_AGREEMENT_MODAL",
  SHOW_TERMS: "SHOW_TERMS",
  AGREEMENT_DECLINED: "AGREEMENT_DECLINED",
  AGREEMENT_ACCEPTED: "AGREEMENT_ACCEPTED",

  GET_USER_LIB_REQ: "GET_USER_LIB_REQ",
  GET_USER_LIB_COMP: "GET_USER_LIB_COMP",

  FUNNEL_VISIBILITY_TOGGLE: "FUNNEL_VISIBILITY_TOGGLE",
  ADDPAYMENT_REQUEST: "ADDPAYMENT_REQUEST",
  ADDPAYMENT_SUCCESS: "ADDPAYMENT_SUCCESS",
  ADDPAYMENT_FAILURE: "ADDPAYMENT_FAILURE",
  UPDATESUB_REQUEST: "UPDATESUB_REQUEST",
  UPDATESUB_FAILURE: "UPDATESUB_FAILURE",
  UPDATESUB_SUCCESS: "UPDATESUB_SUCCESS",

  UPDATE_USER_VALUE: "UPDATE_USER_VALUE",
  UPDATE_PRESET: "UPDATE_PRESET",
};
