import React, { useState, useEffect } from "react";
import { MapContainer, LayerGroup, GeoJSON, TileLayer } from "react-leaflet";
import L from "leaflet";

//styles
import { ReportMapWrap } from "./styles";

// redux
import { useSelector } from "react-redux";

// helpers
import { getBounds } from "../../../Redux";
import { getTopoImages } from "../Helpers";

//map tiles
import { BingLayer } from "../../../Map/components";

import { ZoomControl } from "../ZoomControl";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const TopoMap = ({ mapName, topoMode, setLiveReportMapRefs }) => {
  const inputs = useSelector((state) => state.inputs);
  const features = useSelector((state) => state.inputs.site_features);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  const [map, setMap] = useState(null);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  // const onChangeTileset = (tileset) => {
  //   setBingLayerVisible(false);
  //   setActivetileSet(tileset);
  // };

  useEffect(() => {
    if (!map) return;
    setTimeout(() => {
      setLiveReportMapRefs(mapName, { height: map._size.y, width: map._size.x, className: mapName });
      handleTopoMapExtents(16);
    }, 500);
  }, [map]);

  const handleTopoZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleTopoMapExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(features).length === 0) return;
    // define canopy feature array
    let allFeatures = [];

    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(features).map((feature) => {
      allFeatures.push(feature);
    });

    // get the bounds of the features collection
    let bounds = getBounds(allFeatures);

    if (zoomLevel) {
      map.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      map.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  let southWest = inputs.boundary_bbox && L.latLng(inputs.boundary_bbox[1] - 0.0009, inputs.boundary_bbox[0] - 0.0009),
    northEast = inputs.boundary_bbox && L.latLng(inputs.boundary_bbox[3] + 0.0009, inputs.boundary_bbox[2] + 0.0009),
    boundary_bbox = inputs.boundary_bbox && L.latLngBounds(southWest, northEast);

  const identityColors = {
    0: "white",
    1: "red",
    2: "yellow",
  };
  return (

    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <MapContainer
        // whenCreated={setMap}
        whenReady={(map) => setMap(map.target)}
        id={mapName}
        className={mapName}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[inputs.latitude, inputs.longitude]}
        zoom={zoom}
        minZoom={2}
        maxZoom={20}
        onzoomend={() => handleTopoZoom(map.getZoom())}
        zoomControl={false}
        zoomSnap={inputs.zoomGranularity}
        zoomDelta={inputs.zoomGranularity}
        style={{ width: "100%", height: "100%" }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && map && <ZoomControl mapRef={map} />}

        <LayerGroup>
          {features &&
            Object.values(features).map((poly) => {
              return (
                <GeoJSON
                  data={poly}
                  key={poly.properties.index}
                  style={{
                    fillColor: "#ffffff",
                    fillOpacity: 0.01,
                    weight: 1,
                    color: identityColors[poly.properties.identity] || "white",
                  }}
                ></GeoJSON>
              );
            })}
        </LayerGroup>

        {inputs.topo_id && (
          <TileLayer
            url={getTopoImages(inputs.topo_id, topoMode)}
            opacity={0.7}
            tms
            zIndex={10}
            bounds={boundary_bbox}
            errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            crossOrgin
          />
        )}
      </MapContainer>
    </ReportMapWrap>
  );
};

export { TopoMap };
