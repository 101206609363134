/*eslint no-undef: "error"*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { usePrevious } from "../../Redux";
import { ts_logo_hori } from "../../../assets/images";

import { Row, Button, Form, Input, message, Spin, Result } from "antd";
import {UserOutlined, LockOutlined, InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";

import { UserActions } from "../../Redux";

import { InternalLoginWrapper, LoginErrWrap } from "./styles";

import { ValidateEmail } from "../../ProjectManager/helper";

const FormItem = Form.Item;

const AuthContext = React.createContext();

const Reset_error = ({ errors }) => {
  return (
    <LoginErrWrap>
      <ol>
        {errors?.map((error, index) => {
          return (
            <li key={index}>
              <InfoCircleOutlined style={{ marginRight: 5 }} />
              {error}
            </li>
          );
        })}
      </ol>
    </LoginErrWrap>
  );
};

const InternalSIFTLogin = (props, { isAuth }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);

  const loggingIn = useSelector((state) => state.user.loggingIn);
  const [is_loading, set_is_loading] = useState(false);
  const [reset_success, set_reset_success] = useState(false);
  const [logging_in, set_logging_in] = useState(false);
  const [form_loading, set_form_loading] = useState(false);
  const [show_password_reset, set_show_password_reset] = useState(false);
  const [reset_pw, set_reset_pw] = useState(false);
  const [old_password, set_old_password] = useState("Password2019");
  const [error, set_error] = useState(undefined);

  const prev_logging_in = usePrevious(loggingIn);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    set_logging_in(loggingIn);

    if (show_password_reset && prev_logging_in && !loggingIn) {
      set_error([`${user.pw_reset_msg}`]);
    }
  }, [loggingIn]);

  const handleSubmit = (authValues) => {
    set_is_loading(true);

    if (authValues.email && authValues.password) {
      if (reset_pw) {
        if (authValues.password != authValues.confirm_password) {
          message.error("Passwords do not match", 5);
          return;
        }
        if (authValues.confirm_password.length < 7) {
          message.error("Password must be great than 6 characters", 5);
          return;
        }
        // call reset pw
        // dispatch(UserActions.reset_pw(authValues.email, this.state.old_password, authValues.confirm_password));
      } else {
        set_old_password(authValues.password);
        // login normally

        let quote_id = searchParams.toString() ? searchParams.toString() : false;

        dispatch(UserActions.internalLogin(authValues.email, authValues.password, quote_id));
      }
    }
  };

  const sendResetEmail = (values) => {
    if (ValidateEmail(values.email)) {
      set_is_loading(true);
      dispatch(UserActions.pwReset(values.email));
    } else {
      set_error(["Email is Invalid"]);
    }
  };

  const toggleforgot = () => {
    set_form_loading(true);
    set_show_password_reset(!show_password_reset);

    if (error?.length > 0) {
      set_error(undefined);
    }

    setTimeout(() => {
      set_form_loading(false);
    }, 1200);
  };

  return (
    <>
      <Row justify="center" type="flex" style={{ padding: "25px", backgroundColor: "#fff", height: "100vh" }}>
        <AuthContext.Consumer>
          {(value) => (
            <InternalLoginWrapper>
              {/* START panel */}
              <section className="login-panel">
                <section className="login-header">
                  <a href="#">
                    <img src={ts_logo_hori} alt="RBI Portal" className="center" />
                  </a>
                </section>
                <section className="portal-header">
                  <p>Sales Portal</p>
                </section>
                <section className="login-panel-body">
                  {form_loading ? (
                    <section className="form-load_spinner">
                      <Spin spinning={form_loading} size="large" />
                    </section>
                  ) : (
                    <>
                      {show_password_reset ? (
                        <>
                          <section className="forgot-pass-text">Enter your account email, and we'll send you a link to reset your password.</section>
                          <Form
                            // onSubmit={this.handleSubmit(value)}
                            onFinish={sendResetEmail}
                            className="login-form"
                          >
                            <section className="login-label">Email</section>
                            <FormItem style={{ margin: 0 }} name="email" className="input" rules={[{ required: true, message: "Please enter your email address" }]}>
                              <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Email" className="inner-input" />
                            </FormItem>

                            <FormItem style={{ textAlign: "right", margin: "0px 5px 0px 0px" }}>
                              <a className="login-form-forgot" href="javascript:void(0);" onClick={() => toggleforgot()}>
                                Return to Login
                              </a>
                            </FormItem>

                            <Reset_error errors={error} />

                            <FormItem>
                              <Button block type="primary" htmlType="submit" className="login-button" loading={logging_in}>
                                Request Password Reset
                              </Button>
                            </FormItem>
                          </Form>
                        </>
                      ) : (
                        <Form
                          // onSubmit={this.handleSubmit(value)}
                          onFinish={handleSubmit}
                          className="login-form"
                        >
                          <section className="login-label">Email</section>
                          <FormItem name="email" className="input" rules={[{ required: true, message: "Please input your email!" }]}>
                            <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Email" className="inner-input" />
                          </FormItem>

                          <section style={{ height: 13 }}></section>

                          <section className="login-label">Password</section>
                          <FormItem style={{ margin: 0 }} name="password" className="input" rules={[{ required: true, message: "Please input your password!" }]}>
                            <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                   suffix={showPassword ? <EyeOutlined onClick={togglePasswordVisibility}/>:<EyeInvisibleOutlined onClick={togglePasswordVisibility}/>}
                                   type={showPassword ? "text" : "password"} placeholder="Password" className="inner-input" />
                          </FormItem>

                          <FormItem style={{ textAlign: "right", margin: "0px 5px 0px 0px" }}>
                            <a className="login-form-forgot" href="javascript:void(0);" onClick={() => toggleforgot()}>
                              Forgot your password?
                            </a>
                          </FormItem>

                          <Reset_error errors={error} />

                          <FormItem>
                            <Button block type="primary" htmlType="submit" className="login-button" loading={logging_in}>
                              {logging_in ? "LOGGING IN..." : "LOGIN"}
                            </Button>
                          </FormItem>
                        </Form>
                      )}
                    </>
                  )}

                  {/* if password needs to be updated */}

                  {/* {reset_pw && (
                      <section>
                        <section style={{ height: 13 }}></section>
                        <section style={{ height: 13 }}></section>
                        <section className="login-label reset">Reset Password required for account.</section>
                        <section className="login-label reset">Please enter and confirm a new password.</section>
                      </section>
                    )} */}

                  {/* {reset_pw && (
                      <section>
                        <section style={{ height: 13 }}></section>
                        <section className="login-label"> Confirm Password</section>
                        <FormItem name="confirm_password" className="input" rules={[{ required: true, message: "Please input your password!" }]}>
                          <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Confirm Password" className="inner-input" />
                        </FormItem>
                      </section>
                    )} */}
                </section>
              </section>
              {/* END panel */}
            </InternalLoginWrapper>
          )}
        </AuthContext.Consumer>
      </Row>
    </>
  );
};

export { InternalSIFTLogin };
