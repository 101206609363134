import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const tableTitleColor = "#DDDDDD";
const tableHeaderColor = "#EAEAEA";
const borderColor = "#666";
const textColor = "#333";

// *** These are the custom TWO COLUMN tables that show up in the report

const TableStyles = StyleSheet.create({
  container: {
    width: "100%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
});

const Table = ({ children }) => <View style={TableStyles.container}>{children}</View>;

const TitleStyles = StyleSheet.create({
  title: {
    width: "100%",
    backgroundColor: tableTitleColor,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
});

const TableTitle = ({ title }) => <Text style={TitleStyles.title}>{title}</Text>;

const TableColStyles = StyleSheet.create({
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: borderColor,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const TableCol = ({ children }) => <View style={TableColStyles.tableCol}>{children}</View>;

const TableRowStyles = StyleSheet.create({
  rowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
  },
});

const TableRow = ({ children }) => <View style={TableRowStyles.rowContainer}>{children}</View>;

const TableHeaderStyles = StyleSheet.create({
  title: {
    width: "50%",
    backgroundColor: tableHeaderColor,
    textAlign: "right",
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: textColor,
  },
});

const TableHeader = ({ children }) => <Text style={TableHeaderStyles.title}>{children}</Text>;

const TableTextStyles = StyleSheet.create({
  text: {
    width: "50%",
    textAlign: "left",
    paddingHorizontal: 10,
    paddingVertical: 2,
    color: textColor,
  },
});

const TableText = ({ children }) => <Text style={TableTextStyles.text}>{children}</Text>;

// *** Below are the "standard" table rows and columns for the performance results table that look like the regular HTML tables. ***

const PerfTableStyle = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: borderColor,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
});

const PerfTable = ({ children }) => <View style={PerfTableStyle.table}>{children}</View>;

const PerfRowStyles = StyleSheet.create({
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
});

const PerfRow = ({ children }) => <View style={PerfRowStyles.tableRow}>{children}</View>;

const PerfColStyles = StyleSheet.create({
  tableCol: {
    width: "14%",
    borderStyle: "solid",
    borderColor: borderColor,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol_small: {
    width: "8%",
    borderStyle: "solid",
    borderColor: borderColor,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const PerfCol = ({ children }) => <View style={PerfColStyles.tableCol}>{children}</View>;
const PerfColSmall = ({ children }) => <View style={PerfColStyles.tableCol_small}>{children}</View>;

const PerfColHeaderStyles = StyleSheet.create({
  tableColHeader: {
    width: "14%",
    borderStyle: "solid",
    borderColor: borderColor,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const PerfColHeader = ({ children }) => <Text style={PerfColHeaderStyles.tableColHeader}>{children}</Text>;

const PerfCellHeaderStyles = StyleSheet.create({
  tableCellHeader: {
    margin: "auto",
    fontSize: 10,
    padding: 3,
  },
});

const PerfCellHeader = ({ children }) => <Text style={PerfCellHeaderStyles.tableCellHeader}>{children}</Text>;

const PerfCellStyles = StyleSheet.create({
  tableCell: {
    margin: "auto",
    fontSize: 10,
    padding: 3,
  },
});

const PerfCell = ({ children }) => <Text style={PerfCellStyles.tableCell}>{children}</Text>;

const SoilingHeaderStyles = StyleSheet.create({
  tableColHeader: {
    width: 35,
    borderStyle: "solid",
    borderColor: borderColor,
    borderBottomColor: "#000",
    borderRight: 1,
    borderBottom: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
  },
});

// ********** these are just used for the soiling table on the performance page ************

const SoilingColHeader = ({ children }) => <Text style={SoilingHeaderStyles.tableColHeader}>{children}</Text>;

const SoilingCellHeaderStyles = StyleSheet.create({
  tableCellHeader: {
    margin: "auto",
    width: 40,
    fontSize: 10,
    padding: 3,
    borderBottomColor: "#000",
    borderRight: 1,
    borderBottom: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const SoilingCellHeader = ({ children }) => <Text style={SoilingCellHeaderStyles.tableCellHeader}>{children}</Text>;

const SoilingCellStyles = StyleSheet.create({
  tableCell: {
    width: 35,
    borderStyle: "solid",
    borderColor: borderColor,
    borderBottomColor: "#000",
    borderRight: 1,
    borderBottom: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
  },
});

const SoilingCell = ({ children }) => <Text style={SoilingCellStyles.tableCell}>{children}</Text>;

const BlankCellStyles = StyleSheet.create({
  BlankCell: {
    margin: "auto",
    width: 40,
    height: 18,
    padding: 3,
    borderBottomColor: "#000",
    borderRight: 1,
    borderBottom: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const BlankCell = ({ children }) => <Text style={BlankCellStyles.BlankCell}>{children}</Text>;

// ****************** table styles for the loss tables *******************

const LossTableStyles = StyleSheet.create({
  container: {
    width: "100%",
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
});

const LossTableBox = ({ children }) => <View style={LossTableStyles.container}>{children}</View>;

const BifacialLossTableStyles = StyleSheet.create({
  container: {
    width: "100%",
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 0,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
});

const BifacialLossTableBox = ({ children }) => <View style={BifacialLossTableStyles.container}>{children}</View>;

const LossTableRowStyles = StyleSheet.create({
  loss_table_row: {
    width: "100%",
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 0,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

const LossTableRow = ({ children }) => <View style={LossTableRowStyles.loss_table_row}>{children}</View>;

const LossTableHeaderStyles = StyleSheet.create({
  loss_table_header: {
    width: "80%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    alignItems: "center",
    padding: 3,
    color: "#000",
    fontWeight: 800,
  },
  loss_table_header_small: {
    width: "20%",
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    alignItems: "center",
    padding: 3,
    color: "#000",
    fontWeight: 800,
    fontSize: 10,
  },
});

const LossTableHeader = ({ children }) => <Text style={LossTableHeaderStyles.loss_table_header}>{children}</Text>;
const LossTableHeaderSmall = ({ children }) => <Text style={LossTableHeaderStyles.loss_table_header_small}>{children}</Text>;

const LossTableCellStyles = StyleSheet.create({
  loss_table_cell: {
    width: "80%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    alignItems: "center",
    padding: 3,
    color: "#555",
  },
  loss_table_cell_small: {
    width: "20%",
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    alignItems: "center",
    padding: 3,
    color: "#555",
  },
});

const LossTableCell = ({ children }) => <Text style={LossTableCellStyles.loss_table_cell}>{children}</Text>;
const LossTableCellSmall = ({ children }) => <Text style={LossTableCellStyles.loss_table_cell_small}>{children}</Text>;

const LossTableTwoRowStyles = StyleSheet.create({
  loss_table_two_row: {
    width: "100%",
    height: 35,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: 3,
  },
});

const LossTableTwoRow = ({ children }) => <View style={LossTableTwoRowStyles.loss_table_two_row}>{children}</View>;

const LossTableSpacingRowStyles = StyleSheet.create({
  loss_table_spacing_row: {
    width: "100%",
    height: 25,
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    padding: 3,
  },
  loss_table_spacing_row_no_border: {
    width: "100%",
    height: 25,
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 0,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    padding: 3,
  },
});

const LossTableSpacingRow = ({ children }) => <Text style={LossTableSpacingRowStyles.loss_table_spacing_row}>{children}</Text>;
const LossTableSpacingRow_no_border = ({ children }) => <Text style={LossTableSpacingRowStyles.loss_table_spacing_row_no_border}>{children}</Text>;

const BifacialSpacingRowStyles = StyleSheet.create({
  bifacial_spacing_row: {
    width: "100%",
    height: 25,
    borderLeftColor: borderColor,
    borderLeftWidth: 0,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderBottomColor: borderColor,
    borderBottomWidth: 0,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    padding: 3,
  },
});

const BifacialSpacingRow = ({ children }) => <Text style={BifacialSpacingRowStyles.bifacial_spacing_row}>{children}</Text>;

const LossTableBifacialColSpanStyles = StyleSheet.create({
  loss_table_col_span: {
    width: "100%",
    height: 25,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    padding: 3,
  },
});

const LossTableBifacialColSpan = ({ children }) => <Text style={LossTableBifacialColSpanStyles.loss_table_col_span}>{children}</Text>;

const BifacialStylesHeaderStyles = StyleSheet.create({
  bifacial_header: {
    width: "100%",
    backgroundColor: tableHeaderColor,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    padding: 3,
  },
});

const BifacialHeader = ({ children }) => <Text style={BifacialStylesHeaderStyles.bifacial_header}>{children}</Text>;

export {
  Table,
  TableTitle,
  TableCol,
  TableRow,
  TableHeader,
  TableText,
  PerfTable,
  PerfRow,
  PerfColHeader,
  PerfCol,
  PerfColSmall,
  PerfCellHeader,
  PerfCell,
  SoilingColHeader,
  SoilingCellHeader,
  SoilingCell,
  BlankCell,
  LossTableBox,
  LossTableRow,
  LossTableTwoRow,
  LossTableHeader,
  LossTableHeaderSmall,
  LossTableCell,
  LossTableCellSmall,
  LossTableSpacingRow,
  LossTableSpacingRow_no_border,
  LossTableBifacialColSpan,
  BifacialLossTableBox,
  BifacialHeader,
  BifacialSpacingRow,
};
