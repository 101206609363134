import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, isNumber } from "../../../../../Redux";

import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const suffix_loading = <LoadingOutlined style={{ fontSize: "x-small" }} spin />;

import { InputRow } from "./styles";
import { debouncedUpdateinput } from "./inputs.helper";

function ModuleGapInput() {
  const dispatch = useDispatch();

  // const modGap = useSelector((state) => state.carport.selectedInputs.modGap);

  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);
  const modXGap = useSelector((state) => state.canopy.canopies[selected_canopy]?.modXGap);

  const [local_modGap, set_local_modGap] = useState(modXGap);
  const [local_loading, set_local_loading] = useState(false);
  const [modGap_error, set_modGap_error] = useState(undefined);

  useEffect(() => {
    if (modXGap && modXGap == local_modGap) {
      set_local_loading(false);
    } else if (!local_loading) {
      set_local_modGap(modXGap);
    }
  }, [modXGap]);

  function onChangeInput(e) {
    set_local_modGap(e.target.value);

    let changed_value = parseFloat(e.target.value);

    if (!isNumber(changed_value)) {
      set_modGap_error("Invalid Input");
      return;
    } else if (modGap_error != undefined) {
      set_modGap_error(undefined);
    }
    set_local_loading(true);
    // debounced method
    debouncedUpdateinput.cancel();
    debouncedUpdateinput(dispatch, selected_canopy, "modGap", changed_value);
  }

  return (
    <InputRow>
      <label>Modules, Gap:</label>
      <Input
        size="small"
        value={local_modGap}
        type="number"
        step={0.01}
        min={0}
        suffix={local_loading ? <Spin indicator={suffix_loading} /> : <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
        max="999"
        onChange={onChangeInput}
      ></Input>
    </InputRow>
  );
}

export { ModuleGapInput };
