const LayoutDD = {
  0: {
    id: 0,
    data: {    
      default: 0,    
      name: "Custom Layout",
      azimuth: 180,

      do_inverter_qty_lock: 0,
      inverter_qty: 1,
      do_inv_spacing: 1,
      inverter_per_cut: 1,
      do_roads: 1,
      do_rack_align: 1,
      road_spacing_option: 2,
      road_spacing: 300,
      layout_fill: 0,
      do_designer_margin: 0,
      designer_margin: 0,
      topo_action: 'nothing',
      epsg: 0,
    }
  }  
}

export { LayoutDD }