import React, { useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { getCorners, flipGeoJson } from "../ShadeHelpers/Shade.editor";

import { canopyActions, usePrevious } from "../../Redux";

const TreeEventLayer = ({ id, interactive = true }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const tree = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const prev_selected_shade_object_id = usePrevious(selected_shade_object_id);

  const [editing, set_editing] = useState(false);

  useEffect(() => {
    if ((selected_shade_object_id == undefined && prev_selected_shade_object_id == tree.id) || (prev_selected_shade_object_id == tree.id && selected_shade_object_id != tree.id)) {
      map.eachLayer((layer) => layer.options.type == "tree" && layer.options.id == tree.id && layer.pm.disable());
    }

    if (selected_shade_object_id == tree.id) {
      enable_shade_edit();
    }
  }, [selected_shade_object_id, prev_selected_shade_object_id]);

  const enable_shade_edit = () => {
    map.eachLayer((layer) => layer.options.type == "tree" && layer.options.id == tree.id && layer.pm.enable());
  };

  useEffect(() => {
    if (!tree) return;

    map.eachLayer((layer) => {
      if (layer.options && layer.options.id == tree.id) {
        layer.remove();
      }
    });

    if (tree?.checked === false) return;

    let tree_obj = new L.Circle(tree.geoJson.geometry.coordinates, { ...tree, interactive });

    tree_obj.addTo(map);

    if (selected_shade_object_id == tree.id) {
      tree_obj.pm.enable();
    }

    tree_obj.setStyle({
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 2,
      fillColor: "var(--primary-brand-color)",
    });

    // tree_obj.on("pm:drag", (e) => console.log("e", e));
    // tree_obj.on("click", (e) => {
    //   if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    //   if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
    // });

    tree_obj.on("click", (e) => {
      if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
      if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      if (e.target.pm.enabled()) {
        e.target.pm.disable();
        set_editing(false);
        if (e.target.options.id == selected_shade_object_id) {
          dispatch(canopyActions.updateCanopyInputs("selected_shade_object_id", undefined));
        }
        return;
      } else {
        e.target.pm.enable();
        dispatch(canopyActions.updateCanopyInputs("selected_shade_object_id", tree.id));
      }
    });

    tree_obj.on("pm:edit", (e) => {
      if (!e) return;
      update_tree(e.layer);
      e.layer.remove();
    });

    return () => {
      tree_obj.off("click");
      tree_obj.off("pm:edit");
    };
  }, [tree, tree.checked]);

  const update_tree = (layer) => {
    set_editing(true);
    let edited_tree = { ...tree };
    let new_geoJson = layer.toGeoJSON();
    edited_tree.geoJson = flipGeoJson(new_geoJson);
    edited_tree.center = layer.getBounds().getCenter();
    edited_tree.corners = getCorners(layer);
    edited_tree.radius = layer.getRadius();
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_tree));
  };

  return <></>;
};

export { TreeEventLayer };
