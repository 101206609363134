// @flow
/* eslint-disable no-console, react/no-multi-comp */
import React, { useState } from "react";
import { stripe_logo_1 } from "../_images";

// import { CardElement, StripeProvider, Elements, injectStripe } from "react-stripe-elements";

import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, ElementsConsumer, useStripe, useElements } from "@stripe/react-stripe-js";

import { Button, Input, Skeleton } from "antd";

const handleBlur = () => {};
const handleChange = (change) => {};
const handleFocus = () => {};
const handleReady = () => {};

const CARD_ELEMENT_OPTIONS = {
  style: {
    // border: '1px solid #d9d9d9',
    // borderRadius: '4px',
    // padding: '5px',

    base: {
      boxSizing: "border-box",
      margin: 0,
      fontVariant: "tabular-nums",
      listStyle: "none",
      // font-feature-settings: 'tnum';
      position: "relative",
      display: "inline-block",
      width: "100%",
      height: "32px",
      padding: "4px 11px",
      color: "rgba(0, 0, 0, 0.65)",
      fontSize: "13px",
      lineHeight: "1.5",
      backgroundColor: "#fff",
      // background-image: none;

      transition: "all 0.3s",
      // fontSize: '18px',
      // color: '#000000',
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.65)",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const CARD_STYLE = {
  padding: "10px",
  width: "350px",
  // maxWidth: '50%'
};

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, set_name] = useState(undefined);
  const [name_error, set_name_error] = useState(false);
  const [payment_error, set_payment_error] = useState(false);
  const [complete, set_complete] = useState(false);
  const [error, set_error] = useState("Empty Credit Card Form");
  const [loading, set_loading] = useState(false);
  // const [state, _setState] = useState({
  // 	name: undefined,
  // 	name_error: false,
  // 	payment_error: false,
  // 	complete: false,
  // 	error: "Empty Credit Card Form",
  // 	loading: false,
  // });

  const handleNameChange = (e) => {
    // _setState({ name: e.target.value, name_error: false });
    set_name(e.target.value);
    set_name_error(false);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!name) {
      // _setState({ name_error: true });
      set_name_error(true);
      return;
    }
    if (!complete) {
      // _setState({ payment_error: true });
      set_payment_error(true);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    // // Use your card Element with other Stripe.js APIs
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card,
    // });

    // setIsLoading(true);
    // _setState({ loading: true });
    set_loading(true);
    stripe.createToken(card).then((payload) => props.onReturn(payload, name));
  };
  const handleChange = (change) => {
    // console.log('[change]', change);
    set_complete(change.complete);
    set_error(change.error ? change.error.message : error);
    set_payment_error(false);
    // _setState({
    //   complete: change.complete,
    //   error: change.error ? change.error.message : error,
    //   payment_error: false,
    // });
  };

  return (
    <form
      onSubmit={(ev) => {
        handleSubmit(ev);
      }}
    >
      {stripe ? (
        <div style={{ height: "175px" }}>
          <label>Enter your credit card information</label>
          {stripe ? (
            <div style={CARD_STYLE}>
              <div style={{ paddingBottom: "5px" }}>
                <Input style={{}} placeholder="Enter Name on Card" value={name} onChange={handleNameChange}></Input>
                {name_error && <span style={{ color: "red" }}> Please Enter a name for the Card</span>}
              </div>

              <input name="chrome-autofill-dummy1" style={{ display: "none" }} disabled />
              <input name="chrome-autofill-dummy2" style={{ display: "none" }} disabled />

              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                <CardElement onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onReady={handleReady} options={CARD_ELEMENT_OPTIONS} />
              </div>
              <div style={{ paddingTop: "5px" }}>
                <img src={stripe_logo_1} />
              </div>
              {payment_error && <span style={{ color: "red" }}>{error}</span>}
            </div>
          ) : (
            <div className="StripeElement loading" />
          )}
          <div style={{ float: "right" }}>
            <span style={{ padding: "5px" }}>
              <Button type="primary" ghost onClick={props.onCancel} disabled={loading}>
                Cancel
              </Button>
            </span>
            <span style={{ padding: "5px" }}>
              <Button
                type="primary"
                disabled={!stripe}
                loading={loading}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Add
              </Button>
            </span>
          </div>
        </div>
      ) : (
        <Skeleton active />
      )}
    </form>
  );
};

// Take it out of your production code!
let key = "pk_test_eKFFlpsuJTKBlKcqhWpkJHei";
if (process.env.NODE_ENV === "production") {
  key = "pk_live_jhGKlwSBFPrsd8qNIZe9AefD";
}
// Create the Stripe object yourself...
const stripePromise = loadStripe(key);

const InjectedCheckoutForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export { InjectedCheckoutForm as CheckoutForm };
