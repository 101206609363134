import React from 'react';
import './api.css';

import APICarousel from './apiCarousel';

import { Button } from 'antd';

//images
import apiBanner from '../_images/apiBanner.png';

import slide_1 from '../_images/slider1.png';
import slide_2 from '../_images/slider2.png';
import slide_3 from '../_images/slider3.png';
import slide_4 from '../_images/slider4.png';
import slide_5 from '../_images/slider5.png';
import slide_6 from '../_images/slider6.png';
import slide_7 from '../_images/slider7.png';
import slide_8 from '../_images/slider8.png';

const items = [slide_1, slide_2, slide_3, slide_4, slide_5, slide_6, slide_7, slide_8];

const Api = () => {
  return (
    <section id="api" className="api">
      <div className="api-banner">
        <img src={apiBanner} alt="computer code a screen" />
      </div>
      <div className="api-content">
        <div className="api-header-text">
          <p>
            Integrate Sunfig’s technology directly into your existing software, tools, or proccess with our APIs. APIs available include automated
            layout for ground mount and commercial rooftops, DC and AC coupled storage modeling, performance modeling, financial modeling, and
            topography.
          </p>
        </div>
        <div className="api-carousel">
          <APICarousel items={items} altDescription={'Banner images for various services Sunfig offers'} height={265} />
        </div>

        <div className="devServices-footer-contact">
          <Button className="default" href="#contact">
            Contact Us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Api;
