import React from "react";

import { LeafletControl } from "../Map/components/LeafletControl";

import { LegendWrap } from "../Map/components/Toolbar/styles";

const LayoutEditLegend = ({ position }) => {
  return (
    <LeafletControl position={position}>
      <LegendWrap>
        <section className="legend-row">
          <section style={{ backgroundColor: "orangered" }} />
          <p>Selected Rack</p>
        </section>
        <section className="legend-row">
          <section style={{ backgroundColor: "#33A2FF" }} />
          <p>Enabled Rack</p>
        </section>
        <section className="legend-row">
          <section style={{ backgroundColor: "#A710A7" }} />
          <p>Disabled Rack</p>
        </section>
      </LegendWrap>
    </LeafletControl>
  );
};

export { LayoutEditLegend };
