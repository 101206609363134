const weather_data = [
  {
    month: "1",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "2",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "3",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "4",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "5",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "6",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "7",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "8",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "9",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "10",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "11",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
  {
    month: "12",
    HorizGlobIrrad: 0,
    HorizDiffIrrad: 0,
    HorizNormIrrad: 0,
    globalIncInColPlane: 0,
    eArray: 0,
    eGrid: 0,
    pressure: 0,
    Temp: 0,
    Wind: 0,
  },
];

const losses = {
  loss_stack: {
    "aa. Horizontal global irradiation (kWh/m^2)": 1892.248,
    "ab. Global incident in coll. plane %": 0.2662146737968242,
    "ac. Global incident below threshold": -0.00023969460278797005,
    "ad. Near Shadings: irradiance_loss": -0.03023559298296119,
    "ae. IAM factor on global": -0.0038842264538178073,
    "af. Soiling loss factor": -0.020000000000003994,
    "ba. Ground reflection on front side": 0.0035673213362541856,
    "bb. Global incidence on ground": 955.1614732458556,
    "bc. Global incidence on ground on area": 40227.25839999999,
    "bd. Ground reflection loss (Albedo)": -0.8,
    "be. View Factor for rear side": -0.6732506336693834,
    "bf. sky diffuse on the rear side": 0.31993333895799747,
    "bi. Beam effective on the rear side": 0.0,
    "bj. Shadings loss on rear side": -0.13,
    "bk. Global irradiance on rear side": 0.07094565848484448,
    "bl. Effective irradiation on collectors rear (kWh/m^2)": 161.45658219099366,
    "bm. Effective irradiation on collectors rear * bifaciality (kWh/m^2)": 121.09243664324524,
    "ca. Effective irradiation on collectors (kWh/m^2)": 2275.778189097844,
    "cb. Area of collectors (m^2)": 16090.903359999998,
    "cc. Efficiency at STC (%)": 0.21176286652500864,
    "cd. Array nominal energy at STC (MWh)": 8167230.764644183,
    "da. Loss due to irradiance level": -0.9989994798357259,
    "db. PV loss due to temperature": -0.06479851364453998,
    "dc. Shadings: Electrical loss": -0.0,
    "dd. Spectral correction": -0.0,
    "de. Module quality loss": 0.01,
    "df. LID - Light induced degradation": -0.015,
    "dg. Mismatch loss, modules and strings": -0.021,
    "dh. Mismatch for back irradiance": -0.006624580614595999,
    "di. Ohmic wiring loss": -0.014399028928624638,
    "dj. Array virual energy at MPP": 7287.199999513063,
    "ea. Inverter Loss during operation (efficiency)": -0.02084546891502914,
    "eb. Inverter Loss over nominal inv. power": -0.004590249093789558,
    "ec. Inverter Loss due to max input current": 0.0,
    "ed. Inverter Loss over nominal inv. voltage": 0.0,
    "ee. Inverter loss due to power threshold": -0.0004294952778005611,
    "ef. Inverter loss due to voltage threshold": 0.0,
    "eg. Night consumption": -0.00015043330688853496,
    "eh. Available Energy at Inverter Output (MWh)": 7098.423609204238,
    "fa. Auxiliaries (fans, other)": 0.0,
    "fb. AC ohmic loss": -0.014790218595512975,
    "fc. MV transformer loss": 0.0,
    "fd. MV line ohmic losss": 0.0,
    "fe. Fixed Transmission Loss": -0.0,
    "ff. AC Other": 0,
    "fg. Unused energy (grid limitation)": 0.0,
    "fh. Energy injected into Grid (MWh)": 6993.436372340557,
    "y. Yield (kWh/kWp)": 0.00048515491088459953,
  },
  report: {
    GHISums_kWhm2: [124.5, 126.1, 163.6, 178.5, 187.3, 186.0, 193.4, 186.9, 160.6, 147.4, 122.2, 115.9, 1892.2],
    DHISums_kWhm2: [47.7, 51.7, 63.1, 70.0, 78.7, 79.0, 75.6, 68.5, 62.9, 53.9, 45.2, 44.5, 740.6],
    TAvg_C: [22.05, 21.06, 20.92, 22.61, 21.73, 23.55, 26.36, 24.97, 23.86, 23.78, 24.46, 22.03, 23.11],
    WindAvg_ms: [3.0, 3.2, 5.4, 4.3, 1.8, 6.0, 3.0, 3.1, 3.0, 2.1, 6.4, 6.5, 4.0],
    GlobInc_kwhm2: [160.1, 159.0, 207.8, 226.1, 233.2, 229.0, 243.4, 234.7, 204.6, 190.9, 157.9, 149.4, 2396.0],
    GlobEff_kwhm2: [152.0, 150.8, 197.2, 214.8, 221.4, 217.0, 231.0, 223.4, 194.3, 181.5, 150.1, 142.0, 2275.8],
    EArray_MWh: [545.2, 541.9, 708.3, 770.3, 795.5, 781.1, 829.2, 801.6, 697.2, 649.8, 538.0, 509.2, 8167.2],
    EGrid_MWh: [478.1, 472.4, 605.3, 652.2, 678.2, 664.9, 698.5, 676.3, 595.7, 559.1, 465.5, 447.1, 6993.4],
    PRratio: [0.88, 0.876, 0.859, 0.85, 0.857, 0.856, 0.846, 0.849, 0.858, 0.863, 0.869, 0.882, 0.86],
  },
};

export { weather_data, losses };

// const weather_data = [
//   {
//     month: "1",
//     HorizGlobIrrad: 93.274,
//     HorizDiffIrrad: 25.129,
//     HorizNormIrrad: 176.059,
//     globalIncInColPlane: 30.27,
//     eArray: 291.5,
//     eGrid: 265.5,
//     pressure: 990,
//     Temp: 3.211021505376344,
//     Wind: 2.6370967741935476,
//   },
//   {
//     month: "2",
//     HorizGlobIrrad: 111.38,
//     HorizDiffIrrad: 30.751,
//     HorizNormIrrad: 169.042,
//     globalIncInColPlane: 30.27,
//     eArray: 386.5,
//     eGrid: 350.5,
//     pressure: 990,
//     Temp: 5.260416666666667,
//     Wind: 2.8438988095238082,
//   },
//   {
//     month: "3",
//     HorizGlobIrrad: 167.184,
//     HorizDiffIrrad: 44.582,
//     HorizNormIrrad: 215.296,
//     globalIncInColPlane: 30.27,
//     eArray: 630.5,
//     eGrid: 570.5,
//     pressure: 990,
//     Temp: 8.973118279569892,
//     Wind: 3.4885752688172014,
//   },
//   {
//     month: "4",
//     HorizGlobIrrad: 205.984,
//     HorizDiffIrrad: 56.479,
//     HorizNormIrrad: 227.443,
//     globalIncInColPlane: 30.27,
//     eArray: 742.5,
//     eGrid: 685.5,
//     pressure: 990,
//     Temp: 12.891666666666667,
//     Wind: 3.2072222222222213,
//   },
//   {
//     month: "5",
//     HorizGlobIrrad: 241.789,
//     HorizDiffIrrad: 56.481,
//     HorizNormIrrad: 271.221,
//     globalIncInColPlane: 30.27,
//     eArray: 902.5,
//     eGrid: 835.5,
//     pressure: 990,
//     Temp: 16.692204301075268,
//     Wind: 2.7370967741935464,
//   },
//   {
//     month: "6",
//     HorizGlobIrrad: 260.97,
//     HorizDiffIrrad: 48.068,
//     HorizNormIrrad: 308.904,
//     globalIncInColPlane: 30.27,
//     eArray: 905.5,
//     eGrid: 833.5,
//     pressure: 990,
//     Temp: 24.183333333333334,
//     Wind: 3.280833333333334,
//   },
//   {
//     month: "7",
//     HorizGlobIrrad: 253.594,
//     HorizDiffIrrad: 50.737,
//     HorizNormIrrad: 297.096,
//     globalIncInColPlane: 30.27,
//     eArray: 935.5,
//     eGrid: 865.5,
//     pressure: 990,
//     Temp: 28.743279569892472,
//     Wind: 2.6295698924731195,
//   },
//   {
//     month: "8",
//     HorizGlobIrrad: 230.946,
//     HorizDiffIrrad: 38.873,
//     HorizNormIrrad: 296.582,
//     globalIncInColPlane: 30.27,
//     eArray: 852.5,
//     eGrid: 785.5,
//     pressure: 990,
//     Temp: 27.155913978494624,
//     Wind: 2.6229838709677415,
//   },
//   {
//     month: "9",
//     HorizGlobIrrad: 183.453,
//     HorizDiffIrrad: 39.106,
//     HorizNormIrrad: 241.793,
//     globalIncInColPlane: 30.27,
//     eArray: 666.5,
//     eGrid: 620.5,
//     pressure: 990,
//     Temp: 22.023611111111112,
//     Wind: 2.498750000000004,
//   },
//   {
//     month: "10",
//     HorizGlobIrrad: 149.307,
//     HorizDiffIrrad: 29.794,
//     HorizNormIrrad: 238.088,
//     globalIncInColPlane: 30.27,
//     eArray: 435.5,
//     eGrid: 396.5,
//     pressure: 990,
//     Temp: 14.848118279569892,
//     Wind: 2.254838709677421,
//   },
//   {
//     month: "11",
//     HorizGlobIrrad: 99.337,
//     HorizDiffIrrad: 22.219,
//     HorizNormIrrad: 182.992,
//     globalIncInColPlane: 30.27,
//     eArray: 270.5,
//     eGrid: 240.5,
//     pressure: 990,
//     Temp: 7.301388888888889,
//     Wind: 2.5812499999999967,
//   },
//   {
//     month: "12",
//     HorizGlobIrrad: 81.492,
//     HorizDiffIrrad: 21.433,
//     HorizNormIrrad: 164.602,
//     globalIncInColPlane: 30.27,
//     eArray: 178.5,
//     eGrid: 155.5,
//     pressure: 990,
//     Temp: 1.6021505376344085,
//     Wind: 2.5931451612903245,
//   },
// ];

// export { weather_data };
