import React, { useState, useEffect } from "react";
import { SalesForceCaptureWrap } from "./styles";

import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../Redux";

import { Spinner } from "../../Spinner";

const SalesForceCapture = () => {
  const dispatch = useDispatch();

  // state
  const project_id = useSelector((state) => state.inputs.project_id);
  const loading = useSelector((state) => state.inputs.loading);
  const download_type = useSelector((state) => state.inputs.uiState.download_type);

  const [url, set_url] = useState("");
  const [type, set_type] = useState(download_type);
  const [locked, set_locked] = useState(true);
  const [timer, set_timer] = useState(60);
  const [timer_active, set_timer_active] = useState(false);

  useEffect(() => {
    if (url) {
      set_locked(false);
    } else {
      set_locked(true);
    }
  }, [url]);

  useEffect(() => {
    set_type(download_type);
  }, [download_type]);

  useEffect(() => {
    if (timer_active && timer > 0) {
      setTimeout(() => set_timer(timer - 1), 1000);
    } else if (timer_active && timer == 0) {
      handle_sf_close();
      // bypassing the salesforce submission and unlocking the download button
      dispatch(inputsActions.submit_salesforce_capture(null, null, null, true));
    }
  }, [timer, timer_active]);

  const handle_sf_close = () => {
    set_timer_active(false);
    set_timer(60);
    dispatch(inputsActions.reset_sf_capture());
  };

  const submit_salesforce_url = () => {
    //   // matches just about any url
    // let url_regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    // let regex = new RegExp(url_regex);

    // just removing that blank space that gets added when you paste into an input to avoid any possible errors later.
    let sf_url = url.trimEnd();
    dispatch(inputsActions.submit_salesforce_capture(type, sf_url, project_id));
    set_url("");
  };

  return (
    <SalesForceCaptureWrap>
      <section className="capture-modal">
        {timer_active ? (
          <>
            <section className="capture-text">
              <p>{`Opportunity URLs are required to connect Portal activity to Salesforce activity. If this project has no opportunity, you will need to wait 60 seconds to download.`}</p>
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="https://www.w3.org/2000/svg" onClick={() => handle_sf_close()}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.457 18.9775C4.93418 18.9775 0.457031 14.7725 0.457031 9.58526C0.457031 4.39805 4.93418 0.192993 10.457 0.192993C15.9799 0.192993 20.457 4.39805 20.457 9.58526C20.457 14.7725 15.9799 18.9775 10.457 18.9775ZM10.457 17.2698C14.9757 17.2698 18.6388 13.8293 18.6388 9.58524C18.6388 5.34117 14.9757 1.90066 10.457 1.90066C5.93827 1.90066 2.27515 5.34117 2.27515 9.58524C2.27515 13.8293 5.93827 17.2698 10.457 17.2698ZM7.46338 13.6044L10.4569 10.7928L13.4505 13.6044L14.7361 12.3969L11.7426 9.58532L14.7361 6.77371L13.4505 5.56619L10.4569 8.3778L7.46338 5.56619L6.17773 6.77371L9.17127 9.58532L6.17773 12.3969L7.46338 13.6044Z"
                  fill="#555555"
                />
              </svg>
            </section>
            {!timer_active && (
              <section className="capture-input">
                <input placeholder="Paste URL here" value={url} onChange={(e) => set_url(e.target.value)} />
              </section>
            )}
            <section className="capture-buttons">
              <section className="timer-text">
                <p>{`Download button will unlock in ${timer} seconds...`}</p>
              </section>
              {!timer_active && (
                <section className="the-buttons">
                  <button style={{ cursor: "pointer", visible: timer_active ? "hidden" : "visible" }} onClick={() => set_timer_active(true)}>
                    Bypass
                  </button>
                  <button disabled={locked} className={locked ? "locked" : "unlocked"} onClick={() => submit_salesforce_url()}>
                    {loading ? (
                      <>
                        <Spinner />
                        Download
                      </>
                    ) : (
                      "Download"
                    )}
                  </button>
                </section>
              )}
            </section>
          </>
        ) : (
          <>
            <section className="capture-text">
              <p>{`Copy and paste the URL from this project's Salesforce Opportunity:`}</p>
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="https://www.w3.org/2000/svg" onClick={() => handle_sf_close()}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.457 18.9775C4.93418 18.9775 0.457031 14.7725 0.457031 9.58526C0.457031 4.39805 4.93418 0.192993 10.457 0.192993C15.9799 0.192993 20.457 4.39805 20.457 9.58526C20.457 14.7725 15.9799 18.9775 10.457 18.9775ZM10.457 17.2698C14.9757 17.2698 18.6388 13.8293 18.6388 9.58524C18.6388 5.34117 14.9757 1.90066 10.457 1.90066C5.93827 1.90066 2.27515 5.34117 2.27515 9.58524C2.27515 13.8293 5.93827 17.2698 10.457 17.2698ZM7.46338 13.6044L10.4569 10.7928L13.4505 13.6044L14.7361 12.3969L11.7426 9.58532L14.7361 6.77371L13.4505 5.56619L10.4569 8.3778L7.46338 5.56619L6.17773 6.77371L9.17127 9.58532L6.17773 12.3969L7.46338 13.6044Z"
                  fill="#555555"
                />
              </svg>
            </section>
            <section className="capture-input">
              <input placeholder="Paste URL here" value={url} onChange={(e) => set_url(e.target.value)} />
            </section>
            <section className="capture-buttons">
              <section className="the-buttons">
                <button style={{ cursor: "pointer" }} onClick={() => set_timer_active(true)}>
                  Bypass
                </button>
                <button disabled={locked} className={locked ? "locked" : "unlocked"} onClick={() => submit_salesforce_url()}>
                  {loading ? (
                    <>
                      <Spinner />
                      Download
                    </>
                  ) : (
                    "Download"
                  )}
                </button>
              </section>
            </section>
          </>
        )}
      </section>
    </SalesForceCaptureWrap>
  );
};

export { SalesForceCapture };
