export const accountConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PWRESET_REQUEST: 'PWRESET_REQUEST',
    PWRESET_SUCCESS: 'PWRESET_SUCCESS',
    PWRESET_FAILURE: 'PWRESET_FAILURE',    
    
    CONTINUE_LOGIN: 'USERS_CONTINUE_LOGIN',

    REFRESH_TOKEN_REQUEST: 'USERS_REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_SUCCESS: 'USERS_REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE: 'USERS_REFRESH_TOKEN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    GETALL_COMPLETE: 'USERS_GETALL_COMPLETE',

    GETPROFILE_REQUEST: 'USERS_GETPROFILE_REQUEST',
    GETPROFILE_SUCCESS: 'USERS_GETPROFILE_SUCCESS',
    GETPROFILE_FAILURE: 'USERS_GETPROFILE_FAILURE', 

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CAPACITY_TOOL_REQUEST: 'USERS_CAPACITY_TOOL_REQUEST',
    CAPACITY_TOOL_SUCCESS: 'USERS_CAPACITY_TOOL_SUCCESS',
    CAPACITY_TOOL_FAILURE: 'USERS_CAPACITY_TOOL_FAILURE',

    CAPACITY_RESULTS_REQUEST: 'USERS_CAPACITY_RESULTS_REQUEST',
    CAPACITY_RESULTS_UPDATE: 'USERS_CAPACITY_RESULTS_UPDATE',
    CAPACITY_RESULTS_SUCCESS: 'USERS_CAPACITY_RESULTS_SUCCESS',
    CAPACITY_RESULTS_FAILURE: 'USERS_CAPACITY_RESULTS_FAILURE',

    CAPACITY_RR_REQUEST: 'USERS_CAPACITY_RR_REQUEST',
    CAPACITY_RR_SUCCESS: 'USERS_CAPACITY_RR_SUCCESS',
    CAPACITY_RR_FAILURE: 'USERS_CAPACITY_RR_FAILURE',    

    SIFT_FILE_REQUEST: 'USERS_SIFT_FILE_REQUEST',
    SIFT_FILE_SUCCESS: 'USERS_SIFT_FILE_SUCCESS',
    SIFT_FILE_FAILURE: 'USERS_SIFT_FILE_FAILURE',     
    SIFT_FILE_CLEAR: 'USERS_SIFT_FILE_CLEAR',

    DASH_VALIDATEINPUTS_REQUEST: 'USERS_DASH_VALIDATEINPUTS_REQUEST',
    DASH_VALIDATEINPUTS_SUCCESS: 'USERS_DASH_VALIDATEINPUTS_SUCCESS',
    DASH_VALIDATEINPUTS_FAILURE: 'USERS_DASH_VALIDATEINPUTS_FAILURE',  

    DASH_LOADPROJECTS_REQUEST: 'USERS_DASH_LOADPROJECTS_REQUEST',
    DASH_LOADPROJECTS_SUCCESS: 'USERS_DASH_LOADPROJECTS_SUCCESS',
    DASH_LOADPROJECTS_FAILURE: 'USERS_DASH_LOADPROJECTS_FAILURE',   
    
    DASH_LOADPROJECT: 'USERS_DASH_LOADPROJECT',

    DASH_CANCELSIFT: 'USERS_DASH_CANCELSIFT',

    DASH_SAVEPROJECT_REQUEST: 'USERS_DASH_SAVEPROJECT_REQUEST',
    DASH_SAVEPROJECT_SUCCESS: 'USERS_DASH_SAVEPROJECT_SUCCESS',
    DASH_SAVEPROJECT_FAILURE: 'USERS_DASH_SAVEPROJECT_FAILURE',    

    DASH_SAVEMODULE_REQUEST: 'USERS_DASH_SAVEMODULE_REQUEST',
    DASH_SAVEMODULE_SUCCESS: 'USERS_DASH_SAVEMODULE_SUCCESS',
    DASH_SAVEMODULE_FAILURE: 'USERS_DASH_SAVEMODULE_FAILURE',    
    
    DASH_SAVEINVERTER_REQUEST: 'USERS_DASH_SAVEINVERTER_REQUEST',
    DASH_SAVEINVERTER_SUCCESS: 'USERS_DASH_SAVEINVERTER_SUCCESS',
    DASH_SAVEINVERTER_FAILURE: 'USERS_DASH_SAVEINVERTER_FAILURE',      
    
    DASH_SAVERACKING_REQUEST: 'USERS_DASH_SAVERACKING_REQUEST',
    DASH_SAVERACKING_SUCCESS: 'USERS_DASH_SAVERACKING_SUCCESS',
    DASH_SAVERACKING_FAILURE: 'USERS_DASH_SAVERACKING_FAILURE',   

    DASH_SAVEPERFORMANCE_REQUEST: 'USERS_DASH_SAVEPERFORMANCE_REQUEST',
    DASH_SAVEPERFORMANCE_SUCCESS: 'USERS_DASH_SAVEPERFORMANCE_SUCCESS',
    DASH_SAVEPERFORMANCE_FAILURE: 'USERS_DASH_SAVEPERFORMANCE_FAILURE',   

    DASH_SAVEFINANCE_REQUEST: 'USERS_DASH_SAVEFINANCE_REQUEST',
    DASH_SAVEFINANCE_SUCCESS: 'USERS_DASH_SAVEFINANCE_SUCCESS',
    DASH_SAVEFINANCE_FAILURE: 'USERS_DASH_SAVEFINANCE_FAILURE',   

    DASH_SAVEWEATHER_REQUEST: 'USERS_DASH_SAVEWEATHER_REQUEST',
    DASH_SAVEWEATHER_SUCCESS: 'USERS_DASH_SAVEWEATHER_SUCCESS',
    DASH_SAVEWEATHER_FAILURE: 'USERS_DASH_SAVEWEATHER_FAILURE',    

    DASH_RUNWEATHER_REQUEST: 'USERS_DASH_RUNWEATHER_REQUEST',
    DASH_RUNWEATHER_SUCCESS: 'USERS_DASH_RUNWEATHER_SUCCESS',
    DASH_RUNWEATHER_FAILURE: 'USERS_DASH_RUNWEATHER_FAILURE',   

    DASH_GETWEATHER_REQUEST: 'USERS_DASH_GETWEATHER_REQUEST',
    DASH_GETWEATHER_SUCCESS: 'USERS_DASH_GETWEATHER_SUCCESS',
    DASH_GETWEATHER_FAILURE: 'USERS_DASH_GETWEATHER_FAILURE',   

    DASH_GETTOPO_REQUEST: 'USERS_DASH_GETTOPO_REQUEST',
    DASH_GETTOPO_SUCCESS: 'USERS_DASH_GETTOPO_SUCCESS',
    DASH_GETTOPO_FAILURE: 'USERS_DASH_GETTOPO_FAILURE',      
    
    DASH_CHECKTOPO_REQUEST: 'USERS_DASH_CHECKTOPO_REQUEST',
    DASH_CHECKTOPO_SUCCESS: 'USERS_DASH_CHECKTOPO_SUCCESS',
    DASH_CHECKTOPO_FAILURE: 'USERS_DASH_CHECKTOPO_FAILURE',   

    DASH_CREATEDXF_REQUEST: 'USERS_DASH_CREATEDXF_REQUEST',
    DASH_CREATEDXF_SUCCESS: 'USERS_DASH_CREATEDXF_SUCCESS',
    DASH_CREATEDXF_FAILURE: 'USERS_DASH_CREATEDXF_FAILURE',   
    DASH_CREATEDXF_CLEAR: 'USERS_DASH_CREATEDXF_CLEAR',   

    DASH_CLEAR_UPLOAD_ERRORS: 'USERS_DASH_CLEAR_UPLOAD_ERRORS',
    DASH_CLEARAUTOLAYOUT_CLEAR: 'USERRS_DASH_CLEARAUTOLAYOUT_CLEAR',

    ADDPAYMENT_REQUEST: 'USERS_ADDPAYMENT_REQUEST',
    ADDPAYMENT_SUCCESS: 'USERS_ADDPAYMENT_SUCCESS',
    ADDPAYMENT_FAILURE: 'USERS_ADDPAYMENT_FAILURE',

    UPDATESUB_REQUEST: 'USERS_UPDATESUB_REQUEST',
    UPDATESUB_SUCCESS: 'USERS_UPDATESUB_SUCCESS',
    UPDATESUB_FAILURE: 'USERS_UPDATESUB_FAILURE',

    IMPORTDATA_REQUEST: 'USERS_IMPORTDATA_REQUEST',
    IMPORTDATA_SUCCESS: 'USERS_IMPORTDATA_SUCCESS',
    IMPORTDATA_FAILURE: 'USERS_IMPORTDATA_FAILURE',


    LOGIN_VISIBILITY_TOGGLE: 'LOGIN_VISIBILITY_TOGGLE',
    FUNNEL_VISIBILITY_TOGGLE: 'FUNNEL_VISIBILITY_TOGGLE',

    GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
    GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
    GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

};
