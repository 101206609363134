import React, { useEffect, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";

import { canopyActions } from "../../../Redux";

import { pick, module_input_keys, create_canopy, paste_canopy } from "../CanopyHelpers";

import { CanopyEventHandler } from "./CanopyEventHandler.js";
import { AzimuthLayer } from "./AzimuthLayer.js";
import { ModulesLayer } from "./ModulesLayer";
import { CanopyNames } from "./CanopyNames";

import { message } from "antd";

import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const CanopyLayer = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const canopies = useSelector((state) => state.canopy.canopies);
  const inputs = useSelector((state) => state.inputs);
  const is_drawing = useSelector((state) => state.canopy.is_drawing);
  const deleted_canopy_id = useSelector((state) => state.canopy.deleted_canopy_id);
  const copy_canopy_id = useSelector((state) => state.canopy.copy_canopy_id);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);

  const selected_module_ref = useRef();
  const is_drawing_ref = useRef(is_drawing);
  const copy_canopy_id_ref = useRef();
  const canopies_ref = useRef(canopies);
  const selected_canopy_ref = useRef(selected_canopy);

  useEffect(() => {
    selected_canopy_ref.current = selected_canopy;
  }, [selected_canopy]);

  useEffect(() => {
    if (deleted_canopy_id) {
      if (deleted_canopy_id === "delete_all") {
        _removeAllCanopies();
      } else {
        map.eachLayer((layer) => {
          if ((layer.options && layer.options?.id == deleted_canopy_id) || layer.options?.module_layer_id == deleted_canopy_id || layer.options?.azi_layer_id == deleted_canopy_id) layer.remove();
        });
      }
    }
  }, [deleted_canopy_id]);

  useEffect(() => {
    canopies_ref.current = canopies;
    if (Object.values(canopies_ref.current).length === 0) {
      _removeAllCanopies();
    }
    // setTimeout(() => {
    //   let center = getSiteCenter(canopies_ref.current);
    //   console.log("updated center", center);
    // }, 1000);
  }, [canopies]);

  useEffect(() => {
    is_drawing_ref.current = is_drawing;
  }, [is_drawing]);

  useEffect(() => {
    copy_canopy_id_ref.current = copy_canopy_id;
    if (copy_canopy_id) {
      message.success("Click Map to paste Canopy", 3);
    }
  }, [copy_canopy_id]);

  useEffect(() => {
    selected_module_ref.current = pick(inputs, module_input_keys);
  }, [inputs]);

  useEffect(() => {
    if (!map) return;

    map.on("click", (e) => {
      if (is_drawing_ref.current) {
        _onCreateCanopy(e.latlng, selected_module_ref.current);
      }

      if (copy_canopy_id_ref.current) {
        let currently_selected_canopy = canopies_ref.current[copy_canopy_id_ref.current];
        let pasted_canopy = paste_canopy(e.latlng, currently_selected_canopy);
        dispatch(canopyActions.updateCanopyInputs("add_canopy", pasted_canopy));
      }

      if (!is_drawing_ref.current && selected_canopy_ref.current) {
        dispatch(canopyActions.updateCanopyInputs("selected_canopy", undefined));
      }
    });

    map.on("draw:canceled", (e) => {
      console.log("canceled drawing");
    });

    return () => {
      map.off("click");
      map.off("draw:canceled");
    };
  }, []);

  const _removeAllCanopies = () => {
    map.eachLayer((layer) => ((layer.options.obj_type !== "shade" && layer.options.shape === "Rectangle") || layer.feature) && layer.remove());
  };

  const _onCreateCanopy = (origin, selected_module, completeFromHotkey = false) => {
    if (!origin) return;
    // const canopy = handleCanopyCreated(layer, selected_module, completeFromHotkey);
    const canopy = create_canopy(origin, selected_module, completeFromHotkey);
    dispatch(canopyActions.updateCanopyInputs("add_canopy", canopy));
  };

  return (
    <>
      {Object.values(canopies).map((canopy) => (
        <Fragment key={canopy.id}>
          <CanopyEventHandler key={canopy.id} id={canopy.id} />
          <AzimuthLayer key={`${canopy.id}_azi`} id={canopy.id} />
          <ModulesLayer key={`${canopy.id}_modules`} id={canopy.id} />
          <CanopyNames key={`${canopy.id}names`} id={canopy.id} />
        </Fragment>
      ))}
    </>
  );
};

export { CanopyLayer };
