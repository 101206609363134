import { authHeader } from "../_helpers";
import { siftConstants } from "../_constants";
import config from "config";
import { canopyServices } from "../_services";
import fetchUtil from "../_services/fetchUtil";

const loopLimit = 700;

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export async function execute_sift_run(inputs) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...inputs }),
    // body: JSON.stringify({ ...inputs, job_type: type }),
  };
  return fetch(`${inputs.url}`, requestOptions);
}

export async function execute_job(inputs = undefined, type) {
  let job_inputs = type === "export-job" ? { export_type: inputs.export_type, job_type: type } : type === "weather-fetch" ? { job_type: type } : { ...inputs, job_type: type };
  console.log(job_inputs)
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify({ ...job_inputs }),
    // body: job_inputs,
    body: JSON.stringify({ ...job_inputs }),
  };
  return fetchUtil(`${config.apiUrl}/dash/jobs/`, requestOptions);
  // return fetch(`${config.apiUrl}/dash/jobs/`, requestOptions);
}

export async function exe_job(inputs, type) {
  const requestOptions = {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  };
  return fetchUtil(`${config.apiUrl}/dash/environShade/`, requestOptions);
}

export async function poll_job(job_inputs, type, dispatch) {
  let loopBool = true;
  let loopCount = 0;
  let sleep_amt = 1000;
  let results = { error: false, errors: [] };
  // polling loop
  while (loopBool) {
    // poll request to backend
    let job_poll;
    if (type === "env-shade") {
      job_poll = await canopyServices._getShadeResults(job_inputs.job_id);
    } else if (type === "sift-import") {
      job_poll = await get_hori_shade_job_status(job_inputs.job_id);
    } else {
      job_poll = await get_job_status(job_inputs.job_id);
    }

    if (type === "sift-init") {
      // updates ui state for run layout complete and modeling complete
      dispatch({ type: siftConstants.GENERATE_RESULTS_UPDATE, job_poll });
    }

    // console.log("job_poll", job_poll);
    if (job_poll.status == 100) {
      // JOB HAS COMPLETED
      loopBool = false;
      if (job_poll.count == 0 && type === "sift-init") {
        results.error = { msg: "No Results Returned" };
        return results;
      }
      //
      results.output = job_poll;
      if (type === "sift-init") {
        results.output.results = await download_from_s3(job_poll["results"]);
      }

      if (type === "export-job") {
        await download_from_s3_to_pc(job_poll["url"], job_inputs.meta.file_name);
      }

      if (type === "weather-fetch") {
        results.output.job_output = await download_from_s3(job_poll["url"]);
      }

      if (type === "env-shade") {
        results.output.job_output = job_poll;
      }

      if (type === "sift-import") {
        results.output.results = await download_from_s3(job_poll["url"]);
      }

      results.meta = job_inputs;
      return results;
    } else if (job_poll.status == 50) {
      // CANCEL
      loopBool = false;
      results.error = { msg: "Cancelled" };
      return results;
    } else if (type !== "env-shade" && type !== "sift-import" && job_poll.status == 97) {
      // ERROR

      loopBool = false;
      // let ret_errors_details = job_poll["error"]["errors"];
      let ret_error = job_poll["error"]["error"];
      results.errors = ret_error;
      results.err_details = ret_error;
      results.is_error = true;

      if (type === "weather-fetch" && job_poll.url == null) {
        results.error = true;
        results.errors.push(
          "An error has occurred while fetching weather data. Try requesting data from another weather source. If the problem persists, consider submitting a bug report to the SIFT team."
        );
      }

      if (type === "export-job" && job_poll.url == null) {
        results.error = true;
        results.errors.push("An error has occurred while exporting your data. Check your inputs and try exporting again. If the problem persists, consider submitting a bug report to the SIFT team.");
      }

      if (type !== "export-job" && results.errors.length === 0) {
        if (!results.errors) {
          results.errors = [];
        }
        results.errors.push(job_poll["error"]["error"] || "Error Running Project");
      }

      if (type !== "weather-fetch" && type !== "export-job") {
        if (!results.errors) {
          results.errors = [];
        }
        results.error = { msg: job_poll["error"]["error"] || "Error Running Project" };
      }

      return results;
    } else if (type === "sift-import" && job_poll.status === 97) {
      loopBool = false;
      let ret_error = ["There is no Horizon Shading Data available for this location."];
      results.error = true;
      results.errors = ret_error;
      results.err_details = ret_error;
      results.is_error = true;
      return results;
    } else if (loopCount > loopLimit && type == "sift-init") {
      loopBool = false;
      // TIMEOUT
      // // offer user to continue (reset loop count) or cancel
      // const { openModal: openModalWithState, renderModal: renderModalWithState } = useAwaitableModal({});
      // const time_out_resp = await openModalWithState()
      // console.log(time_out_resp)
      results.error = { msg: ["This SIFT run has timed-out. Check your inputs and try again. If the problem persists, consider submitting a bug report to the SIFT team."] };
      return results;
    } else {
      // wait a cool 1000ms then loop again
      await sleep(sleep_amt);
      sleep_amt += 100;
      loopCount += 1;
    }
  }
}

const cleanError = (errors) => {
  const jsonErrArr = JSON.parse(errors);
  const cleanedErrors = [];

  for (const item of jsonErrArr) {
    const cleanedString = JSON.parse(item);
    cleanedErrors.push(cleanedString);
  }

  // console.log("cleanedErrors", cleanedErrors);
  return cleanedErrors;
};

export async function download_from_s3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  // return fetchUtil(url, requestOptions,false);
  return fetch(url, requestOptions).then((response) => response.json());
}

export function download_from_s3_to_pc(url, file_name) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Disposition": "attachment" },
  };
  return fetch(url, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = file_name;
      document.body.appendChild(a); // have to do this for firefox or it doesn't work
      a.click();
      a.remove();
    });

  // .then((res) => res.blob())
  // .then((blob) => {
  //   let file = window.URL.createObjectURL(blob);
  //   window.location.assign(file);
  // });
}

export function upload_to_s3(url, data) {
  const requestOptions = {
    method: "PUT",
    // headers: { "Content-Type": "application/json" },
    // headers: { 'Content-Type': type },
    // body: JSON.stringify(data),
    body: data,
  };
  console.log(data)
  // return fetchUtil(url, requestOptions, false);
  return fetch(url, requestOptions);
}

async function get_job_status(job_id) {
  const requestOptions = {
    method: "GET",
    // headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetchUtil(`${config.apiUrl}/dash/jobs/?job_id=${job_id}`, requestOptions).then((response) => response);
}

async function get_hori_shade_job_status(job_id) {
  const requestOptions = {
    method: "GET",
    // headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetchUtil(`${config.apiUrl}/dash/import/?runId=${job_id}`, requestOptions).then((response) => response);
}
