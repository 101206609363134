import { authHeader } from "../_helpers";
import config from "config";
import fetchUtil from "./fetchUtil";
export const canopyServices = {
  _initShade,
  _uploadShadeInputs,
  _getShadeResults,
  _getShadeGeoJson,
};

async function _initShade() {
  let response = await fetchUtil(`${config.apiUrl}/dash/environShade/`, {
    method: "POST",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      file_type: "environ",
    }),
  });

  // let response = await fetch(`https://api.sunfig.solar/dev/dash/environShade/`, {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify({
  //     file_type: "environ",
  //   }),
  // });
  return await response;
}

async function _uploadShadeInputs(inputs, url) {
  let response = await fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  });
  return response;
}

async function _getShadeResults(id) {
  let response = await fetchUtil(`${config.apiUrl}/dash/environShade?run_id=${id}`, {
    method: "GET",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  // let response = await fetch(` https://api.sunfig.solar/dev/dash/environShade/?run_id=${id}`, {
  //   method: "GET",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  // });

  return await response;
}

async function _getShadeGeoJson(url) {
  let response = await fetch(url, {
    method: "GET",
    // headers: { "Content-Type": "application/json" },
  });

  return await response.json();
}
