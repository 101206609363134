import React from "react";

// styles
import "./sift.css";
import "./buttons.css";

import { Button, Col, Row, Typography } from "antd";
import { SIFT_Button } from "./Button";

// temp popup for the rebranding
import { NewsPopup } from "./Announcement/NewsPopup";
// images
import siftBanner from "../_images/SIFTBanner.png";
import siftScreencap from "../_images/sift_screencap.png";
import topoScreencap from "../_images/topo_screencap.png";

import icon_1 from "../_images/stat-trophy.png";
import icon_2 from "../_images/stat-cash.png";
import icon_3 from "../_images/stat-timehand.png";

import successSift from "../_images/imageSift.png";

const Sift = (props) => {
  return (
    <section id="sift" className="sift">
      <div className="sift-banner">
        <img src={siftBanner} alt="Banner that has a screencap of our SIFT application as the background" />
      </div>

      <div className="sift-header-text">
        <p>The Sunfig Instant Feasibility Tool (SIFT) allows you to quickly determine the optimum design for your projects.</p>
        <p>We understand how frustrating it is to commit to a design strategy in a hurry. You know that each site has a most valuable design but you don’t have the time, tools, or data to find it.</p>
        <p>SIFT produces the data you need to be confident in your design decisions, win more projects, and become a leader in the solar energy transition.</p>
      </div>
      <div className="sift-features">
        <div className="sift-bullet-points">
          <h2>SIFT Features</h2>
          <ul>
            <li>Projects up to 1GW</li>
            <li>No software to install, SIFT runs from your browser</li>
            <li>Import topography from USGS and Google</li>
            <li>Run up to 1000 GCR and DC:AC configurations at once</li>
            <li>Sunfig Performance Modeling comparable to PVSyst</li>
            <li>LCOE and IRR lifetime financial modeling</li>
            <li>Import modules and inverters from PAN, OND, PVSyst</li>
            <li>Import/Export KMZ</li>
            <li>Export data direct to XLS and CAD</li>
            <li>Ground Fixed Tilt and Single Axis Tracker</li>
            <li>Runs typically take less than 1 minute to complete</li>
          </ul>
        </div>
        <div className="sift-thumbnails">
          <div className="sift-thumbnails-box">
            <img src={siftScreencap} alt="screencap of our SIFT application in action" />
            <p>Generate layouts, performance, and financial models. Download data to Excel & CAD</p>
          </div>
          <div className="sift-thumbnails-box">
            <img src={topoScreencap} alt="screencap of our SIFT application with Topography enabled" />
            <p>Import topography and generate slope and grade analyses</p>
          </div>
          <div className="sift-try-btn">
            <SIFT_Button type="ghost" className="default" onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>
              Try SIFT for FREE
            </SIFT_Button>
          </div>
        </div>
      </div>

      <div className="sift-points">
        <div className="point">
          <div className="point-header-text">
            <ul style={{ listStyleType: "none" }}>
              <li>
                <img src={icon_1} alt="Large gold trophy"></img>
              </li>
              <li className="big">1000x+</li>
              <li>Choose from thousands more project options</li>
            </ul>
          </div>
        </div>

        <div className="point">
          <div className="point-header-text">
            <ul style={{ listStyleType: "none" }}>
              <li>
                <img src={icon_2} alt="dollar bill with an accending arrow above it"></img>
              </li>
              <li className="big">+5-15%</li>
              <li>Higher financial returns on projects</li>
            </ul>
          </div>
        </div>

        <div className="point">
          <div className="point-header-text">
            <ul style={{ listStyleType: "none" }}>
              <li>
                <img src={icon_3} alt="a hand cradling a clock"></img>
              </li>
              <li className="big">90%</li>
              <li>Less time on analysis, more time for development and sales</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="sift-footer-btn">
        <SIFT_Button className="home-button" onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>
          Sign Up Now
        </SIFT_Button>
      </div>

      <div className="sift-footer-text">
        <h3>Watch the video below to see how in just a few minutes you can find the most valuable projects and configurations with SIFT.</h3>
      </div>

      <div className="sift-tutorial">
        <div className="sift-tutorial-video">
          {/* <iframe
            style={{ border: 0 }}
            title="Sift Tutorial"
            src="https://www.youtube.com/embed/xjCXQZlF0k8"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
          <iframe
            style={{ border: 0 }}
            src="https://www.youtube.com/embed/00zeb1kONCo"
            title="Sunfig Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="siftIntro-try row-8">
        <div className="siftIntro-try-text">
          <div style={{ textAlign: "center" }}>
            <h2>Getting Started is Easy</h2>
          </div>
          <div className="siftInto-sift-list">
            <img src={successSift} alt="screencap of SIFT in action" />
            <ol>
              <li>Sign up for SIFT and start your free trial</li>
              <li>Schedule a demo</li>
              <li>Get coaching through your first project</li>
              <li>See immediate and actionable results</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="foot row-9">Complimentary training and support. Nothing to install. Cancel any time.</div>

      <div className="sift-footer-bottom-buttons">
        <SIFT_Button SIFT_Button onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>
          Sign Up Now
        </SIFT_Button>
        <SIFT_Button SIFT_Button onClick={() => window.open("https://calendly.com/siftdemo/demo", "_blank", "noopener,noreferrer")}>
          Schedule a Demo
        </SIFT_Button>
      </div>

      <NewsPopup />

      {/* </div>
        <div className="sift-points">
          <div className="point">
            <div className="point-header-text">
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <img src={icon_1}></img>
                </li>
                <li className="big">1000x+</li>
                <li>Evaluate thousands more project options</li>
              </ul>
            </div>
          </div>

          <div className="point">
            <div className="point-header-text">
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <img src={icon_2}></img>
                </li>
                <li className="big">+5-15%</li>
                <li>Higher financial returns on projects</li>
              </ul>
            </div>
          </div>

          <div className="point">
            <div className="point-header-text">
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <img src={icon_3}></img>
                </li>
                <li className="big">90%</li>
                <li>Less time on early stage analysis and more time for development and sales</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sift-footer-btn">
          <Button className="home-button" type="primary">
            Sign Up Now
          </Button>
        </div>

        <div className="sift-footer-text">
          <h3 className="sift-header-text">
            Watch the video below to see how in just a few minutes you can find the most valuable projects and
            configurations with SIFT.
          </h3>
        </div>

        <div className="sift-tutorial">
          <div className="sift-tutorial-video">
            <iframe
              title="Sift Tutorial"
              src="https://www.youtube.com/embed/3GblvfnkI2o"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="sift-get-started">
          <div className="split">
            <div className="left">
              <img src={successSift} className="img"></img>
            </div>

            <div className="right">
              <h2>Getting Started is Easy</h2>
              <ol>
                <li>Sign up for SIFT and start a free trial</li>
                <li>Schedule a free 1-on-1 demo session</li>
                <li>Work through your project together with Sunfig</li>
                <li>See immediate and actionable results</li>
              </ol>
            </div>
          </div>

          <div className="foot">Complimentary training and support. Nothing to install. Cancel any time.</div>

          <div className="center">
            <Button type="primary" href="/">
              Sign Up Now
            </Button>
            <Button type="primary" href="https://calendly.com/siftdemo/demo">
              Schedule a Demo
            </Button>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Sift;
