import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MapContainer, useMap } from "react-leaflet";
import { MapZoomHandler, MapEventHandler } from "../Map/components";
import { LayoutEditLegend } from "./LayoutEditLegend";

const LayoutEditorMap = ({ children }) => {
  const map_center = useSelector((state) => state.inputs.map_center);

  return (
    <MapContainer
      id="editor-map"
      className="editor-map"
      key="editor-map"
      center={map_center}
      zoom={16}
      zoomSnap={0.15}
      animate={true}
      doubleClickZoom={false}
      zoomControl={false}
      worldCopyJump={true}
      attributionControl={false}
      minZoom={2}
      maxZoom={20}
    >
      <MapEventHandler />
      <MapZoomHandler position="topright" />
      <LayoutEditLegend position="topleft" />
      {children}
    </MapContainer>
  );
};

export { LayoutEditorMap };
