import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { canopyActions } from "../../../Redux/index.js";

import { usePrevious } from "../../../Redux/index.js";

import "./leaflet.toolbar.css";
import "leaflet-toolbar";
// import "leaflet-distortableimage";
import "./leaflet.distortableimage.js";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-distortableimage/dist/vendor.js";

const ImageLayer = ({ is_interactive = true, pathname }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const images = useSelector((state) => state.canopy.images);
  const prev_images = usePrevious(images);

  useEffect(() => {
    if (prev_images && Object.values(prev_images).length > 0 && Object.values(images).length == 0) {
      map.eachLayer((layer) => layer.options.layer_type && layer.options.layer_type == "imported_image" && layer.remove());
    }
  }, [images, prev_images]);

  useEffect(() => {
    map.on("image_was_deleted", (e) => dispatch(canopyActions.updateImportedImages("delete_image", e.image_id)));
    return () => {
      map.off("image_was_deleted");
    };
  }, []);

  return (
    <>
      {images &&
        Object.values(images)?.length > 0 &&
        Object.values(images).map((image) => image && pathname == image.platform_pathname && <ImageEventHandler key={image.imageId} imageId={image.imageId} is_interactive={is_interactive} />)}
    </>
  );
};

const ImageEventHandler = ({ imageId, is_interactive = true }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const image = useSelector((state) => state.canopy.images[imageId]);
  const selectedImageId = useSelector((state) => state.canopy.selectedImageId);
  const [deselect, set_deselect] = useState(false);

  const [cur_selected, set_cur_selected] = useState(undefined);

  useMapEvents({
    disableMapHotkeys: (e) => set_deselect(e.disable),
  });

  useEffect(() => {
    if (deselect) {
      deselect_images();
    }
  }, [deselect]);

  const deselect_images = () => {
    map.eachLayer((layer) => layer.options.imageId && layer.options.imageId == image.imageId && layer.deselect());
  };

  const mapHasFocus = () => {
    return document.getElementById("map") == document.activeElement;
  };

  useEffect(() => {
    if (!image) return;

    // map.eachLayer((layer) => {
    //   if (layer.options.imageId) {
    //     layer.remove();
    //   }
    // });

    let img = new L.distortableImageOverlay(image.imgSrc, {
      imageId,
      selected: is_interactive && selectedImageId == image.imageId,
      editable: is_interactive,
      corners: image.corners ? image.corners : undefined,
      opacity: image.opacity ? image.opacity : 1,
      supresstoolbar: !is_interactive && deselect,
      platform_pathname: image.platform_pathname ? image.platform_pathname : undefined,
      // mode: image.mode ? image.mode : "scale",
      mode: selectedImageId == image.imageId ? image.mode : undefined,
      layer_type: "imported_image",
      actions: [L.DragAction, L.ScaleAction, L.DistortAction, L.FreeRotateAction, L.RotateAction, L.OpacityAction, L.LockAction, L.DeleteAction],
    });

    img.addTo(map);

    img.on("edit", (e) => {
      set_cur_selected(e.target.options.imageId);
      if (e.type == "dragend") return;
      let corners = e.target.getCorners();
      let rotation = e.target.getAngle();
      let mode = e.target.editing._mode;
      console.log("e", e);
      dispatch(canopyActions.updateImportedImages("edit_image", { corners, rotation, mode }, imageId));
      e.target.remove();
      // setTimeout(() => {}, 200);
    });

    img.on("opacity_toggled", (e) => {
      // console.log("e.opacity", e.opacity);
      dispatch(canopyActions.updateImportedImages("edit_image", { opacity: e.opacity }, e.imageId));
      e.target.remove();
      // setTimeout(() => {}, 200);
    });

    img.on("dragend", (e) => {
      let corners = e.target.getCorners();
      let mode = e.target.editing._mode;
      dispatch(canopyActions.updateImportedImages("edit_image", { corners, mode }, imageId));
      e.target.remove();
      // setTimeout(() => {}, 200);
    });

    return () => {
      img.off("edit");
      img.off("dragend");
      img.off("opacity_toggled");
      map.removeLayer(img);
    };
  }, [image]);

  return <></>;
};

const LiveReportImageLayer = ({ map = undefined, platform = undefined }) => {
  const images = useSelector((state) => state.canopy.images);

  useEffect(() => {
    if (Object.values(images).length == 0 || !map) return;

    Object.values(images).map((image) => {
      if (image.platform_pathname == platform) {
        let img = new L.distortableImageOverlay(image.imgSrc, {
          imageOd: image.imageId,
          selected: false,
          editable: false,
          corners: image.corners ? image.corners : undefined,
          opacity: image.opacity ? image.opacity : 1,
          supresstoolbar: true,
          platform_pathname: image.platform_pathname ? image.platform_pathname : undefined,
        });

        img.addTo(map);
      }
    });
  }, [map]);
};

export { ImageLayer, ImageEventHandler, LiveReportImageLayer };
