import React, { useState, useEffect, useRef, Fragment } from "react";
import { MapContainer, LayerGroup, GeoJSON } from "react-leaflet";

//styles
import { ReportMapWrap } from "./styles";

// redux
import { useSelector } from "react-redux";

// components
import { MapTileLayer, MapZoomHandler, MapEventHandler } from "../../../Map/components";
// helpers
import { getBounds } from "../../../Redux";
import { ImageLayer, LiveReportImageLayer } from "../../../Map/components/ImageLayer";

const LiveThirtyPercentMap = ({ setCanopyMapRefs }) => {
  const canopies = useSelector((state) => state.canopy.canopies);
  const mapCenter = useSelector((state) => state.inputs.map_center);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [map, setMap] = useState(null);

  const thirtyPercentLayoutMap = useRef();

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  useEffect(() => {
    if (!map) return;
    setCanopyMapRefs("thirtyPercentLayoutMap", map);
    setTimeout(() => {
      handleThirtyPercentZoomExtents();
    }, 500);
  }, [map]);

  const handleThirtyPercentMapZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleThirtyPercentZoomExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(canopies).length == 0) return;
    // define canopy feature array
    let allCanopyFeatures = [];
    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(canopies).map((canopy) => {
      allCanopyFeatures.push(canopy.geoJson);
    });

    // get the bounds of the features collection
    let bounds = getBounds(allCanopyFeatures);

    if (zoomLevel) {
      map.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      map.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <MapContainer
        whenReady={(map) => setMap(map.target)}
        id="thirtyPercentLayoutMap"
        className="thirtyPercentLayoutMap"
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={mapCenter}
        zoom={zoom}
        minZoom={2}
        maxZoom={20}
        onzoomend={() => handleThirtyPercentMapZoom(map.getZoom())}
        zoomControl={false}
        zoomSnap={0.25}
        zoomDelta={0.25}
        style={{ width: "100%", height: "100%" }}
      >
        <MapTileLayer liveReportMap={true} />
        <LiveReportImageLayer platform={"/canopy"} map={map} />
        <MapZoomHandler position="topright" />
        <MapEventHandler />

        {Object.values(canopies).length > 0 && (
          <LayerGroup>
            {Object.values(canopies).map((canopy) => {
              return (
                <Fragment key={`frag_${canopy.id}`}>
                  {Object.values(canopy.geoJson).length > 0 && (
                    <GeoJSON
                      shape="Rectangle"
                      style={{
                        color: "#60de4f",
                        fillColor: "#60de4f",
                        fillOpacity: 0.2,
                        interactive: false,
                      }}
                      transform={false}
                      draggable={false}
                      scaling={false}
                      data={canopy.geoJson}
                      key={`cell_id_(${canopy.id})`}
                    />
                  )}
                  {canopy.editCellsGeoJson.length > 0 &&
                    canopy.editCellsGeoJson.map((cell, index) => (
                      <GeoJSON
                        shape="Rectangle"
                        style={{
                          fillColor: cell.properties.enabled ? "#ffffff" : "none",
                          fillOpacity: 0.1,
                          weight: 1,
                          color: cell.properties.enabled ? (cell.properties.override_color ? cell.properties.override_color : "#666") : "none",
                        }}
                        transform={false}
                        draggable={false}
                        scaling={false}
                        data={cell}
                        key={`cell_${canopy.id}-${index}`}
                      />
                    ))}
                </Fragment>
              );
            })}
          </LayerGroup>
        )}
      </MapContainer>
    </ReportMapWrap>
  );
};

export { LiveThirtyPercentMap };
