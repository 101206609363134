import React, { Fragment, useState, useEffect } from "react";
import { TableWrap, TrackerTableWrap, PerfSummaryTableWrap, LossTableWrap, TopoLossTableWrap } from "./styles";
import _ from "lodash";

import { cleanRackingName, removePercent } from "../../../Redux";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const RackingTable = ({ reportData }) => {
  console.log("reportDAta", reportData);
  return (
    <TableWrap>
      <div className="title">
        <p>Racking Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Racking Product</p>
        <p className="table-text">{cleanRackingName(reportData.racking_name)}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Type</p>
        <p className="table-text">{reportData.track_mode === 1 ? "Single Axis Tracker" : reportData.track_mode === 2 ? "East-West Fixed" : "Ground Fixed Tilt"}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Rack to Rack Gap</p>
        <p className="table-text">{`${reportData.selectedProduct.gap} m`}</p>
      </div> */}
      <div className="table-row">
        <p className="table-header">Grade Limit</p>
        <p className="table-text">{`${reportData.rack_grade_limit} %`}</p>
      </div>
      {reportData.track_mode === 1 && reportData.en_backtrack === 1 && (
        <div className="table-row">
          <p className="table-header">Backtrack</p>
          <p className="table-text">{`Yes`}</p>
        </div>
      )}

      {reportData.track_mode === 1 ? (
        <div className="table-row">
          <p className="table-header">Tracking Angle</p>
          <p className="table-text">{`+/- ${reportData.rlim}°`}</p>
        </div>
      ) : (
        <div className="table-row">
          <p className="table-header">Tilt Angle</p>
          <p className="table-text">{`${reportData.tilt}°`}</p>
        </div>
      )}

      {/* {reportData.track_mode == 1 ? (
        <div className="table-row">
          <p className="table-header">Tracking Angle</p>
          <p className="table-text">{`+/- ${reportData.rlim}°`}</p>
        </div>
      ) : reportData.track_mode == 2 ? (
        <div className="table-row">
          <p className="table-header">Tilt Angle</p>
          <p className="table-text">{`${reportData.tilt}°`}</p>
        </div>
      ) : (
        <div className="table-row">
          <p className="table-header">Tilt Min/Max</p>
          <p className="table-text">{`${reportData.tilt_min}°/${reportData.tilt_max}°`}</p>
        </div>
      )} */}

      <div className="table-row">
        <p className="table-header">Height Above Ground</p>
        <p className="table-text">{`${reportData.bi_groundClearanceHeight} m`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Transparent Fraction</p>
        <p className="table-text">{`0 %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Structural Shade Factor</p>
        <p className="table-text">{`0 %`}</p>
      </div> */}
      {reportData.track_mode === 0 && (
        <Fragment>
          <div className="table-row">
            <p className="table-header">String Steps</p>
            <p className="table-text">{reportData.Fshd_StringSteps}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cell Transverse</p>
            <p className="table-text">{`${reportData.Fshd_Celltrav} m`}</p>
          </div>
        </Fragment>
      )}
      <div className="table-row">
        <p className="table-header">Racking Dimension Mode</p>
        <p className="table-text">{`${reportData.rack_dims === 0 ? "Auto" : "Manual"}`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Shade Mode</p>
        <p className="table-text">{`${data.selectedProduct.shade_mode === 0 ? ' No Shading' : 'Yes'}`}</p>
      </div>
     
      <div className="table-row">
        <p className="table-header">Continuous</p>
        <p className="table-text">{`${data.selectedProduct.continuous === 0 ? 'No' : 'Yes'} `}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Orientation</p>
        <p className="table-text">{`${data.selectedProduct.mod_orient == 0 ? 'Portrait' : 'Landscape'}`}</p>
      </div> */}
    </TableWrap>
  );
};

const TrackerBreakdownTable = ({ reportData }) => {
  const letters = ["A", "B", "C"];
  const table_keys = ["table_a", "table_b", "table_c"];

  return (
    <TrackerTableWrap>
      <thead>
        <tr>
          <th>Tracker Sizes</th>
          <th>Qty</th>
          <th>Module Count</th>
          <th>{`Tracker X`}</th>
          <th>{`Tracker Y`}</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(reportData.racks).map((rack, index) => (
          <tr key={index}>
            <td>{letters[index]}</td>
            {/* <td>{reportData[table_keys[index]]}</td> */}
            <td>{reportData.rack_breakdown[index]}</td>
            <td>{`${rack.module}`}</td>
            <td>{`${parseFloat(rack.xdim).toFixed(2)} m`}</td>
            <td>{`${parseFloat(rack.ydim).toFixed(2)} m`}</td>
          </tr>
        ))}
      </tbody>
    </TrackerTableWrap>
  );
};

const ModuleTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Module Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Name</p>
        <p className="table-text">{`${reportData.mod_name}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Rating</p>
        <p className="table-text">{`${reportData.mod_rating} W`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Width</p>
        <p className="table-text">{`${reportData.mod_width} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Height</p>
        <p className="table-text">{`${reportData.mod_height} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Area</p>
        <p className="table-text">{`${reportData.mod_area.toFixed(2)} m²`}</p>
      </div>
      {reportData.simple_module === 0 && (
        <>
          <div className="table-row">
            <p className="table-header">Technology</p>
            <p className="table-text">{reportData.technology === 0 ? "Si-Mono" : reportData.technology === 1 ? "Si-Poly" : "CdTe"}</p>
          </div>
          {reportData.en_bifacial === 1 && (
            <>
              <div className="table-row">
                <p className="table-header">Bifacial</p>
                <p className="table-text">True</p>
              </div>
              <div className="table-row">
                <p className="table-header">Bifaciality Factor</p>
                <p className="table-text">{reportData.bi_bifaciality}</p>
              </div>
            </>
          )}
          <div className="table-row">
            <p className="table-header">Gref</p>
            <p className="table-text">{`${reportData.mlm_S_ref} W/m²`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tref</p>
            <p className="table-text">{`${reportData.mlm_T_ref}° C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Isc</p>
            <p className="table-text">{`${reportData.mlm_I_sc_ref} A`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Voc</p>
            <p className="table-text">{`${reportData.mlm_V_oc_ref} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Imp</p>
            <p className="table-text">{`${reportData.mlm_I_mp_ref} A`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Vmp</p>
            <p className="table-text">{`${reportData.mlm_V_mp_ref} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Isc</p>
            <p className="table-text">{`${reportData.mlm_alpha_isc} mA/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Isc</p>
            <p className="table-text">{`${reportData.mlm_beta_voc_spec} mV/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cells In Series </p>
            <p className="table-text">{`${reportData.mlm_N_series}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cells In Parallel </p>
            <p className="table-text">{`${reportData.mlm_N_parallel}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rsh</p>
            <p className="table-text">{`${reportData.mlm_R_shref} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rshunt at G=0</p>
            <p className="table-text">{`${reportData.mlm_R_sh0} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rs</p>
            <p className="table-text">{`${reportData.mlm_R_s} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Shunt Exp</p>
            <p className="table-text">{`${reportData.mlm_R_shexp}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Absorb Coef</p>
            <p className="table-text">{`${reportData.mlm_T_c_fa_alpha}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Pmpp</p>
            <p className="table-text">{`${reportData.muPmpReq} %/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Gamma</p>
            <p className="table-text">{`${reportData.mlm_n_0}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Gamma</p>
            <p className="table-text">{`${reportData.mlm_mu_n} 1/°C`}</p>
          </div>
        </>
      )}
    </TableWrap>
  );
};

const PerformanceTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Performance Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Modules Per String</p>
        <p className="table-text">{`${reportData.mod_per_string}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Degredation</p>
        <p className="table-text">{`${reportData.dc_degrade} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Thermal Constant Loss</p>
        <p className="table-text">{`${reportData.dc_thermal_Uc} W/m²k`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Thermal Wind Loss</p>
        <p className="table-text">{`${reportData.dc_thermal_Uv} W/m²k/m/s`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Mod Quality Loss</p>
        <p className="table-text">{`${reportData.dc_module_quality_loss} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Mod LID Loss</p>
        <p className="table-text">{`${parseFloat(reportData.dc_module_lid_loss).toFixed(2)} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Module Mismatch</p>
        <p className="table-text">{`${reportData.dc_module_mismatch} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Strings Mismatch</p>
        <p className="table-text">{`${reportData.dc_strings_mismatch} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Wiring Loss @ STC</p>
        <p className="table-text">{`${reportData.dc_wiring_loss_at_stc} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Bifacial Back Mismatch</p>
        <p className="table-text">{`${reportData.bi_back_mismatch} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Auxiliaries</p>
        <p className="table-text">{`${reportData.ac_aux_kw} kW`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">AC Wiring Loss @ STC</p>
        <p className="table-text">{`${reportData.ac_wiring_loss_at_stc} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Transformer Constant Loss</p>
        <p className="table-text">{`${reportData.ac_transformer_loss_constant} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Transformer Loss @ STC</p>
        <p className="table-text">{`${reportData.ac_transformer_loss_at_stc} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">MV Line Loss @ STC</p>
        <p className="table-text">{`${reportData.ac_MV_line_loss_stc} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Transmission Loss</p>
        <p className="table-text">{`${reportData.ac_transmission_loss} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Other AC Losses</p>
        <p className="table-text">{`${reportData.ac_other_loss} %`}</p>
      </div>
    </TableWrap>
  );
};

const SoilingAlbedoTable = ({ reportData }) => {
  return (
    <PerfSummaryTableWrap>
      <thead>
        <tr>
          <th></th>
          {months.map((month, index) => (
            <th key={index}>{month}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Soiling (%)</td>
          {reportData.soiling.map((soil, index) => (
            <td key={index}>{soil}</td>
          ))}
        </tr>
        <tr>
          <td>Albedo</td>
          {reportData.albedo.map((alb, index) => (
            <td key={index}>{alb}</td>
          ))}
        </tr>
      </tbody>
    </PerfSummaryTableWrap>
  );
};

//  ********** LossesTable is no longer used ****************
const LossesTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Losses</p>
      </div>
      {reportData.dc_degradation.toFixed(2) != 0 && (
        <div className="table-row">
          <p className="table-header">DC Degredation</p>
          <p className="table-text">{`${reportData.dc_degradation.toFixed(2)} %`}</p>
        </div>
      )}
      {reportData.horizontal_global_irradiance.toFixed(3) != 0 && (
        <div className="table-row">
          <p className="table-header">Horiz. Global Irradiance</p>
          <p className="table-text">{`${reportData.horizontal_global_irradiance.toFixed(3)} kWh/m²`}</p>
        </div>
      )}
      {reportData.global_incident_in_col_plane.toFixed(5) != 0 && (
        <div className="table-row">
          <p className="table-header">Global Incident in Col. Plane</p>
          <p className="table-text">{`${reportData.global_incident_in_col_plane.toFixed(5)} %`}</p>
        </div>
      )}
      {reportData.near_shadings_irradiance_loss.toFixed(5) != 0 && (
        <div className="table-row">
          <p className="table-header">Near Shadings Irradiance Loss</p>
          <p className="table-text">{`${reportData.near_shadings_irradiance_loss.toFixed(5)} %`}</p>
        </div>
      )}
      {reportData.iam_factor_on_global.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">IAM Factor on Global</p>
          <p className="table-text">{`${reportData.iam_factor_on_global.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.soiling_loss.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Soiling Loss</p>
          <p className="table-text">{`${reportData.soiling_loss.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.bifacial_gain.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Bifacial Gain</p>
          <p className="table-text">{`${reportData.bifacial_gain.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.global_effective_irradiation_on_col.toFixed(3) != 0 && (
        <div className="table-row">
          <p className="table-header">Glob. Eff. Irradiation on Col </p>
          <p className="table-text">{`${reportData.global_effective_irradiation_on_col.toFixed(3)} kWh/m²`}</p>
        </div>
      )}
      {reportData.array_nominal_energy.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Array Nominal Energy</p>
          <p className="table-text">{`${reportData.array_nominal_energy.toFixed(0)} kWh`}</p>
        </div>
      )}
      {reportData.pv_loss_irrad.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Loss Irrad</p>
          <p className="table-text">{`${reportData.pv_loss_irrad.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.pv_loss_temp.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Loss Temp</p>
          <p className="table-text">{`${reportData.pv_loss_temp.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.pv_elec_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Elec Loss</p>
          <p className="table-text">{`${reportData.pv_elec_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.lid_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">LID Loss</p>
          <p className="table-text">{`${reportData.lid_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.quality_loss.toFixed(1) != 0 && (
        <div className="table-row">
          <p className="table-header">Quality Loss</p>
          <p className="table-text">{`${reportData.quality_loss.toFixed(1)} %`}</p>
        </div>
      )}
      {reportData.dc_mismatch_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">DC Mismatch Loss</p>
          <p className="table-text">{`${reportData.dc_mismatch_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.ohmic_wiring_loss.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Ohmic Wiring Loss</p>
          <p className="table-text">{`${reportData.ohmic_wiring_loss.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.e_array.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">E Array</p>
          <p className="table-text">{`${reportData.e_array.toFixed(0)} kWh`}</p>
        </div>
      )}
      {reportData.inverter_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Inverter Loss</p>
          <p className="table-text">{`${reportData.inverter_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.ac_loss.toFixed(1) != 0 && (
        <div className="table-row">
          <p className="table-header">AC Loss</p>
          <p className="table-text">{`${reportData.ac_loss.toFixed(1)} %`}</p>
        </div>
      )}
      {reportData.interconnect_lim_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Interconnect Limit Loss</p>
          <p className="table-text">{`${reportData.interconnect_lim_loss.toFixed(0)} %`}</p>
        </div>
      )}
    </TableWrap>
  );
};

const IAMTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>IAM Values</p>
      </div>
      {reportData.module_iam_ang &&
        reportData.module_iam_ang.map((item, index) => {
          if (index > 24) return;
          return (
            <div className="table-row" key={index}>
              <p className="table-header">{`${item}`}</p>
              <p className="table-text">{`${reportData.module_iam_eff[index]}`}</p>
            </div>
          );
        })}
    </TableWrap>
  );
};

const InverterTable = ({ reportData }) => {
  // console.log("invertertable data", reportData);
  const calculateDCMaxPwr = (inv_pd_pacokw, maxEff) => {
    if (!isNaN(inv_pd_pacokw) && !isNaN(maxEff)) {
      if (inv_pd_pacokw > 0 && maxEff > 0) {
        return _.round((inv_pd_pacokw / maxEff) * 100, 2);
      }
    }
  };

  return (
    <TableWrap>
      <div className="title">
        <p>Inverter Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Inverter Name</p>
        <p className="table-text">{`${reportData.simple_inverter === 1 ? "Simple Inverter Input" : `${reportData.inv_name}`}`}</p>
      </div>
      {reportData.simple_inverter === 1 ? (
        <>
          <div className="table-row">
            <p className="table-header">{`DC:AC (ILR)`}</p>
            <p className="table-text">{`${reportData.dcac}`}</p>
          </div>
        </>
      ) : (
        <>
          <div className="table-row">
            <p className="table-header">Inverter Rating</p>
            <p className="table-text">{`${reportData.inv_rating} kW`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">AC Power Nom</p>
            <p className="table-text">{`${reportData.inv_pnom} kWac`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Min MPPT</p>
            <p className="table-text">{`${reportData.mppt_low_inverter} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">AC Power Max</p>
            <p className="table-text">{`${reportData.inv_pd_pacokw} kWac`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Max MPPT</p>
            <p className="table-text">{`${reportData.mppt_hi_inverter} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Max Efficiency</p>
            <p className="table-text">{`${reportData.maxEff} %`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Max PV Volts</p>
            <p className="table-text">{`${reportData.inv_pd_vdcmax} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">DC at Max Pwr</p>
            <p className="table-text">{`${calculateDCMaxPwr(reportData.inv_pd_pacokw, reportData.maxEff)} kW`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Night Pwr Use</p>
            <p className="table-text">{`${reportData.inv_pd_pnt} W`}</p>
          </div>
        </>
      )}
    </TableWrap>
  );
};

const LayoutTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Layout Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">GCR</p>
        <p className="table-text">{`${parseFloat(reportData.gcr).toFixed(2)}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Pitch</p>
        <p className="table-text">{`${reportData.pitch} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Azimuth</p>
        <p className="table-text">{`${reportData.sazm}°`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Racks Removed based on Grade</p>
        <p className="table-text">{reportData.topo_layout_action === "delete" ? `On` : `Off`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Intrarow Spacing</p>
        <p className="table-text">{`${+reportData.r2r.toFixed(2)} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Buildable Area</p>
        <p className="table-text">{`${reportData.boundary_area.toFixed(2)} ha`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Buildable Area (Acres)</p>
        <p className="table-text">{`${reportData.summary["configuration"]?.value["buildable_area_acres"]?.value} acres`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Coordinate System</p>
        <p className="table-text">{`WGS 84`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Wiring</p>
        <p className="table-text">{`${reportData.do_wiring == 1 ? "On" : "Off"}`}</p>
      </div> */}
      {reportData.do_wiring === 1 && (
        <>
          <div className="table-row">
            <p className="table-header">Combiner Location</p>
            <p className="table-text">{`${reportData.combiner_location === 0 ? "Centroids" : "By Road"}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Strings per Combiner</p>
            <p className="table-text">{`${reportData.strings_per_combiner}`}</p>
          </div>
        </>
      )}
    </TableWrap>
  );
};

const BOMTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>BOM Table</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack A Qty</p>
        <p className="table-text">{`${reportData.rack_breakdown[0]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack B Qty</p>
        <p className="table-text">{`${reportData.rack_breakdown[1]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack C Qty</p>
        <p className="table-text">{`${reportData.rack_breakdown[2]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Qty</p>
        <p className="table-text">{`${reportData.en_mod_target === 1 ? parseInt(reportData.mod_target) : parseInt(reportData.module_count)}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Inverter Quantity</p>
        <p className="table-text">{`${reportData.simple_inverter === 1 ? "Simple Inverter Input" : `${reportData.inverter_qty}`}`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Combiner Boxes Qty</p>
        <p className="table-text">{reportData.do_wiring == 1 ? `${reportData.combiner_count}` : `n/a`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Wiring</p>
        <p className="table-text">{reportData.do_wiring == 1 ? `${reportData.wire_length_ft} ft` : `n/a`}</p>
      </div> */}
    </TableWrap>
  );
};

const PerformanceSummaryTable = ({ reportData }) => {
  const [pr_object, set_pr_object] = useState();

  useEffect(() => {
    let { DHISums_kWhm2, EArray_MWh, EGrid_MWh, GHISums_kWhm2, GlobEff_kwhm2, GlobInc_kwhm2, PRratio, TAvg_C, WindAvg_ms } = reportData;
    let pr_object = { DHISums_kWhm2, EArray_MWh, EGrid_MWh, GHISums_kWhm2, GlobEff_kwhm2, GlobInc_kwhm2, PRratio, TAvg_C, WindAvg_ms };
    set_pr_object(JSON.parse(JSON.stringify(pr_object)));
  }, []);

  return (
    <PerfSummaryTableWrap>
      <thead>
        <tr>
          <th></th>
          <th>
            GHI <br />
            (kWh/m²)
          </th>
          <th>
            DHI <br />
            (kWh/m²)
          </th>
          <th>
            Temp <br />
            (C°)
          </th>
          <th>
            Wind <br />
            (m/s)
          </th>
          <th>
            Global Incident <br />
            (kWh/m²)
          </th>
          <th>
            EArray <br />
            (MWh)
          </th>
          <th>
            E_Grid <br />
            (MWh)
          </th>
          <th>
            PR <br />
            (%)
          </th>
        </tr>
      </thead>
      <tbody>
        {pr_object &&
          pr_object.GHISums_kWhm2.map((item, index) => (
            <tr key={index}>
              {index === 12 ? <td>YEAR</td> : <td>{months[index]}</td>}
              <td>{`${pr_object.GHISums_kWhm2[index]}`}</td>
              <td>{`${pr_object.DHISums_kWhm2[index]}`}</td>
              <td>{`${pr_object.TAvg_C[index]}`}</td>
              <td>{`${pr_object.WindAvg_ms[index]}`}</td>
              <td>{`${pr_object.GlobInc_kwhm2[index]}`}</td>
              <td>{`${pr_object.EArray_MWh[index]}`}</td>
              <td>{`${pr_object.EGrid_MWh[index]}`}</td>
              <td>{`${parseFloat(pr_object.PRratio[index]).toFixed(2)}`}</td>
            </tr>
          ))}
      </tbody>
    </PerfSummaryTableWrap>
  );
};

// const FakeLossData = {
//   ghi: {
//     "Horizontal global irradiation (kWh/m²)": { value: 1368.1, poi: true },
//     "Global incident in coll. plane %": { value: 1813.4, change: 32.4 },
//     "Near Shadings: irradiance loss": { value: 1743.9, change: -3.74 },
//     "IAM factor on global": { value: 1719.1, change: -1.36 },
//     "Soiling loss factor": { value: 1684.8, change: -2.0 },
//     "Ground reflection on front side": { value: 1696.3, change: 0.73 },
//     "Effective irradiation on collectors": { value: 1696.3, change: 0, poi: true, area: 22465.2 },
//     "Area of collectors (m²)": { value: 22465.2, change: 0 },
//     "Efficiency at STC (%)": { value: 0.2, change: 18.45 },
//     "Array nominal energy at STC (MWh)": { value: 7674.3, change: 0, poi: true },
//     "Loss due to irradiance level": { value: 7612.7, change: -0.79 },
//     "PV loss due to temperature": { value: 7437.5, change: -1.7 },
//     // "Shadings: Electrical loss": { value: 7437.5, change: 0 },
//     // "Spectral correction": { value: 7437.5, change: 0 },
//     "Module quality loss": { value: 7511.9, change: 1 },
//     "LID - Light induced degradation": { value: 7399.2, change: -1.5 },
//     "Mismatch loss, modules and strings": { value: 7317.8, change: -1.1 },
//     "Mismatch for back irradiance": { value: 7238.5, change: -1.18 },
//     "Ohmic wiring loss": { value: 7162.6, change: -1.09 },
//     "Array virual energy at MPP (MWh)": { value: 7162.6, change: 0, poi: true },
//     "Inverter Loss during operation (efficiency)": { value: 6987.3, change: -2.43 },
//     "Inverter Loss over nominal inv. power": { value: 6928.7, change: -0.86 },
//     "Inverter Loss due to max input current": { value: 6928.7, change: 0 },
//     "Inverter Loss over nominal inv. voltage": { value: 6928.7, change: 0 },
//     "Inverter loss due to power threshold": { value: 6928.6, change: 0 },
//     "Inverter loss due to voltage threshold": { value: 6928.6, change: 0 },
//     "Night consumption": { value: 6891.1, change: -0.54 },
//     "Available Energy at Inverter Output (MWh)": { value: 6891.1, change: 0, poi: true },
//     "Auxiliaries (fans, other)": { value: 6679.6, change: -3.0 },
//     "AC ohmic loss": { value: 6629.6, change: -0.78 },
//     "MV transformer loss": { value: 6550.2, change: -1.24 },
//     "MV line ohmic losss": { value: 6501.9, change: -0.8 },
//     // "Fixed Transmission Loss": { value: 6501.9, change: 0 },
//     // "AC Other": { value: 6501.9, change: 0 },
//     // "Unused energy (grid limitation)": { value: 6501.9, change: 0 },
//     "Energy injected into Grid (MWh)": { value: 6502.1, poi: true },
//   },
//   bifacial: {
//     "Global incidence on ground": { value: 748.4, change: 0, poi: true },
//     "Global incidence on ground on area": { value: 64186.3, change: 0 },
//     "Ground reflection loss (Albedo)": { value: 224.5, change: -70 },
//     "View Factor for rear side": { value: 61.6, change: -72.56 },
//     "sky diffuse on the rear side": { value: 78.4, change: 20.69 },
//     "Beam effective on the rear side": { value: 0, change: 0 },
//     "Shadings loss on rear side": { value: 0, change: 0 },
//     "Global irradiance on rear side": { value: 220, change: 12.98, poi: true },
//     "PV conversion, Bifaciality factor": { value: 0.75, change: 0.75 },
//     // "Effective irradiation on collectors rear (kWh/m²)": { value: 206.2, change: 0, poi: true },
//     // "Effective irradiation on collectors rear * bifaciality (kWh/m²)": { value: 154.6, change: 0 },
//   },
// };

const LossTable = ({ reportData, bifacial = false }) => {
  // console.log("repordata", reportData);
  return (
    <>
      {bifacial ? (
        <LossTableWrap>
          <thead>
            <tr key="bifacial_header">
              <th colSpan="2" style={{ textAlign: "center", backgroundColor: "#dddddd" }}>
                Bifacial
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(reportData.loss_stack.bifacial).map((key) => {
              if (key !== "Global incidence on ground on area" && (reportData.loss_stack.bifacial[key].value || reportData.loss_stack.bifacial[key].change)) {
                if (key === "Global incidence on ground") {
                  return (
                    <tr key={key}>
                      <th colSpan="2" style={{ textAlign: "center" }}>
                        {key}
                        <br></br>
                        {`${reportData.loss_stack.bifacial[key].value} kWh/m² on ${reportData.loss_stack.bifacial["Global incidence on ground on area"].value} m² `}
                      </th>
                    </tr>
                  );
                } else if (key === "Global irradiance on rear side") {
                  return (
                    <tr key={key}>
                      <th colSpan="2" style={{ textAlign: "center" }}>
                        {`${reportData.loss_stack.bifacial[key].change}% ${key} (${reportData.loss_stack.bifacial[key].value} kWh/m²) `}
                      </th>
                    </tr>
                  );
                } else if (key === "PV conversion, Bifaciality factor") {
                  return (
                    <tr key={key} style={{ border: 0, textAlign: "center" }}>
                      <td colSpan="2" style={{ border: 0 }}>
                        {`${key} = ${reportData.loss_stack.bifacial[key].value}`}
                      </td>
                    </tr>
                  );
                } else if (reportData.loss_stack.bifacial[key].poi) {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <th>{reportData.loss_stack.bifacial[key].value}</th>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{reportData.loss_stack.bifacial[key].change ? `${reportData.loss_stack.bifacial[key].change} %` : reportData.loss_stack.bifacial[key].change === 0 ? `${0}%` : ""}</td>
                    </tr>
                  );
                }
              }
            })}
            {reportData.en_bifacial === 1 && reportData.bi_bifaciality && (
              <tr key="bifaciality" style={{ border: 0, textAlign: "center" }}>
                <th colSpan="2" style={{ border: 0 }}>
                  {`Bifaciality Factor = ${reportData.bi_bifaciality}`}
                </th>
              </tr>
            )}
          </tbody>
        </LossTableWrap>
      ) : (
        <LossTableWrap>
          <tbody>
            {Object.keys(reportData.loss_stack.ghi).map((key) => {
              if (key !== "Area of collectors (m^2)" && (reportData.loss_stack.ghi[key].value || reportData.loss_stack.ghi[key].change)) {
                if (key === "Horizontal global irradiation (kWh/m²)") {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <th>{`${reportData.loss_stack.ghi[key].value}`}</th>
                    </tr>
                  );
                } else if (key === "Effective irradiation on collectors (kWh/m^2)") {
                  return (
                    <tr key={key}>
                      <th colSpan="2" style={{ textAlign: "center" }}>
                        {"Effective irradiation on collectors"}
                        <br></br>
                        {`${reportData.loss_stack.ghi[key].value} kWh/m² * ${reportData.loss_stack.ghi["Area of collectors (m^2)"].value} m² coll. `}
                      </th>
                    </tr>
                  );
                } else if (key === "Efficiency at STC (%)") {
                  return (
                    <tr key={key} style={{ border: 0, textAlign: "center" }}>
                      <td colSpan="2" style={{ border: 0 }}>
                        {`${key} = ${reportData.loss_stack.ghi[key].change} %`}
                      </td>
                    </tr>
                  );
                } else if (key === "Yield (kWh/kWp)") {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <th>{`${reportData.loss_stack.ghi[key].value}`}</th>
                    </tr>
                  );
                } else if (key === "Array virual energy at MPP") {
                  return (
                    <tr key={key}>
                      <th>{`${key} (MWh)`}</th>
                      <th>{reportData.loss_stack.ghi[key].value}</th>
                    </tr>
                  );
                } else if (reportData.loss_stack.ghi[key].poi) {
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <th>{reportData.loss_stack.ghi[key].value}</th>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={key}>
                      <td>{removePercent(key)}</td>
                      <td>{reportData.loss_stack.ghi[key].change ? `${reportData.loss_stack.ghi[key].change} %` : reportData.loss_stack.ghi[key].change === 0 ? `${0} %` : ""}</td>
                    </tr>
                  );
                }
              }
            })}
          </tbody>
        </LossTableWrap>
      )}
    </>
  );
};

const TopoLossTable = ({ reportData }) => {
  // console.log("repordata in loss table", reportData);
  return (
    <div style={{ marginTop: 300 }}>
      <br></br>
      <TopoLossTableWrap>
        <thead>
          <tr key="topo_impact_header">
            <th colSpan="2" style={{ textAlign: "center", backgroundColor: "#dddddd" }}>
              Topo Impact
            </th>
          </tr>
        </thead>
        <tbody>
          {(Object.keys(reportData.summary?.topo_impact?.value) || []).map((key) => {
            return (
              <tr key={key}>
                {/* <td>{key?.label}</td> */}
                <td>{removePercent(reportData.summary?.topo_impact?.value[key]?.label)}</td>
                <td>{`${reportData.summary?.topo_impact?.value[key]?.value} %`}</td>
              </tr>
            );
          })}
        </tbody>
      </TopoLossTableWrap>
    </div>
  );
};
export {
  RackingTable,
  TrackerBreakdownTable,
  ModuleTable,
  PerformanceTable,
  IAMTable,
  InverterTable,
  LayoutTable,
  LossesTable,
  BOMTable,
  SoilingAlbedoTable,
  PerformanceSummaryTable,
  LossTable,
  TopoLossTable,
};
