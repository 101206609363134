import { theme } from "antd";

const { getDesignToken, useToken } = theme;

const custom_theme = {
  token: {
    colorPrimary: "#002bcb",
    colorLink: "#002bcb",
    colorSuccess: "#52c41a",
    colorWarning: "#faad14",
    colorError: "#f5222d",
    fontSize: 13,
    colorTextHeading: "#333",
    colorText: "#333",
    colorTextSecondary: "#333",
    colorTextDisabled: "rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    colorBorder: "#d9d9d9",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    fontWeightStrong: 600,
    titleMarginTop: 15,
    titleMarginBottom: 15,
  },
  hashed: false,
};

const config_theme = custom_theme;

export default config_theme;
