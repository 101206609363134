import styled from "styled-components";

const HotkeyTipBoxStyles = styled.section`
  .popup-overlay {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 999;
  }
  .popup-content-wrap {
    width: 400px;
    height: 130px;
    max-width: 100%;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
  }
  .popup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #373737;
    padding: 5px;
    border-bottom: 1px solid #dadada;
    margin-bottom: 5px;
  }
  .popup-content {
    font-size: 14px;
    color: #373737;
    text-align: justify;
  }
  .popup-content p {
    margin-left: 5px;
  }
  .popup-row {
    display: flex;
    justify-content: space-around;
  }
  .popup-optns {
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-size: 18px;
    padding: 0px;

    p {
      padding: 0px !important;
    }
  }

  .popup-row p {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 0;
    margin: 0;
  }
`;

export { HotkeyTipBoxStyles };
