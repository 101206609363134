import styled from 'styled-components';

export const NotificationWrapper = styled.a`
  position: absolute;
  top: 20px;
  right: ${(props) => (props.animateIn ? '50px' : '-400px')};
  width: 320px;
  background: #fff;
  border-left: 6px solid var(--primary-brand-color);
  border-radius: 3px;
  z-index: 499;
  display: grid;
  grid-template-columns: 40px 1fr;
  padding: 10px 15px;
  overflow: hidden;
  transition: right 0.5s var(--easing);

  .info-icon {
    grid-column: 1;
    display: grid;
    place-items: center;
    width: 100%;

    img {
      width: 20px;
      margin-right: 15px;
    }
  }

  .notification-msg {
    display: grid;
    flex-direction: column;

    .notification-text {
      color: #333;
      text-align: left;
      P {
        margin: 0px;
      }
    }

    .notification-text-emphasis {
      color: var(--primary-brand-color);
      text-align: right;
      P {
        margin: 0px;
      }
    }
  }

  @media (max-width: 714px) {
    top: 55px;
    right: 0px;
    margin: auto;
    left: 0px;
  }
`;
