import config from "config";
import { authHeader } from "../_helpers";
import jwtDecode from "jwt-decode";
import fetchUtil from "./fetchUtil";
//import { TutorialFunnelWrap } from "../../Tutorial/styles";

export const userService = {
  login,
  logout,
  agreeToTerms,
  refreshToken,
  register,
  pwReset,
  get_library_data,
  getAll,
  getProfile,
  updateUserData,
  validateInputs,
  runCapacity,
  getResults,
  getRacksRoads,
  getProjects,
  getProjectById,
  saveProject,
  runWeather,
  getWeather,
  saveModule,
  saveInverter,
  saveRacking,
  savePerformance,
  saveFinance,
  saveWeather,

  resendVerifyEmail,
  addPayment,
  deletePayment,
  setDefaultPayment,
  updatePlanTo,

  getResultFiles,
  pollReport,
  logDownload,
  getWeatherFile,
  getTopo,
  checkTopo,

  createDXF,
  pollDXF,

  importData,
  uploadDataToURL,
  downloadFromS3,
  downloadFromS3toPC,
  getById,
  update,
  delete: _delete,
  getTutorial,
  downloadInvoice,
  // portal
  internalLogin,
  updatePassword,
  log_salesforce_downlaod,
};

async function login(email, password, forward) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/api/auth/token/obtain/`, requestOptions)
    .then((response) => handleResponse(response, true))
    .then((returnedUser) => {
      // login successful if there's a jwt access token in the response
      // let remember = localStorage.getItem('remember');
      let user_data = {
        ...returnedUser,
        access: {
          token: returnedUser.access,
          ...jwtDecode(returnedUser.access),
        },
        refresh: {
          token: returnedUser.refresh,
          ...jwtDecode(returnedUser.refresh),
        },
      };

      let user = {
        loggingIn: false,
        loggedIn: true,
        login_visible: false,
        forward: forward,
        // remember: remember,
        refresh_visible: false,
        show_agreement_modal: !returnedUser.terrasmart_terms,
        terrasmart_terms: returnedUser.terrasmart_terms,
        show_terms: false,
        openedViaAgreegmentUpdate: false,
        getting_user_data: true,
        ...user_data,
      };
      let token = {
        access: {
          token: returnedUser.access,
          ...jwtDecode(returnedUser.access),
        },
        refresh: {
          token: returnedUser.refresh,
          ...jwtDecode(returnedUser.refresh),
        },
      }

      // store user object for later
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("refresh", JSON.stringify(user.refresh.token));
      localStorage.setItem("userInfo", JSON.stringify({ email: email }));
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("userInfo");
  location.reload();
  return true;
}

function refreshToken(refresh) {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify({ refresh }),
  };

  return fetchUtil(`${config.apiUrl}/api/auth/token/refresh/`, requestOptions, false).then((access) => {
    // login successful if there's a jwt access token in the response

    if (access) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
    }
    return access;
  });
}


function handlePassResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

function pwReset(email) {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify({
      email: email,
    }),
  };
  // return fetch(`${config.apiUrl}/api/password_reset/`, requestOptions).then(this.handleResponse);
  return fetchUtil(`${config.apiUrl}/api/password_reset/`, requestOptions, false, handlePassResponse);
}

function agreeToTerms() {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({}),
  };

  //return fetch(`${config.apiUrl}/user/agree/`, requestOptions).then(this.handleResponse);
  return fetchUtil(`${config.apiUrl}/user/agree/`, requestOptions);
}

// function handleRequest(request, inputs) {
//   // check if access if expired
//   // if so, refresh, then forward request
//   // if not, just forward request
// }

function get_library_data() {
  const requestOptions = {
    method: "GET",
    //headers: authHeader(),
  };
  
  //return fetch(`${config.apiUrl}/user/library/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/user/library/`, requestOptions);
}

function getAll() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "GET",
    //headers: authHeader(),
  };
  //return fetch(`${config.apiUrl}/user/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/user/`, requestOptions);
}

function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  //return fetch(`${config.apiUrl}/usage/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/usage/`, requestOptions);
}
function updateUserData(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(data),
  };
  //return fetch(`${config.apiUrl}/user/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/user/`, requestOptions);
}

/*
    DASHBOARD FUNCTIONS

*/
function validateInputs(inputs) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ inputs }),
  };
  // return fetch(`${config.apiUrl}/dash/validate/`, requestOptions)
  //   .then(handleResponse)
  //   .then((valid) => {
  //     return valid;
  //   });
  return fetchUtil(`${config.apiUrl}/dash/validate/`, requestOptions).then((valid) => {
    return valid;
  });
}

function runCapacity(inputs, run_id) {
  // console.log(run_id)
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ inputs, run_id }),
  };
  // console.log(requestOptions);
  // console.log(`${config.apiUrl}/dash/`);
  // return fetch(`${config.apiUrl}/dash/`, requestOptions)
  //   .then(handleResponse)
  //   .then((run_id) => {
  //     // console.log(run_id);
  //     // store run_id for pulling results
  //     localStorage.setItem("run_id", JSON.stringify(run_id));
  //
  //     return run_id;
  //   });
  return fetchUtil(`${config.apiUrl}/dash/`, requestOptions).then((run_id) => {
    // console.log(run_id);
    // store run_id for pulling results
    localStorage.setItem("run_id", JSON.stringify(run_id));

    return run_id;
  });
}
function getProjects() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/project/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/project/`, requestOptions);
}
function getProjectById(id, collab_on) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/project/?projectId=${id}&collab_on=${collab_on}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/project/?projectId=${id}&collab_on=${collab_on}`, requestOptions);
}

function getResults(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET https://localhost:8000/dash/?runId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/?runId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/?runId=${id}`, requestOptions);
}
function getRacksRoads(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET https://localhost:8000/dash/?rackId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/?rackId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/?rackId=${id}`, requestOptions);
}
function downloadFromS3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  //return fetch(url, requestOptions).then(handleResponse);
  return fetchUtil(url, requestOptions, false);
}
function downloadFromS3toPC(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Disposition": "attachment" },
  };
  return fetch(url, requestOptions);
  // return fetchUtil(url, requestOptions,false);
}

function getResultFiles(inputs) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(inputs),
  };
  //return fetch(`${config.apiUrl}/dash/files/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/files/`, requestOptions);
}

function pollReport(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions);
}

function logDownload(download_type, url, project_id) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ download_type, url, project_id }),
  };

  // console.log("download_type", download_type);
  //fetch(`${config.apiUrl}/dash/log_download/`, requestOptions);
  fetchUtil(`${config.apiUrl}/dash/log_download/`, requestOptions);
}

function getWeatherFile(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
      "Content-Type": "attachment",
    },
  };
  //return fetch(`${config.apiUrl}/dash/swm/download/weather/?weatherId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/download/weather/?weatherId=${id}`, requestOptions);
}

function getTopo(inputs) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ ...inputs }),
  };
  //return fetch(`${config.apiUrl}/dash/topo/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/topo/`, requestOptions);
}
function checkTopo(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/topo/?topo_id=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/topo/?topo_id=${id}`, requestOptions);
}

function createDXF(inputs) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(inputs),
  };
  //return fetch(`${config.apiUrl}/dash/swm/dxf`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/dxf`, requestOptions);
}
function pollDXF(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions);
}

function saveProject(project) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(project),
  };
  //return fetch(`${config.apiUrl}/dash/project/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/project/`, requestOptions);
}

function saveModule(mod) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(mod),
  };

  //return fetch(`${config.apiUrl}/dash/modules/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/modules/`, requestOptions);
}

function saveInverter(inv) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(inv),
  };

  //return fetch(`${config.apiUrl}/dash/inverters/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/inverters/`, requestOptions);
}
function saveRacking(rack) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(rack),
  };

  //return fetch(`${config.apiUrl}/dash/racking/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/racking/`, requestOptions);
}

function savePerformance(perf) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(perf),
  };

  //return fetch(`${config.apiUrl}/dash/performance/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/performance/`, requestOptions);
}

function saveFinance(finance) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(finance),
  };

  //return fetch(`${config.apiUrl}/dash/finance/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/finance/`, requestOptions);
}

function saveWeather(weather) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(weather),
  };

  //return fetch(`${config.apiUrl}/dash/weather/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/weather/`, requestOptions);
}

function runWeather(inputs) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ inputs }),
  };
  // console.log(requestOptions);
  // console.log(`${config.apiUrl}/dash/`);
  // return fetch(`${config.apiUrl}/dash/weatherRun/`, requestOptions)
  //   .then(handleResponse)
  //   .then((weather_id) => {
  //     // console.log(run_id);
  //     // store run_id for pulling results
  //     localStorage.setItem("weather_id", JSON.stringify(weather_id));
  //
  //     return weather_id;
  //   });
  return fetchUtil(`${config.apiUrl}/dash/weatherRun/`, requestOptions).then((weather_id) => {
    // console.log(run_id);
    // store run_id for pulling results
    localStorage.setItem("weather_id", JSON.stringify(weather_id));

    return weather_id;
  });
}
function getWeather(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET https://localhost:8000/dash/?runId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: "GET",
    //headers: authHeader(),
  };
  //return fetch(`${config.apiUrl}/dash/weatherRun/?weatherId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/weatherRun/?weatherId=${id}`, requestOptions);
}

function resendVerifyEmail() {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/user/emailverify/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/user/emailverify/`, requestOptions);
}

function addPayment(token) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(token),
  };
  // console.log(token)
  //return fetch(`${config.apiUrl}/billing/?token=${token.token.id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/billing/?token=${token.token.id}`, requestOptions);
}

function deletePayment(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
  };

  //return fetch(`${config.apiUrl}/billing/?delId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/billing/?delId=${id}`, requestOptions);
}

function setDefaultPayment(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
  };

  //return fetch(`${config.apiUrl}/billing/?defaultId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/billing/?defaultId=${id}`, requestOptions);
}

function updatePlanTo(sub) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
  };

  //return fetch(`${config.apiUrl}/billing/plan/?planId=${sub}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/billing/plan/?planId=${sub}`, requestOptions);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    //headers: authHeader(),
  };

  //return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/users/${id}`, requestOptions);
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify(user),
  };

  //return fetch(`${config.apiUrl}/rest-auth/registration/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/rest-auth/registration/`, requestOptions, false);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify(user),
  };

  //return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/users/${user.id}`, requestOptions);
}

function importData(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      // ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  //return fetch(`${config.apiUrl}/dash/import/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/import/`, requestOptions);
}

function uploadDataToURL(url, data) {
  const requestOptions = {
    method: "PUT",
    // headers: { 'Content-Type': 'application/json' },
    // headers: { 'Content-Type': type },
    body: data,
    // body: data,
  };

  // return fetch(url, requestOptions).then(handleResponse);
  return fetchUtil(url, requestOptions, false);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    //headers: authHeader(),
  };

  //return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/users/${id}`, requestOptions);
}

function getTutorial(inputs) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/dash/tutorial/`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/dash/tutorial/`, requestOptions);
}

function downloadInvoice(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      //...authHeader(),
    },
  };
  //return fetch(`${config.apiUrl}/billing/invoice/?invoiceId=${id}`, requestOptions).then(handleResponse);
  return fetchUtil(`${config.apiUrl}/billing/invoice/?invoiceId=${id}`, requestOptions);
}

function handleResponse(response, login = false) {
  return response.text().then((text) => {
    let response = text && JSON.parse(text);
    if (!response.success) {
      if (response.error_code === 401 && !login) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      // console.log(data);
      // const error = (data && data.message) || response.statusText;
      const error = data || response.message;

      // console.log(error)
      return Promise.reject(error);
    }

    return response.data;
  });
}

// portal stuff

async function internalLogin(email, password) {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify({ email, password }),
  };

  // temporary
  let response = await obtainToken(email, password);
  if (response.access) {
    localStorage.setItem("access", response.access);
    localStorage.setItem("refresh", response.refresh);
    localStorage.setItem("email", email);
    localStorage.setItem("admin", response.admin);
  }

  return fetchUtil(`${config.apiUrl}/platform/rbi/`, requestOptions, false).then((returnedUser) => {
    // login successful if there's a jwt access token in the response
    // let remember = localStorage.getItem('remember');
    let user_data = {
      ...returnedUser,
      access: {
        token: returnedUser.access,
        ...jwtDecode(returnedUser.access),
      },
      refresh: {
        token: returnedUser.refresh,
        ...jwtDecode(returnedUser.refresh),
      },
    };

    let user = {
      loggingIn: false,
      loggedIn: true,
      login_visible: false,
      forward: true,
      // remember: remember,
      refresh_visible: false,
      show_agreement_modal: false,
      terrasmart_terms: false,
      show_terms: false,
      openedViaAgreegmentUpdate: false,
      getting_user_data: false,
      plan_restrictions: {
        api_credit: 0,
        boundary_size_limit: 1000,
        can_api_key: false,
        can_collab: true,
        can_download_8760: true,
        can_dxf: true,
        can_irr: true,
        can_kmz: true,
        can_lcoe: true,
        can_meteo: true,
        can_ond: true,
        can_pan: true,
        can_pdf: true,
        can_project: false,
        can_run_finance: true,
        can_run_performance: true,
        can_run_storage: false,
        run_limit: 2000,
      },
      use_random_map_location: false,
      map_center: [39.18648929133045, -84.4719573994339],
      // getting_user_data: true,
      ...user_data,
    };

    // store user object for later
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("refresh", JSON.stringify(user.refresh.token));
    return user;
  });
}

function obtainToken(email, password) {
  let requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify({ email, password }),
  };

  return fetchUtil(`${config.apiUrl}/platform/rbi/`, requestOptions, false).then((user) => {
    // login successful if there's a jwt access token in the response
    if (user.access) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // console.log(user.refresh)
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("access", JSON.stringify(user.access));
      localStorage.setItem("refresh", JSON.stringify(user.refresh));
    }

    return user;
  });
}

function updatePassword(email, old_password, new_password) {
  let url = `${config.apiUrl}/platform/rbi/update/`;
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({
      email: email,
      old_password: old_password,
      new_password: new_password,
    }),
  };
  //return fetch(url, requestOptions);
  return fetchUtil(url, requestOptions);
}

async function log_salesforce_downlaod(download_type, url, project_id) {
  const requestOptions = {
    method: "POST",
    headers: {
      //...authHeader(),
    },
    body: JSON.stringify({ download_type, url, project_id }),
  };
  let response = await fetchUtil(`${config.apiUrl}/dash/log_download/`, requestOptions);

  return await response.json();
}
