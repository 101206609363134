import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ShadeInputRow, ShadeInputWrap, ShadeRowWrap, DrawToolsWrap } from "./styles";
import { Input, Button, Tooltip, Popconfirm, Radio } from "antd";
import { delete_polygon } from "../../../assets/images";

import { ShadeDrawing } from "../ShadeDrawing";

import { canopyActions } from "../../Redux";
import { RunShadeButton } from "./RunShadeButton";

const format_shade_name = (name) => {
  switch (name) {
    case "tree":
      return "Tree";
    case "treeLine":
      return "Line";
    case "building":
      return "Building";
    default:
      return "Shade Object";
  }
};

const ShadeInputs = () => {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.user.admin);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const onChangeInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let temp_shade_object = { ...shade_objects[e.target.id] };
    temp_shade_object.height = parseInt(e.target.value);
    dispatch(canopyActions.updateShadeObject("edit_shade_object", temp_shade_object));
  };

  const handleClickDelete = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(canopyActions.updateShadeObject("delete_shade_object", id));
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(canopyActions.updateCanopyInputs("selected_shade_object_id", e.currentTarget.id));
  };

  const handleMouseEnter = (center) => {
    dispatch(canopyActions.updateCanopyInputs("shade_marker_pos", center));
  };

  const handleMouseLeave = () => {
    dispatch(canopyActions.updateCanopyInputs("shade_marker_pos", undefined));
  };

  return (
    <ShadeInputWrap>
      <DrawToolsWrap>
        <ShadeDrawing />
      </DrawToolsWrap>
      <ShadeRowWrap>
        {Object.values(shade_objects)?.map((shade) => (
          <ShadeInputRow
            key={shade.id}
            id={shade.id}
            selected={selected_shade_object_id == shade.id}
            onClick={handleDoubleClick}
            // onMouseEnter={(e) => e.target.id == shade.id && handleMouseEnter(shade.center)}
            // onMouseLeave={(e) => e.target.id == shade.id && handleMouseLeave()}
            onMouseEnter={(e) => e.target.id == shade.id && handleMouseEnter(e.target.id)}
            onMouseLeave={(e) => e.target.id == shade.id && handleMouseLeave()}
          >
            <p>{`${format_shade_name(shade.type)}`}</p>
            <Input
              key={shade.id}
              id={shade.id}
              style={{ height: 24 }}
              size="small"
              value={shade.height}
              type="number"
              step={1}
              min={0}
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>{shade_height_unit}</span>}
              max="999"
              onChange={onChangeInput}
            ></Input>
            <Popconfirm
              placement="right"
              title={`Delete ${format_shade_name(shade.type)}`}
              onCancel={() => console.log("canceled")}
              okText="Yes"
              cancelText="No"
              style={{ maxWidth: "400px" }}
              onConfirm={(e) => handleClickDelete(e, shade.id)}
            >
              <Button type="link" id={shade.id}>
                <img src={delete_polygon} />
              </Button>
            </Popconfirm>
          </ShadeInputRow>
        ))}
      </ShadeRowWrap>
      <RunShadeButton />
    </ShadeInputWrap>
  );
};

export { ShadeInputs };
