import styled from "styled-components";

const LiveCanopyReportWrapper = styled.section`
  /* width: 100%; */
  height: calc(100vh - 60px);
  background: #dadada;
  padding: 10px 40px;
  display: grid;
  grid-template-rows: repeat(3, auto);
  /* place-content: start center; */
  overflow: auto;

  .spin-page {
    height: 1200px;
    width: 900px;
    display: grid;
    place-self: center;
    place-items: start center;
    padding: 100px 0px;
    background: #fff;
  }

  .landscape {
    height: 900px;
    width: 1200px;
  }

  .canopy-report-page {
    height: max-content;
    width: 900px;
    margin-bottom: 15px;
    display: grid;
    place-self: center;
    padding: 10px;
    background: #fff;

    .page-1 {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: repeat(6, min-content) 1fr;
      grid-row-gap: 20px;
      grid-template-areas:
        "date"
        "logo"
        "header_text"
        "map"
        "summary_header"
        "summary_table"
        "footer";

      .date {
        width: 100%;
        display: grid;
        grid-area: date;
        place-items: end;
      }

      .logo {
        width: 100%;
        display: grid;
        grid-area: logo;
        place-items: center;
        margin-bottom: 25px;

        img {
          width: 225px;
        }
      }

      .header_text {
        width: 100%;
        display: grid;
        grid-area: header_text;
        place-items: center;

        h1 {
          color: #333;
          font-size: 20px;
          margin: 0px;
        }
      }

      .map {
        width: 650px;
        height: 525px;
        display: grid;
        grid-area: map;
        place-self: center;
        padding: 20px 0px;
      }
      .summary-header {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-area: summary_header;
        place-self: center;
        width: 85%;

        .info {
          display: flex;
          align-items: center;

          .info-img {
            height: 50px;
            width: 50px;
            background-color: #e5e7e7;
            border-radius: 50%;
            display: grid;
            place-items: center;
            margin-right: 5px;

            img {
              height: 30px;
              filter: sepia(100%) hue-rotate(190deg) saturate(100); // blue
            }
          }

          .info-text {
            white-space: nowrap;
            .info-subtext {
              opacity: 0.8;
              white-space: nowrap;
            }

            p {
              margin: 0px;
            }
          }
        }
      }

      .summary-table {
        width: 100%;
        display: grid;
        grid-area: summary_table;
        place-items: center;
        width: 100%;

        table {
          border-collapse: collapse;
          width: 90%;

          th,
          td {
            white-space: nowrap;
            border: 1px solid #333;
            padding: 5px;
          }
          .canopy-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 140px;
          }
        }
      }

      .footer {
        width: 100%;
        display: grid;
        grid-area: footer;
        place-items: end center;
      }
    }
  }

  .canopy-report-details-page {
    height: 1200px;
    width: 900px;
    margin-bottom: 15px;
    display: grid;
    place-self: center;
    padding: 10px;
    background: #fff;
    grid-template-rows: min-content repeat(3, 340px);

    .date {
      width: 100%;
      grid-row: 1;
      display: grid;
      place-content: end;

      p {
        margin: 0px;
      }
    }

    .detail-row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      place-items: center;
      border-bottom: 1px solid #333;
      padding: 20px 0px;
    }
  }

  .thirty-percent {
    height: auto;
    width: 1200px;
    background: #fff;
    display: grid;
    place-self: center;
    position: relative;
    margin-bottom: 20px;

    img {
      /* width: 100%;
      height: 100%; */
    }

    .thirty-percent-map {
      position: absolute;
      height: 464px;
      width: 475px;
      top: 316px;
      left: 299px;
      z-index: 1;
    }
    .thirty-percent-layout-map {
      position: absolute;
      height: 725px;
      width: 1045px;
      top: 35px;
      left: 30px;

      .north-indicator-img {
        position: absolute;
        height: 40px;
        width: 40px;
        background: #fff;
        z-index: 400;
        bottom: 6px;
        right: 15px;
        border-radius: 3px;
        opacity: 0.7;
      }
    }

    .edit-fields {
      position: absolute;
      display: flex;
      align-items: center;
      top: 55px;
      left: 60px;
      p {
        font-size: 16px;
        margin: 0px 5px 0px 0px;
      }

      svg {
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .customer-name-box,
    .project-address-box {
      position: absolute;
      display: flex;
      align-items: center;
      margin-top: -15px;
      font-size: 20px;
      width: calc(100% - 90px);
      justify-content: center;

      p {
        margin: 0px 5px 0px 0px;
      }
    }
    .customer-name-box {
      /* left: ${(props) => (props.nameToggle ? "430px" : "470px")}; */
      top: 130px;
    }
    .project-address-box {
      /* left: ${(props) => (props.addressToggle ? "425px" : "470px")}; */
      top: 195px;
      display: flex;
      flex-direction: column;
    }

    .customer-name-right {
      position: absolute;
      top: 332px;
      width: 237px;
      height: 50px;
      left: 1030px;
      transform: rotate(-90deg);
      display: grid;
      place-items: center;
      h2 {
        margin: 0px;
      }
    }

    .customer-title-right {
      position: absolute;
      display: grid;
      place-items: center;
      top: 640px;
      left: 1096px;
      width: 80px;

      h5 {
        margin: 0px;
      }

      .cutomer-title {
        text-align: center;
        p {
          font-size: 8px;
          margin: 0px;
        }
      }
    }

    .project-number {
      position: absolute;
      display: grid;
      place-items: center;
      top: 697px;
      left: 1096px;
      width: 80px;

      p {
        margin: 0px;
        font-size: 8px;
      }
    }

    .drawn-by,
    .reviewed-by {
      position: absolute;
      display: grid;
      place-items: center;
      top: 712px;
      left: 1095px;
      p {
        margin: 0px;
        font-size: 8px;
      }
    }
    .reviewed-by {
      left: 1130px;
    }
  }

  .spec-box {
    position: absolute;
    font-size: 8px;
    p {
      margin: 0px;
    }
  }

  .array-type {
    bottom: 22px;
    left: 85px;
  }

  .module-quantity-box {
    bottom: 22px;
    left: 220px;
  }

  .active-pv-modules {
    bottom: 33px;
    left: 220px;
  }

  .active-rating {
    bottom: 33px;
    left: 260px;
  }

  .total-watts {
    bottom: 16px;
    left: 220px;
  }
`;

const ReportMapWrap = styled.section`
  height: 100%;
  width: 100%;
  padding: 0px 4px;
  /* margin: 10px auto; */

  .map-toggles {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      margin: 0px;
    }

    .map-toggle-buttons {
      display: flex;
      align-items: center;

      .toggle {
        display: flex;
        align-items: center;
        margin-right: 5px;
        p {
          margin: 0px 3px;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 22px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #60de4f;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #60de4f;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
`;

const LiveCanopyReportDetailsWrapper = styled.section`
  width: 100%;

  .canopy-name-header {
    color: #333;
    font-weight: 600;
  }

  .canopy-detail-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0px;

    p {
      margin: 0px;
    }
  }
`;

export { LiveCanopyReportWrapper, LiveCanopyReportDetailsWrapper, ReportMapWrap };
