import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Popconfirm } from "antd";
import { folder, boundary_poly, exclusion_poly, inactive_poly, delete_single_polygon, lock, shade_input_icon } from "../../../assets/images";
import { FeatureManagerWrap, ToggleArrow, DragIconWrap } from "./styles";
import { siftActions, inputsActions, canopyActions } from "../..";
import { ImageCheckbox } from "../../BaseComponents";

const Arrow = ({ is_open = false }) => (
  <ToggleArrow width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="https://www.w3.org/2000/svg" is_open={is_open}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.73488 6L0 1.26512L1.26512 0L7.26512 6L1.26512 12L0 10.7349L4.73488 6Z" fill="#333333" />
  </ToggleArrow>
);

const DragIcon = () => (
  <DragIconWrap width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M0 12V10.2857H1.84615V12H0ZM3.69231 12V10.2857H5.53846V12H3.69231ZM7.38462 12V10.2857H9.23077V12H7.38462ZM0 8.57143V6.85714H1.84615V8.57143H0ZM3.69231 8.57143V6.85714H5.53846V8.57143H3.69231ZM7.38462 8.57143V6.85714H9.23077V8.57143H7.38462ZM0 5.14286V3.42857H1.84615V5.14286H0ZM3.69231 5.14286V3.42857H5.53846V5.14286H3.69231ZM7.38462 5.14286V3.42857H9.23077V5.14286H7.38462ZM0 1.71429V0H1.84615V1.71429H0ZM3.69231 1.71429V0H5.53846V1.71429H3.69231ZM7.38462 1.71429V0H9.23077V1.71429H7.38462Z"
      fill="#333333"
    />
  </DragIconWrap>
);

const build_poly_list = (site_features, identity) => {
  return Object.entries(site_features)
    .filter(([key, feature]) => {
      return feature.properties.identity === identity;
    })
    .reduce((acc, [key, feature]) => {
      return { ...acc, [key]: feature };
    }, {});
};

const build_order = (site_features) => {
  let order;
  Object.values(site_features).map((feature, index) => {
    order = {
      ...order,
      [index]: feature.properties.index,
    };
  });
  return order;
};

const FeatureList = ({ drag_area_identity, draggingItem, dragOverItem, identity }) => {
  const dispatch = useDispatch();

  const site_features = useSelector((state) => state.inputs.site_features);
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const hidden_unchecked_features = useSelector((state) => state.inputs.hidden_unchecked_features || []);

  const [local_exclusions, set_local_exclusions] = useState(build_poly_list(site_features, identity));
  const [delete_id, set_delete_id] = useState(undefined);
  const [list_order, set_list_order] = useState(build_order(build_poly_list(site_features, identity)));
  const [dragging, set_dragging] = useState(undefined);

  useEffect(() => {
    set_local_exclusions([...Object.values(build_poly_list(site_features, identity))].sort((a, b) => a.properties.sortIndex - b.properties.sortIndex));
  }, [site_features]);

  const handleDragEnd = (event) => {
    event.preventDefault();
    const temp_features = {};
    setTimeout(() => {
      set_dragging(undefined);
      draggingItem.current = undefined;
    }, 1000);

    local_exclusions.forEach((feature, index) => {
      temp_features[feature.properties.index] = {
        ...feature,
        properties: {
          ...feature.properties,
          sortIndex: index,
          identity: dragging === feature.properties.index && feature.properties.identity !== drag_area_identity ? drag_area_identity : feature.properties.identity,
          active: dragging === feature.properties.index && drag_area_identity === 0 ? !feature.properties.active : feature.properties.active,
        },
      };
    });

    dispatch(inputsActions.update_site_input("add_bulk_features", temp_features));
  };

  const selectFeature = (id) => {
    dispatch(inputsActions.update_site_input("selectedFeatureId", id));
  };

  const handleDragEnter = (event, position) => {
    const listCopy = [...Object.values(local_exclusions)];
    let index = listCopy.findIndex((item) => item.properties.index === draggingItem.current);
    const draggingItemContent = listCopy[index];
    if (!draggingItemContent) return;

    listCopy.splice(index, 1);
    listCopy.splice(position, 0, draggingItemContent);

    let new_order;
    listCopy.map((item, index) => {
      new_order = {
        ...new_order,
        [index]: listCopy[index].properties.index,
      };
    });
    set_list_order(new_order);
    set_local_exclusions(listCopy);
  };

  const handleDragStart = (event) => {
    draggingItem.current = event.target.id;
    set_dragging(event.target.id);
  };

  const handleCheck = (event) => {
    const feature = site_features[event.id];
    if (!feature) return;

    feature.properties.checked = event.checked;
    feature.properties.active = event.checked;

    dispatch(siftActions.updateFeature(feature));
    dispatch(inputsActions.update_site_input("edit_site_feature", feature));

    if (hidden_unchecked_features.includes(feature.properties.identity) && event.checked) {
      const checked_arr = hidden_unchecked_features.filter((item) => item !== feature.properties.identity);
      dispatch(inputsActions.update_input("hidden_unchecked_features", checked_arr));
    }
  };

  const handleClickLabel = (event) => {
    event.stopPropagation();
    dispatch(inputsActions.update_site_input("selectedFeatureId", event.currentTarget.id));
  };

  const handleDoubleClick = (event) => {
    // console.log("double click event", event.currentTarget.id);
    event.stopPropagation();
    dispatch(inputsActions.update_input("pan_to_feature_id", event.currentTarget.id));
  };

  const handleMouseEnter = (event) => {
    dispatch(inputsActions.update_input("cur_hover_id", event.target.id));
  };

  const handleMouseLeave = (event) => {
    dispatch(inputsActions.update_input("cur_hover_id", undefined));
  };

  const delete_single_feature = () => {
    if (delete_id) {
      dispatch(inputsActions.update_site_input("remove_site_feature", delete_id));
    }
    set_delete_id(undefined);
  };

  return (
    <ul>
      {Object.values(local_exclusions).map((feature, index) => (
        <li
          key={feature.properties.index}
          id={feature.properties.index}
          identity={feature.properties.identity}
          className={feature.properties.index === dragging ? "dragging" : feature.properties.index === selectedFeatureId ? "highlight-selected" : null}
          draggable={feature.geometry.type == "Polygon" ? true : false}
          onDragStart={(event) => handleDragStart(event, index)}
          onDragEnter={(event) => handleDragEnter(event, index)}
          onDragOver={(event) => event.preventDefault()}
          onDragEnd={handleDragEnd}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ImageCheckbox id={feature.properties.index} checked={feature.properties.checked ?? true} onChange={handleCheck} />
          <DragIcon />
          <section className="poly-label" id={feature.properties.index} onClick={handleClickLabel} onDoubleClick={handleDoubleClick}>
            <img
              draggable={false}
              src={feature.geometry.type == "LineString" ? lock : feature.properties.identity == 0 ? inactive_poly : feature.properties.identity === 2 ? exclusion_poly : boundary_poly}
              alt=""
            />
            <label htmlFor={feature.properties.index}>{`${
              feature.geometry.type == "LineString" ? "Line" : feature.properties.identity == 1 ? "Boundary" : feature.properties.identity == 2 ? "Exclusion" : "Inactive"
            }_${feature.properties.index.substring(0, 2)}`}</label>
          </section>
          <Popconfirm placement="right" title="Are you sure?" onCancel={() => set_delete_id(undefined)} okText="Yes" cancelText="No" onConfirm={delete_single_feature}>
            <button onClick={(e) => set_delete_id(e.target.id)}>
              <img id={feature.properties.index} src={delete_single_polygon} />
            </button>
          </Popconfirm>
        </li>
      ))}
    </ul>
  );
};

const Boundaries = ({ drag_area_identity, draggingItem, dragOverItem }) => <FeatureList drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} identity={1} />;

const Exclusions = ({ drag_area_identity, draggingItem, dragOverItem }) => <FeatureList drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} identity={2} />;

const Inactives = ({ drag_area_identity, draggingItem, dragOverItem }) => <FeatureList drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} identity={0} />;

const ShadeObjects = ({ highlight, set_highlight, shade_object_manager_open, set_shade_object_manager_open, hidden_unchecked_features }) => {
  const dispatch = useDispatch();

  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);

  const [delete_id, set_delete_id] = useState(undefined);
  const [shade_folder_checked, set_shade_folder_checked] = useState(true);

  const handleDragStart = (event) => {
    set_dragging(event.target.id);
  };

  const handleClickLabel = (event) => {
    event.stopPropagation();
  };

  const handleDoubleClick = (event) => {
    event.stopPropagation();
  };

  const handleMouseEnter = (id) => {
    //center is actually the ID as highlighting works differently with shade layers
    dispatch(canopyActions.updateCanopyInputs("shade_marker_pos", id));
  };

  const handleMouseLeave = () => {
    dispatch(canopyActions.updateCanopyInputs("shade_marker_pos", undefined));
  };

  const handleChecked = (event) => {
    let temp_object = shade_objects[event.id];
    temp_object["checked"] = event.checked;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", temp_object));
  };

  const handleFolderCheck = (event) => {
    console.log("Event", event);
    let temp_shade_checked = { ...shade_objects };
    Object.values(temp_shade_checked).map((obj) => (obj["checked"] = event.checked));
    dispatch(canopyActions.updateShadeObject("load_bulk_shade_objects", temp_shade_checked));
    set_shade_folder_checked(event.checked);
  };

  if (Object.values(shade_objects).length == 0) return null;

  return (
    <section id="shadeObjectManager" className="shadeObjectManager" onDragOver={(e) => handleMouseOver(4)}>
      <section
        // onClick={() => set_inactives_open(!inactives_open)}
        onDragOver={() => set_highlight(4)}
        onDragLeave={() => set_highlight(undefined)}
        className={highlight === 4 ? "folder-header highlight-selected" : "folder-header"}
      >
        <span onClick={() => set_shade_object_manager_open(!shade_object_manager_open)}>
          <Arrow is_open={shade_object_manager_open} />
        </span>
        <ImageCheckbox id="4" key="4" checked={shade_folder_checked} onChange={handleFolderCheck} />
        <section className="folder-label" onClick={() => set_shade_object_manager_open(!shade_object_manager_open)}>
          <img src={folder} />
          <p>Shade Objects</p>
        </section>
      </section>
      {shade_object_manager_open && (
        <ul>
          {Object.values(shade_objects).map((object, index) => (
            <li
              key={object.id}
              id={object.id}
              // identity={feature.properties.identity}
              className={object.id === selected_shade_object_id ? "highlight-selected" : null}
              // draggable={true}
              // onDragStart={(event) => handleDragStart(event, index)}
              // onDragEnter={(event) => handleDragEnter(event, index)}
              // onDragOver={(event) => event.preventDefault()}
              // onDragEnd={handleDragEnd}
              onMouseEnter={(e) => {
                if (object?.checked === false) return;
                e.target.id === object.id && handleMouseEnter(object.id);
              }}
              onMouseLeave={(e) => {
                e.target.id === object.id && handleMouseLeave();
              }}
            >
              <ImageCheckbox id={object.id} checked={object.checked ?? true} onChange={handleChecked} />
              {/* <DragIcon /> */}
              <section className="poly-label" id={object.id} onClick={handleClickLabel} onDoubleClick={handleDoubleClick}>
                <img draggable={false} src={shade_input_icon} alt="" />
                <label htmlFor={object.id}>{`${object.type == "treeLine" ? "Line" : object.type.charAt(0).toUpperCase() + object.type.slice(1)}_${object.id.substring(0, 2)}`}</label>
              </section>
              <Popconfirm
                placement="right"
                title="Are you sure?"
                onCancel={() => console.log("Cancel delete shade object")}
                okText="Yes"
                cancelText="No"
                onConfirm={() => dispatch(canopyActions.updateShadeObject("delete_shade_object", object.id))}
              >
                <button>
                  <img id={object.id} src={delete_single_polygon} />
                </button>
              </Popconfirm>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};
const MapManager = () => {
  const dispatch = useDispatch();
  const site_features = useSelector((state) => state.inputs.site_features);
  const hidden_unchecked_features = useSelector((state) => state.inputs.hidden_unchecked_features || []);

  const boundaries_present = Object.values(site_features).some((feature) => feature.properties.identity === 1);
  const exclusions_present = Object.values(site_features).some((feature) => feature.properties.identity === 2);
  const inactive_present = Object.values(site_features).some((feature) => feature.properties.identity === 0);

  const [boundary_open, set_boundary_open] = useState(boundaries_present);
  const [exclusion_open, set_exclusion_open] = useState(exclusions_present);
  const [inactives_open, set_inactives_open] = useState(false);
  const [shade_object_manager_open, set_shade_object_manager_open] = useState(false);
  const [ctrl_down, set_ctrl_down] = useState(false); //select single
  const [shift_down, set_shift_down] = useState(false); // select multiple
  const [highlight, set_highlight] = useState(undefined);
  const [drag_area_identity, set_drag_area_identity] = useState(undefined);

  const draggingItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey || event.ctrlKey) {
        set_ctrl_down(true);
      }

      if (event.shiftKey) {
        set_shift_down(true);
      }
    };

    const handleKeyUp = (event) => {
      if (!event.metaKey || !event.ctrlKey) {
        set_ctrl_down(false);
      }

      if (!event.shiftKey) {
        set_shift_down(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleCheck = (event) => {
    let checked_arr = hidden_unchecked_features;
    let id = parseInt(event.id);

    let index_in_arr = checked_arr.findIndex((item) => item == id);
    if (index_in_arr === -1) {
      checked_arr.push(parseInt(id));
    } else {
      checked_arr.splice(index_in_arr, 1);
    }

    dispatch(inputsActions.update_input("hidden_unchecked_features", checked_arr));

    let temp_features = {};
    if (checked_arr.length > 0 && checked_arr.findIndex((item) => item == id) > -1) {
      Object.values(site_features).map((feature) => {
        if (feature.properties.identity == id) {
          temp_features = {
            ...temp_features,
            [feature.properties.index]: {
              ...feature,
              properties: {
                ...feature.properties,
                active: false,
                checked: false,
              },
            },
          };
        }
      });
    } else {
      Object.values(site_features).map((feature) => {
        if (feature.properties.identity == id) {
          temp_features = {
            ...temp_features,
            [feature.properties.index]: {
              ...feature,
              properties: {
                ...feature.properties,
                active: true,
                checked: true,
              },
            },
          };
        }
      });
    }

    dispatch(inputsActions.update_site_input("add_bulk_features", temp_features));
  };
  const handleMouseOver = (identity) => {
    set_drag_area_identity(identity);
  };

  return (
    <FeatureManagerWrap>
      {Object.values(site_features).length > 0 ? (
        <>
          <section id="boundaries" className="boundaries" onDragOver={(e) => handleMouseOver(1)}>
            <section
              // onClick={() => set_boundary_open(!boundary_open)}
              onDragOver={() => set_highlight(1)}
              onDragLeave={() => set_highlight(undefined)}
              className={highlight === 1 ? "folder-header highlight-selected" : "folder-header"}
            >
              <span onClick={() => set_boundary_open(!boundary_open)}>
                <Arrow is_open={boundary_open} />
              </span>
              <ImageCheckbox id="1" key="1" checked={hidden_unchecked_features.findIndex((item) => item === 1) === -1} onChange={handleCheck} />
              <section className="folder-label" onClick={() => set_boundary_open(!boundary_open)}>
                <img src={folder} />
                <p>Boundary</p>
              </section>
            </section>
            {boundary_open && <Boundaries drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} ctrl_down={ctrl_down} shift_down={shift_down} />}
          </section>
          <section id="exclusions" className="exclusions" onDragOver={(e) => handleMouseOver(2)}>
            <section
              // onClick={() => set_exclusion_open(!exclusion_open)}
              onDragOver={() => set_highlight(2)}
              onDragLeave={() => set_highlight(undefined)}
              className={highlight === 2 ? "folder-header highlight-selected" : "folder-header"}
            >
              <span onClick={() => set_exclusion_open(!exclusion_open)}>
                <Arrow is_open={exclusion_open} />
              </span>
              <ImageCheckbox id="2" key="2" checked={hidden_unchecked_features.findIndex((item) => item === 2) === -1} onChange={handleCheck} />
              <section className="folder-label" onClick={() => set_exclusion_open(!exclusion_open)}>
                <img src={folder} />
                <p>Exclusion</p>
              </section>
            </section>
            {exclusion_open && <Exclusions drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} ctrl_down={ctrl_down} shift_down={shift_down} />}
          </section>
          <section id="inactives" className="inactives" onDragOver={(e) => handleMouseOver(0)}>
            <section
              // onClick={() => set_inactives_open(!inactives_open)}
              onDragOver={() => set_highlight(0)}
              onDragLeave={() => set_highlight(undefined)}
              className={highlight === 0 ? "folder-header highlight-selected" : "folder-header"}
            >
              <span onClick={() => set_inactives_open(!inactives_open)}>
                <Arrow is_open={inactives_open} />
              </span>
              <ImageCheckbox id="0" key="0" checked={hidden_unchecked_features.findIndex((item) => item === 0) === -1} onChange={handleCheck} />
              <section className="folder-label" onClick={() => set_inactives_open(!inactives_open)}>
                <img src={folder} />
                <p>Inactive</p>
              </section>
            </section>
            {inactives_open && <Inactives drag_area_identity={drag_area_identity} draggingItem={draggingItem} dragOverItem={dragOverItem} ctrl_down={ctrl_down} shift_down={shift_down} />}
          </section>
          <ShadeObjects
            highlight={highlight}
            set_highlight={set_highlight}
            shade_object_manager_open={shade_object_manager_open}
            set_shade_object_manager_open={set_shade_object_manager_open}
            hidden_unchecked_features={hidden_unchecked_features}
          />
        </>
      ) : (
        <Empty description="Use the draw tools or import a KMZ file by dragging it onto the map" />
      )}
    </FeatureManagerWrap>
  );
};

export { MapManager };
