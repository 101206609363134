import styled from "styled-components";

const InputHeaderWrap = styled.section`
  #save-as-btn {
    transition: var(--antd-transition);
    &:hover span svg {
      fill: ${(props) => (props.changeFillOpacity ? "#555" : "var(--primary-brand-color)")};
    }
    span {
      display: flex;
      align-items: center;

      svg {
        margin-right: 2px;
        fill: #555;
        fill-opacity: ${(props) => (props.changeFillOpacity ? "0.5" : "1")};
      }
    }
  }
`;

export { InputHeaderWrap };
