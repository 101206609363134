import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";

import { inputsActions, siftActions } from "../../../../Redux";

import { SearchBar } from "./styles";

const Search = ({ expand_search }) => {
  const dispatch = useDispatch();

  const site_features = useSelector((state) => state.inputs.site_features);
  const boundaries_present = Object.values(site_features).some((feature) => feature.properties.identity === 1);

  const search_bar_ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (search_bar_ref.current) search_bar_ref.current.focus();
    }, 350);
  }, []);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyDFwy0txveMzEYIi42SOSlD2hwq9h9Xqb8");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  }, []);

  const handleSearch = (value) => {
    if (value.length < 3) {
      return;
    }
    let split = value.split(",");
    if (split.length == 2) {
      // could be lat lng search
      let lat = parseFloat(split[0]);
      let lng = parseFloat(split[1]);
      let lat_valid = !isNaN(lat) && lat <= 90 && lat >= -90;
      let lng_valid = !isNaN(lng) && lng <= 180 && lng >= -180;

      if (lat_valid && lng_valid) {
        dispatch(inputsActions.update_input("map_center", [lat, lng]));

        // this.setState({ zoom: 16 });
        return;
      }
    }

    // search for the value
    Geocode.fromAddress(value).then(
      (response) => {
        // console.log("res", response);
        const { lat, lng } = response.results[0].geometry.location;
        dispatch(inputsActions.update_input("map_center", [lat, lng]));

        if (!boundaries_present) {
          dispatch(siftActions.updateMarker({ lat, lng }));
        }
        // this.setState({ zoom: 16 });
      },
      (error) => {
        // message.error("Location wasn't found. Please check your search input and try again.", 1.5);
        console.error(error);
      }
    );
  };

  return (
    <SearchBar
      ref={search_bar_ref}
      placeholder="Latitude, Longitude or Address"
      // onChange={(e) => handleSearch(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleSearch(e.target.value);
        }
      }}
    />
  );
};

export { Search };
