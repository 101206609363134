import styled from 'styled-components';

export const ManagePlanColumn = styled.section`
  width: 100%;
  display: flex;
  ${'' /* margin: 0 auto; */}
  ${'' /* padding-bottom: 32px; */}

  &:button {
    ${'' /* padding: 5px; */}
    width: 80%;
    ${'' /* margin: 0 auto !important; */}
    ${'' /* position: relative !important; */}
  }
`;

export const FreeTrialColumn = styled.section`
  width: 100%;
  height: min-content;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
`;
