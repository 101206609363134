import React, { useState, useEffect } from "react";

import { CanopySelectWrap } from "./styles";
import { delete_polygon, copy } from "../../../../../assets/images";
import { Popconfirm } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import { canopyActions } from "../../../../Redux";

const CanopySelect = () => {
  const dispatch = useDispatch();
  const canopies = useSelector((state) => state.canopy.canopies);
  const selected_canopy = useSelector((state) => state.canopy.selected_canopy);

  const [highlight, set_highlight] = useState(undefined);

  const updateCanopyInputs = (key, value) => {
    dispatch(canopyActions.updateCanopyInputs(key, value));
  };

  return (
    <CanopySelectWrap>
      {Object.values(canopies).map((canopy) => (
        <section
          className={selected_canopy == canopy.id || highlight == canopy.id ? "canopy-feature selected-canopy" : "canopy-feature"}
          key={canopy.id}
          onClick={() => {
            updateCanopyInputs("selected_canopy", canopy.id);
            set_highlight(undefined);
          }}
          onMouseEnter={() => set_highlight(canopy.id)}
          onMouseLeave={() => set_highlight(undefined)}
        >
          <p>{canopy.name}</p>
          <section className="canopy-select-buttons">
            {/* <button onClick={() => console.log("copy canopy")}>
              <img src={copy} />
            </button> */}
            <Popconfirm title="Delete this Canopy?" placement="right" onConfirm={() => dispatch(canopyActions.deleteCanopies(selected_canopy))}>
              <button>
                <img src={delete_polygon} />
              </button>
            </Popconfirm>
          </section>
        </section>
      ))}
    </CanopySelectWrap>
  );
};

export { CanopySelect };
