import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-leaflet";
import { satellite_view, delete_polygon, draw_polygon, export_map, stree_view, search_map, toggle_topo, undo, redo, hexagon_2 } from "../../../../assets/images";
import { inputsActions, undoRedoActions, usePrevious } from "../../../Redux";

import { Popconfirm, Tooltip } from "antd";

// import { Tooltip } from "../../../BaseComponents";

import { ToolbarWrap } from "./styles";
import { Search, LayerMenu } from "./";
import { DrawPolygon } from "./DrawTools";
import { DrawPolyline } from "./DrawTools";
import { PolygonTools } from "./PolygonTools";
import { ExportMenu } from "./ExportMenu";
import { LeafletControl } from "../LeafletControl";
import { useKey } from "../MapEventHandler/useKey";

const Toolbar = ({ position }) => {
  const map = useMap();
  const dispatch = useDispatch();

  // store
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const search_visible = useSelector((state) => state.inputs.uiState.search_visible);
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const is_drawing_polyline = useSelector((state) => state.inputs.uiState.is_drawing_polyline);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const prevSelectedId = usePrevious(selectedFeatureId);

  // state
  const [expand, set_expand] = useState(false);
  const [expand_map_layers, set_expand_map_layers] = useState(false);
  const [expand_polygon_tools, set_expand_polygon_tools] = useState(false);
  const [expand_export_tools, set_expand_export_tools] = useState(false);
  const [expand_height, set_expand_height] = useState(0);

  // hotkeys
  const control_key = useKey("control");
  const meta_key = useKey("meta");
  const z_key = useKey("z");
  const shift_key = useKey("shift");

  const expand_map_layers_ref = useRef();
  const expand_export_tools_ref = useRef();

  useEffect(() => {
    if (!generated && (control_key || meta_key) && !shift_key && z_key) {
      dispatch(undoRedoActions.undo());
    }
  }, [z_key, control_key, meta_key]);

  useEffect(() => {
    if (!generated && (control_key || meta_key) && shift_key && z_key) {
      dispatch(undoRedoActions.redo());
    }
  }, [z_key, control_key, meta_key, shift_key]);

  useEffect(() => {
    map.on("click", () => {
      if (expand_map_layers_ref.current == true) {
        set_expand_map_layers(false);
      }
      if (expand_export_tools_ref.current == true) {
        set_expand_export_tools(false);
      }
    });

    return () => map.off("click");
  }, []);

  useEffect(() => {
    expand_map_layers_ref.current = expand_map_layers;
  }, [expand_map_layers]);

  useEffect(() => {
    expand_export_tools_ref.current = expand_export_tools;
  }, [expand_export_tools]);

  useEffect(() => {
    if (selectedFeatureId) {
      toggle_polygon_tools(true);
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (expand_map_layers) toggle_map_layers(false);
      if (expand_export_tools) toggle_export_tools(false);
    } else {
      toggle_polygon_tools(false);
    }

    if (prevSelectedId != selectedFeatureId) {
      set_expand_height(40);
    }
  }, [prevSelectedId, selectedFeatureId]);

  useEffect(() => {
    if (is_drawing_polyline) {
      if (search_visible) dispatch(inputsActions.update_ui_state("search_visible", false));
      if (expand_map_layers) toggle_map_layers(false);
      if (expand_export_tools) toggle_export_tools(false);
      if (is_drawing) {
        map.fire("cancelDraw");
        dispatch(inputsActions.update_ui_state("is_drawing", false));
      }
    }
  }, [is_drawing_polyline]);

  const toggle_search = (toggled) => {
    dispatch(inputsActions.update_ui_state("search_visible", !search_visible));
    if (toggled == true) {
      set_expand_height(40);
      set_expand_map_layers(false);
      set_expand_polygon_tools(false);
      set_expand_export_tools(false);
      if (is_drawing) map.fire("cancelDraw");
      if (is_drawing_polyline) dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
    }

    if (selectedFeatureId && toggled) {
      toggle_polygon_tools(true);
    }
  };

  const toggle_map_layers = (toggled) => {
    set_expand_map_layers(!expand_map_layers);
    if (toggled == true) {
      set_expand_height(435);
      dispatch(inputsActions.update_ui_state("search_visible", false));
      set_expand_polygon_tools(false);
      set_expand_export_tools(false);
      if (is_drawing) map.fire("cancelDraw");
      if (is_drawing_polyline) dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
    }
  };

  const toggle_polygon_tools = (toggled) => {
    set_expand_polygon_tools(toggled);
    if (toggled == false) {
      set_expand_height(40);
      dispatch(inputsActions.update_ui_state("search_visible", false));
      set_expand_map_layers(false);
      set_expand_export_tools(false);
      if (is_drawing_polyline) {
        map.fire("cancelDraw");
        dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
      }
    }
  };

  const toggle_export_tools = (toggled) => {
    set_expand_export_tools(!expand_export_tools);
    if (toggled == true) {
      set_expand_height(40);
      dispatch(inputsActions.update_ui_state("search_visible", false));
      set_expand_map_layers(false);
      set_expand_polygon_tools(false);
      if (is_drawing) map.fire("cancelDraw");
      if (is_drawing_polyline) dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
    }
  };

  const deleteAll = () => {
    map.fire("deleteFeatures");
  };

  return (
    <LeafletControl position={position} id="toolbar">
      <ToolbarWrap expand_height={expand_height} expand={search_visible || expand_map_layers}>
        <section className="map-action-buttons">
          <Tooltip placement="left" title="Search" mouseEnterDelay={0.5}>
            <button className={search_visible ? "button-active" : ""} onClick={() => toggle_search(!search_visible)}>
              <img draggable={false} src={search_map} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Change Basemap" mouseEnterDelay={0.5}>
            <button className={expand_map_layers ? "button-active" : ""} onClick={() => toggle_map_layers(!expand_map_layers)}>
              <img draggable={false} src={stree_view} />
            </button>
          </Tooltip>
          <DrawPolygon toggle_polygon_tools={toggle_polygon_tools} expand_polygon_tools={expand_polygon_tools} />
          {/* <DrawPolyline /> */}
          <Tooltip placement="bottom" title="Delete All" mouseEnterDelay={0.5}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                deleteAll();
              }}
            >
              <button disabled={generated}>
                <img draggable={false} src={delete_polygon} />
              </button>
            </Popconfirm>
          </Tooltip>
          <ExportMenu />
          {/* <button className={expand_export_tools ? "button-active" : ""} onClick={() => toggle_export_tools(!expand_export_tools)}>
              <img draggable={false} src={export_map} />
            </button> */}
          {/* <section className="undo-redo">
            <section className="vert-line"></section>
            <Tooltip placement="bottom" title="Undo" mouseEnterDelay={0.5}>
              <button onClick={() => dispatch(undoRedoActions.undo())} disabled={generated} style={{ cursor: generated && "not-allowed" }}>
                <img draggable={false} className="undo" src={undo} />
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Redo" mouseEnterDelay={0.5}>
              <button onClick={() => dispatch(undoRedoActions.redo())} disabled={generated} style={{ cursor: generated && "not-allowed" }}>
                <img draggable={false} className="redo" src={redo} />
              </button>
            </Tooltip>
          </section> */}
        </section>

        <section className={search_visible || expand_map_layers || expand_polygon_tools || expand_export_tools ? "expand-down" : "map-action-tray"}>
          {search_visible && <Search />}
          {expand_map_layers && <LayerMenu />}
          {selectedFeatureId && expand_polygon_tools && !search_visible && <PolygonTools id={selectedFeatureId} />}
          {/* <ExportMenu expand_export_tools={expand_export_tools} /> */}
        </section>
      </ToolbarWrap>
    </LeafletControl>
  );
};

export { Toolbar };
